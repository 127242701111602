import React from 'react'
import {waitFrames} from "../../../helpers/waitFrames";
import RunAnimation from './RunAnimation'
import {audioManager} from "../../../audio/audioManager";


class AnimationOpenBuggedItem extends React.Component {

  state = {
    animationsFinished: 0,
    characterJumpsFinished: 0,
  };

  componentDidMount() {
    const targets = this.props.event.subjects;

    audioManager.playSfx("sfx_useBuggedItem");

    waitFrames(10, () => {
      targets.forEach(id => {
        this.props.handleCombatantStateChange([
          {
            combatantId: id,
            absoluteChanges: {
              animation: "characterShake",
            }
          }
        ]);

        waitFrames(30, () => {
          audioManager.playSfx("sfx_iteratePain")
        });

        waitFrames(40, () => {
          this.props.handleCombatantStateChange([
            {
              combatantId: id,
              absoluteChanges: {
                animation: "none",
              }
            }
          ]);
          this.setState(state => ({characterJumpsFinished: state.characterJumpsFinished+1}))
        })
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const targets = this.props.event.subjects;
    const wasFinished = (
      prevState.animationsFinished === targets.length &&
      prevState.characterJumpsFinished === targets.length
    );
    const nowFinished = (
      this.state.animationsFinished === targets.length &&
      this.state.characterJumpsFinished === targets.length
    );
    if (!wasFinished && nowFinished) {
      this.props.onEventDone()
    }
  }

  render() {
    const {pixelSize, event, positions} = this.props;
    return event.subjects.map((subjectId, i) => {
      return (
        <RunAnimation
          key={subjectId}
          pixelSize={pixelSize}
          left={positions[subjectId].leftPosition}
          top={positions[subjectId].topPosition}
          widthOfSpriteSheet={224}
          frames={7}
          duration="0.5s"
          onDone={() => {
            this.setState(state => ({animationsFinished: state.animationsFinished+1}))
          }}
          renderPaths={() => (
            <React.Fragment>
              <path stroke="#fff" d="M264 3h2m-96 1h12m52 0h12m18 0h2M139 5h1m2 0h1m5 0h1m20 0h1m12 0h1m50 0h1m12 0h1M108 6h8m23 0h1m8 0h1m20 0h1m12 0h1m50 0h1m12 0h1M108 7h8m23 0h1m29 0h1m12 0h1m50 0h1m12 0h1m31 0h1M140 8h1m6 0h1m18 0h4m12 0h4m44 0h4m12 0h4M140 9h2m3 0h3m17 0h1m20 0h1m42 0h1m20 0h1m32 0h2m-145 1h2m3 0h3m17 0h1m20 0h1m42 0h1m20 0h1m8 0h1m23 0h2m-145 1h2m3 0h3m17 0h1m20 0h1m42 0h1m20 0h1m-111 1h3m2 0h3m17 0h1m20 0h1m42 0h1m20 0h1m-112 1h4m2 0h4m16 0h1m20 0h1m42 0h1m20 0h1m-86 1h1m20 0h1m42 0h1m20 0h1m-86 1h1m20 0h1m42 0h1m20 0h1m-86 1h1m20 0h1m42 0h1m20 0h1m-86 1h1m4 0h4m4 0h4m4 0h1m42 0h1m4 0h4m4 0h4m4 0h1m-86 1h1m4 0h1m2 0h1m4 0h1m2 0h1m4 0h1m42 0h1m4 0h1m2 0h1m4 0h1m2 0h1m4 0h1m-86 1h1m4 0h1m2 0h1m4 0h1m2 0h1m4 0h1m42 0h1m4 0h1m2 0h1m4 0h1m2 0h1m4 0h1m-86 1h1m4 0h1m2 0h1m4 0h1m2 0h1m4 0h1m42 0h1m4 0h1m2 0h1m4 0h1m2 0h1m4 0h1m-85 1h4m3 0h5m4 0h4m44 0h4m3 0h5m4 0h4"/>
              <path stroke="#ff4848" d="M202 4h12M107 5h10m84 0h1m12 0h1M107 6h1m8 0h1m84 0h1m12 0h1M107 7h1m8 0h1m84 0h1m12 0h1m-17 1h4m12 0h4m-48 1h4m4 0h4m15 0h1m4 0h4m4 0h4m4 0h1m15 0h4m4 0h4m-76 1h4m4 0h4m15 0h1m4 0h4m4 0h4m4 0h1m15 0h4m4 0h4m-76 1h4m4 0h4m15 0h1m4 0h4m4 0h4m4 0h1m15 0h4m4 0h4m-76 1h4m4 0h4m15 0h1m4 0h4m4 0h4m4 0h1m15 0h4m4 0h4m-49 1h1m20 0h1m-22 1h1m20 0h1m-22 1h1m20 0h1m-22 1h1m20 0h1m-22 1h1m4 0h4m4 0h4m4 0h1m-22 1h1m4 0h1m2 0h1m4 0h1m2 0h1m4 0h1m-22 1h1m4 0h1m2 0h1m4 0h1m2 0h1m4 0h1m-22 1h1m4 0h1m2 0h1m4 0h1m2 0h1m4 0h1m-21 1h4m3 0h5m4 0h4"/>
              <path stroke="#5c4438" d="M75 5h10M75 6h1m8 0h1M75 7h1m8 0h1m-42 3h10m-10 1h1m8 0h1m-10 1h1m8 0h1m-42 2h10m-10 1h1m8 0h1m-10 1h1m8 0h1"/>
              <path stroke="#3c1f33" d="M140 5h2m1 0h5m22 0h12m20 0h12m20 0h12M140 6h8m22 0h12m20 0h12m20 0h12M140 7h9m21 0h12m20 0h12m20 0h12M139 8h1m1 0h6m1 0h1m21 0h12m20 0h12m20 0h12M139 9h1m2 0h3m3 0h1m17 0h4m4 0h4m4 0h4m12 0h4m4 0h4m4 0h4m12 0h4m4 0h4m4 0h4m-111 1h1m2 0h3m3 0h1m17 0h4m4 0h4m4 0h4m12 0h4m4 0h4m4 0h4m12 0h4m4 0h4m4 0h4m-111 1h1m2 0h3m3 0h1m17 0h4m4 0h4m4 0h4m12 0h4m4 0h4m4 0h4m12 0h4m4 0h4m4 0h4m-111 1h1m3 0h2m3 0h1m17 0h4m4 0h4m4 0h4m12 0h4m4 0h4m4 0h4m12 0h4m4 0h4m4 0h4m-107 1h2m21 0h20m12 0h20m12 0h20m-107 1h2m21 0h20m12 0h20m12 0h20m-107 1h2m21 0h20m12 0h20m12 0h20m-107 1h1m22 0h20m12 0h20m12 0h20m-107 1h1m22 0h4m4 0h4m4 0h4m12 0h4m4 0h4m4 0h4m12 0h4m4 0h4m4 0h4m-107 1h1m22 0h4m4 0h4m4 0h4m12 0h4m4 0h4m4 0h4m12 0h4m4 0h4m4 0h4m-84 1h4m4 0h4m4 0h4m12 0h4m4 0h4m4 0h4m12 0h4m4 0h4m4 0h4m-84 1h4m4 0h4m4 0h4m12 0h4m4 0h4m4 0h4m12 0h4m4 0h4m4 0h4"/>
              <path stroke="#e7b187" d="M76 6h8m-8 1h8m-40 4h8m-8 1h8m-40 3h8m-8 1h8"/>
              <path stroke="#463025" d="M75 8h1m8 0h1M75 9h1m8 0h1m-10 1h1m8 0h1m-10 1h1m8 0h1m-10 1h1m8 0h1m-42 1h1m8 0h1m22 0h1m8 0h1m-42 1h1m8 0h1m-10 1h1m8 0h1m-10 1h1m8 0h1m-42 1h1m8 0h1m22 0h1m8 0h1m-42 1h1m8 0h1m22 0h1m8 0h1m-42 1h1m8 0h1m-10 1h1m8 0h1m-10 1h1m8 0h1m-10 1h1m8 0h1"/>
              <path stroke="#ffd7b8" d="M76 8h8m-40 5h8m-40 4h8"/>
              <path stroke="red" d="M107 8h1m8 0h1m-10 1h1m8 0h1m-10 1h1m8 0h1m-10 1h1m8 0h1m-10 1h1m8 0h1m-10 1h1m8 0h1"/>
              <path stroke="#ecf0e9" d="M108 8h8m-8 1h8m-8 1h8m-8 1h8m-8 1h8m-8 1h8"/>
              <path stroke="#c17e56" d="M76 9h5m1 0h1m-7 1h6m1 0h1m-8 1h8m-8 1h8m-8 1h8m-40 1h5m1 0h1m-7 1h6m1 0h1m-8 1h8m-8 1h8m-40 1h5m1 0h1m25 0h8m-40 1h6m1 0h1m-8 1h8m-8 1h8m-8 1h8"/>
              <path stroke="#3f3f74" d="M81 9h1m1 0h1m-2 1h1m-34 4h1m1 0h1m-2 1h1m-34 3h1m1 0h1m-2 1h1"/>
              <path stroke="#1f1713" d="M75 14h10m-42 5h10m-42 4h10"/>
              <path stroke="#892828" d="M107 14h10"/>
            </React.Fragment>
          )}
        />
      )
    })
  }
}

export default AnimationOpenBuggedItem;