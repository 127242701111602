export default function({ combatants = [], battleStoryPoints = [], regularDecision, subject }) {

  if (!battleStoryPoints.includes("gareth--block")) {
    return {
      submission: [
        {
          actionId: "speak",
          casterId: subject.id,
          targetIds: [subject.id],
          mergeData: {
            dialogContent: [
              "This Blocker will protect me!"
            ]
          }
        },
        {
          actionId: "script_block",
          casterId: subject.id,
          targetIds: [subject.id]
        }
      ],
      updatedBattleStoryPoints: [...battleStoryPoints, "gareth--block"]
    };
  }

  if (!battleStoryPoints.includes("gareth--foreach")) {
  const hero = combatants.find(c => c.belongsToTeam === "one");
    return {
      submission: [
        {
          actionId: "speak",
          casterId: subject.id,
          targetIds: [subject.id],
          mergeData: {
            dialogContent: [
              "I'm the top hacker around here!"
            ]
          }
        },
        {
          actionId: "script_foreach",
          casterId: subject.id,
          targetIds: [hero.id]
        }
      ],
      updatedBattleStoryPoints: [...battleStoryPoints, "gareth--foreach"]
    };
  }

  //Default to regular
  return {
    submission: regularDecision,
    updatedBattleStoryPoints: battleStoryPoints
  };
}