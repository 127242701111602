export function getPauseLineupSwapMenu({acquiredMembers}) {
  let menu = {
    back: {
      handler() {
        return { type: "GO_BACK"}
      }
    }
  };
  let map = [];


  //If we have somebody in slot 3, the crew is full
  const lineupIsFull = acquiredMembers.find(m => m.battleCrewSlot === 3);

  const perRow = 4;
  let onRow = 0;
  let onCount = 1;

  acquiredMembers.filter(c => {
    return c.isAvailable !== false
  }).forEach(m => {

    if (m.id === "hero") {
      return; //Why even include the hero?
    }



    menu[m.id] = {
      label: m.name,
      skinId: m.skinId,
      class: m.class,
      battleCrewSlot: m.battleCrewSlot,

      description: function() {

        if (lineupIsFull) {
          return "0 slots available"
        }
        if (acquiredMembers.find(m => m.battleCrewSlot === 2)) {
          return "1 slot available"
        }
        return "2 slots available"
      }(),

      checkUnavailable() {

        return lineupIsFull && !m.battleCrewSlot;

        //return m.id === "hero" || m.isAvailable === false
      },
      handler() {

        if (m.battleCrewSlot) {
          return {
            type: "REMOVE_FROM_CREW",
            battleCrewSlot: m.battleCrewSlot,
          }
        }
        return {
          type: "ADD_TO_CREW",
          memberId: m.id,
        }
      }
    };



    if (!map[onRow]) { map[onRow] = []}
    map[onRow].push(m.id);

    onCount += 1;

    if (onCount > perRow) {
      onCount = 1;
      onRow +=1;
    }

  });

  return {menu, map, backOptionId: "back"}
}