import React from 'react'
import {defaultBattlerValues} from './defaultBattlerProperties'
import BattlerForm from './BattlerForm'
import Battler from './Battler'
import {bridgeDevSetupToBattle} from './bridgeDevSetupToBattle'
import Arenas from '../Arenas'
import {getQueryVariable} from "../../helpers/get-query-variable";

//static data
const allArenaKeys = Object.keys(Arenas);
const bosses = [
  {value: "", label: "(none)"},
  {value: "bully", label: "Bully"},
  {value: "gareth", label: "Gareth"},
  {value: "securityjack", label: "Security Jack"},
  {value: "frankie", label: "Frankie & Thugs"},
  {value: "judge", label: "The Judge"},
  {value: "bullyAndRecruiter", label: "Bully & Recruiter"},
  {value: "oliver", label: "Oliver & Friends"},
  {value: "richard1", label: "Richard 1"},
  {value: "richard2", label: "Richard 2"},
  {value: "hackerKing1", label: "Hacker King 1"},
  {value: "hackerKing2", label: "Hacker King 2"},
  {value: "hackerKing3", label: "Hacker King 3"},
  {value: "drewAndGlenn", label: "Drew & Glenn"},
];


class DevSetupBattle extends React.Component {

  state = {
    useArena: window.localStorage.getItem("tdcDevBattleSavedArena") || allArenaKeys[0],
    bossPreset: window.localStorage.getItem("tdcDevBattleSavedBoss") || "",
    battlers: [
      {
        ...defaultBattlerValues,
        isIncluded: "true",
        id: "memberA",
        teamId: "one",
        name: "Jerry",
        class: "lead",
        skinId: "hank",
        personality: "regular"
      },
      {
        ...defaultBattlerValues,
        isIncluded: "false",
        id: "memberB",
        teamId: "one",
        name: "Emmy",
        class: "lead",
        skinId: "emmy",
        personality: "regular"
      },
      {
        ...defaultBattlerValues,
        isIncluded: "false",
        id: "memberC",
        teamId: "one",
        name: "Shirmond",
        class: "lead",
        skinId: "shirmond",
        personality: "regular"
      },

      {
        ...defaultBattlerValues,
        isIncluded: "true",
        id: "enemyA",
        teamId: "two",
        name: "Baddie A",
        skinId: "zombie1",
        personality: "regular",
        items: [
          {actionId: "item_battery-pack", isBugged: "no"},
          {actionId: "item_battery-pack", isBugged: "yes"},
        ]
      },
      {
        ...defaultBattlerValues,
        isIncluded: "false",
        id: "enemyB",
        teamId: "two",
        name: "Baddie B",
        skinId: "zombie1",
        personality: "regular"
      },
      {
        ...defaultBattlerValues,
        isIncluded: "false",
        id: "enemyC",
        teamId: "two",
        name: "Baddie C",
        skinId: "zombie1",
        personality: "regular"
      },
      {
        ...defaultBattlerValues,
        isIncluded: "false",
        id: "enemyD",
        teamId: "two",
        name: "Baddie D",
        skinId: "zombie1",
        personality: "regular"
      },
      {
        ...defaultBattlerValues,
        isIncluded: "false",
        id: "enemyE",
        teamId: "two",
        name: "Baddie E",
        skinId: "zombie1",
        personality: "regular"
      },
    ],
    activeBattlerId: window.localStorage.getItem("tdcDevBattleSetupBattlerId") || "enemyA",
  };


  componentDidMount() {
    const savedPreviousBattleSetup = window.localStorage.getItem("tdcDevBattleSetup");
    if (savedPreviousBattleSetup) {
      this.setState({
        battlers: JSON.parse(savedPreviousBattleSetup),
      }, () => {

          if (getQueryVariable("auto")) {
            this.goToBattle();
          }

      })
    }

  }

  saveBattleSetup() {
    window.localStorage.setItem("tdcDevBattleSetup", JSON.stringify(this.state.battlers));
    window.localStorage.setItem("tdcDevBattleSetupBattlerId", this.state.activeBattlerId);

    window.localStorage.setItem("tdcDevBattleSavedBoss", this.state.bossPreset);
    window.localStorage.setItem("tdcDevBattleSavedArena", this.state.useArena);

    console.log('saved')
  }


  handleAddMember = (teamId) => {
    const {battlers} = this.state;
    //toggle member to included.
    const firstNonActiveMember = battlers.find(b => b.teamId === teamId && b.isIncluded === "false");
    if (firstNonActiveMember) {
      this.handleChangeBattler(firstNonActiveMember.id, "isIncluded", "true")
    }
  };

  handleRemoveMember = (id) => {
    if (id === this.state.activeBattlerId) {
      this.setState({
        activeBattlerId: "enemyA"
      })
    }
    this.handleChangeBattler(id, "isIncluded", "false")
  };

  handleChangeBattler = (id, property, newValue) => {
    this.setState(state => {
      return {
        battlers: state.battlers.map(b => {
          if (b.id === id) {
            return {
              ...b,
              [property]: newValue
            }
          }
          return b
        })
      }
    })
  };

  goToBattle = () => {
    this.saveBattleSetup();
    const combatants = bridgeDevSetupToBattle(this.state.battlers);
    this.props.onDone({
      combatants,
      useArena: this.state.useArena,
      bossPreset: this.state.bossPreset,
    });
  };

  renderBattlerList(teamId) {
    const members = this.state.battlers.filter(b => b.teamId === teamId && b.isIncluded === "true");
    const limit = teamId === "one" ? 3 : 5;
    return (
      <div style={{
        display: "flex",
        alignItems: "center"
      }}>
        {
          members.map(b => {
            return (
              <Battler
                key={b.id}
                model={b}
                isEnemy={teamId === "two"}
                isActive={this.state.activeBattlerId === b.id}
                handleRemoveMember={() => {
                  this.handleRemoveMember(b.id)
                }}
                handleSetFormActive={() => {
                  this.setState({activeBattlerId: b.id})
                }}/>
            )
          })
        }
        {
          members.length < limit && (
            <button
              onClick={() => {
                this.handleAddMember(teamId)
              }}
              style={{height: 40}}>
              Add
            </button>
          )
        }
      </div>
    )
  }

  render() {
    const {battlers, activeBattlerId} = this.state;
    return (
      <div style={{height: "100%", width: "100%", background: "#fff", color: "#111"}}>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1em"
        }}>
          <div>
            <div>
              <label>Use Boss Preset</label>
              <select value={this.state.bossPreset} onChange={e => this.setState({ bossPreset: e.target.value})}>
              {
               bosses.map((m, i) => (
                 <option key={i} value={m.value}>{m.label}</option>
               ))
              }
              </select>
            </div>
            <div>
              <label>Arena</label>
              <select value={this.state.useArena} onChange={e => this.setState({ useArena: e.target.value})}>
                {
                  allArenaKeys.map(key => (
                    <option key={key} value={key}>{key}</option>
                  ))
                }
              </select>
            </div>
          </div>
          <div>
            <button
              style={{padding: "1em", fontFamily: "inherit"}}
              onClick={() => {
                this.goToBattle()
              }}
            >
              Battle!
            </button>
          </div>
        </div>
        <div style={{
          opacity: this.state.bossPreset === "" ? 1 : 0.2,
          pointerEvents: this.state.bossPreset === "" ? "initial" : "none"
        }}>
          {this.renderBattlerList("two")}
        </div>
        <BattlerForm
          battlerId={activeBattlerId}
          battlerValues={battlers.find(b => b.id === activeBattlerId)}
          handleChangeBattler={this.handleChangeBattler}
        />
        {this.renderBattlerList("one")}
      </div>
    );
  }
}

export default DevSetupBattle;