import React from 'react'
import {waitFrames} from "../../../helpers/waitFrames";
import RunAnimation from './RunAnimation'
import {audioManager} from "../../../audio/audioManager";

class AnimationZap extends React.Component {

  state = {
    hasTriggeredAnimation: false
  };

  componentDidMount() {

    const targets = this.props.event.subjects;

    waitFrames(1, () => {
      this.setState({
        hasTriggeredAnimation: true
      }, () => {
        //Start shaking
        audioManager.playSfx("sfx_iteratePain")
        targets.forEach(id => {
          this.props.handleCombatantStateChange([
            {
              combatantId: id,
              absoluteChanges: {
                laptopFlash: "pain",
                animation: "characterShake",
              }
            }
          ])
        });
        //Stop shaking
        waitFrames(20, () => {
          targets.forEach(id => {
            this.props.handleCombatantStateChange([
              {
                combatantId: id,
                absoluteChanges: {
                  laptopFlash: "none",
                  animation: null,
                }
              }
            ])
          });
        });
      });
    });
  }

  render() {
    const {pixelSize, event, positions} = this.props;
    return event.subjects.map((subjectId, i) => {
      return (
        <RunAnimation
          key={subjectId}
          pixelSize={pixelSize}
          left={positions[subjectId].leftPosition}
          top={positions[subjectId].topPosition}
          widthOfSpriteSheet={160}
          frames={5}
          duration="0.4s"
          onDone={() => { waitFrames(10, () => {this.props.onEventDone()}) }}
          renderPaths={() => (
            <React.Fragment>
              <path stroke="#82def2" d="M48 4h1M22 5h1m24 0h2m63 0h1M47 6h3m62 0h2M46 7h5m60 0h4M46 8h5m60 0h4M45 9h7m58 0h2m1 0h2m-92 1h1m21 0h8m57 0h2m-80 1h1m11 0h4m1 0h5m56 0h2m-80 1h1m11 0h3m4 0h3m-22 1h2m9 0h4m5 0h3m41 0h1m-65 1h3m8 0h3m7 0h3m40 0h2m-65 1h3m6 0h4m7 0h3m41 0h2m-65 1h3m5 0h3m8 0h4m6 0h1m34 0h2m-66 1h4m3 0h4m9 0h3m5 0h2m34 0h2m8 0h1m9 0h2m-85 1h4m2 0h3m11 0h3m3 0h3m35 0h2m6 0h1m11 0h1m6 0h1m41 0h2M3 19h1m32 0h8m12 0h3m1 0h4m36 0h2m4 0h2m12 0h2m3 0h3m40 0h1m16 0h1M36 20h7m13 0h8m36 0h3m2 0h2m13 0h3m2 0h3m39 0h1m18 0h2M37 21h6m14 0h6m38 0h2m2 0h2m14 0h6m39 0h2m19 0h1M38 22h4m16 0h3m41 0h1m2 0h1m16 0h3m-99 1h1m11 0h4m17 0h1m42 0h4m17 0h1m-85 1h2m62 0h2m-65 1h1m63 0h1M0 30h1m31 0h1m31 0h1m31 0h1"/>
              <path stroke="#fffdda" d="M67 9h2m-3 1h4m21 0h1m-26 1h5m19 0h2m-27 1h8m16 0h4m-29 1h3m2 0h5m14 0h6m-30 1h2m4 0h5m11 0h9m-31 1h1m6 0h5m9 0h6m2 0h2m-31 1h1m7 0h6m6 0h6m4 0h2m-23 1h6m4 0h6m6 0h1m-22 1h6m2 0h6m-13 1h12m-11 1h10m-9 1h8m-7 1h6m-5 1h4m-3 1h2"/>
            </React.Fragment>
          )}
        />
      )
    })
  }
}

export default AnimationZap;