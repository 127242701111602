export function getArenaBailMenu(allCombatants=[]) {

  let menu = {};
  let map = [
    ["bailFinal"],
    ["back"]
  ];

  const isBailDisabled = allCombatants.find(c => c.preventsBail);

  menu[`bailFinal`] = {
    label: isBailDisabled ? "Cannot bail!" : "Forfeit battle",
    checkUnavailable() {
      return Boolean(isBailDisabled)
    },
    handler() {
      //console.log('handle Battle bail');
      return {
        //goToMenuId: "home"
        submitBail: true
      }
    }
  };

  menu[`back`] = {
    label: "Cancel",
    handler() {
      return {
        goToMenuId: "home"
      }
    }
  };

  return {
    menu,
    map,
    backOptionId: "back"
  }
}