import React from 'react';

export default class DangerMeter extends React.PureComponent {
  render()
  {
    const {value, pixelSize, isDisabled} = this.props;
    const style = {
      width: pixelSize * 25,
      height: pixelSize * 4,
      display: "block",
      marginTop: pixelSize
    };

    //Totally empty state.. no Supers available to use so don't ever show anything
    if (isDisabled) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 25 4" shapeRendering="crispEdges">
          <path stroke="#dedede" d="M0 0h25M0 1h1M6 1h1M12 1h1M18 1h1M24 1h1M0 2h1M6 2h1M12 2h1M18 2h1M24 2h1M0 3h25" />
        </svg>
      )
    }

    return (
      <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 25 4" shapeRendering="crispEdges">
        <path stroke="#08152d" d="M0 0h25M0 1h1M6 1h1M12 1h1M18 1h1M24 1h1M0 2h1M6 2h1M12 2h1M18 2h1M24 2h1M0 3h25" />
        <path stroke="#49515e" d="M1 1h5M7 1h5M13 1h5M19 1h5" />
        <path stroke="#697180" d="M1 2h5M7 2h5M13 2h5M19 2h5" />


        {/* Fill in the blips */}
        {
          [
            {threshold: 25, x: 1},
            {threshold: 50, x: 7},
            {threshold: 75, x: 13},
            //No 4th, because that would be full.
          ].filter(b => value >= b.threshold).map((b, i) => {
            return (
              <React.Fragment key={i}>
                {/*Base color*/}
                <rect fill="#ff9a5b" x={b.x} y="0.5" width="5" height="2"/>
                <rect fill="#ffbe5d" x={b.x} y="0.5" width="5" height="1"/>
              </React.Fragment>
            )
          })
        }

        {
          value >= 100 ? (
            <React.Fragment>
              <rect className="svg_danger-meter-blip--full" x="1" y="0.5" width="5" height="2"/>
              <rect className="svg_danger-meter-blip--full" x="7" y="0.5" width="5" height="2"/>
              <rect className="svg_danger-meter-blip--full" x="13" y="0.5" width="5" height="2"/>
              <rect className="svg_danger-meter-blip--full" x="19" y="0.5" width="5" height="2"/>
            </React.Fragment>
          ) : null
        }
        <style>
          {`
            @keyframes svg_dangerMeterReady {
            50% {
              fill: #FF61C8;
              color: #FF61C8;
            }
            100% {
              fill: white;
              color: white;
            }
          }
            .svg_danger-meter-blip--full {
                  animation: svg_dangerMeterReady 0.5s infinite linear alternate-reverse;
            }
        `}
        </style>
      </svg>
    )
  }
}