import { getQueryVariable } from "../helpers/get-query-variable";
import { getCombatantStats } from "../Battle7/getCombatantStats";
import BattleActions from '../Battle7/battle-data/battle-actions-data'


export function getDefaultPlayerData(options={}) {

  const forceDefault = options.forceDefault || false; //Flag for always making sure this returns default values despite load flag

  let payload = {
    acquiredStoryPoints: [],
    teamXp: 0,
    acquiredMembers: [
      {
        id: "hero",
        name: "Player",
        skinId: "mystery",
        class: "lead",
        battleCrewSlot: 1,
        hp: getCombatantStats({ level: 1 }).maxHp,
        isAlwaysInBattleCrew: true,
        equippedAttacks: {
          slot1: "script_slice",
          slot2: "script_curl",
          slot3: null,
          slot4: null,
          slotSuper: null
        }
      }
    ],
    inventory: [],
    allPlayerAttacks: [
      { actionId: "script_slice" },
      { actionId: "script_curl" }
    ],
    allPlayerUpgrades: [],
    currency: {
      credits: 0
    }
  };

  if (process.env.REACT_APP_FIREBASE_CONFIG === "production" || forceDefault) {
    return payload;
  }

  //This stuff is for local/stage only:
  function createMember(id, level) {
    if (id === "hero") {
      return {
        id: "hero",
        name: "Hank",
        skinId: "hank",
        class: "lead",
        battleCrewSlot: 1,
        hp: getCombatantStats({ level }).maxHp,
        isAlwaysInBattleCrew: true,
        equippedAttacks: {
          slot1: "script_slice",
          slot2: "script_curl",
          slot3: null,
          slot4: null,
          slotSuper: null
        }
      }
    }
    if (id === "memberB") {
      return {
        id: "memberB",
        name: "Zak",
        skinId: "zak",
        class: "support",
        battleCrewSlot: 2,
        hp: getCombatantStats({ level }).maxHp,
        equippedAttacks: {
          slot1: "script_pop",
          slot2: "script_scrum",
          slot3: null,
          slot4: null,
          slotSuper: null
        }
      };
    }
    if (id === "memberC") {
      return {
        id: "memberC",
        name: "KT",
        skinId: "sara",
        class: "disrupt",
        battleCrewSlot: 3,
        hp: getCombatantStats({ level }).maxHp,
        equippedAttacks: {
          slot1: "script_push",
          slot2: "script_garbage-jammer",
          slot3: null,
          slot4: null,
          slotSuper: "super_sql-drop"
        }
      };
    }
    if (id === "memberD") {
      return {
        id: "memberD",
        name: "Becka",
        skinId: "mindy",
        class: "disrupt",
        battleCrewSlot: null,
        hp: getCombatantStats({ level }).maxHp,
        equippedAttacks: {
          slot1: "script_push",
          slot2: "script_garbage-jammer",
          slot3: null,
          slot4: null,
          slotSuper: "super_sql-drop"
        }
      };
    }

    if (id === "staticDean") {
      return   {
        "id": "staticDean",
        "isStatic": true,
        "isAvailable": false,
        "name": "Dean",
        "skinId": "dean",
        "class": "support",
        "hp": 50,
        "equippedAttacks": {
          "slot1": "script_pop",
          "slot2": "script_headphones",
          "slot3": "script_bug",
          "slot4": null,
          "slotSuper": null
        }
      }
    }
    if (id === "staticSecurityJack") {
      return   {
        "id": "staticSecurityJack",
        "isStatic": true,
        "isAvailable": false,
        "name": "Security Jack",
        "skinId": "securityJack",
        "class": "dps",
        "hp": 50,
        "equippedAttacks": {
          //TODO NOT REAL CONFIG, THIS FILE IS NOT THE SOURCE OF TRUTH (see DS2017 events)
          "slot1": "script_pop",
          "slot2": "script_headphones",
          "slot3": "script_bug",
          "slot4": null,
          "slotSuper": null
        }
      }
    }
    if (id === "staticFrankie") {
      return   {
        "id": "staticFrankie",
        "isStatic": true,
        "isAvailable": false,
        "name": "Frankie",
        "skinId": "hugo",
        "class": "disrupt",
        "hp": 50,
        "equippedAttacks": {
          //TODO NOT REAL CONFIG, THIS FILE IS NOT THE SOURCE OF TRUTH (see DS2017 events)
          "slot1": "script_pop",
          "slot2": "script_headphones",
          "slot3": "script_bug",
          "slot4": null,
          "slotSuper": null
        }
      }
    }
    if (id === "staticJacob") {
      return   {
        "id": "staticJacob",
        "isStatic": true,
        "isAvailable": false,
        "name": "Jacob",
        "skinId": "jacob",
        "class": "support",
        "hp": 50,
        "equippedAttacks": {
          //TODO NOT REAL CONFIG, THIS FILE IS NOT THE SOURCE OF TRUTH (see DS2017 events)
          "slot1": "script_pop",
          "slot2": "script_headphones",
          "slot3": "script_bug",
          "slot4": null,
          "slotSuper": null
        }
      }
    }
    return {}
  }


  if (process.env.REACT_APP_FIREBASE_CONFIG !== "production") {


    const allItemIds = Object.keys(BattleActions).filter(k => k.startsWith("item_"));
    let allCharacterItems = allItemIds.map((actionId, i) => {
      return {
        actionId,
        instanceId: `devInstanceId_${i}`
      }
    });
    allCharacterItems.push({
      actionId: "item_danger-boost",
      instanceId: "dev-extra"
    })




    if (getQueryVariable("load") === "dev") {
      //modify the payload here with whatever you need for dev

      payload.acquiredMembers = [
        {
          id: "hero",
          name: "Jerry",
          skinId: "f1",
          class: "lead",
          battleCrewSlot: 1,
          hp: getCombatantStats({ level: 5 }).maxHp,
          //hp:10,
          isAlwaysInBattleCrew: true,
          equippedAttacks: {
            slot1: "script_slice",
            slot2: "script_curl",
            slot3: null,
            slot4: null,
            slotSuper: null
          }
          //activeUpgradeId: "upgrade_stealing-turn"
        },
        {
          id: "memberB",
          name: "Jerry2",
          skinId: "hr",
          class: "support",
          battleCrewSlot: 2,
          hp: 40,
          equippedAttacks: {
            slot1: "script_curl",
            slot2: null,
            slot3: null,
            slot4: null,
            slotSuper: null
          },
          activeUpgradeId: "upgrade_stealing-turn"
        },

      ];

      payload.allPlayerAttacks = [
        // ...Object.keys(BattleActions).filter(key => {
        //   return key.startsWith("super_") || key.startsWith("script_")
        // }).map(id => ({actionId: id}))
        { actionId: "script_slice" },
        { actionId: "script_curl" },
        { actionId: "script_push" },
        { actionId: "script_scrum" },
        { actionId: "script_pop" },
        { actionId: "script_ddos" },
        //{ actionId: "script_foreach" },
        // {actionId: "script_headphones"},
        // {actionId: "script_troll"},
        // {actionId: "script_copy-paste"},
        {actionId: "super_team-slice"},
        {actionId: "super_team-revive"},
      ];

      payload.acquiredStoryPoints = [
        "market-JRQ-1",
        //"SP_JRQ_InitPenaQuest"
        //"SP_JRQ_DefeatPena1"
        // "market-JRQ-2",


      ];
      payload.inventory = [
        { actionId: "item_slippery-sticker", isBugged: true, instanceId: "0a" },
        { actionId: "item_battery-pack", isBugged: true, instanceId: "0" },
        { actionId: "item_battery-pack", isBugged: true, instanceId: "1" },
        { actionId: "item_battery-pack", isBugged: true, instanceId: "2" },
        { actionId: "item_heal-lag", instanceId: "3" },
        { actionId: "item_heal-fire", instanceId: "4" },
        { actionId: "item_heal-memory-leak", instanceId: "5" },
        { actionId: "item_heal-nervous", instanceId: "6" },
        { actionId: "item_heal-nervous", instanceId: "7" },
        { actionId: "item_heal-anything", instanceId: "8" },
        { actionId: "item_attack-sticker", instanceId: "9" },
      ];
      payload.currency.credits = 600;
      payload.teamXp = 0;
      payload.allPlayerUpgrades = [ //everything you'd have for 100%ing ch1
        // {libraryId: "upgrade_stealing-turn"},
        // {libraryId: "upgrade_attack-focus"},
        // {libraryId: "upgrade_item-turn"},
        // {libraryId: "upgrade_maximize-loops"},
      ];
    }


    //Define things you've gained
    //ch1
    let attacksFromChapter1 = [
      { actionId: "script_slice" },
      { actionId: "script_curl" },
      { actionId: "script_foreach" },
      { actionId: "script_headphones" },
      { actionId: "script_troll" },
      { actionId: "script_copy-paste" },
      { actionId: "script_power-stalk" },
      { actionId: "super_team-slice" },
    ];
    let upgradesFromChapter1 = [
      { libraryId: "upgrade_stealing-turn" },
      { libraryId: "upgrade_attack-focus" },
      { libraryId: "upgrade_item-turn" },
      { libraryId: "upgrade_maximize-loops" }
    ];
    let storyPointsFromChapter1 = [
      "market-JRQ-1",
      "market-JRQ-2"
    ];

    //ch2
    let attacksFromChapter2 = [
      { actionId: "script_pop"},
      { actionId: "script_scrum"},
      { actionId: "script_intimidate"},
      { actionId: "script_firewall"},
      { actionId: "super_team-clean"},
    ];
    let upgradesFromChapter2 = [
      {libraryId: "upgrade_attack-reduce-defense"}
    ];
    let storyPointsFromChapter2 = [
      "market-MTG-1",
      "market-MTG-2"
    ];

    //ch3
    let attacksFromChapter3 = [
      {actionId: "script_push"},
      {actionId: "script_garbage-jammer"},
      {actionId: "super_sql-drop"},
      {actionId: "super_team-deadline"},
    ];
    let upgradesFromChapter3 = [
    ];
    let storyPointsFromChapter3 = [
      "market-SHAW-1",
    ];

    //ch4
    let attacksFromChapter4 = [
      {actionId: "script_dangerous"},
      {actionId: "script_clean"},
      {actionId: "script_screen-blur"},
      {actionId: "super_rage-quit"},
      {actionId: "super_team-revive"}
    ];
    let upgradesFromChapter4 = [
      {libraryId: "upgrade_defense-lagging"},
      {libraryId: "upgrade_attack-lowhp"},
      {libraryId: "upgrade_recover-focus"},
    ];
    let storyPointsFromChapter4 = [
      "market-TICKETS-1",
      "market-TICKETS-2",
    ];

    //ch5
    let attacksFromChapter5 = [
      {actionId: "script_deadline"},
      {actionId: "script_frail"},
      {actionId: "script_curl-mk-ii"},
      {actionId: "script_thrash"},
      {actionId: "super_team-foreach"},
      {actionId: "super_sudo-damage"},
      {actionId: "super_promise-all"},
    ];
    let upgradesFromChapter5 = [
      {libraryId: "upgrade_no-promises"},
      {libraryId: "upgrade_no-lag"},
      {libraryId: "upgrade_defense"},
    ];
    let storyPointsFromChapter5 = [
      "market-CONF-1",
    ];

    //ch6
    let attacksFromChapter6 = [
      {actionId: "script_slice-mk-ii"},
      {actionId: "script_foreach-mk-ii"},
      {actionId: "super_wildfire"},
      {actionId: "super_intimidate-all"},
    ];
    let upgradesFromChapter6 = [
    ];
    let storyPointsFromChapter6 = [
      "market-HWY-1",
    ];

    ////////////////////////////////////////////////////////
    if (getQueryVariable("load") === "ch2") {
      payload.acquiredMembers = [
        createMember("hero", 6),
        createMember("staticDean", 6),
      ];
      payload.teamXp = 470; //current total of 100%ing ch1
      payload.currency.credits = 100; //spitballing here
      payload.allPlayerAttacks = [ //everything you'd have for 100%ing ch1
        ...attacksFromChapter1
      ];
      payload.allPlayerUpgrades = [ //everything you'd have for 100%ing ch1
        ...upgradesFromChapter1
      ];
      payload.acquiredStoryPoints = [
        ...storyPointsFromChapter1,
        //"SP_001_InitLaptopQuest"
        //"SP_001_GainedEnoughReputation"
        //"SP_001_DeliverMail4"
        //"SP_001_GotAllLaptops"
      ];
    }
    if (getQueryVariable("load") === "ch3") {
      payload.acquiredMembers = [
        createMember("hero", 8),
        createMember("staticDean", 6),
        createMember("memberB", 8),
      ];

      payload.teamXp = 960;
      payload.currency.credits = 250;
      payload.allPlayerAttacks = [
        ...attacksFromChapter1,
        ...attacksFromChapter2,
      ];
      payload.allPlayerUpgrades = [
        ...upgradesFromChapter1,
        ...upgradesFromChapter2,
      ];
      payload.acquiredStoryPoints = [
        ...storyPointsFromChapter1,
        ...storyPointsFromChapter2,
        //"SP_SHAW_FoundFourStatueParts"
      ];
    }

    if (getQueryVariable("load") === "ch4") {
      payload.acquiredMembers = [
        createMember("hero", 12),
        createMember("staticDean", 6),
        createMember("memberB", 12),
        createMember("memberC", 12),
      ];
      payload.teamXp = 2600;
      payload.currency.credits = 250;
      payload.allPlayerAttacks = [
        ...attacksFromChapter1,
        ...attacksFromChapter2,
        ...attacksFromChapter3,
      ];
      payload.allPlayerUpgrades = [
        ...upgradesFromChapter1,
        ...upgradesFromChapter2,
        ...upgradesFromChapter3,
      ];
      payload.acquiredStoryPoints = [
        ...storyPointsFromChapter1,
        ...storyPointsFromChapter2,
        ...storyPointsFromChapter3,
        //"SP_TICKETS_ReceivedCoffeeTicket",
        //"SP_TICKETS_ReceivedTradingTicket",
        //"SP_TICKETS_ReceivedTowerTicket",

      ];
      payload.inventory = [
        ...allCharacterItems
      ]
    }

    if (getQueryVariable("load") === "ch5") {
      payload.acquiredMembers = [
        createMember("hero", 15),
        createMember("staticDean", 6),
        createMember("memberB", 15),
        createMember("memberC", 15),
        createMember("memberD", 15),
      ];
      payload.teamXp = 4000;
      payload.currency.credits = 250;
      payload.allPlayerAttacks = [
        ...attacksFromChapter1,
        ...attacksFromChapter2,
        ...attacksFromChapter3,
        ...attacksFromChapter4,
      ];
      payload.allPlayerUpgrades = [
        ...upgradesFromChapter1,
        ...upgradesFromChapter2,
        ...upgradesFromChapter3,
        ...upgradesFromChapter4,
      ];
      payload.acquiredStoryPoints = [
        ...storyPointsFromChapter1,
        ...storyPointsFromChapter2,
        ...storyPointsFromChapter3,
        ...storyPointsFromChapter4,
        //"SP_CONF_GainFeedingScript"
        //"SP_CONF_SwagAccompliceEscape"
        //"SP_CONF_InitProjector"
        //"SP_CONF_InitMissingSwag"
        // "SP_CONF_SeeRegistration",
        //"SP_CONF_ResolveSwag"
        //"SP_CONF_InitLonely"

      ];
    }

    if (getQueryVariable("load") === "ch6") {
      payload.acquiredMembers = [
        createMember("hero", 15),
        createMember("staticDean", 6),
        createMember("memberB", 15),
        createMember("memberC", 15),
        createMember("memberD", 15),
      ];
      //payload.acquiredMembers[0].hp = -99;

      payload.teamXp = 6500;
      payload.currency.credits = 250;
      payload.allPlayerAttacks = [
        ...attacksFromChapter1,
        ...attacksFromChapter2,
        ...attacksFromChapter3,
        ...attacksFromChapter4,
        ...attacksFromChapter5,
      ];
      payload.allPlayerUpgrades = [
        ...upgradesFromChapter1,
        ...upgradesFromChapter2,
        ...upgradesFromChapter3,
        ...upgradesFromChapter4,
        ...upgradesFromChapter5,
      ];
      payload.acquiredStoryPoints = [
        ...storyPointsFromChapter1,
        ...storyPointsFromChapter2,
        ...storyPointsFromChapter3,
        ...storyPointsFromChapter4,
        ...storyPointsFromChapter5,
      ];
    }
    if (getQueryVariable("load") === "ch7") {
      payload.acquiredMembers = [
        createMember("hero", 15),
        createMember("staticDean", 6),
        createMember("memberB", 15),
        createMember("memberC", 15),
        createMember("memberD", 15),

        createMember("staticSecurityJack", 15),
        createMember("staticFrankie", 15),
        createMember("staticJacob", 15),
      ];
      payload.teamXp = 6500;
      payload.currency.credits = 2000;
      payload.allPlayerAttacks = [
        ...attacksFromChapter1,
        ...attacksFromChapter2,
        ...attacksFromChapter3,
        ...attacksFromChapter4,
        ...attacksFromChapter5,
        ...attacksFromChapter6,
      ];
      payload.allPlayerUpgrades = [
        ...upgradesFromChapter1,
        ...upgradesFromChapter2,
        ...upgradesFromChapter3,
        ...upgradesFromChapter4,
        ...upgradesFromChapter5,
        ...upgradesFromChapter6,
      ];
      payload.acquiredStoryPoints = [
        ...storyPointsFromChapter1,
        ...storyPointsFromChapter2,
        ...storyPointsFromChapter3,
        ...storyPointsFromChapter4,
        ...storyPointsFromChapter5,
        ...storyPointsFromChapter6,
        //"SP_TTB_DefeatRichard1"
        //"SP_TTB_DefeatRichard2"
      ];
    }
  }
  if (getQueryVariable("load") === "end") {
    payload.acquiredMembers = [
      createMember("hero", 15),
      createMember("staticDean", 6),
      createMember("memberB", 15),
      createMember("memberC", 15),
      createMember("memberD", 15),
    ];
  }

  return payload;
}



//Leaving for example for how these work (static and isAvailable)
// {
//   id: "memberC",
//   name: "Emma",
//   skinId: "emmy",
//   class: "disrupt",
//   //battleCrewSlot: 3,
//   hp: getCombatantStats({ level: 8 }).maxHp,
//   equippedAttacks: {
//     slot1: "script_push",
//     slot2: "script_garbage-jammer",
//     slot3: null,
//     slot4: null,
//     slotSuper: "super_sql-drop"
//   }
// },
// {
//   id: "staticDean",
//   isStatic: true,
//   isAvailable: true,
//   name: "Dean",
//   skinId: "dean",
//   class: "disrupt",
//
//   battleCrewSlot: 3,
//   hp: getCombatantStats({ level: 8 }).maxHp,
//   equippedAttacks: {
//     slot1: "script_push",
//     slot2: "script_garbage-jammer",
//     slot3: null,
//     slot4: null,
//     slotSuper: "super_sql-drop"
//   }
// },
// {
//   id: "staticSecurityJack",
//   isStatic: true,
//   isAvailable: false,
//   name: "Security Jack",
//   skinId: "securityJack",
//   class: "disrupt",
//   //battleCrewSlot: 4,
//   hp: getCombatantStats({ level: 8 }).maxHp,
//   equippedAttacks: {
//     slot1: "script_push",
//     slot2: "script_garbage-jammer",
//     slot3: null,
//     slot4: null,
//     slotSuper: "super_sql-drop"
//   }
// }