import React from 'react'
import {waitFrames} from "../../../helpers/waitFrames";
import {audioManager} from "../../../audio/audioManager";

class AnimationLeak extends React.Component {

  state = {
    hasTriggeredAnimation: false
  };

  componentDidMount() {

    const targets = this.props.event.subjects;

    waitFrames(7, () => {
      this.setState({
        hasTriggeredAnimation: true
      }, () => {
        //Start shaking
        audioManager.playSfx(this.props.event.useSfx || "sfx_leak");
        targets.forEach(id => {
          this.props.handleCombatantStateChange([
            {
              combatantId: id,
              absoluteChanges: {
                laptopFlash: "pain",
                animation: "characterShake",
              }
            }
          ])
        });
        //Stop shaking
        waitFrames(20, () => {
          targets.forEach(id => {
            this.props.handleCombatantStateChange([
              {
                combatantId: id,
                absoluteChanges: {
                  laptopFlash: "none",
                  animation: null,
                }
              }
            ]);

            waitFrames(10, () => {this.props.onEventDone()})

          });
        });
      });
    });
  }

  render() {
    return null
  }
}

export default AnimationLeak;