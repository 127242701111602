import {shuffle} from "../../../helpers/shuffle";
import {negativeStatuses} from "../../battle-data/formulas/battleStatuses";

export function getSuperDecision({casterModel, teamMembers, enemies, teamItems, enemyItems}) {

  const mySuperIds = shuffle((casterModel.supers || []).map(s => s.actionId));
  if (!mySuperIds.length) { return null } //We should never even get here, but heyyyy just in case!

  const aliveTargets = enemies.filter(e => e.hp && e.hp > 0);

  //Find the first valid decision
  const superDecision = mySuperIds.map(id => {

    //Single blow
    if (id === "super_sudo-damage") {
      const highestHpEnemy = [...aliveTargets].sort((t1,t2) => t1.hp < t2.hp ? 1 : -1)[0];
      return {
        actionId: id,
        casterId: casterModel.id,
        targetIds: [highestHpEnemy.id],
      }
    }

    //Group offense
    if (id === "super_team-slice" || id === "super_team-foreach") {
      if (aliveTargets.length > 1) {
        return {
          actionId: id,
          casterId: casterModel.id,
          targetIds: aliveTargets.map(t => t.id),
        }
      }
    }

    //Group curse with negative status
    if (id === "super_wildfire" || id === "super_intimidate-all") {
      const normalStatusAliveEnemies = aliveTargets.filter(t => t.status === "normal");
      if (normalStatusAliveEnemies.length > 1) {
        return {
          actionId: id,
          casterId: casterModel.id,
          targetIds: normalStatusAliveEnemies.map(n => n.id),
        }
      }
    }

    const friendlyIds = [casterModel.id, ...teamMembers.map(c => c.id)];
    //Group revive
    if (id === "super_team-revive") {
      const deadAllies = teamMembers.filter(e => e.hp <= 0);
      if (deadAllies.length) {
        return {
          actionId: id,
          casterId: casterModel.id,
          targetIds: deadAllies.map(a => a.id),
        }
      }
    }

    //Group team clean
    if (id === "super_team-clean") {
      const friendsWithNegativeStatuses = [casterModel, ...teamMembers].filter(e => negativeStatuses.includes(e.status));
      if (friendsWithNegativeStatuses.length > 1) {
        return {
          actionId: id,
          casterId: casterModel.id,
          targetIds: friendlyIds,
        }
      }
    }


    if (id === "super_team-deadline") {
      const friendsWithNegativeOrNormalStatuses = [casterModel, ...teamMembers].filter(e => ["normal", ...negativeStatuses].includes(e.status));
      if (friendsWithNegativeOrNormalStatuses.length > 1) {
        return {
          actionId: id,
          casterId: casterModel.id,
          targetIds: friendlyIds,
        }
      }
    }


    if (id === "super_sql-drop") {
      const nonBuggedItemsEnemyItemsCount = enemyItems.filter(i => !i.isBugged).length;
      if (nonBuggedItemsEnemyItemsCount > 0) {
        return {
          actionId: id,
          casterId: casterModel.id,
          targetIds: enemies.map(e => e.id),
        }
      }
    }

    return null
  }).find(d => d);


  return superDecision || null;
}