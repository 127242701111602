import React from 'react'
import {waitFrames} from "../../helpers/waitFrames";

class EventSchedule extends React.Component {

  componentDidMount() {
    const {roundsFromNow, combatantId, event} = this.props.event;
    this.props.handleScheduleEvent(roundsFromNow, combatantId, event);

    //Slight delay so everything gets done
    waitFrames(5, () => {
      this.props.onEventDone()
    })
  }

  render() {
    return (
      null
    );
  }
}

export default EventSchedule;