import React from 'react'
import {getStyle} from "./letters";
import themes from './themes'

export default {
  "0": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M1 0h2M0 1h1M3 1h1M0 2h1M3 2h1" />
          <path stroke={themes[theme].main} d="M0 3h1M3 3h1M0 4h1M3 4h1M0 5h1M3 5h1M1 6h2" />
        </svg>
      )
    }
  },
  "1": {
    width: 3,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 3 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M1 0h1M0 1h2M1 2h1" />
          <path stroke={themes[theme].main} d="M1 3h1M1 4h1M1 5h1M0 6h3" />
        </svg>
      )
    }
  },
  "2": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M1 0h2M0 1h1M3 1h1M3 2h1" />
          <path stroke={themes[theme].main} d="M2 3h1M1 4h1M0 5h1M0 6h4" />
        </svg>
      )
    }
  },
  "3": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M1 0h2M0 1h1M3 1h1M3 2h1" />
          <path stroke={themes[theme].main} d="M2 3h1M3 4h1M0 5h1M3 5h1M1 6h2" />
        </svg>
      )
    }
  },
  "4": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h1M3 0h1M0 1h1M3 1h1M0 2h1M3 2h1" />
          <path stroke={themes[theme].main} d="M1 3h3M3 4h1M3 5h1M3 6h1" />
        </svg>
      )
    }
  },
  "5": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h4M0 1h1M0 2h1" />
          <path stroke={themes[theme].main} d="M0 3h3M3 4h1M3 5h1M0 6h3" />
        </svg>
      )
    }
  },
  "6": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M1 0h2M0 1h1M3 1h1M0 2h1" />
          <path stroke={themes[theme].main} d="M0 3h3M0 4h1M3 4h1M0 5h1M3 5h1M1 6h2" />
        </svg>
      )
    }
  },
  "7": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h4M0 1h1M3 1h1M3 2h1" />
          <path stroke={themes[theme].main} d="M2 3h1M2 4h1M1 5h1M1 6h1" />
        </svg>
      )
    }
  },
  "8": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M1 0h2M0 1h1M3 1h1M0 2h1M3 2h1" />
          <path stroke={themes[theme].main} d="M1 3h2M0 4h1M3 4h1M0 5h1M3 5h1M1 6h2" />
        </svg>
      )
    }
  },
  "9": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M1 0h2M0 1h1M3 1h1M0 2h1M3 2h1" />
          <path stroke={themes[theme].main} d="M1 3h3M3 4h1M3 5h1M3 6h1" />
        </svg>
      )
    }
  }
}