import {DefaultAction, DefaultScript} from "../Action";
import {getLeadEnemy} from "../../../getLeadEnemy";
import { shuffle } from "../../../../helpers/shuffle";

export default {
  ...DefaultAction,
  ...DefaultScript,
  name: "Infest",
  description: "Bug half of all enemy non-bugged items",
  targetingType: "all-enemies",
  icon: "disrupt",
  accuracy: 100,
  categories: ["itemStealingTurn"], //for upgrade

  teamItemMoveMethods: true,

  shouldFailOnTargetTeam({casterModel, friendlyItems, enemyItems}) {

    const allItemsBugged = enemyItems.length && enemyItems.every(i => i.isBugged);

    if (allItemsBugged) {
      return {
        failCode: "all-bugged"
      }
    }
    if (enemyItems.length === 0) {
      return {
        failCode: "no-items"
      }
    }
    return false;
  },

  wholeTeamFailEvent({casterModel, targetModels, failInfo}) {

    if (failInfo.failCode && failInfo.failCode === "all-bugged") {
      return [
        {
          type: "eventBlippy",
          stateChanges: targetModels.map(targetModel => {
            return {
              combatantId: targetModel.id,
              message: {
                text: `ALL ITEMS BUGGED!`
              },
            }
          }),
        },
      ]
    }

    return [
      {
        type: "eventBlippy",
        stateChanges: targetModels.map(targetModel => {
          return {
            combatantId: targetModel.id,
            message: {
              text: `NO ITEMS!`
            },
          }
        }),
      },
    ]
  },

  successEventOnTeam({casterModel, targetModels, friendlyItems, enemyItems}) {

    const instanceIds = enemyItems.filter(i => !i.isBugged ).map(i => i.instanceId); //Get instance Ids that are not bugged

    const halfOfLength = Math.round(instanceIds.length / 2); //Round will force odd numbers up, so 3 options = 2 will get bugged

    const chosenInstanceIds = shuffle(instanceIds).filter((d,i) => i <= halfOfLength-1);

    const enemyTeamName = getLeadEnemy(targetModels).name;

    return [
      {
        type: "eventBlippy",
        subjects: [casterModel.id],
        teamItemChangeInstructions: {
          type: "bug",
          instanceIds: chosenInstanceIds,
        },
        delayFramesAfter: 10
      },
      {
        type: "eventDialogBox",
        textArray: [`Half of the items on ${enemyTeamName}'s team were bugged!`],
        haltQueueUntilDone: true
      },
    ]
  }
}