import React from 'react'
import AnimationGenericCharacter from "./animations/AnimationCharacterJump";
import AnimationSlice from "./animations/AnimationSlice";
import AnimationPush from "./animations/AnimationPush";
import AnimationPop from "./animations/AnimationPop";
import AnimationZap from "./animations/AnimationZap";
import AnimationGenericPain from './animations/AnimationGenericPain'
import AnimationIterate from './animations/AnimationIterate'
import AnimationOpenItem from './animations/AnimationOpenItem'
import AnimationSqlInjection from './animations/AnimationSqlInjection'
import AnimationOpenBuggedItem from "./animations/AnimationOpenBuggedItem";
import AnimationBinary from "./animations/AnimationBinary";
import AnimationHacked from "./animations/AnimationHacked";
import AnimationLeak from './animations/AnimationLeak'
import AnimationMiss from './animations/AnimationMiss'
import AnimationPromiseResolve from './animations/AnimationPromiseResolve'
import AnimationCreatePromise from './animations/AnimationCreatePromise'
import AnimationNervousMistake from './animations/AnimationNervousMistake'
import AnimationSudoDamage from './animations/AnimationSudoDamage'

class EventAnimation extends React.Component {

  render() {

    const {event} = this.props;

    if ([
        "characterJump",
        "characterShake"
      ].includes(event.animationId)) {
      return (
        <AnimationGenericCharacter
          animation={event.animationId}
          {...this.props}
        />
      )
    }

    if (event.animationId === "animationSlice") {
      return <AnimationSlice {...this.props} />
    }
    if (event.animationId === "animationGenericPain") {
      return <AnimationGenericPain {...this.props} />
    }
    if (event.animationId === "animationNervousMistake") {
      return <AnimationNervousMistake {...this.props} />
    }
    if (event.animationId === "animationPush") {
      return <AnimationPush {...this.props} />
    }
    if (event.animationId === "animationPop") {
      return <AnimationPop {...this.props} />
    }
    if (event.animationId === "animationIterate") {
      return <AnimationIterate {...this.props} />
    }
    if (event.animationId === "animationZap") {
      return <AnimationZap {...this.props} />
    }
    if (event.animationId === "animationOpenItem") {
      return <AnimationOpenItem {...this.props} />
    }
    if (event.animationId === "animationOpenBuggedItem") {
      return <AnimationOpenBuggedItem {...this.props} />
    }
    if (event.animationId === "animationSqlInjection") {
      return <AnimationSqlInjection {...this.props} />
    }
    if (event.animationId === "animationBinary") {
      return <AnimationBinary {...this.props} />
    }
    if (event.animationId === "animationHacked") {
      return <AnimationHacked {...this.props} />
    }
    if (event.animationId === "animationLeak") {
      return <AnimationLeak {...this.props} />
    }
    if (event.animationId === "animationMiss") {
      return <AnimationMiss {...this.props} />
    }
    if (event.animationId === "animationPromiseResolve") {
      return <AnimationPromiseResolve {...this.props} />
    }
    if (event.animationId === "animationCreatePromise") {
      return <AnimationCreatePromise {...this.props} />
    }
    if (event.animationId === "animationSudoDamage") {
      return <AnimationSudoDamage {...this.props} />
    }

    return (
      null
    );
  }
}

export default EventAnimation;