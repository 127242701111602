export default {
  "default": {
    upper: "#ffcdcd",
    main: "#fff",
  },
  "dark": {
    upper: "#39393b",
    main: "#39393b"
  },
  "white": {
    upper: "#fff",
    main: "#fff"
  },
  "red": {
    upper: "#ff0022",
    main: "#ff0022"
  },
  "disabled": {
    upper: "#c9c9c9",
    main: "#c9c9c9"
  },
  "staged": {
    upper: "#1bbedb",
    main: "#1bbedb"
  },

  //NOTE: this is new in B7!! Make sure you include this when dropping in to new repo
  "battlePositiveHp": {
    upper: "#057d08",
    main: "#055d21",
  },
  "battleNegativeHp": {
    upper: "#db1a1a",
    main: "#b40932",
  },
  "battleNoEffect": {
    upper: "#101f7a",
    main: "#0f075f",
  },
  "battleNegativeStatus": {
    upper: "#511940",
    main: "#450d34",
  },
  "battlePositiveStatus": {
    upper: "#04b4d3",
    main: "#0883cb",
  },
  "battleDamageBlock": {
    upper: "#639bff",
    main: "#639bff",
  },
  "battleFrailStatus": {
    upper: "#62a91d",
    main: "#42412b",
  },
  "level": {
    upper: "#876b2c",
    inner: "#f4d232",
    main: "#876b2c",
  },
  "deactive-level": {
    upper: "#08152c",
    inner: "#a7aebd",
    main: "#08152c",
  },


  //pause menu:
  "menuBlue": {
    upper: "#0aa1cb",
    main: "#0aa1cb",
  }

}