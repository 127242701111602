import INITIAL_PEOPLE from './INITIAL_PEOPLE'
import INITIAL_TRIGGER_SPACES from './INITIAL_TRIGGER_SPACES'
import MOCK_CHAPTER_JOURNALS from './MOCK_CHAPTER_JOURNALS'

export default {
  chapterId: "devChapter",
  startingMapId: "map1",
  compoundStoryPoints: [
    {
      "gainStoryPoint": "SP_001_GainedEnoughReputation",
      "neededMembers": 2,
      "storyPointMembers": [
        "boobear",
        "somethingelse",
      ],
      "requiredStoryPointMembers": [
        //"somerequirement"
      ]
    },
  ],
  journals: MOCK_CHAPTER_JOURNALS,
  maps: {
    "map1": {
      //image: "/images/png/M-Coffeeshop-1x.png",

      // image: "/images/png/M-Main1.manual.png",
      // mapBackgroundColor: "#8cc8d9",
      // width: 12,
      // height: 14,

      image: "/images/png/OutsideApartment.manual-new-entrance.png",
      mapBackgroundColor: "#8cc8d9",
      width: 22,
      height: 14,


      playerX: 1,
      playerY: 2,

      //mapBackgroundColor: "#8AEFAD",
      mapBattleArenaId: "office",
      walls: [/*"2x2",*/"1x0", "2x0", "3x0"],
      triggers: INITIAL_TRIGGER_SPACES,
      interactives: INITIAL_PEOPLE,
      interactiveActions: {
        "itemBox": [
          {
            id: "scenario1",
            interaction: [
              // {
              //   "type": "characterWalk",
              //   "direction": "UP",
              //   "subjectId": "itemBox",
              // },
              {
                "type": "characterJump",
                "subjectId": "hero",
                postDelayMs: 300,
              },
              {
                type: "textMessage",
                textArrays: [
                  [
                    "** NEW GEAR NOW AVAILABLE IN MARKET! **"
                  ],
                  [
                    "They're going to think twice before crossing you!"
                  ]
                ],
              },
              {
                type: "addStoryPoint",
                addStoryPoint: "marketJRQ-2"
              },
              {
                type: "changeInteractiveProperty",
                personId: "itemBox",
                changes: {
                  isBlinkingVendingMachine: false
                }
              },
            ]
          }
        ]
      }

    },
    "map2": {
      image: "/images/svg/OutdoorTileDemo.svg",
      mapBackgroundColor: "pink",
      width: 9,
      height: 9,
      interactives: [], //INITIAL_PEOPLE,
      triggers: [
        // {
        //   type: "cutscene",
        //   x: 3,
        //   y: 4,
        //   "bypassOnStoryPoints": [],
        //   "requiresStoryPoints": [],
        //   interaction: [
        //     {
        //       "direction": "UP",
        //       "subjectId": "hero",
        //       "type": "characterWalk"
        //     },
        //     {
        //       "subjectTurnsToFacePlayer": "no",
        //       "textArrays": [
        //         [
        //           "** Don't forget your laptop! You need it for work. **"
        //         ]
        //       ],
        //       "type": "textMessage"
        //     },
        //   ]
        // }
      ]
    }
  },
  combatants: {
    "billy": {
      name: "Billy",
      skinId: "bruce",
      attacks: [
        {actionId: "script_bug"},
        {actionId: "script_ddos"},
        {actionId: "script_push"},
      ]
    },
    "greenFloating": {
      name: "Tr!umph",
      skinId: "greenFloating",
      slipperyModifier: 10,
      playerDefeatMessage: "Beh heh heh",
      playerVictoryMessage: "Awwwwwhrug?",
      attacks: [
        {actionId: "script_bug"},
        {actionId: "script_ddos"},
        {actionId: "script_push"},
      ],
      dropsItems: ["item_heal-lag"],
      dropsCredits: 35,
    },
  },
  battles: {
    "dev-1": ["billy:4", "greenFloating:3"],
    "dev-2": [
      // {
      //   base: "billy",
      //   level: 10
      // },
      {
        base: "greenFloating",
        attackModifier: 1,
        hp: 1
        //...anything else you want to spread onto the combatants when battle starts
      }
    ]
  }
}