import React from 'react'
import {getStyle} from "./letters";
import themes from './themes'

export default {
  "A": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg  key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M1 0h2M0 1h1M3 1h1M0 2h1M3 2h1M0 3h4M0 4h1M3 4h1M0 5h1M3 5h1M0 6h1M3 6h1" />
        </svg>
      )
    }
  },
  "B": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 0h3M0 1h1M3 1h1M0 2h1M3 2h1M0 3h3M0 4h1M3 4h1M0 5h1M3 5h1M0 6h3" />
        </svg>
      )
    }
  },
  "C": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M1 0h2M0 1h1M3 1h1M0 2h1M0 3h1M0 4h1M0 5h1M3 5h1M1 6h2" />
        </svg>
      )
    }
  },
  "D": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 0h2M0 1h1M2 1h1M0 2h1M3 2h1M0 3h1M3 3h1M0 4h1M3 4h1M0 5h1M2 5h1M0 6h2" />
        </svg>
      )
    }
  },
  "E": {
    width: 3,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 3 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 0h3M0 1h1M0 2h1M0 3h3M0 4h1M0 5h1M0 6h3" />
        </svg>
      )
    }
  },
  "F": {
    width: 3,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 3 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 0h3M0 1h1M0 2h1M0 3h3M0 4h1M0 5h1M0 6h1" />
        </svg>
      )
    }
  },
  "G": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M1 0h2M0 1h1M3 1h1M0 2h1M0 3h1M0 4h1M2 4h2M0 5h1M3 5h1M1 6h2" />
        </svg>
      )
    }
  },
  "H": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 0h1M3 0h1M0 1h1M3 1h1M0 2h1M3 2h1M0 3h4M0 4h1M3 4h1M0 5h1M3 5h1M0 6h1M3 6h1" />
        </svg>
      )
    }
  },
  "I": {
    width: 3,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 3 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 0h3M1 1h1M1 2h1M1 3h1M1 4h1M1 5h1M0 6h3" />
        </svg>
      )
    }
  },
  "J": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 0h4M2 1h1M2 2h1M2 3h1M2 4h1M0 5h1M2 5h1M1 6h2" />
        </svg>
      )
    }
  },
  "K": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 0h1M3 0h1M0 1h1M3 1h1M0 2h1M2 2h1M0 3h2M0 4h1M2 4h1M0 5h1M3 5h1M0 6h1M3 6h1" />
        </svg>
      )
    }
  },
  "L": {
    width: 3,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 3 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 0h1M0 1h1M0 2h1M0 3h1M0 4h1M0 5h1M0 6h3" />
        </svg>
      )
    }
  },
  "M": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 0h2M3 0h2M0 1h1M2 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M2 3h1M4 3h1M0 4h1M4 4h1M0 5h1M4 5h1M0 6h1M4 6h1" />
        </svg>
      )
    }
  },
  "N": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 0h2M4 0h1M0 1h2M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M2 3h1M4 3h1M0 4h1M3 4h2M0 5h1M3 5h2M0 6h1M4 6h1" />
        </svg>
      )
    }
  },
  "O": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M1 0h2M0 1h1M3 1h1M0 2h1M3 2h1M0 3h1M3 3h1M0 4h1M3 4h1M0 5h1M3 5h1M1 6h2" />
        </svg>
      )
    }
  },
  "P": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 0h3M0 1h1M3 1h1M0 2h1M3 2h1M0 3h1M3 3h1M0 4h3M0 5h1M0 6h1" />
        </svg>
      )
    }
  },
  "Q": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M1 0h2M0 1h1M3 1h1M0 2h1M3 2h1M0 3h1M3 3h1M0 4h1M3 4h1M0 5h1M2 5h1M1 6h1M3 6h1" />
          <path className="Dpad--NoShow" stroke="#9c9da8" d="M3 5h1M2 6h1" />
        </svg>
      )
    }
  },
  "R": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 0h3M0 1h1M3 1h1M0 2h1M3 2h1M0 3h1M3 3h1M0 4h3M0 5h1M3 5h1M0 6h1M3 6h1" />
        </svg>
      )
    }
  },
  "S": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M1 0h2M0 1h1M3 1h1M0 2h1M1 3h2M3 4h1M0 5h1M3 5h1M1 6h2" />
        </svg>
      )
    }
  },
  "T": {
    width: 3,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 3 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 0h3M1 1h1M1 2h1M1 3h1M1 4h1M1 5h1M1 6h1" />
        </svg>
      )
    }
  },
  "U": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 0h1M3 0h1M0 1h1M3 1h1M0 2h1M3 2h1M0 3h1M3 3h1M0 4h1M3 4h1M0 5h1M3 5h1M1 6h2" />
        </svg>
      )
    }
  },
  "V": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 0h1M4 0h1M0 1h1M4 1h1M0 2h1M4 2h1M1 3h1M3 3h1M1 4h1M3 4h1M1 5h1M3 5h1M2 6h1" />
        </svg>
      )
    }
  },
  "W": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 0h1M4 0h1M0 1h1M4 1h1M0 2h1M4 2h1M0 3h1M2 3h1M4 3h1M0 4h1M2 4h1M4 4h1M0 5h1M2 5h1M4 5h1M1 6h1M3 6h1" />
        </svg>
      )
    }
  },
  "X": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 0h1M4 0h1M0 1h1M4 1h1M1 2h1M3 2h1M2 3h1M1 4h1M3 4h1M0 5h1M4 5h1M0 6h1M4 6h1" />
        </svg>
      )
    }
  },
  "Y": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 0h1M4 0h1M0 1h1M4 1h1M0 2h1M4 2h1M1 3h3M2 4h1M2 5h1M2 6h1" />
        </svg>
      )
    }
  },
  "Z": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 0h4M3 1h1M2 2h1M1 3h1M0 4h1M0 5h1M0 6h4" />
        </svg>
      )
    }
  },


}