export function getModifiedBattleCrew(acquiredMembers, setCrewToIds) {
  return acquiredMembers.map(m => {

    //If we fire this but you're dead, recover the hero a little bit so you don't auto start battles dead
    if (m.id === "hero" && m.hp <= 0 ) {
      m.hp = 1;
    }

    const index = setCrewToIds.findIndex(id => id === m.id);
    if (index > -1) {
      return {
        ...m,
        battleCrewSlot: index + 1 //get the 0-base outta there
      }
    }
    return {
      ...m,
      battleCrewSlot: null
    };
  });
}