import bully from '../battle-data/story-battles/bully.decisions'
import gareth from '../battle-data/story-battles/gareth.decisions'
import recruiter from '../battle-data/story-battles/recruiter.decisions'
import serverRoomArena from '../battle-data/story-battles/serverRoomArena.decisions'
import defensegrid from '../battle-data/story-battles/defensegrid.decisions'
import frankie from '../battle-data/story-battles/frankie.decisions'
import judge from '../battle-data/story-battles/judge.decisions'
import friendRecoversOliver from '../battle-data/story-battles/friendRecoversOliver.decision'
import oliver from '../battle-data/story-battles/oliver.decision'
import {zombieHack, zombieLeak} from '../battle-data/story-battles/zombie.decisions'
import {dpsServer, supportServer, disruptServer} from '../battle-data/story-battles/richardServers.decisions'
import {richard2} from '../battle-data/story-battles/richard2.decisions.js'
import securityJack from '../battle-data/story-battles/securityjack.decisions'
import {confBully, confRecruiter} from '../battle-data/story-battles/bully-recruiter.decisions'
import {hackerKing1Decisions, hackerKing2Decisions, hackerKing3Decisions} from "../battle-data/story-battles/hackerKing.decisions";

const scriptedBattles = {
  "bully": bully,
  "gareth": gareth,
  "recruiter": recruiter,
  "serverRoomArena": serverRoomArena,
  securityJack,
  "defensegrid": defensegrid,
  "frankie": frankie,
  "judge": judge,
  "friendRecoversOliver": friendRecoversOliver,
  "oliver": oliver,
  confRecruiter,
  confBully,
  zombieHack,
  zombieLeak,
  dpsServer,
  supportServer,
  disruptServer,
  richard2,
  hackerKing1Decisions,
  hackerKing2Decisions,
  hackerKing3Decisions
  //add more decisions here...
};

export default function(scriptedBattleId, combatants, currentStoryPoints=[], fallbackDecision, roundNumber, subject) {
  const customDecisionFn = scriptedBattles[scriptedBattleId];

  if (!customDecisionFn) {
    return {
      submission: fallbackDecision,
      updatedBattleStoryPoints: currentStoryPoints
    }
  }

  //Run the personality function to get {submission, updatedStoryPoints} back
  //Not all battles have to use all the data points, but we have options here!
  return customDecisionFn({
    combatants,
    roundNumber,
    battleStoryPoints: currentStoryPoints,
    regularDecision: fallbackDecision,
    subject
  });
}