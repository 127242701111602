import React from 'react'
import PixelArtTextLine from "../../pixel-letter-system/PixelArtTextLine";
import RevealingTextContainer from '../../pixel-letter-system/RevealingTextContainer'
import {getLetterStreamFromArray} from "../../pixel-letter-system/getLetterStreamFromArray";
import BoxDiv from '../../interface/BoxDiv'
import BoxDivBody from "../../interface/BoxDivBody";
import {waitFrames} from "../../helpers/waitFrames";
//import {waitFrames} from "../helpers/waitFrames";
import TextLineBlippyIndicator from '../../pixel-letter-system/TextLineBlippyIndicator'

class EventDialogBox extends React.Component {

  render() {
    const {event, pixelSize, onEventDone, positions} = this.props;

    const letterStream = getLetterStreamFromArray(event.textArray, {theme: "dark"});
    //Mark if this event is global (top of screen for everybody) or local to one person.
    const isOnePersonContext = Boolean(event.subject);

    let position = function () {

      if (event.subject) {
        const subjectPosition = positions[event.subject]
        return {
          left: subjectPosition.leftPosition + pixelSize * 16,
          top: subjectPosition.topPosition - pixelSize * 32,
          transform: `translateX(-50%)`
        }
      }
      return {
        left: 0,
        top: 0,
        right: 0,
      }
    }();



    return (
      <div style={{
        position: "absolute",
        zIndex: 6,
        ...position
      }}>
        <RevealingTextContainer
          onDoneRevealing={() => {
            if (isOnePersonContext) {
              waitFrames(62, () => {
                onEventDone();
              })
            }
          }}
          onUserPrompted={() => {
            onEventDone();
          }}
          letterStream={letterStream}
          render={({lettersRevealed, isDoneWorking}) => {
            return (
              <BoxDiv
                pixelSize={pixelSize}
              >
                <BoxDivBody
                  pixelSize={pixelSize}
                  style={{
                    paddingLeft:pixelSize * 8,
                    paddingRight:pixelSize * 8,
                    paddingTop: pixelSize * 4,
                    paddingBottom: pixelSize * 3,
                    position: "relative",
                    display: event.subject ? "flex" : "block" //Trying this again. If you see weird wrapping, welcome back!
                    //width: event.subject ? letterStream.length * (pixelSize * 6) : "auto"
                      //If not a global messages, provide a computed width (by msg length) so no weird wrapping occurs
                  }}>
                  <PixelArtTextLine
                    pixelSize={pixelSize}
                    letterStream={letterStream}
                    useReveal={true}
                    lettersRevealed={lettersRevealed}
                  />
                  {
                    !isOnePersonContext && (
                      <TextLineBlippyIndicator
                        pixelSize={pixelSize}
                        status={
                          function () {
                            if (isDoneWorking) {
                              return "solid";
                            }
                            return null;
                          }()
                        }
                      />
                    )
                  }
                </BoxDivBody>
              </BoxDiv>
            )
          }}
        />
      </div>
    )
  }
}

export default EventDialogBox;