export function getJournalUpdateFromNewStoryPoint(addedStoryPoint="", allStoryPoints=[], journalStories={}) {

  //console.log(journalStories)

  const stories = Object.keys(journalStories).map(key => journalStories[key]);

  const storyWithRelevantEntry = stories.find(story => {
    //console.log(story)
    return story.entries.find(e => e.requiresStoryPoints.includes(addedStoryPoint))
  });

  if (storyWithRelevantEntry) {

    const lastEntry = storyWithRelevantEntry.entries[storyWithRelevantEntry.entries.length - 1];
    const isComplete = lastEntry.requiresStoryPoints.every(sp => allStoryPoints.indexOf(sp) > -1 );

    return {
      name: storyWithRelevantEntry.name,
      isComplete
    }
  }

  return null;
}

