import BattleActions from '../Battle7/battle-data/battle-actions-data'

//Provides sorting order for item ids
const itemSortMap = {
  "item_battery-pack": 1,
  "item_full--battery-pack": 2,
  "item_team-battery-pack": 3,
  "item_danger-boost": 4,
  "item_team-danger-boost": 5,
};

const allItemIds = Object.keys(BattleActions).filter(key => key.startsWith("item_"));

export function getItemSortValue(itemActionId) {

  //Return what the map says
  if (itemSortMap[itemActionId]) {
    return itemSortMap[itemActionId];
  }

  //If no map, use the index of the allItemIds array
  const index = allItemIds.indexOf(itemActionId);
  if (index > -1) {
    return index + 20; //Seed with more than enough space for the map above
  }

  //If somehow this Item key doesn't exist, return a default (this won't happen though)
  return 999;
}
