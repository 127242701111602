import {DefaultAction, DefaultScript} from "../Action";
import {getCombatantHpRaise} from "../../formulas/getCombatantHpRaise";

export default {
  ...DefaultAction,
  ...DefaultScript,

  name: "Inspire",
  description: "Recover some HP for your entire team",
  icon:"support",
  targetingType: "all-friendlies",

  shouldFailOnSingleTarget(casterModel, targetModel) {
    if (targetModel.hp === targetModel.maxHp) {
      return {
        failCode: "full-hp"
      }
    }
    return null;
  },

  successEventsPerAllTargets({casterModel, targetModels, allUpgrades}) {
    return [
      {
        type: "eventBlippy",
        stateChanges: targetModels.map(targetModel => {
          const hpChange = getCombatantHpRaise(targetModel, 75, "percent");
          return {
            combatantId: targetModel.id,
            relativeChanges: {
              hp: hpChange
            },
            message: {
              theme: "battlePositiveHp",
              text: `+${hpChange}HP`
            },
          }
        })
      }
    ]
  }
}