import BattleActions from "../Battle7/battle-data/battle-actions-data";
import Upgrades from "../Battle7/battle-data/Upgrades";

export function getMarketBuyMenu({resourceType, listOfBuyables, credits, allPlayerAttacks, allPlayerUpgrades, inventory}) {

  const flatPlayerAttacks = allPlayerAttacks.map(a => a.actionId);
  const flatPlayerUpgrades = allPlayerUpgrades.map(a => a.libraryId);

  let menu = {
    back: {
      handler() {
        return { type: "GO_BACK" }
      }
    }
  };
  let map = [];

  const options = listOfBuyables.filter(buyable => {
    //Trying filtering out attacks and upgrades you already own
    if (buyable.type === "attack" && flatPlayerAttacks.includes(buyable.id)) {
      return false;
    }
    if (buyable.type === "upgrade" && flatPlayerUpgrades.includes(buyable.id)) {
      return false;
    }

    return buyable.type === resourceType
  }).map(buyable => {



    if (resourceType === "upgrade") {
      const upgrade = Upgrades[buyable.id];
      return {...buyable, name: upgrade.name, description: upgrade.description}
    }
    //assume attack or item
    const action = BattleActions[buyable.id];
    return {...buyable, name: action.name, description: action.description}
  });

  options.forEach((option, index) => {

    let isAlreadyOwned = false;
    if (resourceType === "upgrade" && allPlayerUpgrades.find(u => u.libraryId === option.id)) {
      isAlreadyOwned = true;
    }
    if (resourceType === "attack" && allPlayerAttacks.find(u => u.actionId === option.id)) {
      isAlreadyOwned = true;
    }

    const icon = function() {


      if (isAlreadyOwned) {
        return "check"
      }

      if (resourceType === "upgrade") {
        return "upgrade"
      }


      const action = BattleActions[option.id];
      return action.icon
    }();


    const description = function() {

      //If item, add in how many you already own
      if (resourceType === "item") {
        const numberOwned = inventory.filter(a => a.actionId === option.id).length;
        return option.description + ` (owned: ${numberOwned})`
      }

      //Otherwise, just use the description
      return option.description;
    }();

    const key = `${resourceType}${index}`;
    menu[key] = {
      label: `${option.name}`,
      description,
      icon,
      viewBag: {
        isAlreadyOwned,
        price: option.price,
        isTooExpensive: credits < option.price
      },

      checkUnavailable() {
        return isAlreadyOwned || credits < option.price
      },
      handler() {
        return {
          type: "STAGE_FOR_CONFIRMATION",
          stagedTransaction: {
            ...option,
            icon
          },
        }
      },
      rightHandler() {
        if (resourceType === "attack") {
          return {
            type: "SET_MARKET_MENU_ID",
            marketMenuId: "buyUpgrades"
          }
        }
        if (resourceType === "upgrade") {
          return {
            type: "SET_MARKET_MENU_ID",
            marketMenuId: "buyItems"
          }
        }
      },
      leftHandler() {
        if (resourceType === "item") {
          return {
            type: "SET_MARKET_MENU_ID",
            marketMenuId: "buyUpgrades"
          }
        }
        if (resourceType === "upgrade") {
          return {
            type: "SET_MARKET_MENU_ID",
            marketMenuId: "buyAttacks"
          }
        }
      }
    };
    map.push([key])
  });


  if (map.length === 0) {
    const key = `emptyState`;
    menu[key] = {
      label: `Nothing to buy!`,
      description: "You've acquired everything in this menu!",
      viewBag: {
      },
      handler() {
        //return { type: "GO_BACK" }
      },
      rightHandler() {
        if (resourceType === "attack") {
          return {
            type: "SET_MARKET_MENU_ID",
            marketMenuId: "buyUpgrades"
          }
        }
        if (resourceType === "upgrade") {
          return {
            type: "SET_MARKET_MENU_ID",
            marketMenuId: "buyItems"
          }
        }
      },
      leftHandler() {
        if (resourceType === "item") {
          return {
            type: "SET_MARKET_MENU_ID",
            marketMenuId: "buyUpgrades"
          }
        }
        if (resourceType === "upgrade") {
          return {
            type: "SET_MARKET_MENU_ID",
            marketMenuId: "buyAttacks"
          }
        }
      }
    };
    map.push([key])
  }


  return {
    menu, map, backOptionId: "back"
  }
}



export function getMarketConfirmMenu(stagedTransaction) {

  const goBack = {
    type: "SET_MARKET_MENU_ID",
    marketMenuId: function() {
      if (stagedTransaction.type === "item") { return "buyItems" }
      if (stagedTransaction.type === "upgrade") { return "buyUpgrades" }
      return "buyAttacks"
    }()
  };

  return {
    menu: {
      marketConfirm: {
        label: "Confirm Buy",
        viewBag: {
          price: stagedTransaction.price
        },
        handler() {
          return {
            type: "CONFIRM",
          }
        }
      },
      marketCancel: {
        label: "Cancel",
        handler() {
          return goBack
        }
      },
      back: {
        handler() {
          return goBack
        }
      }
    },
    map: [
      ["marketConfirm"],
      ["marketCancel"],
    ]
  }
}