import React from "react";
import {connect} from "react-redux";
import {setGlobalValue} from "../redux-actions/global-actions.js";
import {audioManager} from "./audioManager";

class AudioControl extends React.Component {

  /* Listen for incoming prop to interact with the audioManager */
  componentDidUpdate() {
    if (this.props.audioMode === "full") {
      //Handle Audio is now ON
      audioManager.changeSongVolume("on");
    } else {
      //Handle Audio is now OFF
      audioManager.changeSongVolume("off");
    }
  }

  /* Toggle handle audio off */
  handleToggleClick = () => {
    const {audioMode} = this.props;
    //const nextMode = this.props.audioMode === "full" ? "off" : "full";
    const nextMode = function() {

      if (audioMode === "off") {
        return "full"
      }
      if (audioMode === "full") {
        return "sfx"
      }
      return "off"
    }();

    setGlobalValue({
      audioMode: nextMode
    });

    //Save this preference in localstorage
    //TODO: NOTE TO SELF - this value is now saved by AudioVolumeControl which also listens to this prop change in redux
    //setAudioPreferenceInLocalstorage(nextMode);

    //Don't allow browser to focus on this button
    this.refs.button.blur();
  };

  render() {
    const {pixelSize, audioVolume} = this.props;

    const showVolumeLevel = this.props.audioMode === "full" || this.props.audioMode === "sfx";

    let buttonComponent = AudioOff(pixelSize);
    if (this.props.audioMode === "full") {
      buttonComponent = AudioOn(pixelSize);
    }
    if (this.props.audioMode === "sfx") {
      buttonComponent = AudioSfxOnly(pixelSize);
    }

    return (
      <a
        href="#audio"
        className="Dpad--NoShow"
        ref="button"
        tabIndex="-1"
        style={{
          position: "relative",
          display: "block",
          outline: 0,
          border: 0,
          WebkitUserSelect: "none"
        }}
        onClick={e => {
          e.preventDefault();
          this.handleToggleClick()
        }}
      >
        {buttonComponent}
        {/* Progress bar */}
        {
          showVolumeLevel && (
            <div
              className="Dpad--NoShow"
              style={{
              position: "absolute",
              right: pixelSize,
              top: pixelSize,
              bottom: pixelSize * 2,
              width: pixelSize,
              background: "#362c3b"
            }}>
              <div
                className="Dpad--NoShow"
                style={{
                position: "absolute", //vertical fill of volume
                right: 0,
                left: 0,
                bottom: 0,
                width: pixelSize,
                height: audioVolume + "%",
                background: "#99e550"
              }}/>
            </div>
          )
        }
        </a>
    );
  }
}

export default connect((state, props) => {
  return {
    audioVolume: state.global.audioVolume,
    pixelSize: state.global.pixelSize,
    audioMode: state.global.audioMode
  };
})(AudioControl);


const AudioOn = (pixelSize) => {
  return (
    <svg style={{width: pixelSize * 15}} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 15 16" shapeRendering="crispEdges">
      <path className="Dpad--NoShow" stroke="#211f33" d="M1 0h14M0 1h1M0 2h1M0 3h1M0 4h1M0 5h1M0 6h1M0 7h1M0 8h1M0 9h1M0 10h1M0 11h1M0 12h1M0 13h1M0 14h1M1 15h14" />
      <path className="Dpad--NoShow" stroke="#ffffff" d="M1 1h11M1 2h2M4 2h2M7 2h2M10 2h2M1 3h11M1 4h11M1 5h6M9 5h3M1 6h6M10 6h2M1 7h6M8 7h4M1 8h6M8 8h4M1 9h4M8 9h4M1 10h3M8 10h4M1 11h3M8 11h4M1 12h4M7 12h5M1 13h11" />
      <path className="Dpad--NoShow" stroke="#27440e" d="M12 1h1M14 1h1M12 2h1M14 2h1M12 3h1M14 3h1M12 4h1M14 4h1M12 5h1M14 5h1M12 6h1M14 6h1M12 7h1M14 7h1M12 8h1M14 8h1M12 9h1M14 9h1M12 10h1M14 10h1M12 11h1M14 11h1M12 12h1M14 12h1M12 13h1M14 13h1M12 14h1M14 14h1" />
      <path className="Dpad--NoShow" stroke="#362c3a" d="M13 1h1M13 2h1M13 3h1M13 4h1M13 5h1M13 6h1M13 7h1M13 8h1M13 9h1M13 10h1M13 11h1M13 12h1M13 13h1M13 14h1" />
      <path className="Dpad--NoShow" stroke="#639bff" d="M3 2h1" />
      <path className="Dpad--NoShow" stroke="#5f5564" d="M6 2h1M9 2h1M7 5h2M7 6h3M7 7h1M7 8h1M5 9h3M4 10h4M4 11h4M5 12h2" />
      <path className="Dpad--NoShow" stroke="#dde6f4" d="M1 14h11" />
    </svg>
  )
};
const AudioOff = (pixelSize) => {
  return (
    <svg style={{width: pixelSize * 15}} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 15 16" shapeRendering="crispEdges">
      <path className="Dpad--NoShow" stroke="#211f33" d="M1 0h14M0 1h1M0 2h1M0 3h1M0 4h1M0 5h1M0 6h1M0 7h1M0 8h1M0 9h1M0 10h1M0 11h1M10 11h5M0 12h1M10 12h1M12 12h1M14 12h1M0 13h1M10 13h2M13 13h2M0 14h1M10 14h1M12 14h1M14 14h1M1 15h14" />
      <path className="Dpad--NoShow" stroke="#abadaf" d="M1 1h11" />
      <path className="Dpad--NoShow" stroke="#242526" d="M12 1h1M14 1h1M12 2h1M14 2h1M12 3h1M14 3h1M12 4h1M14 4h1M12 5h1M14 5h1M12 6h1M14 6h1M12 7h1M14 7h1M12 8h1M14 8h1M12 9h1M14 9h1M12 10h1M14 10h1" />
      <path className="Dpad--NoShow" stroke="#362c3a" d="M13 1h1M13 2h1M13 3h1M13 4h1M13 5h1M13 6h1M13 7h1M13 8h1M13 9h1M13 10h1" />
      <path className="Dpad--NoShow" stroke="#c5c5c5" d="M1 2h2M4 2h2M7 2h2M10 2h2M1 3h11M1 4h11M1 5h6M9 5h3M1 6h6M10 6h2M1 7h6M8 7h4M1 8h6M8 8h4M1 9h4M8 9h4M1 10h3M8 10h4M1 11h3M8 11h2M1 12h4M7 12h3M1 13h9M1 14h9" />
      <path className="Dpad--NoShow" stroke="#5f5564" d="M3 2h1M6 2h1M7 5h2M7 6h3M7 7h1M7 8h1M5 9h3M4 10h4M4 11h4M5 12h2" />
      <path className="Dpad--NoShow" stroke="#639bff" d="M9 2h1" />
      <path className="Dpad--NoShow" stroke="#ef2e31" d="M11 12h1M13 12h1M12 13h1M11 14h1M13 14h1" />
    </svg>
  )
};
const AudioSfxOnly = (pixelSize) => {
  return (
    <svg style={{width: pixelSize * 15}} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 15 16" shapeRendering="crispEdges">
      <path className="Dpad--NoShow" stroke="#211f33" d="M1 0h14M0 1h1M0 2h1M0 3h1M0 4h1M0 5h1M0 6h1M0 7h1M0 8h1M0 9h1M0 10h1M0 11h1M0 12h1M0 13h1M0 14h1M1 15h14" />
      <path className="Dpad--NoShow" stroke="#ffffff" d="M1 1h11M1 2h2M4 2h2M7 2h2M10 2h2M1 3h11M1 4h11M1 5h1M4 5h1M7 5h1M9 5h1M11 5h1M1 6h1M3 6h2M6 6h2M9 6h1M11 6h1M1 7h1M4 7h1M7 7h2M10 7h2M1 8h2M4 8h1M6 8h2M9 8h1M11 8h1M1 9h1M4 9h1M6 9h2M9 9h1M11 9h1M1 10h11M1 11h2M10 11h2M1 12h11M1 13h11" />
      <path className="Dpad--NoShow" stroke="#27440e" d="M12 1h1M14 1h1M12 2h1M14 2h1M12 3h1M14 3h1M12 4h1M14 4h1M12 5h1M14 5h1M12 6h1M14 6h1M12 7h1M14 7h1M12 8h1M14 8h1M12 9h1M14 9h1M12 10h1M14 10h1M12 11h1M14 11h1M12 12h1M14 12h1M12 13h1M14 13h1M12 14h1M14 14h1" />
      <path className="Dpad--NoShow" stroke="#362c3a" d="M13 1h1M13 2h1M13 3h1M13 4h1M13 5h1M13 6h1M13 7h1M13 8h1M13 9h1M13 10h1M13 11h1M13 12h1M13 13h1M13 14h1" />
      <path className="Dpad--NoShow" stroke="#5f5564" d="M3 2h1M9 2h1M2 5h2M5 5h2M8 5h1M10 5h1M2 6h1M5 6h1M8 6h1M10 6h1M2 7h2M5 7h2M9 7h1M3 8h1M5 8h1M8 8h1M10 8h1M2 9h2M5 9h1M8 9h1M10 9h1" />
      <path className="Dpad--NoShow" stroke="#639bff" d="M6 2h1" />
      <path className="Dpad--NoShow" stroke="#b2adb5" d="M3 11h7" />
      <path className="Dpad--NoShow" stroke="#dde6f4" d="M1 14h11" />
    </svg>
  )
};