import React from 'react'
import {waitFrames} from "../helpers/waitFrames";
import Icon from '../interface/Icon'
import BoxDiv from '../interface/BoxDiv'
import BoxDivBody from "../interface/BoxDivBody";
import {audioManager} from "../audio/audioManager";

class BattleResultMessage extends React.Component {

  state = {
    isIn: false,
    mountFader: false
  };

  componentDidMount() {

    waitFrames(20, () => { //this delay allows the characters to turn around

      //Ending music
      const endingSongId = this.props.winningTeam === "one" ? "music_victory" : "music_defeat";
      audioManager.playSong(endingSongId);

      this.setState({isIn: true})

      let framesToWait = 120;
      if (endingSongId === "music_defeat") {
        framesToWait = 190; //Wait longer for defeat music (don't cut it off + extra shame)
      }

      waitFrames(framesToWait, () => {
        this.setState({
          mountFader: true
        })
      })

    })
  }

  render() {

    const {winningTeam, pixelSize} = this.props;
    const endingMessageIconId = winningTeam === "one" ? "battleVictory" : "battleDefeat";


    return (
      <React.Fragment>

        <div style={{
          opacity: this.state.isIn ? 1 : 0,
          fontSize: 50,
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: this.state.isIn ? "translate3d(-50%, -50%, 0)" : "translate3d(-50%, -25%, 0)",
          transition: "opacity 0.2s, transform 0.2s"
        }}>
          <BoxDiv pixelSize={pixelSize}>
            <BoxDivBody
              insetTheme
              pixelSize={pixelSize}
            >
              <div
                style={{
                  padding: pixelSize * 10,
                  paddingTop: pixelSize * 13,
                }}
              >
                <Icon
                  category={endingMessageIconId}
                  pixelSize={pixelSize * 2}
                />
              </div>
            </BoxDivBody>
          </BoxDiv>
        </div>

        {this.state.mountFader && (
          <div
            onAnimationEnd={() => {
              this.props.onDone();
            }}
            style={{
              animation: "battleResultsFadeIn 0.5s steps(4)",
              position:"fixed",
              left:0,
              top:0,
              bottom:0,
              right:0,
              zIndex: 10,
              background:"#fff"
            }}
          />
        )}

      </React.Fragment>
    );
  }
}

export default BattleResultMessage;