//This will take in data from the chapters, I suppose? Need to figure out the flow of where chapter configs live
//It would be good if the chapters ONLY had what they needed. The bridge could handle including the add configs
//Really, I guess there shouldn't be a difference between adds and people.

//TODO:
//This function will live in the game. It's responsible for marrying a battle configArray with a list of combatant configs (dataset)
//The chapter should provide the configArrays and flat list of combatants separately so there are no duplicates in the data.

export function generateBattle(configArray=[], dataset) {

  return configArray.map(c => {
    if (typeof c === "string") {
      //Split to find the level:
      const base = c.split(":")[0];
      const level = c.split(":")[1];
      if (dataset[base]) {
        return {
          ...dataset[base],
          level: Number(level),
        }
      }
    }
    if (c.base && dataset[c.base]) {
      return {
        ...dataset[c.base],
        level: 1,
        ...c,
      }
    }
    return null;
  }).filter(c => c);
}