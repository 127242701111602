import React from 'react'
import FormField from './FormField'
import {getEmailValidationErrors, getNameValidationErrors, getPasswordValidationErrors} from './validations'
import FormSubmitButton from './FormSubmitButton'
import {authCreateAccount} from "./auth-requests";

class CreateAccountForm extends React.Component {

  state = {
    hasSubmitted: false,
    isLocked: false,
    globalErrors: [],
  };

  handleSubmitRequest = () => {

    if (this.state.isLocked) { console.log('locked'); return }

    this.props.setLoading(true);
    this.setState({ isLocked: true });

    authCreateAccount({
      name: this.props.fieldName,
      email: this.props.fieldEmail,
      password: this.props.fieldCreatePassword,

      success: (response) => {
        this.props.setLoading(false);
        this.props.handleChangeViewId("createAccountSuccess");
      },

      fail: (response) => {
        this.setState({
          isLocked: false,
          globalErrors: response.errors //[] whatever was in the response
        });
        this.props.setLoading(false);
      }
    })

  };

  getProblems() {
    let payload = {};
    const {fieldName, fieldEmail, fieldCreatePassword} = this.props;
    [
      { input: fieldName, fn: getNameValidationErrors, key: "name" },
      { input: fieldEmail, fn: getEmailValidationErrors, key: "email" },
      { input: fieldCreatePassword, fn: getPasswordValidationErrors, key: "password" },
    ].forEach(test => {
      const error = test.fn(test.input);
      if (error) {
        payload[test.key] = error;
      }
    });
    return payload;
  }

  render() {
    const {updateFormField, fieldName, fieldEmail, fieldCreatePassword} = this.props;
    const problems = this.getProblems();
    const isFormReady = Object.keys(problems).length === 0;


    return (
      <form
        style={{paddingBottom: 15}}
        onSubmit={(e) => {
          e.preventDefault();
          if (isFormReady) {
            this.handleSubmitRequest();
          } else {
            this.setState({hasSubmitted: true}); //So we can show warnings now
          }
        }}
      >

        <h2 style={{margin:"0 0 1em 0"}}>Create Account</h2>

        <FormField
          showCompletedCheck={true}
          autoFocus={true}
          label="Name"
          autoComplete="name"
          name="create_name"
          error={problems["name"] || null}
          formName="input_create_name"
          value={fieldName}
          onChange={(newValue) => {updateFormField("fieldName", newValue)}}
          hasSubmitted={this.state.hasSubmitted}
        />
        <FormField
          showCompletedCheck={true}
          label="Email Address"
          placeholder="you@email.com"
          name="create_email"
          error={problems["email"] || null}
          formName="input_create_email"
          autoComplete="email"
          type="email"
          value={fieldEmail}
          onChange={(newValue) => {updateFormField("fieldEmail", newValue)}}
          hasSubmitted={this.state.hasSubmitted}
        />
        <FormField
          showCompletedCheck={true}
          label="Create Password"
          name="create_password"
          error={problems["password"] || null}
          formName="input_create_password"
          autoComplete="new-password"
          type="password"
          value={fieldCreatePassword}
          onChange={(newValue) => {updateFormField("fieldCreatePassword", newValue)}}
          renderSubtext={() => {
            return (
              <div style={{fontSize: "0.8em", paddingTop: "0.3em", color: "#7D7A7A"}}>At least 8 characters. Make it good!</div>
            )
          }}
          hasSubmitted={this.state.hasSubmitted}
        />
        <FormSubmitButton
          text="Create Account"
          isDisabled={!isFormReady}
        />
        {
          this.state.globalErrors.length > 0 && (
              this.state.globalErrors.map((error, key) => {
                return <div style={{color: "red", padding: "1em 0 0", textAlign: "center"}} key={key}>{error}</div>
              })
          )
        }
          <p style={{
            position: "absolute",
            left:0,
            right:0,
            bottom:10,
            fontSize:"0.8em",
            textAlign:"center",
            color: "#7D7A7A"
          }}>By creating an account, you agree to our Terms of Service.</p>
      </form>
    );
  }
}

export default CreateAccountForm;