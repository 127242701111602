import {createStore, combineReducers} from 'redux'
import * as globalReducer from '../redux-reducers/global-reducer'
import * as overworldReducer from '../redux-reducers/overworld-reducer'
import * as playerData from '../redux-reducers/player-data-reducer'

export default function (data) {
  let reducer = combineReducers({
    ...overworldReducer,
    ...playerData,
    ...globalReducer
  });

  let store;
  if (typeof window === "object") {
    store = createStore(reducer, data,
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : undefined
    );
  } else {
    store = createStore(reducer, data);
  }

  return store
}
