import React from 'react'
import PixelArtTextLine from './pixel-letter-system/PixelArtTextLine'
import {getLetterStreamFromArray} from "./pixel-letter-system/getLetterStreamFromArray";

class StaticTextLine extends React.PureComponent {

  render() {
    return (
      <PixelArtTextLine
        pixelSize={this.props.pixelSize}
        letterStream={getLetterStreamFromArray([
          this.props.text || ""
        ], {
          theme: this.props.theme || "dark"
        })}
      />
    );
  }
}

export default StaticTextLine;