import BattleActions from '../battle-data/battle-actions-data'

export function getArenaScriptsMenu(combatant, options={}) {
  const actions = options.allActions || BattleActions;

  let menu = {};
  let map = [];

  (combatant.attacks || []).forEach(({actionId}, index) => {

    const key = `attack${index}`;
    const model = actions[actionId];
    menu[key] = {
      label: model.name,
      icon: model.icon,
      description: model.description,
      handler() {
        return {
          stageActionId: actionId,
          goToMenuId: "targeting"
        }
      }
    };
    map.push([key]);
  });

  menu[`back`] = {
    handler() {
      return {
        goToMenuId: "home"
      }
    }
  };

  return {
    menu,
    map,
    backOptionId: "back"
  }

}