import {getCurrentLevelFromXp} from "../getCurrentLevelFromXp";
import Chapters from '../_data/Chapters'

export function getLoadGameMenu({saves, allChapters}) {

  const chapters = allChapters || Chapters;

  let menu = {
    back: {
      label: "Close",
      handler() {
        return {type: "GO_BACK"}
      }
    }
  };
  let map = [];

  if (saves.length) {
    [...saves].sort((a, b) => {
      return a.timestamp > b.timestamp ? -1 : 1
    }).forEach((save, i) => {
      const key = `saveFile${i}`;

      const heroName = save.playerData.acquiredMembers[0].name;
      const skinId = save.playerData.acquiredMembers[0].skinId;
      const currentLevel = getCurrentLevelFromXp(save.playerData.teamXp);
      const chapterInfo = chapters[save.chapterId] || {};
      const chapterTitle = chapterInfo.name || "???";

      menu[key] = {
        handler() {
          return {
            type: "LOAD_GAME",
            savedFile: save
          }
        },
        viewBag: {
          name: heroName,
          level: currentLevel,
          skinId: skinId,
          lastSaved: save.timestamp,
          chapterTitle
        }
      };
      map.push([key]);
    })
  } else {
    //No saves:

    menu["newGame"] = {
      label: "New Game",
      handler() {
        return { type: "NEW_GAME" }
      }
    };

    map = [
      ["newGame"],
      ["back"],
    ]

  }


  return {menu, map, backOptionId: "back"}
}