import {DefaultAction, DefaultScript} from "../Action";
import {getAttackDamage, getStatusRemovingFromDamageSteps} from "../../formulas/getAttackDamage";

export default {
  ...DefaultAction,
  ...DefaultScript,
  name: "Scope Stab",
  description: "Offensive attack that is extra painful against Deadlines",
  icon: "dps",
  accuracy: 96,

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {

    const targetHasDeadline = targetModel.status === "deadline";

    const damage = getAttackDamage(targetHasDeadline ? 16 : 6, casterModel, targetModel, allUpgrades);
    return [
      targetHasDeadline ? {
        type: "eventDialogBox",
        subject: targetModel.id,
        textArray: ["Extra painful scope change!"],
        haltQueueUntilDone: true
      } : null,
      {
        type: "eventAnimation",
        animationId: "animationGenericPain",
        subjects: [targetModel.id],
        delayFramesAfter: 12,
      },
      //Block step
      targetModel.status === "block" ? {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: targetModel.id,
            absoluteChanges: {
              status: "normal"
            },
            message: {
              theme: "battleDamageBlock",
              text: `Damage Absorbed!`
            },
          }
        ],
      } : null,
      {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: targetModel.id,
            relativeChanges: {
              hp: damage
            },
            message: {
              theme: "battleNegativeHp",
              text: `${damage} HP`
            },
          }
        ],
        delayFramesAfter: 60
      },
      ...getStatusRemovingFromDamageSteps(casterModel, targetModel, allUpgrades)
    ].filter(d => d)
  }
}