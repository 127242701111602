import React from 'react'
import {laptopSprites} from './BattleLaptopOpeningAnimation'



export default props => {
  const {pixelSize, direction} = props;

  const styles = {
    container: {
      width: pixelSize * 64,
      transform: "translate(-25%, -25%)",
      overflow: 'hidden',
    },
    svg: {
      display: "block",
      width: pixelSize * 512,
      transform: "translateX(-75%)", //park on the frame that doesn't change
    }
  };

  return (
      <div style={styles.container}>
        {laptopSprites[direction](styles.svg)}
      </div>
  );
}
