import React from 'react';

export default function (props) {
  const {pixelSize} = props;
  return (
    <React.Fragment>
      <div style={{position: "relative"}}>
        <div className="Dpad--NoShow" style={{ height: pixelSize, background: "#d0cfdf" }}/>
        <div className="Dpad--NoShow" style={{ height: pixelSize, background: "#b0adc9" }}/>
        <div className="Dpad--NoShow" style={{ height: pixelSize, background: "#4d4b60" }}/>

        <div className="Dpad--NoShow" style={{ position: "absolute", left: pixelSize, bottom:pixelSize, height:pixelSize, width:pixelSize, background: "#222033"}} />
        <div className="Dpad--NoShow" style={{ position: "absolute", left: pixelSize*3, bottom:pixelSize, height:pixelSize, width:pixelSize, background: "#222033"}} />
        <div className="Dpad--NoShow" style={{ position: "absolute", left: pixelSize*5, bottom:pixelSize, height:pixelSize, width:pixelSize, background: "#222033"}} />
      </div>
    </React.Fragment>
  )
}