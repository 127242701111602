import {DefaultAction, DefaultScript} from "../Action";
import {getAttackDamage} from "../../formulas/getAttackDamage";
import {shuffle} from "../../../../helpers/shuffle";
import {getDangerMeterReset} from "../../formulas/getDangerMeterReset";
import {getGenericFail} from "../../formulas/getGenericFail";

function createPromiseSendingAction(properties) {
  return {
    ...DefaultAction,
    ...DefaultScript,

    name: properties.name,
    description: properties.description,
    icon: properties.icon,
    targetingType: "all-enemies",

    shouldFailOnSingleTarget(casterModel, targetModel) {
      return null;
    },
    successEventsPerAllTargets({casterModel, targetModels, allUpgrades}) {
      return [
        //animation?
        {
          type: "eventAnimation",
          animationId: "animationCreatePromise",
          subjects: [casterModel.id],
          delayFramesAfter: 60,
        },
        {
          type: "eventDialogBox",
          textArray: [properties.message],
          haltQueueUntilDone: true
        },
        properties.resetDangerMeter ? getDangerMeterReset({casterModel}) : null,
        {
          type: "eventSchedule",
          roundsFromNow: 2,
          combatantId: casterModel.id,
          event: {
            actionId: properties.resolveActionId,
            casterId: casterModel.id,
            targetIds: targetModels.map(t => t.id)
          }
        },
      ].filter(d => d)
    }
  }
}

export const Promise = createPromiseSendingAction({
  name: "Promise",
  description: "Send a powerful attack in the air to strike later",
  resolveActionId: "natural_promise-resolve",
  icon: "dps",
  message: "A Promise flies into the air!"
});
export const PromiseAll = createPromiseSendingAction({
  name: "Promise All",
  description: "Send multiple powerful attacks into the air to strike later",
  resolveActionId: "natural_promise-all-resolve",
  icon: "super-dps",
  resetDangerMeter: true,
  message: "Multiple Promises fly into the air!"
});


function createPromiseResolvingAction(properties) {
  return {
    ...DefaultAction,
    targetingType: "all-enemies",


    getUse() {
      return []
    },

    shouldFailOnSingleTarget(casterModel, targetModel, allUpgrades) {

      //Prevent this promise from landing if we have an upgrade for it
      const targetUpgrade = allUpgrades[ targetModel.activeUpgradeId || null ];
      if (targetUpgrade && targetUpgrade.preventsPromises) {
        return {
          failCode: "upgrade",
          failMessage: "Oathbreaker!"
        }
      }

      //Somebody may have died between then and now
      return targetModel.hp <= 0;
    },

    failEventsPerTarget(casterModel, targetModel, failInfo={}) {
      return getGenericFail({targetModel, failInfo})
    },


    successEventsPerAllTargets({casterModel, targetModels, allUpgrades}) {

      //Maybe narrow down to 1 target if this is regular Promise resolve
      const useTargets = properties.selectOneTarget ? [shuffle([...targetModels])[0]] : targetModels;
      const targetsWithBlock = useTargets.filter(c => c.status === "block");

      return [
        {
          type: "eventDialogBox",
          textArray: [useTargets.length === 1 ? "A Promise resolves!" : "Multiple Promises resolve!"],
          haltQueueUntilDone: true
        },

        {
          type: "eventAnimation",
          animationId: "animationPromiseResolve",
          subjects: useTargets.map(t => t.id),
          delayFramesAfter: 20,
        },


        //animation?
        {
          type: "eventBlippy",
          stateChanges: useTargets.map(targetModel => {
            const damage = getAttackDamage(properties.damage, casterModel, targetModel, allUpgrades);
            return {
              combatantId: targetModel.id,
              relativeChanges: {
                hp: damage
              },
              message: {
                theme: "battleNegativeHp",
                text: `${damage} HP`
              },
            }
          }),
          delayFramesAfter: 60
        },
        targetsWithBlock.length ? {
          type: "eventBlippy",
          stateChanges: targetsWithBlock.map(targetModel => {
            return {
              combatantId: targetModel.id,
              absoluteChanges: {
                status: "normal"
              },
              message: {
                theme: "battleDamageBlock",
                text: `Damage Absorbed!`
              },
            }
          }),
          delayFramesAfter: 60
        } : null
      ].filter(d => d)
    }
  }
}

export const NaturalPromiseResolve = createPromiseResolvingAction({
  damage: 10,
  selectOneTarget: true,
});
export const NaturalPromiseAllResolve = createPromiseResolvingAction({
  damage: 15
});