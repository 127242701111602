import React from "react";
import BoxDiv from "./interface/BoxDiv";
import BoxDivTippyTop from "./interface/BoxDivTippyTop";
import BoxDivBody from "./interface/BoxDivBody";
import StaticTextLine from "./StaticTextLine";
import MapPerson from "./Overworld/MapPerson";
import ArrowMenuOption from "./ArrowMenu/ArrowMenuOption";
import ArrowMenu from "./ArrowMenu/ArrowMenu";
import store from './init/store'

class CreatePersonSkinForm extends React.Component {

  handler = (skinId) => {
    this.props.handleSubmission(skinId)
  };

  render() {

    const currentCrewSkins = store.getState().playerData.acquiredMembers.map(m => m.skinId);

    // const menu = {
    //   hank: { handler: () => "hank" },
    //   emmy: { handler: () => "emmy" },
    //   mindy: { handler: () => "mindy" },
    //   zak: { handler: () => "zak" },
    //   sara: { handler: () => "sara" },
    //   shirmond: { handler: () => "shirmond" },
    // };

    let menu = {};
    ["hank", "emmy", "mindy", "zak", "sara", "shirmond", "patty", "kim", "ghostHank", "scaryShirmond", "springGuy", "springGirl"].forEach(skinId => {
      menu[skinId] = {
        checkUnavailable() {
          return currentCrewSkins.includes(skinId)
        },
        handler: () => skinId
      }
    });



    const map = [
      ["hank", "emmy", "mindy", "patty", "ghostHank", "springGuy"],
      ["zak", "sara", "shirmond", "kim", "scaryShirmond", "springGirl"],
    ];

    const {pixelSize, isHero, defaultValue} = this.props;

    return (
      <div style={{display: "flex"}}>
        <BoxDiv pixelSize={pixelSize}>
          <BoxDivTippyTop pixelSize={pixelSize}/>
          <BoxDivBody
            pixelSize={pixelSize}
            style={{
              padding: 4 * pixelSize,
              paddingLeft: pixelSize * 11,
              paddingRight: pixelSize * 11,
              paddingBottom: pixelSize * 8
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: pixelSize * 2
              }}
            >
              <StaticTextLine
                pixelSize={pixelSize}
                text={isHero ? "Select an Appearance" : "Select Friend's Appearance"}
              />
            </div>

            <ArrowMenu
              handler={this.handler}
              initiallySelectedOptionId={defaultValue || null }
              optionsPageId={"skins"}
              optionsMap={map}
              optionModels={menu}
              render={({handlers, selectedOptionId}) => {
                return (
                  <React.Fragment>
                    <div style={{display: "flex", justifyContent: "space-around"}}>
                      <SkinOption isDisabled={menu["hank"].checkUnavailable()} selectedOptionId={selectedOptionId} handlers={handlers} pixelSize={pixelSize} skinId="hank"/>
                      <SkinOption isDisabled={menu["emmy"].checkUnavailable()} selectedOptionId={selectedOptionId} handlers={handlers} pixelSize={pixelSize} skinId="emmy"/>
                      <SkinOption isDisabled={menu["mindy"].checkUnavailable()} selectedOptionId={selectedOptionId} handlers={handlers} pixelSize={pixelSize} skinId="mindy"/>
                      <SkinOption isDisabled={menu["patty"].checkUnavailable()} selectedOptionId={selectedOptionId} handlers={handlers} pixelSize={pixelSize} skinId="patty"/>
                      <SkinOption isDisabled={menu["ghostHank"].checkUnavailable()} selectedOptionId={selectedOptionId} handlers={handlers} pixelSize={pixelSize} skinId="ghostHank"/>
                      <SkinOption isDisabled={menu["springGuy"].checkUnavailable()} selectedOptionId={selectedOptionId} handlers={handlers} pixelSize={pixelSize} skinId="springGuy"/>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-around"}}>
                      <SkinOption isDisabled={menu["zak"].checkUnavailable()} selectedOptionId={selectedOptionId} handlers={handlers} pixelSize={pixelSize} skinId="zak"/>
                      <SkinOption isDisabled={menu["sara"].checkUnavailable()} selectedOptionId={selectedOptionId} handlers={handlers} pixelSize={pixelSize} skinId="sara"/>
                      <SkinOption isDisabled={menu["shirmond"].checkUnavailable()} selectedOptionId={selectedOptionId} handlers={handlers} pixelSize={pixelSize} skinId="shirmond"/>
                      <SkinOption isDisabled={menu["kim"].checkUnavailable()} selectedOptionId={selectedOptionId} handlers={handlers} pixelSize={pixelSize} skinId="kim"/>
                      <SkinOption isDisabled={menu["scaryShirmond"].checkUnavailable()} selectedOptionId={selectedOptionId} handlers={handlers} pixelSize={pixelSize} skinId="scaryShirmond"/>
                      <SkinOption isDisabled={menu["springGirl"].checkUnavailable()} selectedOptionId={selectedOptionId} handlers={handlers} pixelSize={pixelSize} skinId="springGirl"/>
                    </div>
                  </React.Fragment>
                )
              }}
            />
          </BoxDivBody>
        </BoxDiv>
      </div>
    );
  }
}

const SkinOption = ({skinId, pixelSize, handlers, selectedOptionId, isDisabled}) => {
  const isSelected = selectedOptionId === skinId;
  return (
    <ArrowMenuOption id={skinId} handlers={handlers}>
      <div style={{width: pixelSize * 40, margin: pixelSize * 2, opacity: isDisabled ? 0.4 : 1}}>
        <BoxDiv pixelSize={pixelSize}>
          <BoxDivBody
            pixelSize={pixelSize}
            style={{
              position: "relative",
              height: pixelSize * 40
            }}
          >
            <div
              style={{
                position: "absolute",
                left: pixelSize * 4,
                top: pixelSize * 2
              }}
            >
              <MapPerson
                cellSize={pixelSize * 32}
                pixelSize={pixelSize}
                skinId={skinId}
              />
            </div>
          </BoxDivBody>
          {
            isSelected && (
              <div style={{
                position: "absolute",
                left:pixelSize,
                top:pixelSize,
                bottom:pixelSize,
                right:pixelSize,
                boxShadow: `0 0 0 ${pixelSize}px #07bfdd, 0 0 0 ${pixelSize*2}px #7de2f2, 0 0 0 ${pixelSize*3}px #07bfdd`
              }}>
              </div>
            )
          }
        </BoxDiv>
      </div>
    </ArrowMenuOption>
  );
};

/*
        <button onClick={this.props.handleGoBack}>
          BACK
        </button>
        <select value={this.state.selectedSkin} onChange={e => {
          this.setState({
            selectedSkin: e.target.value
          })
        }}>
          {
            skinGroup1.map(s => {
              return <option key={s} value={s}>{s}</option>
            })
          }
        </select>

        <button
          onClick={() => {
            this.props.handleSubmission(this.state.selectedSkin)
          }}
        >
          Submit
        </button>
 */

export default CreatePersonSkinForm;
