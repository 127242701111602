import { shuffle } from "../../../helpers/shuffle";

export function zombieHack({ combatants = [], battleStoryPoints = [], regularDecision, subject }) {
  return doActionOnNormalTarget({
    combatants, battleStoryPoints, regularDecision, subject,
    actionId: "script_hack",
    dialog: "[FAST]KRAWHHHH!",
    normalTargetMinimumCount: 3, //Only do this if 3 or more people are normal
  })
}

export function zombieLeak({ combatants = [], battleStoryPoints = [], regularDecision, subject }) {
  return doActionOnNormalTarget({
    combatants, battleStoryPoints, regularDecision, subject,
    actionId: "script_garbage-jammer",
    dialog: "[FAST]KRAWHHHH!",
    normalTargetMinimumCount: 1, //Only do this if 1 or more people are normal
  })
}

//Shared functionality for picking 1 normal target and affecting them
function doActionOnNormalTarget({ combatants = [], battleStoryPoints = [], regularDecision, subject, actionId, dialog, normalTargetMinimumCount }) {

  //Find an enemy who isn't affected by something
  const enemyIdsWithNormalStatus = combatants.filter(c => c.belongsToTeam === "one" && c.hp > 0 && c.status === "normal").map(c => c.id);

  if (enemyIdsWithNormalStatus.length >= normalTargetMinimumCount) {
    const targetId = shuffle(enemyIdsWithNormalStatus)[0];
    return {
      submission: [
        {
          actionId: "speak",
          casterId: subject.id, //"self"
          targetIds: [subject.id],
          mergeData: {
            dialogContent: [
              dialog
            ]
          }
        },
        {
          actionId: actionId,
          casterId: subject.id,
          targetIds: [targetId]
        }
      ],
      updatedBattleStoryPoints: battleStoryPoints
    };
  }

  //Default to regular
  return {
    submission: regularDecision,
    updatedBattleStoryPoints: battleStoryPoints
  };
}