import {createPrefixedId} from "../helpers/make-id";
import {getCombatantStats} from "./getCombatantStats";
import {shuffle} from "../helpers/shuffle";

const defaultCombatantProperties = {
  class: "dps",
  status: "normal",
  level: 1,
  attackModifier: 0,
  defenseModifier: 0,
  speedModifier: 0,
  slipperyModifier: 0,
  accuracyModifier: 0,
  dangerMeter: 0,
  attacks: [],
  upgrades: [],
};

export function createInitialCombatantFromConfig(combatantConfig) {

  let payload = {
    ...defaultCombatantProperties,
    ...combatantConfig
  };

  const {maxHp, attackStat} = getCombatantStats({level: combatantConfig.level});

  payload.maxHp = maxHp;
  payload.attackStat = attackStat;


  if (typeof payload.useCustomMaxHp === "number") {
    payload.maxHp = payload.useCustomMaxHp;
    // console.log('using custom maxHp', payload.maxHp)
  }

  //Define HP if we didn't specify a starting value
  if (typeof payload.hp !== "number") {
    payload.hp = payload.maxHp
  }


  //warnings here
  if (!payload.id) {
    console.warn('no id provided!', combatantConfig);
  }
  if (!payload.name) {
    console.warn('no name provided!', combatantConfig);
    payload.name = "Some Battler"
  }
  // if (!payload.skinId) {
  //   console.warn('no skinId provided!', combatantConfig);
  //   payload.skinId = "mystery"
  // }

  if (payload.items) {
    delete payload.items //for cleanliness. item arrays are computed outside of these structures
  }


  //Default taunt:
  if (!payload.playerDefeatMessage) {
    payload.playerDefeatMessage = shuffle([
      "I crushed you!",
    ])[0];
  }
  if (!payload.playerVictoryMessage) {
    payload.playerVictoryMessage = shuffle([
      "Ohhh nooo!",
      "How could this be?!",
      "Yeah yeah, whatever.",
    ])[0];
  }

  //Start with first upgrade
  if (payload.upgrades.length && !payload.activeUpgradeId) {
    payload.activeUpgradeId = payload.upgrades[0];
  }

  return payload;
}


export function createInitialBattleItemList(itemsArray=[]) {
  return itemsArray.map(item => {
    return item.instanceId ? item : {
      ...item,
      instanceId: createPrefixedId("item")
    }
  });
}