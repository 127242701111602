export function getPauseHomeMenu({inventory, allPlayerUpgrades, acquiredMembers, isMarketAvailable, isLoggedIn, showUnreadMarketBadge, showUnreadScriptsBadge }) {

  let menu = {};
  let map = [];



  //Crew
  menu.homeCrew = {
    label: "Battle Scripts",
    description: "Assign Scripts to members on your team",
    icon: "scripts",
    viewBag: {
      hasUnreadBadge: showUnreadScriptsBadge
    },
    handler() {
      return {
        type: "CHANGE_PAGE",
        newPage: "crew",
        rememberOptionId: "homeCrew"
      }
    }
  };
  map.push(["homeCrew"]);


  if (acquiredMembers && acquiredMembers.filter(a => a.isAvailable !== false).length > 3) {
    menu.homeCrewLineup = {
      label: "Change Lineup",
      description: "Change the members of your Battle Crew",
      icon: "lead",
      handler() {
        return {
          type: "CHANGE_PAGE",
          newPage: "swap",
          rememberOptionId: "homeCrewLineup"
        }
      }
    };
    map.push(["homeCrewLineup"]);
  }

  //Journal
  menu.homeJournal = {
    label: "Journal",
    description: "Keep track of your tasks",
    icon: "journal-small", //todo
    handler() {
      return {
        type: "CHANGE_PAGE",
        newPage: "journal",
        rememberOptionId: "homeJournal"
      }
    }
  };
  map.push(["homeJournal"]);

  //Inventory
  menu.homeItems = {
    label: "Items",
    icon: "item",
    description: "Items you have",
    checkUnavailable() {
      return inventory.length === 0
    },
    handler() {
      return {
        type: "CHANGE_PAGE",
        newPage: "items",
        rememberOptionId: "homeItems"
      }
    }
  };
  map.push(["homeItems"]);

  //Upgrades (only if you have Upgrades)
  if (allPlayerUpgrades && allPlayerUpgrades.length) {
    menu.homeUpgrades = {
      label: "Upgrades",
      icon: "upgrade",
      description: "See your Laptop Upgrades",
      handler() {
        return {
          type: "CHANGE_PAGE",
          newPage: "upgrades",
          rememberOptionId: "homeUpgrades"
        }
      }
    };
    map.push(["homeUpgrades"]);
  }

  //Market (only if you have something to buy)
  if (isMarketAvailable) {
    menu.homeMarket = {
      label: "Market",
      icon: "credit",
      description: "Buy new gear for your Crew",
      viewBag: {
        hasUnreadBadge: showUnreadMarketBadge
      },
      handler() {
        return {
          type: "CHANGE_PAGE",
          newPage: "market",
          rememberOptionId: "homeMarket"
        }
      }
    };
    map.push(["homeMarket"]);
  }

  //Login to Save (or Load Game)

  if (true || isLoggedIn) { //always do this now. (No more concept of logged In / guests)
    menu.homeSaveGame = {
      label: "Save Game",
      icon: "saveDisk",
      description: "Save current game progress",
      handler() {
        return {
          type: "SAVE_GAME"
        }
      }
    };
    map.push(["homeSaveGame"]);

    menu.homeLoadGame = {
      label: "Load Game",
      icon: "directory",
      description: "Resume previously saved game",
      handler() {
        return {
          type: "CHANGE_PAGE",
          newPage: "loadgame",
          rememberOptionId: "homeLoadGame"
        }
      }
    };
    map.push(["homeLoadGame"]);
  } else {

    // menu.homeLogIn = {
    //   label: "Log In to Save",
    //   icon: "saveDisk",
    //   description: "Log in to save your progress! It's quick, free, and easy.",
    //   handler() {
    //     return {
    //       type: "CHANGE_PAGE",
    //       newPage: "login"
    //     }
    //   }
    // };
    // map.push(["homeLogIn"]);
  }


  menu.homeFeedback = {
    label: "Give Feedback",
    icon: "mail",
    description: "Send feedback to the developers of Danger Crew",
    handler() {
      return {
        type: "CHANGE_PAGE",
        newPage: "feedback"
      }
    }
  };
  map.push(["homeFeedback"]);

  
    menu.homeBackToTitle = {
      label: "Title Screen",
      icon: "bail",
      description: "Go back to Title Screen",
      handler() {
        return {
          type: "CHANGE_PAGE",
          newPage: "backToTitle",
          rememberOptionId: "homeBackToTitle"
        }
      }
    };
    map.push(["homeBackToTitle"]);



  menu.back = {
    handler() {
      return {type: "CLOSE"}
    }
  };


  return {
    menu, map,
    backOptionId: "back"
  }

}