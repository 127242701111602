import Upgrades from "../battle-data/Upgrades";


export function getArenaUpgradesMenu(combatant, options={}) {

  const upgrades = options.allUpgrades || Upgrades;

  let menu = {};
  let map = [];

  (combatant.upgrades || []).forEach((id, index) => {

    const upgrade = upgrades[id];
    const key = `upgrade${index}`;

    const isActivated = id === combatant.activeUpgradeId;
    menu[key] = {
      label: upgrade.name,
      description: upgrade.description,
      icon: isActivated ? "upgrade" : null,
      isActivated,
      handler() {
        return {
          changeCharacterUpgrade: combatant.id,
          newUpgradeId: id,
        }
      }
    };
    map.push([key]);
  });

  menu[`back`] = {
    handler() {
      return {
        goToMenuId: "home"
      }
    }
  };

  return {
    menu,
    map,
    backOptionId: "back"
  }

}