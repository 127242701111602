import BattleActions from "../Battle7/battle-data/battle-actions-data";

export function getCrewMemberMenu({member = {}, allPlayerAttacks}) {

  let menu = {
    back: {
      handler() {
        return {type: "GO_BACK"}
      }
    }
  };
  let map = [];



  //Create options here...
  const {equippedAttacks} = member;
  const flatCharacterActionIds = ["slot1", "slot2", "slot3", "slot4"].map(key => equippedAttacks[key]).filter(d => d);


  const flatAcquiredRegularAttackIdsValidForMember = allPlayerAttacks.map(a => {
    return {...BattleActions[a.actionId], id: a.actionId}
  }).filter(a => {
    //Filter out invalid classes
    if (member.class !== "lead" && a.icon !== member.class) {
      return false;
    }
    return a.id.startsWith("script_")
  });

  //console.log(flatAcquiredRegularAttackIdsValidForMember, flatCharacterActionIds);

  ["slot1", "slot2", "slot3", "slot4"].forEach(slotKey => {
    const optionKey = `member_${member.id}_${slotKey}`;
    const isEmpty = !equippedAttacks[slotKey] || !BattleActions[equippedAttacks[slotKey]];
    const action = BattleActions[equippedAttacks[slotKey]];

    menu[optionKey] = {
      label: isEmpty ? "--" : action.name,
      icon: isEmpty ? null : action.icon,
      description: isEmpty ? "Equip a Script here!" : action.description,

      viewBag: {
        slotKey
      },

      checkUnavailable() {

        if (member.isStatic) {
          return true
        }

        if (flatCharacterActionIds.length >= flatAcquiredRegularAttackIdsValidForMember.length) {
          return true
        }
        return false
      },

      handler() {
        return {
          type: "OPEN_SWAP_MENU",
          slotKey: slotKey,
          currentAction: action
        }
      },
      leftHandler() {
        return {
          type: "PREV_MEMBER"
        }
      },
      rightHandler() {
        return {
          type: "NEXT_MEMBER"
        }
      }
    };
    map.push([optionKey])
  });

  //Maybe add a super slot here... (if you own any supers)
  const supers = allPlayerAttacks.filter(a => a.actionId.startsWith("super_")).filter(a => {
    const model = BattleActions[a.actionId];
    return member.class === "lead" || model.icon.includes(member.class); //Type validity check
  });
  const hasAnySupers = supers.length > 0;

  const equippedSuper = BattleActions[equippedAttacks.slotSuper || null];

  if (hasAnySupers) {
    menu["slotSuper"] = {
      label: equippedSuper ? equippedSuper.name : "No Super!",
      icon: equippedSuper ? equippedSuper.icon : null,
      description: equippedSuper ? equippedSuper.description : "Equip a Super Script here!",

      viewBag: {
        slotKey: "slotSuper"
      },

      checkUnavailable() {

        if (member.isStatic) {
          return true
        }

        return equippedSuper && supers.length === 1 //disable if we already have the only 1 equipped
      },

      handler() {
        return {
          type: "OPEN_SWAP_MENU",
          slotKey: "slotSuper",
          currentAction: equippedSuper || null
        }
      },
      leftHandler() {
        return {
          type: "PREV_MEMBER"
        }
      },
      rightHandler() {
        return {
          type: "NEXT_MEMBER"
        }
      }
    };
    map.push(["slotSuper"])
  }
  return {menu, map, backOptionId: "back"}
}