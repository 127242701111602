import React from "react";
import Icon from '../interface/Icon'

class NpcArrow extends React.PureComponent {

  constructor() {
    super();
    this.hasEverBeenWalking = false;
  }

  render() {


    const {pixelSize, x,y, cellSize, isHostile, hasQuestIndicator, skinId, currentActionType} = this.props;


    //The arrow doesn't update with translate values as people walk, so please take this away when somebody mounts.
    if (currentActionType === "characterWalk") {
      this.hasEverBeenWalking = true;
    }
    if (this.hasEverBeenWalking) { return null }
    //This fix is obviously kinda hacky. Would be better if it just followed the person?



    this.noQuestIndicator = [
      "attackPickup", "creditsPickup", "itemBox", "upgradeBox",
      "vendingMachine", "friendlyLaptop", "charger", "bear", "switchControl",
      "hrNoArrow" //Feels funny during onboarding
    ].includes(skinId || "");

    if (this.noQuestIndicator) { return null;}


    let goldArrowTopPosition = 0;
    if (skinId === "evilLaptop") { goldArrowTopPosition = -15; }
    if (skinId === "socks") { goldArrowTopPosition = -12; }
    if (skinId === "pencilItemBox") { goldArrowTopPosition = -14; }
    if (skinId === "itemBoxValidGoldArrow") { goldArrowTopPosition = -16; }
    if (skinId === "swagBag") { goldArrowTopPosition = -12; }
    if (skinId === "statuePartsA") { goldArrowTopPosition = -16; }
    if (skinId === "statuePartsB") { goldArrowTopPosition = -16; }


    let icon = null;
    if (isHostile) { icon = "hostileIndicator"}
    if (hasQuestIndicator) { icon = "questIndicator"}

    return (
        <div style={{
          zIndex: 1,
          animation: currentActionType === "characterJump" ? "characterJump 0.3s" : "none",
          // background: function() {
          //
          //   if (isHostile) {
          //     return "red"
          //   }
          //   if (hasQuestIndicator) {
          //     return "gold"
          //   }
          //
          //   return null;
          // }(),
          width: cellSize,
          height: cellSize,
          position: "absolute",
          pointerEvents: "none",
          left: x * cellSize,
          top: y * cellSize - (cellSize) - (goldArrowTopPosition * pixelSize),
          transition: "opacity 0.3s",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <div style={{
            animation: "iconFloat 0.8s linear infinite alternate-reverse",
          }}>
            <Icon pixelSize={pixelSize} category={icon} />
          </div>
        </div>
    )
  }
}


export default NpcArrow;