import store from '../init/store'
let firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');
//Send generic events up to firebase so we can analyze behavior and make the game better

export function handleTrackEvent(properties) {

  if (!properties.eventType) {
    console.warn("no event type provided", properties);
    return
  }

  //Send behavior data up to Firebase
  const user = firebase.auth().currentUser;
  if (user){

    const hero = (store.getState().playerData.acquiredMembers || []).find(m => m.id === "hero");
    let heroName = hero.name || null;
    let heroSkinId = hero.skinId || null;

    const { eventSessionId, version } = store.getState().global;

    firebase.database().ref(`/events`).push({
      eventSessionId,
      gameVersionId: version,
      userId: user.isAnonymous ? null : user.uid,

      heroName, heroSkinId,

      timestamp: Date.now(),
      isElectron: process.env.REACT_APP_ELECTRON_BUILD || false,
      currentMapId: store.getState().overworld.currentMapId,
      ...properties
    })
  }
}
