//Wait frames before doing something.
export function waitFrames(frameCount=1, callback) {

  let promise = new Promise((resolve, reject) => {
    let waited = 0;
    let step = () => {
      waited+=1;

      if (waited >= frameCount) {
        //callback();
        resolve();
      } else {
        requestAnimationFrame(step);
      }
    };
    requestAnimationFrame(step);
  });

  promise.then(callback)

  // let waited = 0;
  // let step = () => {
  //   waited+=1;
  //
  //   if (waited >= frameCount) {
  //     callback();
  //   } else {
  //     requestAnimationFrame(step);
  //   }
  // };
  // requestAnimationFrame(step);

}