import React from "react";
import { connect } from "react-redux";
import FormSubmitButton from "../Auth/FormSubmitButton";
import { createSingleKeyPressBinding } from "../helpers/createSingleKeyPressBinding";
let firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');


class PauseFeedback extends React.Component {

  state = {
    isThankYouState: false
  };

  componentDidMount() {
    this.closeKeyHandler = createSingleKeyPressBinding([27], () => {
      this.props.handleGoBack()
    });
  }

  componentWillUnmount() {
    let el = document.getElementsByTagName("body")[0];
    [...this.closeKeyHandler].forEach(b => {
      el.removeEventListener("keydown", b.keyDownHandler);
      el.removeEventListener("keyup", b.keyUpHandler);
    });
  }

  renderThankYou() {
    return (
      <div>
        <p>Thank you for the feedback!</p>
        <button style={{
          cursor: "pointer",
          display: "block",
          height: 44,
          width: "100%",
          padding:0,
          fontFamily: "inherit",
          fontWeight: 700,
          fontSize: 16,
          border:0,
          color: "#fff",
          background: "#4A90E2",
          borderBottom: "4px solid #2753CD"
        }}
        onClick={() => {
          this.props.handleGoBack()
        }}
        >
          Back to Game
        </button>
      </div>
    )
  }

  handleSubmit = () => {


    const value = document.querySelector("#feedbackInput").value.trim();
    if (value.length <= 0) {
      return;
    }

    //console.log('submitting', value)

    //Write to Firebase
    //Send behavior data up to Firebase
    const user = firebase.auth().currentUser;
    if (user){
      firebase.database().ref(`/feedback`).push({
        message: value,
        userId: user.isAnonymous ? null : user.uid,
        timestamp: Date.now(),
        currentMapId: this.props.currentMapId
      })
    }

    this.setState({
      isThankYouState: true
    })

  };

  renderForm() {
    return (
      <form onSubmit={(e) => {
        e.preventDefault();
        this.handleSubmit()
      }}>
        <h2 style={{ margin: "0 0 1em 0" }}>Send Feedback</h2>
        <p>
          Your feedback helps us make Danger Crew better for everybody else.
        </p>
        <p>
          Having a great time? Having a terrible time? Tell us whatever you
          want here. If you're open to us following up with you, please include your email address.
        </p>
        <label
          htmlFor="feedbackInput"
          style={{
            display: "block",
            fontFamily: "inherit",
          }}
        >
          Feedback
        </label>
        <textarea
          id="feedbackInput"
          style={{
            display: "block",
            width: "100%",
            padding: 4,
            fontSize: 16,
            marginBottom: "1em"
          }}
          maxLength={700}
          rows={6}
          autoFocus={true}
        />
        <FormSubmitButton
          text="Send"
          isDisabled={false}
        />
      </form>
    )
  }

  render() {
    return (
      <div
        style={{
          width: 500,
          marginLeft: -250,
          position: "absolute",
          left: "50%",
          top: 0,
          padding: 50,
          paddingTop: 40,
          background: "#F9F8F8",
          fontFamily: "'Dosis', sans-serif",
          fontSize: 16,
          fontWeight: 500,
          boxShadow: "0 2px 4px 0 rgba(0,0,0,0.30)"
        }}
      >

        <a
          href="#close"
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            paddingLeft: 10,
            textDecoration: "none",
            fontSize: 35,
            lineHeight: "35px",
            display: "block",
            color: "rgba(0,0,0.8)"
          }}
          onClick={e => {
            e.preventDefault();
            this.props.handleGoBack()
          }}
        >
          &times;
        </a>

        {this.state.isThankYouState ? this.renderThankYou() : this.renderForm()}
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    pixelSize: state.global.pixelSize,
    currentMapId: state.overworld.currentMapId
  };
})(PauseFeedback);
