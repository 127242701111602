import { setOverworldValue } from "../redux-actions/overworld-actions";
import { getNextExternalEventTrigger } from "../Overworld/getIncrExternalEventTrigger";
import { audioManager } from "../audio/audioManager";

export function handleUnpause() {

  audioManager.playSfx("sfx_nextPage"); //SFX for closing the menu

  setOverworldValue({
    isPauseMenuOpen: false,
    externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-UNPAUSE")
  });
}