/** XP required to hit each level.
 * Index = the level number. Level 3: LevelMap[3] = 48 xp.
 */
export default [
  0, //resets the Array
  0, //Level 1
  20, //Level 2
  60, //Level 3
  180, //Level 4
  310, //Level 5

  // * Chapter 2ish *
  450, //Level 6
  650, //Level 7
  900, //Level 8
  1200, //Level 9

  // * Chapter 3ish *
  1550, //Level 10
  1950, //Level 11
  2400, //Level 12

  //Chapter 4ish
  2900, //Level 13
  3450, //Level 14

  //Chapter 5ish
  4000, //Level 15
  4600, //Level 16
  5250, //Level 17
  6000, //Level 18

  //Chapter 6ish
  6750, //Level 19
  7500, //Level 20

  //Chapter 7ish
  8500, //Level 21
  10000, //Level 22
  12000, //Level 23

  14500, //Level 24
  17500, //Level 25

  //TODO: these all need to be edited from here of course. Super high numbers to prove a point
  96500, //Level 26
  97020, //Level 27
  97560, //Level 28
  98120, //Level 29
  98700, //Level 30

  99300, //Level 31 ?

  9999999, //Level 32 ? Bonus to never reach this :) (I don't think we need this)
]


