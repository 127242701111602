import { shuffle } from "../../../helpers/shuffle";

export function richard2({ combatants = [], battleStoryPoints = [], regularDecision, subject, actionId, dialog }) {

  const updatedBattleStoryPoints = [...battleStoryPoints];
  const aliveEnemies = combatants.filter(c => c.belongsToTeam === "one" && c.hp > 0);


  //Clear FIRE if he has one
  if (subject.status === "fire") {
    return {
      submission: [
        {
          actionId: "script_clean",
          casterId: subject.id,
          targetIds: [subject.id],
        }
      ],
      updatedBattleStoryPoints: updatedBattleStoryPoints
    }
  }


  if (aliveEnemies.find(c => c.status === "deadline") && !updatedBattleStoryPoints.includes("r2--deadline-is-fair")) {
    updatedBattleStoryPoints.push("r2--deadline-is-fair")
  }

  //Use wildfire + deadline if he hasn't already
  if (updatedBattleStoryPoints.includes("r2--deadline-is-fair") && !updatedBattleStoryPoints.includes("r2--deadline-combo")) {
    updatedBattleStoryPoints.push("r2--deadline-combo");

    const enemiesWithStatus = combatants.filter(c => c.belongsToTeam === "one" && c.hp > 0 && c.status !== "normal");

    return {
      submission: [
        {
          actionId: "speak",
          casterId: subject.id,
          targetIds: [subject.id],
          mergeData: {
            dialogContent: [
              "Your silly Deadline means nothing to me!"
            ]
          }
        },

        //clear any enemies that have status so the Wildfire will work on them
        enemiesWithStatus.length ? {
          actionId: "script_clean",
          casterId: subject.id,
          targetIds: enemiesWithStatus.map(c => c.id)
        } : null,
        {
          actionId: "super_wildfire",
          casterId: subject.id,
          targetIds: combatants.filter(c => c.belongsToTeam === "one" && c.hp > 0).map(c => c.id)
        },

        //clear any status he has so Deadline works as expected
        subject.status !== "normal" ? {
          actionId: "script_clean",
          casterId: subject.id,
          targetIds: [subject.id],
        } : null,
        {
          actionId: "script_deadline",
          casterId: subject.id,
          targetIds: [subject.id],
        },
      ].filter(d => d),
      updatedBattleStoryPoints: updatedBattleStoryPoints
    }
  }

  const allAlivePlayers = combatants.filter(c => c.belongsToTeam === "one" && c.hp > 0);
  if (allAlivePlayers.length > 1 && shuffle([true, false, false])[0]) {
    return {
      submission: {
        actionId: "script_binary",
        casterId: subject.id,
        targetIds: allAlivePlayers.map(c => c.id),
      },
      updatedBattleStoryPoints: updatedBattleStoryPoints
    }
  }



  const enemiesWithNormalStatus = allAlivePlayers.filter(c => c.status === "normal");
  if (enemiesWithNormalStatus.length >= 2 ) {
    return {
      submission: {
        actionId: "super_intimidate-all",
        casterId: subject.id,
        targetIds: allAlivePlayers.map(c => c.id),
      },
      updatedBattleStoryPoints: updatedBattleStoryPoints
    }
  }



  // const aliveEnemyIds = combatants.filter(c => c.belongsToTeam === "one" && c.hp > 0).map(c => c.id);
  // const randomSingleTargetId = shuffle(aliveEnemyIds)[0];





  return {
    submission: regularDecision,
    updatedBattleStoryPoints: battleStoryPoints
  }
}