import React from 'react'
import BattleActions from '../battle-data/battle-actions-data'

const itemIds = Object.keys(BattleActions).filter(k => k.startsWith("item_"));

class BattlerItemPopup extends React.Component {


  handleAddNewItem = () => {
    this.props.handleNewList([
      ...this.props.items,
      {
        actionId: itemIds[0],
        isBugged: "no"
      }
    ])
  }

  handleRemoveItem = index => {
    this.props.handleNewList(
      this.props.items.filter((item, i) => i !== index)
    )
  }

  handleEditItemList(index, key, value) {
    let newList = [...this.props.items];
    newList[index][key] = value;
    this.props.handleNewList(newList)
  }





  renderItemDropdown(index) {
    const {items} = this.props;

    const model = items[index];

    return (
      <React.Fragment>
        <select value={model.actionId} onChange={e => { this.handleEditItemList(index, "actionId", e.target.value) }}>
          {
            itemIds.map(id => (
              <option key={id}>{id}</option>
              )
            )
          }
        </select>
        <select value={model.isBugged} onChange={e => { this.handleEditItemList(index, "isBugged", e.target.value) }}>
          <option value="no">Not Bugged</option>
          <option value="yes">Bugged!!!</option>
        </select>
      </React.Fragment>
    )

  }

  render() {

    const {items} = this.props;

    return (
      <div style={{
        position: "absolute",
        left:0,
        top:0,
        right:0,
        bottom:0,
        background: "rgba(255,255,255,0.8)",
        zIndex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <div style={{
          background: "#fff",
          border: "1px solid #ddd",
          padding: "1em",
          position: "relative"
        }}>
          <div style={{ position: "absolute", right: 0, top: 0}}>
            <button
              onClick={() => this.props.handleCloseItemEditor()}
            >
              CLOSE
            </button>
          </div>
          <h4>Editing Items</h4>
          {
            items.length > 0 && (
              <ul style={{
                listStyleType: "none",
                padding: 0
              }}>
                {
                  items.map((item, i) => {
                    return (
                      <li key={i} style={{padding: 5}}>
                        {this.renderItemDropdown(i)}
                        <span style={{paddingLeft: 15}}>
                          <button onClick={() => this.handleRemoveItem(i)}>
                            &times;
                          </button>
                        </span>
                      </li>
                    )
                  })
                }
              </ul>
            )
          }
          {
            items.length === 0 && (
              <div>No items!</div>
            )
          }
          <div>
            <button onClick={this.handleAddNewItem}>
              Add Item
            </button>
          </div>
        </div>

      </div>
    );
  }
}

export default BattlerItemPopup;