import {hasAllRequiredStoryPoints, hasInvalidStoryPoints} from "./story-point-validity-checks";

export function getRelevantInteractiveScenario(scenarioList=[], acquiredStoryPoints=[]) {

  const match = scenarioList.find(scenario => {

    scenario.bypassOnStoryPoints = scenario.bypassOnStoryPoints || [];
    scenario.requiresStoryPoints = scenario.requiresStoryPoints || [];

    if (hasInvalidStoryPoints(acquiredStoryPoints, scenario.bypassOnStoryPoints)) {
      return false;
    }
    if (!hasAllRequiredStoryPoints(acquiredStoryPoints, scenario.requiresStoryPoints)) {
      return false;
    }
    return true;
  });


  return match || null
}