import React from 'react'
import {waitFrames} from "../helpers/waitFrames";
import {audioManager} from "../audio/audioManager";
//import Icon from '../DO_NOT_MODIFY_FROM_perf-overworld/Icon'

class ArenaLaptop extends React.PureComponent {

  state = {
    renderExplodingLaptop: false,
    explode: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.hp > 0 && this.props.hp <= 0) {
      //Mount the animation
      waitFrames(28, () => {
        this.setState({
          renderExplodingLaptop: true
        });
      });
      //A few frames later, toggle the exploding animation
      waitFrames(35, () => {
        this.setState({
          explode: true
        });
        waitFrames(5, () => {
          audioManager.playSfx("sfx_laptopExplode");
        })
      })
    }

    if (prevProps.hp <= 0 && this.props.hp > 0) {
      //console.log('revive!')
      this.setState({
        renderExplodingLaptop: false,
        explode: false
      })
    }



  }

  render() {
    const {pixelSize, belongsToTeam, laptopFlash, isInvisible} = this.props;
    const {explode, renderExplodingLaptop} = this.state;

    if (renderExplodingLaptop) {
      return (
        <div style={{
          width: 32 * pixelSize,
          height: 32 * pixelSize,
          overflow: "hidden",
          position: "absolute",
          left: 0,
          opacity: isInvisible ? 0 : 1,
          top: pixelSize,
        }}>
          <svg style={{
            width: pixelSize * 224,
            animation: explode ? `svg_battleLaptopExplode 0.6s forwards steps(6)` : "none"
          }} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 224 32" shapeRendering="crispEdges">
            <style>
              {`

            @keyframes svg_battleLaptopExplode {
                  0% { transform: translate3d(0,0,0) }
                  100% { transform: translate3d(-87.5%,0,0) }
            }
        `}
            </style>
            <path stroke="#cbdbfc"
                  d="M99 2h2M99 3h3M100 4h3M101 5h1M125 8h2M123 9h2M122 10h2M68 14h1M68 15h3M91 15h1M69 16h3M89 16h2M69 17h5M88 17h2M96 17h4M70 18h7M86 18h3M97 18h4M70 19h7M86 19h2M71 20h4M86 20h1M70 21h6M69 22h5M68 23h4M67 24h3M66 25h2"/>
            <path stroke="#586366"
                  d="M148 7h2M148 8h2M117 12h2M117 13h2M135 13h1M107 14h1M134 14h2M106 15h2M133 15h3M105 16h3M133 16h2M105 17h2M11 18h10M78 18h7M115 18h5M150 18h5M11 19h10M78 19h7M114 19h6M149 19h6M11 20h4M17 20h4M77 20h2M81 20h4M114 20h1M117 20h3M149 20h1M152 20h3M11 21h10M77 21h8M103 21h1M105 21h1M114 21h6M149 21h6M11 22h10M77 22h8M102 22h4M115 22h5M150 22h5M11 24h3M15 24h1M17 24h4M75 24h3M79 24h1M81 24h4M133 24h1M135 24h1M185 24h3M11 25h10M75 25h10M109 25h2M132 25h4M185 25h3M116 26h1M141 26h2M186 26h1M115 27h2M149 28h1M181 28h1M148 29h2M180 29h2"/>
            <path stroke="#101016"
                  d="M116 11h3M116 12h1M119 12h1M107 13h1M116 13h1M119 13h1M106 14h1M108 14h1M116 14h4M105 15h1M108 15h1M104 16h1M108 16h1M11 17h10M75 17h10M104 17h1M107 17h1M115 17h5M150 17h5M10 18h1M21 18h1M77 18h1M85 18h1M105 18h2M114 18h1M120 18h1M149 18h1M155 18h1M10 19h1M21 19h1M77 19h1M85 19h1M113 19h1M120 19h1M148 19h1M155 19h1M10 20h1M21 20h1M75 20h2M85 20h1M103 20h5M113 20h1M120 20h1M148 20h1M155 20h1M10 21h1M21 21h1M76 21h1M85 21h1M102 21h1M107 21h1M113 21h1M120 21h1M148 21h1M155 21h1M10 22h1M21 22h1M74 22h3M85 22h1M101 22h1M106 22h1M114 22h1M120 22h1M149 22h1M155 22h1M11 23h10M75 23h10M102 23h4M115 23h5M150 23h5M185 23h3M10 24h1M21 24h1M74 24h1M85 24h1M108 24h3M184 24h1M188 24h1M10 25h1M21 25h1M74 25h1M85 25h1M108 25h1M111 25h1M116 25h2M184 25h1M188 25h1M11 26h10M75 26h10M108 26h4M115 26h1M117 26h1M184 26h2M187 26h2M114 27h1M117 27h1M186 27h2M114 28h4"/>
            <path stroke="#222034"
                  d="M109 14h5M109 15h7M109 16h8M108 17h7M107 18h7M104 19h9M108 20h5M108 21h5M107 22h7M106 23h9M105 24h3M111 24h7M105 25h3M112 25h4M106 26h2M112 26h3M107 27h7M109 28h5"/>
            <path stroke="#f82727"
                  d="M43 17h10M42 18h1M53 18h1M42 19h1M53 19h1M42 20h1M53 20h1M42 21h1M53 21h1M42 22h1M53 22h1M43 23h10M42 24h1M53 24h1M42 25h1M53 25h1M43 26h10"/>
            <path stroke="#edf3ff"
                  d="M43 18h10M43 19h10M43 20h4M49 20h4M43 21h10M43 22h10M43 24h3M47 24h1M49 24h4M43 25h10"/>
            <path stroke="#515e62" d="M15 20h2M47 20h2M79 20h2M115 20h2M150 20h2"/>
            <path stroke="#707b7f"
                  d="M104 21h1M106 21h1M14 24h1M16 24h1M46 24h1M48 24h1M78 24h1M80 24h1M134 24h1M136 24h1"/>
          </svg>
        </div>
      );
    }



    let animationStyleRule = {};
    if (laptopFlash === "pain") {
      animationStyleRule = {animation: "svg_laptopPain 0.3s steps(1) infinite"};
    }

    return (
      <div style={{
        position: "absolute",
        opacity: isInvisible ? 0 : 1,
        left: belongsToTeam === "one" ? pixelSize * 3 : pixelSize * 10,
        top: belongsToTeam === "one" ? pixelSize * 17 : pixelSize * 19,
        zIndex: belongsToTeam === "one" ? -1 : 3
      }}>
        <svg style={{width: pixelSize * 12}} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 12 10" shapeRendering="crispEdges">
          <style>
            {`
            @keyframes svg_laptopPain {
              50% { stroke: white; }
            }
            @keyframes svg_laptopDisappear {
              50% { opacity: 0 }
            }
            `}
          </style>
          {
            belongsToTeam === "two" && (
              <React.Fragment>
                <path style={animationStyleRule} stroke="#101016" d="M1 0h10M0 1h1M11 1h1M0 2h1M11 2h1M0 3h1M11 3h1M0 4h1M11 4h1M0 5h1M11 5h1M1 6h10M0 7h1M11 7h1M0 8h1M11 8h1M1 9h10" />
                <path style={animationStyleRule} stroke="#586366" d="M1 1h10M1 2h10M1 3h4M7 3h4M1 4h10M1 5h10M1 7h3M5 7h1M7 7h4M1 8h10" />
                <path style={animationStyleRule} stroke="#515e62" d="M5 3h2" />
                <path style={animationStyleRule} stroke="#707b7f" d="M4 7h1M6 7h1" />
              </React.Fragment>
            )
          }
          {
            belongsToTeam === "one" && (
              <React.Fragment>
                <path style={animationStyleRule} stroke="#101016" d="M1 0h10M0 1h1M11 1h1M0 2h1M11 2h1M0 3h1M11 3h1M0 4h1M11 4h1M0 5h1M11 5h1M1 6h10M0 7h1M11 7h1M0 8h1M11 8h1M1 9h10" />
                <path style={animationStyleRule} stroke="#0f414c" d="M1 1h2M1 2h1" />
                <path style={animationStyleRule} stroke="#052128" d="M3 1h8M2 2h9M1 3h10M1 4h10M1 5h10" />
                <path style={animationStyleRule} stroke="#707b7f" d="M1 7h1M3 7h1M5 7h1M7 7h1M9 7h1M2 8h1M8 8h1M10 8h1" />
                <path style={animationStyleRule} stroke="#586366" d="M2 7h1M4 7h1M6 7h1M8 7h1M10 7h1M1 8h1M3 8h1M9 8h1" />
                <path style={animationStyleRule} stroke="#839093" d="M4 8h4" />
              </React.Fragment>
            )
          }
        </svg>
      </div>
    );
  }
}

export default ArenaLaptop;