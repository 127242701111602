export function getDangerMeterSteps({actionModel={}, casterModel={}}) {

  const casterHasSuper = casterModel.supers && casterModel.supers.length > 0;

  //Maybe add Danger Meter boost
  if (actionModel.increaseDangerMeter && casterHasSuper) {

    //Don't show if already full
    if (typeof casterModel.dangerMeter === "number" && casterModel.dangerMeter >= 100) {
      return [];
    }

    return [
      {
        type: "eventBlippy",
        isDangerMeterStep: true,
        subjects: [casterModel.id],
        stateChanges: [
          {
            combatantId: casterModel.id,
            relativeChanges: {
              dangerMeter: casterModel.status === "dangerous" ? (actionModel.increaseDangerMeter * 2) : actionModel.increaseDangerMeter
            }
          }
        ]
      }
    ]
  }

  //Default to nothing
  return [];
}