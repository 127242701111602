export default {
  "mystery": "/images/skins/people/MYSTERY-SHEET.png",

  //People
  "hank": "/images/skins/people/HANK-2-SHEET.png",
  "emmy": "/images/skins/people/EMMY-SHEET.png",
  "mindy": "/images/skins/people/MINDY-SHEET.png",
  "zak": "/images/skins/people/ZAK-SHEET.png",
  "sara": "/images/skins/people/SARA-SHEET.png",
  "shirmond": "/images/skins/people/SHIRMOND-SHEET.png",
  "patty": "/images/skins/people/PATTY-SHEET.png",
  "kim": "/images/skins/people/KIM-SHEET.png",


  //Halloween 2019
  "ghostHank": "/images/skins/people/GHOST-HANK-SHEET.png",
  "scaryShirmond": "/images/skins/people/SHIRMOND-SCARY-SHEET.png",
  //end Halloween

  //Spring 2020
  "springGuy": "/images/skins/people/SPRING-GUY-SHEET.png",
  "springGirl": "/images/skins/people/SPRING-GIRL-SHEET.png",
  //end Spring2020


  "jacob": "/images/skins/people/JACOB-SHEET.png",
  "drew": "/images/skins/people/DREW-SHEET.png",
  "glenn": "/images/skins/people/GLENN-SHEET.png",
  "macrodomeCasual": "/images/skins/people/MACRODOME-GUY-SHEET.png",
  "harold": "/images/skins/people/HAROLD-SHEET.png",
  "linnie": "/images/skins/people/LINNIE-SHEET.png",
  "iceCream": "/images/skins/people/ICECREAM-SHEET.png",
  "sunny": "/images/skins/people/SUNNY-SHEET.png",
  "security1": "/images/skins/people/SECURITY-1-SHEET.png",
  "securityJack": "/images/skins/people/SECURITY-JACK-SHEET.png",
  "recruiter": "/images/skins/people/RECRUITER-SHEET.png",
  "productManager": "/images/skins/people/PRODUCT-MANAGER-SHEET.png",
  "benny": "/images/skins/people/BENNY-SHEET.png",
  "m1": "/images/skins/people/M1-SHEET.png",
  "m2": "/images/skins/people/M2-SHEET.png",
  "f1": "/images/skins/people/F1-SHEET.png",

  "thug1": "/images/skins/people/THUG-1-SHEET.png",
  "thug2": "/images/skins/people/THUG-2-SHEET.png",
  "thug3": "/images/skins/people/THUG-3-SHEET.png",
  "thug4": "/images/skins/people/THUG-4-SHEET.png",


  "adultM1": "/images/skins/people/ADULT-M1-SHEET.png",
  "meme": "/images/skins/people/MEME-SHEET.png",
  "gareth": "/images/skins/people/GARETH-SHEET.png",
  "baristaB": "/images/skins/people/BARISTA-B-SHEET.png",
  "construction1": "/images/skins/people/CONSTRUCTION-1-SHEET.png",
  "construction2": "/images/skins/people/CONSTRUCTION-2-SHEET.png",
  "marie": "/images/skins/people/MARIE-SHEET.png",
  "bruce": "/images/skins/people/BRUCE-SHEET.png",
  "officeGuy": "/images/skins/people/OFFICE-GUY-SHEET.png",
  "dean": "/images/skins/people/DEAN-SHEET.png",
  "deanSecurity": "/images/skins/people/DEAN-SECURITY-SHEET.png",
  "pena": "/images/skins/people/PENA-SHEET.png",
  "hr": "/images/skins/people/HR-SHEET.png",
  "hrNoArrow": "/images/skins/people/HR-SHEET.png",
  //FSB Interviewers:
  "juan": "/images/skins/people/JUAN-SHEET.png",
  "sam": "/images/skins/people/SAM-SHEET.png",
  "tara": "/images/skins/people/TARA-SHEET.png",
  "chance": "/images/skins/people/CHANCE-SHEET.png",

  //Park
  "hugo": "/images/skins/people/HUGO-SHEET.png",
  "stanley": "/images/skins/people/STANLEY-SHEET.png",
  "grandma": "/images/skins/people/GRANDMA-SHEET.png",
  "grandpa": "/images/skins/people/GRANDPA-SHEET.png",

  //Executive
  "executive1": "/images/skins/people/EXECUTIVE-1-SHEET.png",
  "executive2": "/images/skins/people/EXECUTIVE-2-SHEET.png",
  "executive3": "/images/skins/people/EXECUTIVE-3-SHEET.png",

  //Tickets
  "switchControl": "/images/skins/alternative/SWITCH-CONTROL-SHEET.png",
  "switchRed": "/images/skins/alternative/SWITCH-RED-SHEET.png",
  "switchBlue": "/images/skins/alternative/SWITCH-BLUE-SHEET.png",
  "judge": "/images/skins/people/JUDGE-SHEET.png",
  "techShop": "/images/skins/people/TECH-SHOP-SHEET.png",

  //Macrodome
  "macroIC1": "/images/skins/people/MACRO-IC-1-SHEET.png",
  "macroIC2": "/images/skins/people/MACRO-IC-2-SHEET.png",
  "macroIC3": "/images/skins/people/MACRO-IC-3-SHEET.png",
  "macroIC4": "/images/skins/people/MACRO-IC-4-SHEET.png",

  //Conf
  "oliver": "/images/skins/people/OLIVER-SHEET.png",
  "kitchen": "/images/skins/people/KITCHEN-SHEET.png",
  "zombie1": "/images/skins/people/ZOMBIE-1-SHEET.png",
  "zombie2": "/images/skins/people/ZOMBIE-2-SHEET.png",
  "zombie3": "/images/skins/people/ZOMBIE-3-SHEET.png",
  "zombie4": "/images/skins/people/ZOMBIE-4-SHEET.png",
  "zombieOliver": "/images/skins/people/ZOMBIE-OLIVER-SKIN.png",

  //Animals?
  "bear": "/images/skins/people/BEAR-SHEET.png",


  //B7 Hacker Adds
  "fireQueen": "/images/skins/people/adds/FIRE_QUEEN_SHEET.png",
  "greenFloating": "/images/skins/people/adds/GREEN_FLOATING_SHEET.png",
  "purpleSupport": "/images/skins/people/adds/PURPLE-SUPPORT_SHEET.png",
  "redMan": "/images/skins/people/adds/RED_DPS_MAN_SHEET.png",
  "shieldMan": "/images/skins/people/adds/SHIELD_MAN_SHEET.png",
  "squidMan": "/images/skins/people/adds/SQUID_SHEET.png",
  "hookWoman": "/images/skins/people/adds/THIEF_WOMAN.png",
  "trenchcoat": "/images/skins/people/adds/TRENCHCOAT.png",
  "whiteRanger": "/images/skins/people/adds/WHITE_RANGER_SHEET.png",
  "jetpack": "/images/skins/people/adds/WINGED-BLUE-SHEET.png",
  "kingHacker": "/images/skins/people/adds/KING_HACKER_SHEET.png",


  //Alternatives
  "vendingMachine": "/images/skins/alternative/VENDING-MACHINE.png",
  "evilLaptop": "/images/skins/alternative/EVIL-LAPTOP-SHEET.png",
  "socks": "/images/skins/alternative/SOCKS-SHEET.png",
  "equipCard": "/images/skins/alternative/BAD-IMAGE-ERROR-SHEET.png",

  "attackPickup": "/images/skins/alternative/ATTACK-PICKUP-SHEET.png",
  "creditsPickup": "/images/skins/alternative/credits-pickup-sheet.png",
  "itemBox": "/images/skins/alternative/item-pickup-sheet.png",
  "itemBoxValidGoldArrow": "/images/skins/alternative/item-pickup-sheet.png",
  "pencilItemBox": "/images/skins/alternative/pencil-box-pickup-sheet.png",
  "upgradeBox": "/images/skins/alternative/upgrade-pickup-sheet.png",

  "charger": "/images/skins/alternative/CHARGER-SHEET.png",
  "friendlyLaptop": "/images/skins/alternative/FRIENDLY-LAPTOP-SHEET.png",
  "securityDoor": "/images/skins/alternative/SECURITY-DOOR-SHEET.png",
  "employeeBadge": "/images/skins/alternative/EMPLOYEE-BADGE-SHEET.png",
  "constructionBlocker": "/images/skins/alternative/CONSTRUCTION-BLOCKER-SHEET.png",
  "defenseGrid": "/images/skins/alternative/DEFENSE-GRID-SHEET.png",


  "statuePartsA": "/images/skins/alternative/STATUE-PART-SHEET-A.png",
  "statuePartsB": "/images/skins/alternative/STATUE-PART-SHEET-B.png",
  "statueFull": "/images/skins/alternative/STATUE-FULL-SHEET.png",

  "swagBag": "/images/skins/alternative/SWAG-BAG-SHEET.png",

  "highwayBlocker": "/images/skins/alternative/HIGHWAY-BLOCKER-SHEET.png",

  "serverRed": "/images/skins/alternative/SERVER-RED-SHEET.png",
  "serverBlue": "/images/skins/alternative/SERVER-BLUE-SHEET.png",
  "serverPurple": "/images/skins/alternative/SERVER-PURPLE-SHEET.png",


  //Invisible - careful! This is different per game and editor
  "invisibleStub": "/images/skins/people/INVISIBLE-STUB-SHEET.png",

}