import React from 'react'
import MapPersonCanvas from '../../Overworld/MapPersonCanvas'
import Skins from '../../_data/Skins'

class Battler extends React.Component {

  render() {

    const {
      handleRemoveMember,
      handleSetFormActive,
      isActive,
      isEnemy,
      model,
    } = this.props;
    const {
      name,
    } = model;

    const activeColor = isEnemy ? "#FFAEAE" : "#BCF3C7"

    return (
      <div
        className="pointer-on-hover"
        style={{
          border: "1px solid #959595",
          background: isActive ? activeColor : "#fff",
          padding: 5,
          height: 110,
          width: 110,
          fontSize: 10,
          marginRight: 10,
          position: "relative",
          transition: "top 0.2s",
          top: isActive ? (isEnemy ? 10 : -10) : (isEnemy ? -5 : 5)
        }}>
        <div onClick={() => {
          handleSetFormActive()
        }}>
          <div>{name}</div>
          <div>Items: {(model.items || []).length}</div>
          {/*<PauseCrewMember*/}
            {/*key={this.props.model.skinId}*/}
            {/*pixelSize={2}*/}
            {/*model={this.props.model}*/}
          {/*/>*/}

          <div style={{
            width: 64,
            height:64,
            overflow: "hidden"
          }}>
            <MapPersonCanvas
              key={this.props.model.skinId}
              pixelSize={2}
              skinSrc={Skins[this.props.model.skinId]}
            />
          </div>

        </div>
        {
          (model.id !== "enemyA" && model.id !== "memberA") && (
            <div>
              <button
                onClick={() => handleRemoveMember()}
              >
                Remove
              </button>
            </div>
          )
        }
      </div>
    );
  }
}

export default Battler;