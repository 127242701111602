import React from "react";
import { connect } from "react-redux";
import ScrollingOptionsList from "../menu-components/ScrollingOptionsList";
import BoxDiv from "../interface/BoxDiv";
import BoxDivBody from "../interface/BoxDivBody";
import StaticTextLine from "../StaticTextLine";
import ArrowMenu from "../ArrowMenu/ArrowMenu";
import { getMarketBuyMenu, getMarketConfirmMenu } from "./getPauseMarketMenu";
import { getAvailableBuyablesFromStoryPoints } from "./getAvailableBuyablesFromStoryPoints";
import { handleBuyBuyable } from "./handleBuyBuyable";
import Icon from "../interface/Icon";
import PixelArtTabs from '../menu-components/PixelArtTabs'
import {setOverworldValue} from "../redux-actions/overworld-actions";
import {handleSaveGameEvent} from "../loading/handleSaveGame";
import PauseHeader from "./PauseHeader";
import {audioManager} from "../audio/audioManager";

class PauseMarketArrowMenu extends React.Component {
  state = {
    rememberedOptions: {}, //built up as you navigate the menu
    marketMenuId: "buyAttacks" //buyAttacks, buyItems, buyUpgrades
  };

  componentDidMount() {
    //Turn off the unread badge when viewing this page
    setOverworldValue({
      showUnreadMarketBadge: false
    })
  }

  optionHandler = (action = {}) => {
    if (action.type === "STAGE_FOR_CONFIRMATION") {

      audioManager.playSfx("sfx_nextPage");

      this.setState({
        stagedTransaction: action.stagedTransaction,
        marketMenuId: "confirm"
      });
    }

    if (action.type === "CONFIRM") {
      const buyable = this.state.stagedTransaction;

      audioManager.playSfx("sfx_pickup");

      handleBuyBuyable(buyable);

      handleSaveGameEvent();

      this.setState({
        marketMenuId: (function() {
          if (buyable.type === "item") {
            return "buyItems";
          }
          if (buyable.type === "upgrade") {
            return "buyUpgrades";
          }
          return "buyAttacks";
        })()
      });
    }

    if (action.type === "SET_MARKET_MENU_ID") {
      audioManager.playSfx("sfx_nextPage");
      this.setState({ marketMenuId: action.marketMenuId });
    }

    if (action.type === "GO_BACK") {
      this.props.handleChangeMenuPage("home");
    }
  };

  renderConfirm() {
    if (this.state.marketMenuId === "confirm") {
      const {pixelSize} = this.props;
      return (
        <div>
          <div style={{marginBottom: pixelSize * 4, display: "flex", alignItems: "center"}}>
            <div style={{marginRight: pixelSize * 3, position: "relative"}}>
              <Icon category={this.state.stagedTransaction.icon} pixelSize={pixelSize} />
            </div>
            <StaticTextLine
              pixelSize={pixelSize}
              text={`${this.state.stagedTransaction.name}`}
            />
          </div>
          <div style={{marginBottom: pixelSize * 4}}>
          <StaticTextLine
            pixelSize={pixelSize}
            text={`${this.state.stagedTransaction.description}`}
          />
          </div>
        </div>
      )

      // return (
      //   <div>
      //     Are you sure you want to buy {this.state.stagedTransaction.name}?
      //   </div>
      // );
    }
    return null;
  }

  renderDescription(selectedOptionId, selectedOptionModel) {
    if (!selectedOptionModel || !selectedOptionModel.description) {
      return null;
    }
    const { pixelSize } = this.props;
    return (
      <div style={{ marginTop: pixelSize * 4 }}>
        <BoxDiv pixelSize={pixelSize}>
          <BoxDivBody
            insetTheme
            pixelSize={pixelSize}
            style={{ paddingLeft: pixelSize * 4, paddingRight: pixelSize * 4, minHeight: pixelSize * 40 }}
          >
            <StaticTextLine
              key={selectedOptionId}
              pixelSize={pixelSize}
              text={selectedOptionModel.description}
            />
          </BoxDivBody>
        </BoxDiv>
      </div>
    );
  }

  renderTitles = () => {
    if (
      ["buyAttacks", "buyUpgrades", "buyItems"].includes(
        this.state.marketMenuId
      )
    ) {
      const { pixelSize } = this.props;
      return (
        <main style={{ display: "flex", marginBottom: pixelSize * 6 }}>
          <PixelArtTabs
            pixelSize={pixelSize}
            data={[
              { text: "Attacks", activeId: "buyAttacks" },
              { text: "Upgrades", activeId: "buyUpgrades" },
              { text: "Items", activeId: "buyItems" }
            ]}
            handleNewActiveId={newActiveId => {
              this.setState({ marketMenuId: newActiveId })
            }}
            activeId={this.state.marketMenuId}
          />
        </main>
      );
    }
    return null;
  };

  render() {
    const { marketMenuId, stagedTransaction } = this.state;
    const {
      pixelSize,
      acquiredStoryPoints,
      credits,
      allPlayerAttacks,
      allPlayerUpgrades,
      initiallySelectedOptionId,
      inventory
    } = this.props;

    const listOfBuyables = getAvailableBuyablesFromStoryPoints({
      acquiredStoryPoints
    });

    if (!listOfBuyables.length) {
      return <div>Nothing to buy!</div>;
    }

    const { map, menu } = (function() {
      if (["buyAttacks", "buyUpgrades", "buyItems"].includes(marketMenuId)) {
        return getMarketBuyMenu({
          resourceType: (function() {
            if (marketMenuId === "buyUpgrades") {
              return "upgrade";
            }
            if (marketMenuId === "buyItems") {
              return "item";
            }
            return "attack";
          })(),
          allPlayerAttacks,
          allPlayerUpgrades,
          credits,
          listOfBuyables,
          inventory
        });
      }
      if (marketMenuId === "confirm") {
        return getMarketConfirmMenu(stagedTransaction);
      }
    })();

    return (
      <div style={{ width: pixelSize * 168 }}>
        <PauseHeader
          title={"Market"}
          rightPreset={"credits"}
          handleBackClick={() => this.props.handleChangeMenuPage("home")}
        />
        <div>
          {this.renderTitles()}
          {this.renderConfirm()}
        </div>
        <ArrowMenu
          handler={this.optionHandler}
          backOptionId={"back"}
          optionsPageId={marketMenuId}
          optionsMap={map}
          optionModels={menu}
          rememberedOptionId={
            this.state.rememberedOptions[this.state.marketMenuId] || null
          }
          onNewOptionsPage={change => {
            //"Don't remember list" -> Feels weird to remember Cancel option
            if (["marketCancel"].includes(change.previousSelectedOptionId)) {
              return;
            }
            this.setState(state => ({
              rememberedOptions: {
                ...state.rememberedOptions,
                [change.previousMenuId]: change.previousSelectedOptionId
              }
            }));
          }}
          render={({ handlers, selectedOptionId }) => {
            const selectedOptionModel = menu[selectedOptionId];
            return (
              <React.Fragment>
                <ScrollingOptionsList
                  key={marketMenuId}

                  optionsLimit={6}
                  optionsMinimum={marketMenuId === "confirm" ? null : 5}

                  initiallySelectedOptionId={initiallySelectedOptionId}
                  handlers={handlers}
                  optionsMap={map}
                  optionModels={menu}
                  selectedOptionId={selectedOptionId}
                  pixelSize={pixelSize}
                  renderOptionRight={option => {
                    if (option.price && !option.isAlreadyOwned) {
                      return (
                        <React.Fragment>
                          <div style={{ marginRight: pixelSize * 2 }}>
                            <Icon category="credit" pixelSize={pixelSize} />
                          </div>
                          <StaticTextLine
                            theme={option.isTooExpensive ? "red" : null}
                            pixelSize={pixelSize}
                            text={String(option.price)}
                          />
                        </React.Fragment>
                      );
                    }
                    return null;
                  }}
                />
                {this.renderDescription(selectedOptionId, selectedOptionModel)}
              </React.Fragment>
            );
          }}
        />
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    acquiredStoryPoints: state.playerData.acquiredStoryPoints,
    credits: state.playerData.currency.credits,
    allPlayerAttacks: state.playerData.allPlayerAttacks,
    allPlayerUpgrades: state.playerData.allPlayerUpgrades,
    inventory: state.playerData.inventory
  };
})(PauseMarketArrowMenu);
