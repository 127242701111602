export function testablePercentChance(number) {
  if (process.env.NODE_ENV === "test") {
    if (number === 0) { return false } //If you give it a 0 in the test, you probably want it to be 0 rather than 100
    return percentChance(100);
  }
  return percentChance(number)
}


//Utility: Let me decide true or false for a percent chance
function percentChance(percent) {
  //usage: percentChance(15) //There is a 15 percent chance this will return true;
  return percent >= getRandomInRange(1, 100);
}

export function getRandomInRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}