import React from 'react'
import {connect} from 'react-redux'

import ScrollingOptionsList from "../menu-components/ScrollingOptionsList";
import BoxDiv from "../interface/BoxDiv";
import BoxDivBody from "../interface/BoxDivBody";
import ArrowMenu from "../ArrowMenu/ArrowMenu";
import {getPauseHomeMenu} from "./getPauseHomeMenu";
// import {setOverworldValue} from "../redux-actions/overworld-actions";
// import {getNextExternalEventTrigger} from "../Overworld/getIncrExternalEventTrigger";
import {getAvailableBuyablesFromStoryPoints} from "./getAvailableBuyablesFromStoryPoints";
import StaticTextLine from "../StaticTextLine";
import Icon from '../interface/Icon'
import {getCurrentLevelFromXp} from "../getCurrentLevelFromXp";
import Levels from "../_data/Levels";
import PauseHeader from "./PauseHeader";
import { handleSaveGameEvent } from "../loading/handleSaveGame";
import { audioManager } from "../audio/audioManager";
import {handleUnpause} from './handleUnpause'
import GenericFillBar from '../interface/GenericFillBar'

class PauseHomeArrowMenu extends React.Component {

  constructor(props) {
    super(props);
    this.isMarketAvailable = getAvailableBuyablesFromStoryPoints({
      acquiredStoryPoints: props.acquiredStoryPoints
    }).length > 0;

    this.state = {
      saveTimestamp: 0 //use this to throttle save button from spammers
    }

  }

  optionHandler = (action = {}) => {

    if (action.rememberOptionId) {
      this.props.rememberActionId(action.rememberOptionId)
    }

    if (action.type === "CHANGE_PAGE") {
      this.props.handleChangeMenuPage(action.newPage);
    }

    if (action.type === "SAVE_GAME") {
      const now = Date.now();
      if (now > this.state.saveTimestamp + 3000) { //only allow the save button every X seconds
        this.setState({
          saveTimestamp: Date.now()
        });
        handleSaveGameEvent();
      } else {
        //console.log('save denied - please wait')
      }

      //Satisfying SFX
      audioManager.playSfx("sfx_submitAction");

      //Unpause the game
      handleUnpause()
    }

    if (action.type === "CLOSE") {
      handleUnpause()
    }
  };

  // handleUnpause = () => {
  //   setOverworldValue({
  //     isPauseMenuOpen: false,
  //     externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-UNPAUSE")
  //   })
  // };

  render() {
    const {pixelSize, initiallySelectedOptionId, inventory, allPlayerUpgrades, showUnreadMarketBadge, showUnreadScriptsBadge, acquiredMembers} = this.props;

    const {map, menu, backOptionId} = getPauseHomeMenu({
      inventory,
      allPlayerUpgrades,
      showUnreadMarketBadge,
      showUnreadScriptsBadge,
      acquiredMembers,
      isMarketAvailable: this.isMarketAvailable,
      isLoggedIn: this.props.authState === "loggedIn",
      //crew: getBattleCrewFromRedux()
    });

    const menuWidth = 124;

    return (
      <ArrowMenu
        initiallySelectedOptionId={initiallySelectedOptionId}
        handler={this.optionHandler}
        backOptionId={backOptionId}
        optionsPageId={"home"}
        optionsMap={map}
        optionModels={menu}
        render={({handlers, selectedOptionId}) => {
          return (
            <React.Fragment>
              <PauseHeader
                title={"Pause Menu"}
                handleBackClick={() => handleUnpause()}
                rightPreset={"credits"}
              />
              <div style={{marginBottom: pixelSize * 6}}>
                <ScrollingOptionsList
                  pixelWidth={menuWidth}
                  handlers={handlers}
                  optionsMap={map}
                  optionModels={menu}
                  selectedOptionId={selectedOptionId}
                  pixelSize={pixelSize}

                  renderOptionRight={(option) => {
                    if (option.hasUnreadBadge) {
                      return (
                        <div style={{marginRight: pixelSize * 2, marginBottom: pixelSize}}>
                          <Icon
                            category="unread"
                            pixelSize={pixelSize}
                          />
                        </div>
                      )
                    }
                    return null
                  }}
                />
              </div>
              <BoxDiv pixelSize={pixelSize}>
                <BoxDivBody grayTextboxTheme pixelSize={pixelSize} style={{
                  paddingLeft: pixelSize * 4,
                  paddingRight: 0,
                  paddingBottom: pixelSize * 4
                }}>
                  <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                    <div className="Dpad--NoShow">
                      <StaticTextLine
                        key={selectedOptionId}
                        pixelSize={pixelSize}
                        text="Next Level"
                      />
                    </div>
                    <div className="Dpad--NoShow">
                      <StaticTextLine
                        pixelSize={pixelSize}
                        text={`${this.props.xpUntilNextLevel} XP`}
                      />
                    </div>
                  </div>
                  <GenericFillBar
                    pixelSize={pixelSize}
                    totalWidth={115}
                    fillPercent={this.props.xpFillPercent}
                  />
                </BoxDivBody>
              </BoxDiv>
            </React.Fragment>
          )
        }
        }
      />
    );
  }
}

export default connect((state, props) => {

  const currentPlayerLevel = getCurrentLevelFromXp(state.playerData.teamXp, Levels);

  //Crunching numbers to get the fillPercent
  const currentLevelStartingXp = Levels[currentPlayerLevel];
  const nextLevelAtXp = Levels[currentPlayerLevel + 1];
  const totalGapForLevel = nextLevelAtXp - currentLevelStartingXp;
  const fillPercent = (state.playerData.teamXp - currentLevelStartingXp) / totalGapForLevel;

  return {
    inventory: state.playerData.inventory,
    allPlayerUpgrades: state.playerData.allPlayerUpgrades,
    acquiredStoryPoints: state.playerData.acquiredStoryPoints,
    acquiredMembers: state.playerData.acquiredMembers,
    pixelSize: state.global.pixelSize,
    authState: state.global.authState,
    xpUntilNextLevel: Levels[currentPlayerLevel+1] - state.playerData.teamXp,
    showUnreadMarketBadge: state.overworld.showUnreadMarketBadge,
    showUnreadScriptsBadge: state.overworld.showUnreadScriptsBadge,
    xpFillPercent: fillPercent,
  }
})(PauseHomeArrowMenu)
