import {DefaultAction, DefaultScript} from "../Action";
import {getGenericFail} from "../../formulas/getGenericFail";

export default {
  ...DefaultAction,
  ...DefaultScript,
  name: "Dangerous",
  description: "Gain an extra Danger Meter blip while feeling Dangerous",
  icon: "support",
  targetingType: "single-friendly",

  shouldFailOnSingleTarget(casterModel, targetModel) {
    if (targetModel.status !== "normal") {
      return {
        failCode: "has-status" //could be "denied-by-upgrade" in the future, etc
      }
    }
    return null;
  },

  failEventsPerTarget(casterModel, targetModel, failInfo={}) {
    //Maybe add "WHY" here if an upgrade caused it to fail?
    return getGenericFail({targetModel})
  },

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {
    return [
      {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: targetModel.id,
            absoluteChanges: {
              status: "dangerous"
            },
            message: {
              theme: "battlePositiveStatus",
              text: `Dangerous!`
            },
          }
        ]
      },
      {
        type: "eventSchedule",
        roundsFromNow: 3,
        combatantId: targetModel.id,
        event: {
          actionId: "natural_end-dangerous",
          casterId: targetModel.id,
          targetIds: [targetModel.id]
        }
      }
    ]
  }
}




