import {DefaultAction} from "../Action";
import {getAttackDamage} from "../../formulas/getAttackDamage";
import {getDangerMeterReset} from "../../formulas/getDangerMeterReset";

export default {
  ...DefaultAction,
  name: "Sudo Damage",
  description: "Devastating blow to one enemy that only a super user would dare to use",
  icon: "super-dps",

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {
    const damage = getAttackDamage(25, casterModel, targetModel, allUpgrades);

    return [
      { //TODO: needs amazing animation
        type: "eventAnimation",
        animationId: "animationSudoDamage",
        subjects: [targetModel.id],
        delayFramesAfter: 32,
      },

      //TODO: how does Block play into this? - bypasses Block?
      // //Block step
      // targetModel.status === "block" ? {
      //   type: "eventBlippy",
      //   stateChanges: [
      //     {
      //       combatantId: targetModel.id,
      //       absoluteChanges: {
      //         status: "normal"
      //       },
      //       message: {
      //         theme: "battleDamageBlock",
      //         text: `Damage Absorbed!`
      //       },
      //     }
      //   ],
      // } : null,

      //Damage step
      {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: targetModel.id,
            relativeChanges: {
              hp: damage
            },
            message: {
              theme: "battleNegativeHp",
              text: `${damage} HP`
            },
          }
        ],
        delayFramesAfter: 60
      },
      getDangerMeterReset({casterModel})
    ]
  }
}