import React from 'react'

class FormField extends React.Component {

  state = {
    hasBlurred: false,
    isDirty: false
  };

  render() {
    const {label, onChange, value, renderSubtext, formName, autoComplete, error, name, hasSubmitted, showCompletedCheck, renderRightLabelArea} = this.props;

    //Be as friendly as possible with showing errors.
    const showError = hasSubmitted || (error && this.state.isDirty && this.state.hasBlurred);

    const domId = formName;
    return (
      <div style={{marginBottom: "1em", }}>
        <div style={{display: "flex", justifyContent:"space-between"}}>
          <label
            htmlFor={domId}
            style={{
              display:"block",
              paddingBottom:4,
              fontSize: 16
            }}>
            {label}
            </label>
          {
            renderRightLabelArea && <div>{renderRightLabelArea()}</div>
          }
        </div>
        <div style={{position:"relative"}}>
          <input
            id={domId}
            style={{
              fontSize: 16,
              paddingLeft:5,
              display: "block",
              width: "100%",
              fontFamily: "inherit",
              paddingTop: "0.5em",
              paddingBottom: "0.5em",
              border: showError ? "1px solid red" : "1px solid #ccc"
            }}
            type={this.props.type || "text"}
            autoFocus={this.props.autoFocus}
            placeholder={this.props.placeholder || null}
            name={name}
            value={value}
            autoComplete={autoComplete || null}
            onChange={e => {
              onChange(e.target.value);
              this.setState({isDirty: true})
            }}
            onBlur={e => this.setState({hasBlurred:true}) }
          />
          {
            (!error && showCompletedCheck) && (
              <div style={{position:"absolute", right:10, bottom:10}}>
                <Checkmark />
              </div>
            )
          }
        </div>
        {
          Boolean(showError) && (
            <div style={{color:"red", fontSize: "1em", paddingTop: 4}}>{error}</div>
          )
        }
        {
          renderSubtext && renderSubtext()
        }
      </div>
    );
  }
}

export default FormField;


const Checkmark = () => {
  return (
    <svg style={{display:"block", width:21}} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 7 6" shapeRendering="crispEdges">
      <path stroke="#40a018" d="M5 0h2M4 1h1m1 0h1M0 2h2m1 0h1m1 0h1M0 3h1m1 0h1"/>
      <path stroke="#a6e88a" d="M5 1h1M4 2h1M1 3h1m1 0h1M2 4h1"/>
      <path stroke="#2b8008" d="M4 3h1M1 4h1m1 0h1M2 5h1"/>
    </svg>
  )
};