import {DefaultAction} from "../Action";
import {getDangerMeterReset} from "../../formulas/getDangerMeterReset";

function createTeamStatusSettingAction(properties) {
  return {
    ...DefaultAction,
    name: properties.name,
    description: properties.description,
    icon: properties.icon,
    targetingType: properties.targetingType,

    shouldFailOnSingleTarget(casterModel, targetModel) {
      return null; //Does not fail.
      //return targetModel.status !== "normal"
    },

    successEventsPerAllTargets({casterModel, targetModels, allUpgrades}) {

      let payload = [
        // { //sweet fire animation?
        //   type: "eventAnimation",
        //   animationId: "animationSlice",
        //   subjects: targetModels.map(t => t.id),
        //   delayFramesAfter: 30,
        // },
        {
          type: "eventBlippy",
          stateChanges: targetModels.map(t => {
            return {
              combatantId: t.id,
              absoluteChanges: {
                status: properties.setStatus
              },
              message: {
                //subject: t.id,
                theme: properties.messageTheme,
                text: properties.messageText,
              }
            }
          }),
          delayFramesAfter: 30
        },
        getDangerMeterReset({casterModel})
      ];

      if (properties.recoverAfterTurns) {
        targetModels.forEach(t => {
          payload.push({
            type: "eventSchedule",
            roundsFromNow: properties.recoverAfterTurns[1],
            combatantId: t.id,
            event: {
              actionId: properties.recoverAfterTurns[0],
              casterId: t.id,
              targetIds: [t.id]
            }
          });
        });
      }
      return payload;
    }
  }
}

export const Wildfire = createTeamStatusSettingAction({
  name: "Wildfire",
  description: "Cause the entire enemy team to catch fire",
  icon: "super-disrupt",
  targetingType: "all-enemies",
  setStatus: "fire",
  messageTheme: "battleNegativeStatus",
  messageText: "FIRE",
});

export const IntimidateAll = createTeamStatusSettingAction({
  name: "Intimidate All",
  description: "Cause the entire enemy team to be interview-caliber nervous",
  icon: "super-disrupt",
  targetingType: "all-enemies",
  setStatus: "nervous",
  messageTheme: "battleNegativeStatus",
  messageText: "INTIMIDATED",
  recoverAfterTurns: ["natural_end-nervous", 3]
});