import BattleActions from "../battle-data/battle-actions-data";
import { getEnemies, getFriendlyTeam } from "../getTeams";

export function getArenaTargetingMenu({
  actionId,
  combatant,
  allCombatants,
  items,
  options = {}
}) {
  const actions = options.allActions || BattleActions;
  const action = actions[actionId];

  //Start empty
  let menu = {};
  let map = [[]];

  const enemies = getEnemies(combatant.id, allCombatants).filter(c => {
    if (typeof c.hp === "number" && c.hp <= 0) {
      return false;
    }
    return true;
  });

  let friendlies = getFriendlyTeam(combatant.id, allCombatants).filter(c => {
    if (action.targetsMustBeDead) {
      return typeof c.hp === "number" && c.hp <= 0;
      //return true
    }

    if (typeof c.hp === "number" && c.hp <= 0) {
      return false;
    }
    return true;
  });

  //If no friendlies are valid, return ALL of them (all with warnings, we'd hope!) so you at least have some options but will hopefully backtrack
  if (friendlies.length === 0) {
    friendlies = getFriendlyTeam(combatant.id, allCombatants);
  }

  const targetingType = action.targetingType || "single-enemy";

  //Case 1: target is "single-enemy", so we break apart the individual enemies.
  if (targetingType === "single-enemy") {
    enemies.forEach((enemy, i) => {
      const key = `target${i}`;
      menu[key] = {
        targetingText: "Select one enemy!",
        targetContext: "enemies",
        targets: [
          {
            menuOptionKey: key,
            targetId: enemy.id,
            name: enemy.name,
            isEnemy: true,
            hasWarning: getWarning({
              action,
              caster: combatant,
              target: enemy
            })
          }
        ],
        handler() {
          return {
            submit: true,
            targetIds: [enemy.id]
          };
        }
      };
      map[0].push(key);
    });
  }

  if (targetingType === "single-friendly") {
    friendlies.forEach((friendly, i) => {
      const key = `target${i}`;

      menu[key] = {
        targetingText: "Select one teammate!",
        targetContext: "friendlies",
        targets: [
          {
            menuOptionKey: key,
            targetId: friendly.id,
            name: friendly.name,
            hasWarning: getWarning({
              action,
              caster: combatant,
              target: friendly
            }),
            hasFuryWarning: function() {

              if ([
                "script_clean",
                "item_heal-anything",
              ].includes(actionId)) {
                return false;
              }

              return friendly.belongsToTeam === "one" && friendly.status === "fury"
            }()
          }
        ],
        handler() {
          return {
            submit: true,
            targetIds: [friendly.id]
          };
        }
      };
      map[0].push(key);
    });
  }

  if (targetingType === "all-enemies") {
    const enemyIds = enemies.map(e => e.id);
    menu["target0"] = {
      targetingText: "Confirm team targets!",
      targetContext: "enemies",
      targets: enemies.map(e => {
        return {
          menuOptionKey: "target0",
          targetId: e.id,
          name: e.name,
          isEnemy: true,
          hasWarning: getWarning({action, caster:combatant, target:e, items})
        };
      }),
      handler() {
        return {
          submit: true,
          targetIds: enemyIds
        };
      }
    };
    map = [["target0"]];
  }

  if (targetingType === "all-friendlies") {
    const friendlyIds = friendlies.map(c => c.id);
    menu["target0"] = {
      targetingText: "Confirm friendly targets!",
      targetContext: "friendlies",
      targets: friendlies.map(c => {
        return {
          menuOptionKey: "target0",
          targetId: c.id,
          name: c.name,
          hasWarning: getWarning({action, caster:combatant, target:c, items})
        };
      }),
      handler() {
        //console.log('heyo! submit for real all-friendlies!')
        return {
          submit: true,
          targetIds: friendlyIds
        };
      }
    };
    map = [["target0"]];
  }

  menu[`back`] = {
    handler() {
      return {
        goToMenuId: function() {
          if (action.isItem) { return "items"}
          if (actionId.startsWith("script_")) { return "scripts"}
          return "home"
        }()
      };
    }
  };

  return {
    menu,
    map,
    backOptionId: "back"
  };
}

function getWarning({ action, caster, target, items }) {

  if (action.teamItemMoveMethods) {
    let failInfo = action.shouldFailOnTargetTeam({
      casterModel: caster,
      friendlyItems: items[caster.belongsToTeam === "one" ? "one" : "two"],
      enemyItems: items[caster.belongsToTeam === "one" ? "two" : "one"],
    });
    return Boolean(failInfo);
  }

  if (action.shouldFailOnSingleTarget) {
    const warning = action.shouldFailOnSingleTarget(caster, target, {});
    if (warning) return true;
  }
  return false;
}
