import {DefaultAction, DefaultItem} from "../Action";
import {getCombatantHpRaise} from "../../formulas/getCombatantHpRaise";

function createBatteryPackItem(properties={}) {
  return {
    ...DefaultAction,
    ...DefaultItem,

    name: properties.name,
    description: properties.description,
    icon: "item",
    targetingType: "single-friendly",
    targetsMustBeDead: properties.targetsMustBeDead,
    sellValue: properties.sellValue,
    overworldUsableCode: properties.overworldUsableCode,

    shouldFailOnSingleTarget(casterModel, targetModel) {
      if (properties.targetsMustBeDead && targetModel.hp > 0) {
          return {
            failCode: "not-dead"
          }
      }

      if (targetModel.hp === targetModel.maxHp) {
        return {
          failCode: "full-hp"
        }
      }
      return null;
    },

    failEventsPerTarget(casterModel, targetModel, failInfo={}) {
      return [
        {
          type: "eventBlippy",
          stateChanges: [
            {
              combatantId: targetModel.id,
              message: {
                theme: "battleNoEffect",
                text: `No effect`
              },
            }

          ]
        }
      ]
    },

    successEventsPerTarget(casterModel, targetModel, allUpgrades) {

      if (properties.hpPercent === 100) {

        //Maybe reset Danger Meter for revive moves
        let fullReviveAbsoluteChanges = {};
        if (properties.resetDangerMeter) {
          fullReviveAbsoluteChanges.dangerMeter = 0; //lose all DM progress
        }

        return [
          {
            type: "eventAnimation",
            animationId: "animationOpenItem",
            subjects: [targetModel.id],
            haltQueueUntilDone: true,
          },
          {
            type: "eventBlippy",
            stateChanges: [
              {
                combatantId: targetModel.id,
                absoluteChanges: {
                  hp: targetModel.maxHp,
                  ...fullReviveAbsoluteChanges
                },
                message: {
                  theme: "battlePositiveHp",
                  text: `Full HP!`
                },
              }
            ]
          }
        ]
      }


      const hpChange = getCombatantHpRaise(targetModel, properties.hpPercent, "percent");

      //Maybe reset Danger Meter for revive moves
      let absoluteChangesForRevive = {};
      if (properties.resetDangerMeter) {
        absoluteChangesForRevive.dangerMeter = 0; //lose all DM progress
      }


      return [
        {
          type: "eventAnimation",
          animationId: "animationOpenItem",
          subjects: [targetModel.id],
          haltQueueUntilDone: true,
        },
        {
          type: "eventBlippy",
          stateChanges: [
            targetModel.hp < 0 ? { //set back to 0 if we are reviving somebody
                combatantId: targetModel.id,
                absoluteChanges: {hp: 0}
            } : null,
            {
              combatantId: targetModel.id,
              absoluteChanges: absoluteChangesForRevive,
              relativeChanges: {
                hp: hpChange
              },
              message: {
                theme: "battlePositiveHp",
                text: `+${hpChange}HP`
              },
            }
          ].filter(d => d)
        }
      ]
    }
  }
}

export const BatteryPack = createBatteryPackItem({
  name: "Battery Pack",
  description: "Recovers up to half of your max HP",
  hpPercent: 50,
  targetsMustBeDead: false,
  sellValue: 7,
  overworldUsableCode: ["single", "alive", "50%"]
});
export const FullBatteryPack = createBatteryPackItem({
  name: "Full Battery Pack",
  description: "Recovers full HP",
  hpPercent: 100,
  targetsMustBeDead: false,
  sellValue: 30,
  overworldUsableCode: ["single", "alive", "100%"]
});
export const RevivePack = createBatteryPackItem({
  name: "Revive Pack",
  description: "Revives one person to half HP",
  hpPercent: 50,
  targetsMustBeDead: true,
  sellValue: 22,
  overworldUsableCode: ["single", "dead", "50%"],
  resetDangerMeter: true,
});
export const FullRevivePack = createBatteryPackItem({
  name: "Full Revive Pack",
  description: "Revives one person to full HP",
  hpPercent: 100,
  targetsMustBeDead: true,
  sellValue: 30,
  overworldUsableCode: ["single", "dead", "100%"],
  resetDangerMeter: true
});



function createTeamBatteryPackItem(properties={}) {
  return {
    ...DefaultAction,
    ...DefaultItem,

    name: properties.name,
    description: properties.description,
    icon: "item",
    targetingType: "all-friendlies",
    targetsMustBeDead: properties.targetsMustBeDead,
    sellValue: properties.sellValue,
    overworldUsableCode: properties.overworldUsableCode,

    shouldFailOnSingleTarget(casterModel, targetModel) {
      if (targetModel.hp === targetModel.maxHp) {
        return {
          failCode: "full-hp"
        }
      }
      return null;
    },

    successEventsPerAllTargets({casterModel, targetModels, allUpgrades}) {
      return [
        {
          type: "eventAnimation",
          animationId: "animationOpenItem",
          subjects: targetModels.map(t => t.id),
          haltQueueUntilDone: true,
        },
        {
          type: "eventBlippy",
          stateChanges: targetModels.map(targetModel => {
            const hpChange = getCombatantHpRaise(targetModel, properties.hpPercent, "percent");

            return {
              combatantId: targetModel.id,
              relativeChanges: {
                hp: hpChange
              },
              message: {
                theme: "battlePositiveHp",
                text: `+${hpChange}HP`
              },
            }
          })
        }
      ]
    }
  }
}


export const TeamBatteryPack = createTeamBatteryPackItem({
  name: "Team Battery Pack",
  description: "Recovers full HP of whole team",
  hpPercent: 100,
  targetsMustBeDead: false,
  sellValue: 30,
  overworldUsableCode: ["team", "alive", "100%"]
});