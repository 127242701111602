import { shuffle } from "../../../helpers/shuffle";


const hackerKingBaseDecisions = function({ combatants = [], battleStoryPoints = [], regularDecision, subject }) {

  const wholePlayerTeamIds = combatants.filter(c => c.belongsToTeam === "one").map(c => c.id);


  if (!battleStoryPoints.includes("hackerking--bug-half")) {
    return {
      submission: [
        {
          actionId: "speak",
          casterId: subject.id,
          targetIds: [subject.id],
          mergeData: {
            dialogContent: [
              "** Hacker King is present **"
            ]
          }
        },
        {
          actionId: "script_infest",
          casterId: subject.id,
          targetIds: wholePlayerTeamIds
        }
      ],
      updatedBattleStoryPoints: [...battleStoryPoints, "hackerking--bug-half"]
    };
  }

  //Hacker King will escape if less than certain HP percent
  const subjectHpPercent = (subject.hp / subject.maxHp) * 100;
  if (subjectHpPercent <= 50) {
    //console.log('Should Escape now', subjectHpPercent <= 50)
    return {
      submission: [
        {
          actionId: "speak",
          casterId: subject.id,
          targetIds: [subject.id],
          mergeData: {
            dialogContent: [
              "** Hacker King is not interested **"
            ]
          }
        },
        {
          actionId: "script_escape",
          casterId: subject.id,
          targetIds: wholePlayerTeamIds
        }
      ],
      updatedBattleStoryPoints: [...battleStoryPoints, "hackerking--bug-half"]
    };
  }

  const shouldUseAnnoyingMove = shuffle([true, false])[0];

  //Find 1 target with no status -> DDoS them and use Block on self.
  const playerCharactersWithNormalStatus = combatants.filter(c => c.belongsToTeam === "one" && c.hp > 0 && c.status === "normal").map(c => c.id);
  if (playerCharactersWithNormalStatus.length && shouldUseAnnoyingMove && !battleStoryPoints.includes("hackerking--ddos-combo")) {

    const targetId = shuffle(playerCharactersWithNormalStatus)[0];
      return {
        submission: [
          {
            actionId: "speak",
            casterId: subject.id,
            targetIds: [subject.id],
            mergeData: {
              dialogContent: [
                "** His snickering is infuriating! **"
              ]
            }
          },
          {
            actionId: "script_ddos",
            casterId: subject.id,
            targetIds: [targetId]
          },
          subject.status === "normal" ? {
            actionId: "script_block",
            casterId: subject.id,
            targetIds: [subject.id]
          } : null
        ].filter(d => d),
        updatedBattleStoryPoints: [...battleStoryPoints, "hackerking--ddos-combo"] //only let this happen once
      };
  }

  //Default to regular
  return {
    submission: regularDecision,
    updatedBattleStoryPoints: battleStoryPoints
  };
};


export const hackerKing1Decisions = hackerKingBaseDecisions;
export const hackerKing2Decisions = hackerKingBaseDecisions;

//TODO: maybe make HK3 a little more intense...
export const hackerKing3Decisions = function({ combatants = [], battleStoryPoints = [], regularDecision, subject }) {

  const wholePlayerTeamIds = combatants.filter(c => c.belongsToTeam === "one").map(c => c.id);


  if (!battleStoryPoints.includes("hackerking--bug-half")) {
    return {
      submission: [
        {
          actionId: "speak",
          casterId: subject.id,
          targetIds: [subject.id],
          mergeData: {
            dialogContent: [
              "** Hacker King is present **"
            ]
          }
        },
        {
          actionId: "script_infest",
          casterId: subject.id,
          targetIds: wholePlayerTeamIds
        }
      ],
      updatedBattleStoryPoints: [...battleStoryPoints, "hackerking--bug-half"]
    };
  }

  const shouldUseAnnoyingMove = shuffle([true, false])[0];

  //Find 1 target with no status -> Hack them and use Block on self.
  const playerCharactersWithNormalStatus = combatants.filter(c => c.belongsToTeam === "one" && c.hp > 0 && c.status === "normal").map(c => c.id);
  if (playerCharactersWithNormalStatus.length && shouldUseAnnoyingMove && !battleStoryPoints.includes("hackerking--ddos-combo")) {

    const targetId = shuffle(playerCharactersWithNormalStatus)[0];
    return {
      submission: [
        {
          actionId: "speak",
          casterId: subject.id,
          targetIds: [subject.id],
          mergeData: {
            dialogContent: [
              "** His snickering is infuriating! **"
            ]
          }
        },
        {
          actionId: "script_hack",
          casterId: subject.id,
          targetIds: [targetId]
        },
        subject.status === "normal" ? {
          actionId: "script_block",
          casterId: subject.id,
          targetIds: [subject.id]
        } : null
      ].filter(d => d),
      updatedBattleStoryPoints: [...battleStoryPoints, "hackerking--ddos-combo"] //only let this happen once
    };
  }

  //Default to regular
  return {
    submission: regularDecision,
    updatedBattleStoryPoints: battleStoryPoints
  };
};
