export function hotLoadScenarioOnInteractives(existingInteractivesList, newInteractivesList) {
  return newInteractivesList.map(newInteractive => {

    const oldVersion = existingInteractivesList.find(old => old.id === newInteractive.id);

    if (oldVersion) {

      let payload = {
        ...oldVersion,

        //These are the only keys that will hot load on scenario refresh.
        isHostile: newInteractive.isHostile || false,
        hasQuestIndicator: newInteractive.hasQuestIndicator || false,
        isInvisible: newInteractive.isInvisible || false,
        hasBattleLaptop: newInteractive.hasBattleLaptop || false,
        hasFriendlyLaptop: newInteractive.hasFriendlyLaptop || false,
        isBlinkingVendingMachine: newInteractive.isBlinkingVendingMachine || false,
      };

      if (newInteractive.customMovingSpeed) {
        payload.customMovingSpeed = newInteractive.customMovingSpeed
      }

      return payload;
    }

    //Newly added!
    return newInteractive;
  });
}