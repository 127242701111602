import {shuffle} from "../../../helpers/shuffle";

export function getStatLoweringDecision({casterModel, teamMembers, enemies, teamItems}) {

  //These affect the whole team (at least so far all of them do)
  const myAttackIds = casterModel.attacks.map(a => a.actionId);
  let myStatLoweringAttacks = [
    "script_screen-blur",
    "script_power-stalk"
  ].filter(key => {
    return myAttackIds.includes(key);
  });

  if (!myStatLoweringAttacks.length) { return null }


  const aliveEnemies = enemies.filter(e => e.hp && e.hp > 0);
  return {
    actionId: shuffle(myStatLoweringAttacks)[0],
    casterId: casterModel.id,
    targetIds: aliveEnemies.map(e => e.id)
  };
}