import React from "react";
import { connect } from "react-redux";

class BuyNowButton extends React.Component {
  render() {

    if (process.env.REACT_APP_ELECTRON_BUILD) {
      return null
    }

    const {pixelSize} = this.props;
    return (
      <a style={{display:"block", marginRight: pixelSize * 2}} href={"https://store.steampowered.com/app/1064690/Danger_Crew?icid=webdemo"} target="_blank" rel="noopener noreferrer">
        <svg style={{width: 34 * pixelSize}} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 34 13" shapeRendering="crispEdges">
          <path stroke="#013f9a" d="M1 0h32"/>
          <path stroke="#012b67" d="M0 1h1m32 0h1M0 2h1m32 0h1M0 3h1m32 0h1M0 4h1m32 0h1M0 5h1m32 0h1M0 6h1m32 0h1M0 7h1m32 0h1M0 8h1m32 0h1M0 9h1m32 0h1M0 10h1m32 0h1M0 11h1m32 0h1M1 12h32"/>
          <path stroke="#5a9dff" d="M1 1h32"/>
          <path stroke="#3184ff" d="M1 2h32M1 3h32M1 4h3m2 0h2m1 0h1m1 0h1m1 0h1m1 0h2m2 0h2m3 0h1m1 0h1m1 0h1m1 0h3M1 5h3m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h2m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h3M1 6h3m3 0h1m1 0h1m1 0h1m3 0h2m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h3M1 7h3m1 0h1m1 0h1m1 0h1m1 0h2m1 0h3m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h3M1 8h3m3 0h1m3 0h2m1 0h3m1 0h1m1 0h1m3 0h1m4 0h4M1 9h32M1 10h32"/>
          <path stroke="#fff" d="M4 4h2m2 0h1m1 0h1m1 0h1m1 0h1m2 0h2m2 0h3m1 0h1m1 0h1m1 0h1M4 5h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m2 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1M4 6h3m1 0h1m1 0h1m1 0h3m2 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1M4 7h1m1 0h1m1 0h1m1 0h1m2 0h1m3 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1M4 8h3m1 0h3m2 0h1m3 0h1m1 0h1m1 0h3m1 0h4"/>
          <path stroke="#2965ff" d="M1 11h32"/>
        </svg>
      </a>
    );
  }
}

export default connect((state, props) => {
  return {
    pixelSize: state.global.pixelSize
  };
})(BuyNowButton);