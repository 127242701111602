import store from '../../init/store'
import {setPlayerDataValue} from '../../redux-actions/player-data-actions'
import {setOverworldValue} from "../../redux-actions/overworld-actions";

export function addAttackToPlayer(actionId="", currentAttacks=[]) {

  const isDuplicate = currentAttacks.find(u => u.actionId === actionId);
  if (isDuplicate) {
    return currentAttacks
  }

  return [
    ...currentAttacks,
    { actionId }
  ]
}

export function addAttackToPlayerDataInRedux(actionId="", autoEquip=true) {

  const {allPlayerAttacks, acquiredMembers} = store.getState().playerData;

  let payload = {
    allPlayerAttacks: addAttackToPlayer(actionId, allPlayerAttacks),
  };


  //maybe auto equip, too
  if (autoEquip) {
    payload.acquiredMembers = autoEquipActionIdToCharacter(actionId, "hero", acquiredMembers);
  }

  //Check if we actually did auto equip
  let didEndUpAutoCompleting = false;

  const hero = payload.acquiredMembers && payload.acquiredMembers.find(c => c.id === "hero");
  if (hero) {
    const newLoadoutAfterAutoEquip = Object.keys(hero.equippedAttacks).map(key => hero.equippedAttacks[key]);
    didEndUpAutoCompleting = newLoadoutAfterAutoEquip.includes(actionId);
  }
  ////////////////////////


  setPlayerDataValue(payload);
  setOverworldValue({
    showGlobalHudUnreadBadge: !didEndUpAutoCompleting, //Don't set these if you auto equipped
    showUnreadScriptsBadge: !didEndUpAutoCompleting, //Don't set these if you auto equipped
  })

}


export function autoEquipActionIdToCharacter(actionId, memberId, acquiredMembers) {

  const isSuper = actionId.startsWith("super_");
  return acquiredMembers.map(m => {
    if (m.id === memberId) {

      //Bail here if we already have this actionId equipped
      if (Object.keys(m.equippedAttacks).map(key => m.equippedAttacks[key]).includes(actionId)) {
        return m;
      }

      let updatedEquippedAttacks = {
        ...m.equippedAttacks
      };
      //Find an open slot to equip this new actionId
      if (isSuper) {
        updatedEquippedAttacks["slotSuper"] = updatedEquippedAttacks["slotSuper"] || actionId
      } else {
        let firstRegularKey = ["slot1", "slot2", "slot3", "slot4"].find(key => !updatedEquippedAttacks[key])
        if (firstRegularKey) {
          updatedEquippedAttacks[firstRegularKey] = actionId
        }
      }
      return {
        ...m,
        equippedAttacks: updatedEquippedAttacks
      }
    }
    return m;
  });


}