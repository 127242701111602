import {getQueryVariable} from "../helpers/get-query-variable";

export function getDefaultChapter() {

  if (process.env.REACT_APP_FIREBASE_CONFIG === "production") {
    return "chapterJRQ"
  }

  //if this is dev/stage env only
  if (getQueryVariable("load") === "ch2") {
    return "chapterMTG"
  }
  if (getQueryVariable("load") === "ch3") {
    return "chapterSHAW"
  }
  if (getQueryVariable("load") === "ch4") {
    return "chapterTICKETS"
  }
  if (getQueryVariable("load") === "ch5") {
    return "chapterCONF"
  }
  if (getQueryVariable("load") === "ch6") {
    return "chapterHWY"
  }
  if (getQueryVariable("load") === "ch7") {
    return "chapterTTB"
  }
  if (getQueryVariable("load") === "end") {
    return "chapterENDING"
  }
  ///

  return "chapterJRQ"
}