
//see `handleSaveGame` for reference on what a saveFile looks like
import {setGlobalValue} from "../redux-actions/global-actions";
import {setOverworldValue} from "../redux-actions/overworld-actions";
import {getNextExternalEventTrigger} from "../Overworld/getIncrExternalEventTrigger";

export function handleLoadGame(saveFile) {

  console.log('load this game!', saveFile)

  setGlobalValue({
    currentGameFileId: saveFile.id,
    gameArea: "changingChapter",
    changeToChapterId: saveFile.chapterId,
    loadGameData: {
      //Data that will be merged into the game
      ...saveFile
    }
  });
  setOverworldValue({
    isPauseMenuOpen: false,
    externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-LOAD-GAME"),
    overworldFadeType: null,
  })

}