export function getNextOptionFromArrowing(direction="left", menuOptionsLayout=[], currentlyActiveKey="") {
    /**
     * EXAMPLE
         menuOptionsLayout: [
            ["firstViewKey", "secondViewKey"],
            ["thirdViewKey", "fourthViewKey"],
            ["fifthViewKey", "sixthViewKey"],
        ]
     */

    //Default return. If it's just an empty array, return whatever is active.
    if (menuOptionsLayout.length === 0) {
        return currentlyActiveKey;
    }

    let selectedRowIndex;
    let selectedKeyPosition;
    menuOptionsLayout.forEach((row, index) => {
        const positionInRow = row.indexOf(currentlyActiveKey);
        if (positionInRow > -1) {
            selectedKeyPosition = positionInRow;
            selectedRowIndex = index;
        }
    });

    if (typeof selectedRowIndex !== "undefined" && typeof selectedKeyPosition !== "undefined") {

        //Left and Right
        const selectedRow = menuOptionsLayout[selectedRowIndex];
        if (direction === "left") {
            const candidate = selectedRow[selectedKeyPosition-1];
            return candidate || currentlyActiveKey; //Return the one to the right, or the same one we're on
        }
        if (direction === "right") {
            const candidate = selectedRow[selectedKeyPosition+1];
            return candidate || currentlyActiveKey; //Return the one to the right, or the same one we're on
        }

        //Up and Down
        if (direction === "down") {
            const nextRowDown = menuOptionsLayout[selectedRowIndex+1];
            if (nextRowDown) {
                const candidate = nextRowDown[selectedKeyPosition];
                if (candidate) {
                    return candidate
                } else {
                    //If this spot is null, do not move anywhere.
                    if (candidate === null) {
                        return currentlyActiveKey
                    }
                    return nextRowDown[nextRowDown.length-1];
                }
            } else {
                return currentlyActiveKey; //No change. return the current one.
            }
        }

        if (direction === "up") {
            const nextRowUp = menuOptionsLayout[selectedRowIndex-1];
            if (nextRowUp) {
                const candidate = nextRowUp[selectedKeyPosition];
                if (candidate) {
                    return candidate
                } else {
                    //If this spot is null, do not move anywhere.
                    if (candidate === null) {
                        return currentlyActiveKey
                    }
                    //No matching horizontal member on this new Row, so just use the last one
                    return nextRowUp[nextRowUp.length-1];
                }
            } else {
                return currentlyActiveKey; //No change. return the current one.
            }
        }

    }
}
