import {shuffle} from "../../../helpers/shuffle";

export default function({combatants=[], battleStoryPoints=[], regularDecision, roundNumber}) {

  //Do this every 3 turns:
  if (roundNumber % 3 === 0) { //3

    const randomAliveTeamOnePerson = shuffle(combatants.filter(c => c.hp > 0 && c.belongsToTeam === "one"));
    if (randomAliveTeamOnePerson.length) {

      const arena = combatants.find(c => c.belongsToTeam === "arena");
      const target = randomAliveTeamOnePerson[0];
      return {
        submission: [
          {
            actionId: "speak",
            casterId: arena.id,
            targetIds: [target.id],
            mergeData: {
              useSfx: "sfx_serverWarning", //blop blop blop (maybe make this a custom sfx someday? siren warning?)
              dialogContent: [
                "Server security system engaged!"
              ]
            }
          },
          {
            actionId: "arena_security-grid-zap",
            casterId: target.id,
            targetIds: [target.id],
          },
        ],
        updatedBattleStoryPoints: battleStoryPoints
      }
    }
  }

  //Maybe submit something custom, OR just use regularDecision which goes through the usual decision class
  //console.log('default to this', regularDecision)
  return {
    submission: regularDecision,
    updatedBattleStoryPoints: battleStoryPoints
  }
}
