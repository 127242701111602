export function removeSlotNumberFromBattleCrew(acquiredMembers, slotNumberToRemove) {

  return acquiredMembers.map(m => {
    if (m.battleCrewSlot === slotNumberToRemove) {
      return {
        ...m,
        battleCrewSlot: null
      }
    }
    //Change any higher people to be one place below (so we never have EX: 1 and 3 only)
    if (typeof m.battleCrewSlot === "number" && m.battleCrewSlot > slotNumberToRemove) {
      return {
        ...m,
        battleCrewSlot: m.battleCrewSlot - 1
      }
    }
    return m;
  });


}