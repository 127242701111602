import React from 'react'
import {connect} from 'react-redux'
import {setOverworldValue} from "../redux-actions/overworld-actions";
import {getNextExternalEventTrigger} from "../Overworld/getIncrExternalEventTrigger";
import {getCombatantStats} from "../Battle7/getCombatantStats";
import {getCurrentLevelFromXp} from "../getCurrentLevelFromXp";
import store from '../init/store'
import {setPlayerDataValue} from "../redux-actions/player-data-actions";
import {getRecoverMenu} from './getRecoverMenu'
import ArrowMenu from "../ArrowMenu/ArrowMenu";
import ScrollingOptionsList from "../menu-components/ScrollingOptionsList";
import BoxDivBody from "../interface/BoxDivBody";
import BoxDiv from '../interface/BoxDiv'
import BoxDivTippyTop from '../interface/BoxDivTippyTop'
import StaticTextLine from "../StaticTextLine";
import {changePlayerCreditsInRedux} from "../Overworld/player-data-operations/changePlayerCurrency";
import Icon from '../interface/Icon'
import {getBattleCrewFromRedux} from "../Overworld/player-data-operations/getBattleCrew";
import {handleSaveGameEvent} from "../loading/handleSaveGame";
import {audioManager} from "../audio/audioManager";

class RecoverMenu extends React.Component {

  constructor(props) {
    super(props);

    const battleCrew = getBattleCrewFromRedux();

    this.isWholeCrewFull = battleCrew.every(c => c.hp >= c.maxHp);

    const {menu, map, chargeCredits} = getRecoverMenu({
      isWholeCrewFull: this.isWholeCrewFull,
      credits: props.credits
    });
    this.menu = menu;
    this.map = map;
    this.chargeCredits = chargeCredits
  }

  handler = (action) => {

    if (action.type === "RECOVER") {
      this.handleRecoverCrew(); //recover people
      changePlayerCreditsInRedux(this.chargeCredits * -1); //charge credits

      audioManager.playSfx("sfx_fillHp")
      handleSaveGameEvent();
      this.handleCloseMenu(); //close
    }

    if (action.type === "GO_BACK") {
      this.handleCloseMenu();
    }
  };

  handleRecoverCrew = () => {
    const updatedMembersWithFullHp = store.getState().playerData.acquiredMembers.map(c => {
      const {maxHp} = getCombatantStats({level: this.props.level});
      return {
        ...c,
        hp: maxHp
      }
    });
    setPlayerDataValue({
      acquiredMembers: updatedMembersWithFullHp
    })
  };

  handleCloseMenu = () => {
    setOverworldValue({
      isRecoverMenuOpen: false,
      externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-RECOVERMENU-CLOSE")
    })
  };


  render() {
    const {pixelSize, credits} = this.props;
    const {menu, map} = this;
    return (
      <div style={{
        position: "absolute",
        top: pixelSize * 9,
        left: 0,
        right:0,
        display: "flex",
        justifyContent: "center",
      }}>
        <BoxDiv pixelSize={pixelSize}>
          <BoxDivTippyTop
            pixelSize={pixelSize}
          />
          <BoxDivBody pixelSize={pixelSize} style={{
            padding:pixelSize * 6,
            paddingTop :pixelSize * 2,
            width: pixelSize * 150
          }}>

            {/* Your credits */}
            <div style={{position: "absolute", right:pixelSize * -10, top:pixelSize * -8}}>
              <BoxDiv pixelSize={pixelSize}>
                <BoxDivBody pixelSize={pixelSize} style={{
                  display: "flex", alignItems: "center", paddingLeft: pixelSize * 4
                }}>
                <div style={{position: "relative", top:0, marginRight: pixelSize * 2}}>
                  <Icon
                    category={"credit"}
                    pixelSize={pixelSize}
                  />
                </div>
                <StaticTextLine
                  pixelSize={pixelSize}
                  text={String(credits)}
                />
                </BoxDivBody>
              </BoxDiv>
            </div>

            <div style={{
              padding:pixelSize * 4,
              display: "flex",
              justifyContent: "center"
            }}>
              <StaticTextLine
                pixelSize={pixelSize}
                text={this.isWholeCrewFull ? "Your battery is full!" : "Recover team battery life?"}
              />
            </div>
            <ArrowMenu
              handler={this.handler}
              backOptionId={"back"}
              optionsPageId={"recoverMenu"} //the only one
              optionsMap={map}
              optionModels={menu}
              render={({handlers, selectedOptionId}) => {
                return (
                  <ScrollingOptionsList
                    handlers={handlers}
                    optionsMap={map}
                    optionModels={menu}
                    selectedOptionId={selectedOptionId}
                    pixelSize={pixelSize}
                    renderOptionRight={(option) => {
                      if (option.chargeCredits) {
                        return (
                          <React.Fragment>
                            <div style={{marginRight: pixelSize, marginBottom: pixelSize}}>
                            <Icon
                              category="credit"
                              pixelSize={pixelSize}
                            />
                            </div>
                            <StaticTextLine
                              pixelSize={pixelSize}
                              text={`${option.chargeCredits}`}
                            />
                          </React.Fragment>
                        )
                      }
                      return null
                    }}
                  />
                )
              }}
            />
          </BoxDivBody>
        </BoxDiv>
      </div>
    );
  }
}


export default connect((state, props) => {
  return {
    pixelSize: state.global.pixelSize,
    credits: state.playerData.currency.credits,
    level: getCurrentLevelFromXp(state.playerData.teamXp),
  }
})(RecoverMenu)