import React from 'react'
import {connect} from 'react-redux'
import TextPagesContainer from "../pixel-letter-system/TextPagesContainer";
import RevealingTextContainer from '../pixel-letter-system/RevealingTextContainer'
import {getLetterStreamFromArray} from "../pixel-letter-system/getLetterStreamFromArray";
import PixelArtTextLine from '../pixel-letter-system/PixelArtTextLine'
import {setOverworldValue} from "../redux-actions/overworld-actions";
import {getNextExternalEventTrigger} from "./getIncrExternalEventTrigger";
import BoxDiv from '../interface/BoxDiv'
import BoxDivBody from '../interface/BoxDivBody'
import TextLineBlippyIndicator from '../pixel-letter-system/TextLineBlippyIndicator'

class OverworldTextMessageBox extends React.Component {

  handleFinalDone = () => {
    //NOTE: if you add animation, this stuff should moved to happen AFTER any animation-out would occur. This is the very last thing that will unmount this guy.
    setOverworldValue({
      dialogMessageContent: null,
      externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-MESSAGES__ANIMATED-OUT")
    })
  };

  render() {
    const {pixelSize, textArray} = this.props;

    return (
      <div
        style={{
          position:"absolute",
          bottom: pixelSize*16,
          left: pixelSize*4,
          right:pixelSize*4,
          cursor: "pointer",
          zIndex: "3"
      }}>
        <TextPagesContainer
          textArray={textArray}
          onFinalDone={this.handleFinalDone}
          render={({currentPageContent, index, handleGoToNextPage, isLastPage}) => {
            const letterStream = getLetterStreamFromArray(currentPageContent, { theme: "dark", playerData: this.props.playerData });
            return (
              <RevealingTextContainer
                key={index}
                onUserPrompted={() => { handleGoToNextPage() }}
                letterStream={letterStream}
                render={({lettersRevealed, isDoneWorking}) => {
                  return (
                    <BoxDiv
                        pixelSize={pixelSize}
                    >
                      <BoxDivBody
                        pixelSize={pixelSize}
                        className={"Dpad--NoShow"}
                        style={{
                          lineHeight: 0,
                          paddingTop: pixelSize * 4,
                          paddingLeft: pixelSize * 8,
                          paddingRight: pixelSize * 8,
                          paddingBottom: pixelSize * 4,
                          minHeight: pixelSize * 56,
                          position: "relative"
                      }}>
                        <PixelArtTextLine
                          pixelSize={pixelSize}
                          letterStream={letterStream}
                          useReveal={true}
                          lettersRevealed={lettersRevealed}
                        />
                        <TextLineBlippyIndicator
                          pixelSize={pixelSize}
                          status={
                            function() {
                              if (isDoneWorking) {
                                return isLastPage ? "solid" : "blinking";
                              }
                              return null;
                            }()
                          }
                        />
                      </BoxDivBody>
                    </BoxDiv>
                  )
                }}
              />
            )
          }}
        />
      </div>
    );
  }
}

export default connect((state, props) => {
    return {
      playerData: state.playerData,
      pixelSize: state.global.pixelSize,
      textArray: state.overworld.dialogMessageContent
    }
})(OverworldTextMessageBox)
