import Upgrades from "../Upgrades";

export function getAttackDamage(base, casterModel={}, targetModel={}, allUpgrades) {

  const upgrades = allUpgrades || Upgrades;

  //Maybe we are shielded?
  if (targetModel.status === "block") {
    return -1;
  }

  //base and stats
  let total = base + (casterModel.attackModifier || 0) + (casterModel.attackStat || 0);

  if (targetModel.status !== "frail") {
    total -= (targetModel.defenseModifier || 0);
  }
  if (targetModel.status === "frail") {
    total *= 2;
  }

  if (casterModel.status === "fury") {
    total += 4; //Boost as if the attack was more powerful
  }

  //Upgrades------------------------------------------------------
  //Caster Upgrades
  const casterUpgrade = upgrades[casterModel.activeUpgradeId || null];
  if (casterUpgrade) {

    if (casterUpgrade.upgradeAttackModifier) {
      total += casterUpgrade.upgradeAttackModifier;
    }

    if (casterUpgrade.upgradeAttackWhenHpBelowPercent) {
      const currentPercent = Math.round(casterModel.hp / casterModel.maxHp * 100);
      if (currentPercent <= casterUpgrade.upgradeAttackWhenHpBelowPercent[0]) {
        total += casterUpgrade.upgradeAttackWhenHpBelowPercent[1];
      }
    }
    if (casterUpgrade.upgradeAttackWithStatus && casterModel.status === casterUpgrade.upgradeAttackWithStatus[0]) {
      total += casterUpgrade.upgradeAttackWithStatus[1]
    }

  }

  //Target upgrades
  const targetUpgrade = upgrades[targetModel.activeUpgradeId || null];
  if (targetUpgrade) {
    if (targetUpgrade.upgradeDefenseModifier) {
      total -= targetUpgrade.upgradeDefenseModifier
    }
    if (targetUpgrade.upgradeDefenseWithStatus && targetModel.status === targetUpgrade.upgradeDefenseWithStatus[0]) {
      total -= targetUpgrade.upgradeDefenseWithStatus[1]
    }
  }

  //Final Payload ---------------------------------------------------
  //Convert to negative number
  total = total * -1; //negative number = HP goes down
  if (total > 0) { total = -1 }
  return total;
}


export function getIterativeAttackDamage(base, casterModel={}, targetModel={}, allUpgrades={}) {
  let total = base + (casterModel.attackModifier || 0) + Math.round((casterModel.attackStat || 1) / 3); //divide by a number to nerf the stat a bit

  if (total < 1) { total = 1 }

  if (targetModel && targetModel.status === "frail") {
    total += 4;
  }

  return total * -1;
}

export function getStatusRemovingFromDamageSteps(casterModel={}, targetModel={}, allUpgrades={}) {
  if (targetModel.status === "frail" || targetModel.status === "block") {
    return [
      {
        type: "eventBlippy",
        subjects: [targetModel.id],
        testIsRemovingStatusStep: targetModel.status, //for test
        stateChanges: [
          {
            combatantId: targetModel.id,
            absoluteChanges: {
              status: "normal"
            }
          }
        ],
      }
    ]
  }
  return [];
}