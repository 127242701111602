import {DefaultAction, DefaultScript} from "../Action";
import {testablePercentChance} from "../../../../helpers/testablePercentChance";
import {getAttackDamage, getStatusRemovingFromDamageSteps} from "../../formulas/getAttackDamage";

export default {
  ...DefaultAction,
  ...DefaultScript,
  name: "Push",
  description: "Offensive attack which might decrease opponent accuracy",
  icon: "disrupt",

  accuracy: 96,

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {
    const lowersAccuracy = testablePercentChance(50);
    const damage = getAttackDamage(4, casterModel, targetModel, allUpgrades);
    return [
      {
        type: "eventAnimation",
        animationId: "animationPush",
        subjects: [targetModel.id],
        delayFramesAfter: 12,
      },
      {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: targetModel.id,
            relativeChanges: {
              hp: damage
            },
            message: {
              theme: "battleNegativeHp",
              text: `${damage} HP`
            },
          }
        ],
        delayFramesAfter: lowersAccuracy ? 40 : 60
      },
      lowersAccuracy ? {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: targetModel.id,
            relativeChanges: {
              accuracyModifier: -3
            },
            message: {
              theme: "battleNegativeHp",
              text: "-Accuracy"
            }
          }
        ]
      } : null,
      ...getStatusRemovingFromDamageSteps(casterModel, targetModel, allUpgrades)
    ].filter(d => d)
  }
}