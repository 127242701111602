import {handleTrackEvent} from '../loading/handleTrackEvent'
import store from '../init/store'

let firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');


//Add a manually timed Promise so we can extend the loading state for more smooth UX
//Those Firebase calls are just so fast!
let paddedResponse = (promises=[], minWait=1000, masterResponse) => {
  const delay = new Promise(resolve => {
    setTimeout(() => {
      resolve(true);
    }, minWait);
  });
  Promise.all([...promises, delay]).then(masterResponse)
};

export function authCreateAccount({name, email, password, success, fail}) {

  let errors = null;
  const promise = new Promise(resolve => {

    firebase.auth().createUserWithEmailAndPassword(email, password).then(response => {
      //add the name to this persons account
      firebase.database().ref(`/users/${response.user.uid}/name`).set(name);

      //Side effect! Track in monitor
      handleTrackEvent({
        eventType: "createAccount",
        gameArea: store.getState().global.gameArea
      });
      //End side effect

      resolve();
    }).catch(function(error) {
      // Handle Errors here.
      errors = function() {
        if (error.code === "auth/email-already-in-use") {
          return ["This email address is already in use by another account."]
        }
        return ["There was an error."]
      }();
      resolve();
    });
  });

  paddedResponse([promise], 1200, () => {
    errors ? fail({errors}) : success()
  });
}


export function authLogIn({email, password, success, fail}) {

  let errors = null;
  const promise = new Promise(resolve => {
    firebase.auth().signInWithEmailAndPassword(email, password).then(() => {
      resolve();
    }).catch(function(error) {

      //Handle Errors here.
      errors = function() {
        if (error.code === "auth/wrong-password") {
          return ["Email/Password combination is not correct."]
        }
        return ["There was an error!"]
      }();

      resolve();
    });
  });

  paddedResponse([promise], 1200, () => {
    errors ? fail({errors}) : success()
  });
}


export function authRequestPasswordReset({email, success, fail}) {

  let errors = null;
  const promise = new Promise(resolve => {

    firebase.auth().sendPasswordResetEmail(email).then(function() {
      // Email sent.
      resolve();
    }).catch(function(error) {
      // An error happened.
      errors = ["An error occurred!"];
      resolve();
    });

  });

  paddedResponse([promise], 600, () => {
    errors ? fail({errors}) : success()
  });
}

export function authLogOut() {
  firebase.auth().signOut().then(function() {
    // Sign-out successful.
  }, function(error) {
    // An error happened.
  });
}