import React from 'react';
import { connect } from 'react-redux'
import {chapter} from "../ChapterGetter";

class OverworldBackground extends React.PureComponent {
 render() {
   const {/*pixelSize,*/ currentMapId} = this.props;

   const backgroundRule = function() {

     //let mapBackgroundColor = currentMapId ? chapter.get().maps[currentMapId].mapBackgroundColor : "#ddd";
     return currentMapId ? chapter.get().maps[currentMapId].mapBackgroundColor : "#ddd";

     //Try to use an image that matches pixelSize but default to big (5) if bigger than 5
     // let bgImg = `/images/bg-textures/bg-5px.png`;
     // if (pixelSize >= 1 && pixelSize <= 4) {
     //   bgImg = `/images/bg-textures/bg-${pixelSize}px.png`
     // }
     //
     // return `url(${bgImg}) repeat repeat, ${mapBackgroundColor}`;
   }();

   return (
       <div style={{
         position: "fixed",
         left: 0,
         top: 0,
         bottom: 0,
         right: 0,
         transition: "background 0.2s ease",
         background: backgroundRule
       }}>
       </div>
   )
 }
}

export default connect((state, props) => {
    return {
      currentMapId: state.overworld.currentMapId
    }
})(OverworldBackground)