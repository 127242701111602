export function getPixelSize(windowWidth, windowHeight) {

  const cellSize = 32;
  const rowInCells = 7 + 0.5;
  const columnInCells = 7 + 0.5;

  const firstValidPixelSize = [4,3,2,1].find(pixelSize => {

    const totalPixelRowSize = (pixelSize * cellSize) * rowInCells;
    const totalPixelColumnSize = (pixelSize * cellSize) * columnInCells;

    //console.log(totalPixelRowSize, totalPixelColumnSize);
    return ( windowWidth >= totalPixelRowSize && windowHeight >= totalPixelColumnSize )
  });

  return firstValidPixelSize || 1;
}