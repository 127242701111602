//WATCH OUT: This is not the source of truth. Please copy over from DS2017/AllCombatants
//This is just for dev testing
export default [
  {
    id: "enemyA",
    name: "Friend 1",
    class: "support",
    level: 18,
    skinId: "zombie2",
    useScriptedDecisionFn: "friendRecoversOliver", //comes with scripted moves
    attacks: [
      {actionId: "script_fury"},
      {actionId: "script_foreach"},
      {actionId: "script_sql-injection"},
    ],
    items: [
    ],
    personality: ["mischief:1", "vicious:3", "positiveStatus:2"],
  },

  {
    id: "enemyB",
    //hp: 0,
    //
    isLeadEnemy: true, //will make the ending messaging point at her (and other misc things)
    useMusic: "music_boss1",
    name: "Oliver",
    class: "lead",
    level: 18,
    skinId: "zombieOliver",
    playerVictoryMessage: "Oliver is always in pain.",
    playerDefeatMessage: "Don't touch Oliver's friends!",
    useScriptedDecisionFn: "oliver", //comes with scripted moves
    attacks: [
      {actionId: "script_push"},
    ],
    items: [
      {actionId: "item_battery-pack"},
      {actionId: "item_battery-pack"},
      {actionId: "item_heal-fire"},
    ],
    //immunities: ["lag", "nervous"],
    speedModifier: 4,
    defenseModifier: 5,
  },
  {
    id: "enemyC",
    name: "Friend 2",
    class: "support",
    level: 18,
    skinId: "zombie1",
    useScriptedDecisionFn: "friendRecoversOliver", //comes with scripted moves
    attacks: [
      {actionId: "script_headphones"},
      {actionId: "script_foreach"},
      {actionId: "script_sql-injection"},
    ],
    items: [
    ],
    personality: ["mischief:1", "vicious:3", "positiveStatus:2"],
  }
].map(e => {
  return {
    ...e,
    belongsToTeam: "two"
  }
})