import store from "../init/store";
import { setOverworldValue } from "../redux-actions/overworld-actions";
import { getXpFromWinningBattle } from "../getXpFromWinningBattle";
import BattleActions from "../Battle7/battle-data/battle-actions-data";
import listingTextHelper from '../helpers/listing-text-helper'
import {shuffle} from "../helpers/shuffle";

//How much XP was made, did we acquire items, etc?
export function getInteractionsFromBattleResults(results = null) {
  if (!results) {
    return null;
  }

  const { enemies } = results;
  const addCompoundStoryPointsAfterBattle = results.addCompoundStoryPointsAfterBattle || [];

  const xpGained = getXpFromWinningBattle(enemies);
  let itemIdsGained = [];
  let creditsGained = 0;


  let overworldPlayerVictoryMessage = null;

  enemies.forEach(enemy => {
    if (enemy.dropsItems) {
      itemIdsGained = [...itemIdsGained, ...enemy.dropsItems];
    }
    if (enemy.dropsCredits) {
      creditsGained += enemy.dropsCredits;
    }

    //Find the first overworldPlayerVictoryMessage (nice for when talker is in the middle, like Oliver leadEnemy stuff)
    if (!overworldPlayerVictoryMessage && enemy.overworldPlayerVictoryMessage) {
      overworldPlayerVictoryMessage = enemy.overworldPlayerVictoryMessage;
    }

  });

  //Pick a random item drop so you only gain 1
  if (itemIdsGained.length > 1) {
    itemIdsGained = [ shuffle(itemIdsGained)[0] ]; //Only get one of the dropped items
  }

  const noDrops = itemIdsGained.length === 0 && creditsGained === 0;





  let payload = [
    {
      type: "wait",
      frames: 45
    },

    overworldPlayerVictoryMessage
      ? {
          type: "textMessage",
          isOverworldDialogMessage: true,
          textArrays: [overworldPlayerVictoryMessage]
        }
      : null,
    {
      type: "wait",
      frames: 20
    },
    {
      type: "showBattleWinPopup",
      xpGained
    },
    noDrops ? null : {
      type: "characterJump",
      subjectId: "hero",
      direction: "DOWN",
      jumpType: "single",
      postDelayMs: 500
    },

    ...itemIdsGained.map(itemId => {
      return {
        type: "receiveItem",
        itemId
      };
    })
  ].filter(d => d);

  if (creditsGained > 0) {
    payload.push({
      type: "updateCredits",
      creditType: "credits",
      adjustAmount: creditsGained
    });
  }

  //Tell us what happened!
  let textMessageStep = (function() {

    let itemsText = listingTextHelper(itemIdsGained.map(id => BattleActions[id].name));

    let enemiesText = enemies[0].name;
    const isHackers = Boolean(enemies.find(e => e.isHacker));
    if (isHackers) {
      enemiesText = enemies.length > 1 ? "the hackers" : "the hacker";
    }

    let textMessageEvent = {
      isGainageStep: true,
      type: "textMessage",
      textArrays: []
    };

    if (itemIdsGained.length > 0 && creditsGained > 0) {
      textMessageEvent.textArrays.push([
        `[FAST]Received ${itemsText} and ${creditsGained} credits for defeating ${enemiesText}!`
      ]);
      return textMessageEvent;
    }
    if (itemIdsGained.length === 0 && creditsGained > 0) {
      textMessageEvent.textArrays.push([
        `[FAST]Received ${creditsGained} credits for defeating ${enemiesText}!`
      ]);
      return textMessageEvent;
    }
    if (itemIdsGained.length > 0 && creditsGained === 0) {
      textMessageEvent.textArrays.push([
        `[FAST]Received ${itemsText} for defeating ${enemiesText}!`
      ]);
      return textMessageEvent;
    }

    return null;
  })();
  payload.push(textMessageStep);

  payload.push({
    type: "updateHp",
    percent: 0.25
  });

  //Add saves game
  if (addCompoundStoryPointsAfterBattle.length === 0) {
    //Only add this if we're not adding compound points too, because the journal popup will autosave for us
    payload.push({
      type: "saveGame"
    });
  }

  addCompoundStoryPointsAfterBattle.forEach(sp => {
    payload.push({
      "type": "addStoryPoint",
      "addStoryPoint": sp
    })
  });

  //Clear out the characterJump if we did one
  payload.push({
    "type": "characterStand",
    "subjectId": "hero",
    postDelayMs: 100
  });

  return payload.filter(d => d);
}


export function getInteractionsForBattleResultsFromRedux() {
  //Get them
  const payload = store.getState().overworld.battleResults;

  //Delete theme so they don't linger (and so that they don't show up again when we mount a new map)
  setOverworldValue({
    battleResults: null
  });

  //Return them
  return getInteractionsFromBattleResults(payload || null);
}
