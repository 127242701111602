import {getBattleCrewFromRedux} from "../Overworld/player-data-operations/getBattleCrew";
import {getCurrentLevelFromXp} from "../getCurrentLevelFromXp";
import store from '../init/store'

export function getPlayerBattlePartyFromRedux() {

  const {teamXp, inventory, allPlayerUpgrades} = store.getState().playerData;

  const crew = getBattleCrewFromRedux();
  const teamLevel = getCurrentLevelFromXp(teamXp);

  const teamUpgrades = allPlayerUpgrades.map(u => u.libraryId);

  return [

    ...crew.map(c => {

      let heroSpecificProperties = c.id === "hero" ? {
        //Initial hero stuff:
        speedModifier: 1,
        attackModifier: 1,
          //hero brings the team items
        items: [...inventory].map(i => ({...i, isBugged: false})) //remove the bugging
      } : {};

      return {
        //I don't want this to be a spread because we should be careful to only have combatants be clean necessary data
        //That might make it more manual at times but you'll live :)
        id: c.id,
        name: c.name,
        class: c.class,
        skinId: c.skinId,
        hp: c.hp,
        level: teamLevel,
        activeUpgradeId: c.activeUpgradeId || null,
        attacks: ["slot1", "slot2", "slot3", "slot4"].map(slot => {
          const attackId = c.equippedAttacks[slot];
          if (!attackId) { return null }
          return {
            actionId: attackId
          }
        }).filter(d => d),
        supers: ["slotSuper"].map(slot => {
          const attackId = c.equippedAttacks[slot];
          if (!attackId) { return null }
          return {
            actionId: attackId
          }
        }).filter(d => d),
        upgrades: teamUpgrades,


        ...heroSpecificProperties,
        belongsToTeam: "one",
        isPlayerControlled: true, //freeWill actions will not auto submit
      }
    })


    // {
    //   id: "hero",
    //   name: "Jerry",
    //   level: 1,
    //   skinId: "f1",
    //   class: "lead",
    //   attacks: [
    //     {actionId: "script_slice"},
    //     {actionId: "script_curl"},
    //   ],
    //   supers: [
    //   ],
    //   items: [
    //   ],
    //   upgrades: [
    //     //...Object.keys(Upgrades)
    //   ],
    //
    //
    //   //Initial hero stuff:
    //   speedModifier: 1,
    //   attackModifier: 1,
    //
    //   //Automatic for Player Team stuff:
    //   belongsToTeam: "one",
    //   isPlayerControlled: true, //freeWill actions will not auto submit
    // }
  ]

}