import React from "react";
import { connect } from "react-redux";
import { getCrewMemberMenu } from "./getCrewMemberMenu";
import { getCrewSwapMenu } from "./getCrewSwapMenu";
import ScrollingOptionsList from "../menu-components/ScrollingOptionsList";
import StaticTextLine from "../StaticTextLine";
import ArrowMenu from "../ArrowMenu/ArrowMenu";
import BoxDiv from "../interface/BoxDiv";
import BoxDivBody from "../interface/BoxDivBody";
import { equipAttackToCharacerInRedux } from "../equipping/equipAttackToCharacter";
import { getBattleCrew } from "../Overworld/player-data-operations/getBattleCrew";
import Icon from "../interface/Icon";
import PauseHeader from "./PauseHeader";
import {setOverworldValue} from "../redux-actions/overworld-actions";
import { audioManager } from "../audio/audioManager";
import { swapEntriesInArray } from "./swapEntriesInArray";

class PauseCrewArrowMenu extends React.Component {
  state = {
    rememberedOptions: {}, //built up as you navigate the menu
    crewMenuId: "member", //"member" | "swap"
    activeMemberId: this.props.battleCrewIds[0],
    isSuperOptionOnLeft: false,
    allActionIds: this.props.allPlayerAttacks.map(a => a.actionId) //flat list of ids that we can change the order of within this component
  };

  componentDidMount() {
    setOverworldValue({
      showUnreadScriptsBadge: false,
      showGlobalHudUnreadBadge: false,
    })
  }


  optionHandler = (action = {}) => {
    if (action.type === "OPEN_SWAP_MENU") {

      audioManager.playSfx("sfx_nextPage");

      this.setState({
        isSuperOptionOnLeft: action.slotKey === "slotSuper",
        crewMenuId: "swap",
        swappingSlotKey: action.slotKey,
        swappingFromAction: action.currentAction
      });
    }

    if (action.type === "EXECUTE_SWAP") {
      const activeMember = this.props.acquiredMembers.find(
        m => m.id === this.state.activeMemberId
      );
      const wasActionId =
        activeMember.equippedAttacks[this.state.swappingSlotKey];

      //Swap old ID with new ID in state array (so the old action takes the place of the new action in the right list)
      const newActionIdsOrder = swapEntriesInArray(this.state.allActionIds, action.actionId, wasActionId)

      audioManager.playSfx("sfx_submitAction");

      this.setState(
        {
          allActionIds: newActionIdsOrder
        },
        () => {
          equipAttackToCharacerInRedux(
            this.state.activeMemberId,
            this.state.swappingSlotKey,
            action.actionId
          );
          this.setState({
            isSuperOptionOnLeft: action.isSuperSwap, //make sure we're still in Supers mode if we just swapped in a super
            swappingSlotKey: null, //clear out the swap
            crewMenuId: "member" //close the swap menu
          });
        }
      );
    }

    if (action.type === "PREV_MEMBER") {
      const currentMemberIndex = this.props.battleCrewIds.indexOf(
        this.state.activeMemberId
      );
      const prevMember = this.props.battleCrewIds[currentMemberIndex - 1];
      if (prevMember) {

        audioManager.playSfx("sfx_nextPage"); //pane to prev member

        this.setState({
          activeMemberId: prevMember
        });
      }
    }
    if (action.type === "NEXT_MEMBER") {

      const currentMemberIndex = this.props.battleCrewIds.indexOf(
        this.state.activeMemberId
      );
      const nextMember = this.props.battleCrewIds[currentMemberIndex + 1];
      if (nextMember) {

        audioManager.playSfx("sfx_nextPage"); //pane to next member

        this.setState({
          activeMemberId: nextMember
        });
      }
    }
    if (action.type === "GO_BACK") {
      if (this.state.crewMenuId === "swap") {
        this.setState({
          swappingSlotKey: null, //clear out the swap
          crewMenuId: "member" //close the swap menu
        });
      } else {
        this.props.handleChangeMenuPage("home");
      }
    }
  };

  renderDescription(selectedOptionId, selectedOptionModel) {
    if (!selectedOptionModel || !selectedOptionModel.description) {
      return null;
    }
    const { pixelSize } = this.props;
    return (
      <div style={{ marginTop: pixelSize * 4 }}>
        <BoxDiv pixelSize={pixelSize}>
          <BoxDivBody
            grayTextboxTheme
            pixelSize={pixelSize}
            style={{
              paddingTop: pixelSize * 2,
              paddingLeft: pixelSize * 4,
              paddingRight: pixelSize * 4,
              minHeight: pixelSize * 42
            }}
          >
            <StaticTextLine
              key={selectedOptionId}
              pixelSize={pixelSize}
              text={selectedOptionModel.description}
            />
          </BoxDivBody>
        </BoxDiv>
      </div>
    );
  }

  render() {
    const { pixelSize, acquiredMembers, allPlayerAttacks } = this.props;
    const { activeMemberId, allActionIds } = this.state;
    const activeMember = acquiredMembers.find(m => m.id === activeMemberId);

    const isSupersMode =
      this.state.isSuperOptionOnLeft ||
      this.state.swappingSlotKey === "slotSuper";

    const { map: memberMap, menu: memberMenu } = getCrewMemberMenu({
      member: activeMember,
      allPlayerAttacks
    });

    const { map: swapMap, menu: swapMenu } = getCrewSwapMenu({
      member: activeMember,
      allActionIds,
      isSupersMode
    });

    const useMap = this.state.crewMenuId === "swap" ? swapMap : memberMap;
    const useMenu = this.state.crewMenuId === "swap" ? swapMenu : memberMenu;

    const computedMemberPageId =
      this.state.crewMenuId === "swap" ? "swap" : `member-${activeMemberId}`;

    let rememberedOptionId = this.state.rememberedOptions[computedMemberPageId] || null;
    if (computedMemberPageId === "swap") {
      rememberedOptionId = null;
    }



    const rightEmptyState = (() => {
      if (swapMap.length === 0) {
        if (this.state.isSuperOptionOnLeft) {
          return {
            text: "All Supers Equipped!"
          };
        }

        return {
          text: "All Scripts Equipped!"
        };
      }

      return null;
    })();

    return (
      <ArrowMenu
        handler={this.optionHandler}
        backOptionId={"back"}
        optionsPageId={computedMemberPageId}
        optionsMap={useMap}
        optionModels={useMenu}
        rememberedOptionId={rememberedOptionId}
        onNewOptionSelected={optionId => {
          this.setState({
            isSuperOptionOnLeft: optionId === "slotSuper"
          });
        }}
        onNewPageFirstSelectedOption={firstSelectedOptionOfNewPage => {
          //console.log("firstSelectedOptionOfNewPage", firstSelectedOptionOfNewPage)
          this.setState({
            isSuperOptionOnLeft: firstSelectedOptionOfNewPage === "slotSuper"
          });
        }}
        onNewOptionsPage={change => {
          this.setState(state => ({
            rememberedOptions: {
              ...state.rememberedOptions,
              [change.previousMenuId]: change.previousSelectedOptionId
            }
          }));
        }}
        render={({ handlers, selectedOptionId }) => {
          const selectedOptionModel = useMenu[selectedOptionId];

          //const rightSideKey = this.state.crewMenuId + this.state.isSuperOptionOnLeft + activeMemberId;

          return (
            <React.Fragment>
              <div style={{ width: pixelSize * 210 }}>
                <PauseHeader
                  title={this.state.crewMenuId === "swap" ? "Swap Script" : "Battle Scripts"}
                  handleBackClick={() => {
                    if (this.state.crewMenuId === "swap") {
                      this.setState({
                        swappingSlotKey: null, //clear out the swap
                        crewMenuId: "member" //close the swap menu
                      });
                    } else {
                      this.props.handleChangeMenuPage("home")
                    }
                  }}
                />
                <nav
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around",
                    marginBottom: pixelSize * 6
                  }}
                >
                  {this.props.battleCrew.map(member => {
                    const isActive = activeMemberId === member.id;
                    let name = member.name;
                    if (name === "Security Jack") {
                      name = "Sec. Jack"
                    }
                    return (
                      <div
                        onClick={() => {
                          //console.log('set me active!', member.id)

                          if (this.state.crewMenuId === "member") {
                            this.setState({
                              activeMemberId: member.id,
                              isSuperOptionOnLeft: false,
                              rememberedOptions: {}
                            })
                          }

                          //If swapping, click here to cancel swap
                          if (this.state.crewMenuId === "swap") {
                            this.setState({
                              activeMemberId: member.id,
                              isSuperOptionOnLeft: false,
                              rememberedOptions: {},
                              swappingSlotKey: null, //clear out the swap
                              crewMenuId: "member" //close the swap menu
                            });
                          }
                        }}
                        key={member.id}
                        className={!isActive ? "PauseMenu__hover__memberName" : ""}
                        style={{
                          width: "33%",
                          height: pixelSize * 18,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                          cursor: "pointer"
                        }}
                      >
                        <div style={{ marginRight: pixelSize * 4 }}>
                          <Icon category={member.class} pixelSize={pixelSize} />
                        </div>
                        <StaticTextLine
                          pixelSize={pixelSize}
                          text={name}
                        />
                        {isActive && (
                          <div
                            style={{
                              position: "absolute",
                              left: pixelSize,
                              top: pixelSize,
                              bottom: pixelSize,
                              right: pixelSize,
                              zIndex:2,
                              boxShadow: `0 0 0 ${pixelSize}px #07bfdd, 0 0 0 ${pixelSize *
                                2}px #7de2f2, 0 0 0 ${pixelSize * 3}px #07bfdd`
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </nav>
                <div style={{ display: "flex", width: "100%" }}>
                  {/* Left side */}
                  <main style={{ width: "50%", paddingRight: pixelSize * 2 }}>
                    {/*<StaticTextLine pixelSize={pixelSize} text="Equipped" />*/}
                    <ScrollingOptionsList
                      key={computedMemberPageId}
                      isInCurrentMenu={this.state.crewMenuId === "member"}
                      handlers={handlers}
                      optionsMap={memberMap}
                      optionModels={memberMenu}
                      selectedOptionId={selectedOptionId}
                      pixelSize={pixelSize}

                      renderCustomOptionTheme={option => {
                        if (
                          this.state.crewMenuId === "swap" &&
                          this.state.swappingSlotKey &&
                          option.slotKey === this.state.swappingSlotKey
                        ) {
                          return "staged";
                        }
                        return null
                      }}
                      renderOptionRight={option => {
                        if (option.isSelected) {
                          return (
                            <div style={{ marginRight: pixelSize * 4 }}>
                              <Icon category="swap" pixelSize={pixelSize} />
                            </div>
                          );
                        }

                        return null;
                      }}
                    />
                  </main>
                  {/* Right side */}
                  <aside style={{ width: "50%", paddingLeft: pixelSize * 2 }}>
                    <div style={{ position: "relative" }}>
                      <ScrollingOptionsList
                        key={this.state.crewMenuId + this.state.isSuperOptionOnLeft + activeMemberId}
                        isInCurrentMenu={this.state.crewMenuId === "swap"}
                        optionsMinimum={6}
                        optionsLimit={6}
                        handlers={handlers}
                        optionsMap={swapMap}
                        optionModels={swapMenu}
                        selectedOptionId={selectedOptionId}
                        pixelSize={pixelSize}
                      />

                      {activeMember.isStatic && (
                        <div
                          style={{
                            position: "absolute",
                            left:0,
                            top:0,
                            right:0,
                            bottom:0,
                            display: "flex",
                            justifyContent: "center",
                            zIndex: 2,
                            background: "#fff",
                            paddingLeft: pixelSize * 4,
                          }}
                        >
                          <div>
                            <StaticTextLine
                              key={activeMember.id}
                              pixelSize={pixelSize}
                              text={`${activeMember.name} is a temporary member who cannot be modified!`}
                            />
                          </div>
                        </div>
                      )}

                      {rightEmptyState && !activeMember.isStatic && (
                        <div
                          style={{
                            position: "absolute",
                            left: pixelSize * 4,
                            top: pixelSize * 4,
                            right: pixelSize * 4,
                            display: "flex",
                            justifyContent: "center",
                            zIndex: 2
                          }}
                        >
                          <StaticTextLine
                            key={rightEmptyState.text}
                            pixelSize={pixelSize}
                            text={rightEmptyState.text}
                          />
                        </div>
                      )}
                    </div>
                  </aside>
                </div>
                {this.renderDescription(selectedOptionId, selectedOptionModel)}
              </div>
            </React.Fragment>
          );
        }}
      />
    );
  }
}

export default connect((state, props) => {
  const battleCrew = getBattleCrew(state.playerData.acquiredMembers);
  return {
    acquiredMembers: state.playerData.acquiredMembers,
    allPlayerAttacks: state.playerData.allPlayerAttacks,
    pixelSize: state.global.pixelSize,
    battleCrewIds: battleCrew.map(m => m.id),
    battleCrew: battleCrew
  };
})(PauseCrewArrowMenu);
