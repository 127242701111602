import bully from './bully.config'
import gareth from './gareth.config'
import securityjack from './securityjack.config'
import frankie from './frankie.config'
import judge from './judge.config'
import bullyAndRecruiter from './bully-recruiter.config'
import oliver from './oliver.config'
import richard1 from './richard1.config'
import richard2 from './richard2.config'
import {hackerKing1, hackerKing2, hackerKing3} from './hackerKing.config'
import drewAndGlenn from './drewAndGlenn.config'

export default {
  bully,
  gareth,
  securityjack,
  frankie,
  judge,
  bullyAndRecruiter,
  oliver,
  richard1,
  richard2,
  hackerKing1,
  hackerKing2,
  hackerKing3,
  drewAndGlenn
}