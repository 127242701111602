import React from 'react';

export default class TurnBlip extends React.PureComponent {
  render() {

    let {pixelSize, theme} = this.props;
    const style = {
      width: pixelSize * 3,
      height: pixelSize * 4,
      display: "block",
      animation: "battleTurnBlipAppears 0.3s forwards"
    };

    theme = theme || "player";
    const {barTop, barColor} = themes[theme];

    return (
      <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 3 4" shapeRendering="crispEdges">
        <path stroke={barTop} d="M0 0h3M0 1h1M2 1h1"/>
        <path stroke={barColor} d="M1 1h1M1 2h1"/>
        <path stroke="#08152e" d="M0 2h1M2 2h1M0 3h3"/>
      </svg>
    )
  }
}

const themes = {
  "player": {
    barTop: "#1f2b13",
    barInset: "#84cf3d",
    barColor: "#99e550",
  },
  "enemy": {
    barTop: "#3a0e11",
    barInset: "#c32c36",
    barColor: "#ec5555",
  }
};