import {DefaultAction, DefaultScript} from "../Action";

export default {
  ...DefaultAction,
  ...DefaultScript,

  name: "Judge Spawn",
  description: "Create teammate",
  icon: "support",
  targetingType: "single-friendly",

  shouldFailOnSingleTarget(casterModel, targetModel) {
    return null;
  },

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {
    return [
      {
        type: "eventBlippy",
        spawnCombatants: this.spawnCombatants
        // spawnCombatants: [
        //   {
        //     id: "enemyX",
        //     isPlayerControlled: true,
        //     name: "Assistant",
        //     skinId: "judge",
        //     belongsToTeam: targetModel.belongsToTeam,
        //     level: targetModel.level,
        //     class: "support",
        //     attacks: [ {actionId: "script_slice"} ]
        //   },
        //   {
        //     id: "enemyY",
        //     isPlayerControlled: true,
        //     name: "Assistant",
        //     skinId: "judge",
        //     belongsToTeam: targetModel.belongsToTeam,
        //     level: targetModel.level,
        //     class: "dps",
        //     attacks: [ {actionId: "script_slice"} ]
        //   }
        // ]
        // stateChanges: targetModels.map(targetModel => {
        //   return {
        //     combatantId: targetModel.id,
        //     relativeChanges: {
        //       hp: hpChange
        //     },
        //     message: {
        //       theme: "battlePositiveHp",
        //       text: `+${hpChange}HP`
        //     },
        //   }
        // })
      }
    ]
  }
}