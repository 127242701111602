import React, {Component} from 'react';

class ArrowMenuOption extends Component {
  render() {
    const {handlers, isInCurrentMenu, id} = this.props;
    return (
      <div
        style={{cursor: "pointer"}}
        onClick={() => {
          if (isInCurrentMenu) {
            handlers.submit()
          }
        }}
        onMouseEnter={() => {
          if (isInCurrentMenu) {
            handlers.setActive(id)
          }
        }}>
        {this.props.children}
      </div>
    )
  }
}

ArrowMenuOption.defaultProps = {
  isInCurrentMenu: true
};

export default ArrowMenuOption;