import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux'
import store from './init/store';
import ReactGA from 'react-ga';


/* Absolute root of the app */
class RootComponent extends React.Component {
  render() {
    return (
      <Provider store={this.props.store}>
        <App />
      </Provider>
    )
  }
}


ReactDOM.render(
  <RootComponent store={store}/>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


if (process.env.REACT_APP_FIREBASE_CONFIG === "production") {
  //Google Analytics:
  ReactGA.initialize('UA-75243711-2');
  ReactGA.pageview(window.location.pathname + window.location.search);
}