import React from 'react'


//Display
class TextPagesContainer extends React.Component {

  constructor() {

    super();

    this.state = {
      index: 0
    }
  }

  handleIncreaseIndex = () => {
    this.setState( state => {
      return {index: state.index + 1}
    }, () => {
      if (this.state.index > this.props.textArray.length-1) {
        this.props.onFinalDone();
      }
    })
  };

  render() {
    const {textArray} = this.props;
    if (!textArray[this.state.index]) {
      return null
    }
    return (

        this.props.render({
          index: this.state.index,
          currentPageContent: textArray[this.state.index],
          handleGoToNextPage: this.handleIncreaseIndex,
          isLastPage: this.state.index === this.props.textArray.length-1
        })

    );
  }
}

export default TextPagesContainer;