import React from 'react'
import {waitFrames} from "../../../helpers/waitFrames";
import {audioManager} from "../../../audio/audioManager";

class AnimationCharacterJump extends React.Component {

  componentDidMount() {

    if (this.props.event.useSfx !== "SKIP_JUMP_SFX") {
      audioManager.playSfx(this.props.event.useSfx || "sfx_characterJump");
    }

    this.props.event.subjects.forEach(subjectId => {
      this.props.handleCombatantStateChange([
        {
          combatantId: subjectId,
          absoluteChanges: {
            animation: this.props.animation,
          }
        }
      ])
    });

    waitFrames(50, () => {
      this.props.event.subjects.forEach(subjectId => {
        this.props.handleCombatantStateChange([
          {
            combatantId: subjectId,
            absoluteChanges: {
              animation: null
            }
          }
        ])
      });

      waitFrames(10, () => {
        this.props.onEventDone()
      });
    })
  }

  render() {
    return (
      null
    );
  }
}

export default AnimationCharacterJump;