import {hasAllRequiredStoryPoints, hasInvalidStoryPoints} from "../story-points/story-point-validity-checks";

export function getActiveScenario(scenarios=[], storyPoints=[]) {

  return scenarios.find(s => {

    if (hasInvalidStoryPoints(storyPoints, s.bypassOnStoryPoints)) {
      return false;
    }
    if (!hasAllRequiredStoryPoints(storyPoints, s.requiresStoryPoints)) {
      return false;
    }

    return true;
  }) || null;
}