export default {
  "journal_001" : {
    name: "A Bully Named Berg",
    entries: [
      {
        entryId: "journalEntry_001_001",
        entryDescription: "You discovered the first quest in Danger Crew. Nice work. Keep walking around.",
        requiresStoryPoints: ["boobear"]
      },
      {
        entryId: "journalEntry_002",
        entryDescription: "You've defeated Berg, the angry redhead. He seems to be in a better mood now. The local devs are starting to notice you!",
        requiresStoryPoints: ["b"]
      }
    ]
  },
  "journal_002" : {
    name: "Another Story",
    entries: [
      {
        entryId: "journalEntry_002_001",
        entryDescription: "Some entry",
        requiresStoryPoints: ["c"]
      },
      {
        entryId: "journalEntry_002_001",
        entryDescription: "Some other entry",
        requiresStoryPoints: ["d"]
      }
    ]
  }
};