import React from 'react';


export default class TransitionSpace extends React.PureComponent {
  render() {
    const {pixelSize, x, y, transitionDirection} = this.props;

    if (["LEFT","RIGHT","UP","DOWN"].indexOf(transitionDirection) === -1) {
      return null
    }

    const cellSize = pixelSize * 32;
    const style = {
      container: {
        overflow: "hidden",
        width: cellSize,
        height: cellSize,
        position: "absolute",
        left:0,
        top:0,
        transform: `translate3d(${cellSize*x}px, ${cellSize*y}px, 0)`,
      },
    };

    let arrow = null;
    if (transitionDirection === "LEFT") { arrow = LeftTransitionArrow(pixelSize) }
    if (transitionDirection === "RIGHT") { arrow = RightTransitionArrow(pixelSize) }
    if (transitionDirection === "UP") { arrow = UpTransitionArrow(pixelSize) }
    if (transitionDirection === "DOWN") { arrow = DownTransitionArrow(pixelSize) }

    return (
      <div style={style.container}>
        {
          arrow
        }
      </div>
    )
  }
}


function LeftTransitionArrow(pixelSize) {
  const style = {
    display: "block",
    width: pixelSize * 32
  };
  return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 32 32" shapeRendering="crispEdges">
      <path stroke="#392c1f" d="M15 10h2M14 11h1M16 11h1M13 12h1M16 12h1M12 13h1M16 13h6M11 14h1M21 14h1M10 15h1M21 15h1M10 16h1M21 16h1M11 17h1M21 17h1M12 18h1M16 18h6M13 19h1M16 19h1M14 20h1M16 20h1M15 21h2" />
      <path stroke="#dabfa5" d="M15 11h1M14 12h2M13 13h3M12 14h9M11 15h10M11 16h10M12 17h4M13 18h3M14 19h2M15 20h1" />
      <path stroke="#c0a388" d="M16 17h5" />
    </svg>
  )
}
function RightTransitionArrow(pixelSize) {
  const style = {
    display: "block",
    width: pixelSize * 32
  };
  return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 32 32" shapeRendering="crispEdges">
      <path stroke="#392c1f" d="M15 10h2M15 11h1M17 11h1M15 12h1M18 12h1M10 13h6M19 13h1M10 14h1M20 14h1M10 15h1M21 15h1M10 16h1M21 16h1M10 17h1M20 17h1M10 18h6M19 18h1M15 19h1M18 19h1M15 20h1M17 20h1M15 21h2" />
      <path stroke="#dabfa5" d="M16 11h1M16 12h2M16 13h3M11 14h9M11 15h10M11 16h10M16 17h4M16 18h3M16 19h2M16 20h1" />
      <path stroke="#c0a388" d="M11 17h5" />
    </svg>
  )
}
function UpTransitionArrow(pixelSize) {
  const style = {
    display: "block",
    width: pixelSize * 32
  };
  return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 32 32" shapeRendering="crispEdges">
      <path stroke="#392c1f" d="M15 10h2M14 11h1M17 11h1M13 12h1M18 12h1M12 13h1M19 13h1M11 14h1M20 14h1M10 15h1M21 15h1M10 16h4M18 16h4M13 17h1M18 17h1M13 18h1M18 18h1M13 19h1M18 19h1M13 20h1M18 20h1M13 21h6" />
      <path stroke="#dabfa5" d="M15 11h2M14 12h4M13 13h6M12 14h8M11 15h10M14 16h4M14 17h4M14 18h4" />
      <path stroke="#c0a388" d="M14 19h4M14 20h4" />
    </svg>
  )
}
function DownTransitionArrow(pixelSize) {
  const style = {
    display: "block",
    width: pixelSize * 32
  };
  return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 32 32" shapeRendering="crispEdges">
      <path stroke="#392c1f" d="M13 10h6M13 11h1M18 11h1M13 12h1M18 12h1M13 13h1M18 13h1M13 14h1M18 14h1M10 15h4M18 15h4M10 16h1M21 16h1M11 17h1M20 17h1M12 18h1M19 18h1M13 19h1M18 19h1M14 20h1M17 20h1M15 21h2" />
      <path stroke="#dabfa5" d="M14 11h4M14 12h4M14 13h4M14 14h4M14 15h4M11 16h10M12 17h8M14 18h4M15 19h2" />
      <path stroke="#c0a388" d="M13 18h1M18 18h1M14 19h1M17 19h1M15 20h2" />
    </svg>
  )
}