import React from "react";
import { waitFrames } from "../../../helpers/waitFrames";
import { audioManager } from "../../../audio/audioManager";

class AnimationMiss extends React.Component {

  componentDidMount() {
    audioManager.playSfx("sfx_miss");
    waitFrames(10, () => {
      this.props.onEventDone();
    });
  }

  render() {
    return null;
  }
}

export default AnimationMiss;
