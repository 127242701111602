export function getBuggedItemSteps({casterModel}) {
  //const damage = -5;
  if (typeof casterModel.maxHp === "undefined") {
    console.warn('casterModel maxHp is unknown?', casterModel)
  }

  const rateRate = casterModel.status === "frail" ? -1.7 : -2.5; //I have this high because it feels like it should be extreme. Frail does more.

  const damage = Math.round(casterModel.maxHp / rateRate);
  return [

    {
      type: "eventDialogBox",
      subject: casterModel.id,
      textArray: ["BUGGED!!"],
      isBuggedItemStep: true,
      haltQueueUntilDone: true
    },

    {
      type: "eventAnimation",
      animationId: "animationOpenBuggedItem",
      subjects: [casterModel.id],
      delayFramesAfter: 40,
    },

    //State change
    {
      type: "eventBlippy",
      stateChanges: [
        {
          combatantId: casterModel.id,
          relativeChanges: {
            hp: damage
          },
          message: {
            theme: "battleNegativeHp",
            text: `${damage} HP`
          },
        }
      ]
    }
  ]
}