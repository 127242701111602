import React from 'react'
import {waitFrames} from "../../../helpers/waitFrames";
import RunAnimation from './RunAnimation'
import { audioManager } from "../../../audio/audioManager";

class AnimationPromiseResolve extends React.Component {

  state = {
    hasTriggeredAnimation: false
  };

  componentDidMount() {

    const targets = this.props.event.subjects;

    audioManager.playSfx("sfx_promiseResolve");

    waitFrames(7, () => {
      this.setState({
        hasTriggeredAnimation: true
      }, () => {
        //Start shaking
        targets.forEach(id => {

          this.props.handleCombatantStateChange([
            {
              combatantId: id,
              absoluteChanges: {
                laptopFlash: "pain",
                animation: "characterShake",
              }
            }
          ])
        });

        //Stop shaking
        waitFrames(20, () => {
          targets.forEach(id => {
            this.props.handleCombatantStateChange([
              {
                combatantId: id,
                absoluteChanges: {
                  laptopFlash: "none",
                  animation: null,
                }
              }
            ])
          });
        });
      });
    });
  }

  render() {
    const {pixelSize, event, positions} = this.props;
    return event.subjects.map((subjectId, i) => {
      return (
        <RunAnimation
          key={subjectId}
          pixelSize={pixelSize}
          left={positions[subjectId].leftPosition}
          top={positions[subjectId].topPosition}
          widthOfSpriteSheet={288}
          frames={9}
          duration="0.6s"
          onDone={() => {
            waitFrames(10, () => {
              this.props.onEventDone()
            })
          }}
          renderPaths={() => {
            return (
              <React.Fragment>
                <path stroke="#c70707" d="M15 0h2M14 1h1M14 2h1" />
                <path stroke="#ffffff" d="M46 0h4M46 1h4M46 2h4M46 3h4M46 4h4M46 5h4M46 6h4M46 7h1M49 7h1M109 8h6M107 9h10M106 10h12M105 11h11M117 11h2M105 12h10M117 12h2M145 12h1M104 13h16M144 13h2M175 13h2M104 14h3M108 14h12M143 14h4M175 14h3M104 15h3M109 15h11M142 15h6M176 15h1M104 16h4M109 16h11M143 16h5M104 17h16M144 17h2M104 18h16M144 18h1M105 19h9M116 19h3M105 20h8M114 20h5M106 21h12M107 22h10M109 23h6" />
                <path stroke="#f67f54" d="M15 1h1" />
                <path stroke="#ff0000" d="M16 1h1M15 2h2" />
                <path stroke="#a50b0b" d="M17 1h1M17 2h1M15 3h2" />
                <path stroke="#3f3f73" d="M228 3h2M243 3h2M229 4h1M242 4h4M269 4h1M242 5h3M208 6h2M243 6h2M176 7h2M207 7h3M263 7h1M175 8h4M207 8h2M236 8h1M176 9h3M202 9h1M204 9h2M235 9h2M246 9h1M177 10h1M201 10h1M203 10h3M211 10h1M234 10h4M245 10h3M202 11h4M209 11h4M246 11h1M267 11h2M276 11h1M196 12h2M203 12h4M208 12h5M268 12h1M275 12h3M195 13h4M203 13h3M208 13h5M218 13h2M227 13h1M242 13h2M252 13h2M276 13h1M195 14h4M208 14h4M217 14h4M242 14h2M247 14h1M252 14h1M169 15h3M196 15h2M202 15h2M209 15h2M217 15h4M232 15h2M272 15h2M168 16h5M179 16h1M212 16h3M218 16h2M231 16h4M272 16h2M168 17h5M179 17h5M200 17h4M206 17h3M168 18h4M180 18h4M200 18h3M207 18h3M211 18h2M216 18h2M169 19h2M180 19h4M201 19h2M207 19h3M213 19h1M216 19h3M235 19h2M243 19h1M282 19h2M170 20h1M180 20h3M208 20h2M213 20h1M216 20h3M235 20h2M242 20h3M282 20h1M179 21h3M216 21h2M242 21h1M269 21h2M264 22h1M269 22h2M205 23h1M263 23h3M205 24h3M263 24h1M206 25h4" />
                <path stroke="#de7333" d="M235 3h2M236 4h2M202 6h3M212 6h3M246 6h4M275 6h2M47 7h2M200 7h2M204 7h2M215 7h2M247 7h4M46 8h4M173 8h2M200 8h2M205 8h1M216 8h1M249 8h3M46 9h4M171 9h1M174 9h2M179 9h3M199 9h2M216 9h2M228 9h1M251 9h1M46 10h4M173 10h4M178 10h2M181 10h2M199 10h2M216 10h2M228 10h2M46 11h4M170 11h1M172 11h6M182 11h2M199 11h3M215 11h3M228 11h1M46 12h4M169 12h6M181 12h4M200 12h1M215 12h2M280 12h1M46 13h1M49 13h1M170 13h4M180 13h6M280 13h1M171 14h3M179 14h5M263 14h2M280 14h2M172 15h2M180 15h3M173 17h1M177 17h2M198 17h2M172 18h2M177 18h3M198 18h1M141 19h1M148 19h4M171 19h2M176 19h4M197 19h1M205 19h1M211 19h2M138 20h3M147 20h1M172 20h2M175 20h4M197 20h1M205 20h1M212 20h1M274 20h1M135 21h2M147 21h1M173 21h6M198 21h1M205 21h1M210 21h2M274 21h1M146 22h1M173 22h4M199 22h4M206 22h1M209 22h3M229 22h1M248 22h1M174 23h1M200 23h3M207 23h4M229 23h2M246 23h2M145 24h1M230 24h2M244 24h4M231 25h3M144 26h1M233 26h1" />
                <path stroke="#fbf24a" d="M183 4h2M167 5h2M185 5h1M136 6h1M151 6h2M167 6h1M136 7h2M149 7h3M202 7h2M212 7h3M137 8h2M147 8h4M204 8h1M214 8h2M137 9h3M145 9h6M172 9h2M138 10h3M143 10h7M171 10h2M180 10h1M190 10h1M138 11h11M171 11h1M178 11h4M189 11h2M139 12h6M146 12h3M175 12h6M190 12h1M47 13h2M139 13h5M146 13h4M174 13h1M177 13h3M46 14h4M140 14h3M147 14h5M174 14h1M178 14h1M46 15h4M140 15h2M148 15h5M174 15h2M177 15h3M47 16h2M139 16h4M148 16h7M173 16h6M138 17h6M146 17h10M174 17h3M136 18h8M145 18h13M174 18h3M199 18h1M135 19h6M142 19h6M173 19h3M198 19h2M133 20h5M142 20h5M174 20h1M198 20h2M211 20h1M132 21h3M142 21h5M199 21h2M206 21h2M142 22h4M168 22h1M207 22h2M143 23h3M168 23h2M143 24h2M168 24h3M143 25h2M167 25h3M143 26h1M167 26h2M143 27h1M143 28h1" />
                <path stroke="#ab3536" d="M237 5h1M201 6h1M215 6h1M173 7h2M211 7h1M274 7h1M277 7h1M171 8h2M179 8h3M199 8h1M203 8h1M213 8h1M217 8h1M170 9h1M182 9h1M201 9h1M215 9h1M250 9h1M170 10h1M183 10h1M250 10h2M169 11h1M229 11h1M250 11h1M201 12h2M217 12h1M228 12h1M201 13h1M170 14h1M184 14h1M183 15h1M263 15h1M281 15h1M180 16h3M197 18h1M205 18h2M204 19h1M206 19h1M210 19h1M171 20h1M179 20h1M200 20h1M204 20h1M206 20h2M210 20h1M172 21h1M197 21h1M204 21h1M208 21h2M212 21h1M177 22h1M198 22h1M205 22h1M274 22h1M175 23h2M199 23h1M206 23h1M248 23h1M208 24h3M229 24h1M243 24h1M248 24h1M229 25h2M244 25h4M230 26h3" />
                <path stroke="#26233d" d="M77 8h6M75 9h10M74 10h12M73 11h14M73 12h14M72 13h16M72 14h16M72 15h16M72 16h16M72 17h16M72 18h16M73 19h14M73 20h14M74 21h12M75 22h10M77 23h6" />
                <path stroke="#918fa0" d="M116 11h1M115 12h2M107 14h1M107 15h2M108 16h1M114 19h2M113 20h1" />
              </React.Fragment>
            )
          }}
        />
      )
    })
  }
}

export default AnimationPromiseResolve;