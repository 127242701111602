//TODO: WATCH OUT?! The source of truth for this battle lives in dangerstudio2017

export default [
  {
    id: "enemyA",
    belongsToTeam: "two",
    level: 7,
    //
    useMusic: "music_middleBoss",
    name: "Security Jack",
    preventsBail: true,
    class: "dps",
    skinId: "securityJack",
    useScriptedDecisionFn: "securityJack", //comes with decisions
    immunities: ["lag", "nervous"],
    personality: ["statBoost", "vicious", "healHp"],
    playerDefeatMessage: "Stay out of my server room!",
    playerVictoryMessage: "Dang punks and your computers!",
    attacks: [
      {actionId: "script_slice"},
      {actionId: "script_foreach"},
    ],
    items: [
      {actionId: "item_battery-pack"},
      {actionId: "item_revive-pack"},
      {actionId: "item_danger-boost"},
    ],
    supers: [
      {actionId: "super_team-slice"},
    ],
    defenseModifier: 4,
    speedModifier: 4
  },
  {
    id: "enemyB",
    belongsToTeam: "two",
    level: 4,
    //
    name: "Defense Grid",
    class: "support",
    skinId: "defenseGrid",
    useScriptedDecisionFn: "defensegrid", //comes with decisions
    personality: ["dangerBoost", "healStatus"],
    attacks: [
      {actionId: "script_clean"},
    ],
    defenseModifier: 4,
  }
]