import store from '../init/store'
import {setPlayerDataValue} from "../redux-actions/player-data-actions";
import {getAddedCompoundStoryPoints} from "./getAddedCompoundStoryPoints";


/* Adds story point to `playerData.acquiredStoryPoints` in redux. */
/* Also returns whatever story point was successfully added */

export function addStoryPoint(newStoryPoint="") {
  const acquiredStoryPoints = store.getState().playerData.acquiredStoryPoints;

  //Bail here if we already have this story point.
  if (acquiredStoryPoints.includes(newStoryPoint)) {
    //Inform the caller that the story point was not added
    return null
  }

  let updatedStoryPoints = [
    ...acquiredStoryPoints,
    newStoryPoint
  ];

  //Maybe add Compound Story Point too
  const addedCompoundPoints = getAddedCompoundStoryPoints(updatedStoryPoints); //array
  updatedStoryPoints = [
    ...updatedStoryPoints,
    ...addedCompoundPoints
  ];

  //Update redux with our newly acquired list!
  setPlayerDataValue({
    acquiredStoryPoints: updatedStoryPoints
  });

  //Compound will take precedence over individual
  if (addedCompoundPoints.length) {
    //console.log('added CompoundStoryPoints', addedCompoundPoints[0])
    return addedCompoundPoints[0];
  }

  //Provide the caller with whatever we added
  return newStoryPoint
}


/* Removes story points from `playerData.acquiredStoryPoints` in redux. */
export function removeStoryPoints(storyPointsToRemove=[]) { //Array of strings
  const updatedStoryPoints = store.getState().playerData.acquiredStoryPoints.filter(sp => {
    if (storyPointsToRemove.includes(sp)) {
      return false
    }
    return true
  });

  //Update our Story Point list in Redux!
  setPlayerDataValue({
    acquiredStoryPoints: updatedStoryPoints
  })
}
