import {DefaultAction, DefaultScript} from "../Action";
import {getAttackDamage, getStatusRemovingFromDamageSteps} from "../../formulas/getAttackDamage";

//This is a copied version of Pop, but it's much better. For Jacob.
export default {
  ...DefaultAction,
  ...DefaultScript,
  name: "J-Pop",
  description: "Offensive attack which will remove positive enemy status",
  icon: "support",

  accuracy: 100,

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {
    const damage = getAttackDamage(6, casterModel, targetModel, allUpgrades);
    let payload = [
      {
        type: "eventAnimation",
        animationId: "animationPop",
        subjects: [targetModel.id],
        delayFramesAfter: 12,
      },
      {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: targetModel.id,
            relativeChanges: {
              hp: damage
            },
            message: {
              theme: "battleNegativeHp",
              text: `${damage} HP`
            },
          }
        ],
        delayFramesAfter: 60
      }
    ];
    const removesTargetStatus = ["focus", "fury", "deadline", "dangerous"].includes(targetModel.status);
    if (removesTargetStatus) {
      payload = [
        ...payload,
        {
          type: "eventBlippy",
          stateChanges: [
            {
              combatantId: targetModel.id,
              absoluteChanges: {
                status: "normal"
              },
              message: {
                theme: "battleNegativeHp",
                text: `Status removed!`
              }
            }
          ]
        }
      ]
    }

    payload = [
      ...payload,
      ...getStatusRemovingFromDamageSteps(casterModel, targetModel, allUpgrades)
    ];

    return payload;
  }
}