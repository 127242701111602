import React from 'react';
import Status from "./Status";

class StarEnemyHealthBar extends React.PureComponent {

  renderStars() {
    const {hp, maxHp, pixelSize, laptopFlash} = this.props;
    const percent = hp / maxHp * 100;
    const flashColor = laptopFlash === "pain" ? "rgba(255,255,255,0.5)" : null;

    if (percent >= 90) {
      //5
      return (
        <svg style={{width: pixelSize * 41}} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 41 10" shapeRendering="crispEdges">
          <path stroke={flashColor || "#7df188"} d="M1 0h39M0 1h1m39 0h1M0 2h1m39 0h1M0 3h1m39 0h1M0 4h1m39 0h1M0 5h1m39 0h1M0 6h1m39 0h1M0 7h1m39 0h1M0 8h1m39 0h1M1 9h39"/>
          <path stroke={flashColor || "#0cef23"} d="M6 3h1m6 0h1m6 0h1m6 0h1m6 0h1M4 4h5m2 0h5m2 0h5m2 0h5m2 0h5M5 5h3m4 0h3m4 0h3m4 0h3m4 0h3M5 6h1m1 0h1m4 0h1m1 0h1m4 0h1m1 0h1m4 0h1m1 0h1m4 0h1m1 0h1"/>
        </svg>
      )
    }
    if (percent >= 75) {
      //4
      return (
        <svg style={{width: pixelSize * 34}} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 34 10" shapeRendering="crispEdges">
          <path stroke={flashColor || "#d6f383"} d="M1 0h32M0 1h1m32 0h1M0 2h1m32 0h1M0 3h1m32 0h1M0 4h1m32 0h1M0 5h1m32 0h1M0 6h1m32 0h1M0 7h1m32 0h1M0 8h1m32 0h1M1 9h32"/>
          <path stroke={flashColor || "#cbf94c"} d="M6 3h1m6 0h1m6 0h1m6 0h1M4 4h5m2 0h5m2 0h5m2 0h5M5 5h3m4 0h3m4 0h3m4 0h3M5 6h1m1 0h1m4 0h1m1 0h1m4 0h1m1 0h1m4 0h1m1 0h1"/>
        </svg>
      )
    }

    if (percent >= 50) {
      return (
        <svg style={{width: pixelSize * 27}} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 27 10" shapeRendering="crispEdges">
          <path stroke={flashColor || "#fffbad"} d="M1 0h25M0 1h1m25 0h1M0 2h1m25 0h1M0 3h1m25 0h1M0 4h1m25 0h1M0 5h1m25 0h1M0 6h1m25 0h1M0 7h1m25 0h1M0 8h1m25 0h1M1 9h25"/>
          <path stroke={flashColor || "#f5ee62"} d="M6 3h1m6 0h1m6 0h1M4 4h5m2 0h5m2 0h5M5 5h3m4 0h3m4 0h3M5 6h1m1 0h1m4 0h1m1 0h1m4 0h1m1 0h1"/>
        </svg>
      )

    }

    if (percent >= 30) {
      return (
        <svg style={{width: pixelSize * 20}} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 20 10" shapeRendering="crispEdges">
          <path stroke={flashColor || "#f6a666"} d="M1 0h18M0 1h1M19 1h1M0 2h1M19 2h1M0 3h1M19 3h1M0 4h1M19 4h1M0 5h1M19 5h1M0 6h1M19 6h1M0 7h1M19 7h1M0 8h1M19 8h1M1 9h18" />
          <path stroke={flashColor || "#f67e20"} d="M6 3h1M13 3h1M4 4h5M11 4h5M5 5h3M12 5h3M5 6h1M7 6h1M12 6h1M14 6h1" />
        </svg>
      )
    }

    if (percent > 0) {
      return (
        <svg style={{width: pixelSize * 13}} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 13 10" shapeRendering="crispEdges">
          <path stroke={flashColor || "#f28075"} d="M1 0h11M0 1h1M12 1h1M0 2h1M12 2h1M0 3h1M12 3h1M0 4h1M12 4h1M0 5h1M12 5h1M0 6h1M12 6h1M0 7h1M12 7h1M0 8h1M12 8h1M1 9h11" />
          <path stroke={flashColor || "#f22b17"} d="M6 3h1M4 4h5M5 5h3M5 6h1M7 6h1" />
        </svg>
      )
    }

    return (
      null
    )
  }

  render() {
    const {pixelSize, status} = this.props;
    return (
      <div style={{
        position: "absolute",
        left:0,
        bottom:pixelSize * 35,
        //background: "red",
        width: pixelSize * 32,
        transform: "translateX(-50%)",
        marginLeft: pixelSize * 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}>
        <div>
          {this.renderStars()}
        </div>
        {status !== "normal" && <Status pixelSize={pixelSize} status={status}/>}
      </div>
    )
  }


}

export default StarEnemyHealthBar;
