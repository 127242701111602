import React from 'react'
import {connect} from "react-redux";
import Arenas from '../Battle7/Arenas'
//import AudioControl from "../audio/AudioControl";
import TopUi from '../top-ui/TopUi'

class GameFrame extends React.Component {

  render() {
    const {render, pixelSize, pageBackgroundColor} = this.props;

    const frameSize = (32 * pixelSize) * 7;

    return (
      //Whole page
      <div style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: pageBackgroundColor,
      }}>
        {/* Inner Frame */}
        <main
          style={{
            position: "relative",
            width: frameSize,
            height: frameSize,
            //background: "rgba(255,0,0,0.1)",
          }}
        >
          {
            render()
          }
          <TopUi />
        </main>
      </div>
    );
  }
}

//export default GameContainer;

export default connect((state, props) => {
  return {
    pageBackgroundColor: function() {
      if (state.global.gameArea === "battle") {
        return Arenas[state.overworld.battle_arenaId].background; //EX: #asdasd -> some hex color for the battle page background
      }

      if (["title", "comingSoon", "credits"].includes(state.global.gameArea)) {
        //return "#1b1b1b"
        return "linear-gradient(-180deg, #5DCBE4 0%, #47BCE4 100%)"
      }

      return "#000"
    }(),
    pixelSize: state.global.pixelSize
  }
})(GameFrame)