import {DefaultAction, DefaultScript} from "../Action";
import {getGenericFail} from "../../formulas/getGenericFail";

export default {
  ...DefaultAction,
  ...DefaultScript,
  name: "Thrash",
  description: "Starts a slow burn on 1 enemy",
  icon: "disrupt",

  shouldFailOnSingleTarget(casterModel, targetModel) {
    if (targetModel.status !== "normal") {
      return {
        failCode: "has-status" //could be "denied-by-upgrade" in the future, etc
      }
    }
    return null;
  },

  failEventsPerTarget(casterModel, targetModel, failInfo={}) {
    //Maybe add "WHY" here if an upgrade caused it to fail?
    return getGenericFail({targetModel})
  },

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {
    return [
      {
        type: "eventBlippy",
        subjects: [targetModel.id],
        stateChanges: [
          {
            combatantId: targetModel.id,
            absoluteChanges: {
              status: "memory-leak"
            },
            message: {
              blippyTheme: "red",
              text: `Memory Leak!`
            },
          }
        ],
      },
      {
        type: "eventDialogBox",
        textArray: [`${targetModel.name}'s laptop is getting uncomfortably warm!`],
        haltQueueUntilDone: true
      },
      {
        type: "eventSchedule",
        roundsFromNow: 2,
        combatantId: targetModel.id,
        event: {
          actionId: "natural_escalate-leak-to-fire",
          casterId: targetModel.id,
          targetIds: [targetModel.id]
        }
      }
    ]
  }
}