import {addAttackToPlayerDataInRedux} from "../Overworld/player-data-operations/addAttackToPlayerData";
import {addUpgradeToPlayerDataInRedux} from "../Overworld/player-data-operations/addUpgradeToPlayerData";
import {changePlayerCreditsInRedux} from "../Overworld/player-data-operations/changePlayerCurrency";
import {addNewItemToInventoryInRedux} from "../Overworld/player-data-operations/addNewItemToInventory";

export function handleBuyBuyable(buyable) {

  //Add the thing
  if (buyable.type === "attack") {
    addAttackToPlayerDataInRedux(buyable.id)
  }
  if (buyable.type === "upgrade") {
    addUpgradeToPlayerDataInRedux(buyable.id)
  }
  if (buyable.type === "item") {
    addNewItemToInventoryInRedux(buyable.id)
  }

  //Charge credits
  changePlayerCreditsInRedux(buyable.price * -1)


}