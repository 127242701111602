//WATCH OUT: This is not the source of truth. Please copy over from DS2017/AllCombatants
//This is just for dev testing

export default [
  {
    id: "enemyA",
    name: "Recruiter",
    class: "lead",
    skinId: "recruiter",
    useScriptedDecisionFn: "confRecruiter",
    level: 19,
    playerVictoryMessage: "Argh.",
    playerDefeatMessage: "I only want to sign GOOD developers!",
    attacks: [
      { actionId: "script_blackberry" },
      { actionId: "script_block" },
      { actionId: "script_deadline" },
      { actionId: "script_ddos" },
    ],
    items: [],
    supers: [
      {actionId: "super_wildfire"},
    ],
    attackModifier: 3,
    defenseModifier: 6,
    speedModifier: 3,
    personality: ["positiveStatus:3", "vicious", "cursing:2"],
    dropsCredits: 30,
  },
  {
    id: "enemyB",
    name: "Bully",
    class: "lead",
    skinId: "thug1",
    useScriptedDecisionFn: "confBully",
    level: 19,
    attacks: [
      { actionId: "script_slice-mk-ii" },
      { actionId: "script_intimidate" },
      { actionId: "script_headphones" },
      {actionId: "script_scope-bomb"},
    ],
    items: [],
    supers: [
      {actionId: "super_promise-all"},
    ],
    defenseModifier: 3,
    attackModifier: 3,
    speedModifier: 3,
    personality: ["positiveStatus:2", "vicious:3", "cursing:2"],
    dropsCredits: 30,
  }
].map(e => {
  return {
    ...e,
    belongsToTeam: "two"
  }
})