import {DefaultAction, DefaultNatural} from "../Action";

function createNaturalStatusEnd(properties) {
  return  {
    ...DefaultAction,
    ...DefaultNatural,

    getUse() { return [] },

    shouldFailOnSingleTarget(casterModel, targetModel, allUpgrades, actionModel={}) {

      //Check that this recovery ID matches the combatant
      let naturalActionResolveId = actionModel.naturalActionResolveId || null;
      if (naturalActionResolveId && naturalActionResolveId !== targetModel.statusResolveId) {
        //console.log('fail', 'no match', properties.endingStatus)
        return {
          failCode: "not-correct-resolve-id"
        }
      }
      if (targetModel.status !== properties.endingStatus) {
        return {
          failCode: "not-correct-status"
        }
      }
      return null;
    },

    failEventsPerTarget(casterModel, targetModel, failInfo={}) {
      return [
      ]
    },

    successEventsPerTarget(casterModel, targetModel, allUpgrades) {
      return [
        {
          type: "eventMessage",
          subjects: [targetModel.id],
          textArray: [
            properties.messageText
          ],
          delayFramesAfter: 60,
        },
        {
          type: "eventBlippy",
          stateChanges: [
            {
              combatantId: targetModel.id,
              absoluteChanges: {
                status: "normal"
              }
            }
          ],
        }
      ]
    }
  }
}


export const NaturalEndFury = createNaturalStatusEnd({
  endingStatus: "fury",
  messageText: "Fury ended!"
});
export const NaturalEndFocus = createNaturalStatusEnd({
  endingStatus: "focus",
  messageText: "Focus ended!"
});
export const NaturalEndDeadline = createNaturalStatusEnd({
  endingStatus: "deadline",
  messageText: "Deadline ended!"
});
export const NaturalEndNervous = createNaturalStatusEnd({
  endingStatus: "nervous",
  messageText: "Nervous no more!"
});
export const NaturalEndHacked = createNaturalStatusEnd({
  endingStatus: "hacked",
  messageText: "Hacked no more!"
});
export const NaturalEndDangerous = createNaturalStatusEnd({
  endingStatus: "dangerous",
  messageText: "No longer dangerous"
});
export const NaturalEndBlock = createNaturalStatusEnd({
  endingStatus: "block",
  messageText: "Blocker vanished!"
});
export const NaturalEndFrail = createNaturalStatusEnd({
  endingStatus: "frail",
  messageText: "No longer Frail!"
});
export const NaturalEndLag = createNaturalStatusEnd({
  endingStatus: "lag",
  messageText: "Lagging resolved!"
});
export const NaturalEndDisabled = createNaturalStatusEnd({
  endingStatus: "disabled",
  messageText: "No longer Disabled!"
});