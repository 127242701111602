import React from 'react'
import BattleUseActionMessage from './BattleUseActionMessage'

class EventMessage extends React.Component {

  state = {
    isAnimatingOut: false
  };

  componentDidUpdate(prevProps) {
    //Animate out when I'm the only message left
    if (prevProps.otherEventsLeft !== 0 && this.props.otherEventsLeft === 0) {
      this.setState({
        isAnimatingOut: true
      })
    }
  }

  render() {

    const {event, positions, pixelSize} = this.props;
    const {isAnimatingOut} = this.state;


    return (
      event.subjects.map(subjectId => {

        return (
          <div
            key={subjectId}
            style={{
              position: "absolute",
              zIndex: 6,
              left: positions[subjectId].leftPosition + (pixelSize * 16),
              transform: "translate(-50%)",
              top: function() {
                //Position team two messages UNDER the eney team
                if (positions[subjectId].isEnemyTeam) {
                  return positions[subjectId].topPosition + (pixelSize * 42)
                }
                //Team one:
                return positions[subjectId].topPosition - (pixelSize * 20);
              }(),
              animation: "battleMessageEnter 0.5s forwards",
            }}>
            {/*<span style={{marginRight: 5, fontSize: "0.7em"}}>{event.theme}</span>*/}
            {/*{event.textArray.join("")}*/}
            <div style={{
              opacity: isAnimatingOut ? 0 : 1,
              transition: "opacity 0.3s",
            }}
                 onTransitionEnd={() => {
                   this.props.onEventDone()
                 }}
            >
              <BattleUseActionMessage
                pixelSize={pixelSize}
                text={event.textArray.join(" ")}
                icon={event.theme || null}
              />
            </div>
          </div>
        )
      })

    );
  }
}

export default EventMessage;