export function getUseItemSteps({casterModel, instanceId}) {
  return [
    {
      type: "eventBlippy",
      subjects: [casterModel.id],
      teamItemChangeInstructions: {
        type: "delete",
        instanceIds: [instanceId],
      },
    }
  ]
}