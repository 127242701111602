import React from 'react';
import StaticTextLine from "../StaticTextLine";

export default function (props, state) {
  const {pixelSize} = props;
  return (
    props.data.map(node => {
      const isActive = props.activeId === node.activeId;
      return (
        <div
          key={node.text}
          style={{
            width: "33.3%",
            display: "flex",
            justifyContent: "center",
            position: "relative",
            cursor: "pointer",
            borderBottom: !isActive ? `${pixelSize}px solid #b0adc8` : null,
            borderLeft: isActive ? `${pixelSize}px solid #b0adc8` : null,
            borderRight: isActive ? `${pixelSize}px solid #b0adc8` : null,
            borderTop: isActive ? `${pixelSize}px solid #b0adc8` : null,
          }}
          onClick={() => {
            props.handleNewActiveId(node.activeId)
          }}
        >
          <div style={{ marginLeft: pixelSize * 4 }}>
            <StaticTextLine
              key={props.activeId}
              pixelSize={pixelSize}
              text={node.text}
              theme={isActive ? "dark" : "menuBlue"}
            />
          </div>
        </div>
      );
    })
  )
}