import {shuffle} from "../../../helpers/shuffle";

export default function({combatants=[], battleStoryPoints=[], regularDecision, subject}) {

  //Taunt first (+ Slice) if this is Jack's first turn
  if (!battleStoryPoints.includes("securityjack-init")) {
  const aliveTargets = combatants.filter(c => c.hp > 0 && c.belongsToTeam === "one");
    return {
      submission: [
        {
          actionId: "speak",
          casterId: subject.id,
          targetIds: [subject.id],
          mergeData: {
            dialogContent: [
              "I've had enough of you sneaking around!"
            ]
          }
        },
        {
          actionId: "script_slice",
          casterId: subject.id,
          targetIds: [ shuffle(aliveTargets)[0].id ],
        },
      ],
      updatedBattleStoryPoints: [...battleStoryPoints, "securityjack-init"]
    }
  }

  return {
    submission: regularDecision,
    updatedBattleStoryPoints: battleStoryPoints
  }
}


