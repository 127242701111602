//WATCH OUT: This is not the source of truth. Please copy over from DS2017/AllCombatants
//This is just for dev testing

export default [
  {
    id: "enemyA",
    belongsToTeam: "two",
    //
    name: "Gareth",
    level:3,
    skinId: "gareth",
    class: "dps",
    playerDefeatMessage: "Are you sure you should be hanging out in here?",
    overworldPlayerVictoryMessage: ["I think I've lost my sense of identity!"],
    personality: ["healHp:2", "positiveStatus:2", "vicious", "mischief"],
    useScriptedDecisionFn: "gareth", //comes with scripted moves
    speedModifier: 3,
    attacks: [
      {actionId: "script_block"},
      {actionId: "script_foreach"},
      {actionId: "script_slice"},
      {actionId: "script_curl"},
    ],
    items: [
      {actionId: "item_battery-pack"},
      {actionId: "item_battery-pack"},
    ]
  },
]