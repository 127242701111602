import React from 'react'
import EventStep from "./EventStep";
import EventMessage from "./EventMessage"
import EventBlippy from './visual-events/EventBlippy'
import EventDialogBox from './visual-events/EventDialogBox'
import EventAnimation from './visual-events/EventAnimation'
import EventSchedule from './visual-events/EventSchedule'
import {waitFrames} from "../helpers/waitFrames";

class EventStepper extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeEvents: [],
      pendingEventIds: props.events.map(e => e.id),
      eventIdsToAdd: props.events.map(e => e.id),
    }
  }

  componentDidMount() {
    //Start adding to queue (according to delay order)
    this.addNext();
  }

  addNext() {
    if (this.state.eventIdsToAdd.length === 0) { return } //stop here if we're done.

    const addingEvent = this.props.events.find(e => e.id === this.state.eventIdsToAdd[0]);
    //console.log('adding', addingEvent.id)
    this.setState(state => {
      return {
        eventIdsToAdd: state.eventIdsToAdd.filter((d,i) => i > 0),
        activeEvents: [
          ...state.activeEvents,
          addingEvent
        ]
      }
    }, () => {

      //Some things want us to WAIT until the event is done before adding the next one.
      if (addingEvent.haltQueueUntilDone) {
        return;
      }

      //No need to wait - add the next event after the specified delay!
      waitFrames(addingEvent.delayFramesAfter || 1, () => {
        this.addNext();
      })
    });
  }

  handleRemoveEvent(finishedEventId) {

    //console.log('removing', finishedEventId)
    this.setState(state => {
      return {
        pendingEventIds: state.pendingEventIds.filter(id => id !== finishedEventId),
        activeEvents: state.activeEvents.filter(event => {
          return event.id !== finishedEventId
        })
      }
    }, () => {

      const removedEvent = this.props.events.find(e => e.id === finishedEventId);

      //We were halted on this one, so kick up the loop again:
      if (removedEvent.haltQueueUntilDone) {
        waitFrames(5, () => {
          this.addNext()
        })
      }

      //Check if we've seen everything
      if (this.state.pendingEventIds.length === 0) {
        this.props.onDoneWithAllEvents();
      }
    })
  }

  render() {
    const {activeEvents} = this.state;


    return (
      activeEvents.map(event => {

        if (event.type === "eventBlippy") {

          //Count how many blippy messages we expect to see.
          let messageCount = 0;
          (event.stateChanges || []).forEach(stateChange => {
            if (stateChange.message) {
              messageCount +=1 ;
            }
          });

          return (
            <EventBlippy
              key={event.id}
              messageCount={messageCount}
              pixelSize={this.props.pixelSize}
              positions={this.props.positions}
              event={event}
              onEventDone={() => { this.handleRemoveEvent(event.id) }}
              handleCombatantStateChange={(changesQueue) => {
                this.props.handleCombatantStateChange(changesQueue)
              }}
              handleItemsStateChange={ instruction => {
                this.props.handleItemsStateChange(instruction)
              }}
              handleSpawnCombatants={ instruction => {
                this.props.handleSpawnCombatants(instruction)
              }}
            />
          )
        }

        if (event.type === "eventMessage") {
          return (
            <EventMessage
              key={event.id}
              otherEventsLeft={activeEvents.length - 1}
              pixelSize={this.props.pixelSize}
              positions={this.props.positions}
              event={event}
              onEventDone={() => { this.handleRemoveEvent(event.id) }}
            />
          )
        }

        if (event.type === "eventDialogBox") {
          return (
              <EventDialogBox
                  key={event.id}
                  pixelSize={this.props.pixelSize}
                  positions={this.props.positions}
                  event={event}
                  onEventDone={() => { this.handleRemoveEvent(event.id) }}
              />
          )
        }

        if (event.type === "eventAnimation") {
          return (
            <EventAnimation
              key={event.id}
              pixelSize={this.props.pixelSize}
              event={event}
              positions={this.props.positions}
              onEventDone={() => { this.handleRemoveEvent(event.id) }}
              handleCombatantStateChange={(changesQueue) => {
                this.props.handleCombatantStateChange(changesQueue)
              }}
            />
          )
        }

        if (event.type === "eventSchedule") {
          return (
            <EventSchedule
              key={event.id}
              event={event}
              onEventDone={() => { this.handleRemoveEvent(event.id) }}
              handleScheduleEvent={this.props.handleScheduleEvent}
              handleCombatantStateChange={(changesQueue) => {
                this.props.handleCombatantStateChange(changesQueue)
              }}
            />
          )
        }

        //DEFAULT
        return (
          <EventStep key={event.id} event={event} onEventDone={() => { this.handleRemoveEvent(event.id) }}/>
        )
      })
    );
  }
}

export default EventStepper;