import {DefaultAction} from "../Action";

export default {
  ...DefaultAction,

  getUse() { return [] },

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {
    return [
      //Laggy animation? Purple stuff?
      {
        type: "eventDialogBox",
        subject: casterModel.id,
        textArray: ["Lagged out!"],
        haltQueueUntilDone: true
      },
    ]
  }
}