import React from 'react';

export default function (props, state) {
  return (
    <div style={{
      height: 167,
      background: "url(/images/auth/auth-cover-photo-2.png) no-repeat no-repeat",
      backgroundSize: "100%"
    }}>

    </div>
  )
}