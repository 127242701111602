import React from "react";
//import StaticPromoContent from './StaticPromoContent'
import AuthFormNavigation from "./AuthFormNavigation";
import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import CreateAccountForm from "./CreateAccountForm";
import AuthCoverPhoto from "./AuthCoverPhoto";
import AuthLoadingScreen from "./AuthLoadingScreen";
import AuthSuccessState from "./AuthSuccessState";
import { setGlobalValue } from "../redux-actions/global-actions";
import "./auth.css";
import { createSingleKeyPressBinding } from "../helpers/createSingleKeyPressBinding";
import { waitFrames } from "../helpers/waitFrames";

class AuthFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMountingTimeoutDone: false, //We have this to install a quick DELAY so that ENTER to navigate here don't submit the form right away

      viewId: (function() {
        if (props.launchAuthMenuInState === "login") {
          return "login";
        }
        return "createAccount";
      })(), // "createAccount", "login", "createAccountSuccess", "loginSuccess", "resetPasswordSuccess"
      isForgotPasswordScreen: false,

      //Fields:
      fieldName: "",
      fieldEmail: "",
      fieldCreatePassword: "",
      fieldPassword: "",

      //Use this to know when a fetch is happening, etc
      isLoading: false
    };
  }

  componentDidMount() {
    waitFrames(12, () => {
      this.setState({ isMountingTimeoutDone: true }); //remove the artificial delay
    });

    this.closeKeyHandler = createSingleKeyPressBinding([27], () => {
      if (this.state.isLoading) {
        return;
      }

      setGlobalValue({
        launchAuthMenuInState: null //same as #close handler
      });
    });
  }
  componentWillUnmount() {
    let el = document.getElementsByTagName("body")[0];
    [...this.closeKeyHandler].forEach(b => {
      el.removeEventListener("keydown", b.keyDownHandler);
      el.removeEventListener("keyup", b.keyUpHandler);
    });
  }

  updateFormField = (key, value) => {
    this.setState({
      [key]: value
    });
  };

  setLoading = value => {
    this.setState({ isLoading: value });
  };

  handleChangeViewId = newViewId => {
    this.setState({
      viewId: newViewId,
      isForgotPasswordScreen: false
    });
  };

  handleSetForgotPassword = value => {
    this.setState({ isForgotPasswordScreen: value });
  };

  renderFormContent = () => {
    const { viewId, isForgotPasswordScreen } = this.state;

    if (viewId === "createAccount") {
      return (
        <CreateAccountForm
          setLoading={this.setLoading}
          handleChangeViewId={this.handleChangeViewId}
          updateFormField={this.updateFormField}
          fieldName={this.state.fieldName}
          fieldEmail={this.state.fieldEmail}
          fieldCreatePassword={this.state.fieldCreatePassword}
        />
      );
    }
    if (viewId === "login") {
      if (isForgotPasswordScreen) {
        return (
          <ForgotPasswordForm
            setLoading={this.setLoading}
            handleChangeViewId={this.handleChangeViewId}
            updateFormField={this.updateFormField}
            fieldEmail={this.state.fieldEmail}
            handleSetForgotPassword={this.handleSetForgotPassword}
          />
        );
      }
      return (
        <LoginForm
          setLoading={this.setLoading}
          handleChangeViewId={this.handleChangeViewId}
          updateFormField={this.updateFormField}
          fieldEmail={this.state.fieldEmail}
          fieldPassword={this.state.fieldPassword}
          handleSetForgotPassword={this.handleSetForgotPassword}
        />
      );
    }
    return null;
  };

  renderBody() {
    if (
      ["createAccountSuccess", "loginSuccess", "resetPasswordSuccess"].includes(
        this.state.viewId
      )
    ) {
      const values = {
        createAccountSuccess: {
          headline: "Success!",
          copy: "You are now logged in to your newly created account.",
          buttonText: "Back to Game",
          buttonHandler: () => {
            setGlobalValue({ launchAuthMenuInState: null });
          }
        },
        loginSuccess: {
          headline: "Welcome back!",
          copy: "You are logged in and ready to rock.",
          buttonText: "Back to Game",
          buttonHandler: () => {
            setGlobalValue({ launchAuthMenuInState: null });
          }
        },
        resetPasswordSuccess: {
          headline: "Check your email!",
          copy:
            "If we had you on record, you'll have a Password Reset link ready to go.",
          buttonText: "Back to Log In",
          buttonHandler: () => {
            this.setState({
              viewId: "login",
              fieldPassword: ""
            });
          }
        }
      };

      return <AuthSuccessState {...values[this.state.viewId]} />;
    }

    //Default to forms
    return (
      <section style={{ position: "relative" }}>
        <AuthFormNavigation
          viewId={this.state.viewId}
          handleChangeViewId={this.handleChangeViewId}
        />
        <div
          style={{
            padding: 50,
            paddingTop: 40,
            background: "#F9F8F8"
          }}
        >
          {this.renderFormContent()}
        </div>
        <AuthLoadingScreen isLoading={this.state.isLoading} />
      </section>
    );
  }

  render() {
    if (!this.state.isMountingTimeoutDone) {
      return null;
    }

    //const {pixelSize} = this.props;
    const panelWidth = 390;
    return (
      <div
        style={{
          zIndex: 10,
          position: "absolute",
          left: "50%",
          top: 0, //Don't want Y position to change with content changes
          transform: "translateX(-50%)",
          fontFamily: "'Dosis', sans-serif",
          fontSize: 16,
          fontWeight: 500,
          boxShadow: "0 2px 4px 0 rgba(0,0,0,0.30)"
        }}
      >
        {!this.state.isLoading && (
          <a
            href="#close"
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              paddingLeft: 10,
              textDecoration: "none",
              fontSize: 35,
              lineHeight: "35px",
              display: "block",
              color: "rgba(0,0,0.8)"
            }}
            onClick={e => {
              e.preventDefault();
              setGlobalValue({
                launchAuthMenuInState: null
              });
            }}
          >
            &times;
          </a>
        )}

        <main style={{ width: panelWidth }}>
          <AuthCoverPhoto />
          {this.renderBody()}
        </main>
      </div>
    );
  }
}

export default AuthFormContainer;
