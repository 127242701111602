import store from '../../init/store'
import {setPlayerDataValue} from "../../redux-actions/player-data-actions";

export function changePlayerCurrency(type, amount, currency) {
  return {
    ...currency,
    [type]: currency[type] + amount
  }
}

export function changePlayerCreditsInRedux(change=0) {
  setPlayerDataValue({
    currency: changePlayerCurrency("credits", change, store.getState().playerData.currency)
  })
}