import React from 'react'
import BoxDiv from '../interface/BoxDiv'
import BoxDivBody from "../interface/BoxDivBody";
import Lifebar from './Lifebar'
import Icon from '../interface/Icon'
import StaticTextLine from "../StaticTextLine";
import BlockWord from "../pixel-letter-system/BlockWord";
import DangerMeter from './DangerMeter'
import Status from './Status'
import TurnBlip from './TurnBlip'

export default function FriendlyLifebarPanel(props) {
  const {pixelSize, partyCombatants} = props;
  return (
        partyCombatants.map((c, i) => {
          return (
            <FriendlySlot
              key={i}
              combatant={c}
              pixelSize={pixelSize}
              position={`${i + 1}x${partyCombatants.length}`}
            />
          )
        })
  )
};

const FriendlySlot = (props) => {
  const {pixelSize, position, combatant} = props;

  const showAliveState = combatant.hp > 0;
  return (
    <BoxDiv
      pixelSize={pixelSize}
      style={{
        position: "absolute",
        width: pixelSize * 54,
        transform: showAliveState ? `translate3d(0,0,0)` : `translate3d(0,${pixelSize * 14}px,0)`,
        transition: "transform 0.2s",
        top: pixelSize * -30,
        left: function () {
          //Teeam of One
          if (position === "1x1") { return pixelSize * 82 }
          //Team of Two
          if (position === "1x2") { return pixelSize * 16 }
          if (position === "2x2") { return pixelSize * 147 }
          //Team of Three
          if (position === "1x3") { return pixelSize * 16 }
          if (position === "2x3") { return pixelSize * 82 }
          if (position === "3x3") { return pixelSize * 147 }

          return 0
        }(),
      }}
    >
      {/* Header */}
      <div style={{position:"relative"}}>
        <div style={{height: pixelSize, background: "#d0cfe0"}} />
        <div style={{height: pixelSize, background: "#b0adca"}} />
        <div style={{height: pixelSize, background: "#4d4b60"}} />
        {/* Dots */}
        <div style={{position: "absolute", left: pixelSize * 1, top:pixelSize, height:pixelSize, width: pixelSize, background: "#222034"}} />
        <div style={{position: "absolute", left: pixelSize * 3, top:pixelSize, height:pixelSize, width: pixelSize, background: "#222034"}} />
        <div style={{position: "absolute", left: pixelSize * 5, top:pixelSize, height:pixelSize, width: pixelSize, background: "#222034"}} />
      </div>
      {
        showAliveState ? (
          <AliveInner {...props}/>
        ) : (
          <DeadInner {...props}/>
        )
      }
    </BoxDiv>
  )
};

const AliveInner = (props) => {
  const {pixelSize, combatant} = props;
  let blips = [];
  for (let i=0; i <combatant.turnBlips; i++) {
    blips.push(true)
  }

  //Maybe shorten name to not break the UI
  let name = combatant.name.toUpperCase();
  if (name.match(/SECURITY JACK/)) {
    name = "SEC.JACK";
  }

  return (
    <BoxDivBody
      pixelSize={pixelSize}
      style={{
        height: pixelSize * 28,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <div style={{
        display: "flex",
        justifyContent: "center"
      }}>
        <StaticTextLine
          pixelSize={pixelSize}
          text={name}
        />
      </div>
      <div style={{display: "flex", alignItems: "center"}}>
        <div style={{display: "flex", alignItems: "center", marginRight: pixelSize, width: pixelSize * 17, justifyContent: "center"}}>
          <div style={{marginRight: pixelSize, display: "flex", alignItems: "center"}}>
            <Icon
              pixelSize={pixelSize}
              category={combatant.class}
            />
          </div>
          <BlockWord
            pixelSize={pixelSize}
            string={String(combatant.level || 1)}
          />
        </div>
        <div>
          <Lifebar
            value={combatant.hp}
            maxValue={combatant.maxHp}
            pixelSize={pixelSize}
          />
          <DangerMeter
            pixelSize={pixelSize}
            value={combatant.dangerMeter}
            isDisabled={!combatant.supers || combatant.supers.length === 0}
          />
        </div>
      </div>
      <div style={{left:pixelSize * 9, top:pixelSize * -1, position: "relative"}}>
      </div>
      {
        combatant.status !== "normal" && (
          <div style={{
            position: "absolute",
            left: pixelSize * -3,
            //bottom: pixelSize * -7,
            top:pixelSize * -3,
          }}>
            <Status
              pixelSize={pixelSize}
              status={combatant.status}
            />
          </div>
        )
      }
      {/*//Temporary!*/}
      <div style={{position: "absolute", right:pixelSize * 1, top:pixelSize * 4, display: "flex", flexDirection: "row-reverse"}}>
      {
      blips.map((b,i) => (
        <div key={i} style={{marginLeft: pixelSize}}>
          <TurnBlip
            pixelSize={pixelSize}
            theme="player"
          />
        </div>
      ))
      }
      </div>
    </BoxDivBody>
  )
}
//{/*<div key={i} style={{width:pixelSize*3, height:pixelSize*5, background: "#000"}}/>*/}

const DeadInner = (props) => {
  const {pixelSize} = props;
  return (
    <div style={{
      padding:pixelSize * 2,
      paddingTop: pixelSize * 4,
      paddingBottom: pixelSize * 4,
      display: "flex",
      justifyContent: "center"
    }}>
      <Icon category="batteryDead" pixelSize={pixelSize} />
    </div>
  )
}
