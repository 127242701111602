import React from 'react';
import FormSubmitButton from './FormSubmitButton'

export default function (props, state) {
  return (
    <div style={{padding: 50, paddingTop: 30, background: "#f8f8f8"}}>
      <h2 style={{margin: "0 0 0.5em 0"}}>{props.headline}</h2>
      <p style={{margin: "0 0 2em 0"}}>{props.copy}</p>
      <form onSubmit={(e) => {
        e.preventDefault();
        props.buttonHandler()
      }}>
        <FormSubmitButton
          text={props.buttonText}
        />
      </form>
    </div>
  )
}