import React from 'react'
import {connect} from 'react-redux'
import {handleLoadGame} from './handleLoadGame'
import GameFilePanel from './GameFilePanel'
import StaticTextLine from "../StaticTextLine";
import ScrollingOptionsList from "../menu-components/ScrollingOptionsList";
import ArrowMenu from "../ArrowMenu/ArrowMenu";
import {getLoadGameMenu} from './getLoadGameMenu'
import {setGlobalValue} from "../redux-actions/global-actions";
import {createSaveFileId} from "./handleSaveGame";
import PauseHeader from "../Pause/PauseHeader";
//import mockSaveFile from './mockSaveFile.json'
import {getSaveFilesFromLocalStorage} from "./localstorageSaveFiles";
//let firebase = require('firebase/app');

//require('firebase/database');

class LoadGameMenu extends React.Component {

  state = {
    isLoading: true,
    saves: []
  };

  componentDidMount() {

    const {authUserId} = this.props;

    if (!authUserId) {
      console.warn('no user found')
      return;
    }


    //If this is web, gather saves from localStorage
    const localStorageSaves = getSaveFilesFromLocalStorage();


    const clientGetSavesFromLocalMachine = window.clientGetSavesFromLocalMachine || function(callback) {
      //Web mocks this function (Electron version will be present in `window`
      // callback({
      //   save1: {...mockSaveFile}
      // })
      callback({}) //Stub empty response for web (will use localstorage)
    };




    clientGetSavesFromLocalMachine(dataFromFileSystem => {

      let saves = {
        ...localStorageSaves, //Include localstorage if this is web (will be empty for Electron)
        ...dataFromFileSystem //Include local files from Electron (will be empty for web)
      };

      if (Object.keys(saves).length === 0) {
        this.setState({isLoading: false})
        return;
      }
      //We have more than zero saves
      let fetchedFiles = [];
      Object.keys(saves).forEach(key => {
        fetchedFiles.push(
          {...saves[key], id: key}
        );
      });


      this.setState({
        saves: fetchedFiles,
        isLoading: false,
      })
    })




    // firebase.database().ref(`/users/${authUserId}/saves`).once("value", snap => {
    //   const saves = snap.val();
    //
    //   if (!saves) {
    //     //We don't have any saves!
    //     this.setState({isLoading: false})
    //     return;
    //   }
    //
    //   const saveKeys = Object.keys(saves);
    //   let fetchedFiles = [];
    //   saveKeys.forEach(key => {
    //     firebase.database().ref(`/saves/${key}`).once("value", saveSnap => {
    //       fetchedFiles.push(
    //         {...saveSnap.val(), id: key}
    //       );
    //       if (fetchedFiles.length === saveKeys.length) {
    //         this.setState({
    //           saves: fetchedFiles,
    //           isLoading: false,
    //         })
    //       }
    //     })
    //   });
    // })

  }

  optionHandler = (action) => {
    if (action.type === "LOAD_GAME") {
      handleLoadGame(action.savedFile)
    }
    if (action.type === "NEW_GAME") {
      setGlobalValue({
        currentGameFileId: createSaveFileId(this.props.authUserId),
        gameArea: "changingChapter",
        changeToChapterId: "chapterJRQ"
      })
    }
    if (action.type === "GO_BACK") {
      this.props.handleGoBack();
    }
  };

  render() {

    if (!this.props.authUserId) {
      return null;
    }

    const {isLoading, saves} = this.state;

    if (isLoading) {
      return <div>Loading...</div>
    }

    const {menu, map} = getLoadGameMenu({
      saves
    });


    const {pixelSize} = this.props;
    return (
      <div style={{ width: pixelSize * 160}}>
        <PauseHeader
          title={"Load Game"}
          handleBackClick={() => this.props.handleGoBack()}
        />
        <ArrowMenu
          handler={this.optionHandler}
          backOptionId={"back"}
          optionsPageId={"upgrades"}
          optionsMap={map}
          optionModels={menu}
          render={({handlers, selectedOptionId}) => {

            if (saves.length === 0) {
              return (
                <div>
                  <StaticTextLine
                    pixelSize={pixelSize}
                    text="You do not have any Save Files!"
                  />
                  <ScrollingOptionsList
                    handlers={handlers}
                    optionsMap={map}
                    optionModels={menu}
                    selectedOptionId={selectedOptionId}
                    pixelSize={pixelSize}
                  />
                </div>
              )
            }
            return (
              <ScrollingOptionsList
                optionsLimit={3}
                handlers={handlers}
                optionsMap={map}
                optionModels={menu}
                selectedOptionId={selectedOptionId}
                pixelSize={pixelSize}
                customOptionRender={(option) => {
                  return (
                    <GameFilePanel
                      isSelected={option.isSelected}
                      pixelSize={pixelSize}
                      name={option.name}
                      level={option.level}
                      skinId={option.skinId}
                      lastSaved={option.lastSaved}
                      chapterTitle={option.chapterTitle}
                    />
                  )
                }}
              />
            )
          }} />
      </div>
    );
  }
}


export default connect((state, props) => {
  return {
    pixelSize: state.global.pixelSize,
    authUserId: state.global.authUserId
  }
})(LoadGameMenu)