import React from 'react';

export default function (props, state) {
  const {pixelSize} = props;
  return (
    <div
      style={{
        width: pixelSize * 18,
        height: pixelSize * 14,
        overflow: "hidden"
      }}
    >
      <svg style={{
        display: "block", width: 72 * pixelSize,
        animation: "svg_itemMachineGlow 0.9s steps(4) infinite"
      }} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 72 14" shapeRendering="crispEdges">
        <style>
          {`
            @keyframes svg_itemMachineGlow {
                  0% { transform: translate3d(0,0,0) }
                  100% { transform: translate3d(-100%,0,0) }
            }
        `}
        </style>
        <path stroke="#289ac1" d="M1 0h16"/>
        <path stroke="#7ac1e8" d="M19 0h16m20 0h16M18 1h1m16 0h1m18 0h1m16 0h1M18 2h1m16 0h1m18 0h1m16 0h1M18 3h1m16 0h1m18 0h1m16 0h1M18 4h1m16 0h1m18 0h1m16 0h1M18 5h1m16 0h1m18 0h1m16 0h1M18 6h1m16 0h1m18 0h1m16 0h1M18 7h1m16 0h1m18 0h1m16 0h1M18 8h1m16 0h1m18 0h1m16 0h1M18 9h1m16 0h1m18 0h1m16 0h1m-54 1h1m16 0h1m18 0h1m16 0h1m-54 1h1m16 0h1m18 0h1m16 0h1m-54 1h1m16 0h1m18 0h1m16 0h1m-54 1h18m18 0h18"/>
        <path stroke="#a3dcfc" d="M37 0h16M36 1h1m16 0h1M36 2h1m16 0h1M36 3h1m16 0h1M36 4h1m16 0h1M36 5h1m16 0h1M36 6h1m16 0h1M36 7h1m16 0h1M36 8h1m16 0h1M36 9h1m16 0h1m-18 1h1m16 0h1m-18 1h1m16 0h1m-18 1h1m16 0h1m-18 1h18"/>
        <path stroke="#5ea7cf" d="M0 1h1m16 0h1M0 2h1m16 0h1M0 3h1m16 0h1M0 4h1m16 0h1M0 5h1m16 0h1M0 6h1m16 0h1M0 7h1m16 0h1M0 8h1m16 0h1M0 9h1m16 0h1M0 10h1m16 0h1M0 11h1m16 0h1M0 12h1m16 0h1M0 13h18"/>
        <path stroke="#a2d3e4" d="M1 1h12M1 2h10M1 3h10m5 0h1M1 4h8m6 0h2M1 5h7m6 0h3"/>
        <path stroke="#d4f0fa" d="M13 1h4m-6 1h6m-6 1h5M9 4h6M8 5h6"/>
        <path stroke="#d0ebfa" d="M19 1h12m24 0h12M19 2h10m26 0h10M19 3h10m5 0h1m20 0h10m5 0h1M19 4h8m6 0h2m20 0h8m6 0h2M19 5h7m6 0h3m20 0h7m6 0h3"/>
        <path stroke="#f6fcff" d="M31 1h4m32 0h4M29 2h6m30 0h6M29 3h5m31 0h5M27 4h6m30 0h6M26 5h6m30 0h6M24 6h6m30 0h6M23 7h6m30 0h6M23 8h5m31 0h5M21 9h7m29 0h7m-43 1h6m30 0h6m-43 1h6m30 0h6m-43 1h16m20 0h16"/>
        <path stroke="#eef9ff" d="M37 1h12M37 2h10M37 3h10m5 0h1M37 4h8m6 0h2M37 5h7m6 0h3M37 6h5m6 0h5M37 7h4m6 0h6M37 8h4m5 0h7M37 9h2m7 0h7m-16 1h2m6 0h8m-16 1h1m6 0h9"/>
        <path stroke="#fff" d="M49 1h4m-6 1h6m-6 1h5m-7 1h6m-7 1h6m-8 1h6m-7 1h6m-6 1h5m-7 1h7m-7 1h6m-7 1h6m-7 1h16"/>
        <path stroke="#b7dcf0" d="M1 6h5m6 0h5M1 7h4m6 0h6M1 8h4m5 0h7M1 9h2m7 0h7M1 10h2m6 0h8M1 11h1m6 0h9"/>
        <path stroke="#e2f5fd" d="M6 6h6M5 7h6M5 8h5M3 9h7m-7 1h6m-7 1h6m-7 1h16"/>
        <path stroke="#ddf0fb" d="M19 6h5m6 0h5m20 0h5m6 0h5M19 7h4m6 0h6m20 0h4m6 0h6M19 8h4m5 0h7m20 0h4m5 0h7M19 9h2m7 0h7m20 0h2m7 0h7m-52 1h2m6 0h8m20 0h2m6 0h8m-52 1h1m6 0h9m20 0h1m6 0h9"/>
      </svg>
    </div>
  )
}