//Desktop
//"totalPositions - position - friendly/enemy"
const desktopMap = {
  //Friendly:
  "1-1-f": pixelSize => { return { leftPosition: pixelSize * 96, topPosition: pixelSize * 145} },

  "2-1-f": pixelSize => { return { leftPosition: pixelSize * 32, topPosition: pixelSize * 145} },
  "2-2-f": pixelSize => { return { leftPosition: pixelSize * 160, topPosition: pixelSize * 145} },

  "3-1-f": pixelSize => { return { leftPosition: pixelSize * 32, topPosition: pixelSize * 145} },
  "3-2-f": pixelSize => { return { leftPosition: pixelSize * 96, topPosition: pixelSize * 145} },
  "3-3-f": pixelSize => { return { leftPosition: pixelSize * 160, topPosition: pixelSize * 145} },

  //Enemy:
  "1-1-e": pixelSize => { return { leftPosition: pixelSize * 96, topPosition: pixelSize * 48} },

  "2-1-e": pixelSize => { return { leftPosition: pixelSize * 32, topPosition: pixelSize * 48} },
  "2-2-e": pixelSize => { return { leftPosition: pixelSize * 160, topPosition: pixelSize * 48} },

  "3-1-e": pixelSize => { return { leftPosition: pixelSize * 32, topPosition: pixelSize * 52} },
  "3-2-e": pixelSize => { return { leftPosition: pixelSize * 96, topPosition: pixelSize * 44} },
  "3-3-e": pixelSize => { return { leftPosition: pixelSize * 160, topPosition: pixelSize * 52} },

  "4-1-e": pixelSize => { return { leftPosition: 0, topPosition: pixelSize * 52} },
  "4-2-e": pixelSize => { return { leftPosition: pixelSize * 64, topPosition: pixelSize * 44} },
  "4-3-e": pixelSize => { return { leftPosition: pixelSize * 128, topPosition: pixelSize * 44} },
  "4-4-e": pixelSize => { return { leftPosition: pixelSize * 192, topPosition: pixelSize * 52} },

  "5-1-e": pixelSize => { return { leftPosition: 0, topPosition: pixelSize * 76} },
  "5-2-e": pixelSize => { return { leftPosition: pixelSize * 48, topPosition: pixelSize * 58} },
  "5-3-e": pixelSize => { return { leftPosition: pixelSize * 96, topPosition: pixelSize * 40} },
  "5-4-e": pixelSize => { return { leftPosition: pixelSize * 144, topPosition: pixelSize * 58} },
  "5-5-e": pixelSize => { return { leftPosition: pixelSize * 192, topPosition: pixelSize * 76} },
};


export function getArenaCombatantLayoutPosition({position, isEnemyTeam, pixelSize, totalPositions}) {
  const useKey = `${totalPositions}-${position}-${isEnemyTeam ? "e" : "f"}`;
  return {...desktopMap[useKey](pixelSize), isEnemyTeam};
}