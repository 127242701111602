import {shuffle} from "../../../helpers/shuffle";

export function getStatBoostingDecision({casterModel, teamMembers, enemies, teamItems}) {


  //case: use a team boosting move if I have one (why waste an item?)
  const myAttackIds = casterModel.attacks.map(a => a.actionId);
  let myStatBoostingAttacks = [
    "script_scrum",
    "script_firewall",
    "script_team-building"
  ].filter(key => {
    return myAttackIds.includes(key);
  });

  if (myStatBoostingAttacks.length) {
    const aliveFriendIds = [casterModel, ...teamMembers].filter(c => c.hp && c.hp > 0).map(c => c.id);
    return {
      actionId: shuffle(myStatBoostingAttacks)[0],
      casterId: casterModel.id,
      targetIds: aliveFriendIds
    }
  }

  //case: maybe use an item
  const boostingItem = teamItems.find(item => {
    return [
      "item_attack-sticker",
      "item_defense-sticker",
      "item_slippery-sticker",
    ].includes(item.actionId)
  });
  if (boostingItem) {
    //Find a random valid teammate (including me)
    const aliveTeammates = shuffle([casterModel, ...teamMembers.filter(m => m.hp > 0)]);
    if (aliveTeammates[0]) {
      return {
        actionId: boostingItem.actionId,
        casterId: casterModel.id,
        targetIds: [aliveTeammates[0].id],
        instanceId: boostingItem.instanceId
      }
    }
  }
  return null;
}