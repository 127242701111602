export function getMapImageTransformValue(config) {

  const {pixelSize, followingPersonModel, cameraSpaceOnLeft, cameraSpaceOnTop} = config;
  const yElevate = pixelSize * 16; //To make things appear elevated upwards
  const actualCellSize = (32 * pixelSize);

  let xNudge = 0;
  let yNudge = 0;
  if (followingPersonModel.movingProgress !== 0) {
    const movingProgress = followingPersonModel.movingProgress || 0;
    if (followingPersonModel.direction === "LEFT") {
      xNudge = -movingProgress * pixelSize
    }
    if (followingPersonModel.direction === "RIGHT") {
      xNudge = movingProgress * pixelSize
    }
    if (followingPersonModel.direction === "UP") {
      yNudge = -movingProgress * pixelSize
    }
    if (followingPersonModel.direction === "DOWN") {
      yNudge = movingProgress * pixelSize
    }
  }

  //const x = (actualCellSize * cameraSpaceOnLeft) + (-actualCellSize * followingPersonModel.x) - xNudge;
  //console.log(x)

  return {
    x: (actualCellSize * cameraSpaceOnLeft) + (-actualCellSize * followingPersonModel.x) - xNudge,
    y: (actualCellSize * cameraSpaceOnTop) + (-actualCellSize * followingPersonModel.y) - yNudge,
    yElevate
  };
}

export function getPeopleRelativeToMapTransformValue({people, pixelSize}) {

  let payload = {};
  const actualCellSize = (32 * pixelSize);
  const yElevate = pixelSize * -16; //To elevate things 50% upwards

  //Note -> We filted out the "hero" before passing people into this fn because we wont use his relative-to-map position.
  people.forEach(person => {
    let xNudge = 0;
    let yNudge = 0;
    if (person.movingProgress > 0) {
      if (person.direction === "LEFT") {
        xNudge = -person.movingProgress * pixelSize
      }
      if (person.direction === "RIGHT") {
        xNudge = person.movingProgress * pixelSize
      }
      if (person.direction === "UP") {
        yNudge = -person.movingProgress * pixelSize
      }
      if (person.direction === "DOWN") {
        yNudge = person.movingProgress * pixelSize
      }
    }

    payload[person.id] = {
      x: person.x * actualCellSize + xNudge,
      y: person.y * actualCellSize + yNudge,
      yElevate
    }
  });

  return payload;
}


export function writeTransformValueToString({x, y, yElevate}) {
  return `translate3d(${x}px, ${y + yElevate}px, 0)`
}