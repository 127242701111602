import Upgrades from "../battle-data/Upgrades";
import {getCombatantStats} from "../getCombatantStats";

export function bridgeDevSetupToBattle(battlersArray) {

  const convertedBattlerEnemies = battlersArray.filter(b => b.id.match(/enemy/)).map(b => convertOneBattler(b));
  const convertedBattlersFriendlies = battlersArray.filter(b => b.id.match(/member/)).map(b => convertOneBattler(b));

  //return getObjectFromIncludedArray(convertedBattlers);
  // return {
  //   enemies: getObjectFromIncludedArray(convertedBattlerEnemies),
  //   friendlies: getObjectFromIncludedArray(convertedBattlersFriendlies)
  // }
  return [
    ...convertedBattlersFriendlies.filter(c => c.isIncluded === "true"),
    ...convertedBattlerEnemies.filter(c => c.isIncluded === "true")
  ]
}

// export function getObjectFromIncludedArray(array) {
//   let payload = {};
//   array.forEach(entry => {
//     if (entry.isIncluded === "true") {
//       payload[entry.id] = {
//         ...entry
//       }
//     }
//   });
//   return payload
// }

export function convertOneBattler(battler) {

  //ATTACKS
  let attacks = [];
  [
    battler.attack1, battler.attack2, battler.attack3, battler.attack4
  ].forEach(value => {
    if (value.length) {
      attacks.push({
        actionId: value
      })
    }
  });

  let supers = [];
  [
    battler.attackSuper
  ].forEach(value => {
    if (value.length) {
      supers.push({
        actionId: value
      })
    }
  });


  let payload = {
    id: battler.id,
    isIncluded: battler.isIncluded,
    //teamId: battler.teamId,
    //
    name: battler.name,
    class: battler.class,
    skinId: battler.skinId,
    belongsToTeam: battler.teamId,
    isPlayerControlled: battler.teamId === "one",
    level: Number(battler.level),
    dangerMeter: Number(battler.startingDangerMeter),
    accuracyModifier: Number(battler.accuracyModifier) || 0,
    status: battler.status || "normal",
    attacks,
    supers,
    items: (battler.items || []).map((item, index) => {
      return {
        actionId: item.actionId,
        isBugged: item.isBugged === "yes",
        instanceId: `devBattle_${battler.id}_${index}`
      }
    })
  };

  //Some values we need the help from a full blown combatant model
  const maxHp = getCombatantStats({level: payload.level}).maxHp;

  payload.hp = function(){

    const {startHp} = battler;
    //Possible values ["100%", "0", "1hp", "10%", "50%", "75%"]

    if (startHp === "0") { return 0 }
    if (startHp === "1hp") { return 1 }
    if (startHp === "10%") { return Math.round(maxHp * 0.1) }
    if (startHp === "50%") { return Math.round(maxHp * 0.5) }
    if (startHp === "75%") { return Math.round(maxHp * 0.75) }

    return maxHp;
  }();

  payload.maxHp = maxHp;

  //include upgrades for player people
  if (payload.belongsToTeam === "one") {
    payload.upgrades = Object.keys(Upgrades);
    payload.activeUpgradeId = payload.upgrades[0]
  }

  //Maybe Construct a personality
  const personalityKeys = Object.keys(battler).filter(key => key.startsWith("personalityKey"));
  if (personalityKeys.length > 0) {
    payload.personality = personalityKeys.map(key => {

      const occurrences = Number(battler[key]);
      let value = key.split("personalityKey:")[1];

      if (occurrences > 1) {
        return `${value}:${occurrences}`
      }
      if (occurrences === 1) {
        return value
      }
      return null
    }).filter(d => d);
  }
  
  return payload;
}