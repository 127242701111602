import {DefaultAction, DefaultScript} from "../Action";
import {shuffle} from "../../../../helpers/shuffle";
import BattleActions from "../../battle-actions-data";
import listingTextHelper from '../../../../helpers/listing-text-helper'

function createStealingMove(properties = {}) {
  return {
    ...DefaultAction,
    ...DefaultScript,
    name: properties.name,
    description: properties.description,
    targetingType: "all-enemies",
    icon: properties.icon,
    accuracy: 96,
    categories: properties.categories,
    teamItemMoveMethods: true,
    isCopyPaste: properties.isCopyPaste || false,

    shouldFailOnTargetTeam({casterModel, friendlyItems, enemyItems}) {
      return enemyItems.length === 0;
    },

    wholeTeamFailEvent({casterModel, targetModels}) {
      return [
        {
          type: "eventBlippy",
          stateChanges: targetModels.map(targetModel => {
            return {
              combatantId: targetModel.id,
              message: {
                text: `NO ITEMS!`
              },
            }
          }),
        },
      ]
    },

    successEventOnTeam({casterModel, friendlyItems, enemyItems, allActions}) {

      const actions = allActions || BattleActions;
      const shuffledEnemyItems = shuffle([...enemyItems]);

      const stolenItems = properties.stealQuantity.map((d,i) => {
        return shuffledEnemyItems[i] ? shuffledEnemyItems[i] : null
      }).filter(d => d);

      const stolenInstanceIds = stolenItems.map(i => i.instanceId);
      const namesText = listingTextHelper(
        stolenItems.map(i => actions[i.actionId].name)
      );
      const text = `Stole ${namesText}!`;

      const mappedActionIdsToInstanceIds = stolenInstanceIds.map(id => {
        return enemyItems.find(item => item.instanceId === id).actionId
      });

      return [
        {
          type: "eventAnimation",
          animationId: "characterJump",
          subjects: [casterModel.id],
          delayFramesAfter: 30,
        },
        {
          type: "eventBlippy",
          teamItemChangeInstructions: {
            type: "steal",
            instanceIds: stolenInstanceIds,
            stolenActionIds: mappedActionIdsToInstanceIds,
            teamSwap: [casterModel.belongsToTeam === "one" ? "two" : "one", casterModel.belongsToTeam] //from, to
          },
          delayFramesAfter: 5,
        },
        {
          type: "eventDialogBox",
          //subject: casterModel.id,
          textArray: [text],
          //haltQueueUntilDone: true
        },
      ]
    }
  }
}


export const Curl = createStealingMove({
  name: "Curl",
  icon: "support",
  description: "Steal 1 random item from the enemy team",
  stealQuantity: [true],
  categories: ["itemStealingTurn"]
});
export const CurlMkII = createStealingMove({
  name: "Curl Mk II",
  icon: "support",
  description: "Steal 2 items from the enemy team",
  stealQuantity: [true, true],
  categories: ["itemStealingTurn"]
});
export const CopyPaste = createStealingMove({
  name: "Copy/Paste",
  icon: "dps",
  description: "Use 1 random item from the enemy team",
  stealQuantity: [true],
  categories: ["autoUseStolenItem"]
});