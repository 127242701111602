import {shuffle} from "../../../helpers/shuffle";

export function getCursingDecision({casterModel, teamMembers, enemies, enemyItems}) {

  const myAttackIds = casterModel.attacks.map(a => a.actionId);
  let myCursingAttacks = [
    "script_ddos",
    "script_disable",
    "script_intimidate",
    "script_garbage-jammer",
    "script_thrash",
    "script_hack",
    "script_frail",
  ].filter(key => {
    return myAttackIds.includes(key);
  });
  if (!myCursingAttacks.length) { return null }

  const aliveEnemiesWithNormalStatus = enemies.filter(e => {
    return e.hp > 0 && e.status === "normal"
  });

  if (aliveEnemiesWithNormalStatus.length === 0) { return null }

  return {
    actionId: shuffle(myCursingAttacks)[0],
    casterId: casterModel.id,
    targetIds: [ shuffle(aliveEnemiesWithNormalStatus)[0].id ],
  }
}