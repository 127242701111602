import React from 'react'
import ArrowMenu from '../../ArrowMenu/ArrowMenu'
import {getArenaHomeMenu} from "./getArenaHomeMenu";
import {getArenaScriptsMenu} from "./getArenaScriptsMenu";
import {getArenaItemsMenu} from "./getArenaItemsMenu";
import {getArenaTargetingMenu} from "./getArenaTargetingMenu";
//import ArenaOptionsListPage from './ArenaOptionsListPage'
import ScrollingOptionsList from '../../menu-components/ScrollingOptionsList'
import BoxDivBody from "../../interface/BoxDivBody";
import BoxDiv from '../../interface/BoxDiv'
import StaticTextLine from "../../StaticTextLine";
import ArenaTargetingView from './ArenaTargetingView'
import {getArenaUpgradesMenu} from "./getArenaUpgradesMenu";
import Icon from '../../interface/Icon'
import {getArenaBailMenu} from "./getArenaBailMenu";
import {audioManager} from "../../audio/audioManager";
import {shouldAutoSelectCaster} from './shouldAutoSelectCaster'

class ArenaArrowMenu extends React.Component {

  state = {
    menuId: "home",
    stagedInstanceId: null,
    rememberedOptions: {}, //built up as you navigate the menu
    stagedActionId: "", //set by handler in object returned by individual option handlers
  };

  componentDidMount() {
    audioManager.playSfx("sfx_jump")
  }

  handler = (output={}) => {

    if (output.stageActionId) {
      this.setState({
        stagedActionId: output.stageActionId
      })
    }
    if (output.stageInstanceId) {
      this.setState({
        stagedInstanceId: output.stageInstanceId
      })
    }

    if (output.changeCharacterUpgrade) {

      audioManager.playSfx("sfx_nextPage"); //change this for custom sfx when equipping upgrade

      this.props.handleCombatantStateChange([
        {
          combatantId: output.changeCharacterUpgrade,
          absoluteChanges: {
            activeUpgradeId: output.newUpgradeId
          }
        }
      ], () => {
        //THEN change back to Home
        this.setState({
          menuId: "home"
        })
      });
    }

    if (output.goToMenuId) {

      audioManager.playSfx("sfx_nextPage");

      //Maybe clear out staged instanceId so we don't accidentally submit it
      if (output.goToMenuId !== "items" && output.goToMenuId !== "targeting") {
        this.setState({
          stagedInstanceId: null
        }, () => {
          this.setState({
            menuId: output.goToMenuId
          })
        })
      } else {
        this.setState({
          menuId: output.goToMenuId
        })
      }
    }

    if (output.submitBail) {
      this.props.handleSubmitBail()
    }

    if (output.submit) {
      const submission = {
        actionId: this.state.stagedActionId,
        casterId: this.props.combatantId,
        targetIds: output.targetIds,
        instanceId: this.state.stagedInstanceId
      };
      audioManager.playSfx("sfx_submitAction");
      this.props.handleSubmitActionWithForm(submission)
    }
  };

  renderDescriptionBox(selectedModel) {
    if (!selectedModel) { return null }
    if (["home", "scripts", "items", "upgrades"].includes(this.state.menuId)) {
      const {pixelSize} = this.props;
      return (
        <div style={{
          position: "absolute",
          left:0,
          //top:0,
          bottom: -pixelSize,
          right: 0,
          zIndex:2,
        }}>
          <BoxDiv pixelSize={pixelSize}>
            <BoxDivBody pixelSize={pixelSize} style={{
              paddingLeft:pixelSize * 4,
              paddingRight:pixelSize * 4,
              paddingTop: pixelSize * 2,
              paddingBottom: pixelSize
            }}>
              <StaticTextLine
                key={selectedModel.label}
                pixelSize={pixelSize}
                text={selectedModel.description || ""}
              />
            </BoxDivBody>
          </BoxDiv>
        </div>
      )
    }
    return null
  }

  renderListHeader(handlers) {
    const {pixelSize, teamOneItems} = this.props;
    const {menuId} = this.state;
    if (menuId !== "home") {
      const buggedItemsCount = teamOneItems.filter(t => t.isBugged).length;
      return (
        <div style={{
          height: 14 * pixelSize,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative"
        }}>
          {/* Left Side */}
          <div
            style={{cursor: "pointer"}}
            onClick={() => {
              handlers.submit("back");
            }}
          >
            <Icon pixelSize={pixelSize} category="menuBackSmaller" />
          </div>
          {/* Right Side */}
          {
            buggedItemsCount > 0 && menuId === "items" && (
              <div style={{display: "flex"}}>
                <div style={{marginRight: pixelSize * 2, position: "relative", top: pixelSize}}>
                  <Icon pixelSize={pixelSize} category="buggedItem"/>
                </div>
                <StaticTextLine
                  pixelSize={pixelSize}
                  text={`x${buggedItemsCount}`}
                />
              </div>
            )
          }

          <div style={{position: "absolute", left:"50%", top:pixelSize, transform: `translateX(-50%)`}}>
            <Icon
              pixelSize={pixelSize}
              category={(() => {
                if (menuId === "scripts") { return "scriptsTitle"}
                if (menuId === "upgrades") { return "upgradesTitle"}
                if (menuId === "bail") { return "bailTitle"}
                return "itemsTitle"
              })()}
            />
          </div>
        </div>
      )
    }
    return null;
  }

  render() {
    const {combatantId, combatants, teamOneItems, teamTwoItems, pixelSize, combatantLeftPosition, positions} = this.props;

    const combatant = combatants.find(c => c.id === combatantId);
    const {map, menu, backOptionId} = (() => {
      if (this.state.menuId === "scripts") { return getArenaScriptsMenu(combatant) }
      if (this.state.menuId === "items") { return getArenaItemsMenu({...combatant, items: teamOneItems}) }
      if (this.state.menuId === "upgrades") { return getArenaUpgradesMenu(combatant) }
      if (this.state.menuId === "targeting") {
        return getArenaTargetingMenu({
          actionId: this.state.stagedActionId,
          combatant,
          allCombatants: combatants,
          items: {
            one: teamOneItems,
            two: teamTwoItems,
          }
        })
      }
      if (this.state.menuId === "bail") { return getArenaBailMenu(combatants) }

      const deadAllies = combatants.find(c => c.belongsToTeam === combatant.belongsToTeam && c.hp <= 0);
      const enemyHasZeroItems = teamTwoItems.length === 0;
      return getArenaHomeMenu({...combatant, items: teamOneItems, enemyHasZeroItems, anyDeadAllies: Boolean(deadAllies)})
    })();

    let initiallySelectedOptionId = null;
    if (this.state.menuId === "targeting") {

      //Auto select caster on a positive giving status
      if ( shouldAutoSelectCaster({ stagedActionId: this.state.stagedActionId })) {
        //Find the caster
        Object.keys(menu).forEach(key => {
          const model = menu[key];
          if (model.targets && model.targets[0].targetId === combatantId) {
            initiallySelectedOptionId = model.targets[0].menuOptionKey; //EX: "target1"
          }
        });
      }
    }

    return (
      <ArrowMenu
        shouldAutoSubmit={(props) => {
          //Auto submit if only 1 option
          if (this.state.menuId === "targeting") {
            if (props.optionsMap[0].length === 1) {
              return props.optionsMap[0];
            }
          }
          return false;
        }}
        initiallySelectedOptionId={initiallySelectedOptionId}
        handler={this.handler}
        backOptionId={backOptionId}
        optionsPageId={this.state.menuId}
        optionsMap={map}
        optionModels={menu}
        rememberedOptionId={this.state.rememberedOptions[this.state.menuId] || null}
        onNewOptionsPage={(change) => {
          this.setState(state => ({
            rememberedOptions: {
              ...state.rememberedOptions,
              [change.previousMenuId]: change.previousSelectedOptionId
            }
          }))
        }}
        render={({handlers, selectedOptionId}) => {
          const selectedModel = menu[selectedOptionId];
          if (this.state.menuId === "targeting") {
            return (
              <ArenaTargetingView
                handlers={handlers}
                optionsMap={map}
                optionModels={menu}
                selectedOptionId={selectedOptionId}
                pixelSize={pixelSize}
                positions={positions}
              />
            )
          }
          return (
            <React.Fragment>
              {this.renderDescriptionBox(selectedModel)}
              <div style={{
                  position: "absolute",
                  left: combatantLeftPosition - (pixelSize * 13),
                  bottom: pixelSize * 72,
                  marginLeft: pixelSize * -21
                }}>
                {this.renderListHeader(handlers)}
                <ScrollingOptionsList
                  key={this.state.menuId}
                  pixelWidth={107} //Trying to keep a sweetspot that holds all names (and quantities) but without going too wide
                  optionsLimit={4}
                  handlers={handlers}
                  optionsMap={map}
                  optionModels={menu}
                  selectedOptionId={selectedOptionId}
                  pixelSize={pixelSize}
                  ignoreOptionIds={["bail"]}
                />
                {
                  this.state.menuId === "home" && (
                    <div
                      onClick={() => {
                        handlers.submit("bail")
                      }}
                      style={{
                        position:"absolute",
                        cursor: "pointer",
                        left:-pixelSize,
                        bottom:pixelSize * -11
                      }}
                    >
                      <Icon
                        category={selectedOptionId === "bail" ? "bailButtonBack" : "bailButton"}
                        pixelSize={pixelSize}
                      />
                    </div>
                  )
                }

                <div style={{position: "absolute", right:pixelSize * 28, bottom: pixelSize * -7 }}>
                  <Icon category="menuSpeechArrow" pixelSize={pixelSize} />
                </div>

              </div>
            </React.Fragment>
          )}
        }
      />
    );
  }
}

export default ArenaArrowMenu;