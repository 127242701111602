import React from 'react'
import {waitFrames} from "../../../helpers/waitFrames";
import RunAnimation from './RunAnimation'
import {audioManager} from "../../../audio/audioManager";


class AnimationPop extends React.Component {

  state = {
    hasTriggeredAnimation: false
  };

  componentDidMount() {

    const targets = this.props.event.subjects;

    waitFrames(1, () => {
      this.setState({
        hasTriggeredAnimation: true
      }, () => {
        //Start shaking
        audioManager.playSfx("sfx_iteratePain");
        targets.forEach(id => {
          this.props.handleCombatantStateChange([
            {
              combatantId: id,
              absoluteChanges: {
                laptopFlash: "pain",
                animation: "characterShake",
              }
            }
          ])
        });
        //Stop shaking
        waitFrames(20, () => {
          targets.forEach(id => {
            this.props.handleCombatantStateChange([
              {
                combatantId: id,
                absoluteChanges: {
                  laptopFlash: "none",
                  animation: null,
                }
              }
            ])
          });
        });
      });
    });
  }

  render() {
    const {pixelSize, event, positions} = this.props;
    return event.subjects.map((subjectId, i) => {
      return (
        <RunAnimation
          key={subjectId}
          pixelSize={pixelSize}
          left={positions[subjectId].leftPosition}
          top={positions[subjectId].topPosition}
          widthOfSpriteSheet={160}
          frames={5}
          duration="0.3s"
          onDone={() => { waitFrames(10, () => {this.props.onEventDone()}) }}
          renderPaths={() => (
            <React.Fragment>
              <path stroke="#fff" d="M76 4h8M41 5h5m28 0h12M40 6h7m25 0h16M39 7h9m23 0h18M38 8h11m21 0h20M37 9h13m19 0h22M8 10h3m26 0h13m19 0h22m22 0h5M8 11h3m26 0h13m18 0h24m20 0h7m-82 1h13m18 0h24m19 0h9m-83 1h13m17 0h26m17 0h11m-83 1h11m18 0h26m16 0h13m-83 1h9m19 0h26m16 0h13m-82 1h7m20 0h26m16 0h13m-81 1h5m21 0h26m16 0h13m-55 1h26m16 0h13m27 0h3m-85 1h26m17 0h11m28 0h3m-85 1h26m18 0h9m-52 1h24m20 0h7m-51 1h24m21 0h5m-49 1h22m-22 1h22m-21 1h20m-19 1h18m-17 1h16m-14 1h12m-10 1h8"/>
            </React.Fragment>
          )}
        />
      )
    })
  }
}

export default AnimationPop;