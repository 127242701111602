import React from 'react'
import themes from './themes'

export function getBlockLetterStyle(pixelSize = 1, model) {
  return {
    width: pixelSize * model.width,
  }
}

export default {
  "0": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg"
             viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M2 3h1M4 3h1M0 4h1M2 4h1M4 4h1M0 5h1M4 5h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].inner || "#ffffff"} d="M1 1h3M1 2h1M3 2h1M1 3h1M3 3h1M1 4h1M3 4h1M1 5h3" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "1": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg"
             viewBox="0 -0.5 4 7" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 0h4M0 1h1M3 1h1M0 2h2M3 2h1M1 3h1M3 3h1M1 4h1M3 4h1M1 5h1M3 5h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].inner || "#ffffff"} d="M1 1h2M2 2h1M2 3h1M2 4h1M2 5h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M1 6h3" />
        </svg>
      )
    }
  },
  "2": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg"
             viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h3M4 2h1M0 3h1M4 3h1M0 4h1M2 4h3M0 5h1M4 5h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].inner || "#ffffff"} d="M1 1h3M3 2h1M1 3h3M1 4h1M1 5h3" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "3": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg"
             viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h3M4 2h1M0 3h1M4 3h1M0 4h3M4 4h1M0 5h1M4 5h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].inner || "#ffffff"} d="M1 1h3M3 2h1M1 3h3M3 4h1M1 5h3" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "4": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg"
             viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 0h5M0 1h1M2 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M4 3h1M0 4h3M4 4h1M2 5h1M4 5h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].inner || "#ffffff"} d="M1 1h1M3 1h1M1 2h1M3 2h1M1 3h3M3 4h1M3 5h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M2 6h3" />
        </svg>
      )
    }
  },
  "5": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg"
             viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h3M0 3h1M4 3h1M0 4h3M4 4h1M0 5h1M4 5h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].inner || "#ffffff"} d="M1 1h3M1 2h1M1 3h3M3 4h1M1 5h3" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "6": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg"
             viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h3M0 3h1M4 3h1M0 4h1M2 4h1M4 4h1M0 5h1M4 5h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].inner || "#ffffff"} d="M1 1h3M1 2h1M1 3h3M1 4h1M3 4h1M1 5h3" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "7": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg"
             viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h3M4 2h1M2 3h1M4 3h1M2 4h1M4 4h1M2 5h1M4 5h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].inner || "#ffffff"} d="M1 1h2M3 2h1M3 3h1M3 4h1M3 5h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].inner || "#ffffff"} d="M3 1h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M2 6h3" />
        </svg>
      )
    }
  },
  "8": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg"
             viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M4 3h1M0 4h1M2 4h1M4 4h1M0 5h1M4 5h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].inner || "#ffffff"} d="M1 1h3M1 2h1M3 2h1M1 3h3M1 4h1M3 4h1M1 5h3" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "9": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg"
             viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 0h5" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M4 3h1M0 4h3M4 4h1M0 5h1M4 5h1M0 6h5" />
          <path className="Dpad--NoShow" stroke={themes[theme].inner || "#fff"} d="M1 1h3M1 2h1M3 2h1M1 3h3M3 4h1M1 5h3" />
        </svg>
      )
    }
  },
  "-": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg"
             viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 2h5M0 3h1M4 3h1M0 4h5" />
          <path className="Dpad--NoShow" stroke={themes[theme].inner || "#ffffff"} d="M1 3h3" />
        </svg>
      )
    }
  },
  "+": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg"
             viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M1 1h3M0 2h2M3 2h2M0 3h1M4 3h1M0 4h2M3 4h2" />
          <path className="Dpad--NoShow" stroke={themes[theme].inner || "#ffffff"} d="M2 2h1M1 3h3M2 4h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M1 5h3" />
        </svg>
      )
    }
  }
}