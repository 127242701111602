import BattleActions from "../Battle7/battle-data/battle-actions-data";
import { getItemSortValue } from "../helpers/getItemSortValue";

export function getPauseItemsMenu({ inventory=[], allActions }) {

  const actions = allActions || BattleActions;

  let map = [];
  let menu = {
    back: {
      handler() {
        return { type: "GO_BACK" }
      }
    }
  };

  //Build up a map of what we have and how many.
  let itemsMap = {};
  inventory.forEach(({actionId, instanceId}) => {
    if (itemsMap[actionId]) {
      itemsMap[actionId].push(instanceId)
    } else {
      itemsMap[actionId] = [instanceId]
    }
  });



  //Create options for the menu
  Object.keys(itemsMap).sort((idA, idB) => {
    return getItemSortValue(idA) > getItemSortValue(idB) ? 1 : -1
  }).forEach((key, index) => {

    const action = actions[key];
    const optionKey = `item${index}`;

    menu[optionKey] = {
      label: action.name,
      description: action.description,
      icon: "item",
      viewBag: {
        quantity: itemsMap[key].length,
      },
      handler() {
        return {
          type: "ACTION",
          actionId: key,
          instanceId: itemsMap[key][0]
        }
      }
    };
    map.push([optionKey])
  });



  return {
    map, menu, backOptionId: "back"
  }
}