import {DefaultAction} from "../Action";
import {getIterativeAttackDamage} from "../../formulas/getAttackDamage";
import {shuffle} from "../../../../helpers/shuffle";
import {getDangerMeterReset} from "../../formulas/getDangerMeterReset";

export default {
  ...DefaultAction,
  name: "Team ForEach",
  description: "Rapidly deliver valuable pain towards the enemy team",
  icon: "super-dps",
  targetingType: "all-enemies",

  shouldFailOnSingleTarget(casterModel, targetModel) {
    return null;
  },

  successEventsPerAllTargets({casterModel, targetModels, allUpgrades}) {

    let payload = [];

    //This move is always consistent because it's a super.
    let iterationCount = 6;

    // Create a temporary map of combatant HP. This helps us continue to only target alive people as this move goes on (for max damage!)
    // Because our passed in targetModels is a reference to the original state when calling this move.
    let hpMap = targetModels.map(c => {
      return {
        id: c.id,
        hp: c.hp,
        status: c.status
      }
    }).filter(c => c.hp > 0);

    //Keep a list of targets who can BLOCK the first hit.
    let targetsWithBlock = targetModels.filter(t => t.status === "block").map(t => t.id);

    for (let i = 0; i < iterationCount; i++) {
      const shuffledAliveTargetModels = shuffle(hpMap);
      //Only add more if somebody is still alive! :D

      if (shuffledAliveTargetModels.length > 0) {
        const targetModel = shuffledAliveTargetModels[0];
        let damagePerIteration = getIterativeAttackDamage(6, casterModel, targetModel);

        payload = [
          ...payload,
          {
            type: "eventAnimation",
            animationId: "animationIterate",
            casterId: casterModel.id,
            subjects: [targetModel.id],
            haltQueueUntilDone: true
          },
        ];

        //If this is a blocked hit, modify the damage to be -1 this time and remove the status
        const isBlockedHit = targetsWithBlock.includes(targetModel.id);
        if (isBlockedHit) {
          targetsWithBlock = targetsWithBlock.filter(id => id !== targetModel.id);
          damagePerIteration = -1;
          payload.push({
            type: "eventBlippy",
            subjects: [targetModel.id],
            stateChanges: [
              {
                combatantId: targetModel.id,
                relativeChanges: {
                  hp: damagePerIteration,
                },
                absoluteChanges: {
                  status: "normal"
                },
                message: {
                  theme: "battleNegativeHp",
                  text: `${damagePerIteration} HP`
                }
              }
            ],
            delayFramesAfter: 5,
          })
        } else {
          //Otherwise, this is a normal hit. Let it fly!
          payload.push({
            type: "eventBlippy",
            subjects: [targetModel.id],
            stateChanges: [
              {
                combatantId: targetModel.id,
                relativeChanges: {
                  hp: damagePerIteration
                },
                message: {
                  theme: "battleNegativeHp",
                  text: `${damagePerIteration} HP`
                }
              }
            ],
            delayFramesAfter: 5,
          })
        }

        //Remove this hp from our hpMap (and maybe remove the entry
        hpMap = hpMap.map(c => {
          if (c.id === targetModel.id) {
            return {...c, hp: c.hp + damagePerIteration}
          }
          return c;
        }).filter(c => c.hp > 0);

      }
    }
    payload.push(getDangerMeterReset({casterModel}));

    return payload;
  },
}