export default {
  devChapter: {
    name: "Test Dev Chapter",
    endpoint: null //??? Can we load this?
  },
  chapterDev2: {
    name: "Dev Chapter 2", //I think this was just to test that chapter switching works?
    endpoint: "/chapters/chapterDev2.json"
  },

  //Production chapters ---------------------------------------------------------------------
  chapterJRQ: {
    name: "Jacob's Request",
    endpoint: "/chapters/chapterJRQ.json"
  },
  chapterMTG: {
    name: "The Meeting",
    endpoint: "/chapters/chapterMTG.json"
  },
  chapterSHAW: {
    name: "Shaw Park",
    endpoint: "/chapters/chapterSHAW.json"
  },
  chapterTICKETS: {
    name: "Three Tickets",
    endpoint: "/chapters/chapterTICKETS.json"
  },
  chapterCONF: {
    name: "DangerCon",
    endpoint: "/chapters/chapterCONF.json"
  },
  chapterHWY: {
    name: "The Highway",
    endpoint: "/chapters/chapterHWY.json"
  },
  chapterTTB: {
    name: "Tighten the Belt",
    endpoint: "/chapters/chapterTTB.json"
  },
  chapterENDING: {
    name: "Ending",
    endpoint: "/chapters/chapterENDING.json"
  }
};
