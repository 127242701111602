//Audio on/off
export function getAudioPreferenceFromLocalstorage() {
  if (!window.localStorage) { return }
  const savedPreference = window.localStorage.getItem("audioPreference");
  return savedPreference || "full" //default to full if nothing saved
}

export function setAudioPreferenceInLocalstorage(setting) {
  if (!window.localStorage) { return }
  window.localStorage.setItem("audioPreference", setting);
}


//Audio volume
export function getAudioVolumeFromLocalstorage() {
  if (!window.localStorage) { return }
  const savedPreference = window.localStorage.getItem("audioVolume");
  return Number(savedPreference) || 100 //default to 100%
}
export function setAudioVolumeInLocalstorage(setting) {
  if (!window.localStorage) { return }
  window.localStorage.setItem("audioVolume", Number(setting));
}