/* eslint no-useless-escape: "off" */
export function getEmailValidationErrors(input) {

  if (input.length === 0) {
    return "Please enter an email address."
  }

  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!emailRegex.test(input)) {
    return "Please enter a valid email address."
  }
  return null
}

export function getNameValidationErrors(input) {
  if (input.length < 2) {
    return "Please enter at least 2 characters"
  }
  if (input.length > 40) {
    return "Name is too long! How about a nickname?"
  }
  return null;
}

export function getPasswordValidationErrors(input) {
  if (input.length < 8) {
    return "Please enter at least 8 characters."
  }
  return null;
}

export function getReturningPasswordErrors(input) {
  if (input.length < 1) {
    return "Please enter your password."
  }
  return null;
}