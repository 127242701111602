import React from 'react';

export default function (props, state) {

  const {pixelSize, children} = props;

  const theme = {
    borderColor: "#222034",
    boxFill: "#e5e4e7",
    ...props.theme
  };

  const style = props.style || {};

  return (
    <div
    className="Dpad--NoShow"
      style={{
      position: "relative",
      ...style
    }}>
      <div style={{
        "zIndex": 1,
        "position": "absolute",
        "top": 0,
        "bottom": 0,
        "right": -pixelSize,
        "left": -pixelSize,
        "background": theme.borderColor,
      }}/>
      <div style={{
        "zIndex": 1,
        "position": "absolute",
        "top": -pixelSize,
        "bottom": -pixelSize,
        "right": 0,
        "left": 0,
        "background": theme.borderColor,
      }}/>
      <main style={{
        position: "relative",
        zIndex: 2,
        background: theme.boxFill
      }}>
        {children}
      </main>
    </div>
  )
}