import React from "react";
import BoxDiv from "./interface/BoxDiv";
import BoxDivTippyTop from "./interface/BoxDivTippyTop";
import BoxDivBody from "./interface/BoxDivBody";
import StaticTextLine from "./StaticTextLine";
import MapPerson from "./Overworld/MapPerson";
import ArrowMenu from "./ArrowMenu/ArrowMenu";
import ScrollingOptionsList from "./menu-components/ScrollingOptionsList";
import Icon from './interface/Icon'

class CreatePersonConfirmForm extends React.Component {
  optionHandler = (instruction) => {

    if (instruction === "confirm") {
      this.props.handleSubmission(true)
    }

    if (instruction === "editName") {
      this.props.handleChangePage("name")
    }
    if (instruction === "editAppearance") {
      this.props.handleChangePage("skin")
    }
  };

  render() {
    const { userProvidedName, userProvidedSkin, pixelSize } = this.props;

    const menu = {
      confirm: { label: "READY", handler: () => "confirm" },
      editName: { label: "Change name...", handler: () => "editName" },
      editAppearance: {
        label: "Change appearance...",
        handler: () => "editAppearance"
      }
    };

    const map = [["confirm"], ["editName"], ["editAppearance"]];

    return (
      <div style={{ display: "flex" }}>
        <BoxDiv pixelSize={pixelSize}>
          <BoxDivTippyTop pixelSize={pixelSize} />
          <BoxDivBody
            pixelSize={pixelSize}
            style={{
              padding: 4 * pixelSize,
              paddingLeft: pixelSize * 11,
              paddingRight: pixelSize * 11,
              paddingBottom: pixelSize * 8
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{marginRight: pixelSize * 3, position:"relative", top:pixelSize * 2}}>
                <Icon
                  category={this.props.memberClass}
                  pixelSize={pixelSize}
                />
              </div>
              <StaticTextLine
                pixelSize={pixelSize}
                text={userProvidedName}
              />
            </div>

            <aside style={{height: pixelSize * 40, position: "relative", width: pixelSize * 40, margin: "0 auto"}}>
              <div
                style={{
                  position: "absolute",
                  left: pixelSize * 4,
                  top: pixelSize * -2
                }}
              >
                <MapPerson
                  cellSize={pixelSize * 32}
                  pixelSize={pixelSize}
                  skinId={userProvidedSkin}
                />
              </div>
            </aside>
            <ArrowMenu
              handler={this.optionHandler}
              backOptionId={"back"}
              optionsPageId={"journal"}
              optionsMap={map}
              optionModels={menu}
              render={({ handlers, selectedOptionId }) => {
                return (
                  <ScrollingOptionsList
                    pixelWidth={100}
                    handlers={handlers}
                    optionsMap={map}
                    optionModels={menu}
                    selectedOptionId={selectedOptionId}
                    pixelSize={pixelSize}
                  />
                );
              }}
            />
          </BoxDivBody>
        </BoxDiv>
      </div>
    );
  }
}

/*
        <button onClick={this.props.handleGoBack}>
          BACK
        </button>

        Does this character good?
        <div>
          NAME: {userProvidedName}
        </div>
        <div>
          SKIN: {userProvidedSkin}
        </div>
        <button
          onClick={() => {
            this.props.handleSubmission(true)
          }}
        >Yes!</button>
 */

export default CreatePersonConfirmForm;
