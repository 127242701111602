import React from "react";
import {connect} from "react-redux";
import {waitFrames} from "./helpers/waitFrames";
import {setOverworldValue} from "./redux-actions/overworld-actions";
import {getNextExternalEventTrigger} from "./Overworld/getIncrExternalEventTrigger";
import BoxDivBody from "./interface/BoxDivBody";
import BoxDiv from "./interface/BoxDiv";
import StaticTextLine from "./StaticTextLine";
import GenericFillBar from "./interface/GenericFillBar";
import Levels from "./_data/Levels";
import {getCurrentLevelFromXp} from "./getCurrentLevelFromXp";
import {setPlayerDataValue} from "./redux-actions/player-data-actions";
import {runEaseOut} from "./helpers/run-ease-out";
import BlockWord from "./pixel-letter-system/BlockWord";
import {audioManager} from "./audio/audioManager";
import BoxDivTippyTop from "./interface/BoxDivTippyTop";
import store from "./init/store";
import {getCombatantStats} from "./Battle7/getCombatantStats";
import { createSingleKeyPressBinding } from "./helpers/createSingleKeyPressBinding";

class BattleWinXpPopup extends React.Component {
  constructor(props) {
    super(props);

    this.currentPlayerLevel = getCurrentLevelFromXp(props.teamXp, Levels);
    this.nextLevelAtXp = Levels[this.currentPlayerLevel+1];

    this.hasPlayedFillSfx = false;

    this.startingXp = props.teamXp;
    this.totalToAdd = props.battleWinPopup.xpGained;
    this.state = {
      showingXp: props.teamXp,
      isDoneFilling: false,
      hasSpedUp: false,
    };
  }

  //Maybe make this animate out or something. Just being cheap for now.
  componentDidMount() {

    audioManager.playSfx("sfx_popup");

    this.speedUpButtonPress = createSingleKeyPressBinding([13,32], () => {
      if (!this.state.hasSpedUp) {

        if (!this.hasPlayedFillSfx) { //don't fire this twice
          audioManager.playSfx("sfx_fillHp"); //fire the fill SFX even though we are jumping ahead
        }

        this.setState({
          hasSpedUp: true,
          showingXp: this.startingXp + this.totalToAdd,
          isDoneFilling: true
        })
      }
    });

    //Wait before we start the animation, because it's jarring if we don't wait
    waitFrames(50, () => {
      this.beginFillingAnimation();
    });
  }


  componentWillUnmount() {
    let el = document.getElementsByTagName("body")[0];
    [...this.speedUpButtonPress].forEach(b => {
      el.removeEventListener("keydown", b.keyDownHandler);
      el.removeEventListener("keyup", b.keyUpHandler);
    });
  }


  restoreCrewHpAfterLevelUp = () => {
    const updatedMembersWithFullHp = store.getState().playerData.acquiredMembers.map(c => {

      //If a member was dead, don't allow them to get the full recovery
      if (c.hp <= 0) {
        return c;
      }

      const {maxHp} = getCombatantStats({level: this.currentPlayerLevel+1});
      return {
        ...c,
        hp: maxHp
      }
    });
    setPlayerDataValue({
      acquiredMembers: updatedMembersWithFullHp
    })
  };


  componentDidUpdate(prevProps, prevState) {
    //Detect point of contact with hitting 100%
    if (prevState.showingXp < this.nextLevelAtXp && this.state.showingXp >= this.nextLevelAtXp) {

      this.didLevelUp = true;
      audioManager.playSfx("sfx_levelup");

      if (!this.state.isDoneFilling) {
        this.setState({
          isDoneFilling: true
        })
      }

      waitFrames(20, () => { //This is just to break up the movement happening on screen. Feels stressful when the bar fills + level up text appears at same time
        this.restoreCrewHpAfterLevelUp()
      })
    }

    if (!prevState.isDoneFilling && this.state.isDoneFilling) {
      //console.log('DONE FILLING')
      this.transitionOutAfterFillingDone()
    }

  }

  beginFillingAnimation = () => {

    if (!this.state.hasSpedUp) {
      audioManager.playSfx("sfx_fillHp");
      this.hasPlayedFillSfx = true;
    }

    runEaseOut(
      this.startingXp,
      this.totalToAdd,
      80,
      value => {

        if (!this.state.hasSpedUp) {
          this.setState(state => {
            return {
              showingXp: value
            };
          });
        }
      },
      () => {
        if (!this.state.isDoneFilling) {
          this.setState({
            isDoneFilling: true
          })
        }
      }
    );
  };




  transitionOutAfterFillingDone = () => {

    //Update redux state
    setPlayerDataValue({
      teamXp: this.props.teamXp + this.props.battleWinPopup.xpGained
    });

    const waitNumberOfFrames = this.didLevelUp ? 140 : 90; //Slightly longer if you did level up

    waitFrames(waitNumberOfFrames, () => {
      setOverworldValue({
        externalEventTrigger: getNextExternalEventTrigger(
          "OVERWORLD-BATTLEWINPOPUP_SHOWN"
        ),
        battleWinPopup: null
      });
    });
  };

  render() {
    const {pixelSize} = this.props;

    const {showingXp} = this.state;

    const currentLevelStartingXp = Levels[this.currentPlayerLevel];
    const nextLevelAtXp = Levels[this.currentPlayerLevel + 1];
    const totalGapForLevel = nextLevelAtXp - currentLevelStartingXp;
    const fillPercent = (showingXp - currentLevelStartingXp) / totalGapForLevel;

    const text = fillPercent < 1 ? `Gained ${this.totalToAdd} XP!` : `Level ${this.currentPlayerLevel + 1}!"`;

    const levelContainerStyle = {
      width: pixelSize * 12,
      height: pixelSize * 12,
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    };

    return (
      <div
        style={{
          position: "absolute",
          top: pixelSize * 60,
          left: pixelSize * 64
        }}
      >
        <BoxDiv pixelSize={pixelSize}>
          <BoxDivTippyTop
            pixelSize={pixelSize}
          />
          <BoxDivBody
            pixelSize={pixelSize}
            style={{
              padding: pixelSize * 4,
              paddingRight: pixelSize * 6,
              paddingLeft: pixelSize * 6
            }}
          >
            <div style={{display: "flex", justifyContent: "center"}}>
              <StaticTextLine
                pixelSize={pixelSize}
                text={text}
                key={text}
              />
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
              <div style={{
                ...levelContainerStyle,
                opacity: fillPercent < 1 ? 1 : 0,
                transition: "opacity 0.2s"
              }}>
                <BlockWord
                  pixelSize={pixelSize}
                  theme="level"
                  string={String(this.currentPlayerLevel)}
                />
              </div>
              <GenericFillBar
                pixelSize={pixelSize}
                totalWidth={60}
                totalHeight={6}
                fillPercent={fillPercent}
              />
              <div style={levelContainerStyle}>
                <BlockWord
                  key={fillPercent < 1 ? "deactive-level" : "level"}
                  pixelSize={pixelSize}
                  theme={fillPercent < 1 ? "deactive-level" : "level"}
                  string={String(this.currentPlayerLevel+1)}
                />
              </div>
            </div>
          </BoxDivBody>
        </BoxDiv>
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    teamXp: state.playerData.teamXp,
    battleWinPopup: state.overworld.battleWinPopup,
    pixelSize: state.global.pixelSize
  };
})(BattleWinXpPopup);
