import {DefaultAction, DefaultItem} from "../Action";

function createDangerBoost(properties={}) {
  return {
    ...DefaultAction,
    ...DefaultItem,

    name: properties.name,
    description: properties.description,
    targetingType: properties.targetingType,
    sellValue: properties.sellValue,
    icon: "item",

    shouldFailOnSingleTarget(casterModel, targetModel) {
      if ((targetModel.supers || []).length === 0) {
        return {
          failCode: "no-super"
        }
      }
      return null;
    },

    failEventsPerTarget(casterModel, targetModel, failInfo={}) {
      return [
        {
          type: "eventBlippy",
          stateChanges: [
            {
              combatantId: targetModel.id,
              message: {
                theme: "battleNoEffect",
                text: `No super!`
              },
            }
          ]
        }
      ]
    },

    successEventsPerTarget(casterModel, targetModel, allUpgrades) {
      return [
        {
          type: "eventBlippy",
          stateChanges: [
            {
              combatantId: targetModel.id,
              relativeChanges: {
                dangerMeter: properties.increase
              },
              message: {
                theme: "battlePositiveHp",
                text: `++Danger Meter`
              },
            }
          ],
        }
      ]
    }
  }
}

export const DangerBoost = createDangerBoost({
  name: "Danger Boost",
  description: "Add two blips to your Danger Meter",
  increase: 50,
  sellValue: 20,
  targetingType: "single-friendly",
});

function createTeamDangerBoost(properties={}) {
  return {
    ...DefaultAction,
    ...DefaultItem,

    name: properties.name,
    description: properties.description,
    sellValue: properties.sellValue,
    icon: "item",
    targetingType: "all-friendlies",

    shouldFailOnSingleTarget(casterModel, targetModel) {
      if ((targetModel.supers || []).length === 0) {
        return {
          failCode: "no-super"
        }
      }
      return null;
    },

    successEventsPerAllTargets({casterModel, targetModels, allUpgrades}) {
      return [
        {
          type: "eventBlippy",
          stateChanges: targetModels.map(targetModel => {
            return {
              combatantId: targetModel.id,
              relativeChanges: {
                dangerMeter: properties.increase
              },
              message: {
                theme: "battlePositiveHp",
                text: `Danger Meter++`
              },
            }
          })
        }
      ]
    }
  }
}

export const TeamDangerBoost = createTeamDangerBoost({
  name: "Team Danger Boost",
  description: "Add two Danger Meter blips to whole team",
  increase: 50,
  sellValue: 30,
  targetingType: "all-friendlies",
});