import React from 'react'

class ExplodingBattleSpace extends React.Component {

  render() {
    const {pixelSize, x, y} = this.props;
    const cellSize = pixelSize * 32;

    //This one is a little custom because it's 96x96 instead of 32x32
    return (
      <div style={{
        position: "absolute",
        width: cellSize * 3,
        height: cellSize * 3,
        left: (cellSize * x) - cellSize,
        top: (cellSize * y) - cellSize,
        overflow: "hidden"
      }}>

        <svg style={{
          width: (pixelSize * 384),
          display: "block",
          animation: `svg_run-animation  0.5s steps(4) forwards`,
          animationDelay: "0.4s",
        }} xmlns="http://www.w3.org/2000/svg" viewBox={`0 -0.5 384 96`} shapeRendering="crispEdges">
          <style>
            {`@keyframes svg_run-animation { 0% { transform: translate3d(0,0,0) } 100% { transform: translate3d(-100%,0,0) }}`}
          </style>
          <path stroke="#fff"
                d="M322 28h2m-2 1h2m20 0h1m7 1h2m-131 1h8m18 0h8m62 0h1m29 0h1m2 0h2m-131 1h8m18 0h8m-128 1h8m14 0h8m64 0h7m20 0h7m59 0h2m-189 1h8m14 0h8m64 0h3m28 0h3m59 0h2m36 0h1m-226 1h7m16 0h7m64 0h3m28 0h3m91 0h2m-221 1h3m24 0h3m64 0h3m28 0h3m61 0h1m2 0h1m26 0h2m4 0h1m-226 1h3m24 0h3m64 0h3m28 0h3m-128 1h3m24 0h3m64 0h2m30 0h2m-128 1h3m24 0h3m-30 1h2m26 0h2m159 14h1m-190 1h2m26 0h2m192 0h1m-223 1h3m24 0h3m-30 1h3m24 0h3m64 0h2m30 0h2m-128 1h3m24 0h3m64 0h3m28 0h3m93 0h1m-222 1h3m24 0h3m64 0h3m28 0h3m64 0h2m-194 1h7m16 0h7m64 0h3m28 0h3m64 0h2m-194 1h8m14 0h8m64 0h3m28 0h3m90 0h2m-220 1h8m14 0h8m64 0h7m20 0h7m67 0h1m22 0h2m-126 1h8m18 0h8m-34 1h8m18 0h8m61 0h2m6 0h1m25 0h2m-36 1h2m26 0h1m5 0h2"/>
          <path stroke="#fed7d5"
                d="M32 32h10m12 0h10m64 0h10m12 0h10M32 33h1m8 0h1m12 0h1m8 0h1m64 0h1m8 0h1m12 0h1m8 0h1M32 34h1m8 0h1m12 0h1m8 0h1m64 0h1m8 0h1m12 0h1m8 0h1M32 35h1m7 0h1m14 0h1m7 0h1m64 0h1m7 0h1m14 0h1m7 0h1M32 36h1m3 0h4m16 0h4m3 0h1m64 0h1m3 0h4m16 0h4m3 0h1M32 37h1m3 0h1m22 0h1m3 0h1m64 0h1m3 0h1m22 0h1m3 0h1M32 38h1m3 0h1m22 0h1m3 0h1m64 0h1m3 0h1m22 0h1m3 0h1M32 39h1m3 0h1m22 0h1m3 0h1m64 0h1m3 0h1m22 0h1m3 0h1M32 40h1m2 0h1m24 0h1m2 0h1m64 0h1m2 0h1m24 0h1m2 0h1M32 41h3m26 0h3m64 0h3m26 0h3M32 54h3m26 0h3m64 0h3m26 0h3M32 55h1m2 0h1m24 0h1m2 0h1m64 0h1m2 0h1m24 0h1m2 0h1M32 56h1m3 0h1m22 0h1m3 0h1m64 0h1m3 0h1m22 0h1m3 0h1M32 57h1m3 0h1m22 0h1m3 0h1m64 0h1m3 0h1m22 0h1m3 0h1M32 58h1m3 0h1m22 0h1m3 0h1m64 0h1m3 0h1m22 0h1m3 0h1M32 59h1m3 0h4m16 0h4m3 0h1m64 0h1m3 0h4m16 0h4m3 0h1M32 60h1m7 0h1m14 0h1m7 0h1m64 0h1m7 0h1m14 0h1m7 0h1M32 61h1m8 0h1m12 0h1m8 0h1m64 0h1m8 0h1m12 0h1m8 0h1M32 62h1m8 0h1m12 0h1m8 0h1m64 0h1m8 0h1m12 0h1m8 0h1M32 63h10m12 0h10m64 0h10m12 0h10"/>
          <path stroke="#c01117" d="M33 33h8m14 0h8M33 62h8m14 0h8"/>
          <path stroke="#d41419"
                d="M33 34h1m6 0h1m14 0h1m6 0h1m-30 1h1m2 0h4m16 0h4m2 0h1m-30 1h1m1 0h1m24 0h1m1 0h1m-30 1h1m1 0h1m24 0h1m1 0h1m-30 1h1m1 0h1m24 0h1m1 0h1m-30 1h1m1 0h1m24 0h1m1 0h1m-30 1h2m26 0h2M33 55h2m26 0h2m-30 1h1m1 0h1m24 0h1m1 0h1m-30 1h1m1 0h1m24 0h1m1 0h1m-30 1h1m1 0h1m24 0h1m1 0h1m-30 1h1m1 0h1m24 0h1m1 0h1m-30 1h1m2 0h4m16 0h4m2 0h1m-30 1h1m6 0h1m14 0h1m6 0h1"/>
          <path stroke="#fe8e83" d="M34 34h2m24 0h2m-28 1h1m26 0h1M34 60h1m26 0h1m-28 1h2m24 0h2"/>
          <path stroke="#fd5556"
                d="M36 34h4m-5 1h1m-2 1h1m-1 1h1m-1 1h1m-1 1h1m26 17h1m-1 1h1m-1 1h1m-1 1h1m-2 1h1m-5 1h4"/>
          <path stroke="#fc6364"
                d="M56 34h4m0 1h1m0 1h1m-1 1h1m-1 1h1m-1 1h1M34 56h1m-1 1h1m-1 1h1m-1 1h1m0 1h1m0 1h4"/>
        </svg>
      </div>
    );
  }
}

export default ExplodingBattleSpace;