import BattleActions from './battle-data/battle-actions-data'
import {testablePercentChance} from "../helpers/testablePercentChance";
import {getFriendlyTeam} from "./getTeams";
import {shuffle} from "../helpers/shuffle";

export function getReplacedSubmission({initialSubmission, combatants, allActions}) {

  const actions = allActions || BattleActions;
  const actionModel = actions[initialSubmission.actionId];

  //If action model can't be replaced, bail right now.
  if (actionModel.cannotBeReplaced) {
    return initialSubmission;
  }

  //Otherwise, maybe replace it
  const casterModel = combatants.find(c => c.id === initialSubmission.casterId);
  // const targetModels = initialSubmission.targetIds.map(tId => {
  //   return combatants.find(c => c.id === tId);
  // });

  //Case 1: Lag
  if (casterModel.status === "lag" && testablePercentChance(50)) {
      return {
        actionId: "natural_lag-out",
        casterId: casterModel.id,
        targetIds: [casterModel.id]
      }
  }

  //Case 2: Nervous
  if (casterModel.status === "nervous" && testablePercentChance(50)) { //50!
    const friendlyIds = getFriendlyTeam(casterModel.id, combatants).filter(c => c.hp > 0).map(c => c.id);
    return {
      actionId: "natural_nervous-mistake",
      casterId: casterModel.id,
      targetIds: [shuffle([...friendlyIds])[0]] //one random friendly
    }
  }


  return initialSubmission;
}


/*
FYI, a submission is:
  {
    actionId: "noop"
    casterId: "arenaThingA"
    targetIds: ["arenaThingA"]
  }
 */