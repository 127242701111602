export default [
  {
    id: "itemBox",
    skinId: "vendingMachine",
    scenarios: [
      {
        x: 2,
        y: 1,
        isBlinkingVendingMachine: true,
        "bypassOnStoryPoints": ["boobear"],
        direction: "DOWN",
        // behavior: [
        //   {
        //     type: "characterWalk",
        //     direction: "LEFT"
        //   }
        // ],
        // behaviorIndex: 0,
        // lineOfSightInteraction: [
        //   {
        //     "type": "textMessage",
        //     "textArrays": [
        //       [
        //         "[FAST]** I SEE YOU **"
        //       ]
        //     ]
        //   },
        // ]
      },
    ]
  },

  {
    id: "shirmond",
    "skinId": "shirmond",
    scenarios: [
      {
        "scenarioId": "scenario0",
        "x": 10,
        "y": 6,
        "direction": "DOWN",
        "bypassOnStoryPoints": ["boobear"],

        isHostile: true
      },
      {
        "scenarioId": "scenario1",
        //hasBattleLaptop: true,
        //hasFriendlyLaptop: true,
        //isInvisible: true,
        isHostile: true,
        "x": 3,
        "y": 4,
        "direction": "DOWN",
        //"bypassOnStoryPoints": ["boobear"],
        "requiresStoryPoints": [],
      },
      {
        "scenarioId": "scenario2",
        hasFriendlyLaptop: true,
        "x": 3,
        "y": 4,
        "direction": "DOWN",
        "bypassOnStoryPoints": [],
        "requiresStoryPoints": ["boobear"],
      }
    ]
  }
];




// export default [
//   {
//     id: "shirmond",
//     skinId: "shirmond",
//     x: 3,
//     y: 4,
//     direction: "UP",
//     // behaviorIndex: 0,
//     // behavior: [
//     //   {
//     //     "type": "characterWalk",
//     //     "direction": "RIGHT",
//     //     "subjectId": "shirmond",
//     //   },
//     //   {
//     //     "type": "characterStand",
//     //     "direction": "RIGHT",
//     //     "subjectId": "shirmond",
//     //     "postDelayMs": 9000,
//     //   },
//     //   {
//     //     "type": "characterWalk",
//     //     "direction": "LEFT",
//     //     "subjectId": "shirmond",
//     //   },
//     // ]
//   },
// ]