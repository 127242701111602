function easeOutCubic(currentIteration, startValue, changeInValue, totalIterations) {
  return changeInValue * (Math.pow(currentIteration / totalIterations - 1, 3) + 1) + startValue;
}

export function runEaseOut(startValue, changeInStartValue, totalIterations, iterationCallback, doneCallback) {

  let iteration = 0;
  let value = 0;

  let step = function () {
    value = Math.round(easeOutCubic(iteration, startValue, changeInStartValue, totalIterations));

    iterationCallback(value);

    iteration += 1;
    if (iteration < totalIterations) {
      requestAnimationFrame(step);
    } else {
      doneCallback();
    }
  };
  requestAnimationFrame(step);
}