export function createPrefixedId(prefix="", saltLength=5) {

  var text = "";
  var possible = "0123456789abcdefghijklmnopqrstuvwxyz";

  for( var i=0; i < saltLength; i++ )
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return prefix+"_"+Date.now()+text;
}

export default createPrefixedId;