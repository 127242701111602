import React from 'react'
import BlockWord from '../../pixel-letter-system/BlockWord'
import { audioManager } from "../../audio/audioManager";

class EventBlippy extends React.Component {

  constructor(props) {
    super();
    // this.expecting = (props.event.subjects || []).length;
    // console.log('this.expecting', this.expecting)
    this.doneCount = 0;
  }

  componentDidMount() {

    if (this.props.event.teamItemChangeInstructions) {
      this.props.handleItemsStateChange(this.props.event.teamItemChangeInstructions);
    }
    if (this.props.event.spawnCombatants) {
      this.props.handleSpawnCombatants(this.props.event.spawnCombatants);
    }

    this.props.handleCombatantStateChange(this.props.event.stateChanges || []); //array of changes


    //Maybe play a SFX for this blippy
    if (this.props.event.playSfxOnBlippyMount) {
      audioManager.playSfx("sfx_block");
    }

    //Sometimes we provide a blippy that is ONLY a state change with no message. In these cases, just resolve now!
    if (this.props.messageCount === 0) {
      this.props.onEventDone()
    }
  }

  getPosition(subjectId) {
    const {positions, pixelSize} = this.props;

    const initialTop = positions[subjectId].topPosition;
    const initialLeft = positions[subjectId].leftPosition;

    const isFriendlyTeam = initialTop > 400; //A little hacky, but we need to handle enemy and friendly positions differently

    //Enemy
    if (!isFriendlyTeam) {
      return {
        left: initialLeft + pixelSize * 18,
        top: initialTop - (pixelSize * 14),
      }
    }

    //Friendly
    return {
      left: initialLeft + pixelSize * 24,
      top: initialTop + (pixelSize * 35),
    }
  }


  handleAnimationDone(subjectId) {
    this.doneCount += 1;
    if (this.doneCount === this.props.messageCount) {
      this.props.onEventDone()
    }
  }

  render() {
    const {event, pixelSize} = this.props;

    let allMessages = [];
    (event.stateChanges || []).forEach(stateChange => {
      if (stateChange.message) {

        if (stateChange.message.subject) {
          console.warn('explicit subject found in', event, 'but not sure we need to do that anymore. Maybe rm for simplicity')
        }

        allMessages.push({
          ...stateChange.message,
          subject: stateChange.combatantId
        })
      }
    });
    if (allMessages.length === 0) { return null }


    return (
      allMessages.map(message => {

        return (
          <div
            key={message.subject}
            onAnimationEnd={() => this.handleAnimationDone(message.subject)}
            style={{
              zIndex: 4,
              position: "absolute",
              ...this.getPosition(message.subject),
              animation: "battleBlippyRise 1.2s linear forwards"
            }}>
            <BlockWord
              string={message.text}
              theme={message.theme || "dark"}
              pixelSize={pixelSize}
            />
          </div>
        )
      })
    );
  }
}

export default EventBlippy;