import store from '../../init/store'
import {setPlayerDataValue} from "../../redux-actions/player-data-actions";
import {getBattleCrew} from "./getBattleCrew";
import {getCombatantStats} from "../../Battle7/getCombatantStats";
import {getCurrentLevelFromXp} from "../../getCurrentLevelFromXp";

export function mergePersonToAcquiredMembers(newPersonProperties={}, acquiredMembers=[]) {
  const existingMember = acquiredMembers.find(m => m.id === newPersonProperties.id);
  if (existingMember) {
    return acquiredMembers.map(m => {
      if (m.id === newPersonProperties.id) {
        return {
          ...m,
          ...newPersonProperties
        }
      }
      return m;
    })
  }

  //Maybe automatically add to new slot
  let newPerson = {
    ...newPersonProperties
  };

  //create this person with full HP (look at team level and figure out the maxHp)
  const currentLevel = getCurrentLevelFromXp(store.getState().playerData.teamXp); //impure but meh.
  newPerson.hp = getCombatantStats({ level: currentLevel }).maxHp;

  const battleCrew = getBattleCrew(acquiredMembers);
  if (battleCrew.length < 3) {
    newPerson.battleCrewSlot = battleCrew.length + 1;
  }
  //new member being added
  return [
    ...acquiredMembers,
    newPerson
  ]
}

export function mergePersonToAcquiredMembersInRedux(newPersonProperties={}) {
  setPlayerDataValue({
    acquiredMembers: mergePersonToAcquiredMembers(newPersonProperties, store.getState().playerData.acquiredMembers)
  })
}