import React from "react";
import { connect } from "react-redux";
import StaticTextLine from "./StaticTextLine";
import BoxDiv from "./interface/BoxDiv";
import { setGlobalValue } from "./redux-actions/global-actions";
import BoxDivBody from "./interface/BoxDivBody";
import BoxDivTippyTop from "./interface/BoxDivTippyTop"

//"There's way more Danger Crew to go! The rest of the game is available on our website: thedangercrew.com"
//Now the "Go to our website" screen
class ComingSoonScreen extends React.Component {
  render() {
    const { pixelSize } = this.props;
    return (

      <BoxDiv pixelSize={pixelSize}>
        <BoxDivTippyTop pixelSize={pixelSize} />
        <BoxDivBody pixelSize={pixelSize}>
          <main style={{ padding: pixelSize * 8 }}>
            <div style={{ marginBottom: pixelSize * 10 }}>
              <StaticTextLine
                pixelSize={pixelSize}
                text={"Thank you for playing!"}
                //theme="white"
              />
            </div>
            <div style={{ width: pixelSize * 200, marginBottom: pixelSize * 10 }}>
              <StaticTextLine
                pixelSize={pixelSize}
                text={
                  "There's way more Danger Crew to go! The rest of the game is available on our website: thedangercrew.com"
                }
                //theme="white"
              />
            </div>
            <div>
              <StaticTextLine
                pixelSize={pixelSize}
                text={"Love,"}
                //theme="white"
              />
            </div>
            <div style={{ marginBottom: pixelSize * 10 }}>
              <StaticTextLine
                pixelSize={pixelSize}
                text={"-Team Danger Crew"}
                //theme="white"
              />
            </div>
            <div style={{ position: "relative", width: pixelSize * 100 }}>
              <button
                onClick={() => {
                  setGlobalValue({ gameArea: "title" });
                }}
                style={{
                  position: "absolute",
                  display: "block",
                  left: 0, top: 0, bottom: 0, right: 0,
                  width: "100%",
                  cursor: "pointer",
                  background: "red"
                }}/>
              <BoxDiv pixelSize={pixelSize} style={{ pointerEvents: "none" }}>
                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  background: "#729efc",
                  paddingTop: pixelSize,
                  borderBottom: `${pixelSize}px solid #4372e9`
                }}>
                  <StaticTextLine
                    pixelSize={pixelSize}
                    text={"Back to Title"}
                    theme="white"
                  />
                </div>
              </BoxDiv>
            </div>
          </main>
        </BoxDivBody>
      </BoxDiv>
    );
  }
}

export default connect((state, props) => {
  return {
    pixelSize: state.global.pixelSize
  };
})(ComingSoonScreen);
