export function getNextScrollingOptionPosition(prevSelectedId, newSelectedId, currentArray, totalArray) {

  const prevIndex = totalArray.indexOf(prevSelectedId);
  const nextIndex = totalArray.indexOf(newSelectedId);

  if (!totalArray.includes(newSelectedId)) {
    return currentArray;
  }

  const isNextIndexAlreadyVisible = currentArray.indexOf(totalArray[nextIndex]) > -1;
  if (isNextIndexAlreadyVisible) {
    return currentArray
  }

  const changeDirection = nextIndex > prevIndex ? "DOWN" : "UP";

  if (changeDirection === "DOWN") {
    const pageLength = currentArray.length;
    let payload = [];
    for(let i=0; i<pageLength; i++) {
      payload.push( totalArray[nextIndex-i] )
    }
    return payload.reverse()
  }

  if (changeDirection === "UP") {
    const pageLength = currentArray.length;
    let payload = [];
    for(let i=0; i<pageLength; i++) {
      payload.push( totalArray[nextIndex+i] )
    }
    return payload;
  }
}