import React from 'react'
import {createSingleKeyPressBinding} from "../helpers/createSingleKeyPressBinding";
import {audioManager} from "../audio/audioManager";

class RevealingTextContainer extends React.Component {

  constructor(props) {
    super(props);

    this.letterStream = props.letterStream.filter(c => c.character !== " ");


    this.state = {
      isDoneWorking: false,
      lettersRevealed: -1,
      //Filter for only non-space characters because those dont get rendered
      lettersMax: (this.letterStream.length) - 1
    }
  }

  componentDidMount() {

    //TODO: only do this if we have "support fastforward" prompt.
    this.promptHandler = createSingleKeyPressBinding([13, 32], () => {
      this.handleUserPrompt();
    });

    const revealOne = () => {
      const node = this.letterStream[this.state.lettersRevealed+1];

      audioManager.playSfx("sfx_typeBlip");

      this.wait(node.delayFramesAfter, () => {
        this.setState(state => {
          return {lettersRevealed: state.lettersRevealed + 1}
        });
        if (this.state.lettersRevealed < this.state.lettersMax) {
          revealOne()
        } else {
          this.setState({
            isDoneWorking: true,
          })
        }
      })
    };
    revealOne();
  }

  componentWillUnmount() {
    let el = document.getElementsByTagName("body")[0];
    [
      ...this.promptHandler,
    ].forEach(b => {
      el.removeEventListener("keydown", b.keyDownHandler);
      el.removeEventListener("keyup", b.keyUpHandler);
    });
  }

  handleUserPrompt = () => {
    if (this.state.lettersRevealed < this.state.lettersMax) {
      if (this.rAF) { cancelAnimationFrame(this.rAF)}
      this.setState({
        lettersRevealed: this.letterStream.length - 1
      })
    } else {
      //We are done, so go to the next line.
      this.props.onUserPrompted();
    }
  };



  wait(frames, done) {
    let waited = 0;

    let step = () => {
      waited+=1;

      if (waited < frames) {
        this.rAF = requestAnimationFrame(step)
      } else {
        done();
      }
    };
    this.rAF =requestAnimationFrame(step)
  }

  render() {
    return (
      <div onClick={this.handleUserPrompt}>
        {this.props.render(this.state)}
      </div>
    );
  }
}

RevealingTextContainer.defaultProps = {
  letterStream: []
};

export default RevealingTextContainer;

