import {DefaultAction} from "../Action";

export default {
  ...DefaultAction,

  getUse() { return [] },

  shouldFailOnSingleTarget(casterModel, targetModel) {
    if (targetModel.status !== "memory-leak") {
      return {
        failCode: "not-memory-leak"
      }
    }
    return null;
  },

  failEventsPerTarget(casterModel, targetModel, failInfo={}) {
    return [
    ]
  },

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {
    return [
      {
        type: "eventDialogBox",
        subject: casterModel.id,
        textArray: ["[LIGHTNING]LAPTOP CAUGHT ON FIRE!"],
        haltQueueUntilDone: true,
      },
      {
        type: "eventBlippy",
        subjects: [targetModel.id],
        stateChanges: [
          {
            combatantId: targetModel.id,
            absoluteChanges: {
              status: "fire"
            }
          }
        ],
      }
    ]
  }
}