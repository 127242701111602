import Upgrades from "../Battle7/battle-data/Upgrades";

export function getPauseUpgradesMenu({upgrades, allUpgrades}) {

  allUpgrades = allUpgrades || Upgrades;
  upgrades = upgrades.map(u => u.libraryId);

  let map = [];
  let menu = {
    back: {
      handler() {
        return { type: "GO_BACK" }
      }
    }
  };

  upgrades.forEach((upgradeId, index) => {
    const model = allUpgrades[upgradeId];
    const key = `upgrade${index}`;
    menu[key] = {
      label: model.name,
      description: model.description,
      icon: "upgrade",
      handler() {
        //Upgrade doesn't do anything in the pause menu?
      }
    };
    map.push([key])
  });

  return {
    map, menu, backOptionId: "back"
  }
}