import Upgrades from "../Upgrades";

export function getCasterAccuracy(actionModel, casterModel, targetModel, allUpgrades) {

  if (!typeof actionModel.accuracy === "number" || casterModel.status === "focus" || targetModel.playerWontMiss) {
    return 100;
  }

  //Upgrades
  const upgrades = allUpgrades || Upgrades;
  const casterUpgrade = upgrades[casterModel.activeUpgradeId || null];
  if (casterUpgrade && casterUpgrade.upgradeAccuracy) {
    return 100;
  }

  let casterAccuracyModifier = casterModel.accuracyModifier || 0;
  let targetSlipperyModifier = targetModel.slipperyModifier || 0;


  if (casterAccuracyModifier < -30) {
    casterAccuracyModifier = -30; //Don't let the accuracy get too low
  }

  return actionModel.accuracy + casterAccuracyModifier - targetSlipperyModifier;
}