import {getBattleCrewFromRedux} from "./getBattleCrew";
import store from '../../init/store'
import {setPlayerDataValue} from "../../redux-actions/player-data-actions";

export function updatePlayerDataHpInRedux(event) {

  const updatedBattleCrewHp = getBattleCrewFromRedux().map(c => {
    //Don't recover if dead!
    if (c.hp <= 0) {
      return {
        id: c.id,
        hp: c.hp
      }
    }
    let newValue = Math.round( c.hp + (c.maxHp * event.percent) );
    if (newValue > c.maxHp) {
      newValue = c.maxHp;
    }
    return {
      id: c.id,
      hp: newValue
    }
  });

  const updatedAcquiredMembers = store.getState().playerData.acquiredMembers.map(m => {
    const battleCrewUpdate = updatedBattleCrewHp.find(c => c.id === m.id);
    if (battleCrewUpdate) {
      return {
        ...m,
        hp: battleCrewUpdate.hp
      }
    }
    return m;
  });

  setPlayerDataValue({
    acquiredMembers: updatedAcquiredMembers
  })
}