import React from 'react';
import FormField from './FormField'
import {getEmailValidationErrors} from './validations'
import FormSubmitButton from './FormSubmitButton'
import {authRequestPasswordReset} from "./auth-requests";

class ForgotPasswordForm extends React.Component {

  state = {
    isLocked: false,
    globalErrors: []
  };

  handleSubmitRequest = () => {

    if (this.state.isLocked) { return }

    this.props.setLoading(true);
    this.setState({ isLocked: true });

    authRequestPasswordReset({
      email: this.props.fieldEmail,

      success: (response) => {
        this.props.handleChangeViewId("resetPasswordSuccess");
        this.props.setLoading(false);
      },

      fail: (response) => {
        this.setState({
          isLocked: false,
          globalErrors: response.errors //[] whatever was in the response
        });
        this.props.setLoading(false);
      }
    })

  };


  getProblems() {
    let payload = {};
    const {fieldEmail} = this.props;
    [
      {input: fieldEmail, fn: getEmailValidationErrors, key: "email"},
    ].forEach(test => {
      const error = test.fn(test.input);
      if (error) {
        payload[test.key] = error;
      }
    });
    return payload;
  }

  render() {
    const {updateFormField, fieldEmail} = this.props;
    const problems = this.getProblems();
    const isFormReady = Object.keys(problems).length === 0;

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          this.handleSubmitRequest()
        }}
      >

        <h2 style={{margin: "0 0 5px 0"}}>Forgot Password</h2>
        <p style={{margin: "0 0 2em 0"}}>Don't worry - it happens to us all the time! Enter your email address and we will send you a magic resetting link.</p>

        <FormField
          label="Email Address"
          value={fieldEmail}
          onChange={(newValue) => {
            updateFormField("fieldEmail", newValue)
          }}
        />

        <FormSubmitButton
          text="Send Reset Link"
          isDisabled={!isFormReady}
        />
        {
          this.state.globalErrors.length > 0 && (
            this.state.globalErrors.map((error, key) => {
              return <div style={{color: "red", padding: "1em 0 0", textAlign: "center"}} key={key}>{error}</div>
            })
          )
        }
        <div style={{paddingTop: "3em", textAlign:"center"}}>
          <a style={{color:"#4A90E2"}} href="#backToLogin" onClick={(e) => {
            e.preventDefault();
            this.props.handleSetForgotPassword(false)
          }}>
            Go back to Login
          </a>
        </div>
      </form>
    );
  }
}

export default ForgotPasswordForm;


// export default (props) => {
//   return (
//     <div>
//
//       Forgot your password? I can help!
//
//       <a href="#forgotPassword" onClick={(e) => {
//         e.preventDefault();
//         props.handleSetForgotPassword(false)
//       }}>
//         Go back to Login
//       </a>
//     </div>
//   )
// }