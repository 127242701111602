//Try to swap places A and B in the array
export function swapEntriesInArray(initialArray, flipPlaceA, flipPlaceB) {

  let array = [...initialArray];

  if (!flipPlaceA || !flipPlaceB) {
    return array;
  }

  const wasIndex = array.indexOf(flipPlaceA);
  const newIndex = array.indexOf(flipPlaceB);

  array[wasIndex] = flipPlaceB;
  array[newIndex] = flipPlaceA;

  return array;

}