import React from 'react'
import {connect} from 'react-redux'
import CreatePersonNameForm from './CreatePersonNameForm'
import CreatePersonSkinForm from './CreatePersonSkinForm'
import CreatePersonConfirmForm from './CreatePersonConfirmForm'
import {setOverworldValue} from "./redux-actions/overworld-actions";
import {getNextExternalEventTrigger} from "./Overworld/getIncrExternalEventTrigger";
import {mergePersonToAcquiredMembersInRedux} from "./Overworld/player-data-operations/mergePersonToAcquiredMembers";
import {audioManager} from "./audio/audioManager";
import {getCombatantStats} from "./Battle7/getCombatantStats";
import {getCurrentLevelFromXp} from "./getCurrentLevelFromXp";
import {addAttackToPlayerDataInRedux} from "./Overworld/player-data-operations/addAttackToPlayerData";


class CreatePersonMenu extends React.Component {

  state = {
    stage: "skin",//"name", "skin", "confirm"
    userProvidedName: "",
    userProvidedSkin: null,
    userHasConfirmed: false,
  };

  handleSubmission = (stateKey, value) => {
    this.setState({
      [stateKey]: value
    }, () => {

      if (this.state.stage === "confirm") {

        audioManager.playSfx("sfx_submitAction");

        this.handleFinishCreatingPerson();
        return
      }

      this.setState(state => {
        return {
          stage: function() {
            //Friendly navigation if you've already filled out one of the screens (maybe you're jumping around from Confirm)
            if (state.stage === "skin" && state.userProvidedName.length < 2) {
              return "name"
            }
            if (state.stage === "name" && !state.userProvidedSkin) {
              return "skin"
            }

            return "confirm"
          }()
        }
      })
    })
  };

  handleFinishCreatingPerson= () => {

    //Merge this person into our backlog
    const {userProvidedName, userProvidedSkin} = this.state;
    mergePersonToAcquiredMembersInRedux({
      name: userProvidedName,
      skinId: userProvidedSkin,
      hp: getCombatantStats({ level: this.props.currentLevel} ).maxHp, //start with max hp

      ...this.props.memberProperties,
    });

    //Add this person's attacks to redux player data so everybody in the party has rightful access
    const attacks = this.props.memberProperties.equippedAttacks || {};
    Object.keys(attacks).forEach(slot => {
      const actionId = attacks[slot];
      if (actionId) {
        addAttackToPlayerDataInRedux(actionId, false)
      }
    });

    //move on with the Overworld
    setOverworldValue({
      showCreatePersonMenu: null,
      externalEventTrigger: getNextExternalEventTrigger("CREATE-PERSON-MENU_DONE")
    })
  };

  render() {
    const {stage, userProvidedName, userProvidedSkin} = this.state;
    const {pixelSize} = this.props;

    const containerStyle = {position: "absolute", top: pixelSize * 16, left: 0, right: 0, display: "flex", justifyContent: "center"}

    if (stage === "name") {
      return (
        <div style={containerStyle}>
          <CreatePersonNameForm
            pixelSize={pixelSize}
            defaultValue={userProvidedName || ""}
            isHero={this.props.memberProperties.id === "hero"}
            handleSubmission={(value) => this.handleSubmission("userProvidedName", value)}
          />
        </div>
      );
    }

    if (stage === "skin") {
      return (
        <div style={containerStyle}>
          <CreatePersonSkinForm
            pixelSize={pixelSize}
            defaultValue={userProvidedSkin}
            isHero={this.props.memberProperties.id === "hero"}
            handleGoBack={() => {this.setState({stage: "name"}) }}
            handleSubmission={(value) => this.handleSubmission("userProvidedSkin", value)}
          />
        </div>
      );
    }

    if (stage === "confirm") {
      return (
        <div style={containerStyle}>
          <CreatePersonConfirmForm
            pixelSize={pixelSize}
            handleChangePage={(newpage) => {this.setState({stage: newpage}) }}
            userProvidedName={userProvidedName}
            userProvidedSkin={userProvidedSkin}
            memberClass={this.props.memberProperties.class}
            handleSubmission={(value) => this.handleSubmission("userHasConfirmed", value)}
          />
        </div>
      );
    }

    return null;
  }
}

export default connect((state, props) => {
  return {
    currentLevel: getCurrentLevelFromXp(state.playerData.teamXp),
    pixelSize: state.global.pixelSize,
    memberProperties: state.overworld.showCreatePersonMenu.memberProperties
  }
})(CreatePersonMenu)