import {DefaultAction, DefaultScript} from "../Action";
import {getRandomInRange} from "../../../../helpers/testablePercentChance";
import {getIterativeAttackDamage} from "../../formulas/getAttackDamage";
import {casterAffectsIterationCount, targetAffectsIterationCount} from "../../../getAffectedIterationCount";

//import {getAttackDamage} from "../../formulas/getAttackDamage";

function createForEachAction(properties) {
  return {
    ...DefaultAction,
    ...DefaultScript,
    name: properties.name,
    description: properties.description,
    icon: "dps",
    accuracy: 96,

    successEventsPerTarget(casterModel, targetModel, allUpgrades) {

      const {min, max} = properties;


      //maybe just block
      const blockSteps = (targetModel.status === "block") ?
        [
          {
            type: "eventAnimation",
            animationId: "animationIterate",
            casterId: casterModel.id,
            subjects: [targetModel.id],
            haltQueueUntilDone: true
          },
          {
            type: "eventBlippy",
            subjects: [targetModel.id],
            stateChanges: [
              {
                combatantId: targetModel.id,
                relativeChanges: {
                  hp: -1
                },
                absoluteChanges: {
                  status: "normal"
                },
                message: {
                  theme: "battleNegativeHp",
                  text: `${-1} HP`
                }
              }
            ],
            delayFramesAfter: 60,
          },
          {
            type: "eventBlippy",
            stateChanges: [
              {
                combatantId: targetModel.id,
                message: {
                  theme: "battleDamageBlock",
                  text: `Damage Absorbed!`
                },
              }
            ],
            delayFramesAfter: 10,
          }
        ] : [];



      let iterationCount = getRandomInRange(min, max);
      const casterAdjustments = casterAffectsIterationCount({
        min: min,
        max: max,
        initialValue: iterationCount,
        casterModel
      });
      iterationCount = casterAdjustments.value;

      //maybe add the maximizing step
      let casterChange = function () {
        if (casterAdjustments.changeCode === "maximized") {
          return {
            type: "eventDialogBox",
            subject: casterModel.id,
            textArray: ["Loop Boosted!"],
            haltQueueUntilDone: true
          }
        }
        return null;
      }();

      const targetAdjustments = targetAffectsIterationCount({
        min: min,
        max: max,
        initialValue: iterationCount,
        targetModel
      });
      iterationCount = targetAdjustments.value;

      //Target adjustments
      let targetChange = function () {
        if (targetAdjustments.changeCode === "minimized") {
          return {
            type: "eventDialogBox",
            subject: targetModel.id,
            textArray: ["Loop Breaker!"],
            haltQueueUntilDone: true
          }
        }
        return null;
      }();

      let payload = [
        casterChange,
        targetChange,
        ...blockSteps,
      ];

      if (blockSteps.length > 0) {
        iterationCount -= 1; //Take away an iteration because 1 is baked into the block step
      }



      let damagePerIteration = getIterativeAttackDamage(2, casterModel, targetModel);

      let targetModelHpCount = targetModel.hp; //keep track of this so when we know to stop for non-overkill

      for (let i = 0; i < iterationCount; i++) {
        damagePerIteration = damagePerIteration-i;

        if (targetModelHpCount > 0) {

          targetModelHpCount -= Math.abs(damagePerIteration);

          payload = [
            ...payload,
            {
              type: "eventAnimation",
              animationId: "animationIterate",
              casterId: casterModel.id,
              subjects: [targetModel.id],
              haltQueueUntilDone: true
            },
            {
              type: "eventBlippy",
              subjects: [targetModel.id],
              stateChanges: [
                {
                  combatantId: targetModel.id,
                  relativeChanges: {
                    hp: damagePerIteration
                  },
                  message: {
                    theme: "battleNegativeHp",
                    text: `${damagePerIteration} HP`
                  }
                }
              ],
              delayFramesAfter: 10,
            }
          ]
        }
      }

      return payload.filter(m => m);
    }
  }
}

export const ForEach = createForEachAction({
  name: "ForEach",
  description: "Deliver pain in 2 to 4 iterations",
  min: 2,
  max: 4
});

export const ForEachMkII = createForEachAction({
  name: "ForEach Mk II",
  description: "Deliver even more pain in 3 to 5 iterations",
  min: 3,
  max: 5
});