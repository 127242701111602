import React from 'react'
import {waitFrames} from "../../../helpers/waitFrames";
import RunAnimation from './RunAnimation'
import {audioManager} from "../../../audio/audioManager";


class AnimationSlice extends React.Component {

  state = {
    hasTriggeredAnimation: false
  };

  componentDidMount() {

    if (this.props.event.isTeamSlice) {
      audioManager.playSfx("sfx_submitMegaSuccess");
    }

    const targets = this.props.event.subjects;


    waitFrames(7, () => {
      this.setState({
        hasTriggeredAnimation: true
      }, () => {
        //Start shaking
        targets.forEach(id => {

          this.props.handleCombatantStateChange([
            {
              combatantId: id,
              absoluteChanges: {
                laptopFlash: "pain",
                animation: "characterShake",
              }
            }
          ])
        });
        waitFrames(3, () => {
          audioManager.playSfx("sfx_iteratePain")
        });



        //Stop shaking
        waitFrames(20, () => {
          targets.forEach(id => {
            this.props.handleCombatantStateChange([
              {
                combatantId: id,
                absoluteChanges: {
                  laptopFlash: "none",
                  animation: null,
                }
              }
            ])
          });
        });
      });
    });
  }

  render() {
    const {pixelSize, event, positions} = this.props;
    return event.subjects.map((subjectId, i) => {
      return (
        <RunAnimation
          key={subjectId}
          pixelSize={pixelSize}
          left={positions[subjectId].leftPosition}
          top={positions[subjectId].topPosition}
          widthOfSpriteSheet={160}
          frames={5}
          duration="0.4s"
          onDone={() => { waitFrames(10, () => {this.props.onEventDone()}) }}
          renderPaths={() => {

            if (event.isTeamSlice) {
              return (
                <React.Fragment>
                  <path stroke="#fff" d="M65 2h30m2 0h30m2 0h30M65 3h30m2 0h30m2 0h30M53 26h10m-20 1h20M1 28h30m2 0h20M1 29h30m2 0h10"/>
                  <path stroke="#f9d339" d="M65 4h30M65 5h30M65 6h30M65 7h30M65 8h30M65 9h30m-30 1h30m-30 1h28m-28 1h26m-26 1h24m-24 1h22m-22 1h20m-20 1h18m-18 1h16m-16 1h14m-14 1h12m-12 1h10m-10 1h8m-8 1h6m-6 1h4m-4 1h2"/>
                  <path stroke="#d77bba" d="M97 4h22M97 5h20M97 6h18M97 7h16M97 8h14M97 9h12m-12 1h10m-10 1h8m-8 1h6m-6 1h4m-4 1h2"/>
                  <path stroke="#c959ac" d="M119 4h8m-10 1h10m-12 1h10m-12 1h10m-12 1h10m-12 1h10m-12 1h10m-12 1h10m-12 1h10m-12 1h10m-12 1h10m-12 1h10m-10 1h8m-8 1h6m-6 1h4m-4 1h2"/>
                  <path stroke="#9e3c9a" d="M125 6h2m-4 1h2m-4 1h2m-4 1h2m-4 1h2m-4 1h2m-4 1h2m-4 1h2m-4 1h2m-4 1h2m-4 1h2m-4 1h2m-4 1h2m-4 1h2m-4 1h2"/>
                  <path stroke="#f39646" d="M93 11h2m-4 1h4m-6 1h6m-8 1h8m-10 1h10m-12 1h12m-14 1h14m-16 1h16m-18 1h18m-20 1h20m-22 1h22m-24 1h24m-26 1h26m-28 1h28m-30 1h30m-30 1h30m-30 1h30m-30 1h30m-30 1h30"/>
                </React.Fragment>
              )
            }

            return(
              <React.Fragment>
              <path stroke="#ffffff" d="M68 2h24M100 2h24M132 2h24M68 3h24M100 3h24M132 3h24M53 26h7M44 27h16M4 28h24M36 28h17M4 29h24M36 29h8" />
              <path stroke="#8a25f4" d="M68 4h24M68 5h24M68 6h24M68 7h24M68 8h24M68 9h24M68 10h24M68 11h24M68 12h24M68 13h24M68 14h22M68 15h20M68 16h18M68 17h16M68 18h14M68 19h12M68 20h10M68 21h8M68 22h6M68 23h4M68 24h2" />
              <path stroke="#fde76b" d="M100 4h22M100 5h20M100 6h18M100 7h16M100 8h14M100 9h12M100 10h10M100 11h8M100 12h6M100 13h4M100 14h2" />
              <path stroke="#fdaf43" d="M122 4h2M120 5h4M118 6h6M116 7h8M114 8h10M112 9h10M110 10h10M108 11h10M106 12h10M104 13h10M102 14h10M100 15h10M100 16h8M100 17h6M100 18h4M100 19h2" />
              <path stroke="#e48d37" d="M122 9h2M120 10h2M118 11h2M116 12h2M114 13h2M112 14h2M110 15h2M108 16h2M106 17h2M104 18h2M102 19h2M100 20h2" />
              <path stroke="#68396b" d="M90 14h2M88 15h4M86 16h6M84 17h8M82 18h10M80 19h12M78 20h14M76 21h16M74 22h18M72 23h20M70 24h22M68 25h24M68 26h24M68 27h24M68 28h24M68 29h24" />
            </React.Fragment>
            )
          }}
        />
      )
    })
  }
}

export default AnimationSlice;