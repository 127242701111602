export function hasInvalidStoryPoints(existingStoryPoints=[], invalidStoryPoints=[]) {
  for (let i=0; i<=existingStoryPoints.length-1; i++) {
    if (invalidStoryPoints.indexOf(existingStoryPoints[i]) !== -1) {
      return true;
    }
  }
  return false;
}

export function hasAllRequiredStoryPoints(existingStoryPoints=[], requiredStoryPoints=[]) {
  return requiredStoryPoints.every(sp => existingStoryPoints.indexOf(sp) !== -1)
}

//This one puts them both together
export function areStoryPointsValid(acquiredStoryPoints=[], bypassOnStoryPoints=[], requiresStoryPoints=[]) {
  if (
    hasInvalidStoryPoints(acquiredStoryPoints, bypassOnStoryPoints)
  ) {
    return false;
  }
  if (!hasAllRequiredStoryPoints(acquiredStoryPoints, requiresStoryPoints)) {
    return false;
  }
  return true;
}