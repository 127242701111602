import {DefaultAction, DefaultScript} from "../Action";
import {getGenericFail} from "../../formulas/getGenericFail";

export default {
  ...DefaultAction,
  ...DefaultScript,
  name: "Shut Down",
  description: "Causes an opponent to be disabled for 3 rounds",
  icon: "disrupt",

  shouldFailOnSingleTarget(casterModel, targetModel) {

    if (targetModel.immunities && targetModel.immunities.includes("nervous")) {
      return {
        failCode: "immune"
      }
    }

    if (targetModel.status !== "normal") {
      return {
        failCode: "has-status" //could be "denied-by-upgrade" in the future, etc
      }
    }
    return null;
  },

  failEventsPerTarget(casterModel, targetModel, failInfo={}) {
    //Maybe add "WHY" here if an upgrade caused it to fail?
    return getGenericFail({targetModel, failInfo})
  },

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {
    return [
      {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: targetModel.id,
            absoluteChanges: {
              status: "disabled"
            },
            message: {
              theme: "battleNegativeStatus",
              text: `DISABLED!`
            },
          }
        ]
      },
      {
        type: "eventSchedule",
        roundsFromNow: 3,
        combatantId: targetModel.id,
        event: {
          actionId: "natural_end-disabled",
          casterId: targetModel.id,
          targetIds: [targetModel.id]
        }
      }
    ]
  }
}