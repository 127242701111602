//import {shuffle} from "../../../helpers/shuffle";
export default function({combatants=[], battleStoryPoints=[], regularDecision, subject}) {

  //Do Wildfire on First Turn
  if (!battleStoryPoints.includes("oliver--first-turn")) {
    return {
      submission: [
        {
          actionId: "speak",
          casterId: subject.id,
          targetIds: [subject.id],
          mergeData: {
            dialogContent: [
              "[FAST]Oliver feels pain!"
            ]
          }
        },
        {
          actionId: "super_wildfire",
          casterId: subject.id,
          targetIds: combatants.filter(c => c.belongsToTeam === "one" && c.hp > 0).map(c => c.id),
        },
      ],
      updatedBattleStoryPoints: [...battleStoryPoints, "oliver--first-turn"]
    }
  }

  //Heal friends if they are alive but suffering
  const aliveFriendsWhoNeedHelp = combatants.filter(c => {
    return c.belongsToTeam === "two" && c.id !== subject.id && c.hp > 0 && (c.hp <= c.maxHp / 2)
  });
  if (aliveFriendsWhoNeedHelp.length > 0) {
    return {
      submission: [
        {
          actionId: "speak",
          casterId: subject.id,
          targetIds: [subject.id],
          mergeData: {
            dialogContent: [
              "[FAST]Oliver loves his friends!"
            ]
          }
        },
        {
          actionId: "script_restart",
          casterId: subject.id,
          targetIds: aliveFriendsWhoNeedHelp.map(c => c.id),
        },
      ],
      updatedBattleStoryPoints: battleStoryPoints
    }
  }


  if (!battleStoryPoints.includes("oliver--friend-fury")) {
    const oliverAliveFriends = combatants.filter(c => {
      return c.belongsToTeam === "two" && c.id !== subject.id && c.hp > 0
    });
    if (oliverAliveFriends.length === 0 && subject.status === "normal") {
      return {
        submission: [
          {
            actionId: "speak",
            casterId: subject.id,
            targetIds: [subject.id],
            mergeData: {
              dialogContent: [
                "[FAST]Don't hurt friends!"
              ]
            }
          },
          {
            actionId: "script_troll",
            casterId: subject.id,
            targetIds: [subject.id],
          },
        ],
        updatedBattleStoryPoints: [...battleStoryPoints, "oliver--friend-fury"]
      }
    }
  }





  //Default to regular
  return {
    submission: regularDecision,
    updatedBattleStoryPoints: battleStoryPoints
  }
}