import React from "react";
import { connect } from "react-redux";
import { getPauseItemsMenu } from "./getPauseItemsMenu";
import ArrowMenu from "../ArrowMenu/ArrowMenu";
import ScrollingOptionsList from "../menu-components/ScrollingOptionsList";
import StaticTextLine from "../StaticTextLine";
import { getUseItemMenu, getSellItemConfirmMenu } from "./getUseItemMenu";
import BattleActions from "../Battle7/battle-data/battle-actions-data";
import { getBattleCrewFromRedux } from "../Overworld/player-data-operations/getBattleCrew";
import BoxDiv from "../interface/BoxDiv";
import BoxDivBody from "../interface/BoxDivBody";
import Icon from '../interface/Icon'
import PauseHeader from './PauseHeader'

class PauseItemsArrowMenu extends React.Component {
  state = {
    rememberedOptions: {}, //built up as you navigate the menu
    itemsMenuId: "list" //"list" "action", "confirmUse", "confirmSell"
  };

  optionHandler = (action = {}) => {
    if (action.type === "ACTION") {
      this.setState({
        itemsMenuId: "action",
        stagedActionId: action.actionId,
        stagedInstanceId: action.instanceId
      });
    }

    if (action.type === "GO_TO_LIST") {
      //Check that we still have items to show (maybe you sold or used them all?)
      if (this.props.inventory.length > 0) {
        this.setState({
          itemsMenuId: "list"
        });
      } else {
        console.log("no items, back to home!");
        //no more items! go back to Pause Home
        this.props.handleChangeMenuPage("home");
      }
    }
    if (action.type === "GO_TO_CONFIRM_USE") {
      this.setState({
        itemsMenuId: "confirmUse"
      });
    }
    if (action.type === "GO_TO_CONFIRM_SELL") {
      this.setState({
        itemsMenuId: "confirmSell"
      });
    }

    if (action.type === "GO_BACK") {
      const { itemsMenuId } = this.state;
      if (itemsMenuId === "action") {
        this.setState({
          itemsMenuId: "list"
        });
        return;
      }
      if (itemsMenuId === "confirmUse" || itemsMenuId === "confirmSell") {
        this.setState({
          itemsMenuId: "action"
        });
        return;
      }

      this.props.handleChangeMenuPage("home");
    }
  };

  render() {
    const { pixelSize, inventory } = this.props;
    const { itemsMenuId } = this.state;

    const { map: listMap, menu: listMenu } = getPauseItemsMenu({
      inventory
    });

    const item = BattleActions[this.state.stagedActionId] || null;
    const { map: useItemMap, menu: useItemMenu } = getUseItemMenu({
      item,
      crew: getBattleCrewFromRedux(),
      stagedInstanceId: this.state.stagedInstanceId
    });

    //These could probably be cleaned up because we don't need to use them every render. But keep it simple for now:
    const {
      map: confirmSellItemMap,
      menu: confirmSellItemMenu
    } = getSellItemConfirmMenu({
      item,
      stagedInstanceId: this.state.stagedInstanceId
    });

    let map = listMap;
    let menu = listMenu;
    if (itemsMenuId === "action") {
      map = useItemMap;
      menu = useItemMenu;
    }
    if (itemsMenuId === "confirmSell") {
      map = confirmSellItemMap;
      menu = confirmSellItemMenu;
    }

    if (map.length === 0) {
      return <div>No items!</div>;
    }

    return (
      <ArrowMenu
        key={`${inventory.length}`} //remount when inventory changes
        handler={this.optionHandler}
        backOptionId={"back"}
        optionsPageId={itemsMenuId}
        optionsMap={map}
        optionModels={menu}
        rememberedOptionId={this.state.rememberedOptions[itemsMenuId] || null}
        onNewOptionsPage={change => {
          this.setState(state => ({
            rememberedOptions: {
              ...state.rememberedOptions,
              [change.previousMenuId]: change.previousSelectedOptionId
            }
          }));
        }}
        render={({ handlers, selectedOptionId }) => {
          const selectedOptionModel = menu[selectedOptionId] || {};

          return (
            <div style={{ width: pixelSize * 180 }}>
              <PauseHeader
                title={"Items"}
                rightPreset={"credits"}
                handleBackClick={() => this.props.handleChangeMenuPage("home")}
              />
              <ScrollingOptionsList
                isInCurrentMenu={itemsMenuId === "list"}
                handlers={handlers}
                optionsMap={listMap}
                optionModels={listMenu}
                selectedOptionId={selectedOptionId}
                optionsMinimum={5}
                optionsLimit={6}
                pixelSize={pixelSize}
                renderOptionRight={option => {
                  if (option.quantity > 1) {
                    return (
                      <StaticTextLine
                        pixelSize={pixelSize}
                        text={`x${option.quantity}`}
                      />
                    );
                  }
                  return null;
                }}
              />

              <div style={{ marginTop: pixelSize * 4 }}>
                <BoxDiv pixelSize={pixelSize}>
                  <BoxDivBody
                    grayTextboxTheme
                    pixelSize={pixelSize}
                    style={{
                      paddingTop: pixelSize * 2,
                      paddingLeft: pixelSize * 4,
                      paddingRight: pixelSize * 4,
                      minHeight: pixelSize * 42
                    }}
                  >
                    <StaticTextLine
                      key={selectedOptionId}
                      pixelSize={pixelSize}
                      text={selectedOptionModel.description}
                    />
                  </BoxDivBody>
                </BoxDiv>
              </div>

              {["action", "confirmSell"].includes(this.state.itemsMenuId) && (
                <div
                  style={{
                    left: 0,
                    top: pixelSize * 5,
                    right: 0,
                    bottom: 0,
                    background: "rgba(255,255,255,1)",
                    position: "absolute",
                    zIndex: 3,
                    padding: pixelSize * 8,
                    paddingTop: pixelSize * 6
                  }}
                >
                  <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: pixelSize * 5}}>
                    <div style={{marginRight: pixelSize * 4}}>
                      <Icon category="item" pixelSize={pixelSize} />
                    </div>
                    <StaticTextLine
                      pixelSize={pixelSize}
                      text={BattleActions[this.state.stagedActionId].name}
                    />
                  </div>
                  <div style={{marginBottom: pixelSize * 5, display: "flex", flexWrap: "wrap", justifyContent: "center", width: "100%"}}>
                    <StaticTextLine
                      pixelSize={pixelSize}
                      text={BattleActions[this.state.stagedActionId].description}
                    />
                  </div>
                  {this.state.itemsMenuId === "action" && (
                    <ScrollingOptionsList
                      handlers={handlers}
                      optionsMap={useItemMap}
                      optionModels={useItemMenu}
                      selectedOptionId={selectedOptionId}
                      pixelSize={pixelSize}
                    />
                  )}
                  {this.state.itemsMenuId === "confirmSell" && (
                    <div>
                      <StaticTextLine
                        key={selectedOptionId}
                        pixelSize={pixelSize}
                        text="Are you sure you want to sell?"
                      />
                      <ScrollingOptionsList
                        handlers={handlers}
                        optionsMap={confirmSellItemMap}
                        optionModels={confirmSellItemMenu}
                        selectedOptionId={selectedOptionId}
                        pixelSize={pixelSize}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        }}
      />
    );
  }
}

export default connect((state, props) => {
  return {
    inventory: state.playerData.inventory,
    pixelSize: state.global.pixelSize
  };
})(PauseItemsArrowMenu);
