//Helper utility to figure out if a move should auto select the caster (like Items or self boosting status)
import BattleActions from "../battle-data/battle-actions-data";

export function shouldAutoSelectCaster({ stagedActionId }) {

  //self boosting status
  if (["script_block", "script_headphones", "script_troll", "script_deadline", "script_dangerous"].includes(stagedActionId)) {
    return true;
  }

  //Check for single target item (assume caster want to use on themselves first)
  if (stagedActionId.startsWith("item_")) {
    const actionModel = BattleActions[stagedActionId];
    if (actionModel.targetingType === "single-friendly") {
      return true
    }
  }

  return false;
}