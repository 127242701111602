import {Person} from "./Person";

export function getNextBusinessAsUsualFrame(state, directionArrows) {

  let nextStepId = state.stepId;
  const arrowPersonBehavior = directionArrows.length ? {type: "characterWalk", direction: directionArrows[0]} : null;

  //Assume no hero change this frame
  let heroPositionChange = null;
  let checkSneakChange = null;
  let heroPathBlockedWhenGoingDirection = null;
  let heroPathBlockedWhenGoingXY = '';

  const newInteractives = state.interactives.map(model => {

    //Record hero's current position (so we can diff later)
    const heroPos = model.isArrowControlled ? `${model.x}x${model.y}` : null;
    const sneakGuardNpcPos = model.lineOfSightInteraction ? `${model.x}x${model.y}` : null;
    const sneakGuardNpcDirection = model.lineOfSightInteraction ? model.direction : null;

    const p = model.isArrowControlled
      ? new Person({...model, doThisBehavior: arrowPersonBehavior}, state.interactives, state.walls)
      : new Person(model, state.interactives, state.walls);

    p.betterStep();

    //Diff the hero's position
    if (heroPos && `${p.params.x}x${p.params.y}` !== heroPos) {
      //If this has changed, we'll include the new hero position in the payload
      heroPositionChange = `${p.params.x}x${p.params.y}`;
    }

    //Diff a guard's position and direction
    if (
      (sneakGuardNpcPos && `${p.params.x}x${p.params.y}` !== sneakGuardNpcPos) ||
      (sneakGuardNpcDirection && sneakGuardNpcDirection !== p.params.direction)
    ) {
      //If this has changed, we'll include a check in the payload
      checkSneakChange = true;
    }

    //break visual cache if something changed
    if (p.hasVisualThingChanged) {
      nextStepId ++
    }

    if (heroPos && p.pathBlockedWhenGoingDirection) {
      //console.log('hero path is blocked', p.pathBlockedWhenGoingDirection)
      heroPathBlockedWhenGoingDirection = p.pathBlockedWhenGoingDirection;
      heroPathBlockedWhenGoingXY = `${p.params.x}x${p.params.y}`;
    }

    return p.params;
  });


  return {
    nextStepId,
    nextState: {
      interactives: newInteractives
    },
    heroPositionChange,
    checkSneakChange,
    heroPathBlockedWhenGoingDirection,
    heroPathBlockedWhenGoingXY
  }
}