export function addSlotNumberToBattleCrew(acquiredMembers, newMemberId) {

  let usedSlots = acquiredMembers.filter(m => typeof m.battleCrewSlot === "number").map(m => m.battleCrewSlot).sort((a,b) => a < b ? 1 : -1);
  const lastUsedSlot = usedSlots[0];

  if (lastUsedSlot >= 3) {
    return acquiredMembers;
  }

  return acquiredMembers.map(m => {
    if (m.id === newMemberId) {
      return {
        ...m,
        battleCrewSlot: lastUsedSlot + 1
      }
    }
    return m
  })
}