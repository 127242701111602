import {DefaultAction, DefaultScript} from "../Action";
import {testablePercentChance} from "../../../../helpers/testablePercentChance";
import {getAttackDamage, getStatusRemovingFromDamageSteps} from "../../formulas/getAttackDamage";


function createSliceAction(properties) {
  return {
    ...DefaultAction,
    ...DefaultScript,
    name: properties.name,
    description: properties.description,
    icon: "dps",

    accuracy: 96,

    successEventsPerTarget(casterModel, targetModel, allUpgrades) {
      const lowersDefense = testablePercentChance(50);
      const damage = getAttackDamage(properties.damage, casterModel, targetModel, allUpgrades);

      return [
        {
          type: "eventAnimation",
          animationId: "animationSlice",
          subjects: [targetModel.id],
          delayFramesAfter: 12,
        },
        //Block step
        targetModel.status === "block" ? {
          type: "eventBlippy",
          stateChanges: [
            {
              combatantId: targetModel.id,
              absoluteChanges: {
                status: "normal"
              },
              message: {
                theme: "battleDamageBlock",
                text: `Damage Absorbed!`
              },
            }
          ],
        } : null,

        //Damage step
        {
          type: "eventBlippy",
          stateChanges: [
            {
              combatantId: targetModel.id,
              relativeChanges: {
                hp: damage
              },
              message: {
                theme: "battleNegativeHp",
                text: `${damage} HP`
              },
            }
          ],
          delayFramesAfter: lowersDefense ? 40 : 60
        },

        //Maybe defense lowering
        lowersDefense ? {
          type: "eventBlippy",
          stateChanges: [
            {
              combatantId: targetModel.id,
              relativeChanges: {
                defenseModifier: -1
              },
              message: {
                theme: "battleNegativeHp",
                text: "-Defense"
              }
            }
          ]
        } : null,
        ...getStatusRemovingFromDamageSteps(casterModel, targetModel, allUpgrades)
      ].filter(d => d)
    }
  }
}

export const Slice = createSliceAction({
  name: "Slice",
  description: "Offensive attack, may also decrease target defense",
  damage: 6
});

export const SliceMkII = createSliceAction({
  name: "Slice Mk II",
  description: "Stronger attack which might decrease opponent defense",
  damage: 8
});

export const SliceMkIII = createSliceAction({
  name: "Slice Mk III",
  description: "It hurts... bad...",
  damage: 9
});