import {DefaultAction, DefaultNatural} from "../Action";
import { getAttackDamage, getStatusRemovingFromDamageSteps } from "../../formulas/getAttackDamage";

export default {
  ...DefaultAction,
  ...DefaultNatural,

  getUse(actionModel, casterModel, targetModels) {
    return [
      {
        type: "eventMessage",
        subjects: [casterModel.id],
        theme: "disrupt", //todo: make this a skull or something?
        textArray: [
          "Hacked!!"
        ],
        delayFramesAfter: 30,
      }
    ]
  },

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {
    const damage = getAttackDamage(2, casterModel, targetModel, allUpgrades);
    return [
      {
        type: "eventAnimation",
        animationId: "animationHacked",
        subjects: [targetModel.id],
        delayFramesAfter: 30,
      },
      {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: targetModel.id,
            relativeChanges: {
              hp: damage
            },
            message: {
              theme: "battleNegativeHp",
              text: `${damage} HP`
            },
          }
        ]
      },
      ...getStatusRemovingFromDamageSteps(casterModel, targetModel, allUpgrades)
    ]
  }
}