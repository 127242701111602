import React from 'react'
import {connect} from 'react-redux'
import {waitFrames} from "../helpers/waitFrames";
import {setOverworldValue} from "../redux-actions/overworld-actions";
import {getNextExternalEventTrigger} from "./getIncrExternalEventTrigger";

class OverworldFader extends React.Component {

  state = {
    backgroundColor: "#fff",
    opacity: 0,
    transitionTimeMs: 0,
    transitionEase: "ease"
  };

  componentDidUpdate(oldProps) {
    if (oldProps.overworldFadeType !== this.props.overworldFadeType) {
      const newFadeType = this.props.overworldFadeType;
      waitFrames(1, () => {
        this.setState({
          ...getFadeStyleProperties(newFadeType)
        })
      });
    }
  }

  handleTransitionEnd = () => {

    const { overworldFadeType } = this.props;

    //Unlock the overworld when animation ends
    setOverworldValue({
      externalEventTrigger: getNextExternalEventTrigger(`OVERWORLD-FADER-DONE:${overworldFadeType}`)
    });


    //Maybe kill the mounting of this guy if it was an "out" transition (or something else that leaves the game area)
    if (this.state.opacity === 0 || overworldFadeType === "toBattle") {
      setOverworldValue({
        overworldFadeType: null
      })
    }


  };

  render() {
    const {backgroundColor, opacity, transitionTimeMs, transitionEase} = this.state;

    if (!this.props.overworldFadeType) { return null }

    return (
      <div className="OverworldFader"
      onTransitionEnd={this.handleTransitionEnd}
      style={{
        transition: `opacity ${transitionTimeMs}ms ${transitionEase}`,
        background: backgroundColor,
        opacity,
        position: "fixed",
        left:0,
        right:0,
        top:0,
        bottom:0,
        pointerEvents: "none",
      }}/>
    );
  }
}


function getFadeStyleProperties(fadeTypeId) {
  if (fadeTypeId === "toWhite") {
    return {
      transitionTimeMs: 1800,
      backgroundColor: "#fff",
      opacity: 1,
      transitionEase: "ease" //ease
    }
  }
  if (fadeTypeId === "fromWhite") {
    return {
      transitionTimeMs: 1700,
      backgroundColor: "#fff",
      opacity: 0,
      transitionEase: "ease" //ease
    }
  }

  if (fadeTypeId === "toTeleport") {
    return {
      transitionTimeMs: 140,
      backgroundColor: "#F1AFFF",
      opacity: 1,
      transitionEase: "steps(3)"
    }
  }
  if (fadeTypeId === "fromTeleport") {
    return {
      transitionTimeMs: 140,
      backgroundColor: "#AFB6FF",
      opacity: 0,
      transitionEase: "steps(3)"
    }
  }

  if (fadeTypeId === "toBattle") {
    return {
      transitionTimeMs: 300,
      //backgroundColor: "#ff5b25",
      backgroundColor: "#fff",
      opacity: 1,
      transitionEase: "steps(3)"
    }
  }

  if (fadeTypeId === "fastToWhite") {
    return {
      transitionTimeMs: 600,
      backgroundColor: "#fff",
      opacity: 1,
      transitionEase: "ease" //ease
    }
  }
  if (fadeTypeId === "fastFromWhite") {
    return {
      transitionTimeMs: 700,
      backgroundColor: "#fff",
      opacity: 0,
      transitionEase: "ease" //ease
    }
  }
  if (fadeTypeId === "mapTransitionToWhite") {
    return {
      transitionTimeMs: 400,
      backgroundColor: "#fff",
      opacity: 1,
      transitionEase: "ease"
    }
  }
  if (fadeTypeId === "mapTransitionFromWhite") {
    return {
      transitionTimeMs: 400,
      backgroundColor: "#fff",
      opacity: 0,
      transitionEase: "ease"
    }
  }
  if (fadeTypeId === "twoStepBlack") {
    return {
      transitionTimeMs: 200,
      backgroundColor: "#000",
      opacity: 1,
      transitionEase: "steps(2)"
    }
  }
  return {}
}

export default connect((state, props) => {
  return {
    overworldFadeType: state.overworld.overworldFadeType
  }
})(OverworldFader)