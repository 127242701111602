export function getPauseJournalMenu({ acquiredStoryPoints, journalStories }) {
  let menu = {
    back: {
      handler() {
        return { type: "GO_BACK"}
      }
    }
  };
  let map = [
    []
  ];

  let journals = Object.keys(journalStories).map(key => {
    const journal = journalStories[key];
    const {entries} = journal;
    const seenEntries = entries.filter(entry => {
      return entry.requiresStoryPoints.every(sp => acquiredStoryPoints.includes(sp));
    });
    const latestEntry = seenEntries.length > 0 ? seenEntries[seenEntries.length-1] : null;
    const isComplete = latestEntry ? latestEntry.entryId === entries[entries.length-1].entryId : false;
    return {
      id: key,
      name: journal.name,
      isComplete,
      latestEntry,
    }
  }).filter(e => e.latestEntry);

  journals.sort((j,j2) => {
    return j.isComplete ? 1 : -1
  }).forEach(j => {
    menu[j.id] = {
      label: j.name,
      isComplete: j.isComplete,
      description: j.latestEntry.entryDescription,
      handler() {}
    };
    map[0].push(j.id)
  });

  return {
    menu, map, backOptionId: "back"
  }
}