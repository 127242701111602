import React from 'react'
import {verticalDpadArrow, horizontalDpadArrow} from './dpad-icons'
import './NewMobileDirectionPad.css'

class NewMobileDirectionPad extends React.Component {

  state = {
    isTouching: false,
    lastDirectionTouched: null,
    moduleX: 100, //where the Dpad shows up relative to the page top/left
    moduleY: 100, //where the Dpad shows up relative to the page top/left

    touchX: 0,
    touchY: 0,
  };

  componentDidMount() {
    let el = document.getElementsByTagName("body")[0];
    const $hbUp = document.querySelector('.hb-up');
    const $hbDown = document.querySelector('.hb-down');
    const $hbLeft = document.querySelector('.hb-left');
    const $hbRight = document.querySelector('.hb-right');

    this.touchStartHandler = (e) => {

      //console.log(e.target)
      if (e.target.classList) {
        if (e.target.classList.contains("Dpad--NoShow") || e.target.classList.contains("Letter")) {
          //The thing you clicked on has the class, so don't show
          return
        }
      }


      const touchPointX = (e.touches) ? e.touches[0].pageX : e.pageX;
      const touchPointY = (e.touches) ? e.touches[0].pageY : e.pageY;
      this.setState({
        isTouching: true,
        moduleX: touchPointX,
        moduleY: touchPointY,
        touchX: touchPointX,
        touchY: touchPointY,
        lastDirectionTouched: null
      })
    };


    this.touchMoveHandler = (e) => {
      let touchPointX = (e.touches) ? e.touches[0].pageX : e.pageX;
      let touchPointY = (e.touches) ? e.touches[0].pageY : e.pageY;

      touchPointX = Math.round(touchPointX);
      touchPointY = Math.round(touchPointY);


      const upCoords = $hbUp.getBoundingClientRect();
      const downCoords = $hbDown.getBoundingClientRect();
      const leftCoords = $hbLeft.getBoundingClientRect();
      const rightCoords = $hbRight.getBoundingClientRect();


      if (isInBox(upCoords.top, upCoords.bottom, upCoords.left, upCoords.right, touchPointX, touchPointY)) {
        this.setState({ lastDirectionTouched: "UP" });
        return;
      }
      if (isInBox(downCoords.top, downCoords.bottom, downCoords.left, downCoords.right, touchPointX, touchPointY)) {
        this.setState({ lastDirectionTouched: "DOWN" });
        return;
      }
      if (isInBox(leftCoords.top, leftCoords.bottom, leftCoords.left, leftCoords.right, touchPointX, touchPointY)) {
        this.setState({ lastDirectionTouched: "LEFT" });
        return;
      }
      if (isInBox(rightCoords.top, rightCoords.bottom, rightCoords.left, rightCoords.right, touchPointX, touchPointY)) {
        this.setState({ lastDirectionTouched: "RIGHT" });
      }
    };


    this.touchEndHandler = (e) => {
      this.setState({
        isTouching: false,
        lastDirectionTouched: null
      })
    };


    ["mousedown","touchstart"].forEach(eventType => {
      el.addEventListener(eventType, this.touchStartHandler);
    });

    ["touchmove"].forEach(eventType => {
      el.addEventListener(eventType, this.touchMoveHandler);
    });

    ["mouseup","touchend"].forEach(eventType => {
      el.addEventListener(eventType, this.touchEndHandler);
    });
  }

  componentWillUnmount() {
    let el = document.getElementsByTagName("body")[0];
    ["mousedown","touchstart"].forEach(eventType => {
      el.removeEventListener(eventType, this.touchStartHandler);
    });
    ["touchmove"].forEach(eventType => {
      el.removeEventListener(eventType, this.touchMoveHandler);
    });
    ["mouseup","touchend"].forEach(eventType => {
      el.removeEventListener(eventType, this.touchEndHandler);
    });
  }

  componentDidUpdate(oldProps, oldState) {
    const {isTouching, lastDirectionTouched} = oldState;

    if (isTouching && !this.state.isTouching) {
      this.props.handleNewArrows([]);
      return
    }
    if (this.state.isTouching && lastDirectionTouched !== this.state.lastDirectionTouched) {
      //console.log('new!', this.state.lastDirectionTouched);

      if (!this.state.lastDirectionTouched) {
        this.props.handleNewArrows([]);
        return
      }

      this.props.handleNewArrows([this.state.lastDirectionTouched]);
    }
  }



  handleTouchMove = (direction) => {
    //console.log(direction)
    this.setState({
      lastDirectionTouched: direction
    })
  };


  render() {

    const {isTouching, moduleX, moduleY, lastDirectionTouched} = this.state;

    const dpadStyle = {
      left: moduleX - 70,
      top: moduleY - 70,
      opacity: isTouching ? 1: 0
    };

    const activeClass = isTouching ? "dpad-active" : "";
    const activeUpClass = isTouching && lastDirectionTouched === "UP" ? "hb-active" : "";
    const activeDownClass = isTouching && lastDirectionTouched === "DOWN" ? "hb-active" : "";
    const activeLeftClass = isTouching && lastDirectionTouched === "LEFT" ? "hb-active" : "";
    const activeRightClass = isTouching && lastDirectionTouched === "RIGHT" ? "hb-active" : "";

    return (
      <div
        style={dpadStyle}
        className={`mobile-dpad ${activeClass}`}
      >
        {/*<div style={indicatorStyle} />*/}
        <div className="circle js-dpad-circle" />

        <div
          className={`hitbox hb-up ${activeUpClass}`}
          onMouseEnter={() => this.handleTouchMove("UP")}
          onMouseOver={() => this.handleTouchMove("UP")}
          onMouseMove={() => this.handleTouchMove("UP")}
        >
          {verticalDpadArrow(33, "")}
        </div>
        <div className={`hitbox hb-down ${activeDownClass}`}
             onMouseEnter={() => this.handleTouchMove("DOWN")}
             onMouseOver={() => this.handleTouchMove("DOWN")}
             onMouseMove={() => this.handleTouchMove("DOWN")}
        >
          {verticalDpadArrow(33, "")}
        </div>
        <div
          className={`hitbox hb-left ${activeLeftClass}`}
          onMouseEnter={() => this.handleTouchMove("LEFT")}
          onMouseOver={() => this.handleTouchMove("LEFT")}
          onMouseMove={() => this.handleTouchMove("LEFT")}
        >
          {horizontalDpadArrow(13*3, "")}
        </div>
        <div
          className={`hitbox hb-right ${activeRightClass}`}
          onMouseEnter={() => this.handleTouchMove("RIGHT")}
          onMouseOver={() => this.handleTouchMove("RIGHT")}
          onMouseMove={() => this.handleTouchMove("RIGHT")}
        >
          {horizontalDpadArrow(13*3, "")}
        </div>
      </div>
    );
  }
}

export default NewMobileDirectionPad;

function isInBox(topEdge, bottomEdge, leftEdge, rightEdge, userX, userY) {
  const isValidHorizontal = (userX >= leftEdge && userX <= rightEdge);
  const isValidVertical = (userY >= topEdge && userY <= bottomEdge);
  return isValidHorizontal && isValidVertical;
}