//NOTE: This is raw from the editor. Do we want to change it? Be thoughtful.
export default [
  {
    "id": "cutscene_001",
    "type": "cutscene", //We only really need this to visualize, right? like battle spaces?
    "x": "2",
    "y": "4",
    "bypassOnStoryPoints": [],
    "requiresStoryPoints": [],
    "interaction": [
      // {
      //   "direction": "UP",
      //   "subjectId": "hero",
      //   "type": "characterWalk"
      // },
      // {
      //   "direction": "UP",
      //   "subjectId": "hero",
      //   "type": "characterWalk"
      // },
      // {
      //   "subjectTurnsToFacePlayer": "no",
      //   "textArrays": [
      //     [
      //       "** Don't forget your laptop! You need it for work. **"
      //     ]
      //   ],
      //   "type": "textMessage"
      // },
      // {
      //   "direction": "DOWN",
      //   "subjectId": "hero",
      //   "type": "characterWalk"
      // }
    ],
  },

  {
    "id": "battle_000",
    "type": "battle",
    "x": "2",
    "y": "2",
    "bypassOnStoryPoints": [],
    "requiresStoryPoints": [
      "boobear"
    ],
    "interaction": [
    ],
  },

  {
    "id": "battle_001",
    "type": "battle", //We only really need this to visualize, right? like battle spaces?
    "x": "1",
    "y": "3",
    "bypassOnStoryPoints": ["bananas"],
    "requiresStoryPoints": [
    ],
    "interaction": [

      // {
      //   type: "changeMap",
      //   mapId: "[dynamic-current-map]",
      //   playerX: 4,
      //   playerY: 4,
      //   playerDirection: "UP"
      // },

      {
        type: "showLoginReminder"
      }

      // {
      //   type: "goToBattle",
      //   stepIntoBattleSpace: true, //all battle spaces should have this
      //   //...battle data here?
      //   battleId: "dev-2",
      //   addStoryPointOnWin: "bananas",
      // }

      // {
      //   type: "changeInteractiveProperty",
      //   personId: "hero",
      //   changes: {
      //     skinId: "bear"
      //     // x: 10,
      //     // y:10
      //   }
      // },

      // {
      //   type: "addStoryPoint",
      //   addStoryPoint: "boobear"
      // },

      // {
      //   type: "changeChapter",
      //   chapterId: "chapterDev2"
      // }


      // {
      //   "subjectTurnsToFacePlayer": "no",
      //   "textArrays": [
      //     [
      //       "** Don't forget your laptop! You need it for work. **"
      //     ]
      //   ],
      //   "type": "textMessage"
      // },
      // {
      //   "direction": "DOWN",
      //   "subjectId": "hero",
      //   "type": "characterWalk"
      // }
    ],
  },
  {
    "id": "transition_001",
    "type": "transition",
    "x": "0",
    "y": "-1",
    transitionDirection: "LEFT",
    "bypassOnStoryPoints": [],
    "requiresStoryPoints": [],
    "interaction": [
      {
        type: "changeMap",
        mapId: "testMap",
        playerX: 4,
        playerY: 4,
        playerDirection: "UP"
      },
    ]
  }
]



//WILL NEED A BRIDGE FROM EDITOR... create one list of all event types. (add id to each node, too)
//We can either save the map this way (which is less footprint?) or run the conversion when loading a map.


//NOTE: This is raw from the editor:
/*
export default {
  sampleCutscene: {
    "bypassOnStoryPoints": [
      "SP_JRQ_GotApartmentLaptop"
    ],
    "requiresStoryPoints": [],
    "interaction": [
      {
        "subjectTurnsToFacePlayer": "no",
        "textArrays": [
          [
            "** Don't forget your laptop! You need it for work. **"
          ]
        ],
        "type": "textMessage"
      },
      {
        "direction": "UP",
        "subjectId": "hero",
        "type": "characterWalk"
      }
    ],
    "x": "3",
    "y": "6"
  }
}
 */