import React from 'react'
import NewKeyboardArrowController from './NewKeyboardArrowController'
import NewMobileDirectionPad from './NewMobileDirectionPad'
import ActionAndPauseKeyHandlers from './ActionAndPauseKeyHandlers'

/* NOTE TO FUTURE SELF: "HOW DO I USE THIS?"
Oh man, it's so easy!
1) Both KeyboardArrowController and MobileDirectionPad set the `arrows` state here. They call up to the parent with updated array.
2) You *could* use this component to container the overworld and pass down the `arrows` as a render prop. I dunno what the context looks like yet.
 */


class KeyboardArrowControllerContainer extends React.Component {

  constructor() {
    super();
    this.arrows = [];
    this.state = {
      arrows: [],

      actionKeyFireCache: 0,
      pauseKeyFireCache: 0,
    }
  }

  handleNewArrows = newArrows => {
    this.setState({arrows: newArrows})
  };

  handleNewActionKeyPress = () => {
    this.setState(state => {
      return {
        actionKeyFireCache: state.actionKeyFireCache + 1
      }
    })
  };

  handleNewPauseKeyPress = () => {
    this.setState(state => {
      return {
        pauseKeyFireCache: state.pauseKeyFireCache + 1
      }
    })
  };

  render() {
    const {arrows, actionKeyFireCache, pauseKeyFireCache} = this.state;
    return (
      <React.Fragment>
        <NewKeyboardArrowController
          arrows={arrows}
          handleNewArrows={this.handleNewArrows}
        />
        <ActionAndPauseKeyHandlers
          handleNewActionKeyPress={this.handleNewActionKeyPress}
          handleNewPauseKeyPress={this.handleNewPauseKeyPress}
        />
        {
          this.props.render({
            directionArrows: arrows,
            actionKeyFireCache,
            pauseKeyFireCache
          })
        }
        <NewMobileDirectionPad
          handleNewArrows={this.handleNewArrows}
        />
        )
      </React.Fragment>
    );
  }
}

export default KeyboardArrowControllerContainer;