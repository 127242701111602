export function getUpdatedPlayerDataAfterBattle({ resultType, endingCombatants, addStoryPointOnWin, playerData, teamOneItemsUsed=[], endingTeamOneItems=[] }) {

  let payload = {...playerData};

  //console.log(endingCombatants, teamOneItemsUsed, playerData)

  //Add story points
  if (resultType === "win" && addStoryPointOnWin) {
    if (!payload.acquiredStoryPoints.includes(addStoryPointOnWin)) {
      payload.acquiredStoryPoints.push(addStoryPointOnWin);
    }
  }

  //Persist HP
  if (payload.acquiredMembers) {
    payload.acquiredMembers = payload.acquiredMembers.map(m => {
      const battleCombatant = endingCombatants.find(c => c.id === m.id && c.belongsToTeam === "one");
      if (battleCombatant) {
        let memberPayload = {...m};
        if (resultType === "win") {
          memberPayload.hp = battleCombatant.hp
        }
        if (battleCombatant.activeUpgradeId) {
          memberPayload.activeUpgradeId = battleCombatant.activeUpgradeId
        }
        return memberPayload
      }
      return m
    });
  }

  //Persist items used
  if (resultType === "win") {
    payload.inventory = (payload.inventory || []).filter(i => {
      //Remove any used items
      return !teamOneItemsUsed.includes(i.instanceId)
    });
    //Add in any extra items we have now (as long as we didn't already have this instance)
    endingTeamOneItems.forEach(i => {
      if (!payload.inventory.find(item => item.instanceId === i.instanceId)) {
        payload.inventory.push(i)
      }
    });
  }


  return payload;
}