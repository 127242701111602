export function getInteractionFromHeroIntersection(interactives=[], walls=[]) {

  const hero = interactives.find(i => i.id === "hero");
  const heroPos = hero ? `${hero.x}x${hero.y}` : "-99x-99";
  const guards = interactives.filter(i => i.id !== "hero" && i.lineOfSightInteraction && i.lineOfSightInteraction.length);

  const match = guards.find(guard => {
    let isMatch = false;
    const lineOfSight = [1,2,3,4].map(n => {
      if (guard.direction === "LEFT") { return `${guard.x - n}x${guard.y}`}
      if (guard.direction === "RIGHT") { return `${guard.x + n}x${guard.y}`}
      if (guard.direction === "UP") { return `${guard.x}x${guard.y-n}`}
      //assume down
      return `${guard.x}x${guard.y+n}`
    });

    //console.log(lineOfSight)
    let isBlockedByWall = false;

    lineOfSight.forEach(pos => {

      if (walls.indexOf(pos) > -1) {
        isBlockedByWall = true;
      }

      if (!isBlockedByWall && !isMatch && heroPos === pos) {
        isMatch = true;
      }
    });

    return isMatch;
  });

  return match ? match.lineOfSightInteraction : null
}