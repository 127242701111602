import React from 'react'
import {waitFrames} from "../../../helpers/waitFrames";
import RunAnimation from './RunAnimation'
import {audioManager} from "../../../audio/audioManager";


class AnimationPush extends React.Component {

  state = {
    hasTriggeredAnimation: false
  };

  componentDidMount() {

    const targets = this.props.event.subjects;

    waitFrames(1, () => {
      this.setState({
        hasTriggeredAnimation: true
      }, () => {

        //Start shaking
        audioManager.playSfx("sfx_iteratePain");
        targets.forEach(id => {
          this.props.handleCombatantStateChange([
            {
              combatantId: id,
              absoluteChanges: {
                laptopFlash: "pain",
                animation: "characterShake",
              }
            }
          ])
        });
        //Stop shaking
        waitFrames(20, () => {
          targets.forEach(id => {
            this.props.handleCombatantStateChange([
              {
                combatantId: id,
                absoluteChanges: {
                  laptopFlash: "none",
                  animation: null,
                }
              }
            ])
          });
        });
      });
    });
  }

  render() {
    const {pixelSize, event, positions} = this.props;
    return event.subjects.map((subjectId, i) => {
      return (
        <RunAnimation
          key={subjectId}
          pixelSize={pixelSize}
          left={positions[subjectId].leftPosition}
          top={positions[subjectId].topPosition}
          widthOfSpriteSheet={160}
          frames={5}
          duration="0.3s"
          onDone={() => { waitFrames(10, () => {this.props.onEventDone()}) }}
          renderPaths={() => (
            <React.Fragment>
              <path stroke="#fff"
                    d="M0 5h32M0 6h1m30 0h1M0 7h1m30 0h1M0 8h1m30 0h1M0 9h1m30 0h1m2 0h28M0 10h1m30 0h1m2 0h1m26 0h1M0 11h1m30 0h1m2 0h1m26 0h1M0 12h1m30 0h1m2 0h1m26 0h1M0 13h1m30 0h1m2 0h1m26 0h1m80 0h4M0 14h1m30 0h1m2 0h1m26 0h1m47 0h6m27 0h4M0 15h1m30 0h1m2 0h1m26 0h1m15 0h6m26 0h6M0 16h1m30 0h1m2 0h1m26 0h1m15 0h6M0 17h1m30 0h1m2 0h1m26 0h1M0 18h1m30 0h1m2 0h1m26 0h1M0 19h1m30 0h1m2 0h1m26 0h1M0 20h1m30 0h1m2 0h1m26 0h1M0 21h1m30 0h1m2 0h1m26 0h1M0 22h1m30 0h1m2 0h28M0 23h1m30 0h1M0 24h32"/>
              <path stroke="#4da9ea"
                    d="M66 9h28m-28 1h28m-28 1h28m-28 1h28m-28 1h28m-28 1h28m-28 1h11m6 0h11m-28 1h11m6 0h11m-28 1h8m12 0h8m-28 1h5m18 0h5m-28 1h2m24 0h2"/>
              <path stroke="#c9f468" d="M108 16h3m2 0h3m-10 1h5m2 0h5m-15 1h7m4 0h6m-18 1h7m6 0h6"/>
              <path stroke="#8edc56" d="M111 16h2m-2 1h2m-3 1h4m-5 1h6"/>
              <path stroke="#3173d7" d="M74 17h12m-15 1h18m-21 1h24"/>
            </React.Fragment>
          )}
        />
      )
    })
  }
}

export default AnimationPush;