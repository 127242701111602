import {shuffle} from "../../../helpers/shuffle";

export function getNormalAttackDecision({casterModel, enemies}) {

  const myAttackIds = casterModel.attacks.map(a => a.actionId);
  let myRegularAttacks = [
    "script_slice",
    "script_slice-mk-ii",
    "script_slice-mk-iii",
    "script_push",
    "script_pop",
    "script_blackberry"
  ].filter(key => {
    return myAttackIds.includes(key);
  });
  if (!myRegularAttacks.length) { return null }

  const aliveEnemies = enemies.filter(e => {
    return e.hp > 0
  });

  if (aliveEnemies.length === 0) { return null }

  return {
    actionId: shuffle(myRegularAttacks)[0],
    casterId: casterModel.id,
    targetIds: [ shuffle(aliveEnemies)[0].id ],
  }
}