import React from 'react'
import {battlerOptions} from './defaultBattlerProperties'
import BattlerItemPopup from './BattlerItemPopup'

class BattlerForm extends React.Component {

  state = {
    isShowingItemsForm: false
  };

  handleChange = (property, newValue) => {
    this.props.handleChangeBattler(this.props.battlerId, property, newValue)
  };


  renderDropdown(label, property) {
    const {battlerValues} = this.props;
    const values = battlerOptions[property];
    return (
      <div style={{padding: 5}}>
        <label htmlFor="name" style={{fontSize: 10, display: "block"}}>{label}</label>
        <select
          value={battlerValues[property]}
          onChange={(e) => {
            this.handleChange(property, e.target.value)
          }}
        >
          {
            values.map((v, i) => {
              return (
                <option key={i} value={v}>{v}</option>
              )
            })
          }
        </select>
      </div>
    )
  }

  renderPersonalitySlider(key) {
    const {battlerValues} = this.props;
    const computedPropertyName = `personalityKey:${key}`;


    const value = battlerValues[computedPropertyName] || 0;

    return (
      <div key={key}>
        <label style={{width: 120, display: "inline-block"}}>{key}</label>
        <input type="range" min={0} max={4}
               value={value}
               onChange={e => {
                 this.handleChange(computedPropertyName, e.target.value)
               }}
        />
        <span>{value}</span>
      </div>
    )
  }

  render() {
    const {battlerValues} = this.props;

    if (!battlerValues) {
      return <div>Battler not found</div>
    }


    if (this.state.isShowingItemsForm) {
      return (
        <BattlerItemPopup
          items={battlerValues.items || []}
          handleNewList={newList => {
            this.handleChange("items", newList)
          }}
          handleCloseItemEditor={() => {
            this.setState({
              isShowingItemsForm: false
            })
          }}
        />
      )
    }

    return (
      <div style={{
        padding: "1em",
        display: "flex",
        border: "1px solid #333"
      }}>
        <aside style={{marginRight: 10}}>
          <div style={{paddingRight: 10}}>
            <label style={{fontSize: 10, display: "block"}} htmlFor="name">Name</label>
            <input
              id="name"
              type="text"
              value={battlerValues.name}
              onChange={(e) => this.handleChange("name", e.target.value) }
              style={{
                display: "block",
                fontSize: 22,
                outline : 0,
                border: 0,
                borderBottom: "1px solid #888",
                fontFamily: "inherit",
                background: "rgba(255,255,255,0)",
                width: 200
              }}
            />
          </div>
          { this.renderDropdown("Skin", "skinId") }
          { this.renderDropdown("Class", "class") }
          { this.renderDropdown("Level", "level") }
          { this.renderDropdown("Start Hp", "startHp") }
          { this.renderDropdown("Danger Meter", "startingDangerMeter") }
          { this.renderDropdown("Status", "status") }
          { this.renderDropdown("Accuracy Modifier", "accuracyModifier") }
        </aside>
        <aside>
          { this.renderDropdown("Attack 1", "attack1") }
          { this.renderDropdown("Attack 2", "attack2") }
          { this.renderDropdown("Attack 3", "attack3") }
          { this.renderDropdown("Attack 4", "attack4") }
          { this.renderDropdown("Super", "attackSuper") }
        </aside>
        <aside style={{paddingLeft:10}}>
          <div>
            <button onClick={() => this.setState({isShowingItemsForm: true})}>
              EDIT ITEMS ({(battlerValues.items || []).length})
            </button>
          </div>
          <label
            style={{
              fontSize: 10,
              display: "block"
            }}
          >
            Personality
          </label>
          {
            ["mischief", "vicious", "cursing", "statBoost", "positiveStatus", "healStatus", "healHp", "lowerStats", "dangerBoost"].map(key => {
              return this.renderPersonalitySlider(key)
            })
          }
        </aside>
      </div>
    );
  }
}

//"mischief", "vicious", "cursing", "statBoost", "positiveStatus", "healStatus", "healHp", "lowerStats", "dangerBoost"

export default BattlerForm;