import React from 'react';

export default function (props, state) {

  const {pixelSize, totalWidth, fillPercent} = props;
  const height = 6;

  const isFull = fillPercent >= 1;

  //Baking these "XP" colors for now, but this could get abstracted to a them. Just not sure if we'll need it yet.
  let topFillColor = isFull ? "#d5b20e" : "#3ec0db";
  let bottomFillColor = isFull ? "#ffda2f" : "#40d4f3";

  return (
    <div style={{
      width: totalWidth * pixelSize,
      height: height * pixelSize,
      background: "#08152d",
      position: "relative"
    }}>
      <div style={{
        position: "absolute",
        borderTop: `${pixelSize}px solid #49515e`,
        background: "#697180",
        left: pixelSize,
        right: pixelSize,
        bottom: pixelSize,
        top: pixelSize,
      }}>
        {/* Fill */}
        <div style={{
          position: "absolute",
          borderTop: `${pixelSize}px solid ${topFillColor}`,
          background: bottomFillColor,
          left: 0,
          bottom: 0,
          top: -pixelSize,
          width: !isFull ? String(fillPercent * 100)+"%" : "100%", //Do not let it go full
        }}/>
      </div>
    </div>
  )
}