import Upgrades from "./battle-data/Upgrades";

export function casterAffectsIterationCount({initialValue, min, max, casterModel={}, allUpgrades}) {

  const upgrades = allUpgrades || Upgrades;

  if (casterModel.activeUpgradeId) {
    const upgrade = upgrades[casterModel.activeUpgradeId];
    if (upgrade && upgrade.maximizeIterationCount) {
      if (initialValue === min) {
        return {
          value: max,
          changeCode: "maximized"
        }
      }
    }
  }
  return {
    value: initialValue
  }
}


export function targetAffectsIterationCount({initialValue, min, max, targetModel={}, allUpgrades}) {

  const upgrades = allUpgrades || Upgrades;

  if (targetModel.activeUpgradeId) {
    const upgrade = upgrades[targetModel.activeUpgradeId];
    if (upgrade && upgrade.minimizeIterationCount) {
      return {
        value: 1, //this is intentional hardcoded 1. actual minimum of 2 doesn't feel like a strong enough effect
        changeCode: "minimized"
      }
    }
  }

  return {
    value: initialValue
  }
}