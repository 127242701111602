import {DefaultAction, DefaultScript} from "../Action";
import {getCombatantHpRaise} from "../../formulas/getCombatantHpRaise";
import {getDangerMeterReset} from "../../formulas/getDangerMeterReset";

export default {
  ...DefaultAction,
  ...DefaultScript,

  name: "Team Revive",
  description: "Revive all dead friendlies on your team",
  icon:"super-support",
  targetingType: "all-friendlies",
  targetsMustBeDead: true,

  shouldFailOnSingleTarget(casterModel, targetModel) {
    if (targetModel.hp > 0) {
      return {
        failCode: "not-dead"
      }
    }
    return null;
  },

  successEventsPerAllTargets({casterModel, targetModels, allUpgrades}) {
    return [
      {
        type: "eventBlippy",
        stateChanges: targetModels.map(targetModel => {
          const hpChange = getCombatantHpRaise(targetModel, 75, "percent");
          return [
            {
              combatantId: targetModel.id,
              absoluteChanges: {
                dangerMeter: 0, //lose all DM progress
                hp: 0
              } //set back to 0 before we revive
            },
            {
            combatantId: targetModel.id,
            relativeChanges: {
              hp: hpChange
            },
            message: {
              theme: "battlePositiveHp",
              text: `+${hpChange}HP`
            },
          }
          ]
        }).reduce((a,b) => [...a, ...b])
      },
      getDangerMeterReset({casterModel})
    ]
  }
}