import React from 'react'
import {getNextBusinessAsUsualFrame} from './getNextOverworldBusinessFrame'
import {getNextCutsceneFrame} from './getNextCutsceneFrame'
//import MapImage from './MapImage.js'
import MapCanvas from './MapCanvas'
import Person from './MapPerson'
import {
  getMapImageTransformValue, getPeopleRelativeToMapTransformValue,
  writeTransformValueToString
} from './getCameraTransformValue'
import './character-animations.css'
import {handleExternalEvent} from "./handleExternalEvent";
import {getEventFromTriggerSpaces} from "./trigger-spaces/getEventFromTriggerSpaces";
import BattleSpace from './BattleSpace'
import TransitionSpace from './TransitionSpace'
import ExplodingBattleSpace from './ExplodingBattleSpace'
import {getActionFromInteractives} from "./action-handler/getActionFromInteractives";
import {setOverworldValue} from "../redux-actions/overworld-actions";
import {getNextExternalEventTrigger} from "./getIncrExternalEventTrigger";
import {getCurrentMapData} from "./getCurrentMapData";
import {hotLoadScenarioOnInteractives} from './hotLoadScenarioOnInteractives'
import {areStoryPointsValid} from "../story-points/story-point-validity-checks";
import {injectDynamicValuesOnInteractionArray} from './injectDynamicValuesOnInteractionArray'
import {getInteractionFromHeroIntersection} from "./sneaking/getInteractionFromHeroIntersection";
import {getInteractionsForBattleResultsFromRedux} from './getInteractionsForBattleResults'
import {audioManager} from "../audio/audioManager";
import OverworldCrewHud from "./hud/OverworldCrewHud";
import NpcArrow from './NpcArrow'

class Overworld extends React.Component {
  constructor(props) {
    super();
    this.stepId = 0;

    this.walls = props.mapData.walls || [];

    this.triggerSpaces = props.mapData.triggers || []; //"all of them"
    this.updateTriggerSpaces(props.acquiredStoryPoints); //set the `rendered` trigger spaces

    //Maybe add exploding space at hero position
    const hero = (props.mapData.interactives || []).find(p => p.id === "hero");

    this.explodingTriggerSpaces = hero && props.mapData.addExplodingSpaceAtHeroPosition ? [
        {x: hero.x, y: hero.y}
      ] : [];

    this.interactiveActions = props.mapData.interactiveActions || {};

    this.checkSneakingChanges = false;

    this.state = {
      interactives: props.mapData.interactives || []
    };
  }


  componentDidMount() {

    //Kick off the map's song!
    audioManager.playSong(this.props.mapData.mapSong);

    //Figure out if we have some beginning things to do
    //Check if we start on a cutscene space
    const {x, y} = this.getHero();
    const heroPosition = `${x}x${y}`;
    const triggerSpaceEvents = getEventFromTriggerSpaces(heroPosition, this.props.acquiredStoryPoints, this.triggerSpaces, "cutscene") || [];
    const battleResultSteps = getInteractionsForBattleResultsFromRedux() || [];
    const interaction = [
      ...battleResultSteps, //Add in any Battle Result steps if we're returning from battle
      ...triggerSpaceEvents, //Include any cutscene steps from starting the map
    ];

    if (interaction.length > 0) {
      this.props.handleProvideNewCutsceneEvents([...this.props.globalCutsceneEvents, ...interaction])
        //Sometimes we changeMap and still have some events left to do (overworld fade out, etc),
        // so here we tack on the incoming new events at the END of the array to not blow away the unfinished ones.
    } else {
      this.setSneakingCheck();
      this.beginLoop();
    }
  }

  componentDidUpdate(oldProps) {

    if (oldProps.globalCutsceneEvents.length !== this.props.globalCutsceneEvents.length) {
      this.beginLoop()
    }

    if (oldProps.actionKeyFireCache !== this.props.actionKeyFireCache) {
      this.fireActionButton();
    }

    if (oldProps.pauseKeyFireCache !== this.props.pauseKeyFireCache) {
      this.firePauseButton();
    }

    if (!oldProps.refreshPeopleList && this.props.refreshPeopleList) {
      this.refreshPeople();
    }

    if (oldProps.storyPointTrigger !== this.props.storyPointTrigger) {
      this.updateTriggerSpaces(this.props.acquiredStoryPoints);
      this.refreshPeopleScenariosAfterNewStoryPoint()
    }

    //TODO: not sure if this needs to stay here. Only have it here because we removed the animation hooks for blurryness
    //This could probably just happen one level up? or we need to work on a new way to transition
    if (oldProps.mapTransition !== this.props.mapTransition) {
      this.props.handleMapTransitionOut();
    }


  }

  //Update a dedicated render array that does the expensive checking. This way it won't happen on every render! :D
  updateTriggerSpaces = (acquiredStoryPoints) => {
    //console.log('updating render trigger spaces')
    this.renderTriggerSpaces = this.triggerSpaces.filter(t => {
      return areStoryPointsValid(acquiredStoryPoints, t.bypassOnStoryPoints, t.requiresStoryPoints);
    });
  };

  fireActionButton = () => {
    //Click/Touch should also be routed here (just break the cache like Desktop)
    if (this.props.globalCutsceneEvents.length === 0) { //dont allow when we are working on cutscene events

      let interactionArray = getActionFromInteractives(this.state.interactives, this.interactiveActions, this.props.acquiredStoryPoints);
      if (interactionArray) {

        interactionArray = injectDynamicValuesOnInteractionArray(interactionArray, this.state.interactives);

        //console.log('action!!', interactionArray);
        cancelAnimationFrame(this.rAF); //kill any loop that might want to fire
        this.props.handleProvideNewCutsceneEvents(interactionArray);
      }
    }
  };

  firePauseButton = () => {
    if (this.props.globalCutsceneEvents.length === 0) { //dont allow when we are working on cutscene events


      const heroBehavior = this.getHero().currentActionType || "characterStand";
      if (heroBehavior !== "characterStand") {
        return null
      }
      cancelAnimationFrame(this.rAF); //kill any loop that might want to fire

      audioManager.playSfx("sfx_nextPage"); //SFX for opening the menu

      this.props.handleProvideNewCutsceneEvents([{type: "showPauseMenu"}]);
    }
  };


  getHero = () => {
    return this.state.interactives.find(p => p.id === "hero");
  };

  beginLoop() {

    const businessAsUsualStep = () => {
      //Get the next frame
      const {nextStepId, nextState, heroPositionChange, checkSneakChange, heroPathBlockedWhenGoingDirection, heroPathBlockedWhenGoingXY} = getNextBusinessAsUsualFrame({
        //Pass in the current state:
        stepId: this.stepId,
        interactives: this.state.interactives,
        walls: this.walls,

        checkSneakingChanges: this.checkSneakingChanges,

      }, this.props.directionArrows);


      //Recheck if we're on a transition space to allow "going back" to previous map
      if (heroPathBlockedWhenGoingDirection) {
        //console.log('heroPathBlockedWhenGoingDirection', heroPathBlockedWhenGoingDirection, heroPathBlockedWhenGoingXY)
        const interaction = getEventFromTriggerSpaces(heroPathBlockedWhenGoingXY, this.props.acquiredStoryPoints, this.triggerSpaces);
        if (interaction && interaction[0] && interaction[0].type === "changeMap" && interaction[0].playerDirection) {
          if ( interaction[0].playerDirection.toUpperCase() === heroPathBlockedWhenGoingDirection.toUpperCase() ) {
            this.props.handleProvideNewCutsceneEvents(interaction) //retrigger the transition space's event
          }
        }
      }

      //Check if we walked into a trigger
      if (heroPositionChange) {
        const interaction = getEventFromTriggerSpaces(heroPositionChange, this.props.acquiredStoryPoints, this.triggerSpaces);
        if (interaction) {
          this.props.handleProvideNewCutsceneEvents(interaction)
        }
        if (!interaction && this.checkSneakingChanges) {
          //Sneak -> Hero changed, so check for intersection
          const lineOfSightInteraction = getInteractionFromHeroIntersection(nextState.interactives, this.walls);
          if (lineOfSightInteraction) {
            this.props.handleProvideNewCutsceneEvents(lineOfSightInteraction)
          }
        }
      } else {
        if (checkSneakChange && this.checkSneakingChanges) {
          //Sneak -> Guard pos changed, so check for intersection
          const lineOfSightInteraction = getInteractionFromHeroIntersection(nextState.interactives, this.walls);
          if (lineOfSightInteraction) {
            this.props.handleProvideNewCutsceneEvents(lineOfSightInteraction)
          }
        }
      }

      if (nextStepId !== this.stepId) { //update React if stepId was updated in step
        //console.log('update react')
        //Track this as our new ID to diff
        this.stepId = nextStepId;
        this.setState({
          interactives: nextState.interactives
        }, () => {
          //Call the loop again so long as no cutscenes are waiting
          if (this.props.globalCutsceneEvents.length === 0) {
            this.rAF = requestAnimationFrame(businessAsUsualStep);
          }
        })
      } else {
        //console.log('no change')
        this.rAF = requestAnimationFrame(businessAsUsualStep); //continue with the loop without DOM update
      }
    };

    const cutsceneStep = () => {
      const {
        nextStepId,
        nextState,
        isWaitingOnExternalEvent,
        isInternalEventFinished,
      } = getNextCutsceneFrame({
        stepId: this.stepId,
        interactives: this.state.interactives
      }, this.props.globalCutsceneEvents);

      //This event must be handled outside the loop. We will wait patiently for loop to be booted up again by something else
      if (isWaitingOnExternalEvent) {
        //Run me when you can.
        Promise.resolve().then(() => {

          if (this.props.globalCutsceneEvents[0].type === "showPauseMenu") {
            //If we're about to pause, make sure to cancel any frame
            cancelAnimationFrame(this.rAF); //kill any loop that might want to fire
          }

          handleExternalEvent(this.props.globalCutsceneEvents[0], this.state.interactives);
        });

        return
      }

      //At this point we know the event is INTERNAL
      if (nextStepId !== this.stepId) { //update React if stepId was updated in step
        this.stepId = nextStepId;
        this.setState({
          interactives: nextState.interactives
        }, () => {
          if (isInternalEventFinished) {
            this.props.handleSingleCutsceneEventCompleted();

            //Cut for a new trigger cutscene after finishing a different cutscene
            if(this.props.globalCutsceneEvents.length === 1) {
              const hero = this.getHero();
              const interaction = getEventFromTriggerSpaces(`${hero.x}x${hero.y}`, this.props.acquiredStoryPoints, this.triggerSpaces);
              if (interaction) {
                //NOTE: sortof hacky gotcha, but this only works properly if the last event was external. Internal ones (like Jump) cause a repeat submission of the cutscene.
                //You can either fix it for real someday after more investigation, or sprinkle in a TextMessage or something to break up the ending
                this.props.handleProvideNewCutsceneEvents(interaction)
              }
            }

          } else {
            //Call the loop again if we're not done with the internal event that is going on.
            this.rAF = requestAnimationFrame(cutsceneStep);
          }
        })
      }
    };

    const getNextStepFunction = () => {
      return this.props.globalCutsceneEvents.length ? cutsceneStep : businessAsUsualStep
    };

    //Kick off the loop!
    this.rAF = requestAnimationFrame(getNextStepFunction());
  }

  refreshPeople() {
    const {refreshPeopleList} = this.props;
    this.setState(state => {
      return {
        interactives: state.interactives.map(i => {
          const refreshedPerson = refreshPeopleList.find(newP => newP.id === i.id);
          if (refreshedPerson) {
            return {
              ...i,
              ...refreshedPerson
            }
          }
          return i
        })
      }
    }, () => {
      setOverworldValue({
        refreshPeopleList: null,
        externalEventTrigger: getNextExternalEventTrigger("OVERWORLD_REFRESH-PEOPLE-LIST")
      })
    })
  }

  setSneakingCheck() {
    const personWithLineOfSightInteraction = this.state.interactives.find(i => i.lineOfSightInteraction && i.lineOfSightInteraction.length);
    this.checkSneakingChanges = Boolean(personWithLineOfSightInteraction);
  }

  refreshPeopleScenariosAfterNewStoryPoint() {

    //Get new list of people (because we have new story points this time)
    const {interactives} = getCurrentMapData({ currentHero: this.getHero() });



    //We only want to hot update certain things here (we might also have lost or gained people)
    const interactivesAfterHotload = hotLoadScenarioOnInteractives(this.state.interactives, interactives)

    //Apply these changes
    this.setState({
      interactives: interactivesAfterHotload
    }, () => {

      this.setSneakingCheck();

      setOverworldValue({
        externalEventTrigger: getNextExternalEventTrigger("OVERWORLD_REFRESH-PEOPLE-AFTER-NEW-STORY-POINT")
      })
    })

  }

  render() {
    //console.log('render Overworld')
    const {pixelSize, mapData, /*mapTransition*/} = this.props;
    const hero = this.getHero();
    const cellSize = pixelSize * 32;

    const frameWidth = 7; //this could come from a prop or something
    const frameHeight = 7; //this could come from a prop or something

    const cameraSpaceOnLeft = Math.floor(frameWidth / 2); //3 spaces on left of hero
    const cameraSpaceOnTop = Math.floor(frameHeight / 2); //3 spaces on top of hero


    //Get our transforms
    const mapTransform = getMapImageTransformValue({
      followingPersonModel: hero,
      pixelSize,
      cameraSpaceOnLeft,
      cameraSpaceOnTop,
    });
    const peopleTransformValues = getPeopleRelativeToMapTransformValue({
      pixelSize,
      people: this.state.interactives.filter(m => m.id !== "hero"),
    });
    //console.log('mapData', mapData)

    const isDialogBoxUp = (this.props.globalCutsceneEvents.length > 0 && this.props.globalCutsceneEvents[0].type === "textMessage");

    return (
      <React.Fragment>
        <div
          className="Overworld"
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}>
          {/*<div style={{position: "fixed", left:0, top:0, zIndex: 100}}>*/}
          {/*<button onClick={() => this.beginLoop()}>Next Frame</button>*/}
          {/*</div>*/}

          {/* The map background image + NPCs */}
          <div
            className="MapContainer"
            style={{
              transform: writeTransformValueToString(mapTransform),
              width: mapData.width * (pixelSize * 32),
              height: mapData.height * (pixelSize * 32),
              position: "relative",

              // animation: function () {
              //   if (mapTransition === "out") {
              //     return "fadeOut 0.3s ease forwards"
              //   }
              //   return "fadeIn 0.3s ease forwards"
              // }(),
            }}
          >
            <MapCanvas
              mapImage={mapData.image}
              mapPxWidth={mapData.width * 32}
              mapPxHeight={mapData.height * 32}
              mapWidthStyle={mapData.width * (pixelSize * 32)}
              mapHeightStyle={mapData.height * (pixelSize * 32)}
            />
            {/*<MapImage*/}
            {/*mapImage={mapData.image}*/}
            {/*width={mapData.width * (pixelSize*32)}*/}
            {/*height={mapData.height * (pixelSize*32)}*/}
            {/*/>*/}
            {
              this.renderTriggerSpaces.filter(t => {
                return t.type === "battle"
              }).map(model => {
                const isHeroNearby = function() {
                  //The intent here is to shut off the effect when the hero is close to the space so it's welcoming to step in
                  const yDiff = Math.abs(hero.y - model.y);
                  const xDiff = Math.abs(hero.x - model.x); //< 3;
                  const yClose = (yDiff < 3 ) || (yDiff > 4 )
                  const xClose = (xDiff < 3 ) || (xDiff > 4 )
                  return yClose && xClose;
                }();
                return (
                  <BattleSpace
                    key={model.id}
                    battleId={model.bypassOnStoryPoints ? model.bypassOnStoryPoints[0] : null}
                    pixelSize={pixelSize}
                    x={model.x}
                    y={model.y}
                    heroX={hero.x}
                    heroY={hero.y}
                    isHeroNearby={isHeroNearby}
                    isActivated={String(hero.x) === String(model.x) && String(hero.y) === String(model.y)}
                  />
                )
              })
            }
            {
              this.explodingTriggerSpaces.map((explodingTriggerSpace, i) => {
                return (
                  <ExplodingBattleSpace
                    key={i}
                    pixelSize={pixelSize}
                    x={explodingTriggerSpace.x}
                    y={explodingTriggerSpace.y}
                  />
                )
              })
            }
            {
              this.renderTriggerSpaces.filter(t => t.type === "transition").map(model => {
                return (
                  <TransitionSpace
                    key={model.id}
                    pixelSize={pixelSize}
                    x={model.x}
                    y={model.y}
                    transitionDirection={model.transitionDirection}
                  />
                )
              })
            }
            {
              //Render each interactive relative to the map container
              this.state.interactives.filter(m => m.id !== "hero").map(model => {
                return (
                  <div
                    key={model.id}
                    style={{ cursor: "pointer"}}
                    onClick={() => this.fireActionButton()}
                  >
                    <Person
                      pixelSize={pixelSize}
                      cellSize={pixelSize * 32}

                      //Easy diffable (for PureComponent) values from model:
                      isCameraHero={model.isCameraHero}
                      skinId={model.skinId}
                      x={model.x}
                      y={model.y}
                      transformValue={writeTransformValueToString(peopleTransformValues[model.id])}
                      direction={model.direction}
                      movingProgress={model.movingProgress}
                      currentActionType={model.currentActionType}
                      isInvisible={model.isInvisible}
                      isHostile={model.isHostile}
                      hasQuestIndicator={model.hasQuestIndicator}
                      hasBattleLaptop={model.hasBattleLaptop}
                      hasFriendlyLaptop={model.hasFriendlyLaptop}
                      isBlinkingVendingMachine={model.isBlinkingVendingMachine || false}
                      isDialogBoxUp={isDialogBoxUp}
                    />
                  </div>
                )
              })
            }
          </div>

          {/* Render just the hero centered in the middle of the frame */}
          <Person
            pixelSize={pixelSize}
            cellSize={pixelSize * 32}
            skinId={hero.skinId}
            transformValue={`translate3d(${cellSize * cameraSpaceOnLeft}px, ${ cellSize * cameraSpaceOnTop}px, 0)`}
            direction={hero.direction}
            movingProgress={hero.movingProgress}
            currentActionType={hero.currentActionType}
            isInvisible={hero.isInvisible}
            hasBattleLaptop={hero.hasBattleLaptop}
            hasFriendlyLaptop={hero.hasFriendlyLaptop}
            isDialogBoxUp={isDialogBoxUp}
          />

        </div>

        <div
            className="ArrowsContainer"
            style={{
              transform: writeTransformValueToString(mapTransform),
              width: mapData.width * (pixelSize * 32),
              height: mapData.height * (pixelSize * 32),
                left:0,
                top:0,
              position: "absolute",
              pointerEvents: "none",
            }}
        >

            {
              this.state.interactives.filter(m => {

                if (m.isHostile || m.hasQuestIndicator) {
                  return true;
                }

                return false;
              }).map(model => {
                return (
                  <NpcArrow
                      key={"arrow-"+model.id}
                      //transformValue={writeTransformValueToString(peopleTransformValues[model.id])}
                      pixelSize={pixelSize}
                      cellSize={cellSize}
                      skinId={model.skinId}
                      isHostile={model.isHostile}
                      hasQuestIndicator={model.hasQuestIndicator}
                      currentActionType={model.currentActionType}
                      x={model.x}
                      y={model.y}
                  />
                )
              })
            }


        </div>


        <OverworldCrewHud
          handlePauseClick={() => {
            this.firePauseButton()
          }}
        />
      </React.Fragment>
    );
  }
}


export default Overworld;