export function getWinningTeam(combatants) {

  const isTeamOneDead = combatants.filter(c => c.belongsToTeam === "one").every(c => c.hp <= 0);
  if (isTeamOneDead) {
    return "two"
  }

  const isTeamTwoDead = combatants.filter(c => c.belongsToTeam === "two").every(c => c.hp <= 0);
  if (isTeamTwoDead) {
    return "one"
  }

  return null
}