//import {shuffle} from "../../../helpers/shuffle";

export default function({combatants=[], battleStoryPoints=[], regularDecision}) {

  let updatedBattleStoryPoints = [...battleStoryPoints];

  const judge = combatants.find(c => c.belongsToTeam === "two"); //only one
  const judgeHpPercent = judge.hp / judge.maxHp * 100;

  const allAlivePlayers = combatants.filter(c => c.belongsToTeam === "one" && c.hp > 0);
  const playerIds = combatants.filter(c => c.belongsToTeam === "one").map(c => c.id);

  //Don't let this battle happen if you have less than 3 people
  //execute all if less than 3 players
  if (playerIds.length < 3) {
    return {
      submission: [
        {
          actionId: "speak",
          casterId: judge.id,
          targetIds: [judge.id],
          mergeData: {
            dialogContent: [
              "You need a three person Crew to impress me!"
            ]
          }
        },
        {
          actionId: "natural_auto-kill",
          casterId: judge.id,
          targetIds: playerIds,
        },
      ],
      updatedBattleStoryPoints: updatedBattleStoryPoints
    };
  }



  //First, delete all player items
  if (!battleStoryPoints.includes("judge--delete-items")) {
    updatedBattleStoryPoints.push("judge--delete-items")
    return {
      submission: [
        {
          actionId: "speak",
          casterId: judge.id,
          targetIds: [judge.id],
          mergeData: {
            dialogContent: [
              "Ahem... No outside help!"
            ]
          }
        },
        {
          actionId: "super_sql-drop",
          casterId: judge.id,
          targetIds: allAlivePlayers.map(c => c.id),
        },
      ],
      updatedBattleStoryPoints: updatedBattleStoryPoints
    };
  }



  if (judgeHpPercent <= 60 && !battleStoryPoints.includes("judge--isolate-person1")) {
    updatedBattleStoryPoints.push("judge--isolate-person1");
    return {
      submission: [
        {
          actionId: "speak",
          casterId: judge.id,
          targetIds: [judge.id],
          mergeData: {
            dialogContent: [
              "First, I judge you alone!"
            ]
          }
        },
        {
          actionId: "script_restart",
          casterId: judge.id,
          targetIds: [judge.id, playerIds[0]],
        },
        {
          actionId: "natural_auto-kill",
          casterId: judge.id,
          targetIds: [playerIds[1], playerIds[2]],
        },
        {
          actionId: "speak",
          casterId: judge.id,
          targetIds: [judge.id],
          mergeData: {
            dialogContent: [
              "Show me your best work!"
            ]
          }
        },
      ],
      updatedBattleStoryPoints: updatedBattleStoryPoints
    };
  }

  if (judgeHpPercent <= 60 && !battleStoryPoints.includes("judge--isolate-person2")) {
    updatedBattleStoryPoints.push("judge--isolate-person2");
    return {
      submission: [
        {
          actionId: "speak",
          casterId: judge.id,
          targetIds: [judge.id],
          mergeData: {
            dialogContent: [
              "Very good!"
            ]
          }
        },
        {
          actionId: "script_restart",
          casterId: judge.id,
          targetIds: [judge.id, playerIds[1]],
        },
        {
          actionId: "natural_auto-kill",
          casterId: judge.id,
          targetIds: [playerIds[0]],
        },
        {
          actionId: "speak",
          casterId: judge.id,
          targetIds: [judge.id],
          mergeData: {
            dialogContent: [
              "You need to WOW me!"
            ]
          }
        },
      ],
      updatedBattleStoryPoints: updatedBattleStoryPoints
    };
  }

  if (judgeHpPercent <= 60 && !battleStoryPoints.includes("judge--isolate-person3")) {
    updatedBattleStoryPoints.push("judge--isolate-person3");
    return {
      submission: [
        {
          actionId: "speak",
          casterId: judge.id,
          targetIds: [judge.id],
          mergeData: {
            dialogContent: [
              "I'm impressed..."
            ]
          }
        },
        {
          actionId: "script_restart",
          casterId: judge.id,
          targetIds: [judge.id, playerIds[2]],
        },
        {
          actionId: "natural_auto-kill",
          casterId: judge.id,
          targetIds: [playerIds[1]],
        },
        {
          actionId: "speak",
          casterId: judge.id,
          targetIds: [judge.id],
          mergeData: {
            dialogContent: [
              "Do you have what it takes?"
            ]
          }
        },
      ],
      updatedBattleStoryPoints: updatedBattleStoryPoints
    };
  }

  if (judgeHpPercent <= 60 && !battleStoryPoints.includes("judge--everybody")) {
    updatedBattleStoryPoints.push("judge--everybody");
    return {
      submission: [
        {
          actionId: "speak",
          casterId: judge.id,
          targetIds: [judge.id],
          mergeData: {
            dialogContent: [
              "Convince us that you belong at DangerCon!"
            ]
          }
        },
        {
          actionId: "script_restart",
          casterId: judge.id,
          targetIds: [judge.id, ...playerIds],
        },
        {
          actionId: "script_judge-spawn",
          casterId: judge.id,
          targetIds: [judge.id],
          mergeData: {
            spawnCombatants: [
              {
                id: "enemyX",
                name: "Assistant",
                skinId: "baristaB",
                belongsToTeam: judge.belongsToTeam,
                level: judge.level,
                class: "dps",
                attacks: [{ actionId: "script_slice" }],
                //hp:1,
              },
              {
                id: "enemyY",
                name: "Assistant",
                skinId: "marie",
                belongsToTeam: judge.belongsToTeam,
                level: judge.level,
                class: "support",
                attacks: [{ actionId: "script_slice" }],
                //hp:1,
              },
            ]
          }
        },
      ],
      updatedBattleStoryPoints: updatedBattleStoryPoints
    };
  }


  return {
    submission: regularDecision,
    updatedBattleStoryPoints: updatedBattleStoryPoints
  }
}


