export function getSaveFilesFromLocalStorage() {
  //Files are stringified when saving
  let str = window.localStorage.getItem("dangercrew-web-save-files") || "{}";
  return JSON.parse(str);
}


export function writeSaveFileToLocalStorage(saveFileKey, saveFileData) {

  //Get the existing blob of saves
  let allSaves = getSaveFilesFromLocalStorage();

  //Update the blob to include our new save
  allSaves = {
    ...allSaves,
    [saveFileKey]: saveFileData
  };

  //Write the whole thing in localStorage
  window.localStorage.setItem("dangercrew-web-save-files", JSON.stringify(allSaves));
}