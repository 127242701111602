import BattleActions from "../Battle7/battle-data/battle-actions-data";

export function getCrewSwapMenu({ member, allActionIds, isSupersMode }) {

  let menu = {
    back: {
      handler() {
        return {type: "GO_BACK"}
      }
    }
  };
  let map = [];


  const currentMemberAttacks = Object.keys(member.equippedAttacks).map(key => member.equippedAttacks[key]);

  //Create a menu option for each valid one for this member.
  //Reverse so NEWEST ACQUIRED is on top of list
  [...allActionIds].reverse().map(actionId => {
    const model = BattleActions[actionId];
    return model && {
      actionId,
      name: model.name,
      description: model.description,
      icon: model.icon,
    }
  }).filter(a => {
    if (!a) { return false } //maybe this one doesn't exist

      //Only include if valid class type
      if (member.class !== "lead" && !a.icon.includes(member.class)) {
        return false;
      }

    if (currentMemberAttacks.includes(a.actionId)) {
      return false
    }

    //Supers or Regular mode
    if (isSupersMode) {
      if (!a.actionId.startsWith("super_")) {
        return false;
      }
    } else {
      if (a.actionId.startsWith("super_")) {
        return false;
      }
    }

    return true;
  }).forEach((a,i) => {

    const key = `swap-option${i}`;

    // const isValidClass = function() {
    //   if (member.class === "lead") {
    //     return true;
    //   }
    //   return a.icon.includes(member.class) //"support" or "super-support" or whatever
    // }();

    menu[key] = {
      label: a.name,
      icon: a.icon,
      description: a.description, //isValidClass ? a.description : `${a.name} is not compatible with ${member.name}!`,

      // checkUnavailable() {
      //   return !isValidClass
      // },

      handler() {
        return {
          type: "EXECUTE_SWAP",
          actionId: a.actionId,
          isSuperSwap: isSupersMode,
        }
      }
    };
    map.push([key])
  });


  return {map, menu, backOptionId: ""}
}