import {DefaultAction} from "../Action";
//import {getGenericFail} from '../../formulas/getGenericFail'


export const RageQuit = {
  ...DefaultAction,
  name: "Rage Quit",
  description: "Destroy yourself, destroy 1 enemy",
  icon: "super-disrupt",
  categories: ["rageQuit"],

  shouldFailOnSingleTarget(casterModel, targetModel, allUpgrades={}) {
    return null;
  },

  // failEventsPerTarget(casterModel, targetModel, failInfo={}) {
  //   //Maybe add "WHY" here if an upgrade caused it to fail?
  //   return getGenericFail({targetModel, failInfo})
  // },

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {
    return [
      {
        type: "eventAnimation",
        animationId: "animationGenericPain",
        subjects: [casterModel.id],
        delayFramesAfter: 12,
      },
      {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: casterModel.id,
            absoluteChanges: {
              hp: 0
            }
          }
        ],
      }
      //Followup "natural_auto-kill" is created by getNextTurn.js (see tests)
    ]
  }
};