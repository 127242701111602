import React from 'react';
import MapPersonCanvas from '../MapPersonCanvas'
import Skins from '../../_data/Skins'
import BlockWord from "../../pixel-letter-system/BlockWord";
//import Number from './Number'

export default function (props, state) {

  const {pixelSize, showLevel} = props;

  const theme = {
    barInset: "#84cf3d",
    barColor: "#99e550",
  };


  const value = props.hp || 0;
  const maxValue = props.maxHp || 0;

  const fillRatio = value / maxValue;

  //30 is the pixel width of the max fullness
  let fillValue = ( 30 * fillRatio );

  //Floor to 0 or max
  if (fillValue < 0) {
    fillValue = 0
  }
  if (fillValue > 30) {
    fillValue = 30
  }

  //If fill Value is between 25 and 30 (not quite full), force it to 25.
  if (fillValue > 29 && fillValue < 30) {
    fillValue = 29;
  }
  //If ALMOST EMPTY, don't let it round to totally empty.
  if (fillValue > 0 && fillValue < 1) {
    fillValue = 1;
  }

  //Finally, round it to a whole number for no sub pixels
  fillValue = Math.round(fillValue);

  return (
    <div style={{position: "relative", width: pixelSize * 32, height: pixelSize * 21}}>
      <svg style={{display: "block", width: pixelSize * 32}} xmlns="http://www.w3.org/2000/svg"
           viewBox="0 -0.5 32 21" shapeRendering="crispEdges">
        <path className="Dpad--NoShow" stroke="#322c3f" d="M1 0h30M0 1h1M31 1h1"/>
        <path className="Dpad--NoShow" stroke="#0b081a"
              d="M0 2h1M31 2h1M0 3h1M31 3h1M0 4h1M31 4h1M0 5h1M31 5h1M0 6h1M31 6h1M0 7h1M31 7h1M0 8h1M31 8h1M0 9h1M31 9h1M0 10h1M31 10h1M0 11h1M31 11h1M0 12h1M31 12h1M0 13h1M31 13h1M0 14h1M31 14h1M0 15h1M31 15h1M0 16h1M31 16h1"/>
        <path className="Dpad--NoShow" stroke="#1f2b13" d="M0 17h32"/>
        <path className="Dpad--NoShow" stroke="#08152e" d="M0 18h32M0 19h32M1 20h30"/>

        {/* Lifebar svg */}
        <svg className="Dpad--NoShow" style={{width: pixelSize * 32, position: "absolute"}} xmlns="http://www.w3.org/2000/svg"
             shapeRendering="crispEdges">
          <rect className="Dpad--NoShow" style={{transition: "width 0.3s ease"}} fill={theme.barInset} x={1} y={17.5} width={fillValue} height={1}/>
          <rect className="Dpad--NoShow" style={{transition: "width 0.3s ease"}} fill={theme.barColor} x={1} y={18.5} width={fillValue} height={1}/>
        </svg>
      </svg>

      {/* Face */}
      <div style={{
        position: "absolute",
        left: pixelSize,
        top: pixelSize,
        right: pixelSize,
        bottom: pixelSize * 4,
        background: "#f6fdf1",
        overflow: "hidden"
      }}>
        <MapPersonCanvas
          pixelSize={pixelSize}
          skinSrc={Skins[props.skinId]}
          style={{
            transform: `translate3d(0,${pixelSize * -6}px,0)`
          }}
        />
        {/*<div style={{*/}
          {/*display: "block",*/}
          {/*position: "relative",*/}
          {/*top: pixelSize * -6,*/}
          {/*width: pixelSize * 32,*/}
          {/*height: pixelSize * 32,*/}
          {/*backgroundImage: `url(${Skins[props.skinId]})`,*/}
          {/*backgroundSize: "400%",*/}
        {/*}}/>*/}
      </div>

      {/* Number */}
      {
        Boolean(showLevel) && (
          <div style={{position: "absolute", right:pixelSize*-1, top:pixelSize*-1,}}>
            <BlockWord
              pixelSize={pixelSize}
              string={String(showLevel)}
              theme="level"
            />
          </div>
        )
      }

    </div>
  )
}