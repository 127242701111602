import React from 'react'
import {setOverworldValue} from "./redux-actions/overworld-actions";
import {getNextExternalEventTrigger} from "./Overworld/getIncrExternalEventTrigger";
import {waitFrames} from "./helpers/waitFrames";
import BoxDiv from "./interface/BoxDiv";
import BoxDivBody from "./interface/BoxDivBody";
import StaticTextLine from "./StaticTextLine";
import BoxDivTippyTop from "./interface/BoxDivTippyTop.js";
import {audioManager} from "./audio/audioManager";

class HowToPlayMessage extends React.Component {

  componentDidMount() {

    audioManager.playSfx("sfx_popup");

    //Unlock the overworld right away
    setOverworldValue({
      externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-HOW-TO-PLAY_SHOWN")
    });

    //Keep the window up for a bit
    waitFrames(500, () => {
      setOverworldValue({isHowToPlayMessageOpen: false})
    })

  }

  componentWillUnmount() {
    //unmount it early so it doesn't come back. (maybe a text message came up)
    setOverworldValue({isHowToPlayMessageOpen: false})
  }


  render() {
    const {pixelSize} = this.props;
    const indentStyle = {
      paddingLeft: pixelSize * 4
    };


    const keyboardInstructionStyle = {
      container: {
        overflow: "hidden",
        width: pixelSize * 41,
        height: pixelSize * 43,
        position: "absolute",
        right:pixelSize * 4,
        bottom:pixelSize * 5,
        zIndex: 10,
      },
      svg: {
        width: pixelSize * 82,
        height: pixelSize * 43,
        display: "block",
        //animation: `svg_keyboard 0.8s infinite steps(2)`
      }
    };


    return (
        <div style={{
          position: "absolute",
          left: pixelSize * 4,
          bottom:pixelSize * 4,
          right: pixelSize * 4,
        }}>

          <div style={keyboardInstructionStyle.container}>
            <svg style={keyboardInstructionStyle.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 82 43" shapeRendering="crispEdges">

              <path stroke="#5f5f5f" d="M15 0h11M14 1h1M26 1h1M14 2h1M26 2h1M56 2h11M14 3h1M26 3h1M55 3h1M67 3h1M14 4h1M26 4h1M55 4h1M67 4h1M14 5h1M20 5h1M26 5h1M55 5h1M67 5h1M14 6h1M18 6h5M26 6h1M55 6h1M67 6h1M14 7h1M26 7h1M55 7h1M61 7h1M67 7h1M14 8h1M26 8h1M55 8h1M59 8h5M67 8h1M55 9h1M67 9h1M55 10h1M67 10h1M1 14h11M15 14h11M29 14h11M0 15h1M12 15h1M14 15h1M26 15h1M28 15h1M40 15h1M0 16h1M12 16h1M14 16h1M26 16h1M28 16h1M40 16h1M42 16h11M56 16h11M70 16h11M0 17h1M12 17h1M14 17h1M26 17h1M28 17h1M40 17h2M53 17h1M55 17h1M67 17h1M69 17h1M81 17h1M0 18h1M6 18h1M12 18h1M14 18h1M26 18h1M28 18h1M34 18h1M40 18h2M53 18h1M55 18h1M67 18h1M69 18h1M81 18h1M0 19h1M5 19h2M12 19h1M14 19h1M19 19h3M26 19h1M28 19h1M34 19h2M40 19h2M53 19h1M55 19h1M67 19h1M69 19h1M81 19h1M0 20h1M5 20h2M12 20h1M14 20h1M20 20h1M26 20h1M28 20h1M34 20h2M40 20h2M47 20h1M53 20h1M55 20h1M67 20h1M69 20h1M75 20h1M81 20h1M0 21h1M6 21h1M12 21h1M14 21h1M26 21h1M28 21h1M34 21h1M40 21h2M46 21h2M53 21h1M55 21h1M60 21h3M67 21h1M69 21h1M75 21h2M81 21h1M0 22h1M12 22h1M14 22h1M26 22h1M28 22h1M40 22h2M46 22h2M53 22h1M55 22h1M61 22h1M67 22h1M69 22h1M75 22h2M81 22h1M41 23h1M47 23h1M53 23h1M55 23h1M67 23h1M69 23h1M75 23h1M81 23h1M41 24h1M53 24h1M55 24h1M67 24h1M69 24h1M81 24h1M1 30h39M0 31h1M40 31h1M0 32h1M40 32h1M42 32h39M0 33h1M40 33h2M81 33h1M0 34h1M40 34h2M81 34h1M0 35h1M40 35h2M81 35h1M0 36h1M40 36h2M81 36h1M0 37h1M40 37h2M81 37h1M0 38h1M40 38h2M81 38h1M41 39h1M81 39h1M41 40h1M81 40h1" />
              <path stroke="#f5f5f5" d="M15 1h11M15 2h11M15 3h11M15 4h5M21 4h5M15 5h4M22 5h4M15 6h3M23 6h3M15 7h11M15 8h11M1 15h11M15 15h11M29 15h11M1 16h11M15 16h11M29 16h11M1 17h5M7 17h5M15 17h11M29 17h5M35 17h5M1 18h4M7 18h5M15 18h3M23 18h3M29 18h5M36 18h4M1 19h3M7 19h5M15 19h4M22 19h4M29 19h5M37 19h3M1 20h4M7 20h5M15 20h5M21 20h5M29 20h5M36 20h4M1 21h5M7 21h5M15 21h11M29 21h5M35 21h5M1 22h11M15 22h11M29 22h11M1 31h39M1 32h39M1 33h11M15 33h1M18 33h2M23 33h1M27 33h1M31 33h9M1 34h11M13 34h3M17 34h1M19 34h2M22 34h2M25 34h3M29 34h1M31 34h9M1 35h11M14 35h2M17 35h1M19 35h2M22 35h2M26 35h2M30 35h10M1 36h11M13 36h3M17 36h1M19 36h2M22 36h2M25 36h3M29 36h1M31 36h9M1 37h11M15 37h1M17 37h1M19 37h2M22 37h2M27 37h1M29 37h1M31 37h9M1 38h39" />
              <path stroke="#c6c6c6" d="M56 3h11M42 17h11M56 17h11M70 17h11M42 33h39" />
              <path stroke="#434343" d="M20 4h1M19 5h1M21 5h1M61 6h1M60 7h1M62 7h1M14 9h1M26 9h1M14 10h1M26 10h1M14 11h1M26 11h1M55 11h1M67 11h1M15 12h11M56 12h11M6 17h1M34 17h1M5 18h1M18 18h5M35 18h1M4 19h1M36 19h1M47 19h1M75 19h1M46 20h1M59 20h5M76 20h1M45 21h1M77 21h1M0 23h1M12 23h1M14 23h1M26 23h1M28 23h1M40 23h1M0 24h1M12 24h1M14 24h1M26 24h1M28 24h1M40 24h1M0 25h1M12 25h1M14 25h1M26 25h1M28 25h1M40 25h2M53 25h1M55 25h1M67 25h1M69 25h1M81 25h1M1 26h11M15 26h11M29 26h11M42 26h11M56 26h11M70 26h11M0 39h1M40 39h1M0 40h1M40 40h1M0 41h1M40 41h2M81 41h1M1 42h39M42 42h39" />
              <path stroke="#dbdbdb" d="M56 4h11M56 5h11M56 6h5M62 6h5M56 7h4M63 7h4M56 8h3M64 8h3M56 9h11M56 10h11M56 11h11M42 18h11M56 18h11M70 18h11M42 19h5M48 19h5M56 19h11M70 19h5M76 19h5M42 20h4M48 20h5M56 20h3M64 20h3M70 20h5M77 20h4M42 21h3M48 21h5M56 21h4M63 21h4M70 21h5M78 21h3M42 22h4M48 22h5M56 22h5M62 22h5M70 22h5M77 22h4M42 23h5M48 23h5M56 23h11M70 23h5M76 23h5M42 24h11M56 24h11M70 24h11M42 25h11M56 25h11M70 25h11M42 34h39M42 35h11M56 35h1M59 35h2M64 35h1M68 35h1M72 35h9M42 36h11M54 36h3M58 36h1M60 36h2M63 36h2M66 36h3M70 36h1M72 36h9M42 37h11M55 37h2M58 37h1M60 37h2M63 37h2M67 37h2M71 37h10M42 38h11M54 38h3M58 38h1M60 38h2M63 38h2M66 38h3M70 38h1M72 38h9M42 39h11M56 39h1M58 39h1M60 39h2M63 39h2M68 39h1M70 39h1M72 39h9M42 40h39M42 41h39" />
              <path stroke="#ffffff" d="M15 9h11M1 23h11M15 23h11M29 23h11M1 39h39" />
              <path stroke="#cccccc" d="M15 10h11M15 11h11M1 24h11M15 24h11M29 24h11M1 25h11M15 25h11M29 25h11M1 40h39M1 41h39" />
              <path stroke="#595652" d="M12 33h3M16 33h2M20 33h3M24 33h3M28 33h3M12 34h1M16 34h1M18 34h1M21 34h1M24 34h1M28 34h1M30 34h1M12 35h2M16 35h1M18 35h1M21 35h1M24 35h2M28 35h2M53 35h3M57 35h2M61 35h3M65 35h3M69 35h3M12 36h1M16 36h1M18 36h1M21 36h1M24 36h1M28 36h1M30 36h1M53 36h1M57 36h1M59 36h1M62 36h1M65 36h1M69 36h1M71 36h1M12 37h3M16 37h1M18 37h1M21 37h1M24 37h3M28 37h1M30 37h1M53 37h2M57 37h1M59 37h1M62 37h1M65 37h2M69 37h2M53 38h1M57 38h1M59 38h1M62 38h1M65 38h1M69 38h1M71 38h1M53 39h3M57 39h1M59 39h1M62 39h1M65 39h3M69 39h1M71 39h1" />
              <style>
                {`

            @keyframes svg_keyboard {
                  0% { transform: translate3d(0,0,0) }
                  100% { transform: translate3d(-100%,0,0) }
            }
        `}
              </style>
            </svg>

          </div>

          <BoxDiv pixelSize={pixelSize}>
            <BoxDivTippyTop pixelSize={pixelSize} />
            <BoxDivBody
                pixelSize={pixelSize}
                style={{
                  padding: pixelSize * 4
                }}
            >
              {/*<StaticTextLine*/}
                  {/*pixelSize={pixelSize}*/}
                  {/*text="How to Play:"*/}
              {/*/>*/}
              <div style={indentStyle}>
                <StaticTextLine
                    pixelSize={pixelSize}
                    text="* Walk with Arrow keys or WASD"
                />
              </div>
              <div style={indentStyle}>
                <StaticTextLine
                    pixelSize={pixelSize}
                    text="* Talk to people with Enter/Space"
                />
              </div>
              <div style={indentStyle}>
                <StaticTextLine
                    pixelSize={pixelSize}
                    text="* See your menu with Esc"
                />
              </div>
            </BoxDivBody>
          </BoxDiv>
        </div>
    );
  }
}

export default HowToPlayMessage;