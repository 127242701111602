import {shuffle} from "../../../helpers/shuffle";

export function getMischiefDecision({casterModel, teamMembers, enemies, enemyItems}) {

  const myAttackIds = casterModel.attacks.map(a => a.actionId);
  const myMischiefAttacks = [
    "script_curl",
    "script_curl-mk-ii",
    "script_bug",
    "script_sql-injection"
  ].filter(key => {
    return myAttackIds.includes(key);
  }).filter(key => {
    //Check validity of each one.
    if (
      key === "script_curl" ||
      key === "script_curl-mk-ii" ||
      key === "script_sql-injection"
    ) { return enemyItems.length > 0 }

    if (
      key === "script_bug"
    ) {
      return enemyItems.map(i => !i.isBugged).length > 0
    }

    return false;
  });

  //If I have 0, return null here...
  if (myMischiefAttacks.length === 0) { return null }

  //Choose one of my valid ones:
  const chosenAttackId = shuffle(myMischiefAttacks)[0];
  return {
    actionId: chosenAttackId,
    casterId: casterModel.id,
    targetIds: enemies.map(e => e.id)
  };
}