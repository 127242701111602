import React from 'react';
import moment from 'moment';
// import BoxDiv from '../interface/BoxDiv'
// import BoxDivBody from '../interface/BoxDivBody'
import StaticTextLine from "../StaticTextLine";
import MapPerson from "../Overworld/MapPerson";

export default ({pixelSize, name, skinId, level, chapterTitle, lastSaved, isSelected}) => {

  const lineHeightStyle = {height: pixelSize * 14};

  return (
    <div style={{
      position: "relative",
      display: "flex",
      alignItems: "center",
      paddingTop: pixelSize * 2,
      paddingBottom: pixelSize * 2,
      borderBottom: `${pixelSize}px solid #d8d5ec`
    }}>
      <div style={{height: pixelSize * 40, width: pixelSize * 40, position: "relative"}}>
        <div style={{
          position: "absolute",
          left: pixelSize * 3,
          top: pixelSize * 2
        }}>
          <MapPerson
            skinId={skinId}
            pixelSize={pixelSize}
            cellSize={pixelSize * 32}
          />
        </div>
      </div>
      <div>
        <div style={lineHeightStyle}>
          <StaticTextLine
            pixelSize={pixelSize}
            text={`${name} L${level}`}
          />
        </div>
        <div style={lineHeightStyle}>
          <StaticTextLine
            pixelSize={pixelSize}
            text={chapterTitle}
          />
        </div>
        <div style={lineHeightStyle}>
          <StaticTextLine
            pixelSize={pixelSize}
            text={moment(lastSaved).fromNow()}
          />
        </div>
      </div>

      {
        isSelected && (
          <div style={{
            position: "absolute",
            left: pixelSize,
            top: pixelSize,
            bottom: pixelSize,
            right: pixelSize,
            boxShadow: `0 0 0 ${pixelSize}px #07bfdd, 0 0 0 ${pixelSize * 2}px #7de2f2, 0 0 0 ${pixelSize * 3}px #07bfdd`
          }}>
          </div>
        )
      }

    </div>
  )
}