import React from "react";
import { connect } from "react-redux";
import StaticTextLine from "./StaticTextLine";
import BoxDiv from "./interface/BoxDiv";
import { setGlobalValue } from "./redux-actions/global-actions";
import BoxDivBody from "./interface/BoxDivBody";
import BoxDivTippyTop from "./interface/BoxDivTippyTop";

class CreditsScreen extends React.Component {
  render() {
    const { pixelSize } = this.props;
    return (
      <BoxDiv pixelSize={pixelSize}>
        <BoxDivBody pixelSize={pixelSize}>
          <BoxDivTippyTop pixelSize={pixelSize}/>

          <main style={{ padding: pixelSize * 8 }}>
            <div style={{ marginBottom: pixelSize * 10 }}>
              <StaticTextLine
                pixelSize={pixelSize}
                text={"CREDITS"}
              />
            </div>
            <div style={{ width: pixelSize * 200, marginBottom: pixelSize * 10 }}>
              <StaticTextLine
                pixelSize={pixelSize}
                text={
                  "Danger Crew was made by Drew Conley and Glenn LaBarre. Additional music by Henry Leacock."
                }
              />
            </div>
            <div style={{ width: pixelSize * 200, marginBottom: pixelSize * 10 }}>
              <StaticTextLine
                pixelSize={pixelSize}
                text={
                  "Original concept by Henry Leacock, David Stout, and Drew Conley."
                }
              />
            </div>
            <div style={{ marginBottom: pixelSize * 10 }}>
              <StaticTextLine
                pixelSize={pixelSize}
                text={"Thank you for playing! Please visit our website: thedangercrew.com"}
              />
            </div>
            <div style={{ position: "relative", width: pixelSize * 100 }}>
              <button
                onClick={() => {
                  setGlobalValue({ gameArea: "title" });
                }}
                style={{
                  position: "absolute",
                  display: "block",
                  left: 0, top: 0, bottom: 0, right: 0,
                  width: "100%",
                  cursor: "pointer",
                  background: "red"
                }}/>
              <BoxDiv pixelSize={pixelSize} style={{ pointerEvents: "none" }}>
                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  background: "#729efc",
                  paddingTop: pixelSize,
                  borderBottom: `${pixelSize}px solid #4372e9`
                }}>
                  <StaticTextLine
                    pixelSize={pixelSize}
                    text={"Back to Title"}
                    theme="white"
                  />
                </div>
              </BoxDiv>
            </div>
          </main>
        </BoxDivBody>
      </BoxDiv>
    );
  }
}

export default connect((state, props) => {
  return {
    pixelSize: state.global.pixelSize
  };
})(CreditsScreen);
