import store from "../init/store";
import { setGlobalValue } from "../redux-actions/global-actions";

export function getTitleScreenMenu(userState) {


  //NOTE: No more concept of guest/logged in because everybody does local saving
  //if (userState === "guest") {
  let payload = {

    menu: {
      guestNewGame: {
        label: "New Game",
        handler() {
          return { type: "NEW_GAME" };
        }
      },
      guestContinueGame: {
        label: "Continue Game",
        handler() {
          return { type: "CONTINUE_GAME" };
        }
      }
      // guestCreateAccount: {
      //   label: "Create Account",
      //   handler() {
      //     return { type: "CREATE_ACCOUNT" }
      //   }
      // },
      // guestLogIn: {
      //   label: "Log In",
      //   handler() {
      //     return { type: "LOG_IN" }
      //   }
      // }
    },
    map: [
      ["guestNewGame"],
      ["guestContinueGame"]
      // ["guestCreateAccount"],
      // ["guestLogIn"],
    ]
  };


  //Provided by Electron build - does not show in web version
  if (process.env.REACT_APP_ELECTRON_BUILD) {
    //FULL SCREEN
    payload.menu.guestToggleFullscreen = {
      label: "Toggle Full Screen",
      handler() {

        if (store.getState().global.userRequestedFullScreen) {
          //Go windowed
          const closeFullScreenHandler = window.clientRequestsNotFullScreen || function() {
          };
          closeFullScreenHandler();
          setGlobalValue({ userRequestedFullScreen: false });
        } else {
          //Go fullscreen
          const fullScreenHandler = window.clientRequestsFullScreen || function() {
          };
          fullScreenHandler();
          setGlobalValue({ userRequestedFullScreen: true });
        }
      }
    };
    payload.map.push(["guestToggleFullscreen"]);
  }

  //Push credits option (both web and electron)
  payload.menu.guestCredits = {
    label: "Credits",
    handler() {
      return { type: "CREDITS" };
    }
  };
  payload.map.push(["guestCredits"]);


  //Provided by Electron build - does not show in web version
  if (process.env.REACT_APP_ELECTRON_BUILD) {

    // EXIT GAME
    payload.menu.guestQuitGame = {
      label: "Exit",
      handler() {
        const quitHandler = window.clientRequestsCloseApp || function() {
        };
        quitHandler();
        //no return
      }
    };
    payload.map.push(["guestQuitGame"]);

    //Provide this for ESC to close Electron app
    payload.backOptionId = "guestQuitGame";
  }


  return payload;


  //}

  // if (userState === "loggedIn") {
  //   return {
  //     menu: {
  //       userContinueGame: {
  //         label: "Continue Game",
  //         handler() {
  //           return { type: "CONTINUE_GAME" }
  //         }
  //       },
  //       userNewGame: {
  //         label: "New Game",
  //         handler() {
  //           return { type: "NEW_GAME" }
  //         }
  //       },
  //       userLogOut: {
  //         label: "Log Out",
  //         handler() {
  //           return { type: "LOG_OUT" }
  //         }
  //       }
  //     },
  //     map: [
  //       ["userContinueGame"],
  //       ["userNewGame"],
  //       ["userLogOut"],
  //     ]
  //   }
  // }
  //If the code runs beyond here, something is wrong. There are only two states :)
}