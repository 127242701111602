import {DefaultAction} from "../Action";
import {getCombatantHpRaise} from "../../formulas/getCombatantHpRaise";

export default {
  ...DefaultAction,

  getUse() { return [] },

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {

    let focusRecoverPercent = 15;

    const casterUpgrade = allUpgrades[casterModel.activeUpgradeId || null];
    if (casterUpgrade && casterUpgrade.increaseFocusRecoveryPercent) {
      focusRecoverPercent += casterUpgrade.increaseFocusRecoveryPercent
    }

    const hpChange = getCombatantHpRaise(targetModel, focusRecoverPercent, "percent");


    return [
      {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: targetModel.id,
            relativeChanges: {
              hp: hpChange
            },
            message: {
              theme: "battlePositiveHp",
              text: `Focus +${hpChange}HP`
            },
          }
        ]
      }
    ]
  }
}