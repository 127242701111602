import React from "react";
import { waitFrames } from "../../../helpers/waitFrames";
import { audioManager } from "../../../audio/audioManager";

//import RunAnimation from './RunAnimation'

class AnimationNervousMistake extends React.Component {
  state = {
    hasTriggeredAnimation: false
  };

  componentDidMount() {
    const targets = this.props.event.subjects;
    let delay = 30; //frames

    audioManager.playSfx("sfx_nervous");

    //Shake the NERVOUS PERSON
    this.props.handleCombatantStateChange([
      {
        combatantId: this.props.event.nervousPersonId,
        absoluteChanges: {
          animation: "characterShake"
        }
      }
    ]);

    waitFrames(delay, () => {
      this.setState(
        {
          hasTriggeredAnimation: true
        },
        () => {
          //Start shaking
          audioManager.playSfx("sfx_iteratePain");
          targets.forEach(id => {
            this.props.handleCombatantStateChange([
              {
                combatantId: id,
                absoluteChanges: {
                  laptopFlash: "pain",
                  animation: "characterShake"
                }
              }
            ]);
          });
          //Stop shaking
          waitFrames(20, () => {
            targets.forEach(id => {
              this.props.handleCombatantStateChange([
                {
                  combatantId: id,
                  absoluteChanges: {
                    laptopFlash: "none",
                    animation: null
                  }
                }
              ]);

              waitFrames(10, () => {
                //stop shaking the NERVOUS PERSON
                this.props.handleCombatantStateChange([
                  {
                    combatantId: this.props.event.nervousPersonId,
                    absoluteChanges: {
                      animation: null
                    }
                  }
                ]);

                this.props.onEventDone();
              });
            });
          });
        }
      );
    });
  }

  render() {
    return null;
  }
}

export default AnimationNervousMistake;
