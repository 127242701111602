import React from 'react'
import ArenaPerson from './ArenaPerson'
import Lifebar from './Lifebar'
import Icon from '../interface/Icon'
import BoxDivBody from "../interface/BoxDivBody";
import BoxDiv from '../interface/BoxDiv'
import BlockWord from "../pixel-letter-system/BlockWord";
import {waitFrames} from "../helpers/waitFrames";
import Status from './Status'
import TurnBlip from './TurnBlip'
import StarEnemyHealthBar from './StarEnemyHealthBar'

class ArenaCombatant extends React.Component {

  constructor(props) {
    super();
    let direction = "UP";
    if (props.belongsToTeam === "two" || props.isUsingMenu) {
      direction = "DOWN"
    }
    this.state = {
      direction
    }
  }

  //Rotate to and from the camera
  componentDidUpdate(oldProps) {
    //The menu just swapped over to ME!
    if (this.props.isUsingMenu && !oldProps.isUsingMenu) {
      //console.log('now using the menu!', this.props.skinId)
      this.setState({direction: "LEFT"});
      waitFrames(4, () => {
        this.setState({direction: "DOWN"})
      })
    }
    //The menu is moving AWAY from me
    if (!this.props.isUsingMenu && oldProps.isUsingMenu) {
      //console.log('done with the menu!', this.props.skinId)
      this.setState({direction: "LEFT"});
      waitFrames(5, () => {
        this.setState({direction: "UP"})
      })
    }

    //Battle is over and we won -> player team should face camera
    if (
      this.props.belongsToTeam === "one" &&
      (!oldProps.winningTeam && this.props.winningTeam === "one")
    ) {
      this.setState({direction: "LEFT"});
      waitFrames(5, () => {
        this.setState({direction: "DOWN"})
      })
    }
  }

  renderEnemyLifebar() {
    const {pixelSize, level, hp, maxHp, status, turnBlips, useCustomLifebar, laptopFlash, useIcon, hasEscaped } = this.props;


    if (hasEscaped) {
      return null
    }

    if (useCustomLifebar && useCustomLifebar === "richard2") {
      return <StarEnemyHealthBar pixelSize={pixelSize} hp={hp} maxHp={maxHp} status={status} laptopFlash={laptopFlash} />
    }

    let blips = [];
    for (let i = 0; i < turnBlips; i++) {
      blips.push(true)
    }

    return (
      <div style={{
        position: "absolute",
        left: pixelSize * -2,
        bottom: pixelSize * 38,
        display: "flex",
        alignItems: "flex-end",
        zIndex: 3,
        opacity: hp > 0 ? 1 : 0,
        transition: "opacity 0.2s",
      }}>
        <div style={{position: "absolute", right: 0, top: pixelSize * 4, display: "flex", flexDirection: "row-reverse"}}>
          {
            blips.map((b, i) => (
              <div key={i} style={{marginLeft: pixelSize}}>
                <TurnBlip
                  pixelSize={pixelSize}
                  theme="enemy"
                />
              </div>
            ))
          }
        </div>
        <BoxDiv
          pixelSize={pixelSize}
          style={{
            marginRight: pixelSize * 2,
            marginBottom: pixelSize * 1
          }}
        >
          <BoxDivBody
            pixelSize={pixelSize}
            insetTheme={true}
            style={{
              padding: pixelSize,
              paddingLeft: pixelSize * 2,
              paddingRight: pixelSize * 2,
            }}>
            <Icon
              pixelSize={pixelSize}
              category={useIcon ? useIcon : this.props.class} //allow useIcon to be passed in
            />
          </BoxDivBody>
        </BoxDiv>
        <div>
          <BlockWord
            pixelSize={pixelSize}
            string={String(level)}
          />
          <Lifebar
            value={hp}
            maxValue={maxHp}
            pixelSize={pixelSize}
            theme={"enemy"}
          />
        </div>

        {
          status !== "normal" && (
            <div style={{
              position: "absolute",
              //left: "50%",
              left: 0,
              bottom: pixelSize * -10,
              //transform: `translate3d(-50%,0,0)`
            }}>
              <Status
                pixelSize={pixelSize}
                status={status}
              />
            </div>
          )
        }
      </div>
    )
  }

  render() {
    const {pixelSize, leftPosition, topPosition, skinId, hp, belongsToTeam, laptopFlash, animation, isSteppedForward, hasEscaped} = this.props;

    return (
      <main
        style={{
          width: pixelSize * 32,
          height: pixelSize * 32,
          position: "absolute",
          left: leftPosition,
          top: topPosition,
          transform: isSteppedForward && belongsToTeam === "two" ? `translate3d(0,25%,0)` : `translate3d(0,0,0)`,
          transition: "transform 0.3s linear, opacity 0.3s linear",
          opacity: hasEscaped ? 0 : 1

        }}
      >
        {
          belongsToTeam === "two" && (
            this.renderEnemyLifebar()
          )
        }

        {/*<div style={{position: "absolute", left:0, top:0, background:"#000", color: "lime"}}>{this.state.direction}</div>*/}
        <ArenaPerson
          pixelSize={pixelSize}
          cellSize={pixelSize * 32}
          skinId={skinId}
          direction={this.state.direction}
          currentActionType={animation}
          belongsToTeam={belongsToTeam}
          laptopFlash={laptopFlash}
          hp={hp}
          hasEscaped={hasEscaped}
        />
      </main>
    )


    // return (
    //
    //   <main
    //     style={{
    //       animation: animation || "none",
    //       width: pixelSize * 32,
    //       height: pixelSize * 32,
    //       background: "#333",
    //       position: "absolute",
    //       left: leftPosition,
    //       top: topPosition,
    //       color: "#fff",
    //       //transition: "transform 0.3s ease",
    //     }}
    //   >
    //     <div style={{fontSize: "0.7em"}}>
    //       {name}
    //       <div>
    //         HP: {hp}/{maxHp}
    //       </div>
    //       {
    //         status !== "normal" && (
    //           <div style={{color: "red"}}>
    //             {status}
    //           </div>
    //         )
    //       }
    //     </div>
    //   </main>
    //
    // );
  }
}

export default ArenaCombatant;