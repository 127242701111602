export const DefaultAction = {
  name: "",
  description: "",

  getUse(actionModel, casterModel, targetModels) {
    return [
      presetActionName(actionModel.name, actionModel.icon, casterModel),
    ]
  },

  missEventsPerTarget(casterModel, targetModel) {
    //console.log(targetModel)
    return [
      {
        type: "eventAnimation",
        animationId: "animationMiss",
        subjects: [targetModel.id],
        delayFramesAfter: 2,
      },
      {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: targetModel.id,
            message: {
              theme: "battleNoEffect",
              text: `Miss!`
            }
          }
        ]
      },
    ]
  }

};

function presetActionName(name, icon, casterModel) {
  return {
    type: "eventMessage",
    subjects: [casterModel.id],
    theme: icon,
    textArray: [
      name
    ],
    delayFramesAfter: 30,
  }
}

export const DefaultScript = {
  increaseDangerMeter: 25,
};
export const DefaultNatural = {
  cannotBeReplaced: true
};
export const DefaultItem = {
  isItem: true,
  icon: "item",
  cannotBeReplaced: true
};