import React from 'react';
import AuthRunningHank from './AuthRunningHank'

class AuthLoadingScreen extends React.Component {

  render() {
    if (!this.props.isLoading) {
      return null
    }

    return (
      <div
        style={{
          position: "absolute",
          left:0,
          right:0,
          top:0,
          bottom:0,
          background: "#fff",
          display: "flex",
          justifyContent: "center",
          paddingTop: 35,
          textAlign: "center"
        }}>
        <div>
          <AuthRunningHank />
          <h2 style={{fontSize:32}}>Working...</h2>
          <p>Beep boop beep!</p>
        </div>
      </div>
    )
  }
}

export default AuthLoadingScreen;


