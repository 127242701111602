import {DefaultAction, DefaultItem} from "../Action";


function createStatusCuringItem(properties) {
  return {
    ...DefaultAction,
    ...DefaultItem,

    name: properties.name,
    description: properties.description,
    targetingType: "single-friendly",
    icon: "item",
    sellValue: properties.sellValue,

    shouldFailOnSingleTarget(casterModel, targetModel) {

      if (properties.healsStatus === "*") {
        //"*" can't heal normal
        if (targetModel.status === "normal") {
          return {
            failCode: "not-correct-status"
          }
        }
        //but is valid for anything else
        return null
      }

      //named property can't heal target's status
      if (targetModel.status !== properties.healsStatus) {
        return {
          failCode: "not-correct-status"
        }
      }
      return null;
    },

    failEventsPerTarget(casterModel, targetModel, failInfo={}) {
      return [
        {
          type: "eventBlippy",
          stateChanges: [
            {
              combatantId: targetModel.id,
              message: {
                theme: "battleNoEffect",
                text: `No effect`
              },
            }
          ]
        }
      ]
    },

    successEventsPerTarget(casterModel, targetModel, allUpgrades) {
      return [
        {
          type: "eventAnimation",
          animationId: "animationOpenItem",
          subjects: [targetModel.id],
          haltQueueUntilDone: true,
        },
        {
          type: "eventBlippy",
          stateChanges: [
            {
              combatantId: targetModel.id,
              absoluteChanges: {
                status: "normal"
              },
              message: {
                theme: "battlePositiveHp",
                text: properties.messageText
              },
            },
          ]
        }
      ]
    }
  }
}

export const ItemHealMemoryLeak = createStatusCuringItem({
  name: "Breakpoint",
  description: "Heals a memory leak",
  healsStatus: "memory-leak",
  messageText: "Leak patched!",
  sellValue: 12,
});

export const ItemHealFire = createStatusCuringItem({
  name: "Extinguisher",
  description: "Heals a laptop that is burning in fire",
  healsStatus: "fire",
  messageText: "Fire extinguished!",
  sellValue: 20,
});

export const ItemHealLag = createStatusCuringItem({
  name: "Network Reset",
  description: "Heals lagging",
  healsStatus: "lag",
  messageText: "Lagging fixed!",
  sellValue: 12,
});

export const ItemHealHacked = createStatusCuringItem({
  name: "Password Reset",
  description: "Heals being hacked",
  healsStatus: "hacked",
  messageText: "No longer hacked!",
  sellValue: 20,
});

export const ItemHealNervous = createStatusCuringItem({
  name: "Self Help Book",
  description: "Heals being nervous",
  healsStatus: "nervous",
  messageText: "No longer nervous!",
  sellValue: 12,
});

export const ItemHealDisabled = createStatusCuringItem({
  name: "Rebooter",
  description: "Heals being disabled",
  healsStatus: "disabled",
  messageText: "No longer disabled!",
  sellValue: 30,
});

export const ItemHealAnything = createStatusCuringItem({
  name: "Normalizer",
  description: "Heals any status",
  healsStatus: "*",
  messageText: "Status removed!",
  sellValue: 40,
});