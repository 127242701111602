export function mergeChangesIntoCombatants(combatants, changes) {

  return combatants.map(c => {

    let payload = {...c};

    changes.forEach(change => {
      if (change.combatantId === c.id) {
        if (change.relativeChanges) {
          Object.keys(change.relativeChanges).forEach(key => {
            payload[key] = payload[key] + change.relativeChanges[key]
          })
        }
        if (change.absoluteChanges) {
          Object.keys(change.absoluteChanges).forEach(key => {
            payload[key] = change.absoluteChanges[key]
          })
        }
      }
    });

    //Remove any statuses on die
    if (payload.hp <= 0) {
      payload.status = "normal";
    }

    return payload;
  });
}