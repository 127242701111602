import React, { Component } from 'react';
import Word from './Word'

class PixelArtTextLine extends Component {

  constructor(props) {
    super(props);


    //Assemble stream into words (separate by spaces) that have themes attached
    let words = [
      []
    ];
    props.letterStream.forEach(l => {

      if (l.character === " ") {
        words.push([]);
      } else {
        words[words.length-1].push({
          character: l.character,
          theme: l.theme
        })
      }
    });

    words = words.map(group => {

      if (group.length === 0) { return null } //Sometimes the end could just be a space if the last word mistakely ends in a space.

      return {
        word: group.map(l => l.character).join(""),
        theme: group[0].theme
      }
    }).filter(w => w);

    this.words = words;
  }

  render() {

    let letterIndex = 0;

    return (
      this.words.map((word, i) => {
        letterIndex += word.word.length;
        return (
          <Word
            key={i}
            pixelSize={this.props.pixelSize}
            word={word.word}
            theme={word.theme || "white"}
            //Stuff for animation and displaying
            firstLetterIndex={letterIndex - word.word.length}
            useReveal={this.props.useReveal}
            lettersRevealed={this.props.lettersRevealed}
          />
        )
      })
    );
  }
}

export default PixelArtTextLine;
