//WATCH OUT: This is not the source of truth. Please copy over from DS2017/AllCombatants
//This is just for dev testing
export const hackerKing1 = [
  {
    id: "enemyA",
    name: "Hacker King",
    class: "lead",
    level: 11,
    skinId: "kingHacker",
    playerVictoryMessage: "** Hacker King has left the battle **",
    playerDefeatMessage: "Defeated.",
    attacks: [
      {actionId: "script_slice-mk-ii"},
      {actionId: "script_foreach-mk-ii"},
    ],
    useMusic: "music_kingHackerBattle",
    useCustomMaxHp: 100,
    useScriptedDecisionFn: "hackerKing1Decisions",
    items: [
      {actionId: "item_battery-pack"},
      {actionId: "item_attack-sticker"}
    ],
    immunities: ["lag", "nervous"],
    isImmuneToRageQuit: true,
    speedModifier: 10,
    defenseModifier: 10
  },
].map(e => {
  return {
    ...e,
    belongsToTeam: "two"
  }
});
export const hackerKing2 = [
  {
    id: "enemyA",
    name: "Hacker King",
    class: "lead",
    level: 18,
    skinId: "kingHacker",
    playerVictoryMessage: "** Hacker King has left the battle **",
    playerDefeatMessage: "Defeated.",
    attacks: [
      {actionId: "script_slice-mk-ii"},
      {actionId: "script_foreach-mk-ii"},
    ],
    useMusic: "music_kingHackerBattle",
    useCustomMaxHp: 140,
    useScriptedDecisionFn: "hackerKing2Decisions",
    items: [
      {actionId: "item_battery-pack"},
      {actionId: "item_attack-sticker"}
    ],
    immunities: ["lag", "nervous"],
    isImmuneToRageQuit: true,
    attackModifier: 3,
    speedModifier: 10,
    defenseModifier: 10
  },
].map(e => {
  return {
    ...e,
    belongsToTeam: "two"
  }
});
export const hackerKing3 = [
  {
    id: "enemyA",
    level: 20,
    "name": "H0tr0d III",
    "isHacker": true,
    "useIcon": "hacker",
    "useMusic": "music_hackersBattle",
    "skinId": "redMan",
    "playerDefeatMessage": "** The hacker boasts in glory. **",
    "playerVictoryMessage": "** The hacker is speechless! **",
    "attacks": [
      {
        "actionId": "script_slice"
      },
      {
        "actionId": "script_troll"
      }
    ],
    "items": [
      {
        "actionId": "item_attack-sticker"
      }
    ],
    "dropsItems": [
      "item_attack-sticker"
    ],
    "dropsCredits": 22
  },
  {
    isLeadEnemy: true,
    id: "enemyB",
    name: "Hacker King",
    class: "lead",
    level: 21,
    skinId: "kingHacker",
    playerVictoryMessage: "** Hacker King lays to rest... for now **",
    playerDefeatMessage: "Defeated.",
    attacks: [
      {actionId: "script_slice-mk-ii"},
      {actionId: "script_foreach-mk-ii"},
    ],
    useMusic: "music_kingHackerBattle",
    useCustomMaxHp: 140,
    useScriptedDecisionFn: "hackerKing3Decisions",
    items: [
      {actionId: "item_battery-pack"},
      {actionId: "item_attack-sticker"}
    ],
    immunities: ["lag", "nervous"],
    isImmuneToRageQuit: true,
    attackModifier: 3,
    speedModifier: 10,
    defenseModifier: 10
  },
  {
    id: "enemyC",
    level: 20,
    "name": "Bubba III",
    "isHacker": true,
    "useIcon": "hacker",
    "useMusic": "music_hackersBattle",
    "skinId": "shieldMan",
    "defenseModifier": 6,
    "playerDefeatMessage": "** The hacker wants you to know your place. **",
    "playerVictoryMessage": "** The hacker begins to sulk. **",
    "attacks": [
      {
        "actionId": "script_block"
      },
      {
        "actionId": "script_slice"
      },
      {
        "actionId": "script_screen-blur"
      },
      {
        "actionId": "script_bug"
      }
    ],
    "personality": [
      "vicious",
      "positiveStatus:3",
      "lowerStats",
      "healHp",
      "mischief"
    ],
    "immunities": [
      "hacked",
      "nervous"
    ],
    "dropsItems": [
      "item_defense-sticker"
    ],
    "dropsCredits": 30
  }


].map(e => {
  return {
    ...e,
    belongsToTeam: "two"
  }
});