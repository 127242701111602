import React from 'react'
import themes from './themes'
import blockNumbers from './block-numbers'

export function getBlockLetterStyle(pixelSize = 1, model) {
  return {
    width: pixelSize * model.width,
  }
}

export default {
  ...blockNumbers,

  "A": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M4 3h1M0 4h1M2 4h1M4 4h1M0 5h1M2 5h1M4 5h1" />
          <path stroke="#ffffff" d="M1 1h3M1 2h1M3 2h1M1 3h3M1 4h1M3 4h1M1 5h1M3 5h1" />
          <path stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "B": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h4M0 1h1M3 1h2M0 2h1M2 2h1M4 2h1M0 3h1M3 3h2M0 4h1M2 4h1M4 4h1M0 5h1M4 5h1" />
          <path stroke="#ffffff" d="M1 1h2M1 2h1M3 2h1M1 3h2M1 4h1M3 4h1M1 5h3" />
          <path stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "C": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h3M0 3h1M2 3h1M0 4h1M2 4h3M0 5h1M4 5h1" />
          <path stroke="#ffffff" d="M1 1h3M1 2h1M1 3h1M1 4h1M1 5h3" />
          <path stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "D": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h4M0 1h1M3 1h2M0 2h1M2 2h1M4 2h1M0 3h1M2 3h1M4 3h1M0 4h1M2 4h1M4 4h1M0 5h1M3 5h2" />
          <path stroke="#ffffff" d="M1 1h2M1 2h1M3 2h1M1 3h1M3 3h1M1 4h1M3 4h1M1 5h2" />
          <path stroke={themes[theme].main} d="M0 6h4" />
        </svg>
      )
    }
  },
  "E": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h3M0 3h1M3 3h1M0 4h1M2 4h3M0 5h1M4 5h1" />
          <path stroke="#ffffff" d="M1 1h3M1 2h1M1 3h2M1 4h1M1 5h3" />
          <path stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "F": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h3M0 3h1M3 3h1M0 4h1M2 4h2M0 5h1M2 5h1" />
          <path stroke="#ffffff" d="M1 1h3M1 2h1M1 3h2M1 4h1M1 5h1" />
          <path stroke={themes[theme].main} d="M0 6h3" />
        </svg>
      )
    }
  },
  "G": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h3M0 3h1M2 3h3M0 4h1M2 4h1M4 4h1M0 5h1M4 5h1" />
          <path stroke="#ffffff" d="M1 1h3M1 2h1M1 3h1M1 4h1M3 4h1M1 5h3" />
          <path stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "H": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M2 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M4 3h1M0 4h1M2 4h1M4 4h1M0 5h1M2 5h1M4 5h1" />
          <path stroke="#ffffff" d="M1 1h1M3 1h1M1 2h1M3 2h1M1 3h3M1 4h1M3 4h1M1 5h1M3 5h1" />
          <path stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "I": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h2M3 2h2M1 3h1M3 3h1M0 4h2M3 4h2M0 5h1M4 5h1" />
          <path stroke="#ffffff" d="M1 1h3M2 2h1M2 3h1M2 4h1M1 5h3" />
          <path stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "J": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M1 0h4M1 1h1M4 1h1M1 2h2M4 2h1M0 3h3M4 3h1M0 4h1M2 4h1M4 4h1M0 5h1M4 5h1" />
          <path stroke="#ffffff" d="M2 1h2M3 2h1M3 3h1M1 4h1M3 4h1M1 5h3" />
          <path stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "K": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M2 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M3 3h2M0 4h1M2 4h1M4 4h1M0 5h1M2 5h1M4 5h1" />
          <path stroke="#ffffff" d="M1 1h1M3 1h1M1 2h1M3 2h1M1 3h2M1 4h1M3 4h1M1 5h1M3 5h1" />
          <path stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "L": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h3M0 1h1M2 1h1M0 2h1M2 2h1M0 3h1M2 3h1M0 4h1M2 4h3M0 5h1M4 5h1" />
          <path stroke="#ffffff" d="M1 1h1M1 2h1M1 3h1M1 4h1M1 5h3" />
          <path stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "M": {
    width: 7,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 7 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h7M0 1h1M6 1h1M0 2h1M2 2h1M4 2h1M6 2h1M0 3h1M2 3h1M4 3h1M6 3h1M0 4h1M2 4h3M6 4h1M0 5h1M2 5h1M4 5h1M6 5h1" />
          <path stroke="#ffffff" d="M1 1h5M1 2h1M3 2h1M5 2h1M1 3h1M3 3h1M5 3h1M1 4h1M5 4h1M1 5h1M5 5h1" />
          <path stroke={themes[theme].main} d="M0 6h3M4 6h3" />
        </svg>
      )
    }
  },
  "N": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h4M0 1h1M3 1h2M0 2h1M2 2h1M4 2h1M0 3h1M2 3h1M4 3h1M0 4h1M2 4h1M4 4h1M0 5h1M2 5h1M4 5h1" />
          <path stroke="#ffffff" d="M1 1h2M1 2h1M3 2h1M1 3h1M3 3h1M1 4h1M3 4h1M1 5h1M3 5h1" />
          <path stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "O": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M2 3h1M4 3h1M0 4h1M2 4h1M4 4h1M0 5h1M4 5h1" />
          <path stroke="#ffffff" d="M1 1h3M1 2h1M3 2h1M1 3h1M3 3h1M1 4h1M3 4h1M1 5h3" />
          <path stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "P": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M4 3h1M0 4h1M2 4h3M0 5h1M2 5h1" />
          <path stroke="#ffffff" d="M1 1h3M1 2h1M3 2h1M1 3h3M1 4h1M1 5h1" />
          <path stroke={themes[theme].main} d="M0 6h3" />
        </svg>
      )
    }
  },
  "Q": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M2 3h1M4 3h1M0 4h1M2 4h1M4 4h1M0 5h1M3 5h2" />
          <path stroke="#ffffff" d="M1 1h3M1 2h1M3 2h1M1 3h1M3 3h1M1 4h1M3 4h1M1 5h2" />
          <path stroke={themes[theme].main} d="M0 6h4" />
        </svg>
      )
    }
  },
  "R": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M2 3h1M4 3h1M0 4h1M3 4h2M0 5h1M2 5h1M4 5h1" />
          <path stroke="#ffffff" d="M1 1h3M1 2h1M3 2h1M1 3h1M3 3h1M1 4h2M1 5h1M3 5h1" />
          <path stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "S": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h3M0 3h1M4 3h1M0 4h3M4 4h1M0 5h1M4 5h1" />
          <path stroke="#ffffff" d="M1 1h3M1 2h1M1 3h3M3 4h1M1 5h3" />
          <path stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "T": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h2M3 2h2M1 3h1M3 3h1M1 4h1M3 4h1M1 5h1M3 5h1" />
          <path stroke="#ffffff" d="M1 1h3M2 2h1M2 3h1M2 4h1M2 5h1" />
          <path stroke={themes[theme].main} d="M1 6h3" />
        </svg>
      )
    }
  },
  "U": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M2 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M2 3h1M4 3h1M0 4h1M2 4h1M4 4h1M0 5h1M4 5h1" />
          <path stroke="#ffffff" d="M1 1h1M3 1h1M1 2h1M3 2h1M1 3h1M3 3h1M1 4h1M3 4h1M1 5h3" />
          <path stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "V": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M2 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M2 3h1M4 3h1M0 4h1M2 4h1M4 4h1M0 5h2M3 5h2" />
          <path stroke="#ffffff" d="M1 1h1M3 1h1M1 2h1M3 2h1M1 3h1M3 3h1M1 4h1M3 4h1M2 5h1" />
          <path stroke={themes[theme].main} d="M1 6h3" />
        </svg>
      )
    }
  },
  "W": {
    width: 7,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 7 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h3M4 0h3M0 1h1M2 1h1M4 1h1M6 1h1M0 2h1M2 2h3M6 2h1M0 3h1M2 3h1M4 3h1M6 3h1M0 4h1M2 4h1M4 4h1M6 4h1M0 5h1M6 5h1" />
          <path stroke="#ffffff" d="M1 1h1M5 1h1M1 2h1M5 2h1M1 3h1M3 3h1M5 3h1M1 4h1M3 4h1M5 4h1M1 5h5" />
          <path stroke={themes[theme].main} d="M0 6h7" />
        </svg>
      )
    }
  },
  "X": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M2 1h1M4 1h1M0 2h2M3 2h2M1 3h1M3 3h1M0 4h2M3 4h2M0 5h1M2 5h1M4 5h1" />
          <path stroke="#ffffff" d="M1 1h1M3 1h1M2 2h1M2 3h1M2 4h1M1 5h1M3 5h1" />
          <path stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "Y": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M2 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M4 3h1M0 4h2M3 4h2M1 5h1M3 5h1" />
          <path stroke="#ffffff" d="M1 1h1M3 1h1M1 2h1M3 2h1M1 3h3M2 4h1M2 5h1" />
          <path stroke={themes[theme].main} d="M1 6h3" />
        </svg>
      )
    }
  },
  "Z": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h3M4 2h1M0 3h2M3 3h2M0 4h1M2 4h3M0 5h1M4 5h1" />
          <path stroke="#ffffff" d="M1 1h3M3 2h1M2 3h1M1 4h1M1 5h3" />
          <path stroke={themes[theme].main} d="M0 6h5" />
        </svg>
      )
    }
  },
  "!": {
    width: 3,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 3 7" shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h3M0 1h1M2 1h1M0 2h1M2 2h1M0 3h1M2 3h1M0 4h3M0 5h1M2 5h1" />
          <path stroke="#ffffff" d="M1 1h1M1 2h1M1 3h1M1 5h1" />
          <path stroke={themes[theme].main} d="M0 6h3" />
        </svg>
      )
    }
  }






  //OLDER, SMALLER ONES ------------------------

  // "A": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M4 3h1M0 4h1M2 4h1M4 4h1" />
  //         <path stroke="#ffffff" d="M1 1h3M1 2h1M3 2h1M1 3h3M1 4h1M3 4h1" />
  //         <path stroke={themes[theme].main} d="M0 5h5" />
  //       </svg>
  //     )
  //   }
  // },
  // "B": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M3 1h2M0 2h1M2 2h1M4 2h1M0 3h1M3 3h2M0 4h1M4 4h1" />
  //         <path stroke="#ffffff" d="M1 1h2M1 2h1M3 2h1M1 3h2M1 4h3" />
  //         <path stroke={themes[theme].main} d="M0 5h5" />
  //       </svg>
  //     )
  //   }
  // },
  // "C": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h3M0 3h1M2 3h3M0 4h1M4 4h1" />
  //         <path stroke="#ffffff" d="M1 1h3M1 2h1M1 3h1M1 4h3" />
  //         <path stroke={themes[theme].main} d="M0 5h5" />
  //       </svg>
  //     )
  //   }
  // },
  // "D": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h4M0 1h1M3 1h2M0 2h1M2 2h1M4 2h1M0 3h1M2 3h1M4 3h1M0 4h1M3 4h2" />
  //         <path stroke="#ffffff" d="M1 1h2M1 2h1M3 2h1M1 3h1M3 3h1M1 4h2" />
  //         <path stroke={themes[theme].main} d="M0 5h4" />
  //       </svg>
  //     )
  //   }
  // },
  // "E": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M3 2h2M0 3h1M2 3h3M0 4h1M4 4h1" />
  //         <path stroke="#ffffff" d="M1 1h3M1 2h1M1 3h1M1 4h3" />
  //         <path stroke="#e0d0d0" d="M2 2h1" />
  //         <path stroke={themes[theme].main} d="M0 5h5" />
  //       </svg>
  //     )
  //   }
  // },
  // "F": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h3M0 3h1M3 3h1M0 4h1M2 4h2" />
  //         <path stroke="#ffffff" d="M1 1h3M1 2h1M1 3h2M1 4h1" />
  //         <path stroke={themes[theme].main} d="M0 5h3" />
  //       </svg>
  //     )
  //   }
  // },
  // "G": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h3M0 3h1M2 3h1M4 3h1M0 4h1M4 4h1" />
  //         <path stroke="#ffffff" d="M1 1h3M1 2h1M1 3h1M3 3h1M1 4h3" />
  //         <path stroke={themes[theme].main} d="M0 5h5" />
  //       </svg>
  //     )
  //   }
  // },
  // "H": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M2 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M4 3h1M0 4h1M2 4h1M4 4h1" />
  //         <path stroke="#ffffff" d="M1 1h1M3 1h1M1 2h1M3 2h1M1 3h3M1 4h1M3 4h1" />
  //         <path stroke={themes[theme].main} d="M0 5h5" />
  //       </svg>
  //     )
  //   }
  // },
  // "I": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h2M3 2h2M0 3h2M3 3h2M0 4h1M4 4h1" />
  //         <path stroke="#ffffff" d="M1 1h3M2 2h1M2 3h1M1 4h3" />
  //         <path stroke={themes[theme].main} d="M0 5h5" />
  //       </svg>
  //     )
  //   }
  // },
  // "J": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M1 0h4M1 1h1M4 1h1M0 2h3M4 2h1M0 3h1M2 3h1M4 3h1M0 4h1M4 4h1" />
  //         <path stroke="#ffffff" d="M2 1h2M3 2h1M1 3h1M3 3h1M1 4h3" />
  //         <path stroke={themes[theme].main} d="M0 5h5" />
  //       </svg>
  //     )
  //   }
  // },
  // "K": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M2 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M3 3h2M0 4h1M2 4h1M4 4h1" />
  //         <path stroke="#ffffff" d="M1 1h1M3 1h1M1 2h1M3 2h1M1 3h2M1 4h1M3 4h1" />
  //         <path stroke={themes[theme].main} d="M0 5h5" />
  //       </svg>
  //     )
  //   }
  // },
  // "L": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h3M0 1h1M2 1h1M0 2h1M2 2h1M0 3h1M2 3h3M0 4h1M4 4h1" />
  //         <path stroke="#ffffff" d="M1 1h1M1 2h1M1 3h1M1 4h3" />
  //         <path stroke={themes[theme].main} d="M0 5h5" />
  //       </svg>
  //     )
  //   }
  // },
  // "M": {
  //   width: 7,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 7 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h7M0 1h1M6 1h1M0 2h1M2 2h1M4 2h1M6 2h1M0 3h1M2 3h1M4 3h1M6 3h1M0 4h1M2 4h3M6 4h1" />
  //         <path stroke="#ffffff" d="M1 1h5M1 2h1M3 2h1M5 2h1M1 3h1M3 3h1M5 3h1M1 4h1M5 4h1" />
  //         <path stroke={themes[theme].main} d="M0 5h7" />
  //       </svg>
  //     )
  //   }
  // },
  // "N": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h4M0 1h1M3 1h2M0 2h1M2 2h1M4 2h1M0 3h1M2 3h1M4 3h1M0 4h1M2 4h1M4 4h1" />
  //         <path stroke="#ffffff" d="M1 1h2M1 2h1M3 2h1M1 3h1M3 3h1M1 4h1M3 4h1" />
  //         <path stroke={themes[theme].main} d="M0 5h5" />
  //       </svg>
  //     )
  //   }
  // },
  // "O": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M2 3h1M4 3h1M0 4h1M4 4h1" />
  //         <path stroke="#ffffff" d="M1 1h3M1 2h1M3 2h1M1 3h1M3 3h1M1 4h3" />
  //         <path stroke={themes[theme].main} d="M0 5h5" />
  //       </svg>
  //     )
  //   }
  // },
  // "P": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M4 3h1M0 4h1M2 4h3" />
  //         <path stroke="#ffffff" d="M1 1h3M1 2h1M3 2h1M1 3h3M1 4h1" />
  //         <path stroke={themes[theme].main} d="M0 5h3" />
  //       </svg>
  //     )
  //   }
  // },
  // "Q": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M2 3h1M4 3h1M0 4h1M3 4h2" />
  //         <path stroke="#ffffff" d="M1 1h3M1 2h1M3 2h1M1 3h1M3 3h1M1 4h2" />
  //         <path stroke={themes[theme].main} d="M0 5h4" />
  //       </svg>
  //     )
  //   }
  // },
  // "R": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M3 3h2M0 4h1M2 4h1M4 4h1" />
  //         <path stroke="#ffffff" d="M1 1h3M1 2h1M3 2h1M1 3h2M1 4h1M3 4h1" />
  //         <path stroke={themes[theme].main} d="M0 5h5" />
  //       </svg>
  //     )
  //   }
  // },
  // "S": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h1M2 2h3M0 3h2M4 3h1M0 4h1M4 4h1" />
  //         <path stroke="#ffffff" d="M1 1h3M1 2h1M3 3h1M1 4h3" />
  //         <path stroke="#e0d0d0" d="M2 3h1" />
  //         <path stroke={themes[theme].main} d="M0 5h5" />
  //       </svg>
  //     )
  //   }
  // },
  // "T": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h2M3 2h2M1 3h1M3 3h1M1 4h1M3 4h1" />
  //         <path stroke="#ffffff" d="M1 1h3M2 2h1M2 3h1M2 4h1" />
  //         <path stroke={themes[theme].main} d="M1 5h3" />
  //       </svg>
  //     )
  //   }
  // },
  // "U": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M2 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M2 3h1M4 3h1M0 4h1M4 4h1" />
  //         <path stroke="#ffffff" d="M1 1h1M3 1h1M1 2h1M3 2h1M1 3h1M3 3h1M1 4h3" />
  //         <path stroke={themes[theme].main} d="M0 5h5" />
  //       </svg>
  //     )
  //   }
  // },
  // "V": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M2 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h1M2 3h1M4 3h1M0 4h2M3 4h2" />
  //         <path stroke="#ffffff" d="M1 1h1M3 1h1M1 2h1M3 2h1M1 3h1M3 3h1M2 4h1" />
  //         <path stroke={themes[theme].main} d="M1 5h3" />
  //       </svg>
  //     )
  //   }
  // },
  // "W": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h7M0 1h1M2 1h3M6 1h1M0 2h1M2 2h1M4 2h1M6 2h1M0 3h1M2 3h1M4 3h1M6 3h1M0 4h1M6 4h1" />
  //         <path stroke="#ffffff" d="M1 1h1M5 1h1M1 2h1M3 2h1M5 2h1M1 3h1M3 3h1M5 3h1M1 4h5" />
  //         <path stroke={themes[theme].main} d="M0 5h7" />
  //       </svg>
  //     )
  //   }
  // },
  // "Y": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M2 1h1M4 1h1M0 2h1M2 2h1M4 2h1M0 3h2M3 3h2M1 4h1M3 4h1" />
  //         <path stroke="#ffffff" d="M1 1h1M3 1h1M1 2h1M3 2h1M2 3h1M2 4h1" />
  //         <path stroke={themes[theme].main} d="M1 5h3" />
  //       </svg>
  //     )
  //   }
  // },
  // "Z": {
  //   width: 5,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h5M0 1h1M4 1h1M0 2h3M4 2h1M0 3h2M3 3h2M0 4h1M4 4h1" />
  //         <path stroke="#ffffff" d="M1 1h3M3 2h1M2 3h1M1 4h3" />
  //         <path stroke={themes[theme].main} d="M0 5h5" />
  //       </svg>
  //     )
  //   }
  // },
  //
  // "!": {
  //   width: 3,
  //   render({pixelSize, key, theme}) {
  //     return (
  //       <svg key={key} style={getBlockLetterStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 3 6" shapeRendering="crispEdges">
  //         <path stroke={themes[theme].upper} d="M0 0h3M0 1h1M2 1h1M0 2h1M2 2h1M0 3h3M0 4h1M2 4h1" />
  //         <path stroke="#ffffff" d="M1 1h1M1 2h1M1 4h1" />
  //         <path stroke={themes[theme].main} d="M0 5h3" />
  //       </svg>
  //     )
  //   }
  // }


}