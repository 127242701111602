/* Howler configs without the Howl instance */
export default {
  sfx_typeBlip: {
    src: [
      //"/sfx/Blip-0.0_bip_3.1.wav"
      "/sfx/new-blip.mp3"
    ],
    volume: 0.9
  },
  sfx_cursorMove: {
    src: ["/sfx/menu-move.mp3"],
    volume: 1
  },
  sfx_submitAction: {
    src: ["/sfx/submit.mp3"],
    volume: 1
  },
  sfx_submitMegaSuccess: {
    src: [
      "/sfx/sfx-mega-success.mp3"
    ],
    volume: 1
  },
  sfx_loopIterate: {
    src: ["/sfx/iterator.mp3"],
    volume: 1
  },
  sfx_Babuhm: {
    src: ["/sfx/baBuhm.mp3"]
  },
  sfx_fillHp: {
    src: ["/sfx/increase-xp.mp3"],
    volume: 1
  },
  sfx_popup: {
    src: ["/sfx/bell_up.mp3"],
    volume: 0.7
  },
  sfx_taunt: { //Also, "whoops!"
    src: ["/sfx/taunt.mp3"],
    volume: 1
  },
  sfx_jump: {
    src: ["/sfx/sfx-jump.mp3"],
    volume: 0.4
  },

  sfx_battleRequest: {
    src: ["/sfx/exclaim_up.mp3"],
    volume: 0.8
  },

  sfx_characterJump: {
    src: ["/sfx/overworld-jump-sfx.mp3"],
    volume: 0.4
  },
  sfx_acceptBattle: {
    src: ["/sfx/shhhink-accepted--bit-crush-more-cresc.mp3"],
    volume: 0.4
  },
  sfx_keyboardTyping: {
    src: ["/sfx/typing-sfx.mp3"],
    volume: 0.8
  },

  //new in b7
  sfx_iteratePain: {
    src: ["/sfx/sfx-iterate-pain.mp3"],
    volume: 0.6
  },
  sfx_laptopExplode: {
    src: ["/sfx/sfx-laptop-explode.mp3"],
    volume: 0.8
  },
  sfx_nextPage: {
    src: ["/sfx/sfx-next-page.mp3"],
    volume: 1.2
  },
  sfx_useItem: {
    src: ["/sfx/sfx-use-item.mp3"],
    volume: 0.6
  },
  sfx_useBuggedItem: {
    src: ["/sfx/sfx-bugged-item.mp3"],
    volume: 0.6
  },
  sfx_journal: {
    src: ["/sfx/journal.mp3"],
    volume: 1
  },
  sfx_pickup: {
    src: ["/sfx/item1.mp3"],
    volume: 1
  },
  sfx_vendingMachine: {
    src: ["/sfx/sfx-market-new.mp3"],
    volume: 0.27
  },
  sfx_bearGrowl: {
    src: ["/sfx/bear-growl.mp3"],
    volume: 0.9
  },
  sfx_hackerKingGrowl: {
    src: ["/sfx/bear-growl.mp3"], //Same bear SFX but quieter
    volume: 0.6
  },
  sfx_levelup: {
    src: ["/sfx/sfx-levelup.mp3"],
    volume: 1
  },
  sfx_hack: {
    src: ["/sfx/hacked-sfx.mp3"],
    volume: 1
  },
  sfx_miss: {
    src: ["/sfx/miss.sfx.mp3"],
    volume: 1.3
  },
  sfx_leak: {
    src: ["/sfx/leak.sfx.mp3"],
    volume: 1
  },

  sfx_harshSlice: {
    src: ["/sfx/slice.sfx.mp3"],
    volume: 0.6
  },
  sfx_serverWarning: {
    src: ["/sfx/server-warning.sfx.mp3"],
    volume: 0.8
  },
  sfx_promiseResolve: {
    src: ["/sfx/promise-resolve.sfx.mp3"],
    volume: 1
  },
  sfx_nervous: {
    src: ["/sfx/nervous.sfx.mp3"],
    volume: 0.15
  },
  sfx_burn: {
    src: ["/sfx/burn.sfx.mp3"],
    volume: 0.2
  },
  sfx_block: {
    src: ["/sfx/block.sfx.mp3"],
    volume: 0.3
  },


  sfx_warpPositive: {
    src: ["/sfx/warp-positive.mp3"],
    volume: 1
  },
  sfx_warpNegative: {
    src: ["/sfx/warp-negative.mp3"],
    volume: 1
  }

};

// export const sfxBattleRequest = new Howl({
//   src: ['https://s3-us-west-2.amazonaws.com/s.cdpn.io/163669/exclaim_up.mp3'],
//   volume: 1
// });
// export const sfxBattleRequestDenied = new Howl({
//   src: ['https://s3-us-west-2.amazonaws.com/s.cdpn.io/163669/exclaim_down.mp3'],
//   volume: 1
// });