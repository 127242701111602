import {shuffle} from "../../../helpers/shuffle";

export default function({combatants=[], battleStoryPoints=[], regularDecision}) {

  //First enemy is the bully.
  const frankie = combatants.find(c => c.belongsToTeam === "two" && c.skinId === "hugo");

  //Hacked people
  const allAlivePlayers = combatants.filter(c => c.belongsToTeam === "one" && c.hp > 0);
  const hackedPlayerIds = allAlivePlayers.filter(c => c.status === "hacked");


  if (hackedPlayerIds.length === 0 && allAlivePlayers.length > 1) {
    const personIdToHack = shuffle([...allAlivePlayers])[0];

    return {
      submission: [
        {
          actionId: "speak",
          casterId: frankie.id,
          targetIds: [ frankie.id ],
          mergeData: {
            dialogContent: [
              "Be mine."
            ]
          }
        },
        {
          actionId: "script_hack",
          casterId: frankie.id,
          targetIds: [personIdToHack.id],
        },
      ],
      updatedBattleStoryPoints: battleStoryPoints
    }
  }

  if (allAlivePlayers.length > 1) {
    return {
      submission: {
        actionId: "script_binary",
        casterId: frankie.id,
        targetIds: allAlivePlayers.map(c => c.id),
      },
      updatedBattleStoryPoints: battleStoryPoints
    }
  }

  // if (heroHpPercent <= 50 && bully.status === "normal") {
  //   return {
  //     submission: [
  //       {
  //         actionId: "speak",
  //         casterId: bully.id,
  //         targetIds: [bully.id],
  //         mergeData: {
  //           dialogContent: [
  //             "You're boring me!"
  //           ]
  //         }
  //       },
  //       {
  //         actionId: "script_headphones",
  //         casterId: bully.id,
  //         targetIds: [bully.id],
  //       },
  //     ],
  //     updatedBattleStoryPoints: battleStoryPoints
  //   }
  // }
  // if (heroHpPercent <= 50 && bully.status !== "normal") {
  //   return {
  //     submission: [
  //       {
  //         actionId: "speak",
  //         casterId: bully.id,
  //         targetIds: [bully.id],
  //         mergeData: {
  //           dialogContent: [
  //             shuffle([
  //               "Think you got what it takes?",
  //               "I don't got all day!",
  //               "Is that the best you've got?!",
  //               "I bet a pile of rocks could code better than you.",
  //               "It's like I'm one with the computer.",
  //               "I doubt you're half as programmatic as me!",
  //               "I've never coded a bug in my life!",
  //               "I write algorithms in my sleep!"
  //             ])[0]
  //           ]
  //         }
  //       }
  //     ],
  //     updatedBattleStoryPoints: battleStoryPoints
  //   }
  // }


  //Maybe submit something custom, OR just use regularDecision which goes through the usual decision class
  //console.log('default to this', regularDecision)
  return {
    submission: regularDecision,
    updatedBattleStoryPoints: battleStoryPoints
  }
}
