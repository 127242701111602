import {setOverworldValue} from "../redux-actions/overworld-actions";
import {setGlobalValue} from "../redux-actions/global-actions";
import {addStoryPoint, removeStoryPoints} from "../story-points/addStoryPoint";
//import {getNextExternalEventTrigger} from "./getIncrExternalEventTrigger";
import store from '../init/store'
import {getJournalUpdateFromNewStoryPoint} from "../story-points/getJournalUpdateFromNewStoryPoint";
import {chapter} from "../ChapterGetter";
import {getDynamicOverworldValues} from './getDynamicOverworldValues'
import {getNextExternalEventTrigger} from "./getIncrExternalEventTrigger";
import {addNewItemToInventoryInRedux} from "./player-data-operations/addNewItemToInventory";
import {changePlayerCreditsInRedux} from "./player-data-operations/changePlayerCurrency"
import {addUpgradeToPlayerDataInRedux} from "./player-data-operations/addUpgradeToPlayerData";
import {addAttackToPlayerDataInRedux} from "./player-data-operations/addAttackToPlayerData";
import {updatePlayerDataHpInRedux} from './player-data-operations/updatePlayerDataHpInRedux'

import {waitFrames} from "../helpers/waitFrames";
import {handleSaveGameEvent} from "../loading/handleSaveGame";
import {audioManager} from "../audio/audioManager";
import {getModifiedBattleCrew} from './player-data-operations/getModifiedBattleCrew'
import {setPlayerDataValue} from "../redux-actions/player-data-actions";
import {
  mergePersonToAcquiredMembersInRedux
} from "./player-data-operations/mergePersonToAcquiredMembers";



//This thing needs to "do stuff" then either automatically resolve the event (by triggering Redux) or document *where* the thing will finally be resolved.
export function handleExternalEvent(event, interactives = []) {

  if (event.type === "textMessage") {
    setOverworldValue({
      dialogMessageContent: event.textArrays,
    })
    //overworld loop is resumed by OverworldDialogBox
  }

  if (event.type === "showPauseMenu") {
    //console.log(event)
    setOverworldValue({
      isPauseMenuOpen: true,
      openPauseMenuAtPage: event.openPauseMenuToPage || null
    });
    //overworld loop is resumed by unpausing
  }

  if (event.type === "showRecoverMenu") {
    setOverworldValue({isRecoverMenuOpen: true});
    //overworld loop is resumed by completing the recover menu
  }

  if (event.type === "showCreatePersonMenu") {
    setOverworldValue({showCreatePersonMenu: {...event}});
    //overworld loop is resumed by completing the menu
  }

  if (event.type === "goToBattle") {


    //Set up to return to Overworld after this battle
    const hero = interactives.find(i => i.id === "hero");
    setOverworldValue({
      returnFromBattleHeroProperties: {
        x: hero.x,
        y: hero.y,
        direction: hero.direction,
        steppedIntoSpace: event.stepIntoBattleSpace || false,
      },
      returnFromBattleInteractives: interactives,

      //Seeded battle stuff:
      battle_currentBattleId: event.battleId,
      battle_addStoryPointOnWin: event.addStoryPointOnWin || null,
    });

    //seed the enemies....

    setGlobalValue({
      gameArea: "battle"
    }) //leaves the Overworld game area... will re-enter after battle (no need to resume loop)
  }

  if (event.type === "goToComingSoon") {
    setGlobalValue({gameArea: "comingSoon"}) //does not ever return to Overworld (at this time)
  }
  if (event.type === "goToCredits") {
    setGlobalValue({gameArea: "credits"}) //does not ever return to Overworld (at this time)
  }


  if (event.type === "changeMap") {

    let mapChangePayload = {
      ...event
    };

    const hero = interactives.find(i => i.id === "hero");
    if (!mapChangePayload.transitionDirection && hero) {
      //If no arrow, set player to natural direction
      mapChangePayload.playerDirection = hero.direction
    }

    let newMapId = event.mapId;
    if (newMapId === "[dynamic-current-map]") {
      newMapId = store.getState().overworld.currentMapId;
    }

    setOverworldValue({
      mapChangeCache: String(Date.now()), //use a trigger other than mapId so we can reload the same map
      currentMapId: newMapId,
      mapChangePayload: mapChangePayload //OverworldContainer will mount a new map and start a fresh loop
    })
  }

  //For the sake of right now, this always only affects one person. That's the parity with the existing engine/editor.
  if (event.type === "changeInteractiveProperty") {

    const newValues = getDynamicOverworldValues(event.changes, store.getState().playerData);

    setOverworldValue({
      refreshPeopleList: [
        {
          id: event.personId,
          ...newValues
        }
      ]
    })
    //`refreshPeople` method of Overworld will call the incr.
  }

  //Add story point + maybe show journal popup
  //Note: no such type as "journalPopup" anymore because it's always tied to adding a story point.
  if (event.type === "addStoryPoint") {

    const addedStoryPoint = addStoryPoint(event.addStoryPoint);

    let overworldValueUpdate = {
      storyPointTrigger: store.getState().overworld.storyPointTrigger + 1
    };


    //Side effect! If this is a market changing story point, show the badge
    if (event.addStoryPoint.startsWith("market-")) {
      overworldValueUpdate.showGlobalHudUnreadBadge = true;
      overworldValueUpdate.showUnreadMarketBadge = true;
    }

    if (addedStoryPoint) {
      const journalUpdate = getJournalUpdateFromNewStoryPoint(
        addedStoryPoint,
        store.getState().playerData.acquiredStoryPoints,
        chapter.get().journals
      );
      if (journalUpdate) {
        overworldValueUpdate.journalPopup = {
          name: journalUpdate.name,
          isComplete: journalUpdate.isComplete,
          expireId: String(Date.now())
        };
      }
    }
    setOverworldValue(overworldValueUpdate);
  }

  if (event.type === "removeStoryPoints") {
    removeStoryPoints(event.removeStoryPoints);
    setOverworldValue({
      storyPointTrigger: store.getState().overworld.storyPointTrigger + 1
    })
  }

  if (event.type === "showBattleWinPopup") {
    setOverworldValue({
      battleWinPopup: {
        xpGained: event.xpGained
      }
    }) //incr is called when the XP viewing experience is done.
  }


  if (event.type === "showHowToPlayMenu") {
    setOverworldValue({
      isHowToPlayMessageOpen: true
    }) //incr is called right away by the mounting message
  }
  if (event.type === "showLoginReminder") {

    //No longer caring about this (because everybody can save locally now)
    setOverworldValue({
      externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-SKIP-LOGIN-REMINDER")
    })


    //Leaving this here in case we need it again: (code for showing popup if not logged in)
    // if (store.getState().global.authState !== "loggedIn") {
    //   setOverworldValue({
    //     showLoginReminder: true
    //   }) //incr is called right away by the mounting message
    // } else {
    //   setOverworldValue({
    //     externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-SKIP-LOGIN-REMINDER")
    //   })
    // }
  }

  if (event.type === "overworldFade") {
    setOverworldValue({
      overworldFadeType: event.fadeTypeId
    }) //incr when animation is over (see OverworldFader)
  }

  if (event.type === "changeChapter") {

    setGlobalValue({
      gameArea: "changingChapter",
      changeToChapterId: event.chapterId
    })

  } //does not come back. ChangingChapter remounts a new gameArea


  //------------------------------------------------------------------------
  //Quick ones -> these update right after the synchronous processing is done.
  if (event.type === "receiveItem") {
    audioManager.playSfx("sfx_pickup");
    addNewItemToInventoryInRedux(event.itemId);
    setOverworldValue({
      externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-RECEIVE-ITEM")
    })
  }

  if (event.type === "updateCredits") {
    //Note: this is tied directly to credits right now. Would add different functions (alternative to changePlayerCreditsInRedux) for other currency types
    //console.log(event)
    changePlayerCreditsInRedux(event.adjustAmount);
    setOverworldValue({
      externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-UPDATE-CREDITS")
    })
  }

  if (event.type === "receiveUpgrade") {
    audioManager.playSfx("sfx_pickup");
    addUpgradeToPlayerDataInRedux(event.libraryId);
    setOverworldValue({
      externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-RECEIVE-UPGRADE")
    })
  }

  if (event.type === "receiveAttack") {
    audioManager.playSfx("sfx_pickup");
    addAttackToPlayerDataInRedux(event.actionId)
    setOverworldValue({
      externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-RECEIVE-ATTACK")
    })
  }

  if (event.type === "updateHp") {
    updatePlayerDataHpInRedux(event);
    setOverworldValue({
      externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-UPDATE-HP")
    })
  }


  if (event.type === "stopSong") {
    //Stop song here...
    audioManager.stopSong();
    setOverworldValue({
      externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-STOP-SONG")
    })
  }
  if (event.type === "playSong") {
    //Play song here...
    audioManager.playSong(event.songId)
    setOverworldValue({
      externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-PLAY-SONG")
    })
  }
  if (event.type === "playSfx") {
    audioManager.playSfx(event.sfxId)
    setOverworldValue({
      externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-PLAY-SFX")
    })
  }

  if (event.type === "saveGame") {
    handleSaveGameEvent();
    setOverworldValue({
      externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-SAVEGAME-DONE")
    })
  }

  if (event.type === "changeCrew") {
    setPlayerDataValue({
      acquiredMembers: getModifiedBattleCrew(store.getState().playerData.acquiredMembers, event.setCrewToIds)
    });
    setOverworldValue({
      externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-CHANGE-CREW")
    })
  }

  if (event.type === "changeCrewMemberProperty") {
    (event.changes || []).forEach(payload => {
      mergePersonToAcquiredMembersInRedux(payload);
    });
    setOverworldValue({
      externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-CHANGE-MEMBER-PROPERTY")
    })
  }

  if (event.type === "wait") {
    waitFrames(event.frames, () => {
      setOverworldValue({
        externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-WAIT-DONE")
      })
    })
  }
}