import {handleSellItemInRedux} from "./handleSellItem";
import {useItemOnCrewMembersInRedux} from "./useItemOnCrewMember";
import {audioManager} from "../audio/audioManager";

export function getUseItemMenu({stagedActionId, stagedInstanceId, item, crew}) {


  let map = [];
  let menu = {
    back: {
      label: "Cancel",
      handler() {
        return {type: "GO_BACK"}
      }
    }
  };

  //Look at the action to determine what we can do with it.
  if (item && item.overworldUsableCode) {

    const fullTeamTarget = item.targetingType === "all-friendlies";
    if (fullTeamTarget) {
      //1 option for the whole team
      menu["option0"] = {
        label: "Use on Crew",
        icon: "item",
        handler() {
          audioManager.playSfx("sfx_fillHp")
          useItemOnCrewMembersInRedux(crew.map(c => c.id), item, stagedInstanceId);
          return {
            type: "GO_TO_LIST"
          }
        }
      };
      map.push(["option0"])
    } else {

      //1 option per member
      crew.forEach((c, i) => {
        const key = `option${i}`;
        menu[key] = {
          label: `Use on ${c.name}`,
          icon: "item",
          viewBag: {
            hp: c.hp,
            maxHp: c.maxHp,
          },
          checkUnavailable() {
            return itemIsInvalidOnMember(c, item)
          },
          handler() {
            audioManager.playSfx("sfx_fillHp")
            useItemOnCrewMembersInRedux([c.id], item, stagedInstanceId);
            return {
              type: "GO_TO_LIST"
            }
          }
        };
        map.push([key])

      })
    }

  }

  if (item && item.sellValue > 0) {
    menu["sellItem"] = {
      label: `Sell for ${item.sellValue} credits`,
      icon: "credit",
      handler() {
        return {type: "GO_TO_CONFIRM_SELL"}
      }
    };
    map.push(["sellItem"])
  }

  map.push(["back"]);
  return {
    map, menu, backOptionId: "back"
  }
}

export function getSellItemConfirmMenu({stagedActionId, stagedInstanceId, item}) {
  let menu = {
    back: {
      label: "Cancel",
      handler() {
        return {type: "GO_BACK"}
      }
    }
  };

  menu["confirmSell"] = {
    label: "Yes, Sell Item",
    handler() {
      audioManager.playSfx("sfx_pickup");
      handleSellItemInRedux(stagedInstanceId, item.sellValue);
      return {
        type: "GO_TO_LIST"
      }
    }
  };


  let map = [
    ["confirmSell"],
    ["back"]
  ];

  return {
    map, menu, backOptionId: "back"
  }
}

function itemIsInvalidOnMember(crewMember, item) {
  const {overworldUsableCode} = item;
  if (overworldUsableCode.includes("alive") && crewMember.hp <= 0) {
    return true
  }
  if (overworldUsableCode.includes("dead") && crewMember.hp > 0) {
    return true
  }
  if (crewMember.hp >= crewMember.maxHp) {
    return true
  }
  return false;
}