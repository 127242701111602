import React from 'react'
import blockLetters from './letters/block-letters'

class BlockWord extends React.Component {

  render() {
    const {string, pixelSize, theme} = this.props;
    const characters = string.split("").map((c, i) => {

      if (c === " ") {
        return (
          <span key={i} style={{marginRight: pixelSize * 3}} />
        )
      }

      let character = c.toUpperCase();
      const blockCharacter = blockLetters[character];
      if (!blockCharacter) {
        console.log('no character found in blockLetters:', character, string)
        return null;
      }

      return (
        <span key={i}
              className="Dpad--NoShow"
              style={{
          position: "relative",
          left: i * -pixelSize,
          display: "block",
        }}>
          {
            blockCharacter.render({
              pixelSize,
              theme: theme || "dark"
            })
          }
        </span>
      )

    });

    return (
      <div style={{
        display: "flex",
        alignItems: "flex-end"
      }}>
        {characters}
      </div>
    );
  }
}

export default BlockWord;