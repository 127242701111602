import React from "react";
import BoxDiv from "./interface/BoxDiv";
import BoxDivBody from "./interface/BoxDivBody";
import StaticTextLine from "./StaticTextLine";
import Icon from "./interface/Icon";

export default function(props) {
  const { pixelSize } = props;
  return (
    <div
      style={{
        position: "absolute",
        right: 0,
        bottom: 0
      }}
    >
      <BoxDiv pixelSize={pixelSize}>
        <BoxDivBody
          pixelSize={pixelSize}
          style={{
            paddingLeft: pixelSize * 4,
            paddingRight: pixelSize * 4,
            display: "flex",
            alignItems: "center"
          }}
        >
          <div style={{ marginRight: pixelSize * 3, position:"relative" }}>
            <Icon pixelSize={pixelSize} category="saveDisk" />
            {/* Layered spinny animation */}
            <div style={{width: pixelSize * 8, height: pixelSize * 8, position: "absolute", left:0, top:0, overflow:"hidden"}}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 48 8" shapeRendering="crispEdges"
                   style={{
                     display: "block",
                     width: pixelSize * 48,
                     animation: "svg_run-save-animation 0.7s steps(6) infinite"
                   }}
              >
                <style>
                {`@keyframes svg_run-save-animation { 0% { transform: translate3d(0,0,0) } 100% { transform: translate3d(-100%,0,0) }}`}
                </style>
                <path stroke="#85fc12" d="M0 0h2m8 0h2m8 0h2m8 0h1M0 1h1m30 0h1m7 1h2m-2 1h2m-9 1h1m14 0h1M32 5h1m14 0h1M7 6h1m16 0h1M6 7h2m4 0h2m4 0h2m4 0h2"/>
              </svg>
            </div>
          </div>
          <StaticTextLine pixelSize={pixelSize} text={"Saving"} />
        </BoxDivBody>
      </BoxDiv>
    </div>
  );
}
