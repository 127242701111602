export default function ({combatants = [], battleStoryPoints = [], regularDecision, roundNumber}) {



  const self = combatants.find(c => c.belongsToTeam === "two" && c.useScriptedDecisionFn === "defensegrid");
  //Find first enemy who is not self
  const master = combatants.find(c => c.belongsToTeam === "two" && c.id !== self.id);

  if (master && master.hp > 0 && master.hp < master.maxHp) {
      return {
        submission: {
          actionId: "script_restart",
          casterId: self.id,
          targetIds: [master.id],
        },
        updatedBattleStoryPoints: battleStoryPoints
      }
  }


  //Find any non-shielded enemy
  // const nonShieldedEnemy = combatants.find(c => c.belongsToTeam === "two" && c.status === "normal");
  // if (nonShieldedEnemy) {
  //   return {
  //     submission: {
  //       actionId: "script_block",
  //       casterId: self.id,
  //       targetIds: [nonShieldedEnemy.id],
  //     },
  //     updatedBattleStoryPoints: battleStoryPoints
  //   }
  // }

  //Recovery? - use ITEMS so it's limited? or just have a recover move?

  // //Do this every 3 turns:
  // if (roundNumber % 3 === 0) {
  //   const randomAliveTeamOnePerson = shuffle(combatants.filter(c => c.hp > 0 && c.belongsToTeam === "one"));
  //   if (randomAliveTeamOnePerson.length) {
  //
  //     const arena = combatants.find(c => c.belongsToTeam === "arena");
  //     const target = randomAliveTeamOnePerson[0];
  //     return {
  //       submission: [
  //         {
  //           actionId: "speak",
  //           casterId: arena.id,
  //           targetIds: [target.id],
  //           mergeData: {
  //             dialogContent: [
  //               "Server security system engaged!"
  //             ]
  //           }
  //         },
  //         {
  //           actionId: "script_slice", //TODO: change this to zapped by Arena special move
  //           casterId: target.id,
  //           targetIds: [target.id],
  //         },
  //       ],
  //       updatedBattleStoryPoints: battleStoryPoints
  //     }
  //   }
  // }

  //Maybe submit something custom, OR just use regularDecision which goes through the usual decision class
  //console.log('default to this', regularDecision)
  return {
    submission: regularDecision,
    updatedBattleStoryPoints: battleStoryPoints
  }
}
