import BattleActions from "../battle-data/battle-actions-data";
import { getItemSortValue } from "../../helpers/getItemSortValue";

export function getArenaItemsMenu(combatant, options = {}) {

  const actions = options.allActions || BattleActions;

  let menu = {};
  let map = [];

  groupItemsIntoQuantities(combatant.items).sort((a, b) => {
    return getItemSortValue(a.actionId) > getItemSortValue(b.actionId) ? 1 : -1;
  }).forEach((groupedItem, index) => {
    const key = `item${index}`;
    const model = actions[groupedItem.actionId];
    const quantity = groupedItem.quantity;

    menu[key] = {
      label: function() {

        //Maybe abbreviate item name
        let name = model.name;
        if (name === "Team Danger Boost" && quantity > 1) {
          name = "Team Dngr Boost"
        }
        if (name === "Team Battery Pack" && quantity > 1) {
          name = "Team Battery Pk"
        }
        if (name === "Full Battery Pack" && quantity > 1) {
          name = "Full Battery Pk"
        }

        return quantity > 1 ? `${name} x${quantity}` : `${name}`
      }(),
      description: model.description,
      icon: "item",
      quantity: groupedItem.quantity,
      instanceId: groupedItem.instanceId,
      handler() {
        return {
          stageInstanceId: groupedItem.instanceId,
          stageActionId: groupedItem.actionId,
          goToMenuId: "targeting"
        };
      }
    };
    map.push([key]);
  });

  menu[`back`] = {
    handler() {
      return {
        goToMenuId: "home"
      };
    }
  };

  return {
    menu,
    map,
    backOptionId: "back"
  };
}

export function groupItemsIntoQuantities(arrayOfItems = []) {
  let payload = {};
  arrayOfItems.forEach(item => {
    payload[item.actionId] = payload[item.actionId] || [];
    payload[item.actionId].push(item.instanceId || "?");
  });
  return Object.keys(payload).map(actionId => {
    return {
      actionId,
      quantity: payload[actionId].length,
      instanceId: payload[actionId][0]
    };
  });
}