import {chapter} from "../ChapterGetter";

export function shouldAddCompoundStoryPoint(compoundModel={}, currentStoryPoints) {

  const {gainStoryPoint, neededMembers, storyPointMembers} = compoundModel;

  //Bail here if we already have this SP
  if (currentStoryPoints.includes(gainStoryPoint)) { return null }

  //Support a list of MUST HAVES for a compound story point. EX: you must have "a" and 2 of ["b","c", or "d"]
  compoundModel.requiredStoryPointMembers = compoundModel.requiredStoryPointMembers || [];

  const hasRequiredStoryPointMembers = compoundModel.requiredStoryPointMembers.every(sP => {
    return currentStoryPoints.includes(sP);
  });

  //Figure out if we have enough to match
  const neededMembersAcquired = storyPointMembers.filter(needed => currentStoryPoints.includes(needed));

  if (neededMembersAcquired.length >= neededMembers && hasRequiredStoryPointMembers) {
    return gainStoryPoint
  }
  return null
}


export function getAddedCompoundStoryPoints(currentStoryPoints) {
  //Map each compound story point with `shouldAddCompoundStoryPoint` which will be the point name or no
  return (chapter.get().compoundStoryPoints || []).map(compound => {
    return shouldAddCompoundStoryPoint(compound, currentStoryPoints)
  }).filter(result => Boolean(result)); //filter out of null stuff
}