import BattleActions from "../Battle7/battle-data/battle-actions-data";
import Upgrades from '../Battle7/battle-data/Upgrades'

export function getLetterStreamFromArray(array = [], config = {}) {

  //These are frames that are waited after revealing the character to reveal the next one.
  const options = {
    crawlSpeed: 15,
    slowSpeed: 7,
    regularSpeed: 4,
    fastSpeed: 3,
    lightningSpeed: 1,
    theme: "default",
    ...config
  };

  let payload = [];

  array.forEach(str => {

    let speed = options.regularSpeed;
    let theme = options.theme;

    //determine stuff about the string. Maybe replace stuff.
    let useString = str;


    if (options.playerData) {
      //Swap in main character name
      if (useString.includes("@@PLAYERNAME@@")) {
        const hero = options.playerData.acquiredMembers.find(m => m.id === "hero");
        if (hero) {
          useString = useString.replace("@@PLAYERNAME@@", hero.name || "Hero");
        }
      }
      if (useString.match(/@@crewmember_/)) {
        //Swap in a crew member's name
        let memberId = useString.match(/@@(.*)@@/).pop().replace("crewmember_", "");
        const personMatch = options.playerData.acquiredMembers.find(m => m.id === memberId);
        if (personMatch) {
          useString = useString.replace(/@@(.*)@@/, personMatch.name);
        } else {
          useString = useString.replace(/@@(.*)@@/, "???"); //This would be a bug if it ever showed up... you shouldn't reference a member name that you don't have?
        }
      }
    }

    //Swap in New Attack message via its ID
    if (useString.includes("NEW_SCRIPT::")) {
      const key = useString.replace("NEW_SCRIPT::", "");
      const model = BattleActions[key];
      if (model) {
        const word = key.match(/super_/) ? "SUPER" : "BATTLE";
        useString = `[FAST]** NEW ${word} SCRIPT: ${model.name} - ${model.description}. **`;
      }
    }

    //Swap in New Upgrade message via its ID
    if (useString.includes("NEW_UPGRADE::")) {
      const key = useString.replace("NEW_UPGRADE::", "");
      const model = Upgrades[key];
      if (model) {
        useString = `[FAST]** NEW LAPTOP UPGRADE: ${model.name} - ${model.description}. **`;
      }
    }

    if (useString.match(/^\[LIGHTNING\]/i)) {
      useString = useString.replace("[LIGHTNING]", "");
      speed = options.lightningSpeed;
    }
    if (useString.match(/^\[FAST\]/i)) {
      useString = useString.replace("[FAST]", "");
      speed = speed = options.fastSpeed;
    }
    if (useString.match(/^\[SLOW\]/i)) {
      useString = useString.replace("[SLOW]", "");
      speed = options.slowSpeed;
    }
    if (useString.match(/^\[CRAWL\]/i)) {
      useString = useString.replace("[CRAWL]", "");
      speed = options.crawlSpeed;
    }

    if (useString.match(/@@pause_/)) {
      speed = Number(useString.match(/\d+/g)[0]);
      useString = "`"; //special character for non-visible effect
    }

    useString.split("").forEach(character => {
      payload.push({
        character: character,
        delayFramesAfter: speed,
        theme: theme
      });
    });
  });

  return payload;
}