import {shuffle} from "../../../helpers/shuffle";

export function getViciousDecision({casterModel, teamMembers, enemies, enemyItems}) {

  //Vicious = attack the enemy with least HP
  const myAttackIds = casterModel.attacks.map(a => a.actionId);
  let myViciousAttacks = [
    "script_slice",
    "script_foreach",
    "script_foreach-mk-ii",
    "script_slice-mk-ii",
    "script_slice-mk-iii",
    "script_promise",
    "script_scope-bomb"
  ].filter(key => {
    return myAttackIds.includes(key);
  });

  if (!myViciousAttacks.length) { return null }
  
  //Automatically pick "scope-bomb" if there is a Deadline in the mix
  const deadlineEnemy = enemies.find(e => e.status === "deadline" && e.hp > 0);
  if (myViciousAttacks.find(c => c === "script_scope-bomb") && deadlineEnemy) {
    return {
      actionId: "script_scope-bomb",
      casterId: casterModel.id,
      targetIds: [deadlineEnemy.id]
    }
  }

  const aliveEnemyWithLeastHp = enemies.filter(e => e.hp > 0).sort((a,b) => a.hp > b.hp);

  const actionId = shuffle(myViciousAttacks)[0];

  //Promise needs to target all enemies, not just one.
  const targetIds = actionId === "script_promise" ? enemies.map(e=>e.id) : [ aliveEnemyWithLeastHp[0].id ];

  return {
    actionId: actionId,
    casterId: casterModel.id,
    targetIds: targetIds
  };
}