export function getAvailableBuyablesFromStoryPoints({ dataset, acquiredStoryPoints}) {
  const allBuyables = dataset || actualData;
  let payload = [];
  Object.keys(allBuyables).forEach(requiredStoryPoint => {
    if (acquiredStoryPoints.includes(requiredStoryPoint)) {
      payload = [
        ...allBuyables[requiredStoryPoint],
        ...payload
      ]
    }
  });
  return payload;
}

const actualData = {
  //NOTE TO SELF: Please see `MasterTDCActionUnlockSheet` in Dropbox before editing. It contains a worldview of when you get what.
  "market-JRQ-1": [
    {type: "attack", id: "script_troll", price: 70, },
    {type: "attack", id: "script_headphones", price: 70, },
    {type: "item", id: "item_battery-pack", price: 10, },
    {type: "item", id: "item_attack-sticker", price: 15, },
    {type: "item", id: "item_defense-sticker", price: 15, },
    {type: "item", id: "item_slippery-sticker", price: 15, },

    {type: "upgrade", id: "upgrade_item-turn", price: 100, }
  ],
  "market-JRQ-2": [
    {type: "item", id: "item_heal-lag", price: 17, },
    {type: "attack", id: "script_copy-paste", price: 80, },
    {type: "upgrade", id: "upgrade_maximize-loops", price: 100, },
  ],
  "market-MTG-1": [
    {type: "item", id: "item_heal-nervous", price: 24, },
    {type: "attack", id: "script_bug", price: 90, },
    {type: "upgrade", id: "upgrade_perfect-accuracy", price: 90, },
  ],
  "market-MTG-2": [
    {type: "attack", id: "script_block", price: 105, },
    {type: "upgrade", id: "upgrade_minimize-loops", price: 100, },
  ],

  "market-SHAW-1": [
    {type: "attack", id: "script_ddos", price: 125, },
    {type: "item", id: "item_danger-boost", price: 30, },
    {type: "item", id: "item_revive-pack", price: 30, },
    {type: "item", id: "item_heal-hacked", price: 20, },
  ],
  "market-TICKETS-1": [
    {type: "attack", id: "script_dangerous", price: 125, },
    {type: "attack", id: "script_clean", price: 125, },
    {type: "item", id: "item_full-revive-pack", price: 40, },
    {type: "item", id: "item_full-battery-pack", price: 40, },
    {type: "item", id: "item_heal-memory-leak", price: 20, },
    {type: "upgrade", id: "upgrade_defense-lagging", price: 100, },
  ],
  "market-TICKETS-2": [
    {type: "attack", id: "super_rage-quit", price: 150, },
    {type: "attack", id: "super_team-revive", price: 150, },
    {type: "upgrade", id: "upgrade_recover-focus", price: 100, },
  ],

  "market-CONF-1": [
    {type: "attack", id: "script_deadline", price: 150, },
    {type: "attack", id: "script_frail", price: 150, },
    {type: "attack", id: "script_curl-mk-ii", price: 150, },
    {type: "attack", id: "script_thrash", price: 150, },
    {type: "attack", id: "super_team-foreach", price: 250, },
    {type: "attack", id: "super_promise-all", price: 250, },

    {type: "upgrade", id: "upgrade_no-promises", price: 120, },
    {type: "upgrade", id: "upgrade_defense", price: 200, },

    {type: "item", id: "item_team-battery-pack", price: 40, },
    {type: "item", id: "item_heal-disabled", price: 30, },
    {type: "item", id: "item_heal-fire", price: 30, },
  ],

  "market-HWY-1": [
    {type: "attack", id: "script_slice-mk-ii", price: 150, },
    {type: "attack", id: "script_foreach-mk-ii", price: 150, },
    {type: "attack", id: "super_wildfire", price: 350, },
    {type: "attack", id: "super_intimidate-all", price: 350, },
    {type: "item", id: "item_team-danger-boost", price: 55, },
    {type: "item", id: "item_heal-anything", price: 40, },
  ]

};