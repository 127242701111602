//WATCH OUT: This is not the source of truth. Please copy over from DS2017/AllCombatants
//This is just for dev testing
export default [
  {
    id: "enemyA",
    belongsToTeam: "two",
    //
    //useIcon: "hacker",
    name: "Bully",
    class: "dps",
    level: 1,
    skinId: "thug1",
    bonusXp: 16,
    playerVictoryMessage: "What in the... ?",
    useScriptedDecisionFn: "bully", //comes with scripted moves
    attacks: [
      {actionId: "script_slice"}
    ],
    items: [
      {actionId: "item_attack-sticker"} //this is so you can steal it.
    ],
    dropsCredits: 25,
    playerWontMiss: true
  }
]