import React from 'react'

const RunAnimation = ({pixelSize, widthOfSpriteSheet, onDone, frames, duration, renderPaths, left, top}) => {
  return (
    <div
      style={{
        position: "absolute",
        overflow: "hidden",
        width: pixelSize * 32,
        height: pixelSize * 32,
        left,
        top,
      }}>
      <svg onAnimationEnd={() => { onDone(); }}
           style={{
             width: (pixelSize * widthOfSpriteSheet),
             display: "block",
             animation: `svg_run-animation  ${duration} steps(${frames}) forwards`
           }} xmlns="http://www.w3.org/2000/svg" viewBox={`0 -0.5 ${widthOfSpriteSheet} 32`} shapeRendering="crispEdges">
        <style>
          {`@keyframes svg_run-animation { 0% { transform: translate3d(0,0,0) } 100% { transform: translate3d(-100%,0,0) }}`}
        </style>
        {renderPaths()}
      </svg>
    </div>
  );
};

export default RunAnimation;