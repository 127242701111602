import React from 'react'
import ScrollingMenuOptionLine from './ScrollingMenuOptionLine'
import BoxDivBody from "../interface/BoxDivBody";
import BoxDiv from '../interface/BoxDiv'
import {getNextScrollingOptionPosition} from './getNextScrollingOptionPosition'
import {getScrollingDownAndUpArrows} from "./getScrollingDownAndUpArrows";
import Icon from '../interface/Icon'
import ArrowMenuOption from '../ArrowMenu/ArrowMenuOption'

class ScrollingOptionsList extends React.Component {

  constructor(props) {
    super(props);
    this.flatOptions = props.optionsMap.length ? props.optionsMap.reduce((row1, row2) => [...row1, ...row2]) : [];

    if (props.ignoreOptionIds) {
      this.flatOptions = this.flatOptions.filter(id => !props.ignoreOptionIds.includes(id))
    }


    const optionsLimit = props.optionsLimit || 99;


    let initialVisible = this.flatOptions.filter((d, i) => i < optionsLimit);

    if (props.optionsMinimum && initialVisible.length < props.optionsMinimum) {
      while (initialVisible.length < props.optionsMinimum) {
        initialVisible.push("PLACEHOLDER")
      }
    }

    this.state = {
      visibleOptions: initialVisible
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.selectedOptionId !== this.props.selectedOptionId) {

      const newVisibleOptions = getNextScrollingOptionPosition(
        previousProps.selectedOptionId,
        this.props.selectedOptionId,
        this.state.visibleOptions,
        this.flatOptions
      );
      this.setState({
        visibleOptions: newVisibleOptions
      })
    }
  }

  render() {
    const {handlers, optionModels, pixelSize, selectedOptionId, pixelWidth} = this.props;
    const {up, down} = getScrollingDownAndUpArrows(this.state.visibleOptions, this.flatOptions);

    return (
      <BoxDiv pixelSize={pixelSize} style={{
        width: pixelWidth ? pixelSize * pixelWidth : "100%"
      }}>
        <BoxDivBody pixelSize={pixelSize}>
          {
            this.state.visibleOptions.map((id, index) => {


              if (id === "PLACEHOLDER") {
                //Stub in an empty div to keep the parent open. This is only for "minimum" page options
                return (
                  <div style={{height: pixelSize * 16}} key={index} />
                )
              }

              const model = optionModels[id];
              if (!model) { return null}
              const isDisabled = model.checkUnavailable && model.checkUnavailable();
              const viewBag = model.viewBag || {};
              return (
                <ArrowMenuOption
                  key={id}
                  id={id}
                  handlers={handlers}
                  isInCurrentMenu={typeof this.props.isInCurrentMenu !== "undefined" ? this.props.isInCurrentMenu : true}
                >
                  {
                    this.props.customOptionRender && this.props.customOptionRender({
                      isDisabled: isDisabled,
                      isSelected: selectedOptionId === id,
                      ...viewBag
                    })
                  }
                  {
                    //Default
                    !this.props.customOptionRender && (
                      <ScrollingMenuOptionLine
                        pixelSize={pixelSize}
                        text={model.label}
                        icon={model.icon}
                        renderCustomOptionTheme={this.props.renderCustomOptionTheme}
                        isDisabled={isDisabled}
                        isSelected={selectedOptionId === id}
                        renderOptionRight={this.props.renderOptionRight || null}
                        {...viewBag}
                      />
                    )
                  }
                </ArrowMenuOption>
              )
            })
          }
          {
            up && (
              <div
                style={{
                  position: "absolute",
                  right: -pixelSize * 13,
                  top:-pixelSize,
                  cursor:"pointer"
                }}
                onClick={() => {
                  const oneUpSelectedIndex = this.flatOptions.indexOf(selectedOptionId) - 1;
                  const newOptionId = this.flatOptions[oneUpSelectedIndex];
                  if (this.flatOptions[oneUpSelectedIndex]) {
                    handlers.setActive(newOptionId);
                  }
                }}>
                <Icon
                  pixelSize={pixelSize}
                  category="menuUpButton"
                />
              </div>
            )
          }
          {
            down && (
              <div
                style={{
                  position: "absolute",
                  right: -pixelSize * 13,
                  bottom:-pixelSize,
                  cursor:"pointer"
                }}
                onClick={() => {
                  const oneUpSelectedIndex = this.flatOptions.indexOf(selectedOptionId) + 1;
                  const newOptionId = this.flatOptions[oneUpSelectedIndex];
                  if (this.flatOptions[oneUpSelectedIndex]) {
                    handlers.setActive(newOptionId);
                  }
                }}>
                  <Icon
                    pixelSize={pixelSize}
                    category="menuDownButton"
                  />
              </div>
            )
          }
        </BoxDivBody>
      </BoxDiv>
    );
  }
}

export default ScrollingOptionsList;