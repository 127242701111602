import React from 'react'

class TextLineBlippyIndicator extends React.Component {

  render() {
    const {pixelSize, status} = this.props;
    if (!status) { return null }
    return (
      <div style={{
        position: "absolute", right:0, bottom:0, width:pixelSize*3, height:pixelSize*3, background: "#39393b",
        marginRight: pixelSize * 3,
        marginBottom: pixelSize * 2,
        animation: status === "blinking" ? "blink 0.9s steps(2, start) infinite" : "none"
      }}/>
    );
  }
}

export default TextLineBlippyIndicator;