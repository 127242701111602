import React from 'react';

export default (props) => {

  return (
    <header style={{
      height: 60,
      fontSize: "1.15em",
      fontWeight: "700",
      display: "flex",
      background: "#fff"
    }}>
      <Tab
        href="#createAccount"
        text="Create Account"
        handler={() => {
          props.handleChangeViewId("createAccount")
        }}
        isActive={props.viewId === "createAccount"}
      />
      <Tab
        href="#login"
        text="Log In"
        handler={() => {
          props.handleChangeViewId("login")
        }}
        isActive={props.viewId === "login"}
      />
    </header>
  )
}

const Tab = props => {

  const tabStyle = {
    width: "50%",
    textAlign: "center",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    color: props.isActive ? "#4A90E2" : "#7C7C7C",
    borderBottom: props.isActive ? "4px solid #4A90E2" : "4px solid rgba(255,255,255,0)"
  };
  return (
    <a style={tabStyle}
       href={props.href} onClick={(e) => {
      e.preventDefault();
      props.handler()
    }}>
      {props.text}
    </a>
  )
};