import React from 'react'
import {createInitialCombatantFromConfig, createInitialBattleItemList} from "./createInitialCombatantFromConfig";
import Arena from './Arena'
import BattleModel from './BattleModel'
import {formatEventList, getEventStepsFromAction, mapSubmissionIdsToRoles} from './getEventStepsFromAction'
import {mergeChangesIntoCombatants} from './mergeChangesIntoCombatants'
import {getNewTeamItems} from './getNewTeamItems'
import {createSummary} from './createSummary'
import './battle.css'
import AiPersonality from "./ai-decisions/AiPersonality";
import getScriptedBattleDecision from "./ai-decisions/ScriptedBattleDecision"
import {getReplacedSubmission} from './getReplacedSubmission'
//import {getRegularScriptIds} from "./battle-data/battle-actions-data";
import {waitFrames} from "../helpers/waitFrames";
import Arenas from './Arenas'

class ArenaContainer extends React.Component {

  constructor(props) {
    super(props);
    //Use data from editor form or the default fixture

    let initialCombatants = props.initialCombatants;
    //add the arena
    initialCombatants = [
      {
        id: "arenaThingA",
        name: "Arena Member 1",
        belongsToTeam: "arena",
        //arena personality:
        useScriptedDecisionFn: Arenas[props.arenaId].useScriptedDecisionFn || null
      },
      ...initialCombatants
    ];

    const rawTeamOneItems = initialCombatants.filter(c => c.belongsToTeam === "one").map(c => c.items || []).reduce((a, b) => [...a, ...b]);
    const rawTeamTwoItems = initialCombatants.filter(c => c.belongsToTeam === "two").map(c => c.items || []).reduce((a, b) => [...a, ...b]);

    this.state = {
      waitingOnCombatantToUseMenu: null,
      combatants: initialCombatants.map(c => createInitialCombatantFromConfig(c)),
      eventSteps: [],
      battleStoryPoints: [],
      queuedSubmissions: [], //maybe auto submit these before asking the system for any others
      items: {
        one: createInitialBattleItemList(rawTeamOneItems),
        two: createInitialBattleItemList(rawTeamTwoItems),
      },
      turnBlips: {}
    }
  }

  componentDidMount() {

    window.getCombatantState = () => {
      console.table(this.state.combatants)
    };

    const battleListener = (signal) => {
      //If we have any queued, ignore everything else and just do this
      if (this.state.queuedSubmissions.length) {
        const firstSubmission = {...this.state.queuedSubmissions[0]};
        this.setState(state => ({
          queuedSubmissions: state.queuedSubmissions.filter((d, i) => i > 0),
        }), () => {
          this.submitAction(firstSubmission)
        });
        return;
      }

      const {combatants, battleStoryPoints} = this.state;
      const {type, combatantId} = signal;
      const subject = combatants.find(c => c.id === combatantId);

      //Make sure all combatants are reset from stepping forward
      this.setState(state => {
        return {
          turnBlips: this.battle.getTurnBlips(),
          combatants: state.combatants.map(c => ({
            ...c,
            isSteppedForward: false,

            //Assign a winning team value to combatants if the battle is over
            winningTeam: type === "battle-over" ? signal.winningTeam : null
          }))
        }
      }, () => {
        if (type === "battle-over") {
          //Fire some side effect to end the battle here...
          //console.log('battle over! ready for Results popup!', signal.winningTeam);
        }
      });

      if (type === "freeWill") { //"freeWill" = combatant gets to choose whatever option they want (still true for regular turns, bonus turns, deadlines, etc)
        //If this is a player controlled person, give them the form!
        if (subject.isPlayerControlled) {
          //Set state / Change the menu flag to this ID... so the form renders... blah blah
          this.setState({
            waitingOnCombatantToUseMenu: combatantId
          })
        } else {

          waitFrames(5, () => {

            //Create a decision for the Bot (or Arena)
            const personality = new AiPersonality({
              combatantId,
              allCombatants: this.state.combatants,
              teamOneItems: this.state.items.one,
              teamTwoItems: this.state.items.two,
            });
            const decision = personality.getDecision();


            if (decision.actionId !== "noop") {
              //Step the chooser forward (if not a noop)
              this.setState(state => {
                return {
                  combatants: state.combatants.map(c => {
                    if (subject.id === c.id) {
                      return {
                        ...c,
                        isSteppedForward: true
                      }
                    }
                    return c;
                  })
                }
              });
            }

            waitFrames(17, () => {

              //A combatant may opt in to using a scripted battle personality.
              //We provide the originally made decision in case the scripted battle calls for BAU behavior
              if (subject.useScriptedDecisionFn) {
                const {submission, updatedBattleStoryPoints} = getScriptedBattleDecision(
                  subject.useScriptedDecisionFn, combatants, battleStoryPoints, decision, this.battle.roundNumber, subject
                );

                //Allow multiple submissions in the form of an array
                if (Array.isArray(submission)) {
                  //console.log('multi submissions!', submission);

                  this.battle.setQueuedSubmissions(submission.length - 1)

                  this.setState({
                    queuedSubmissions: submission.filter((d, i) => i > 0), //Save the others in state:
                    battleStoryPoints: updatedBattleStoryPoints
                  });

                  //Submit the first one.
                  this.submitAction(submission[0])
                  return;
                }


                //update battle story points
                this.setState({battleStoryPoints: updatedBattleStoryPoints});

                //submit the decision
                this.submitAction(submission)
                return;
              }

              //Default to simply using the decision.
              this.submitAction(decision)
            });
          });
        }
      }
      if (type === "automaticAction") {
        this.submitAction({
          actionId: signal.actionId,
          casterId: signal.combatantId,
          targetIds: signal.targetIds,
          instanceId: signal.instanceId || null,
          mergeData: signal.mergeData || {},

        })
      }
    };

    //Init the battle model
    this.battle = new BattleModel(this.state.combatants, battleListener);

    this.setState({
      turnBlips: this.battle.getTurnBlips()
    });

    //Init the first step!
    this.battle.next();
  }

  submitAction = (initialSubmission) => {

    const submission = getReplacedSubmission({
      initialSubmission,
      combatants: this.state.combatants
    }); //returns either a replacement or the initial again

    const {actionModel, casterModel, targetModels} = mapSubmissionIdsToRoles({
      actionId: submission.actionId,
      casterId: submission.casterId,
      targetIds: submission.targetIds,
      combatants: this.state.combatants
    });

    const eventSteps = formatEventList(
      getEventStepsFromAction({
        casterModel,
        targetModels,
        actionModelRaw: actionModel,
        allUpgrades: {},
        actionMergeData: submission.mergeData,
        teamOneItems: this.state.items.one,
        teamTwoItems: this.state.items.two,
        instanceId: submission.instanceId,
      })
    );

    //Track usage of team one items (so we can remove them later)
    if (casterModel.belongsToTeam === "one" && submission.instanceId) {
      this.props.handleTrackTeamOneItemUsage(submission.instanceId);
    }

    //Create a summary object from the description
    const summary = createSummary(submission, this.state.combatants, eventSteps);
    this.battle.updateSummary(summary);

    this.battle.addRoundSubmission(submission.casterId);

    //Pipe the event steps into the Arena
    this.setState({
      turnBlips: this.battle.getTurnBlips(),
      waitingOnCombatantToUseMenu: null,
      eventSteps
    }, () => {
      if (eventSteps.length === 0) {
        //console.log('none to see')
        this.handleSeenAllEvents()
      }
    })
  };




  handleSubmitBail = () => {
    this.setState({
      waitingOnCombatantToUseMenu: null, //unmount the submission menu
      //Mark a winning team so the ResultMessage will appear.
      combatants: this.state.combatants.map(c => ({...c, winningTeam: "bail"}))
    })
  };

  handleSeenAllEvents = () => {
    this.setState({
      eventSteps: []
    }, () => {
      this.battle.updateCombatants(this.state.combatants);
      this.battle.next()
    })
  };

  handleCombatantStateChange = (listOfChanges = [], callback) => {
    this.setState(state => {
      return {
        combatants: mergeChangesIntoCombatants(state.combatants, listOfChanges)
      }
    }, () => {
      callback && callback() //optional callback
    })
  };

  handleItemsStateChange = (instruction = {}) => {
    const {newTeamOneItems, newTeamTwoItems} = getNewTeamItems({
      initialTeamOneItems: this.state.items.one,
      initialTeamTwoItems: this.state.items.two,
      instruction
    });

    //console.log(newTeamOneItems, newTeamTwoItems)
    this.setState({
      items: {
        one: newTeamOneItems,
        two: newTeamTwoItems,
      }
    })
  };

  handleSpawnCombatants = (spawnCombatants = []) => {
    const combatantsToAdd = spawnCombatants.map(c => createInitialCombatantFromConfig(c));
      this.setState({
        combatants: [...this.state.combatants, ...combatantsToAdd]
      })


  };
  //TODO: I want to keep these concepts around, just in case
  //I think some kind of new EventStep type would probably just call these methods.
  // devHandleAddEnemy = () => {
  //   const enemy = {
  //     id: `battler${Date.now()}`,
  //     name: "New Enemy",
  //     belongsToTeam: "two",
  //     hp: 100,
  //     maxHp: 100,
  //   };
  //   this.setState({
  //     combatants: [...this.state.combatants, createInitialCombatantFromConfig(enemy)]
  //   })
  // };


  handleScheduleEvent = (roundsFromNow, combatantId, event) => {
    this.battle.addScheduledEvent(roundsFromNow, combatantId, event);
  };

  handleTransitionOutOfBattle = (winningTeam) => {
    let resultType = winningTeam === "one" ? "win" : "lose";
    this.props.handleBattleExit(resultType, this.state.combatants, this.state.items.one);
  };


  render() {
    return (
      <React.Fragment>
        <Arena
          pixelSize={this.props.pixelSize}
          arenaId={this.props.arenaId}

          waitingOnCombatantToUseMenu={this.state.waitingOnCombatantToUseMenu}
          eventSteps={this.state.eventSteps}
          onSeenAllEvents={this.handleSeenAllEvents}

          handleSubmitActionWithForm={this.submitAction}
          handleSubmitBail={this.handleSubmitBail}
          handleCombatantStateChange={this.handleCombatantStateChange}
          handleItemsStateChange={this.handleItemsStateChange}
          handleSpawnCombatants={this.handleSpawnCombatants}
          handleScheduleEvent={this.handleScheduleEvent}

          handleTransitionOutOfBattle={this.handleTransitionOutOfBattle}

          turnBlips={this.state.turnBlips}
          combatants={this.state.combatants}
          teamOneItems={this.state.items.one}
          teamTwoItems={this.state.items.two}
        />
        {
          this.props.handleBattleExit && (process.env.REACT_APP_FIREBASE_CONFIG !== "production") && (
            <div style={{position: "absolute", left: 0, top: 0,}}>
              <button onClick={() => this.handleTransitionOutOfBattle("one")}>Auto Win</button>
              <button onClick={() => this.handleTransitionOutOfBattle("two")}>Auto Lose</button>
              <button onClick={() => this.handleTransitionOutOfBattle("bail")}>Auto Bail</button> {/* Hrm this is weird now */}
            </div>
          )
        }
      </React.Fragment>
    );
  }
}

//{/*<button onClick={() => {this.battle.next()}}>Next Thing</button>*/}
//{/*<button onClick={this.devHandleAddEnemy}>Add Enemy</button>*/}
//{/*<button onClick={this.devHandleAddFriend}>Add Friend</button>*/}


export default ArenaContainer;