import store from "../init/store";
import {chapter} from "../ChapterGetter";
import {getInitialMapState} from "./getInitialMapState";

export function getCurrentMapData(flags={}) {
  const mapChangePayload = store.getState().overworld.mapChangePayload || {};
  const rawMap = chapter.data.maps[store.getState().overworld.currentMapId];

  const {acquiredStoryPoints, acquiredMembers} = store.getState().playerData;



  return getInitialMapState(rawMap, {
    ...mapChangePayload,
    ...flags,
    acquiredStoryPoints,
    acquiredMembers,
    mergeInteractives: store.getState().overworld.returnFromBattleInteractives || [],
  });
}