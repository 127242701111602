export function getMergeInteractivesAfterBattleResult(properties) {
  let payload = properties.interactives || [];
  const {steppedIntoSpace, resultType} = properties;

  const useLoseBehavior = ["lose", "bail"].includes(resultType);

  return payload.map(person => {

    if (person.id === "hero") {
      const {direction, x, y} = person;

      let mergeX = function() {
        if (useLoseBehavior && steppedIntoSpace && direction === "LEFT") {
          return x+1
        }
        if (useLoseBehavior && steppedIntoSpace && direction === "RIGHT") {
          return x-1
        }
        return x;
      }();

      let mergeY = function() {
        if (useLoseBehavior && steppedIntoSpace && direction === "UP") {
          return y+1
        }
        if (useLoseBehavior && steppedIntoSpace && direction === "DOWN") {
          return y-1
        }
        return y;
      }();

      return {
        ...person,
        x: mergeX,
        y: mergeY
      }
    }

    //default
    return person;
  });
}
