import React from 'react'
import {waitFrames} from "../../../helpers/waitFrames";
import {audioManager} from "../../../audio/audioManager";

//import RunAnimation from './RunAnimation'


class AnimationIterate extends React.Component {

  componentDidMount() {

    const targets = this.props.event.subjects;
    const {casterId} = this.props.event;

    audioManager.playSfx("sfx_loopIterate");
    this.props.handleCombatantStateChange([
      {
        combatantId: casterId,
        absoluteChanges: {
          animation: "characterTada"
        }
      }
    ]);

    waitFrames(7, () => {

      //Start shaking
      targets.forEach(id => {
        this.props.handleCombatantStateChange([
          {
            combatantId: id,
            absoluteChanges: {
              laptopFlash: "pain",
              animation: "characterShake",
            }
          }
        ])
      });
      waitFrames(3, () => {
        audioManager.playSfx("sfx_iteratePain")
      });

      //Stop shaking
      waitFrames(20, () => {
        targets.forEach(id => {
          this.props.handleCombatantStateChange([
            {
              combatantId: id,
              absoluteChanges: {
                laptopFlash: "none",
                animation: null,
              }
            },
            {
              //Stop iterating
              combatantId: casterId,
              absoluteChanges: {
                animation: "none"
              }
            }
          ]);


          //waitFrames(2, () => {
            this.props.onEventDone()
          //})

        });
      });
    });
  }

  render() {
    return null
  }
}

export default AnimationIterate;