import React from 'react'
import './canvas.css'


class MapPersonCanvas extends React.PureComponent {

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  draw() {
    // get canvas context
    const canvas = this.ref.current;
    let ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // load image
    let image = new Image();
    image.onload = function () {
      // draw the image into the canvas
      ctx.drawImage(image, 0, 0);
    };
    image.src = this.props.skinSrc;
  }

  componentDidMount() {
    this.draw();
  }

  componentDidUpdate(oldProps) {
    //re-draw if skin changes
    if (oldProps.skinSrc !== this.props.skinSrc) {
      this.draw();
    }
  }

  render() {
    const {
      pixelSize,
      style
    } = this.props;


    return (
      <canvas
        className="Dpad--NoShow"
        ref={this.ref}
        width={128}
        height={128}
        style={{
          width: (pixelSize * 32) * 4,
          ...style
        }}
      />
    )

  }
}

export default MapPersonCanvas;