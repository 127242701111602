import React from 'react'
import {waitFrames} from "../../../helpers/waitFrames";
import RunAnimation from './RunAnimation'
import {audioManager} from "../../../audio/audioManager";


class AnimationSudoDamage extends React.Component {

  state = {
    hasTriggeredAnimation: false
  };

  componentDidMount() {


    const targets = this.props.event.subjects;

    waitFrames(30, () => {
      this.setState({
        hasTriggeredAnimation: true
      }, () => {

        audioManager.playSfx("sfx_harshSlice");
        targets.forEach(id => {
          this.props.handleCombatantStateChange([
            {
              combatantId: id,
              absoluteChanges: {
                laptopFlash: "pain",
                animation: "characterShake",
              }
            }
          ])
        });
        //Stop shaking
        waitFrames(20, () => {
          targets.forEach(id => {
            this.props.handleCombatantStateChange([
              {
                combatantId: id,
                absoluteChanges: {
                  laptopFlash: "none",
                  animation: null,
                }
              }
            ])
          });
        });
      });
    });
  }

  render() {
    const {pixelSize, event, positions} = this.props;
    return event.subjects.map((subjectId, i) => {
      return (
        <RunAnimation
          key={subjectId}
          pixelSize={pixelSize}
          left={positions[subjectId].leftPosition}
          top={positions[subjectId].topPosition}
          widthOfSpriteSheet={256}
          frames={8}
          duration="0.6s"
          onDone={() => { waitFrames(10, () => {this.props.onEventDone()}) }}
          renderPaths={() => (
            <React.Fragment>
              <path stroke="#970909" d="M13 6h6m24 0h11m18 0h15m15 0h19m10 0h19M13 7h1m4 0h1m24 0h1m4 0h1m4 0h1m18 0h1m4 0h1m4 0h1m3 0h1m15 0h1m4 0h1m4 0h1m3 0h1m3 0h2m9 0h1m4 0h1m4 0h1m3 0h1m3 0h2M13 8h1m1 0h2m1 0h1m24 0h1m4 0h1m4 0h1m18 0h1m4 0h1m4 0h2m1 0h2m15 0h1m4 0h1m4 0h2m1 0h2m1 0h2m1 0h1m9 0h1m4 0h1m4 0h2m1 0h2m1 0h2m1 0h1M13 9h1m4 0h1m24 0h1m4 0h1m4 0h1m18 0h1m4 0h1m4 0h2m1 0h2m15 0h1m4 0h1m4 0h2m1 0h2m1 0h2m1 0h1m9 0h1m4 0h1m4 0h2m1 0h2m1 0h2m1 0h1M13 10h1m1 0h4m24 0h1m1 0h4m1 0h2m1 0h1m18 0h1m1 0h4m1 0h2m1 0h2m1 0h2m15 0h1m1 0h4m1 0h2m1 0h2m1 0h2m1 0h2m1 0h1m9 0h1m1 0h4m1 0h2m1 0h2m1 0h2m1 0h2m1 0h7M13 11h1m1 0h1m27 0h1m1 0h1m2 0h1m1 0h2m1 0h1m18 0h1m1 0h1m2 0h1m1 0h2m1 0h1m3 0h1m15 0h1m1 0h1m2 0h1m1 0h2m1 0h1m3 0h1m1 0h2m1 0h1m9 0h1m1 0h1m2 0h1m1 0h2m1 0h1m3 0h1m1 0h2m1 0h2m4 0h1M13 12h3m27 0h3m2 0h6m18 0h3m2 0h10m15 0h3m2 0h15m9 0h3m2 0h21"/>
              <path stroke="red" d="M163 6h19m13 0h19m13 0h19m-83 1h1m4 0h1m4 0h1m3 0h1m3 0h2m12 0h1m4 0h1m4 0h1m3 0h1m3 0h2m12 0h1m4 0h1m4 0h1m3 0h1m3 0h2m-84 1h1m1 0h2m1 0h1m1 0h2m1 0h2m1 0h2m1 0h2m1 0h1m12 0h1m1 0h2m1 0h1m1 0h2m1 0h2m1 0h2m1 0h2m1 0h1m12 0h1m1 0h2m1 0h1m1 0h2m1 0h2m1 0h2m1 0h2m1 0h1m-84 1h1m4 0h1m4 0h2m1 0h2m1 0h2m1 0h1m12 0h1m4 0h1m4 0h2m1 0h2m1 0h2m1 0h1m12 0h1m4 0h1m4 0h2m1 0h2m1 0h2m1 0h1m-84 1h1m1 0h4m1 0h2m1 0h2m1 0h2m1 0h2m1 0h7m6 0h1m1 0h4m1 0h2m1 0h2m1 0h2m1 0h2m1 0h7m6 0h1m1 0h4m1 0h2m1 0h2m1 0h2m1 0h2m1 0h7m-90 1h1m1 0h1m2 0h1m1 0h2m1 0h1m3 0h1m1 0h2m1 0h2m4 0h1m6 0h1m1 0h1m2 0h1m1 0h2m1 0h1m3 0h1m1 0h2m1 0h2m4 0h1m6 0h1m1 0h1m2 0h1m1 0h2m1 0h1m3 0h1m1 0h2m1 0h2m4 0h1m-90 1h3m2 0h21m6 0h3m2 0h21m6 0h3m2 0h21m-21 2h3m9 0h3m-15 1h1m1 0h2m7 0h2m1 0h1m-15 1h2m1 0h2m5 0h2m1 0h2m-14 1h2m1 0h2m3 0h2m1 0h2m-12 1h2m1 0h2m1 0h2m1 0h2m-40 1h5m25 0h2m1 0h3m1 0h2m-40 1h2m1 0h1m1 0h2m25 0h2m1 0h1m1 0h2m-38 1h2m1 0h2m27 0h2m1 0h2m-38 1h2m1 0h1m1 0h2m25 0h2m1 0h1m1 0h2m-38 1h5m25 0h2m1 0h3m1 0h2m-10 1h2m1 0h2m1 0h2m1 0h2m-12 1h2m1 0h2m3 0h2m1 0h2m-14 1h2m1 0h2m5 0h2m1 0h1m-14 1h1m1 0h2m7 0h3m-14 1h3"/>
              <path stroke="#ff4545" d="M14 7h4m26 0h4m1 0h4m20 0h4m1 0h4m1 0h3m17 0h4m1 0h4m1 0h3m1 0h3m12 0h4m1 0h4m1 0h3m1 0h3M14 8h1m2 0h1m26 0h1m2 0h1m1 0h1m2 0h1m20 0h1m2 0h1m1 0h1m2 0h1m2 0h1m18 0h1m2 0h1m1 0h1m2 0h1m2 0h1m2 0h1m2 0h1m11 0h1m2 0h1m1 0h1m2 0h1m2 0h1m2 0h1m2 0h1M14 9h4m26 0h4m1 0h4m20 0h4m1 0h4m2 0h1m18 0h4m1 0h4m2 0h1m2 0h1m2 0h1m11 0h4m1 0h4m2 0h1m2 0h1m2 0h1M14 10h1m29 0h1m4 0h1m2 0h1m20 0h1m4 0h1m2 0h1m2 0h1m18 0h1m4 0h1m2 0h1m2 0h1m2 0h1m2 0h1m11 0h1m4 0h1m2 0h1m2 0h1m2 0h1m2 0h1M14 11h1m29 0h1m4 0h1m2 0h1m20 0h1m4 0h1m2 0h1m1 0h3m17 0h1m4 0h1m2 0h1m1 0h3m1 0h1m2 0h1m11 0h1m4 0h1m2 0h1m1 0h3m1 0h1m2 0h1m2 0h4"/>
              <path stroke="#fff" d="M164 7h4m1 0h4m1 0h3m1 0h3m15 0h4m1 0h4m1 0h3m1 0h3m15 0h4m1 0h4m1 0h3m1 0h3m-81 1h1m2 0h1m1 0h1m2 0h1m2 0h1m2 0h1m2 0h1m14 0h1m2 0h1m1 0h1m2 0h1m2 0h1m2 0h1m2 0h1m14 0h1m2 0h1m1 0h1m2 0h1m2 0h1m2 0h1m2 0h1m-82 1h4m1 0h4m2 0h1m2 0h1m2 0h1m14 0h4m1 0h4m2 0h1m2 0h1m2 0h1m14 0h4m1 0h4m2 0h1m2 0h1m2 0h1m-82 1h1m4 0h1m2 0h1m2 0h1m2 0h1m2 0h1m14 0h1m4 0h1m2 0h1m2 0h1m2 0h1m2 0h1m14 0h1m4 0h1m2 0h1m2 0h1m2 0h1m2 0h1m-82 1h1m4 0h1m2 0h1m1 0h3m1 0h1m2 0h1m2 0h4m8 0h1m4 0h1m2 0h1m1 0h3m1 0h1m2 0h1m2 0h4m8 0h1m4 0h1m2 0h1m1 0h3m1 0h1m2 0h1m2 0h4m-19 4h1m11 0h1m-12 1h1m9 0h1m-10 1h1m7 0h1m-8 1h1m5 0h1m-6 1h1m3 0h1m-36 1h1m1 0h1m29 0h1m1 0h1m-34 1h1m31 0h1m-34 1h1m1 0h1m29 0h1m1 0h1m-4 1h1m3 0h1m-6 1h1m5 0h1m-8 1h1m7 0h1m-10 1h1m9 0h1m-12 1h1"/>
              <path stroke="#9a0404" d="M45 8h2m3 0h2m22 0h2m3 0h2m23 0h2m3 0h2m22 0h2m3 0h2"/>
            </React.Fragment>
          )}
        />
      )
    })
  }
}

export default AnimationSudoDamage;