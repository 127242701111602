import {DefaultAction, DefaultScript} from "../Action";
import {getAttackDamage, getStatusRemovingFromDamageSteps} from "../../formulas/getAttackDamage";

function createGenericAttack(properties) {
  return {
    ...DefaultAction,
    ...DefaultScript,
    name: properties.name,
    description: properties.description,
    icon: "dps",

    accuracy: properties.accuracy,

    successEventsPerTarget(casterModel, targetModel, allUpgrades) {
      const damage = getAttackDamage(properties.damage, casterModel, targetModel, allUpgrades);

      //Generate event objects by passing in an array of functions
      const preEvents = (properties.preEvents || []).map(fn => fn({casterModel, targetModel}));

      return [
        ...preEvents,
        {
          type: "eventAnimation",
          animationId: "animationGenericPain",
          subjects: [targetModel.id],
          delayFramesAfter: 12,
        },
        //Block step
        targetModel.status === "block" ? {
          type: "eventBlippy",
          stateChanges: [
            {
              combatantId: targetModel.id,
              absoluteChanges: {
                status: "normal"
              },
              message: {
                theme: "battleDamageBlock",
                text: `Damage Absorbed!`
              },
            }
          ],
        } : null,
        {
          type: "eventBlippy",
          stateChanges: [
            {
              combatantId: targetModel.id,
              relativeChanges: {
                hp: damage
              },
              message: {
                theme: "battleNegativeHp",
                text: `${damage} HP`
              },
            }
          ],
          delayFramesAfter: 60
        },
        ...getStatusRemovingFromDamageSteps(casterModel, targetModel, allUpgrades)
      ].filter(d => d)
    }
  }
}

export const Blackberry = createGenericAttack({
  name: "Blackberry",
  description: "Checking email since before you were born, kid!",
  damage: 10,
  accuracy: 96,
  preEvents: [
    function({targetModel}) {
      return {
        type: "eventDialogBox",
        subject: targetModel.id,
        textArray: ["[LIGHTNING]Smacked with an old Blackberry!"],
        haltQueueUntilDone: true,
      }
    }
  ],
  //TODO: some kind of animation support? Can it just pass in the animation that's used? or default to the generic one?
    //UPDATE: Maybe use pre events?

});