import {Component} from 'react';
import {createSingleKeyPressBinding} from "../../helpers/createSingleKeyPressBinding";

class ActionAndPauseKeyHandlers extends Component {

  componentDidMount() {
    this.actionHandler = createSingleKeyPressBinding([13, 32], this.props.handleNewActionKeyPress );
    this.pauseHandler = createSingleKeyPressBinding([27], this.props.handleNewPauseKeyPress );
  }
  componentWillUnmount() {
    let el = document.getElementsByTagName("body")[0];
    [
      ...this.actionHandler,
      ...this.pauseHandler,
    ].forEach(b => {
      el.removeEventListener("keydown", b.keyDownHandler);
      el.removeEventListener("keyup", b.keyUpHandler);
    });
    //console.log('action/pause bindings removed!')
  }

  render() {
    return (
      null
    )
  }
}

export default ActionAndPauseKeyHandlers;