//WATCH OUT: This is not the source of truth. Please copy over from DS2017/AllCombatants
//This is just for dev testing

export default [
  {
    id: "enemyA",
    belongsToTeam: "two",
    level: 27,
    //
    useMusic: "music_middleBoss",
    name: "Drew",
    class: "lead",
    skinId: "drew",

    playerVictoryMessage: "Good game!",
    playerDefeatMessage: "At least you could beat the other final boss?",
    overworldPlayerVictoryMessage: ["You are the real deal!"],
    attacks: [
      {actionId: "script_slice-mk-ii"},
      {actionId: "script_foreach-mk-ii"},
      {actionId: "script_ddos"},
    ],
    items: [
      { actionId: "item_heal-anything" },
      { actionId: "item_heal-anything" },
      { actionId: "item_attack-sticker" },
      { actionId: "item_attack-sticker" },
      { actionId: "item_revive-pack" },
      { actionId: "item_revive-pack" },
    ],
    supers: [
      { actionId: "super_sudo-damage" },
      { actionId: "super_wildfire" },
    ],
    activeUpgradeId: "upgrade_item-turn",
    speedModifier: 4,
    defenseModifier: 6,
    dangerMeter: 50,
    isImmuneToRageQuit: true,
    immunities: ["lag", "nervous"],
    personality: ["cursing", "vicious", "healHp", "positiveStatus", "statBoost"],
  },
  {
    id: "enemyB",
    belongsToTeam: "two",
    level: 27,
    //
    name: "Glenn",
    class: "lead",
    skinId: "glenn",
    attacks: [
      {actionId: "script_slice-mk-ii"},
      {actionId: "script_clean"},
      {actionId: "script_curl-mk-ii"},
      {actionId: "script_inspire"},
    ],
    items: [],
    supers: [
      { actionId: "super_team-deadline" }
    ],
    speedModifier: 8,
    defenseModifier: 7,
    dangerMeter: 100,
    isImmuneToRageQuit: true,
    immunities: ["lag", "nervous"],
    personality: ["vicious", "healHp", "positiveStatus", "statBoost"],
  },
]