import {getOppositeDirection} from "../helpers/getOppositeDirection";

export function injectDynamicValuesOnInteractionArray(interactionArray=[], interactives) {
  return interactionArray.map(event => {

    //Swap player direction
    if (event.type === "characterStand" && event.direction === "[dynamic-face-player]") {
      const hero = interactives.find(i => i.id === "hero");
      if (hero) {
        return {
          ...event,
          direction: getOppositeDirection(hero.direction)
        }
      }
    }
    return event;
  })
}