import React from 'react'
import {waitFrames} from "../../../helpers/waitFrames";
import RunAnimation from './RunAnimation'
import {audioManager} from "../../../audio/audioManager";


class AnimationOpenItem extends React.Component {

  state = {
    // isAnimationFinished: false,
    // isCharacterJumpFinished: false,

    animationsFinished: 0,
    characterJumpsFinished: 0,

  };

  componentDidMount() {
    const targets = this.props.event.subjects;

    audioManager.playSfx("sfx_useItem"); //don't have the perfect sfx for this yet

    waitFrames(30, () => {
      targets.forEach(id => {
        this.props.handleCombatantStateChange([
          {
            combatantId: id,
            absoluteChanges: {
              animation: "characterJump",
            }
          }
        ]);
        waitFrames(30, () => {
          this.props.handleCombatantStateChange([
            {
              combatantId: id,
              absoluteChanges: {
                animation: "none",
              }
            }
          ]);
          this.setState(state => ({characterJumpsFinished: state.characterJumpsFinished+1}))
        })
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const targets = this.props.event.subjects;
    const wasFinished = (
      prevState.animationsFinished === targets.length &&
      prevState.characterJumpsFinished === targets.length
    );
    const nowFinished = (
      this.state.animationsFinished === targets.length &&
      this.state.characterJumpsFinished === targets.length
    );
    if (!wasFinished && nowFinished) {
      this.props.onEventDone()
    }
  }

  render() {
    const {pixelSize, event, positions} = this.props;
    return event.subjects.map((subjectId, i) => {
      return (
        <RunAnimation
          key={subjectId}
          pixelSize={pixelSize}
          left={positions[subjectId].leftPosition}
          top={positions[subjectId].topPosition}
          widthOfSpriteSheet={224}
          frames={7}
          duration="0.5s"
          onDone={() => {
            this.setState(state => ({animationsFinished: state.animationsFinished+1}))
          }}
          renderPaths={() => (
            <React.Fragment>
              <path stroke="#fff" d="M170 3h4m26 0h2m-32 1h4m3 0h4m19 0h2m-63 1h10m21 0h4m3 0h4m-73 1h8m23 0h10m21 0h4m3 0h4m-73 1h8m23 0h10m28 0h4m33 0h1m-76 1h10m-10 1h10m70 0h2m-82 1h10m46 0h1m23 0h2m-82 1h10m-10 1h10m-10 1h10"/>
              <path stroke="#5c4438" d="M75 5h10M75 6h1m8 0h1M75 7h1m8 0h1m-42 3h10m-10 1h1m8 0h1m-10 1h1m8 0h1m-42 2h10m-10 1h1m8 0h1m-10 1h1m8 0h1"/>
              <path stroke="#aaf167" d="M107 5h10m-10 1h1m8 0h1m-10 1h1m8 0h1"/>
              <path stroke="#e7b187" d="M76 6h8m-8 1h8m-40 4h8m-8 1h8m-40 3h8m-8 1h8"/>
              <path stroke="#463025" d="M75 8h1m8 0h1M75 9h1m8 0h1m-10 1h1m8 0h1m-10 1h1m8 0h1m-10 1h1m8 0h1m-42 1h1m8 0h1m22 0h1m8 0h1m-42 1h1m8 0h1m-10 1h1m8 0h1m-10 1h1m8 0h1m-42 1h1m8 0h1m22 0h1m8 0h1m-42 1h1m8 0h1m22 0h1m8 0h1m-42 1h1m8 0h1m-10 1h1m8 0h1m-10 1h1m8 0h1m-10 1h1m8 0h1"/>
              <path stroke="#ffd7b8" d="M76 8h8m-40 5h8m-40 4h8"/>
              <path stroke="#99e550" d="M107 8h1m8 0h1m-10 1h1m8 0h1m-10 1h1m8 0h1m-10 1h1m8 0h1m-10 1h1m8 0h1m-10 1h1m8 0h1"/>
              <path stroke="#ecf0e9" d="M108 8h8m-8 1h8m-8 1h8m-8 1h8m-8 1h8m-8 1h8"/>
              <path stroke="#c17e56" d="M76 9h5m1 0h1m-7 1h6m1 0h1m-8 1h8m-8 1h8m-8 1h8m-40 1h5m1 0h1m-7 1h6m1 0h1m-8 1h8m-8 1h8m-40 1h5m1 0h1m25 0h8m-40 1h6m1 0h1m-8 1h8m-8 1h8m-8 1h8"/>
              <path stroke="#3f3f74" d="M81 9h1m1 0h1m-2 1h1m-34 4h1m1 0h1m-2 1h1m-34 3h1m1 0h1m-2 1h1"/>
              <path stroke="#1f1713" d="M75 14h10m-42 5h10m-42 4h10"/>
              <path stroke="#3a5024" d="M107 14h10"/>
            </React.Fragment>
          )}
        />
      )
    })
  }
}

export default AnimationOpenItem;