import {DefaultAction, DefaultScript} from "../Action";
import {getDangerMeterReset} from "../../formulas/getDangerMeterReset";

export default {
  ...DefaultAction,
  ...DefaultScript,
  name: "Sql Drop",
  description: "Deletes all enemy items",
  targetingType: "all-enemies",
  icon: "super-disrupt",
  targetTeamMustHaveItems: true,

  teamItemMoveMethods: true,

  shouldFailOnTargetTeam({casterModel, friendlyItems, enemyItems}) {
    return enemyItems.length === 0; //TODO: fail reason - no items?
  },

  wholeTeamFailEvent({casterModel, targetModels}) {
    return [
      {
        type: "eventBlippy",
        stateChanges: targetModels.map(targetModel => {
          return {
            combatantId: targetModel.id,
            message: {
              text: `NO ITEMS!`
            },
          }
        }),
      },
    ]
  },

  successEventOnTeam({casterModel, friendlyItems, enemyItems, targetModels}) {

    const copy = casterModel.belongsToTeam === "one"
      ? "All enemy items deleted!"
      : "All of your items were deleted!";

    return [
      {
        type: "eventAnimation",
        animationId: "animationSqlInjection",
        subjects: targetModels.filter(t => t.hp > 0).map(t => t.id),
        haltQueueUntilDone: true,
      },

      {
        type: "eventBlippy",
        teamItemChangeInstructions: {
          type: "deleteAll",
          teamId: casterModel.belongsToTeam === "one" ? "two" : "one"
        },
        stateChanges: [],
        delayFramesAfter: 10,
      },
      {
        type: "eventDialogBox",
        textArray: [copy],
        haltQueueUntilDone: true
      },
      getDangerMeterReset({casterModel})
    ]
  }
}