//WATCH OUT: This is not the source of truth. Please copy over from DS2017/AllCombatants
//This is just for dev testing
export default [
  {
    id: "enemyA",
    name: "Knuckle",
    class: "dps",
    level: 21,
    skinId: "serverRed",
    attacks: [
      {actionId: "script_disable"},
      {actionId: "script_hack"},
      {actionId: "script_ddos"},
      {actionId: "script_promise"},
    ],
    useScriptedDecisionFn: "dpsServer",
    items: [
    ]
  },
  {
    id: "enemyB",
    isLeadEnemy: true, //will make the ending messaging point at her (and other misc things)
    name: "Grip",
    class: "disrupt",
    level: 21,
    playerVictoryMessage: "Error: Server Defense failure.",
    playerDefeatMessage: "Operation: Server Defense successful!",
    skinId: "serverPurple",
    useScriptedDecisionFn: "disruptServer",
    attacks: [
      {actionId: "script_push"},
    ],
    items: [
    ],
    immunities: ["lag", "nervous", "memory-leak", "fire", "frail"],
    speedModifier: 2,
    useMusic: "music_richard1"
  },
  {
    id: "enemyC",
    name: "Palm",
    class: "support",
    level: 21,
    skinId: "serverBlue",
    useScriptedDecisionFn: "supportServer",
    attacks: [
      {actionId: "script_push"},
    ],
    items: [
    ],
    personality: ["mischief:2", "positiveStatus:2", "healHp:2"],
    defenseModifier: 5,
  }
].map(e => {
  return {
    ...e,
    belongsToTeam: "two"
  }
})