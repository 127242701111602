import React from "react";

export default class Icon extends React.PureComponent {

  render() {
    /* THIS IS A PURE COMPONENT. DO NOT ACCEPT OBJECTS AS PROPS EVER EVER EVER */
    let { pixelSize, category } = this.props;

    const model = icons[category];

    if (!model) {
      console.warn("icon not found:", category);
      return null;
    }

    const finalStyle = {
      width: pixelSize * model.width,
      height: pixelSize * model.height,
      display: "block"
    };

    return (
      model.svg(finalStyle, this.props)
    );
  }
}

//This is basically just a bucket of misc Icons. It functionally doesn't really do anything except get the SVG

const icons = {
  "dps": {
    width: 5,
    height: 10,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 10" shapeRendering="crispEdges">
          <path stroke="#434343" d="M3 0h1M2 1h2M1 2h1M3 2h1M1 3h1M3 3h1M1 4h1M3 4h1M1 5h1M3 5h1"/>
          <path stroke="#ebf1f1" d="M2 2h1M2 3h1M2 4h1M2 5h1"/>
          <path stroke="#3c091d" d="M0 6h1M4 6h1M1 7h1M3 7h1M1 8h1M3 8h1M2 9h1"/>
          <path stroke="#bb5c5c" d="M1 6h3M2 7h1M2 8h1"/>
        </svg>
      );
    }
  },

  "disrupt": {
    width: 7,
    height: 10,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 7 10" shapeRendering="crispEdges">
          <path stroke="#df7126" d="M1 0h1M0 1h1M2 1h1M1 2h1"/>
          <path stroke="#4e1973" d="M2 0h1M3 1h1M3 2h1M3 3h2M5 4h1M6 5h1M0 6h1M6 6h1M0 7h1M6 7h1M1 8h1M5 8h1M2 9h3"/>
          <path stroke="#fbf236" d="M1 1h1"/>
          <path stroke="#772d95" d="M2 3h1M1 4h1M0 5h1"/>
          <path stroke="#dd6dec" d="M2 4h1M1 5h3M2 6h1"/>
          <path stroke="#8946ad" d="M3 4h2M4 5h2M1 6h1M3 6h3M1 7h5M2 8h3"/>
        </svg>
      );
    }
  },

  "support": {
    width: 6,
    height: 8,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 6 8" shapeRendering="crispEdges">
          <path stroke="#306384" d="M1 0h4M0 1h1M5 1h1"/>
          <path stroke="#dcf9ff" d="M1 1h2"/>
          <path stroke="#62c1ff" d="M3 1h2"/>
          <path stroke="#174867" d="M0 2h1M5 2h1M0 3h1M5 3h1M0 4h1M5 4h1M0 5h1M5 5h1M1 6h1M4 6h1M2 7h2"/>
          <path stroke="#bcf3ff" d="M1 2h2M1 3h2M1 4h2M1 5h2M2 6h1"/>
          <path stroke="#47a9e9" d="M3 2h2M3 3h2M3 4h2M3 5h2M3 6h1"/>
        </svg>
      );
    }
  },

  "super-dps": {
    width: 5,
    height: 10,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 10" shapeRendering="crispEdges">
          <path stroke="#654b0b" d="M3 0h1M2 1h2M1 2h1M3 2h1M1 3h1M3 3h1"/>
          <path stroke="#f9d339" d="M2 2h1M2 5h1"/>
          <path stroke="#fafda4" d="M2 3h1M2 4h1"/>
          <path stroke="#542903" d="M1 4h1M3 4h1M1 5h1M3 5h1M0 6h1M4 6h1M1 7h1M3 7h1M1 8h1M3 8h1M2 9h1"/>
          <path stroke="#f39646" d="M1 6h3M2 7h1M2 8h1"/>
        </svg>
      );
    }
  },
  "super-disrupt": {
    width: 7,
    height: 10,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 7 10" shapeRendering="crispEdges">
          <path stroke="#f9d339" d="M1 0h1M0 1h1M2 1h1M1 2h1"/>
          <path stroke="#542903" d="M2 0h1M3 1h1M3 2h1M3 3h2M5 4h1M6 5h1M0 6h1M6 6h1M0 7h1M6 7h1M1 8h1M5 8h1M2 9h3"/>
          <path stroke="#ffffff" d="M1 1h1"/>
          <path stroke="#654b0b" d="M2 3h1M1 4h1M0 5h1"/>
          <path stroke="#fafda4" d="M2 4h1M1 5h3M2 6h1"/>
          <path stroke="#f39646" d="M3 4h2M4 5h2M1 6h1M3 6h3M1 7h4"/>
          <path stroke="#d07120" d="M5 7h1M2 8h3"/>
        </svg>
      );
    }
  },
  "super-support": {
    width: 6,
    height: 8,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 6 8" shapeRendering="crispEdges">
          <path stroke="#654b0b" d="M1 0h4M0 1h1M5 1h1"/>
          <path stroke="#fafda4" d="M1 1h2"/>
          <path stroke="#e1e649" d="M3 1h2"/>
          <path stroke="#542903" d="M0 2h1M5 2h1M0 3h1M5 3h1M0 4h1M5 4h1M0 5h1M5 5h1M1 6h1M4 6h1M2 7h2"/>
          <path stroke="#f9d339" d="M1 2h2M1 3h2"/>
          <path stroke="#e6bf13" d="M3 2h2M3 3h2"/>
          <path stroke="#f39646" d="M1 4h2M1 5h2M2 6h1"/>
          <path stroke="#d07120" d="M3 4h2M3 5h2M3 6h1"/>
        </svg>
      );
    }
  },


  "lead": {
    width: 7,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 7 9" shapeRendering="crispEdges">
          <metadata>Made with Pixels to Svg https://codepen.io/shshaw/pen/XbxvNj</metadata>
          <path stroke="#73361d" d="M3 0h1M2 1h1M1 2h1"/>
          <path stroke="#ebdeb5" d="M3 1h1M2 2h1M3 4h1M2 5h1"/>
          <path stroke="#4d200e"
                d="M4 1h1M5 2h1M0 3h1M3 3h1M6 3h1M0 4h1M2 4h1M4 4h1M6 4h1M1 5h1M5 5h1M0 6h1M3 6h1M6 6h1M0 7h1M2 7h1M4 7h1M6 7h1M1 8h1M5 8h1"/>
          <path stroke="#e1b42b" d="M3 2h2M1 3h2M4 3h2M1 4h1M3 5h2M1 6h2M4 6h1"/>
          <path stroke="#b28641" d="M5 4h1M5 6h1M1 7h1M5 7h1"/>
        </svg>
      );
    }
  },
  "hacker": {
    width: 7,
    height: 8,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 7 8" shapeRendering="crispEdges">
          <path stroke="#17171f" d="M1 0h5M0 1h1M6 1h1M0 2h1M6 2h1M0 3h1M6 3h1M0 4h1M6 4h1M0 5h1M6 5h1M0 6h2M5 6h2M2 7h3" />
          <path stroke="#574e6c" d="M1 1h1M1 2h2M1 4h1M1 5h2M2 6h1" />
          <path stroke="#2f2f45" d="M2 1h4M3 2h3M3 3h1M3 4h1M5 4h1M3 5h3M3 6h2" />
          <path stroke="#ff5050" d="M1 3h2M4 3h2M2 4h1M4 4h1" />
        </svg>
      );
    }
  },

  "upgrade": {
    width: 7,
    height: 7,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 7 7" shapeRendering="crispEdges">
          <path stroke="#393e62" d="M2 0h3M2 1h1M4 1h1M0 2h3M4 2h3M0 3h1M6 3h1M0 4h3M4 4h3M2 5h1M4 5h1M2 6h3"/>
          <path stroke="#8be94a" d="M3 1h1"/>
          <path stroke="#6abe30" d="M3 2h1M1 3h5M3 4h1M3 5h1"/>
        </svg>
      );
    }
  },

  "upgrade-unused": {
    width: 7,
    height: 7,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 7 7" shapeRendering="crispEdges">
          <path stroke="#4f5056" d="M2 0h3M2 1h1M4 1h1M0 2h3M4 2h3M0 3h1M6 3h1M0 4h3M4 4h3M2 5h1M4 5h1M2 6h3"/>
          <path stroke="#b4b5b9" d="M3 1h1"/>
          <path stroke="#d4d6e3" d="M3 2h1M1 3h5M3 4h1M3 5h1"/>
        </svg>
      );
    }
  },

  "scripts": {
    width: 8,
    height: 8,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 8 8" shapeRendering="crispEdges">
          <path stroke="#1e1e2a" d="M0 0h8M0 1h1m6 0h1M0 2h1m6 0h1M0 3h1m6 0h1M0 4h1m6 0h1M1 5h6M0 6h1m6 0h1M0 7h8"/>
          <path stroke="#31580c" d="M1 1h1m1 0h1m1 0h2M2 2h1m1 0h1m1 0h1M1 3h6M1 4h6"/>
          <path stroke="#99e550" d="M2 1h1m1 0h1M1 2h1m1 0h1m1 0h1"/>
          <path stroke="#39393b" d="M1 6h6"/>
        </svg>
      )
    }
  },

  "item": {
    width: 7,
    height: 7,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 7 7" shapeRendering="crispEdges">
          <path stroke="#8d6423" d="M0 0h7M0 1h1M6 1h1M0 2h1M6 2h1M0 3h1M6 3h1M0 4h1M6 4h1M0 5h1M6 5h1"/>
          <path stroke="#ffdba2" d="M1 1h5"/>
          <path stroke="#ffe6c0" d="M1 2h5"/>
          <path stroke="#d9a250" d="M1 3h2M4 3h1M1 4h3M5 4h1M1 5h5"/>
          <path stroke="#ca7e47" d="M3 3h1M5 3h1M4 4h1"/>
          <path stroke="#462d07" d="M0 6h7"/>
        </svg>
      );
    }
  },

  "battleItem": {
    width: 7,
    height: 7,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 7 7" shapeRendering="crispEdges">
          <path stroke="#89312c" d="M0 0h7M0 1h1M6 1h1M0 2h1M6 2h1"/>
          <path stroke="#bf667a" d="M1 1h5M1 2h5"/>
          <path stroke="#6c1e1e" d="M0 3h1M6 3h1M0 4h1M6 4h1M0 5h1M6 5h1"/>
          <path stroke="#9a3550" d="M1 3h2M4 3h1M1 4h3M5 4h1M1 5h5"/>
          <path stroke="#bc5f6a" d="M3 3h1M5 3h1M4 4h1"/>
          <path stroke="#5a080d" d="M0 6h7"/>
        </svg>
      );
    }
  },

  "enemyItemsLabel": {
    width: 45,
    height: 5,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 45 5" shapeRendering="crispEdges">
          <path stroke="#211f33" d="M0 0h3M4 0h2M8 0h3M12 0h2M15 0h2M18 0h1M20 0h1M24 0h3M28 0h3M32 0h3M36 0h2M39 0h2M42 0h3M0 1h1M4 1h1M6 1h1M8 1h1M12 1h1M14 1h1M16 1h1M18 1h1M20 1h1M25 1h1M29 1h1M32 1h1M36 1h1M38 1h1M40 1h1M42 1h1M0 2h2M4 2h1M6 2h1M8 2h2M12 2h1M14 2h1M16 2h1M18 2h3M25 2h1M29 2h1M32 2h2M36 2h1M38 2h1M40 2h1M42 2h3M0 3h1M4 3h1M6 3h1M8 3h1M12 3h1M14 3h1M16 3h1M19 3h1M25 3h1M29 3h1M32 3h1M36 3h1M38 3h1M40 3h1M44 3h1M0 4h3M4 4h1M6 4h1M8 4h3M12 4h1M14 4h1M16 4h1M19 4h1M24 4h3M29 4h1M32 4h3M36 4h1M38 4h1M40 4h1M42 4h3" />
        </svg>
      )
    }
  },
  "oneEnemyItemLabel": {
    width: 55,
    height: 5,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 55 5" shapeRendering="crispEdges">
          <path stroke="#211f33" d="M0 0h3M4 0h2M8 0h3M14 0h3M18 0h2M22 0h3M26 0h2M29 0h2M32 0h1M34 0h1M38 0h3M42 0h3M46 0h3M50 0h2M53 0h2M0 1h1M2 1h1M4 1h1M6 1h1M8 1h1M14 1h1M18 1h1M20 1h1M22 1h1M26 1h1M28 1h1M30 1h1M32 1h1M34 1h1M39 1h1M43 1h1M46 1h1M50 1h1M52 1h1M54 1h1M0 2h1M2 2h1M4 2h1M6 2h1M8 2h2M14 2h2M18 2h1M20 2h1M22 2h2M26 2h1M28 2h1M30 2h1M32 2h3M39 2h1M43 2h1M46 2h2M50 2h1M52 2h1M54 2h1M0 3h1M2 3h1M4 3h1M6 3h1M8 3h1M14 3h1M18 3h1M20 3h1M22 3h1M26 3h1M28 3h1M30 3h1M33 3h1M39 3h1M43 3h1M46 3h1M50 3h1M52 3h1M54 3h1M0 4h3M4 4h1M6 4h1M8 4h3M14 4h3M18 4h1M20 4h1M22 4h3M26 4h1M28 4h1M30 4h1M33 4h1M38 4h3M43 4h1M46 4h3M50 4h1M52 4h1M54 4h1" />
        </svg>
      )
    }
  },
  "noEnemyItemsLabel": {
    width: 55,
    height: 5,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 55 5" shapeRendering="crispEdges">
          <path stroke="#211f33" d="M0 0h2M4 0h3M10 0h3M14 0h2M18 0h3M22 0h2M25 0h2M28 0h1M30 0h1M34 0h3M38 0h3M42 0h3M46 0h2M49 0h2M52 0h3M0 1h1M2 1h1M4 1h1M6 1h1M10 1h1M14 1h1M16 1h1M18 1h1M22 1h1M24 1h1M26 1h1M28 1h1M30 1h1M35 1h1M39 1h1M42 1h1M46 1h1M48 1h1M50 1h1M52 1h1M0 2h1M2 2h1M4 2h1M6 2h1M10 2h2M14 2h1M16 2h1M18 2h2M22 2h1M24 2h1M26 2h1M28 2h3M35 2h1M39 2h1M42 2h2M46 2h1M48 2h1M50 2h1M52 2h3M0 3h1M2 3h1M4 3h1M6 3h1M10 3h1M14 3h1M16 3h1M18 3h1M22 3h1M24 3h1M26 3h1M29 3h1M35 3h1M39 3h1M42 3h1M46 3h1M48 3h1M50 3h1M54 3h1M0 4h1M2 4h1M4 4h3M10 4h3M14 4h1M16 4h1M18 4h3M22 4h1M24 4h1M26 4h1M29 4h1M34 4h3M39 4h1M42 4h3M46 4h1M48 4h1M50 4h1M52 4h3" />
        </svg>
      )
    }
  },

  "enemyItem": {
    width: 7,
    height: 7,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 7 7" shapeRendering="crispEdges">
          <path stroke="#511633" d="M0 0h7M0 1h1m5 0h1M0 2h1m5 0h1"/>
          <path stroke="#fe8973" d="M1 1h5M1 2h5"/>
          <path stroke="#461419" d="M0 3h1m5 0h1M0 4h1m5 0h1M0 5h1m5 0h1M0 6h7"/>
          <path stroke="#fb3b3d" d="M1 3h2M1 4h3m1 0h1M1 5h5"/>
          <path stroke="#feb9ab" d="M3 3h1m1 0h1M4 4h1"/>
          <path stroke="#dc2d3d" d="M4 3h1"/>
        </svg>

      );
    }
  },

  "buggedItem": {
    width: 7,
    height: 7,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 7 7" shapeRendering="crispEdges">
          <path stroke="#3c5921" d="M0 0h7M0 1h1m5 0h1M0 2h1m5 0h1"/>
          <path stroke="#566a42" d="M1 1h1m3 0h1"/>
          <path stroke="#f3f2b9" d="M2 1h3M1 2h1m1 0h1m1 0h1M1 3h5M1 4h1m1 0h1m1 0h1"/>
          <path stroke="#214938" d="M2 2h1m1 0h1M2 4h1m1 0h1M1 5h5"/>
          <path stroke="#0f3022" d="M0 3h1m5 0h1M0 4h1m5 0h1M0 5h1m5 0h1M0 6h7"/>
        </svg>
      );
    }
  },

  "credit": {
    width: 8,
    height: 8,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 8 8" shapeRendering="crispEdges">
          <path stroke="#73361d" d="M2 0h3M1 1h1M0 2h1M0 3h1"/>
          <path stroke="#4d200e" d="M5 0h1M6 1h1M7 2h1M7 3h1M0 4h1M7 4h1M0 5h1M7 5h1M1 6h1M6 6h1M2 7h4"/>
          <path stroke="#ebdeb5" d="M2 1h3M1 2h2M1 3h1"/>
          <path stroke="#e1b42b" d="M5 1h1M3 2h4M2 3h2M5 3h2M1 4h2M5 4h2M2 5h4"/>
          <path stroke="#b28641" d="M4 3h1M3 4h1M1 5h1M6 5h1M2 6h4"/>
          <path stroke="#9a753b" d="M4 4h1"/>
        </svg>
      );
    }
  },

  "check": {
    width: 7,
    height: 6,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 7 6" shapeRendering="crispEdges">
          <path stroke="#25c45b" d="M5 0h2M4 1h1M6 1h1M0 2h2M3 2h1M5 2h1M0 3h1M2 3h1"/>
          <path stroke="#5bff79" d="M5 1h1M4 2h1M1 3h1M3 3h1M2 4h1"/>
          <path stroke="#0d9069" d="M4 3h1M1 4h1M3 4h1M2 5h1"/>
        </svg>
      );
    }
  },

  "journal": {
    width: 9,
    height: 11,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 9 11" shapeRendering="crispEdges">
          <path stroke="#f8f8f8" d="M0 0h1"/>
          <path stroke="#38125e" d="M1 0h8"/>
          <path stroke="#21063c"
                d="M0 1h1M8 1h1M0 2h1M8 2h1M0 3h1M8 3h1M0 4h1M8 4h1M0 5h1M8 5h1M0 6h1M8 6h1M0 7h1M8 7h1M0 8h1M2 8h7M0 9h1M2 9h1M8 9h1M0 10h9"/>
          <path stroke="#ef7ddd" d="M1 1h2M1 2h1M5 2h1M1 3h1M5 3h1M1 4h1M3 4h1M5 4h1M1 5h1M3 5h3M1 6h1M1 7h2M1 8h1"/>
          <path stroke="#c64ddc" d="M3 1h4M2 2h3M6 2h1M2 3h3M6 3h1M2 4h1M4 4h1M6 4h1M2 5h1M6 5h1M2 6h5M3 7h4M1 9h1"/>
          <path stroke="#8a37b7" d="M7 1h1M7 2h1M7 3h1M7 4h1M7 5h1M7 6h1M7 7h1"/>
          <path stroke="#b6b3aa" d="M3 9h1M7 9h1"/>
          <path stroke="#f7f7ee" d="M4 9h3"/>
        </svg>
      );
    }
  },
  "journal-small": {
    width: 7,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 7 9" shapeRendering="crispEdges">
          <path stroke="#f8f8f8" d="M0 0h1m5 0h1"/>
          <path stroke="#38135d" d="M1 0h5"/>
          <path stroke="#20063b" d="M0 1h1M0 2h1M0 3h1M0 4h1M0 5h1M0 6h1m1 0h4M0 7h1m1 0h1M0 8h6"/>
          <path stroke="#ee7fdc" d="M1 1h2M1 2h1M1 3h1M1 4h1M1 5h2M1 6h1"/>
          <path stroke="#c550da" d="M3 1h2M2 2h3M2 3h3M2 4h3M3 5h2M1 7h1"/>
          <path stroke="#8939b5" d="M5 1h1M5 2h1M5 3h1M5 4h1M5 5h1"/>
          <path stroke="#5d3b7e" d="M6 1h1M6 2h1M6 3h1M6 4h1M6 5h1M6 6h1M6 7h1M6 8h1"/>
          <path stroke="#f7f7ee" d="M3 7h2"/>
          <path stroke="#b6b3aa" d="M5 7h1"/>
        </svg>
      );
    }
  },

  "notification": {
    width: 10,
    height: 11,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 10 11" shapeRendering="crispEdges">
          <path stroke="#73361d" d="M4 0h2M3 1h1M2 2h1M1 3h1M1 4h1"/>
          <path stroke="#ebdeb5" d="M4 1h2M3 2h2M2 3h2M2 4h1M2 5h1M2 6h1"/>
          <path stroke="#4d200e" d="M6 1h1M7 2h1M8 3h1M8 4h1M1 5h1M8 5h1M1 6h1M8 6h1M1 7h1M8 7h1M0 8h1M9 8h1M0 9h10"/>
          <path stroke="#e1b42b" d="M5 2h2M4 3h4M3 4h5M3 5h5M3 6h5M2 7h6M1 8h7"/>
          <path stroke="#b28641" d="M8 8h1"/>
          <path stroke="#360605" d="M3 10h4"/>
        </svg>
      );
    }
  },

  "directory": {
    width: 9,
    height: 8,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 9 8" shapeRendering="crispEdges">
          <path stroke="#267b8e" d="M0 0h3M0 1h1M3 1h5"/>
          <path stroke="#94f8fe" d="M1 1h2M1 2h7M1 3h2M1 4h1"/>
          <path stroke="#0c404c" d="M0 2h1M8 2h1M0 3h1M8 3h1M0 4h1M8 4h1M0 5h1M8 5h1M0 6h1M8 6h1M0 7h9"/>
          <path stroke="#6de4ff" d="M3 3h5M2 4h6M1 5h7M1 6h7"/>
        </svg>
      );
    }
  },

  "bail": {
    width: 7,
    height: 7,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 7 7" shapeRendering="crispEdges">
          <path stroke="#834425" d="M3 0h2M2 1h1M1 2h1M0 3h1"/>
          <path stroke="#ff6243" d="M3 1h1M2 2h2M1 3h5"/>
          <path stroke="#703315" d="M4 1h1M4 2h3M6 3h1M1 4h1M4 4h3M2 5h1M4 5h1M3 6h2"/>
          <path stroke="#f12525" d="M2 4h2M3 5h1"/>
        </svg>
      );
    }
  },

  "rightSubmissionMenuArrow": {
    width: 3,
    height: 5,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 3 5" shapeRendering="crispEdges">
          <path stroke="#8d8daf" d="M0 0h1M1 1h1M2 2h1M1 3h1M0 4h1"/>
        </svg>
      );
    }
  },

  "unknown": {
    width: 6,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 6 9" shapeRendering="crispEdges">
          <path stroke="#6b8f93" d="M1 0h4M0 1h2M4 1h2M0 2h1M5 2h1"/>
          <path stroke="#def4e3" d="M2 1h2M1 2h1M4 2h1"/>
          <path stroke="#728486" d="M2 2h2M0 3h4M5 3h1M1 4h1M4 4h2M1 5h1M3 5h2M1 6h3M1 7h1M3 7h1M1 8h3"/>
          <path stroke="#bed5c9" d="M4 3h1M2 4h2M2 5h1M2 7h1"/>
        </svg>
      );
    }
  },

  "closeButton": {
    width: 9,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 9 9" shapeRendering="crispEdges">
          <path stroke="#35315d" d="M1 0h7M0 1h1M8 1h1M0 2h1M8 2h1M3 3h1M5 3h1M4 4h1M3 5h1M5 5h1"/>
          <path stroke="#ffffff" d="M1 1h7"/>
          <path stroke="#f6f3f1"
                d="M1 2h1M3 2h3M7 2h1M1 3h2M4 3h1M6 3h2M1 4h3M5 4h3M1 5h2M4 5h1M6 5h2M1 6h1M3 6h3M7 6h1"/>
          <path stroke="#5d5b71" d="M2 2h1M6 2h1M2 6h1M6 6h1"/>
          <path stroke="#222034" d="M0 3h1M8 3h1M0 4h1M8 4h1M0 5h1M8 5h1M0 6h1M8 6h1M0 7h1M8 7h1M1 8h7"/>
          <path stroke="#ccd7d9" d="M1 7h7"/>
        </svg>
      );
    }
  },

  // OLD "menuUpButton": {
  //   width: 9,
  //   height: 9,
  //   svg(style) {
  //     return (
  //       <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 9 9" shapeRendering="crispEdges">
  //         <path stroke="red" d="M1 0h7M0 1h1M8 1h1M0 2h1M8 2h1M4 3h1M3 4h1M5 4h1"/>
  //         <path stroke="#ffffff" d="M1 1h7"/>
  //         <path stroke="#f6f3f1" d="M1 2h7M1 3h3M5 3h3M1 4h2M4 4h1M6 4h2M1 5h1M3 5h3M7 5h1M1 6h7"/>
  //         <path stroke="#222034" d="M0 3h1M8 3h1M0 4h1M8 4h1M0 5h1M8 5h1M0 6h1M8 6h1M0 7h1M8 7h1M1 8h7"/>
  //         <path stroke="#5d5b71" d="M2 5h1M6 5h1"/>
  //         <path stroke="#ccd7d9" d="M1 7h7"/>
  //       </svg>
  //     );
  //   }
  // },
  //

  "menuUpButton": {
    width: 11,
    height: 11,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 11 11" shapeRendering="crispEdges">
          <path stroke="#35315d" d="M1 0h9M0 1h1m9 0h1M0 2h1m9 0h1M0 3h1m9 0h1M5 4h1M4 5h1m1 0h1"/>
          <path stroke="#fff" d="M1 1h9"/>
          <path stroke="#f6f3f1" d="M1 2h9M1 3h9M1 4h4m1 0h4M1 5h3m1 0h1m1 0h3M1 6h2m1 0h3m1 0h2M1 7h9M1 8h9"/>
          <path stroke="#222034" d="M0 4h1m9 0h1M0 5h1m9 0h1M0 6h1m9 0h1M0 7h1m9 0h1M0 8h1m9 0h1M0 9h1m9 0h1M1 10h9"/>
          <path stroke="#5d5b71" d="M3 6h1m3 0h1"/>
          <path stroke="#ccd7d9" d="M1 9h9"/>
        </svg>

      );
    }
  },

  // OLD "menuDownButton": {
  //   width: 9,
  //   height: 9,
  //   svg(style) {
  //     return (
  //       <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 9 9" shapeRendering="crispEdges">
  //         <path stroke="#35315d" d="M1 0h7M0 1h1m7 0h1M0 2h1m7 0h1M3 4h1m1 0h1M4 5h1"/>
  //         <path stroke="#fff" d="M1 1h7"/>
  //         <path stroke="#f6f3f1" d="M1 2h7M1 3h1m1 0h3m1 0h1M1 4h2m1 0h1m1 0h2M1 5h3m1 0h3M1 6h7"/>
  //         <path stroke="#222034" d="M0 3h1m7 0h1M0 4h1m7 0h1M0 5h1m7 0h1M0 6h1m7 0h1M0 7h1m7 0h1M1 8h7"/>
  //         <path stroke="#5d5b71" d="M2 3h1m3 0h1"/>
  //         <path stroke="#ccd7d9" d="M1 7h7"/>
  //       </svg>
  //     );
  //   }
  // },

  "menuDownButton": {
    width: 11,
    height: 11,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 11 11" shapeRendering="crispEdges">
          <path stroke="#35315d" d="M1 0h9M0 1h1m9 0h1M0 2h1m9 0h1M0 3h1m9 0h1M4 5h1m1 0h1M5 6h1"/>
          <path stroke="#fff" d="M1 1h9"/>
          <path stroke="#f6f3f1" d="M1 2h9M1 3h9M1 4h2m1 0h3m1 0h2M1 5h3m1 0h1m1 0h3M1 6h4m1 0h4M1 7h9M1 8h9"/>
          <path stroke="#222034" d="M0 4h1m9 0h1M0 5h1m9 0h1M0 6h1m9 0h1M0 7h1m9 0h1M0 8h1m9 0h1M0 9h1m9 0h1M1 10h9"/>
          <path stroke="#5d5b71" d="M3 4h1m3 0h1"/>
          <path stroke="#ccd7d9" d="M1 9h9"/>
        </svg>
      );
    }
  },


  "battleSpaceSkull": {
    width: 12,
    height: 12,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 12 12" shapeRendering="crispEdges">
          <path stroke="#ffd7d4"
                d="M3 0h6M2 1h1M9 1h1M1 2h1M10 2h1M0 3h1M11 3h1M0 4h1M11 4h1M0 5h1M3 5h1M8 5h1M11 5h1M0 6h1M3 6h2M7 6h2M11 6h1M1 7h1M10 7h1M1 8h1M10 8h1M1 9h1M10 9h1M2 10h1M4 10h1M7 10h1M9 10h1M3 11h6"/>
          <path stroke="#d60000"
                d="M3 1h6M2 2h1M9 2h1M1 3h1M10 3h1M1 4h1M3 4h2M7 4h2M10 4h1M1 5h2M4 5h4M9 5h2M1 6h2M5 6h2M9 6h2M2 7h3M7 7h3M2 8h1M9 8h1M2 9h1M9 9h1M3 10h1M5 10h2M8 10h1"/>
          <path stroke="#ff8c80" d="M3 2h6M2 3h2"/>
          <path stroke="#fe5f5f" d="M4 3h6M2 4h1M5 4h2M9 4h1M5 7h2M3 8h6M3 9h6"/>
        </svg>
      );
    }
  },

  "star": {
    width: 5,
    height: 4,
    svg(style, props) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 4" shapeRendering="crispEdges">
          <path stroke={props.fill || "#d07120"} d="M2 0h1M0 1h5M1 2h3M1 3h1M3 3h1"/>
        </svg>
      );
    }
  },

  "laptop": {
    width: 12,
    height: 10,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 12 10" shapeRendering="crispEdges">
          <path stroke="#101016"
                d="M1 0h10M0 1h1M11 1h1M0 2h1M11 2h1M0 3h1M11 3h1M0 4h1M11 4h1M0 5h1M11 5h1M1 6h10M0 7h1M11 7h1M0 8h1M11 8h1M1 9h10"/>
          <path stroke="#0f414c" d="M1 1h2M1 2h1"/>
          <path stroke="#052128" d="M3 1h8M2 2h9M1 3h10M1 4h10M1 5h10"/>
          <path stroke="#707b7f" d="M1 7h1M3 7h1M5 7h1M7 7h1M9 7h1M2 8h1M8 8h1M10 8h1"/>
          <path stroke="#586366" d="M2 7h1M4 7h1M6 7h1M8 7h1M10 7h1M1 8h1M3 8h1M9 8h1"/>
          <path stroke="#839093" d="M4 8h4"/>
        </svg>
      );
    }
  },

  "batteryDead": {
    width: 44,
    height: 5,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 44 5" shapeRendering="crispEdges">
          <path stroke="#8f939c"
                d="M0 0h3M5 0h1M8 0h3M12 0h3M16 0h3M20 0h3M24 0h1M26 0h1M0 1h1M2 1h1M4 1h1M6 1h1M9 1h1M13 1h1M16 1h1M20 1h1M22 1h1M24 1h1M26 1h1M0 2h2M4 2h3M9 2h1M13 2h1M16 2h3M20 2h2M24 2h3M0 3h1M2 3h1M4 3h1M6 3h1M9 3h1M13 3h1M16 3h1M20 3h1M22 3h1M25 3h1M0 4h3M4 4h1M6 4h1M9 4h1M13 4h1M16 4h3M20 4h1M22 4h1M25 4h1"/>
          <path stroke="#515459"
                d="M29 0h2M33 0h3M37 0h3M41 0h2M29 1h1M31 1h1M33 1h1M37 1h1M39 1h1M41 1h1M43 1h1M29 2h1M31 2h1M33 2h3M37 2h3M41 2h1M43 2h1M29 3h1M31 3h1M33 3h1M37 3h1M39 3h1M41 3h1M43 3h1M29 4h2M33 4h3M37 4h1M39 4h1M41 4h2"/>
        </svg>
      );
    }
  },


  "battleVictory": {
    width: 59,
    height: 16,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 59 16" shapeRendering="crispEdges">
          <path stroke="#222034"
                d="M0 0h52m1 0h5M0 1h1m3 0h1m3 0h1m3 0h1m6 0h1m7 0h1m7 0h1m7 0h1m3 0h1m3 0h1m1 0h1m3 0h1M0 2h1m3 0h1m3 0h1m3 0h1m6 0h1m7 0h1m7 0h1m7 0h1m3 0h1m3 0h1m1 0h1m3 0h1M0 3h1m3 0h1m3 0h1m3 0h1m6 0h1m7 0h1m7 0h1m7 0h1m3 0h1m3 0h1m1 0h1m3 0h1M0 4h1m3 0h1m3 0h1m3 0h1m3 0h6m3 0h3m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m1 0h1m3 0h1M0 5h1m3 0h1m3 0h1m3 0h1m3 0h6m3 0h3m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m1 0h1m3 0h1M0 6h1m3 0h1m3 0h1m3 0h1m3 0h1m4 0h1m3 0h1m1 0h1m3 0h1m3 0h1m3 0h1m3 0h1m7 0h1m1 0h1m3 0h1M0 7h1m3 0h1m3 0h1m3 0h1m3 0h1m4 0h1m3 0h1m1 0h1m3 0h1m3 0h1m3 0h1m3 0h1m7 0h1m1 0h1m3 0h1M0 8h1m3 0h1m3 0h1m3 0h1m3 0h4m1 0h1m3 0h1m1 0h1m3 0h1m3 0h1m6 0h4m3 0h3m1 0h5M0 9h1m7 0h1m3 0h1m6 0h1m1 0h1m3 0h1m1 0h1m7 0h1m6 0h4m3 0h3m1 0h5M0 10h2m5 0h2m3 0h1m6 0h1m1 0h1m3 0h1m1 0h1m7 0h1m3 0h1m3 0h1m1 0h1m3 0h1m3 0h1m3 0h1M0 11h3m3 0h3m3 0h1m6 0h1m1 0h1m3 0h1m1 0h1m7 0h1m3 0h1m3 0h1m1 0h1m3 0h1m3 0h1m3 0h1M1 12h19m1 0h5m1 0h17m1 0h5m3 0h5M2 13h18m1 0h5m1 0h17m1 0h5m3 0h5"/>
          <path stroke="#f9d339"
                d="M1 1h3m1 0h3m1 0h3m1 0h6m1 0h7m1 0h7m1 0h7m1 0h3m1 0h3m3 0h3M1 4h3m1 0h3m1 0h3m1 0h3m6 0h3m3 0h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m3 0h3M1 5h3m1 0h3m1 0h3m1 0h3m6 0h3m3 0h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m3 0h3M1 6h3m1 0h3m1 0h3m1 0h3m6 0h3m3 0h3m1 0h3m1 0h3m1 0h3m1 0h7m3 0h3M3 11h3m3 0h3m1 0h6m3 0h3m3 0h7m1 0h3m1 0h3m3 0h3m5 0h3"/>
          <path stroke="#c9c9c9"
                d="M52 1h1m5 0h1m-7 1h1m5 0h1m-7 1h1m5 0h1m-7 1h1m5 0h1m-7 1h1m5 0h1m-7 1h1m5 0h1m-7 1h1m5 0h1m-7 1h1m5 0h1m-7 1h1m5 0h1m-9 1h3m-3 1h1m7 0h1m-9 1h1m7 0h1m-9 1h1m7 0h1M2 14h43m1 0h5m3 0h5M3 15h42m1 0h5m3 0h5"/>
          <path stroke="#fafda4"
                d="M1 2h3m1 0h3m1 0h3m1 0h6m1 0h7m1 0h7m1 0h7m1 0h3m1 0h3m3 0h3M1 3h3m1 0h3m1 0h3m1 0h6m1 0h7m1 0h7m1 0h7m1 0h3m1 0h3m3 0h3"/>
          <path stroke="#f39646"
                d="M1 7h3m1 0h3m1 0h3m1 0h3m6 0h3m3 0h3m1 0h3m1 0h3m1 0h3m1 0h7m3 0h3M1 8h3m1 0h3m1 0h3m1 0h3m6 0h3m3 0h3m1 0h3m1 0h6m4 0h3M1 9h7m1 0h3m1 0h6m3 0h3m3 0h7m1 0h6m4 0h3M2 10h5m2 0h3m1 0h6m3 0h3m3 0h7m1 0h3m1 0h3m3 0h3m5 0h3"/>
        </svg>
      );
    }
  },

  "battleDefeat": {
    width: 53,
    height: 16,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 53 16" shapeRendering="crispEdges">
          <path stroke="#222034"
                d="M0 0h7M8 0h38M47 0h5M0 1h1M7 1h2M15 1h1M22 1h1M29 1h1M37 1h1M45 1h1M47 1h1M51 1h1M0 2h1M8 2h1M15 2h1M22 2h1M29 2h1M37 2h1M45 2h1M47 2h1M51 2h1M0 3h1M8 3h1M15 3h1M22 3h1M29 3h1M37 3h1M45 3h1M47 3h1M51 3h1M0 4h1M4 4h1M8 4h1M12 4h4M19 4h4M26 4h4M33 4h1M37 4h3M43 4h3M47 4h1M51 4h1M0 5h1M4 5h1M8 5h1M15 5h1M22 5h1M29 5h1M33 5h1M37 5h3M43 5h3M47 5h1M51 5h1M0 6h1M4 6h1M8 6h1M15 6h1M22 6h1M29 6h1M33 6h1M37 6h1M39 6h1M43 6h1M47 6h1M51 6h1M0 7h1M4 7h1M8 7h1M15 7h1M22 7h1M29 7h1M33 7h1M37 7h1M39 7h1M43 7h1M47 7h1M51 7h1M0 8h1M4 8h1M8 8h1M12 8h4M19 8h4M26 8h4M37 8h1M39 8h1M43 8h1M47 8h5M0 9h1M8 9h1M15 9h1M19 9h4M29 9h1M37 9h1M39 9h1M43 9h1M47 9h5M0 10h1M8 10h1M15 10h1M19 10h1M22 10h1M29 10h1M33 10h1M37 10h1M39 10h1M43 10h1M47 10h1M51 10h1M0 11h1M8 11h1M15 11h1M19 11h1M22 11h1M29 11h1M33 11h1M37 11h1M39 11h1M43 11h1M47 11h1M51 11h1M0 12h20M22 12h16M39 12h5M47 12h5M0 13h20M22 13h16M39 13h5M47 13h5"/>
          <path stroke="#e47676"
                d="M1 1h6M9 1h6M16 1h6M23 1h6M30 1h7M38 1h7M48 1h3M1 4h3M5 4h3M9 4h3M16 4h3M23 4h3M30 4h3M34 4h3M40 4h3M48 4h3M1 5h3M5 5h3M9 5h6M16 5h6M23 5h6M30 5h3M34 5h3M40 5h3M48 5h3M1 6h3M5 6h3M9 6h6M16 6h6M23 6h6M30 6h3M34 6h3M40 6h3M48 6h3M1 11h7M9 11h6M16 11h3M23 11h6M30 11h3M34 11h3M40 11h3M48 11h3"/>
          <path stroke="#c9c9c9"
                d="M52 1h1M52 2h1M52 3h1M52 4h1M52 5h1M52 6h1M52 7h1M52 8h1M52 9h1M52 11h1M52 12h1M44 13h1M52 13h1M1 14h37M40 14h5M48 14h5M2 15h36M40 15h5M48 15h5"/>
          <path stroke="#faa38f"
                d="M1 2h7M9 2h6M16 2h6M23 2h6M30 2h7M38 2h7M48 2h3M1 3h7M9 3h6M16 3h6M23 3h6M30 3h7M38 3h7M48 3h3"/>
          <path stroke="#cd5d4c"
                d="M1 7h3M5 7h3M9 7h6M16 7h6M23 7h6M30 7h3M34 7h3M40 7h3M48 7h3M1 8h3M5 8h3M9 8h3M16 8h3M23 8h3M30 8h7M40 8h3M1 9h7M9 9h6M16 9h3M23 9h6M30 9h7M40 9h3M1 10h7M9 10h6M16 10h3M23 10h6M30 10h3M34 10h3M40 10h3M48 10h3"/>
        </svg>
      );
    }
  },

  "creditsText": {
    width: 25,
    height: 5,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 25 5" shapeRendering="crispEdges">
          <path stroke="#222034"
                d="M0 0h3m1 0h3m1 0h3m1 0h2m2 0h1m1 0h3m1 0h3M0 1h1m3 0h1m1 0h1m1 0h1m3 0h1m1 0h1m1 0h1m2 0h1m2 0h1M0 2h1m3 0h2m2 0h2m2 0h1m1 0h1m1 0h1m2 0h1m2 0h3M0 3h1m3 0h1m1 0h1m1 0h1m3 0h1m1 0h1m1 0h1m2 0h1m4 0h1M0 4h3m1 0h1m1 0h1m1 0h3m1 0h2m2 0h1m2 0h1m2 0h3"/>
        </svg>
      );
    }
  },
  "experienceText": {
    width: 38,
    height: 5,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 38 5" shapeRendering="crispEdges">
          <path stroke="#222034"
                d="M0 0h3M4 0h1M7 0h1M9 0h3M13 0h3M17 0h3M21 0h1M23 0h3M27 0h2M31 0h3M35 0h3M0 1h1M4 1h1M7 1h1M9 1h1M11 1h1M13 1h1M17 1h1M19 1h1M21 1h1M23 1h1M27 1h1M29 1h1M31 1h1M35 1h1M0 2h2M5 2h2M9 2h3M13 2h2M17 2h2M21 2h1M23 2h2M27 2h1M29 2h1M31 2h1M35 2h2M0 3h1M4 3h1M7 3h1M9 3h1M13 3h1M17 3h1M19 3h1M21 3h1M23 3h1M27 3h1M29 3h1M31 3h1M35 3h1M0 4h3M4 4h1M7 4h1M9 4h1M13 4h3M17 4h1M19 4h1M21 4h1M23 4h3M27 4h1M29 4h1M31 4h3M35 4h3"/>
        </svg>
      );
    }
  },
  "levelUpText": {
    width: 36,
    height: 7,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 36 7" shapeRendering="crispEdges">
          <path stroke="#222034"
                d="M0 0h3M4 0h15M23 0h9M33 0h3M0 1h1M2 1h1M4 1h1M8 1h1M10 1h1M12 1h1M16 1h1M18 1h1M23 1h1M25 1h1M27 1h1M31 1h1M33 1h1M35 1h1M0 2h1M2 2h1M4 2h1M6 2h3M10 2h1M12 2h1M14 2h3M18 2h1M23 2h1M25 2h1M27 2h1M29 2h1M31 2h1M33 2h1M35 2h1M0 3h1M2 3h1M4 3h1M7 3h2M10 3h1M12 3h1M15 3h2M18 3h1M23 3h1M25 3h1M27 3h1M31 3h1M33 3h1M35 3h1M0 4h1M2 4h3M6 4h3M10 4h1M12 4h1M14 4h3M18 4h3M23 4h1M25 4h1M27 4h1M29 4h3M33 4h3M0 5h1M4 5h1M8 5h2M11 5h2M16 5h1M20 5h1M23 5h1M27 5h1M29 5h1M33 5h1M35 5h1M0 6h8M9 6h12M23 6h7M33 6h3"/>
          <path
            style={{
              animation: "svg_levelUpRave 1s infinite alternate-reverse linear"
            }}
            stroke="#ffc6fc"
            d="M1 1h1M5 1h3M9 1h1M11 1h1M13 1h3M17 1h1M24 1h1M26 1h1M28 1h3M34 1h1M1 2h1M5 2h1M9 2h1M11 2h1M13 2h1M17 2h1M24 2h1M26 2h1M28 2h1M30 2h1M34 2h1M1 3h1M5 3h2M9 3h1M11 3h1M13 3h2M17 3h1M24 3h1M26 3h1M28 3h3M34 3h1M1 4h1M5 4h1M9 4h1M11 4h1M13 4h1M17 4h1M24 4h1M26 4h1M28 4h1M1 5h3M5 5h3M10 5h1M13 5h3M17 5h3M24 5h3M28 5h1M34 5h1"/>

          <style>
            {`
           @keyframes svg_levelUpRave {
            50% { stroke: #fce369; }
            100% { stroke: white; }
            }
           `}
          </style>

        </svg>
      );
    }
  },


  "plus": {
    width: 5,
    height: 5,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 5" shapeRendering="crispEdges">
          <path stroke="#222032" d="M1 0h3M0 1h2m1 0h2M0 2h1m3 0h1M0 3h2m1 0h2M1 4h3"/>
          <path stroke="#fafda4" d="M2 1h1M1 2h3M2 3h1"/>
        </svg>
      );
    }
  },


  "hostileIndicator": {
    width: 9,
    height: 6,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 9 6" shapeRendering="crispEdges">
          <path stroke="#832b1f" d="M0 0h9"/>
          <path stroke="#702014" d="M0 1h1M8 1h1M1 2h1M7 2h1M2 3h1M6 3h1M3 4h1M5 4h1M4 5h1"/>
          <path stroke="#ef452d" d="M1 1h2M4 1h4M5 2h2M4 3h2M4 4h1"/>
          <path stroke="#fa626e" d="M3 1h1M2 2h3M3 3h1"/>
        </svg>
      );
    }
  },
  // "hostileIndicator": {
  //   width: 12,
  //   height: 12,
  //   svg(style) {
  //     return (
  //       <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 12 12" shapeRendering="crispEdges">
  //         <path stroke="#000000" d="M3 0h6M2 1h1M9 1h1M1 2h3M8 2h3M0 3h1M11 3h1M0 4h1M11 4h1M0 5h1M11 5h1M0 6h1M11 6h1M0 7h1M5 7h2M11 7h1M1 8h2M5 8h2M9 8h2M3 9h1M8 9h1M4 10h1M7 10h1M5 11h2" />
  //         <path stroke="#b30024" d="M3 1h2M7 1h2M1 7h1M10 7h1M3 8h1M8 8h1M4 9h1M7 9h1M5 10h2" />
  //         <path stroke="#f81d1d" d="M5 1h2M4 2h4M1 3h10M1 4h1M5 4h2M10 4h1M1 5h1M5 5h2M10 5h1M1 6h1M4 6h4M10 6h1M2 7h3M7 7h3M4 8h1M7 8h1" />
  //         <path stroke="#222034" d="M2 4h3M7 4h3M2 5h3M7 5h3M2 6h2M8 6h2" />
  //         <path stroke="#f01818" d="M5 9h2" />
  //       </svg>
  //     );
  //   }
  // },

  "questIndicator": {
    width: 9,
    height: 7,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 9 7" shapeRendering="crispEdges">
          <path stroke="#625d3d" d="M0 0h7M0 1h1M0 2h1M1 3h1M2 4h1M3 5h1M4 6h1" />
          <path stroke="#4e4820" d="M7 0h2M8 1h1M8 2h1M7 3h1M6 4h1M5 5h1" />
          <path stroke="#ffef87" d="M1 1h3M1 2h3M2 3h1M3 4h1M4 5h1" />
          <path stroke="#fcdc0d" d="M4 1h3M4 2h3M3 3h3M4 4h1" />
          <path stroke="#dab401" d="M7 1h1M7 2h1M6 3h1M5 4h1" />
        </svg>
      );
    }
  },


  "friendlyIndicator": {
    width: 9,
    height: 6,
    svg(style) {
      return null
      // return (
      //   <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 9 6" shapeRendering="crispEdges">
      //     <path stroke="#1f2b14" d="M0 0h9"/>
      //     <path stroke="#162c03" d="M0 1h1M8 1h1M1 2h1M7 2h1M2 3h1M6 3h1M3 4h1M5 4h1M4 5h1"/>
      //     <path stroke="#85ce46" d="M1 1h2M4 1h4M5 2h2M4 3h2M4 4h1"/>
      //     <path stroke="#9ae458" d="M3 1h1M2 2h3M3 3h1"/>
      //   </svg>
      // );
    }
  },

  "bailButton": {
    width: 22,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 22 9" shapeRendering="crispEdges">
          <path stroke="#222033"
                d="M1 0h20M0 1h1M21 1h1M0 2h1M21 2h1M0 3h1M21 3h1M0 4h1M21 4h1M0 5h1M21 5h1M0 6h1M21 6h1M0 7h1M21 7h1M1 8h20"/>
          <path stroke="#ffffff"
                d="M1 1h20M1 2h3M5 2h2M9 2h2M14 2h1M16 2h1M18 2h3M1 3h2M5 3h2M8 3h1M10 3h1M12 3h1M14 3h1M16 3h1M18 3h3M1 4h1M5 4h2M9 4h2M14 4h1M16 4h1M18 4h3M1 5h2M5 5h2M8 5h1M10 5h1M12 5h1M14 5h1M16 5h1M18 5h3M1 6h3M5 6h2M10 6h1M12 6h1M14 6h1M16 6h1M19 6h2"/>
          <path stroke="#39393b"
                d="M4 2h1M7 2h2M11 2h3M15 2h1M17 2h1M3 3h2M7 3h1M9 3h1M11 3h1M13 3h1M15 3h1M17 3h1M2 4h3M7 4h2M11 4h3M15 4h1M17 4h1M3 5h2M7 5h1M9 5h1M11 5h1M13 5h1M15 5h1M17 5h1M4 6h1M7 6h2M11 6h1M13 6h1M15 6h1M17 6h2"/>
          <path stroke="#727278" d="M9 6h1"/>
          <path stroke="#d8d5ec" d="M1 7h20"/>
        </svg>
      );
    }
  },
  "bailButtonBack": {
    width: 22,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 22 9" shapeRendering="crispEdges">
          <path stroke="#07bfdd" d="M1 0h20M0 1h1m20 0h1M0 2h1m3 0h1m2 0h2m2 0h3m1 0h1m1 0h1m3 0h1M0 3h1m2 0h2m2 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m3 0h1M0 4h1m1 0h3m2 0h2m2 0h3m1 0h1m1 0h1m3 0h1M0 5h1m2 0h2m2 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m3 0h1M0 6h1m3 0h1m2 0h2m2 0h1m1 0h1m1 0h1m1 0h2m2 0h1M0 7h1m20 0h1M1 8h20"/>
          <path stroke="#fff" d="M1 1h20M1 2h3m1 0h2m2 0h2m3 0h1m1 0h1m1 0h3M1 3h2m2 0h2m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h3M1 4h1m3 0h2m2 0h2m3 0h1m1 0h1m1 0h3M1 5h2m2 0h2m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h3M1 6h3m1 0h2m3 0h1m1 0h1m1 0h1m1 0h1m2 0h2"/>
          <path stroke="#56ccdf" d="M9 6h1"/>
          <path stroke="#ddf5d8" d="M1 7h20"/>
        </svg>
      )
    }
  },

  "menuSpeechArrow": {
    width: 7,
    height: 8,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 7 8" shapeRendering="crispEdges">
          <path stroke="#d8d5ec" d="M0 0h1M6 0h1M5 1h1M5 2h1M4 3h1M3 4h1M2 5h1M1 6h1" />
          <path stroke="#f8f8f8" d="M1 0h5M1 1h4M1 2h4M1 3h3M1 4h2M1 5h1" />
          <path stroke="#222033" d="M0 1h1M6 1h1M0 2h1M6 2h1M0 3h1M5 3h1M0 4h1M4 4h1M0 5h1M3 5h1M0 6h1M2 6h1M0 7h2" />
        </svg>
      )
    }
  },

  "menuBackSmaller": {
    width: 10,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 10 9" shapeRendering="crispEdges">
          <path stroke="#222033"
                d="M1 0h8M0 1h1M9 1h1M0 2h1M4 2h1M9 2h1M0 3h1M3 3h2M9 3h1M0 4h1M2 4h6M9 4h1M0 5h1M3 5h2M9 5h1M0 6h1M4 6h1M9 6h1M0 7h1M9 7h1M1 8h8"/>
          <path stroke="#f8f8f8" d="M1 1h8M1 2h3M5 2h4M1 3h2M5 3h4M1 4h1M8 4h1M1 5h2M5 5h4M1 6h3M5 6h4"/>
          <path stroke="#d8d5ec" d="M1 7h8"/>
        </svg>
      );
    }
  },
  "menuBack": {
    width: 12,
    height: 13,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 12 13" shapeRendering="crispEdges">
          <path stroke="#222033" d="M1 0h10M0 1h1M11 1h1M0 2h1M11 2h1M0 3h1M11 3h1M0 4h1M5 4h1M11 4h1M0 5h1M4 5h2M11 5h1M0 6h1M3 6h6M11 6h1M0 7h1M4 7h2M11 7h1M0 8h1M5 8h1M11 8h1M0 9h1M11 9h1M0 10h1M11 10h1M0 11h1M11 11h1M1 12h10" />
          <path stroke="#f8f8f8" d="M1 1h10M1 2h10M1 3h10M1 4h4M6 4h5M1 5h3M6 5h5M1 6h2M9 6h2M1 7h3M6 7h5M1 8h4M6 8h5M1 9h10M1 10h10" />
          <path stroke="#d8d5ec" d="M1 11h10" />
        </svg>
      );
    }
  },


  "scriptsTitle": {
    width: 34,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 34 9" shapeRendering="crispEdges">
          <path stroke="#39393b"
                d="M2 0h2M7 0h2M10 0h4M15 0h8M24 0h5M30 0h2M1 1h1M4 1h1M6 1h1M9 1h2M14 1h2M19 1h1M23 1h2M28 1h2M32 1h1M0 2h1M2 2h2M5 2h1M7 2h2M10 2h1M12 2h2M15 2h2M18 2h2M21 2h2M24 2h2M27 2h2M30 2h2M33 2h1M0 3h1M2 3h4M7 3h1M9 3h2M12 3h2M15 3h2M18 3h2M21 3h2M24 3h2M27 3h2M30 3h3M1 4h1M4 4h2M7 4h1M10 4h1M12 4h2M15 4h2M18 4h2M21 4h2M24 4h2M27 4h1M29 4h1M32 4h1M1 5h3M5 5h1M7 5h1M9 5h2M14 5h3M18 5h2M23 5h1M25 5h1M27 5h1M29 5h3M33 5h1M0 6h1M2 6h2M5 6h1M7 6h2M10 6h1M12 6h2M15 6h2M18 6h2M21 6h2M25 6h1M27 6h2M30 6h2M33 6h1M1 7h1M4 7h1M6 7h1M9 7h1M12 7h2M15 7h1M19 7h1M21 7h1M25 7h1M27 7h1M29 7h1M32 7h1"/>
          <path stroke="#ffffff"
                d="M2 1h2M7 1h2M11 1h3M16 1h3M20 1h3M25 1h3M30 1h2M1 2h1M4 2h1M6 2h1M9 2h1M11 2h1M14 2h1M17 2h1M20 2h1M23 2h1M26 2h1M29 2h1M32 2h1M1 3h1M6 3h1M11 3h1M14 3h1M17 3h1M20 3h1M23 3h1M26 3h1M29 3h1M2 4h2M6 4h1M11 4h1M14 4h1M17 4h1M20 4h1M23 4h1M26 4h1M30 4h2M4 5h1M6 5h1M11 5h3M17 5h1M20 5h3M26 5h1M32 5h1M1 6h1M4 6h1M6 6h1M9 6h1M11 6h1M14 6h1M17 6h1M20 6h1M26 6h1M29 6h1M32 6h1M2 7h2M7 7h2M11 7h1M14 7h1M16 7h3M20 7h1M26 7h1M30 7h2"/>
          <path stroke="#222033" d="M2 8h2M7 8h2M11 8h1M14 8h1M16 8h3M20 8h1M25 8h3M30 8h2"/>
        </svg>
      );
    }
  },
  "itemsTitle": {
    width: 24,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 24 9" shapeRendering="crispEdges">
          <path stroke="#3f3f45"
                d="M0 0h15M16 0h3M20 0h2M0 1h1M4 1h1M8 1h1M12 1h1M15 1h1M18 1h2M22 1h1M1 2h1M3 2h1M5 2h1M7 2h2M10 2h3M14 2h1M16 2h1M18 2h1M20 2h2M23 2h1M1 3h1M3 3h1M5 3h1M7 3h2M10 3h3M14 3h1M16 3h1M18 3h1M20 3h3M1 4h1M3 4h1M5 4h1M7 4h2M12 4h1M14 4h1M16 4h1M18 4h2M22 4h1M1 5h1M3 5h1M5 5h1M7 5h2M10 5h3M14 5h3M18 5h4M23 5h1M1 6h1M3 6h1M5 6h1M7 6h2M10 6h3M14 6h1M16 6h1M18 6h1M20 6h2M23 6h1M0 7h1M4 7h2M7 7h2M12 7h1M14 7h1M16 7h1M18 7h2M22 7h1"/>
          <path stroke="#ffffff"
                d="M1 1h3M5 1h3M9 1h3M13 1h2M16 1h2M20 1h2M2 2h1M6 2h1M9 2h1M13 2h1M15 2h1M17 2h1M19 2h1M22 2h1M2 3h1M6 3h1M9 3h1M13 3h1M15 3h1M17 3h1M19 3h1M2 4h1M6 4h1M9 4h3M13 4h1M15 4h1M17 4h1M20 4h2M2 5h1M6 5h1M9 5h1M13 5h1M17 5h1M22 5h1M2 6h1M6 6h1M9 6h1M13 6h1M17 6h1M19 6h1M22 6h1M1 7h3M6 7h1M9 7h3M13 7h1M17 7h1M20 7h2"/>
          <path stroke="#222033" d="M0 8h5M6 8h1M8 8h4M13 8h2M16 8h2M20 8h2"/>
        </svg>
      );
    }
  },
  "upgradesTitle": {
    width: 40,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 40 9" shapeRendering="crispEdges">
          <path stroke="#3f3f45"
                d="M0 0h2M4 0h5M12 0h2M16 0h3M22 0h2M26 0h2M31 0h4M36 0h2M0 1h1M2 1h2M5 1h1M9 1h1M11 1h1M14 1h2M19 1h1M21 1h1M24 1h2M28 1h1M30 1h1M34 1h2M38 1h1M0 2h1M2 2h2M5 2h1M7 2h2M10 2h1M12 2h2M15 2h1M17 2h2M20 2h1M22 2h2M25 2h1M27 2h1M29 2h2M32 2h3M36 2h2M39 2h1M0 3h1M2 3h2M5 3h1M7 3h2M10 3h1M12 3h4M17 3h2M20 3h1M22 3h2M25 3h1M27 3h2M30 3h1M32 3h3M36 3h3M0 4h1M2 4h2M5 4h1M7 4h2M10 4h1M12 4h4M17 4h2M20 4h1M25 4h1M27 4h2M30 4h1M34 4h1M38 4h1M0 5h1M2 5h2M5 5h1M9 5h2M12 5h1M15 5h1M19 5h1M22 5h2M25 5h1M27 5h2M30 5h1M32 5h2M35 5h3M39 5h1M0 6h1M2 6h2M5 6h1M7 6h2M10 6h1M12 6h2M15 6h1M17 6h2M20 6h1M22 6h2M25 6h1M27 6h1M29 6h2M32 6h3M36 6h2M39 6h1M0 7h2M4 7h2M7 7h1M11 7h1M14 7h2M17 7h2M20 7h1M22 7h2M25 7h1M30 7h1M34 7h2M38 7h1"/>
          <path stroke="#ffffff"
                d="M1 1h1M4 1h1M6 1h3M12 1h2M16 1h3M22 1h2M26 1h2M31 1h3M36 1h2M1 2h1M4 2h1M6 2h1M9 2h1M11 2h1M14 2h1M16 2h1M19 2h1M21 2h1M24 2h1M26 2h1M28 2h1M31 2h1M35 2h1M38 2h1M1 3h1M4 3h1M6 3h1M9 3h1M11 3h1M16 3h1M19 3h1M21 3h1M24 3h1M26 3h1M29 3h1M31 3h1M35 3h1M1 4h1M4 4h1M6 4h1M9 4h1M11 4h1M16 4h1M19 4h1M21 4h4M26 4h1M29 4h1M31 4h3M36 4h2M1 5h1M4 5h1M6 5h3M11 5h1M13 5h2M16 5h3M21 5h1M24 5h1M26 5h1M29 5h1M31 5h1M38 5h1M1 6h1M4 6h1M6 6h1M11 6h1M14 6h1M16 6h1M19 6h1M21 6h1M24 6h1M26 6h1M28 6h1M31 6h1M35 6h1M38 6h1M2 7h2M6 7h1M12 7h2M16 7h1M19 7h1M21 7h1M24 7h1M26 7h2M31 7h3M36 7h2"/>
          <path stroke="#222033" d="M28 7h1M1 8h4M6 8h2M11 8h4M16 8h1M19 8h1M21 8h1M24 8h1M26 8h2M31 8h3M36 8h2"/>
        </svg>
      );
    }
  },
  "bailTitle": {
    width: 19,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 19 9" shapeRendering="crispEdges">
          <path stroke="#360909"
                d="M1 0h3M7 0h2M10 0h7M0 1h1M4 1h1M6 1h1M9 1h2M14 1h1M16 1h1M0 2h1M2 2h2M5 2h1M7 2h2M10 2h2M13 2h2M16 2h1M0 3h1M2 3h2M5 3h1M7 3h2M10 3h2M13 3h2M16 3h1M0 4h1M4 4h2M10 4h2M13 4h2M16 4h1M0 5h1M2 5h2M5 5h1M7 5h2M10 5h2M13 5h2M16 5h1M0 6h1M2 6h2M5 6h1M7 6h2M10 6h2M13 6h2M16 6h3M0 7h1M4 7h1M7 7h2M10 7h1M14 7h1M18 7h1M18 8h1"/>
          <path stroke="#ff8477"
                d="M1 1h3M7 1h2M11 1h3M15 1h1M1 2h1M4 2h1M6 2h1M9 2h1M12 2h1M15 2h1M1 3h1M4 3h1M6 3h1M9 3h1M12 3h1M15 3h1M1 4h3M6 4h4M12 4h1M15 4h1M1 5h1M4 5h1M6 5h1M9 5h1M12 5h1M15 5h1M1 6h1M4 6h1M6 6h1M9 6h1M12 6h1M15 6h1M1 7h3M6 7h1M9 7h1M11 7h3M15 7h3"/>
          <path stroke="#1a0303" d="M1 8h3M6 8h1M9 8h1M11 8h3M15 8h3"/>
        </svg>
      );
    }
  },
  "warningDoNotUse": {
    width: 9,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 9 9" shapeRendering="crispEdges">
          <path stroke="#620922" d="M2 0h5M1 1h1M7 1h1"/>
          <path stroke="#f43636" d="M2 1h5M1 2h3M5 2h3M1 3h3M5 3h3M1 4h3M5 4h3M1 5h7M2 6h2M5 6h2"/>
          <path stroke="#360110" d="M0 2h1M8 2h1M0 3h1M8 3h1M0 4h1M8 4h1M0 5h1M8 5h1M0 6h1M8 6h1M1 7h1M7 7h1M2 8h5"/>
          <path stroke="#ffffff" d="M4 2h1M4 3h1M4 4h1M4 6h1"/>
          <path stroke="#c51446" d="M1 6h1M7 6h1M2 7h5"/>
        </svg>
      );
    }
  },

  "warningFuryIcon": {
    width: 11,
    height: 10,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 11 10" shapeRendering="crispEdges">
          <path stroke="#161301" d="M4 0h3M3 1h1M7 1h1M2 2h1M8 2h1M2 3h1M8 3h1M1 4h1M9 4h1M1 5h1M9 5h1M0 6h1M10 6h1M0 7h1M10 7h1M0 8h1M10 8h1M1 9h9" />
          <path stroke="#d8d570" d="M4 1h3" />
          <path stroke="#d5d14c" d="M3 2h5M3 3h2M6 3h2M2 4h3M6 4h3M2 5h3M6 5h3M1 6h9M1 7h4M6 7h4" />
          <path stroke="#39380a" d="M5 3h1M5 4h1M5 5h1M5 7h1" />
          <path stroke="#c3bf35" d="M1 8h9" />
        </svg>
      );
    }
  },

  "saveDisk": {
    width: 8,
    height: 8,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 8 8" shapeRendering="crispEdges">
          <path stroke="#5f5564"
                d="M0 0h7M0 1h1m6 0h1M0 2h1m6 0h1M0 3h1m6 0h1M0 4h1m6 0h1M0 5h1m6 0h1M0 6h1m6 0h1M0 7h8"/>
          <path stroke="#9e95a3" d="M1 1h1m1 0h1m2 0h1"/>
          <path stroke="#fff" d="M2 1h1m1 0h2M2 2h1m1 0h2M2 3h4"/>
          <path stroke="#847e87" d="M1 2h1m1 0h1m2 0h1M1 3h1m4 0h1M1 4h6M1 5h6M1 6h1m4 0h1"/>
          <path stroke="#9fc2ff" d="M2 6h4"/>
        </svg>
      );
    }
  },

  "unread": { //"new"
    width: 19,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 19 9" shapeRendering="crispEdges">
          <path stroke="#ff0404"
                d="M0 0h19M0 1h19M0 2h2m3 0h3m2 0h2m1 0h1m1 0h1m1 0h2M0 3h2m1 0h2m1 0h1m1 0h2m1 0h1m1 0h1m1 0h1m1 0h2M0 4h2m1 0h2m1 0h1m4 0h1m1 0h1m1 0h1m1 0h2M0 5h2m1 0h2m1 0h1m1 0h4m1 0h1m1 0h1m1 0h2M0 6h2m1 0h2m1 0h2m3 0h2m4 0h2M0 7h19"/>
          <path stroke="#fff"
                d="M2 2h3m3 0h2m2 0h1m1 0h1m1 0h1M2 3h1m2 0h1m1 0h1m2 0h1m1 0h1m1 0h1m1 0h1M2 4h1m2 0h1m1 0h4m1 0h1m1 0h1m1 0h1M2 5h1m2 0h1m1 0h1m4 0h1m1 0h1m1 0h1M2 6h1m2 0h1m2 0h3m2 0h4"/>
          <path stroke="#c4043f" d="M0 8h19"/>
        </svg>
      );
    }
  },
  "unreadBadge": {
    width: 9,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 9 9" shapeRendering="crispEdges">
          <path stroke="#8b381a" d="M1 0h7M0 1h1m7 0h1M0 2h1m7 0h1M0 3h1"/>
          <path stroke="#ff6848" d="M1 1h7"/>
          <path stroke="#f80b0b" d="M1 2h3m1 0h3M1 3h3m1 0h3M1 4h3m1 0h3M1 5h7M1 6h3m1 0h3"/>
          <path stroke="#fff" d="M4 2h1M4 3h1M4 4h1M4 6h1"/>
          <path stroke="#7c260e" d="M8 3h1M0 4h1m7 0h1M0 5h1m7 0h1M0 6h1m7 0h1M0 7h1m7 0h1M1 8h7"/>
          <path stroke="#dc0d1a" d="M1 7h7"/>
        </svg>
      );
    }
  },

  "swap": {
    width: 8,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 8 9" shapeRendering="crispEdges">
          <path stroke="#07bfdd"
                d="M2 0h1M2 1h2M1 2h4M0 3h1M2 3h2M7 3h1M0 4h1M2 4h1M5 4h1M7 4h1M0 5h1M4 5h2M7 5h1M3 6h4M4 7h2M5 8h1"/>
          <path stroke="#7de2f2" d="M0 2h1M7 6h1"/>
        </svg>
      );
    }
  },

  "pauseBack": {
    width: 10,
    height: 11,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 10 11" shapeRendering="crispEdges">
          <path stroke="#222033"
                className="Dpad--NoShow"
                d="M1 0h8M0 1h1M9 1h1M0 2h1M9 2h1M0 3h1M9 3h1M0 4h1M9 4h1M0 5h1M9 5h1M0 6h1M9 6h1M0 7h1M9 7h1M0 8h1M9 8h1M0 9h1M9 9h1M1 10h8"/>
          <path className="Dpad--NoShow" stroke="#f8f8f8" d="M1 1h8M1 2h8M1 3h4M6 3h3M1 4h3M6 4h3M1 5h2M6 5h3M1 6h3M6 6h3M1 7h4M6 7h3M1 8h8"/>
          <path className="Dpad--NoShow" stroke="#39393b" d="M5 3h1M4 4h2M3 5h3M4 6h2M5 7h1"/>
          <path className="Dpad--NoShow" stroke="#d0cfde" d="M1 9h8"/>
        </svg>
      );
    }
  },

  "mail": {
    width: 9,
    height: 7,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 9 7" shapeRendering="crispEdges">
          <path stroke="#aca1a1" d="M0 0h9"/>
          <path stroke="#887979"
                d="M0 1h2M7 1h2M0 2h1M2 2h1M6 2h1M8 2h1M0 3h1M3 3h3M8 3h1M0 4h1M2 4h1M6 4h1M8 4h1M0 5h2M7 5h2"/>
          <path stroke="#fffdfd" d="M2 1h5M1 2h1M7 2h1"/>
          <path stroke="#ece3e3" d="M3 2h3M1 3h2M6 3h2M1 4h1M3 4h3M7 4h1M2 5h5"/>
          <path stroke="#6e6969" d="M0 6h9"/>
        </svg>
      );
    }
  },

  "selectedCheck": {
    width: 13,
    height: 13,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 13 13" shapeRendering="crispEdges">
          <path stroke="#27cf60" d="M0 0h12M0 1h12M0 2h12M0 3h12M0 4h8M9 4h3M0 5h7M9 5h3M0 6h4M5 6h1M8 6h4M0 7h4M7 7h5M0 8h5M6 8h6M0 9h12M0 10h12M0 11h12" />
          <path stroke="#13af75" d="M12 0h1M12 1h1M12 2h1M12 3h1M12 4h1M12 5h1M12 6h1M12 7h1M12 8h1M12 9h1M12 10h1M12 11h1M0 12h13" />
          <path stroke="#ffffff" d="M8 4h1M7 5h1M4 6h1M6 6h1M5 7h1" />
          <path stroke="#1cbe53" d="M8 5h1M7 6h1M4 7h1M6 7h1M5 8h1" />
        </svg>
      )
    }
  },

  // "theDangerCrewLogo": {
  //   width: 48,
  //   height: 35,
  //   svg(style) {
  //     return (
  //       <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 48 35" shapeRendering="crispEdges">
  //         <path stroke="#222034"
  //               d="M12 1h1m5 0h13m5 0h1M9 2h3m1 0h3m2 0h1m3 0h1m1 0h1m1 0h1m3 0h1m2 0h3m1 0h3M9 3h1m5 0h1m2 0h2m1 0h2m1 0h1m1 0h1m1 0h3m2 0h1m5 0h1M10 4h1m3 0h1m4 0h1m1 0h2m3 0h1m2 0h1m4 0h1m3 0h1M10 5h1m1 0h1m1 0h1m4 0h1m1 0h2m1 0h1m1 0h1m1 0h3m3 0h1m1 0h1m1 0h1M10 6h5m4 0h1m1 0h2m1 0h1m1 0h1m3 0h1m3 0h5M19 7h12M0 9h7m1 0h40M0 10h1m6 0h2m7 0h1m6 0h2m7 0h1m6 0h1m7 0h1M0 11h1m7 0h1m7 0h1m7 0h1m7 0h1m6 0h1m7 0h1M0 12h1m7 0h1m7 0h1m7 0h1m7 0h1m6 0h1m7 0h1M0 13h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h5m3 0h4m3 0h1m3 0h1M0 14h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h5m6 0h1m3 0h1m3 0h1M0 15h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m6 0h1m3 0h1m3 0h1M0 16h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m6 0h1m3 0h1m3 0h1M0 17h1m3 0h1m3 0h1m7 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h4m6 0h2M0 18h1m7 0h1m7 0h1m3 0h1m3 0h1m7 0h1m6 0h1m6 0h2M0 19h1m7 0h1m3 0h1m3 0h1m3 0h1m3 0h1m7 0h1m6 0h1m3 0h1m3 0h1M0 20h1m7 0h1m3 0h1m3 0h1m3 0h1m3 0h1m7 0h1m6 0h1m3 0h1m3 0h1M0 21h48M0 22h8m6 0h1m7 0h1m6 0h1m3 0h4m3 0h8M7 23h1m6 0h1m7 0h1m6 0h1m3 0h1m2 0h1m3 0h1M7 24h1m6 0h1m7 0h1m6 0h1m3 0h1m2 0h1m3 0h1M7 25h1m3 0h4m3 0h1m3 0h1m3 0h4m3 0h1m2 0h1m3 0h1M7 26h1m3 0h4m3 0h1m3 0h1m6 0h1m3 0h4m3 0h1M7 27h1m3 0h1m6 0h1m3 0h1m6 0h1m3 0h1m2 0h1m3 0h1M7 28h1m3 0h1m6 0h1m3 0h1m6 0h1m3 0h1m2 0h1m3 0h1M7 29h1m3 0h4m6 0h2m3 0h4m3 0h1m2 0h1m3 0h1M7 30h1m6 0h1m6 0h2m6 0h1m3 0h1m2 0h1m3 0h1M7 31h1m6 0h1m3 0h1m3 0h1m6 0h1m10 0h1M7 32h1m6 0h1m3 0h1m3 0h1m6 0h1m10 0h1M7 33h34M7 34h34"/>
  //         <path stroke="#f9d339"
  //               d="M12 2h1m23 0h1M11 5h1m1 0h1m21 0h1m1 0h1M1 10h6m2 0h7m1 0h6m2 0h7m1 0h6m1 0h7M1 13h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m5 0h3m4 0h3m1 0h3M1 14h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m5 0h6m1 0h3m1 0h3M1 15h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h6m1 0h3m1 0h3M1 20h7m1 0h3m1 0h3m1 0h3m1 0h3m1 0h7m1 0h6m1 0h3m1 0h3M8 22h6m1 0h7m1 0h6m1 0h3m4 0h3M8 25h3m4 0h3m1 0h3m1 0h3m4 0h3m4 0h3M8 26h3m4 0h3m1 0h3m1 0h6m1 0h3m4 0h3M8 27h3m4 0h3m1 0h3m1 0h6m1 0h3m1 0h2m1 0h3M8 32h6m1 0h3m1 0h3m1 0h6m1 0h10"/>
  //         <path stroke="#fff" d="M19 2h3m1 0h1m1 0h1m1 0h3"/>
  //         <path stroke="#fafda4"
  //               d="M10 3h5m19 0h5M1 11h7m1 0h7m1 0h7m1 0h7m1 0h6m1 0h7M1 12h7m1 0h7m1 0h7m1 0h7m1 0h6m1 0h7M8 23h6m1 0h7m1 0h6m1 0h3m4 0h3M8 24h6m1 0h7m1 0h6m1 0h3m4 0h3"/>
  //         <path stroke="#cdd8db"
  //               d="M20 3h1m2 0h1m1 0h1m1 0h1m-8 1h1m2 0h3m1 0h2m-9 1h1m2 0h1m1 0h1m1 0h1m-8 1h1m2 0h1m1 0h1m1 0h3"/>
  //         <path stroke="#f39646"
  //               d="M11 4h3m21 0h3M1 16h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h6m1 0h3m1 0h3M1 17h3m1 0h3m1 0h7m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m4 0h6M1 18h7m1 0h7m1 0h3m1 0h3m1 0h7m1 0h6m1 0h6M1 19h7m1 0h3m1 0h3m1 0h3m1 0h3m1 0h7m1 0h6m1 0h3m1 0h3M8 28h3m4 0h3m1 0h3m1 0h6m1 0h3m1 0h2m1 0h3M8 29h3m4 0h6m2 0h3m4 0h3m1 0h2m1 0h3M8 30h6m1 0h6m2 0h6m1 0h3m1 0h2m1 0h3M8 31h6m1 0h3m1 0h3m1 0h6m1 0h10"/>
  //       </svg>
  //     );
  //   }
  // }

  "theDangerCrewLogo": {
    width: 54,
    height: 34,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 54 34" shapeRendering="crispEdges">
          <path stroke="#1d1946" d="M26 0h1m-4 1h3m1 0h3m-7 1h1m5 0h1m-6 1h1m3 0h1m-5 1h1m1 0h1m1 0h1m-5 1h5M3 8h7m1 0h40M3 9h1m6 0h2m7 0h1m6 0h2m7 0h1m6 0h1m7 0h1M3 10h1m7 0h1m7 0h1m7 0h1m7 0h1m6 0h1m7 0h1M3 11h1m7 0h1m7 0h1m7 0h1m7 0h1m6 0h1m7 0h1M3 12h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h5m3 0h4m3 0h1m3 0h1M3 13h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h5m6 0h1m3 0h1m3 0h1M3 14h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m6 0h1m3 0h1m3 0h1M3 15h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h1m6 0h1m3 0h1m3 0h1M3 16h1m3 0h1m3 0h1m7 0h1m3 0h1m3 0h1m3 0h1m3 0h1m3 0h4m6 0h2M3 17h1m7 0h1m7 0h1m3 0h1m3 0h1m7 0h1m6 0h1m6 0h2M3 18h1m7 0h1m3 0h1m3 0h1m3 0h1m3 0h1m7 0h1m6 0h1m3 0h1m3 0h1M3 19h1m7 0h1m3 0h1m3 0h1m3 0h1m3 0h1m7 0h1m6 0h1m3 0h1m3 0h1M3 20h48M3 21h8m6 0h1m7 0h1m6 0h1m3 0h4m3 0h8m-41 1h1m6 0h1m7 0h1m6 0h1m3 0h1m2 0h1m3 0h1m-34 1h1m6 0h1m7 0h1m6 0h1m3 0h1m2 0h1m3 0h1m-34 1h1m3 0h4m3 0h1m3 0h1m3 0h4m3 0h1m2 0h1m3 0h1M3 25h1m6 0h1m3 0h4m3 0h1m3 0h1m6 0h1m3 0h4m3 0h1m6 0h1M0 26h3m1 0h3m3 0h1m3 0h1m2 0h1m3 0h1m3 0h1m6 0h1m3 0h1m2 0h1m3 0h1m3 0h3m1 0h3M0 27h1m5 0h1m3 0h1m3 0h1m2 0h1m3 0h1m3 0h1m6 0h1m3 0h1m2 0h1m3 0h1m3 0h1m5 0h1M1 28h1m3 0h1m4 0h1m3 0h4m6 0h2m3 0h4m3 0h1m2 0h1m3 0h1m4 0h1m3 0h1M1 29h1m1 0h1m1 0h1m4 0h1m6 0h1m6 0h2m6 0h1m3 0h1m2 0h1m3 0h1m4 0h1m1 0h1m1 0h1M1 30h5m4 0h1m6 0h1m3 0h1m3 0h1m6 0h1m10 0h1m4 0h5m-43 1h1m6 0h1m3 0h1m3 0h1m6 0h1m10 0h1m-34 1h34m-34 1h34"/>
          <path stroke="#cdd8db" d="M26 1h1m-2 2h3m-3 1h1m1 0h1M3 26h1m46 0h1M2 28h3m44 0h3M2 29h1m1 0h1m44 0h1m1 0h1"/>
          <path stroke="#fff" d="M24 2h5M1 27h5m42 0h5"/>
          <path stroke="#f9d339" d="M4 9h6m2 0h7m1 0h6m2 0h7m1 0h6m1 0h7M4 12h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m5 0h3m4 0h3m1 0h3M4 13h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m5 0h6m1 0h3m1 0h3M4 14h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h6m1 0h3m1 0h3M4 19h7m1 0h3m1 0h3m1 0h3m1 0h3m1 0h7m1 0h6m1 0h3m1 0h3m-39 2h6m1 0h7m1 0h6m1 0h3m4 0h3m-32 3h3m4 0h3m1 0h3m1 0h3m4 0h3m4 0h3m-32 1h3m4 0h3m1 0h3m1 0h6m1 0h3m4 0h3m-32 1h3m4 0h3m1 0h3m1 0h6m1 0h3m1 0h2m1 0h3m-32 5h6m1 0h3m1 0h3m1 0h6m1 0h10"/>
          <path stroke="#fafda4" d="M4 10h7m1 0h7m1 0h7m1 0h7m1 0h6m1 0h7M4 11h7m1 0h7m1 0h7m1 0h7m1 0h6m1 0h7M11 22h6m1 0h7m1 0h6m1 0h3m4 0h3m-32 1h6m1 0h7m1 0h6m1 0h3m4 0h3"/>
          <path stroke="#f39646" d="M4 15h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m1 0h6m1 0h3m1 0h3M4 16h3m1 0h3m1 0h7m1 0h3m1 0h3m1 0h3m1 0h3m1 0h3m4 0h6M4 17h7m1 0h7m1 0h3m1 0h3m1 0h7m1 0h6m1 0h6M4 18h7m1 0h3m1 0h3m1 0h3m1 0h3m1 0h7m1 0h6m1 0h3m1 0h3m-39 9h3m4 0h3m1 0h3m1 0h6m1 0h3m1 0h2m1 0h3m-32 1h3m4 0h6m2 0h3m4 0h3m1 0h2m1 0h3m-32 1h6m1 0h6m2 0h6m1 0h3m1 0h2m1 0h3m-32 1h6m1 0h3m1 0h3m1 0h6m1 0h10"/>
        </svg>

      )
    }
  }


};