import React, {Component} from 'react';
import {connect} from 'react-redux'
import GameContainer from './GameFrame/GameFrame'
import OverworldContainer from "./Overworld/OverworldContainer";
import OverworldMessages from './OverworldMessages'
import BattleContainer from './BattleContainer'
import StubComingSoonScreen from './StubComingSoonScreen'
import CreditsScreen from './CreditsScreen'
import {chapter} from "./ChapterGetter";
import INITIAL_CHAPTER from "./Overworld/DEV_CHAPTER";
import PauseMenuContainer from './Pause/PauseMenuContainer'
import CreatePersonMenu from './CreatePersonMenu'
import OverworldFader from './Overworld/OverworldFader'
import ChangingChapter from './Overworld/ChangingChapter'
import {setOverworldValue} from "./redux-actions/overworld-actions";
import OverworldBackground from './Overworld/OverworldBackground'
import RecoverMenuContainer from './RecoverMenu/RecoverMenu'
import {setGlobalValue} from "./redux-actions/global-actions";
import {getPixelSize} from "./GameFrame/getPixelSize";
import TitleScreen from './title/TitleScreen'
import AuthFormContainer from "./Auth/AuthFormContainer";
import getFirebaseConfig from './getFirebaseConfig'
import SavingIndicator from './SavingIndicator'
//import {checkLaunchImpersonate} from './impersonation/checkLaunchImpersonate'

let firebase = require('firebase/app');
require('firebase/auth');


//Set up the initial chapter.
//if dev?... //maybe ds2017 chapter preview logic check will go here? maybe this can get broken out into its own function that is only for dev
//Note: this stuff doesn't mount in the changeChapter state, but this is basically the same. I just want to be set up to launch right in a battle, etc.
chapter.set(INITIAL_CHAPTER);
setOverworldValue({
  currentMapId: INITIAL_CHAPTER.startingMapId
});


//Set the initial pixel value before any mounting occurs
setGlobalValue({
  // windowWidth: window.innerWidth,
  // windowHeight: window.innerHeight,
  isMobileViewport: window.innerWidth <= 600,
  pixelSize: getPixelSize(window.innerWidth, window.innerHeight)
});


class App extends Component {

  componentDidMount() {
    //Bind resize handler
    let resizeTimeout;
    window.onresize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        setGlobalValue({
          isMobileViewport: window.innerWidth <= 600,
          pixelSize: getPixelSize(window.innerWidth, window.innerHeight)
        });
      }, 200);
    };

    //Firebase auth
    firebase.initializeApp(getFirebaseConfig());



    firebase.auth().onAuthStateChanged((user) => {
      //console.log(user)
      setGlobalValue({
        authState: function() {
          if (user && user.isAnonymous) { return "guest" }
          return user ? "loggedIn" : "guest";
        }(),
        authUserId: user? user.uid : null
      });


      if (!user) {
        //Attempt to sign all non-users in as anon
        //console.log('signing in as anon')
        firebase.auth().signInAnonymously().catch((anonSignInError) => {
          // // Handle Errors here.
          if (anonSignInError) {
            console.log("anonSignInError!", anonSignInError)
          }
        });
      }


      //Admin might want to impersonate a user's account
      //checkLaunchImpersonate();
    });
  }

  renderGameArea() {
    const {pixelSize, gameArea, isPauseMenuOpen, showCreatePersonMenu, isRecoverMenuOpen, isSavingIndicatorOpen} = this.props;

    if (gameArea === "changingChapter") {
      return <ChangingChapter />
    }

    if (gameArea === "overworld") {
      return (
        <React.Fragment>
          <OverworldBackground
            pixelSize={pixelSize}
          />
          <OverworldContainer
            pixelSize={pixelSize}
          />
          <OverworldMessages
            pixelSize={pixelSize}
          />
          {
            isPauseMenuOpen && (
              <PauseMenuContainer />
            )
          }
          {
            Boolean(showCreatePersonMenu) && (
              <CreatePersonMenu />
            )
          }
          {
            isRecoverMenuOpen && (
              <RecoverMenuContainer />
            )
          }
          {
            isSavingIndicatorOpen && (
              <SavingIndicator pixelSize={pixelSize} />
            )
          }
          <OverworldFader />
        </React.Fragment>
      )
    }

    if (gameArea === "battle") {
      return (
        <BattleContainer />
      )
    }


    if (gameArea === "title") {
      return (
        <TitleScreen />
      )
    }

    if (gameArea === "credits") {
      return (
        <CreditsScreen />
      )
    }
    if (gameArea === "comingSoon") {
      return (
        <StubComingSoonScreen />
      )
    }


    return <div style={{color: "white"}}>No GameArea match!</div>;
  }

  render() {
    const {pixelSize, launchAuthMenuInState} = this.props;
    return (
      <GameContainer
        pixelSize={pixelSize}
        render={() => {
          return (
            <React.Fragment>
              {this.renderGameArea()}
              {
                Boolean(launchAuthMenuInState) && (
                  <AuthFormContainer
                    launchAuthMenuInState={launchAuthMenuInState}
                  />
                )
              }

            </React.Fragment>
          )
        }}
      />
    );
  }
}

export default connect((state, props) => {
  return {
    pixelSize: state.global.pixelSize,
    gameArea: state.global.gameArea,
    launchAuthMenuInState: state.global.launchAuthMenuInState,
    isPauseMenuOpen: state.overworld.isPauseMenuOpen,
    isRecoverMenuOpen: state.overworld.isRecoverMenuOpen,
    showCreatePersonMenu: state.overworld.showCreatePersonMenu,
    isSavingIndicatorOpen: state.overworld.isSavingIndicatorOpen
  }
})(App)