import {DefaultAction} from "../Action";

export function createHurtByLeak(properties={}) {

  return {
    ...DefaultAction,

    getUse() { return [] },

    successEventsPerTarget(casterModel, targetModel, allUpgrades) {

      const hpChange = Math.round(targetModel.maxHp * properties.drainPercent);
      const preText = properties.preText;

      return [
        {
          type: "eventAnimation",
          animationId: "animationLeak",
          subjects: [targetModel.id],
          delayFramesAfter: 12,
          useSfx: properties.useSfx || null
        },
        {
          type: "eventBlippy",
          stateChanges: [
            {
              combatantId: targetModel.id,
              relativeChanges: {
                hp: hpChange
              },
              message: {
                theme: "battleNegativeHp",
                text: `${preText} ${hpChange} HP`
              },
            }
          ]
        }
      ]
    }
  }
}

export const NaturalHurtByLeak = createHurtByLeak({
  drainPercent: -0.15,
  preText: "Leak"
});

export const NaturalHurtByFire = createHurtByLeak({
  drainPercent: -0.33,
  preText: "Fire Burn",
  useSfx: "sfx_burn"
});