import {shuffle} from "../../../helpers/shuffle";

export default function({combatants=[], battleStoryPoints=[], regularDecision}) {

  //First enemy is the recruiter.
  const recruiter = combatants.find(c => c.belongsToTeam === "two");

  //Player
  const hero = combatants.find(c => c.belongsToTeam === "one");

  if (!battleStoryPoints.includes("recruiter-taunt") || shuffle([true, false, false])[0]) {

    let updatedStoryPoints = [...battleStoryPoints];
    if (!updatedStoryPoints.includes("recruiter-taunt")) {
      updatedStoryPoints.push("recruiter-taunt")
    }

    return {
      submission: [
        {
          actionId: "speak",
          casterId: recruiter.id,
          targetIds: [hero.id],
          mergeData: {
            dialogContent: [
              shuffle([
                "Why haven't you responded to my emails?",
                "Why haven't you responded to my emails?",
                "You know all the frameworks, right?",
                "What do I have to do to get you to say YES?",
              ])[0]
            ]
          }
        },
        {
          actionId: "script_blackberry",
          casterId: recruiter.id,
          targetIds: [hero.id],
        },
      ],
      updatedBattleStoryPoints: updatedStoryPoints
    }
  }




  //Maybe submit something custom, OR just use regularDecision which goes through the usual decision class
  //console.log('default to this', regularDecision)
  return {
    submission: regularDecision,
    updatedBattleStoryPoints: battleStoryPoints
  }
}
