import {DefaultAction} from "../Action";

export default {
  ...DefaultAction,
  name: "Speak",
  cannotBeReplaced: true,

  getUse() {
    return []
  },

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {
    const dialogContent = this.dialogContent || ["Speak"]; //mergedData
    const preDelayFrames = this.preDelayFrames || 0; //mergeData, default to no delay
    const subject = this.subject || null;

    //Decide the sfx to use
    let sfx = this.useSfx || null; //maybe just custom SFX (like arena zap decision does)
    if (casterModel.hasEscaped) {
      sfx = "SKIP_JUMP_SFX"; //maybe skip if the person Escaped
    }

    return [
      preDelayFrames > 0 ? {
        type: "eventBlippy", //faking a "Wait" because battle doesn't have that
        stateChanges: [
          {
            combatantId: targetModel.id,
          }
        ],
        delayFramesAfter: preDelayFrames
      } : null,
      {
        type: "eventAnimation",
        animationId: "characterJump",
        subjects: [casterModel.id],
        delayFramesAfter: 30,
        useSfx: sfx
      },
      {
        type: "eventDialogBox",
        subject: subject,
        textArray: dialogContent,
        haltQueueUntilDone: true
      },
    ].filter(d => d)
  }
}