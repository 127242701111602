//WATCH OUT: This is not the source of truth. Please copy over from DS2017/AllCombatants
//This is just for dev testing
export default [
  {
    id: "enemyA",
    name: "Richard2",
    class: "lead",
    level: 23,
    skinId: "macrodomeCasual",
    playerVictoryMessage: "I don't believe it!",
    playerDefeatMessage: "Say goodbye.",
    attacks: [
      {actionId: "script_bug"},
      {actionId: "script_clean"},
      {actionId: "script_slice-mk-iii"},
      {actionId: "script_screen-blur"},
    ],
    useMusic: "music_richard2",
    useCustomMaxHp: 900,
    useCustomLifebar: "richard2",
    useScriptedDecisionFn: "richard2",
    items: [
    ],
    immunities: ["lag", "nervous"],
    isImmuneToRageQuit: true
  },
].map(e => {
  return {
    ...e,
    belongsToTeam: "two"
  }
})