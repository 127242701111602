//Take special values (EX: from the map editor) that need to look up values based on context.
//EX: get the skin of my crew in slot memberB
export function getDynamicOverworldValues(input={}, playerData={}) {

  let payload = {...input};

  Object.keys(payload).forEach(key => {

    const value = payload[key];



    if (typeof value === "string" && value.includes("[dynamic_member_skin]::")) {
      const memberId = value.split("[dynamic_member_skin]::")[1];
      const member = (playerData.acquiredMembers || []).find(m => m.id === memberId);
      if (member) {
        payload[key] = member.skinId || "mystery"
      } else {
        payload[key] = "mystery"
      }
    }

  });


  return payload;
}