import React from "react";
import { connect } from "react-redux";
import { waitFrames } from "./helpers/waitFrames";
import { setOverworldValue } from "./redux-actions/overworld-actions";
import { getNextExternalEventTrigger } from "./Overworld/getIncrExternalEventTrigger";
import BoxDiv from "./interface/BoxDiv";
import BoxDivBody from "./interface/BoxDivBody";
import StaticTextLine from "./StaticTextLine";
import Icon from "./interface/Icon";
import { handleSaveGameEvent } from "./loading/handleSaveGame";
import BoxDivTippyTop from "./interface/BoxDivTippyTop";
import { audioManager } from "./audio/audioManager";
import {handleTrackEvent} from "./loading/handleTrackEvent";
import store from './init/store'

class JournalPopup extends React.Component {
  state = {
    isShowing: false
  };

  componentDidMount() {

    //Keep a timer so subsequent Journal Popups don't get nulled out early.
    //EX: 1.. 2.....! where 1's expiration callback used to knock out 2 like: 1...2.
    //This only happens if you're speed run talking to everybody super fast and happen to get multiple popups.
    setOverworldValue({
      validJournalExpireTime: Date.now() + 3000
    });

    //SIDE EFFECT: Track what happened in Firebase
    handleTrackEvent({
      eventType: "journalPopup",
      ...this.props.journalPopup
    });
    // END SIDE EFFECT



    this.safeToUpdate = true;
    //Wait a bit before we show the popup (otherwise it collides with the previous textbox and is a little jarring)
    waitFrames(15, () => {
      this.revealAndScheduleRemove();
      if (this.safeToUpdate) {
        this.setState({
          isShowing: true
        });
      }
    });
  }

  revealAndScheduleRemove() {
    audioManager.playSfx("sfx_journal");

    //Unlock the overworld right away
    setOverworldValue({
      externalEventTrigger: getNextExternalEventTrigger(
        "OVERWORLD-JOURNALPOPUP_SHOWN"
      )
    });

    //Side effect! Fire an auto save:
    waitFrames(100, () => {
      handleSaveGameEvent();
    });

    //Keep the window up for a bit, then close
    waitFrames(220, () => {

      const validJournalExpireTime = store.getState().overworld.validJournalExpireTime;
      if (Date.now() > validJournalExpireTime) {
        //Check to make sure the popup has been up long enough (another one may pop up, so we don't want the previous popup to prematurely knock out this one)
        setOverworldValue({ journalPopup: null });
      } else {
        //console.log('NOPE. NEED TO KEEP IT UP LONGER')
        //Another popup has occured which will cancel this one whenever it's ready.
      }


    });
  }

  componentWillUnmount() {
    //unmount if this gets forced out by a textbox or something else that blows this one away
    setOverworldValue({ journalPopup: null });
    this.safeToUpdate = false;
  }

  render() {
    if (!this.state.isShowing) {
      return null;
    }

    const { journalPopup, pixelSize } = this.props;
    const text = journalPopup.isComplete
      ? "Journal complete!"
      : "Journal updated!";
    return (
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          display: "flex",
          justifyContent: "center"
        }}
      >
        <BoxDiv className="Dpad--NoShow" pixelSize={pixelSize} style={{ width: pixelSize * 130 }}>
          <BoxDivTippyTop pixelSize={pixelSize} />
          <BoxDivBody
            className="Dpad--NoShow"
            pixelSize={pixelSize}
            style={{
              paddingLeft: pixelSize * 2,
              paddingRight: pixelSize * 2,
              paddingTop: pixelSize * 4,
              paddingBottom: pixelSize * 4
            }}
          >
            <div className="Dpad--NoShow" style={{ display: "flex", justifyContent: "center" }}>
              <div
                className="Dpad--NoShow"
                style={{
                  position: "relative",
                  left: pixelSize * 6,
                  top: journalPopup.isComplete ? pixelSize * 4 : pixelSize * 2
                }}
              >
                <Icon
                  pixelSize={pixelSize}
                  category={journalPopup.isComplete ? "check" : "journal"}
                />
              </div>
              <div className="Dpad--NoShow" style={{ marginLeft: pixelSize * 10 }}>
                <StaticTextLine
                  pixelSize={pixelSize}
                  text={text.toUpperCase()}
                />
              </div>
            </div>
            <div className="Dpad--NoShow" style={{ display: "flex", justifyContent: "center" }}>
              <StaticTextLine pixelSize={pixelSize} text={journalPopup.name} />
            </div>
          </BoxDivBody>
        </BoxDiv>
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    pixelSize: state.global.pixelSize,
    journalPopup: state.overworld.journalPopup
  };
})(JournalPopup);
