import React from 'react'
import {connect} from 'react-redux'
import MemberFaceLifebar from './MemberFaceLifebar'
import {getBattleCrew} from "../player-data-operations/getBattleCrew";
import {getCombatantStats} from "../../Battle7/getCombatantStats";
import {getCurrentLevelFromXp} from "../../getCurrentLevelFromXp";
//import {getLevelFromCurrentXp} from "../xp/xp-helpers";
import Icon from '../../interface/Icon'
import { handleUnpause } from "../../Pause/handleUnpause";

class OverworldCrewHud extends React.Component {

  render() {
    const {pixelSize, battleCrew, level, handlePauseClick, showNotification, isPauseMenuOpen} = this.props;
    return (
      <div
        onClick={() => {

          //Maybe unpause
          if (isPauseMenuOpen) {
            handleUnpause();
          } else {
            handlePauseClick()
          }

        }}
        style={{
          cursor: "pointer",
        position: "fixed", left: 0, top: 0, paddingLeft: pixelSize * 2, paddingTop: pixelSize * 2, zIndex: 1
      }}>
        {
          showNotification && (
            <div style={{ position: "absolute", left:pixelSize, top: pixelSize, zIndex: 1}}>
              <Icon
                pixelSize={pixelSize}
                category="unreadBadge"
              />
            </div>
          )
        }
        {
          battleCrew.map((c, i) => {
            const {maxHp} = getCombatantStats({ level }); //TODO: actual level
            return (
              <div key={i} style={{marginBottom:pixelSize * 2}}>
                <MemberFaceLifebar
                  pixelSize={pixelSize}
                  skinId={c.skinId}
                  hp={c.hp}
                  maxHp={maxHp}
                  showLevel={i === 0 ? level : null}
                />
              </div>
            )
          })
        }
      </div>
    )
  }
}

export default connect((state, props) => {
  return {
    level: getCurrentLevelFromXp(state.playerData.teamXp),
    pixelSize: state.global.pixelSize,
    battleCrew: getBattleCrew(state.playerData.acquiredMembers),
    showNotification: state.overworld.showGlobalHudUnreadBadge,
    isPauseMenuOpen: state.overworld.isPauseMenuOpen
  }
})(OverworldCrewHud)