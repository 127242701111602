import {setValue} from '../helpers/reducer-utilities'

export function overworld(state = {}, action) {
  switch (action.type) {

    case "SET_OVERWORLD_VALUE":
      return setValue(state, action.payload.changes);

    default:
      return state;
  }
}