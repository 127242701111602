import {shuffle} from "../../../helpers/shuffle";

export function getHpCuringDecision({casterModel, teamMembers, enemies, teamItems}) {

  const reviveItems = shuffle(teamItems.filter(i => i.actionId === "item_revive-pack" || i.actionId === "item_full-revive-pack"));
  if (reviveItems.length) {
    //Find dead people
    const deadTeamIds = teamMembers.filter(m => m.hp <= 0).map(m => m.id);
    if (deadTeamIds.length) {
      return {
        actionId: reviveItems[0].actionId,
        casterId: casterModel.id,
        targetIds: [shuffle(deadTeamIds)[0]],
        instanceId: reviveItems[0].instanceId,
      }
    }
  }

  const aliveFriendsWithNotFullHp = [casterModel, ...teamMembers].filter(c => typeof c.hp === "number" && c.hp > 0 && c.hp < c.maxHp);
  if (aliveFriendsWithNotFullHp.length === 0) {
    return null
  }

  const personWhoNeedsTheMostHealing = aliveFriendsWithNotFullHp.sort((c1, c2) => c1.hp > c2.hp ? 1 : -1)[0];

  //It might just use Inspire
  if ((casterModel.attacks || []).find(a => a.actionId === "script_inspire") && aliveFriendsWithNotFullHp.length > 1) {
    return {
      actionId: "script_inspire",
      casterId: casterModel.id,
      targetIds: aliveFriendsWithNotFullHp.map(c => c.id)
    }
  }

  const firstBatteryPack = teamItems.find(i => i.actionId === "item_battery-pack");
  const firstFullBatteryPack = teamItems.find(i => i.actionId === "item_full-battery-pack");

  //If we don't have any battery packs, just bail here
  if (!firstBatteryPack && !firstFullBatteryPack) {
    return null;
  }

  let useBatteryPack = function () {
    if (firstFullBatteryPack) {
      const {hp, maxHp} = personWhoNeedsTheMostHealing;
      const percent = hp / maxHp * 100;
      if (percent < 50) {
        return firstFullBatteryPack
      }
    }
    return firstBatteryPack || firstFullBatteryPack;
  }();

  return {
    actionId: useBatteryPack.actionId,
    casterId: casterModel.id,
    targetIds: [personWhoNeedsTheMostHealing.id],
    instanceId: useBatteryPack.instanceId
  };
}