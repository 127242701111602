import React from "react";
import { connect } from "react-redux";
import { getTitleScreenMenu } from "./getTitleScreenMenu";
import ArrowMenu from "../ArrowMenu/ArrowMenu";
import { setGlobalValue } from "../redux-actions/global-actions";
import { waitFrames } from "../helpers/waitFrames";
import { authLogOut } from "../Auth/auth-requests";
import Icon from "../interface/Icon";
import LoadGameMenu from "../loading/LoadGameMenu";
import ArrowMenuOption from "../ArrowMenu/ArrowMenuOption";
import ScrollingMenuOptionLine from "../menu-components/ScrollingMenuOptionLine";
import BoxDiv from "../interface/BoxDiv";
import BoxDivBody from "../interface/BoxDivBody";
import { setPlayerDataValue } from "../redux-actions/player-data-actions";
import { getDefaultPlayerData } from "../init/getDefaultPlayerData";
import BoxDivTippyTop from "../interface/BoxDivTippyTop";

class TitleScreen extends React.Component {
  state = {
    isLoadMenuOpen: false
  };

  handler = (output = {}) => {
    if (output.type === "NEW_GAME") {

      //Reset all player values
      setPlayerDataValue({
        ...getDefaultPlayerData({
          forceDefault: true //Fresh start
        })
      });

      setGlobalValue({
        gameArea: "changingChapter",
        changeToChapterId: "chapterJRQ"
      });
    }
    if (output.type === "CONTINUE_GAME") {
      this.setState({
        isLoadMenuOpen: true
      });
    }
    if (output.type === "CREATE_ACCOUNT") {
      waitFrames(5, () => {
        //so enter key doesnt auto fire
        setGlobalValue({ launchAuthMenuInState: "createaccount" });
      });
    }
    if (output.type === "LOG_IN") {
      waitFrames(5, () => {
        //so enter key doesnt auto fire
        setGlobalValue({ launchAuthMenuInState: "login" });
      });
    }
    if (output.type === "LOG_OUT") {
      authLogOut();
    }

    if (output.type === "CREDITS") {
      setGlobalValue({
        gameArea: "credits"
      })
    }


  };

  renderForm() {
    const { authState, pixelSize, launchAuthMenuInState, isMobileViewport } = this.props;
    if (launchAuthMenuInState || this.state.isLoadMenuOpen || isMobileViewport) { //used to check authState here, but now Title is the same for everybody (local saving)
      return null;
    }

    const { map, menu, backOptionId } = getTitleScreenMenu(authState);

    return (
      <React.Fragment>
        <ArrowMenu
          key={authState} //most menus probably won't to remount. But this one does with the logging out
          handler={this.handler}
          optionsPageId={authState}
          optionsMap={map}
          backOptionId={backOptionId || null}
          optionModels={menu}
          rememberedOptionId={null}
          render={({ handlers, selectedOptionId }) => {
            return (
              <div>
                {map
                  .reduce((row1, row2) => [...row1, ...row2])
                  .map(id => {
                    const model = menu[id];
                    const viewBag = model.viewBag || {};
                    return (
                      <div
                        key={id}
                        style={{
                          display: "block",
                          width: pixelSize * 100,
                          marginBottom: pixelSize * 2,
                          position: "relative",
                          left: pixelSize * 5,
                        }}
                      >
                        <ArrowMenuOption
                          id={id}
                          handlers={handlers}
                          isInCurrentMenu={true}
                        >
                          <ScrollingMenuOptionLine
                            centered={true}
                            pixelSize={pixelSize}
                            text={model.label}
                            icon={model.icon}
                            isDisabled={false}
                            isSelected={selectedOptionId === id}
                            renderOptionRight={
                              this.props.renderOptionRight || null
                            }
                            //textLineTheme="white"
                            {...viewBag}
                          />
                        </ArrowMenuOption>
                      </div>
                    );
                  })}
              </div>
            );
          }}
        />
        {
          // authState === "loggedIn" && (
          //   <LoadGameMenu />
          // )
        }
      </React.Fragment>
    );
  }

  renderTwitterLink() {

    if (process.env.REACT_APP_ELECTRON_BUILD) {
      return (
        <span style={{
          textDecoration: "none",
          padding: "0.5em 1em",
          display: "inline-block"
        }}>
          @dangercrewgame on Twitter
        </span>
      )
    }

    //Web
    return (
      <a style={{
        color: "#08a0e9",
        textDecoration: "none",
        padding: "0.5em 1em",
        display: "inline-block"
      }} target="_blank" rel="noopener noreferrer" href="https://twitter.com/dangercrewgame">
        @dangercrewgame</a>
    )
  }

  render() {
    const { pixelSize, version, isMobileViewport } = this.props;


    const mobileErrorText = process.env.REACT_APP_ELECTRON_BUILD
      ? "Please make the game's window larger!" //Electron
      : "Oh no! This game is not available on mobile browsers! Please try on a Desktop computer."; //Mobile web

    return (
      <React.Fragment>
        <div style={{width: pixelSize * 170, margin: "0 auto"}}>
        <BoxDiv pixelSize={pixelSize}>
          <BoxDivTippyTop pixelSize={pixelSize} />
          <BoxDivBody pixelSize={pixelSize}>
          <div
            style={{
              paddingTop: pixelSize * 6,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative"
            }}
          >
            <main>
              <div style={{ marginBottom: pixelSize * 12, display: "flex", justifyContent: "center" }}>
                <Icon pixelSize={pixelSize * 2} category="theDangerCrewLogo"/>
              </div>
              {this.renderForm()}
              {
                isMobileViewport && (
                  <div style={{
                    textAlign: "center",
                    padding: "1em"
                  }}>
                    {mobileErrorText}
                  </div>
                )
              }
              <div style={{
                fontSize: "1em",
                position: "absolute",
                right: 0,
                top: 0,
                padding: "0.2em 0.4em"
              }}>v{version}</div>
            </main>
          </div>

            <div style={{ padding: "2em 1em 1em", textAlign: "center", margin: 0 }}>
              Copyright © 2020 Team Danger Crew.
              <div>
                {
                  this.renderTwitterLink()
                }
              </div>
            </div>



          {this.state.isLoadMenuOpen && (
            <div
              style={{
                position: "fixed",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                background: "rgba(255,255,255,0.6)"
              }}
            >
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%,-50%)"
                }}
              >
                <BoxDiv pixelSize={pixelSize}>
                  <BoxDivBody
                    pixelSize={pixelSize}
                    style={{ padding: pixelSize * 4 }}
                  >
                    <LoadGameMenu
                      handleGoBack={() => {
                        this.setState({ isLoadMenuOpen: false });
                      }}
                    />
                  </BoxDivBody>
                </BoxDiv>
              </div>
            </div>
          )}
          </BoxDivBody>
        </BoxDiv>
        </div>
      </React.Fragment>
    );
  }
}

export default connect((state, props) => {
  return {
    version: state.global.version,
    authState: state.global.authState,
    pixelSize: state.global.pixelSize,
    isMobileViewport: state.global.isMobileViewport,
    launchAuthMenuInState: state.global.launchAuthMenuInState
  };
})(TitleScreen);
