import React from 'react'

class BattleLaptopOpeningAnimation extends React.Component {

  render() {

    const {pixelSize, direction} = this.props;
    
    const styles = {
      container: {
        //background: 'rgba(255,255,0,0.4)',
        width: pixelSize * 64,
        transform: "translate(-25%, -25%)",
        overflow: 'hidden',
      },
      svg: {
        display: "block",
        width: pixelSize * 512,
        animation: `svg_npcOpenLaptop 0.7s forwards steps(7)` //1 less than length... and it stops one frame before 100% (87.5% at the moment).
      }
    };

    return (
        <div style={styles.container}>
          {laptopSprites[direction](styles.svg)}
        </div>
    );
  }
}


export const laptopSprites = {
  "DOWN": styles => {
    return (
        <svg style={styles} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 512 64" shapeRendering="crispEdges">
          <path stroke="#101016"
                d="M155 8h10M154 9h1M165 9h1M154 10h1M165 10h1M154 11h1M165 11h1M154 12h1M165 12h1M219 12h10M154 13h1M165 13h1M218 13h1M229 13h1M155 14h10M218 14h1M229 14h1M91 15h10M154 15h1M165 15h1M218 15h1M229 15h1M90 16h1M101 16h1M154 16h1M165 16h1M218 16h1M229 16h1M90 17h1M101 17h1M155 17h10M218 17h1M229 17h1M91 18h10M219 18h10M218 19h1M229 19h1M218 20h1M229 20h1M283 20h10M219 21h10M282 21h1M293 21h1M282 22h1M293 22h1M282 23h1M293 23h1M282 24h1M293 24h1M282 25h1M293 25h1M283 26h10M282 27h1M293 27h1M282 28h1M293 28h1M283 29h10M347 30h10M346 31h1M357 31h1M346 32h1M357 32h1M346 33h1M357 33h1M346 34h1M357 34h1M411 34h10M346 35h1M357 35h1M410 35h1M421 35h1M347 36h10M410 36h1M421 36h1M346 37h1M357 37h1M410 37h1M421 37h1M346 38h1M357 38h1M410 38h1M421 38h1M347 39h10M410 39h1M421 39h1M411 40h10M410 41h1M421 41h1M410 42h1M421 42h1M411 43h10"/>
          <path stroke="#586366"
                d="M155 9h10M155 10h10M155 11h4M161 11h4M155 12h10M155 13h10M219 13h10M219 14h10M155 15h3M159 15h1M161 15h4M219 15h4M225 15h4M91 16h3M95 16h1M97 16h4M155 16h10M219 16h10M91 17h10M219 17h10M219 19h3M223 19h1M225 19h4M219 20h10M283 21h10M283 22h10M283 23h4M289 23h4M283 24h10M283 25h10M283 27h3M287 27h1M289 27h4M283 28h10M347 31h10M347 32h10M347 33h4M353 33h4M347 34h10M347 35h10M411 35h10M411 36h10M347 37h3M351 37h1M353 37h4M411 37h4M417 37h4M347 38h10M411 38h10M411 39h10M411 41h3M415 41h1M417 41h4M411 42h10"/>
          <path stroke="#515e62" d="M159 11h2M223 15h2M287 23h2M351 33h2M415 37h2"/>
          <path stroke="#707b7f"
                d="M158 15h1M160 15h1M94 16h1M96 16h1M222 19h1M224 19h1M286 27h1M288 27h1M350 37h1M352 37h1M414 41h1M416 41h1"/>
          <path stroke="#fd0000"
                d="M475 34h10M474 35h1M485 35h1M474 36h1M485 36h1M474 37h1M485 37h1M474 38h1M485 38h1M474 39h1M485 39h1M475 40h10M474 41h1M485 41h1M474 42h1M485 42h1M475 43h10"/>
          <path stroke="#ffffff" d="M475 35h2M475 36h1M475 41h1"/>
          <path stroke="#f1dddd" d="M477 35h8M476 36h9M475 37h10M475 38h10M475 39h10M476 41h9M475 42h10"/>

          <style>
            {`

            @keyframes svg_npcOpenLaptop {
                  0% { transform: translate3d(0,0,0) }
                  100% { transform: translate3d(-87.5%,0,0) }
            }
        `}
          </style>
        </svg>
    )
  },
  "LEFT": styles => {
    return (
        <svg style={styles} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 512 64" shapeRendering="crispEdges">
          <path stroke="#101016"
                d="M209 20h2M208 21h1M210 21h1M154 22h3M208 22h1M210 22h1M152 23h2M156 23h1M208 23h1M210 23h1M149 24h3M154 24h2M208 24h1M210 24h1M147 25h3M152 25h2M208 25h1M210 25h1M147 26h1M150 26h2M208 26h1M210 26h1M270 26h2M147 27h1M149 27h7M208 27h1M210 27h1M269 27h1M271 27h1M147 28h2M156 28h1M208 28h1M210 28h7M269 28h1M271 28h1M148 29h9M208 29h2M217 29h1M270 29h1M272 29h1M338 29h2M83 30h10M209 30h9M270 30h1M272 30h1M337 30h1M339 30h1M82 31h1M93 31h1M271 31h1M273 31h1M337 31h1M339 31h1M401 31h2M83 32h10M271 32h1M273 32h1M337 32h1M339 32h1M400 32h1M402 32h1M271 33h1M273 33h7M337 33h1M339 33h1M400 33h1M402 33h1M272 34h1M280 34h1M336 34h1M338 34h1M400 34h1M402 34h1M272 35h9M336 35h1M338 35h1M400 35h1M402 35h1M336 36h1M338 36h1M400 36h1M402 36h1M336 37h1M338 37h7M400 37h1M402 37h1M336 38h2M345 38h1M400 38h1M402 38h1M337 39h9M400 39h1M402 39h7M400 40h2M409 40h1M401 41h9"/>
          <path stroke="#707b7f"
                d="M209 21h1M209 22h1M154 23h2M209 23h1M152 24h2M209 24h1M150 25h2M209 25h1M148 26h2M209 26h1M209 27h1M270 27h1M270 28h1M271 29h1M271 30h1M338 30h1M86 31h1M88 31h1M272 31h1M338 31h1M272 32h1M338 32h1M401 32h1M272 33h1M338 33h1M401 33h1M337 34h1M401 34h1M337 35h1M401 35h1M337 36h1M401 36h1M401 37h1M401 38h1"/>
          <path stroke="#586366"
                d="M148 27h1M149 28h7M209 28h1M210 29h7M83 31h3M87 31h1M89 31h4M273 34h7M337 37h1M338 38h7M401 39h1M402 40h7"/>
          <path stroke="#fd0000"
                d="M465 31h2M464 32h1M466 32h1M464 33h1M466 33h1M464 34h1M466 34h1M464 35h1M466 35h1M464 36h1M466 36h1M464 37h1M466 37h1M464 38h1M466 38h1M464 39h1M466 39h7M464 40h2M473 40h1M465 41h9"/>
          <path stroke="#ffffff" d="M465 32h1M465 33h1M465 34h1M465 35h1M465 36h1M465 37h1M465 38h1M465 39h1"/>
          <path stroke="#f1dddd" d="M466 40h7"/>
          <style>
            {`

            @keyframes svg_npcOpenLaptop {
                  0% { transform: translate3d(0,0,0) }
                  100% { transform: translate3d(-87.5%,0,0) }
            }
        `}
          </style>
        </svg>
    )
  },

  "RIGHT": styles => {
    return (
        <svg style={styles} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 512 64" shapeRendering="crispEdges">
          <path stroke="#101016"
                d="M237 20h2M237 21h1M239 21h1M163 22h3M237 22h1M239 22h1M163 23h1M166 23h2M237 23h1M239 23h1M164 24h2M168 24h3M237 24h1M239 24h1M166 25h2M170 25h3M237 25h1M239 25h1M168 26h2M172 26h1M237 26h1M239 26h1M304 26h2M164 27h7M172 27h1M237 27h1M239 27h1M304 27h1M306 27h1M163 28h1M171 28h2M231 28h7M239 28h1M304 28h1M306 28h1M163 29h9M230 29h1M238 29h2M303 29h1M305 29h1M364 29h2M99 30h10M230 30h9M303 30h1M305 30h1M364 30h1M366 30h1M98 31h1M109 31h1M302 31h1M304 31h1M364 31h1M366 31h1M429 31h2M99 32h10M302 32h1M304 32h1M364 32h1M366 32h1M429 32h1M431 32h1M296 33h7M304 33h1M364 33h1M366 33h1M429 33h1M431 33h1M295 34h1M303 34h1M365 34h1M367 34h1M429 34h1M431 34h1M295 35h9M365 35h1M367 35h1M429 35h1M431 35h1M365 36h1M367 36h1M429 36h1M431 36h1M359 37h7M367 37h1M429 37h1M431 37h1M358 38h1M366 38h2M429 38h1M431 38h1M358 39h9M423 39h7M431 39h1M422 40h1M430 40h2M422 41h9"/>
          <path stroke="#707b7f"
                d="M238 21h1M238 22h1M164 23h2M238 23h1M166 24h2M238 24h1M168 25h2M238 25h1M170 26h2M238 26h1M238 27h1M305 27h1M305 28h1M304 29h1M304 30h1M365 30h1M103 31h1M105 31h1M303 31h1M365 31h1M303 32h1M365 32h1M430 32h1M303 33h1M365 33h1M430 33h1M366 34h1M430 34h1M366 35h1M430 35h1M366 36h1M430 36h1M430 37h1M430 38h1"/>
          <path stroke="#586366"
                d="M171 27h1M164 28h7M238 28h1M231 29h7M99 31h4M104 31h1M106 31h3M296 34h7M366 37h1M359 38h7M430 39h1M423 40h7"/>
          <path stroke="#fd0000"
                d="M493 31h2M493 32h1M495 32h1M493 33h1M495 33h1M493 34h1M495 34h1M493 35h1M495 35h1M493 36h1M495 36h1M493 37h1M495 37h1M493 38h1M495 38h1M487 39h7M495 39h1M486 40h1M494 40h2M486 41h9"/>
          <path stroke="#ffffff" d="M494 32h1M494 33h1M494 34h1M494 35h1M494 36h1M494 37h1M494 38h1M494 39h1"/>
          <path stroke="#f1dddd" d="M487 40h7"/>
          <style>
            {`

            @keyframes svg_npcOpenLaptop {
                  0% { transform: translate3d(0,0,0) }
                  100% { transform: translate3d(-87.5%,0,0) }
            }
        `}
          </style>
        </svg>
    )
  },

  "UP": styles => {
    return (
        <svg style={styles} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 512 64" shapeRendering="crispEdges">
          <path stroke="#101016"
                d="M155 8h10M154 9h1M165 9h1M154 10h1M165 10h1M154 11h1M165 11h1M154 12h1M165 12h1M154 13h1M165 13h1M155 14h10M225 14h10M91 15h10M154 15h1M165 15h1M224 15h1M235 15h1M90 16h1M101 16h1M154 16h1M165 16h1M224 16h1M235 16h1M90 17h1M101 17h1M155 17h10M224 17h1M235 17h1M91 18h10M224 18h1M235 18h1M224 19h1M235 19h1M225 20h10M224 21h1M235 21h1M224 22h1M235 22h1M225 23h10M292 23h10M291 24h1M302 24h1M291 25h1M302 25h1M291 26h1M302 26h1M291 27h1M302 27h1M356 27h10M291 28h1M302 28h1M355 28h1M366 28h1M292 29h10M355 29h1M366 29h1M291 30h1M302 30h1M355 30h1M366 30h1M291 31h1M302 31h1M355 31h1M366 31h1M292 32h10M355 32h1M366 32h1M420 32h10M356 33h10M419 33h1M430 33h1M355 34h1M366 34h1M419 34h1M430 34h1M355 35h1M366 35h1M419 35h1M430 35h1M356 36h10M419 36h1M430 36h1M419 37h1M430 37h1M420 38h10M419 39h1M430 39h1M419 40h1M430 40h1M420 41h10"/>
          <path stroke="#0f414c"
                d="M155 9h2M155 10h1M225 15h2M225 16h1M292 24h2M292 25h1M356 28h2M356 29h1M420 33h2M420 34h1"/>
          <path stroke="#052128"
                d="M157 9h8M156 10h9M155 11h10M155 12h10M155 13h10M227 15h8M226 16h9M225 17h10M225 18h10M225 19h10M294 24h8M293 25h9M292 26h10M292 27h10M292 28h10M358 28h8M357 29h9M356 30h10M356 31h10M356 32h10M422 33h8M421 34h9M420 35h10M420 36h10M420 37h10"/>
          <path stroke="#707b7f"
                d="M155 15h1M157 15h1M159 15h1M161 15h1M163 15h1M94 16h1M96 16h1M156 16h1M162 16h1M164 16h1M225 21h1M227 21h1M229 21h1M231 21h1M233 21h1M226 22h1M232 22h1M234 22h1M292 30h1M294 30h1M296 30h1M298 30h1M300 30h1M293 31h1M299 31h1M301 31h1M356 34h1M358 34h1M360 34h1M362 34h1M364 34h1M357 35h1M363 35h1M365 35h1M420 39h1M422 39h1M424 39h1M426 39h1M428 39h1M421 40h1M427 40h1M429 40h1"/>
          <path stroke="#586366"
                d="M156 15h1M158 15h1M160 15h1M162 15h1M164 15h1M91 16h3M95 16h1M97 16h4M155 16h1M157 16h1M163 16h1M91 17h10M226 21h1M228 21h1M230 21h1M232 21h1M234 21h1M225 22h1M227 22h1M233 22h1M293 30h1M295 30h1M297 30h1M299 30h1M301 30h1M292 31h1M294 31h1M300 31h1M357 34h1M359 34h1M361 34h1M363 34h1M365 34h1M356 35h1M358 35h1M364 35h1M421 39h1M423 39h1M425 39h1M427 39h1M429 39h1M420 40h1M422 40h1M428 40h1"/>
          <path stroke="#839093" d="M158 16h4M228 22h4M295 31h4M359 35h4M423 40h4"/>
          <path stroke="#fd0000"
                d="M484 32h10M483 33h1M494 33h1M483 34h1M494 34h1M483 35h1M494 35h1M483 36h1M494 36h1M483 37h1M494 37h1M484 38h10M483 39h1M494 39h1M483 40h1M494 40h1M484 41h10"/>
          <path stroke="#ffffff" d="M484 33h2M484 34h1M484 39h1"/>
          <path stroke="#f1dddd"
                d="M486 33h8M485 34h9M484 35h10M484 36h10M484 37h10M486 39h1M488 39h1M490 39h1M492 39h1M485 40h7M493 40h1"/>
          <path stroke="#c0aeae" d="M485 39h1M487 39h1M489 39h1M491 39h1M493 39h1M484 40h1M492 40h1"/>
          <style>
            {`

            @keyframes svg_npcOpenLaptop {
                  0% { transform: translate3d(0,0,0) }
                  100% { transform: translate3d(-87.5%,0,0) }
            }
        `}
          </style>
        </svg>
    )
  }


};


export default BattleLaptopOpeningAnimation;