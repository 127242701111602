import React from 'react';
import {waitFrames} from "../helpers/waitFrames";
import {audioManager} from "../audio/audioManager";

export default class Lifebar extends React.PureComponent {

  state = {
    diff: 0,
  };

  componentDidUpdate(prevProps) {
    const oldFillValue = this.getFillValue(prevProps);
    const newFillValue = this.getFillValue(this.props);

    //Animate value DECREASING
    if (this.props.value < prevProps.value) {
      this.setState({
        fillDirection: "DOWN",
        diff: oldFillValue - newFillValue
      }, () => {
        this.animateDiffOut();
      })
    }

    //Animate value INCREASING
    if (this.props.value > prevProps.value) {

      audioManager.playSfx("sfx_fillHp");

      this.setState({
        fillDirection: "UP",
        diff: Math.abs(oldFillValue - newFillValue)
      }, () => {
        this.animateDiffOut();
      })
    }
  }

  animateDiffOut() {
    let step = () => {
      this.setState(state => {
        return {
          diff: state.diff - 1
        }
      }, () => {
        if (this.state.diff > 0) {
          requestAnimationFrame(step)
        }
      });
    };
    waitFrames(10, () => {
      requestAnimationFrame(step);
    });
  }

  getFillValue(props) {
    const fillRatio = props.value / props.maxValue;

    //26 is the pixel width of the max fullness
    let fillValue = ( 26 * fillRatio );

    //Floor to 0 or max
    if (fillValue < 0) { fillValue = 0}
    if (fillValue > 26) { fillValue = 26}

    //If fill Value is between 25 and 26 (not quite full), force it to 25.
    if (fillValue > 25 && fillValue < 26) {
      fillValue = 25;
    }
    //If ALMOST EMPTY, don't let it round to totally empty.
    if (fillValue > 0 && fillValue < 1) {
      fillValue = 1;
    }

    //Finally, round it to a whole number for no sub pixels
    //fillValue = Math.round(fillValue);
    return Math.round(fillValue);
  }

  render() {
    let {pixelSize, theme} = this.props;
    const {diff, fillDirection} = this.state;
    const style = {
      width: pixelSize * 28,
      height: pixelSize * 5,
      display: "block"
    };

    theme = theme || "player";
    const {barTop, barInset, barColor} = themes[theme];


    const fillValue = this.getFillValue(this.props);

    return (
      <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 28 5" shapeRendering="crispEdges">
        <path stroke={barTop} d="M0 0h28" />
        <path stroke="#08152e" d="M0 1h28M0 2h28M0 3h28M0 4h28" />

        <rect fill={barColor} x="1" y="0.5" height="3" width={fillValue} />
        <rect fill={barInset} x="1" y="0.5" height="1" width={fillValue} />
        {
          diff > 0 && fillDirection === "DOWN" && (
            <rect fill="#fff" x={fillValue+1} y="0.5" height="3" width={diff}/>
          )
        }
        {
          diff > 0 && fillDirection === "UP" && (
            <rect fill="#fff" x={fillValue+1-(diff)} y="0.5" height="3" width={diff}/>
          )
        }
      </svg>
    )
  }
}

const themes = {
  "player": {
    barTop: "#1f2b13",
    barInset: "#84cf3d",
    barColor: "#99e550",
  },
  "enemy": {
    barTop: "#3a0e11",
    barInset: "#c32c36",
    barColor: "#ec5555",
  }
};