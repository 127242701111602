import store from '../init/store'

//Get a new value that will be used to break the trigger cache
export function getNextExternalEventTrigger(caller) {


  //console.log(caller, store.getState().overworld.externalEventTrigger + 1)

  //caller is only for debug purposes. You can log call to see where it came from.
  return store.getState().overworld.externalEventTrigger + 1
}