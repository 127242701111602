import {DefaultAction} from "../Action";
import {getAttackDamage} from "../../formulas/getAttackDamage";
import {getDangerMeterReset} from "../../formulas/getDangerMeterReset";

export default {
  ...DefaultAction,
  name: "Team Slice",
  description: "Attack the entire enemy team while also reducing their defense",
  icon: "super-dps",
  targetingType: "all-enemies",

  shouldFailOnSingleTarget(casterModel, targetModel) {
    //TeamSlice does not fail, but something like Wildfire might.
    return null;
  },

  successEventsPerAllTargets({casterModel, targetModels, allUpgrades}) {

    let removeBlockEvent = null;
    const blockedTargets = targetModels.filter(t => t.status === "block");
    if (blockedTargets.length > 0) {
      removeBlockEvent = {
        type: "eventBlippy",
        stateChanges: blockedTargets.map(t => {
          return {
            combatantId: t.id,
            absoluteChanges: {
              status: "normal"
            },
            message: {
              theme: "battleDamageBlock",
              text: `Damage Absorbed!`
            }
          }
        }),
        delayFramesAfter: 60
      }
    }


    return [
      {
        type: "eventAnimation",
        animationId: "animationSlice",
        subjects: targetModels.map(t => t.id),
        delayFramesAfter: 30,
        isTeamSlice: true,
      },
      removeBlockEvent,
      {
        type: "eventBlippy",
        stateChanges: targetModels.map(t => {
          const damage = getAttackDamage(13, casterModel, t, allUpgrades);
          return {
            combatantId: t.id,
            relativeChanges: {
              hp: damage
            },
            message: {
              theme: "battleNegativeHp",
              text: `${damage} HP`
            }
          }
        }),
        delayFramesAfter: 45
      },
      {
        type: "eventBlippy",
        stateChanges: targetModels.map(t => {
          return {
            combatantId: t.id,
            relativeChanges: {
              defenseModifier: -3
            },
            message: {
              theme: "battleNegativeHp",
              text: "-Defense"
            }
          }
        }),
        delayFramesAfter: 30
      },
      getDangerMeterReset({casterModel})
    ].filter(e => e)
  }
}