export default {
  "upgrade_item-turn": {
    name: "Item Ninja",
    description: "Bonus turn after using an item",
    provideBonusTurnAfterUsingAnyItem: true
  },
  "upgrade_stealing-turn": {
    name: "Item Mischief",
    description: "Bonus turn after stealing or bugging an item",
    provideBonusTurnAfterUsingActionWithCategory: ["itemStealingTurn"]
  },

  "upgrade_maximize-loops": {
    name: "Healthy Iteration",
    description: "Boosts the odds of dealing maximum iterations",
    maximizeIterationCount: true
  },

  "upgrade_minimize-loops": {
    name: "Loop Breaker",
    description: "Limits oncoming loops to their minimum count",
    minimizeIterationCount: true
  },

  "upgrade_defense": {
    name: "Secure",
    description: "Increases defense while equipped",
    upgradeDefenseModifier: 3,
  },

  "upgrade_defense-lagging": {
    name: "Rock Solid",
    description: "Reduces oncoming damage while lagging",
    upgradeDefenseWithStatus: ["lag", 6],
  },

  "upgrade_attack-lowhp": {
    name: "Pressure Cooker",
    description: "Increases attack while under 50% HP",
    upgradeAttackWhenHpBelowPercent: [50, 4], //increase +4 when hp is below 50%
  },


  "upgrade_attack-deadline": {
    name: "Dead Serious",
    description: "Increases attack while having a Deadline",
    upgradeAttackWithStatus: ["deadline", 4]
  },

  "upgrade_attack-focus": {
    name: "Zenmaster",
    description: "Increases outgoing damage while Focused",
    upgradeAttackWithStatus: ["focus", 4]
  },

  "upgrade_perfect-accuracy": {
    name: "Eagle Glass",
    description: "Perfect accuracy while equipped",
    upgradeAccuracy: true
  },


  "upgrade_recover-focus": {
    name: "Tomato Timer",
    description: "Increases HP of Focus recoveries",
    increaseFocusRecoveryPercent: 15 // adds another 15% percent to Focus recoveries
  },

  "upgrade_no-lag": {
    name: "Hard Wired",
    description: "Prevents lag status",
    preventsStatus: "lag"
  },

  "upgrade_no-promises": {
    name: "Oathbreaker",
    description: "Prevents Promises from resolving on you",
    preventsPromises: true
  },

  "upgrade_attack-reduce-defense": {
    name: "Blue Keys",
    description: "Increases attack but decreases defense",
    upgradeAttackModifier: 3,
    upgradeDefenseModifier: -3,
  }
}