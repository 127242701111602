import {DefaultAction, DefaultScript} from "../Action";
import {getRandomInRange} from "../../../../helpers/testablePercentChance";
import {getLeadEnemy} from "../../../getLeadEnemy";

export default {
  ...DefaultAction,
  ...DefaultScript,
  name: "Bug",
  description: "Secretly sabotage 1 enemy item to explode on usage",
  targetingType: "all-enemies",
  icon: "disrupt",
  accuracy: 96,
  categories: ["itemStealingTurn"], //for upgrade

  teamItemMoveMethods: true,

  shouldFailOnTargetTeam({casterModel, friendlyItems, enemyItems}) {

    const allItemsBugged = enemyItems.length && enemyItems.every(i => i.isBugged);

    if (allItemsBugged) {
      return {
        failCode: "all-bugged"
      }
    }
    if (enemyItems.length === 0) {
      return {
        failCode: "no-items"
      }
    }
    return false;
  },

  wholeTeamFailEvent({casterModel, targetModels, failInfo}) {

    if (failInfo.failCode && failInfo.failCode === "all-bugged") {
      return [
        {
          type: "eventBlippy",
          stateChanges: targetModels.map(targetModel => {
            return {
              combatantId: targetModel.id,
              message: {
                text: `ALL ITEMS BUGGED!`
              },
            }
          }),
        },
      ]
    }

    return [
      {
        type: "eventBlippy",
        stateChanges: targetModels.map(targetModel => {
          return {
            combatantId: targetModel.id,
            message: {
              text: `NO ITEMS!`
            },
          }
        }),
      },
    ]
  },

  successEventOnTeam({casterModel, targetModels, friendlyItems, enemyItems}) {

    const instanceIds = enemyItems.filter(i => !i.isBugged ).map(i => i.instanceId); //Get instance Ids that are not bugged
    const chosenInstanceId = instanceIds[ getRandomInRange(0, instanceIds.length-1) ];
    const enemyTeamName = getLeadEnemy(targetModels).name;

    return [
      {
        type: "eventBlippy",
        subjects: [casterModel.id],
        teamItemChangeInstructions: {
          type: "bug",
          instanceIds: [chosenInstanceId],
        },
        delayFramesAfter: 10
      },
      {
        type: "eventDialogBox",
        textArray: [`An item on ${enemyTeamName}'s team was bugged!`],
        haltQueueUntilDone: true
      },
    ]
  }
}