import React from 'react';

export default function (props, state) {

  const pixelSize = 3;

  return (
    <div
      style={{
        width: 32 * pixelSize,
        height: 32 * pixelSize,
        position:"relative",
        overflow: "hidden"
      }}
    >
      {/* Shadow */}
      <svg style={{
        position: "absolute",
        bottom:0,
        left:0,
        right:0,
        width: 32 * pixelSize
      }} viewBox="0 0 160 160" xmlns="http://www.w3.org/2000/svg">
        <title>Group</title>
        <path fill="#200535" opacity=".2"
              d="M125 130v-5h-5v-5h-5v-5H45v5h-5v5h-5v5h-5v15h5v5h5v5h5v5h70v-5h5v-5h5v-5h5v-15z" fillRule="evenodd"/>
      </svg>
      <svg
        style={{
          display: "block",
          width: 128 * pixelSize,
          animation: "authRunningCharacter 0.5s steps(4) infinite"
        }}
        xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 128 32" shapeRendering="crispEdges">
        <path stroke="#222034" d="M11 3h9m55 0h9M10 4h1m9 0h2m21 0h9m22 0h1m9 0h2m21 0h9M9 5h1m12 0h1m19 0h1m9 0h2m19 0h1m12 0h1m19 0h1m9 0h2M8 6h1m14 0h1m17 0h1m12 0h1m17 0h1m14 0h1m17 0h1m12 0h1M7 7h1m16 0h1m15 0h1m14 0h1m15 0h1m16 0h1m15 0h1m14 0h1M7 8h1m16 0h1m14 0h1m16 0h1m14 0h1m16 0h1m14 0h1m16 0h1M7 9h1m16 0h1m14 0h1m16 0h1m14 0h1m16 0h1m14 0h1m16 0h1M6 10h2m16 0h2m13 0h1m16 0h1m13 0h2m16 0h2m13 0h1m16 0h1M6 11h1m18 0h1m12 0h2m16 0h2m12 0h1m18 0h1m12 0h2m16 0h2M5 12h2m18 0h2m11 0h1m18 0h1m11 0h2m18 0h2m11 0h1m18 0h1M5 13h2m18 0h2m10 0h2m18 0h2m10 0h2m18 0h2m10 0h2m18 0h2M5 14h2m18 0h2m10 0h2m18 0h2m10 0h2m18 0h2m10 0h2m18 0h2M5 15h3m3 0h3m4 0h3m3 0h3m10 0h2m18 0h2m10 0h3m3 0h3m4 0h3m3 0h3m10 0h2m18 0h2M6 16h3m1 0h1m3 0h1m2 0h1m3 0h1m1 0h1m1 0h1m11 0h3m3 0h3m4 0h3m3 0h3m11 0h3m1 0h1m3 0h1m2 0h1m3 0h1m1 0h1m1 0h1m11 0h3m3 0h3m4 0h3m3 0h3M6 17h2m1 0h2m3 0h4m3 0h2m1 0h2m12 0h3m1 0h1m3 0h1m2 0h1m3 0h1m1 0h1m1 0h1m12 0h2m1 0h2m3 0h4m3 0h2m1 0h2m12 0h3m1 0h1m3 0h1m2 0h1m3 0h1m1 0h1m1 0h1M7 18h2m1 0h1m3 0h1m2 0h1m3 0h1m1 0h2m13 0h2m1 0h2m3 0h4m3 0h2m1 0h2m13 0h2m1 0h1m3 0h1m2 0h1m3 0h1m1 0h2m13 0h2m1 0h2m3 0h4m3 0h2m1 0h2M7 19h2m2 0h3m5 0h1m3 0h2m14 0h2m1 0h1m3 0h1m2 0h1m3 0h1m1 0h2m14 0h2m2 0h3m5 0h1m3 0h2m14 0h2m1 0h1m3 0h1m2 0h1m3 0h1m1 0h2M8 20h2m12 0h2m15 0h2m2 0h3m5 0h1m3 0h2m15 0h2m12 0h2m15 0h2m2 0h3m5 0h1m3 0h2M8 21h4m8 0h4m16 0h2m9 0h5m16 0h4m8 0h4m16 0h5m9 0h2M8 22h1m1 0h1m1 0h8m1 0h1m1 0h1m16 0h4m7 0h1m3 0h1m16 0h1m1 0h1m1 0h8m1 0h1m1 0h1m16 0h1m3 0h1m7 0h4M8 23h1m1 0h1m10 0h1m1 0h1m16 0h1m1 0h1m1 0h8m3 0h1m16 0h1m1 0h1m10 0h1m1 0h1m16 0h1m3 0h8m1 0h1m1 0h1M8 24h1m1 0h12m1 0h1m17 0h2m8 0h1m3 0h1m16 0h1m1 0h12m1 0h1m16 0h1m3 0h1m8 0h2M9 25h2m10 0h2m19 0h1m8 0h5m17 0h2m10 0h2m17 0h5m8 0h1M10 26h1m10 0h1m20 0h1m1 0h2m7 0h1m20 0h1m10 0h1m20 0h1m7 0h2m1 0h1M10 27h1m2 0h6m2 0h1m20 0h3m1 0h5m2 0h1m20 0h1m2 0h6m2 0h1m20 0h1m2 0h5m1 0h3M10 28h1m1 0h1m6 0h1m1 0h1m29 0h1m1 0h1m20 0h1m1 0h1m6 0h1m1 0h1m20 0h1m1 0h1m-99 1h3m6 0h3m29 0h3m20 0h3m6 0h3m20 0h3"/>
        <path stroke="#6c562e" d="M11 4h9m55 0h9M10 5h1m10 0h1m21 0h9m22 0h1m10 0h1m21 0h9M9 6h1m12 0h1m19 0h1m10 0h1m19 0h1m12 0h1m19 0h1m10 0h1M8 7h1m14 0h1m17 0h1m12 0h1m17 0h1m14 0h1m17 0h1m12 0h1M40 8h1m14 0h1m48 0h1m14 0h1M8 9h1m14 0h1m48 0h1m14 0h1M8 10h1m14 0h1m16 0h1m14 0h1m16 0h1m14 0h1m16 0h1m14 0h1M7 11h1m15 0h2m15 0h1m14 0h1m15 0h1m15 0h2m15 0h1m14 0h1m-96 1h1m14 0h1m15 0h2m31 0h1m14 0h1m15 0h2m-65 1h1m63 0h1M10 20h1m10 0h1m52 0h1m10 0h1m-74 1h1m2 0h2m2 0h1m22 0h1m33 0h1m2 0h2m2 0h1m33 0h1m-74 1h1m2 0h2m62 0h2m2 0h1"/>
        <path stroke="#b89657" d="M11 5h3m61 0h3M10 6h3m30 0h3m28 0h3m30 0h3M10 7h2m3 0h1m3 0h1m22 0h3m29 0h2m3 0h1m3 0h1m22 0h3M16 8h1m3 0h1m21 0h2m3 0h1m3 0h1m28 0h1m3 0h1m21 0h2m3 0h1m3 0h1M12 9h2m3 0h1m3 0h1m26 0h1m3 0h1m23 0h2m3 0h1m3 0h1m26 0h1m3 0h1M13 10h1m3 0h1m3 0h1m22 0h2m3 0h1m3 0h1m23 0h1m3 0h1m3 0h1m22 0h2m3 0h1m3 0h1M14 11h1m3 0h1m26 0h1m3 0h1m3 0h1m24 0h1m3 0h1m26 0h1m3 0h1m3 0h1m-72 1h1m3 0h1m59 0h1m3 0h1"/>
        <path stroke="#a47f3c" d="M14 5h7m57 0h7M13 6h9m24 0h7m24 0h9m24 0h7M9 7h1m2 0h3m1 0h3m1 0h3m22 0h9m19 0h1m2 0h3m1 0h3m1 0h3m22 0h9M8 8h8m1 0h3m1 0h3m17 0h1m2 0h3m1 0h3m1 0h3m17 0h8m1 0h3m1 0h3m17 0h1m2 0h3m1 0h3m1 0h3M9 9h3m2 0h3m1 0h3m1 0h1m17 0h8m1 0h3m1 0h3m17 0h3m2 0h3m1 0h3m1 0h1m17 0h8m1 0h3m1 0h3M9 10h4m1 0h3m1 0h3m1 0h1m18 0h3m2 0h3m1 0h3m1 0h1m18 0h4m1 0h3m1 0h3m1 0h1m18 0h3m2 0h3m1 0h3m1 0h1M8 11h1m3 0h2m1 0h3m1 0h4m18 0h4m1 0h3m1 0h3m1 0h1m17 0h1m3 0h2m1 0h3m1 0h4m18 0h4m1 0h3m1 0h3m1 0h1M14 12h9m17 0h1m3 0h2m1 0h3m1 0h4m23 0h9m17 0h1m3 0h2m1 0h3m1 0h4M7 13h1m16 0h1m21 0h9m16 0h1m16 0h1m21 0h9M7 14h1m16 0h1m14 0h1m16 0h1m14 0h1m16 0h1m14 0h1m16 0h1m-82 1h1m16 0h1m46 0h1m16 0h1M15 19h2m62 0h2m-70 1h4m2 0h4m26 0h2m26 0h4m2 0h4m26 0h2M13 21h2m2 0h2m24 0h4m2 0h2m26 0h2m2 0h2m26 0h2m2 0h4m-72 1h2m2 0h2m58 0h2m2 0h2"/>
        <path stroke="#d8aaaa" d="M9 11h3m61 0h3m-35 1h3m61 0h3M8 13h1m63 0h1M8 14h1m31 0h1m31 0h1m31 0h1M8 15h1m31 0h1m31 0h1m31 0h1M9 16h1m12 0h1m1 0h1m15 0h1m32 0h1m12 0h1m1 0h1m15 0h1M8 17h1m14 0h1m17 0h1m12 0h1m1 0h1m15 0h1m14 0h1m17 0h1m12 0h1m1 0h1M9 18h1m30 0h1m14 0h1m17 0h1m30 0h1m14 0h1M9 19h1m12 0h1m18 0h1m31 0h1m12 0h1m18 0h1m-65 1h1m12 0h1m50 0h1m12 0h1"/>
        <path stroke="#3a2b10" d="M7 12h1m63 0h1m-33 1h1m63 0h1"/>
        <path stroke="#edb7b6" d="M8 12h1m4 0h1m9 0h1m48 0h1m4 0h1m9 0h1m-74 1h10m16 0h1m4 0h1m9 0h1m22 0h10m16 0h1m4 0h1m9 0h1m-97 1h1m22 0h10m31 0h1m22 0h10m-97 1h1m31 0h1m31 0h1m31 0h1M15 16h2m38 0h1m23 0h2m38 0h1m-73 1h2m62 0h2m-98 1h2m62 0h2m-34 1h2m62 0h2M9 22h1m12 0h1m50 0h1m12 0h1M9 23h1m12 0h1m18 0h1m31 0h1m12 0h1m-33 1h1m52 0h1"/>
        <path stroke="#f5c4c3" d="M9 12h4m60 0h4M9 13h5m27 0h4m28 0h5m27 0h4M9 14h14m18 0h5m27 0h14m18 0h5M9 15h1m5 0h2m5 0h1m18 0h14m18 0h1m5 0h2m5 0h1m18 0h14m-78 1h1m5 0h2m5 0h1m50 0h1m5 0h2m5 0h1m-97 2h1m63 0h1m-33 1h1m63 0h1m-67 3h3m50 0h3m-56 1h3m50 0h3m10 0h1M9 24h1m12 0h1m29 0h2m19 0h1m12 0h1m18 0h2"/>
        <path stroke="#28263a" d="M10 15h1m3 0h1m2 0h1m3 0h1m52 0h1m3 0h1m2 0h1m3 0h1m-44 1h1m3 0h1m2 0h1m3 0h1m52 0h1m3 0h1m2 0h1m3 0h1M10 19h1m3 0h1m2 0h2m1 0h2m52 0h1m3 0h1m2 0h2m1 0h2m-71 1h2m25 0h1m3 0h1m2 0h2m1 0h2m25 0h2m25 0h1m3 0h1m2 0h2m1 0h2m-71 1h2m62 0h2"/>
        <path stroke="#fdfeff" d="M11 16h2m5 0h2m55 0h2m5 0h2m-73 1h1m6 0h1m24 0h2m5 0h2m23 0h1m6 0h1m24 0h2m5 0h2m-73 1h1m6 0h1m56 0h1m6 0h1"/>
        <path stroke="#d5e3f7" d="M13 16h1m6 0h1m56 0h1m6 0h1m-73 1h2m5 0h2m24 0h1m6 0h1m23 0h2m5 0h2m24 0h1m6 0h1M11 18h3m4 0h3m23 0h2m5 0h2m22 0h3m4 0h3m23 0h2m5 0h2m-74 1h3m4 0h3m54 0h3m4 0h3"/>
        <path stroke="#e349a1" d="M11 22h1m63 0h1m-64 1h1m1 0h1m1 0h1m1 0h1m1 0h1m22 0h1m32 0h1m1 0h1m1 0h1m1 0h1m1 0h1m-41 1h1m1 0h1m1 0h1m1 0h1m59 0h1m1 0h1m1 0h1m1 0h1"/>
        <path stroke="#5ecebc" d="M20 22h1m63 0h1m-74 1h1m1 0h1m1 0h1m1 0h1m1 0h1m55 0h1m1 0h1m1 0h1m1 0h1m1 0h1m32 0h1m-74 1h1m1 0h1m1 0h1m1 0h1m59 0h1m1 0h1m1 0h1m1 0h1"/>
        <path stroke="#868752" d="M11 25h1m8 0h1m54 0h1m8 0h1m31 0h1M12 26h8m26 0h6m24 0h8m24 0h6M12 27h1m6 0h1m31 0h1m24 0h1m6 0h1m24 0h1"/>
        <path stroke="#9fa05f" d="M12 25h4m1 0h3m23 0h5m1 0h2m25 0h4m1 0h3m25 0h3m1 0h3M11 26h1m8 0h1m31 0h1m22 0h1m8 0h1m22 0h1m-97 1h1m8 0h1m31 0h1m22 0h1m8 0h1m22 0h1"/>
        <path stroke="#666731" d="M16 25h1m31 0h1m31 0h1m31 0h1m-70 1h1m72 0h1M11 28h1m8 0h1m31 0h1m22 0h1m8 0h1m22 0h1"/>
      </svg>
    </div>
  )
}