import React from 'react'
import {setGlobalValue} from "../redux-actions/global-actions";
import { connect } from 'react-redux'
import {handleSaveGameEvent} from "../loading/handleSaveGame";

//This component simply utilizes lifecycle hooks to control flow of Auth within pause menu (because it kinda does its own global thing)
class PauseLoginStub extends React.Component {

  componentDidMount() {
    setGlobalValue({
      launchAuthMenuInState: "createAccount" //not login here because most new players will want to create an account, right?
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.launchAuthMenuInState && !this.props.launchAuthMenuInState) {

      handleSaveGameEvent(); //attempt to autosave

      this.props.handleGoBack(); //go back to home screen
    }
  }

  render() {
    return (
      null
    );
  }
}

export default connect((state, props) => {
    return {
      launchAuthMenuInState: state.global.launchAuthMenuInState
    }
})(PauseLoginStub)