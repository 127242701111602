import {DefaultAction} from "../Action";

export default {

  ...DefaultAction,

  getUse() {
    return []
  },



  successEventsPerTarget(casterModel, targetModel, allUpgrades) {

    const previousTargetsWithFury = this.previousTargetsWithFury || [];

    //Add a blippy for every recoil we have (number of targets that had FURY)
    let payload = [
      ...previousTargetsWithFury.map(targetId => {
        return {
          type: "eventAnimation",
          animationId: "characterShake",
          useSfx: "sfx_bearGrowl", //kinda quick and configy way to change the sfx
          subjects: [targetId],
          delayFramesAfter: 20,
        }
      }),
    ];

    let targetHp = targetModel.hp; //Keep track of HP so multiple recoils (like Curl against multiple Furies) don't continue to attack a dead body
      //Starts at current hp value. We will subtract from this during the loop iterations

    //One for each previously hit target
    previousTargetsWithFury.forEach((t) => {

      //For fury, the Recoil is a percentage of the furious person's max HP
      let hpChange = Math.round(casterModel.maxHp * -0.2);
      if (targetModel.status === "block") {
        hpChange = -1;
      }

      if (targetHp > 0) { //don't do this if the target is dead now
        payload.push({
          type: "eventAnimation",
          animationId: "animationGenericPain",
          subjects: [targetModel.id],
          delayFramesAfter: 12,
        },);

        payload.push({
          type: "eventBlippy",
          stateChanges: [
            {
              combatantId: targetModel.id,
              relativeChanges: {
                hp: hpChange
              },
              message: {
                theme: "battleNegativeHp",
                text: `Recoil ${hpChange} HP`
              },
            }
          ]
        });

        if (targetModel.status === "block") {
          payload.push({
            type: "eventBlippy",
            stateChanges: [
              {
                combatantId: targetModel.id,
                absoluteChanges: {
                  status: "normal"
                },
                message: {
                  theme: "battleDamageBlock",
                  text: `Damage Absorbed!`
                },
              }
            ],
          })
        }
      }

      //Subtract from map
      targetHp += hpChange; //reverse it to add negative hp change to make the targetHp go DOWN

    });

    return payload;
  }

}