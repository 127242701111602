import {DefaultAction, DefaultNatural} from "../Action";
//import {testablePercentChance} from "../../../../helpers/testablePercentChance";
import { getAttackDamage, getStatusRemovingFromDamageSteps } from "../../formulas/getAttackDamage";

export default {
  ...DefaultAction,
  ...DefaultNatural,

  getUse() { return [] },

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {
    const damage = getAttackDamage(4, casterModel, targetModel, allUpgrades);


    return [
      {
        type: "eventDialogBox",
        subject: casterModel.id,
        textArray: ["Nervous mistake!"],
        haltQueueUntilDone: true,
      },

      {
        type: "eventAnimation",
        animationId: "animationNervousMistake",
        subjects: [targetModel.id],
        delayFramesAfter: 40,
        nervousPersonId: casterModel.id,
      },
      {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: targetModel.id,
            relativeChanges: {
              hp: damage
            },
            message: {
              theme: "battleNegativeHp",
              text: `${damage} HP`
            }
          }
        ],
      },
      ...getStatusRemovingFromDamageSteps(casterModel, targetModel, allUpgrades)

      ]
  }
}