import React from 'react'
import {connect} from 'react-redux'
import {chapter} from "../ChapterGetter";
import {setGlobalValue} from "../redux-actions/global-actions";
import {setOverworldValue} from "../redux-actions/overworld-actions";
import {setPlayerDataValue} from "../redux-actions/player-data-actions";
import Chapters from '../_data/Chapters'

class ChangingChapter extends React.Component {

  // state = {
  //   isFetching: true //? Do we need this?
  // };

  componentDidMount() {
    const {changeToChapterId, loadGameData} = this.props;
    
    //For web demo, go straight to coming soon/end of demo screen if incoming chapter is not a demo chapter
    //SEND TO COMING SOON IF ON DEMO AND NOT CHAPTER 1 or 2
    if (process.env.REACT_APP_IS_WEB_DEMO) { //Netlify supplies this value on build in web/prod env
      if (!["chapterJRQ", "chapterMTG"].includes(changeToChapterId)) {
        setGlobalValue({
          gameArea: "comingSoon"
        });
        return;
      }
    }
    //END DEMO ZONE

    const mapFileLocation = Chapters[changeToChapterId].endpoint;

    let useStartingMap = null;
    if (loadGameData) {

      useStartingMap = loadGameData.currentMapId;

      //Merge player data:
      setPlayerDataValue({
        ...loadGameData.playerData
      });
      setOverworldValue({
        returnFromLoadGameInteractives: [
          {
            id: "hero",
            x: loadGameData.heroEntranceX,
            y: loadGameData.heroEntranceY,
            direction: loadGameData.heroEntranceDirection || "DOWN"
          }
        ]
      });

      //Clear out this data so it doesn't hang around
      setGlobalValue({
        loadGameData: null
      })
    }


    fetch(mapFileLocation).then(response => { return response.json(); }).then(data => {
      chapter.set(data);
      if (!useStartingMap) {
        useStartingMap = data.startingMapId;
        if (!data.startingMapId) {
          console.warn(`no startingMapId found for chapter: ${changeToChapterId}`)
        }
      }

      //Set up the overworld for the first map we should be.
      setOverworldValue({
        currentMapId: useStartingMap
      });

      //Transition to the Overworld!
      setGlobalValue({
        gameArea: "overworld"
      })
    })
  }

  render() {
    return (
      null
    );
  }
}

export default connect((state, props) => {
  return {
    changeToChapterId: state.global.changeToChapterId,
    loadGameData: state.global.loadGameData
  }
})(ChangingChapter)