import BattleActions from '../battle-data/battle-actions-data'
//import Upgrades from '../_data/Upgrades'
import Skins from '../../_data/Skins'
import {negativeStatuses} from "../battle-data/formulas/battleStatuses";

let superActionKeys = Object.keys(BattleActions).filter(k => k.startsWith("super_"));
superActionKeys = ["", ...superActionKeys];


let attackActionKeys = Object.keys(BattleActions).filter(k => k.startsWith("script_"));
attackActionKeys = ["", ...attackActionKeys];

//const upgradeKeys = ["", ...Object.keys(Upgrades)];

const upgradeKeys = ["", ...Object.keys({})];
const skinKeys = Object.keys(Skins);

export const battlerOptions = {
  class: ["lead", "dps", "disrupt", "support"],
  level: function() {
    let payload = [];
    for (let i = 1; i<=30; i++) {
      payload.push(String(i));
    }
    return payload;
  }(),
  skinId: skinKeys,
  startHp: ["100%", "0", "1hp", "10%", "50%", "75%"],
  attack1: attackActionKeys, //all attacks
  attack2: attackActionKeys, //all attacks
  attack3: attackActionKeys, //all attacks
  attack4: attackActionKeys, //all attacks
  attackSuper: superActionKeys, //all supers,
  startingDangerMeter: ["0", "20", "40", "60", "80", "100"],
  upgrade: upgradeKeys,
  personality: [
    "regular",
    "vicious",
    "recovering",
    "cursing",
    "cleaning",
    "boosting",
    "slowing",
    "thief",
  ],
  status: [
    "normal",
    //positive:
      "focus", "fury", "deadline", "dangerous", "block",
    //negative:
      ...negativeStatuses
  ],
  accuracyModifier: [
    "0",
    "-999",
    "999"
  ]
};






export const defaultBattlerValues = {
  isIncluded: "true", //"true | false"
  teamId: "one", //"one" | "two"
  name: "A Name",
  skinId: "hank",
  class: "dps", //["lead", "dps", "disrupt", "support"]
  level: "1", //[1..30]
  startHp: "100%", //["100%", "0", "1hp", "10%", "50%", "75%"]
  attack1: "special_slice", //all attacks
  attack2: "", //all attacks
  attack3: "", //all attacks
  attack4: "", //all attacks
  attackSuper: "", //all supers,
  startingDangerMeter: "0",
  upgrade: "", //all upgrades,
  personality: "regular"
};