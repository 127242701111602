import {DefaultAction, DefaultScript} from "../Action";
import {getGenericFail} from "../../formulas/getGenericFail";
//import createPrefixedId from "../../../../helpers/make-id";

export default {
  ...DefaultAction,
  ...DefaultScript,
  name: "Garbage Jammer",
  description: "Causes a memory leak to 1 enemy",
  icon: "disrupt",

  shouldFailOnSingleTarget(casterModel, targetModel) {
    if (targetModel.status !== "normal") {
      return {
        failCode: "has-status" //could be "denied-by-upgrade" in the future, etc
      }
    }
    return null;
  },

  failEventsPerTarget(casterModel, targetModel, failInfo={}) {
    //Maybe add "WHY" here if an upgrade caused it to fail?
    return getGenericFail({targetModel})
  },

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {
    return [
      {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: targetModel.id,
            absoluteChanges: {
              status: "memory-leak"
            },
            message: {
              theme: "battleNegativeHp",
              text: `Memory Leak!`
            },
          }
        ],
      }
    ]
  }
}