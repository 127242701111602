import React from 'react';

export default function (props, state) {

  const style = function() {

    //Disabled styles
    if (props.isDisabled) {
      return {
        background: "#B8C3D1",
      }
    }

    //Regular styles
    return {
      background: "#4A90E2",
      borderBottom: "4px solid #2753CD"
    }
  }();

  return (
    <input
      style={{
        cursor: "pointer",
        display: "block",
        height: 44,
        width: "100%",
        //outline: 0,
        padding:0,
        fontFamily: "inherit",
        fontWeight: 700,
        fontSize: 16,
        border:0,
        color: "#fff",
        transition: "background 0.3s",
        ...style
      }}
      type="submit"
      value={props.text || "Submit"}
      //disabled={props.isDisabled ? "disabled" : "none"}
    />
  )
}