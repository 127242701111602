export function getNewTeamItems({ initialTeamOneItems, initialTeamTwoItems, instruction}) {

  const map = {
    "one": initialTeamOneItems,
    "two": initialTeamTwoItems,
  };

  //This is hilarious because I can't think or focus right now but at least you have passing tests
  if (instruction.type === "steal") {
    const fromTeam = map[ instruction.teamSwap[0] ];
    const toTeam = map[ instruction.teamSwap[1] ];
    const changeItems = instruction.instanceIds.map(id => {
      return fromTeam.find(item => item.instanceId === id);
    }).map(item => {return {...item, isBugged: false}});
    const newToItems = [...toTeam, ...changeItems];
    const newFromItems = fromTeam.filter(i => !instruction.instanceIds.includes(i.instanceId) )

    if (instruction.teamSwap[0] === "one") {
      return {
        newTeamOneItems: newFromItems,
        newTeamTwoItems: newToItems,
      }
    }
    if (instruction.teamSwap[0] === "two") {
      return {
        newTeamOneItems: newToItems,
        newTeamTwoItems: newFromItems,
      }
    }
  }

  if (instruction.type === "bug") {
    return {
      newTeamOneItems: initialTeamOneItems.map(item => {
        if (instruction.instanceIds.includes(item.instanceId)) {
          return {...item, isBugged: true}
        }
        return item;
      }),
      newTeamTwoItems: initialTeamTwoItems.map(item => {
        if (instruction.instanceIds.includes(item.instanceId)) {
          return {...item, isBugged: true}
        }
        return item;
      })
    }
  }

  if (instruction.type === "delete") {
    return {
      newTeamOneItems: initialTeamOneItems.filter(item => !instruction.instanceIds.includes(item.instanceId)),
      newTeamTwoItems: initialTeamTwoItems.filter(item => !instruction.instanceIds.includes(item.instanceId)),
    }
  }

  if (instruction.type === "deleteAll") {
    return {
      newTeamOneItems: instruction.teamId === "one" ? [] : initialTeamOneItems,
      newTeamTwoItems: instruction.teamId === "two" ? [] : initialTeamTwoItems,
    }
  }

  return {
    newTeamOneItems: initialTeamOneItems,
    newTeamTwoItems: initialTeamTwoItems,
  }
}