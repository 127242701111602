import React from "react";
import {connect} from "react-redux";
import AudioControl from '../audio/AudioControl'
//import FullScreenButton from './FullScreenButton'
import AudioVolumeButtons from './AudioVolumeButtons'
import BuyNowButton from '../BuyNowButton'

class TopUi extends React.Component {
  render() {
    const {pixelSize} = this.props;
    return (
      <div
        style={{
          position: "fixed",
          display: "flex",
          justifyContent: "flex-start",
          zIndex: 10,
          right: pixelSize*2,
          top: pixelSize*2,
        }}
      >
        {/*<div style={{marginRight: pixelSize}}>*/}
          {/*<FullScreenButton />*/}
        {/*</div>*/}
        <BuyNowButton />
        <AudioControl />
        <AudioVolumeButtons />
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    pixelSize: state.global.pixelSize
  };
})(TopUi);