import React from 'react'
import StaticTextLine from "../StaticTextLine";

class TargetingCrosshair extends React.PureComponent {

  render() {

    const themes = {
      friendly: {
        topOutline: "#1f2b14",
        mainOutline: "#162c03",
        topColor: "#85ce46",
        mainColor: "#9ae458",
      },
      enemy: {
        topOutline: "#1f2b14",
        mainOutline: "#300707",
        topColor: "#db1a1a",
        mainColor: "#e96161",
      }
    };

    const {pixelSize, isEnemy, text} = this.props;
    const {topOutline, mainOutline, topColor, mainColor} = themes[isEnemy ? "enemy" : "friendly"];


    return (
      <React.Fragment>
        <div style={{
          position: "relative",
          overflow: "hidden",
          width: pixelSize * 32,
          height: pixelSize * 32,
        }}>
          <svg style={{
            width: (pixelSize * 128),
            display: "block",
            animation: `svg_targetingCrosshair 0.2s steps(3, end) 0s infinite alternate-reverse none running`
          }} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 128 32" shapeRendering="crispEdges">
            <style>
              {`

            @keyframes svg_targetingCrosshair {
                  0% { transform: translate3d(0,0,0) }
                  100% { transform: translate3d(-75%,0,0) }
            }
        `}
            </style>
            <path stroke={topOutline}
                  d="M15 0h3m29 1h3m61 0h3M79 2h3M2 13h1m27 0h1m4 0h1m25 0h1m6 0h1m23 0h1m6 0h1m25 0h1M80 25h1m-33 1h1m30 0h1m1 0h1m30 0h1m-97 1h1m30 0h1m1 0h1m61 0h1m1 0h1m-99 1h1m1 0h1"/>
            <path stroke={mainOutline}
                  d="M14 1h1m3 0h1m-4 1h1m1 0h1m28 0h1m3 0h1m59 0h1m3 0h1M16 3h1m30 0h1m1 0h1m28 0h1m3 0h1m28 0h1m1 0h1M48 4h1m30 0h1m1 0h1m30 0h1M80 5h1M1 14h1m1 0h1m25 0h1m1 0h1m2 0h1m1 0h1m23 0h1m1 0h1m4 0h1m1 0h1m21 0h1m1 0h1m4 0h1m1 0h1m23 0h1m1 0h1M1 15h1m2 0h1m23 0h1m2 0h1m2 0h1m2 0h1m21 0h1m2 0h1m4 0h1m2 0h1m19 0h1m2 0h1m4 0h1m2 0h1m21 0h1m2 0h1M1 16h1m1 0h1m25 0h1m1 0h1m2 0h1m1 0h1m23 0h1m1 0h1m4 0h1m1 0h1m21 0h1m1 0h1m4 0h1m1 0h1m23 0h1m1 0h1M2 17h1m27 0h1m4 0h1m25 0h1m6 0h1m23 0h1m6 0h1m25 0h1M78 27h1m3 0h1m-37 1h1m3 0h1m28 0h3m28 0h1m3 0h1M14 29h1m3 0h1m28 0h3m61 0h3m-99 1h3"/>
            <path stroke={topColor}
                  d="M15 1h3m29 1h3m61 0h3M79 3h3M2 14h1m27 0h1m4 0h1m25 0h1m6 0h1m23 0h1m6 0h1m25 0h1M79 27h3m-35 1h3m61 0h3m-99 1h3"/>
            <path stroke={mainColor}
                  d="M16 2h1m31 1h1m63 0h1M80 4h1M2 15h2m25 0h2m4 0h2m23 0h2m6 0h2m21 0h2m6 0h2m23 0h2M2 16h1m27 0h1m4 0h1m25 0h1m6 0h1m23 0h1m6 0h1m25 0h1M80 26h1m-33 1h1m63 0h1m-97 1h1"/>
          </svg>
        </div>
        {
          isEnemy && (
            <div style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              bottom: pixelSize * -16,
              background: "#db1a1a",
              borderBottom: `${pixelSize}px solid #790b20`,
              paddingTop: pixelSize * 3,
              paddingLeft: pixelSize * 3,
              paddingRight: pixelSize * 3,
              whiteSpace: "nowrap"
            }}>
              <StaticTextLine
                theme="white"
                pixelSize={pixelSize}
                text={text.toUpperCase()}
              />
            </div>
          )
        }
      </React.Fragment>
    )
  }
}

export default TargetingCrosshair;