import createStore from './create-store'
import {getQueryVariable} from "../helpers/get-query-variable";
import {getAudioPreferenceFromLocalstorage, getAudioVolumeFromLocalstorage} from "../audio/audio-localstorage-operations";
import {getDefaultPlayerData} from './getDefaultPlayerData'
import {getDefaultChapter} from './getDefaultChapter'
import createPrefixedId from "../helpers/make-id";

export default createStore({
  global: {
    userRequestedFullScreen: true, //defaulting to full screen on Electron
    version: "6.24.2020",
    audioMode: getAudioPreferenceFromLocalstorage() || "full", //"full" | "off"
    audioVolume: getAudioVolumeFromLocalstorage() || 100, //percent, so rounding doesn't get dumb

    //isImpersonateMode: false,
    authState: null, //null (pending) | "loggedIn" | "guest"
    eventSessionId: createPrefixedId("session"),
    currentGameFileId: null, //created on first save to Firebase
    launchAuthMenuInState: null, //null (not mounted) | "createAccount" | "logIn"
    pixelSize: 3,

    //gameArea: "battle", //"changingChapter", //"overworld", "battle"
    gameArea: (() => {

      // console.log('warn fix gameArea starting on title')
      //return "title"

      if (process.env.REACT_APP_FIREBASE_CONFIG === "production") {
        return "title"
      }
      if (Boolean(getQueryVariable("battle"))) {
        return "battle"
      }
      if (process.env.NODE_ENV === "production" && !getQueryVariable("load")) {
        return "title";
      }

      if (getQueryVariable("load") === "title") {
        //Title in dev mode
        return "title"
      }
      // return "battle"
      //return "title"
      //return "comingSoon";
      //return "credits";
      return "changingChapter" //use "changingChapter" to load a real chapter correctly.
    })(),

    changeToChapterId: getDefaultChapter() //"chapterJRQ" //I don't know if we need this or not initially?
  },

  playerData: getDefaultPlayerData(),

  overworld: {
    currentMapId: null, //string... set before anything loads
    externalEventTrigger: 0, //one external event is "finished" when this value changes
    storyPointTrigger: 0, //changed when story points are added
    dialogMessageContent: null,
    journalPopup: null,
    showLoginReminder: null,
    isSavingIndicatorOpen: false,

    showGlobalHudUnreadBadge: false,
    showUnreadMarketBadge: false,
    showUnreadScriptsBadge: false,

    mapChangePayload: null,
      //Case 1: Object gets set by external event and then read by `OverworldContainer` then cleared out after use

    returnFromBattleHeroProperties: null,
      //Usage: This is set by externalEventHandler. It saves the player's location. so we can reload after battle
      //TODO: Shouldn't we be saving all people in here? We need to reload all people in the correct spot.
      // returnFromBattleHeroProperties: {
      //   x: 0,
      //   y: 0,
      //   direction: "RIGHT",
      // }
    returnFromBattleInteractives: null, // array of interactives that gets published when transitioning to battle
      //The purpose is to reload a person at their correct spot

    refreshPeopleList: null,
    isPauseMenuOpen: false,
    isRecoverMenuOpen: false,
    isHowToPlayMessageOpen: false, //false,
    showCreatePersonMenu: null, //object provided by event

    overworldFadeType: null,
    battle_currentBattleId: "dev-1", //Refers to a battleId in the current chapter (Dev fixture chapter, in this case)
    battle_arenaId: "office" //Maps set this when loading
  }
})