export default function() {

  //For production, return production firebase app config
  //This value is passed in Netlify
  if (process.env.REACT_APP_FIREBASE_CONFIG === "production") {
    return {
      apiKey: "AIzaSyDyAGX1SdG2CGF0-VwqANizhdfZppiTgKY",
      authDomain: "thedangercrew-production.firebaseapp.com",
      databaseURL: "https://thedangercrew-production.firebaseio.com",
      projectId: "thedangercrew-production",
      storageBucket: "thedangercrew-production.appspot.com",
      messagingSenderId: "1016690197057"
    };
  }


  return {
    apiKey: "AIzaSyAQ50GqkYf0nHIJaAnr646U2f7H079B2mE",
    authDomain: "tdc-v1-development.firebaseapp.com",
    databaseURL: "https://tdc-v1-development.firebaseio.com",
    projectId: "tdc-v1-development",
    storageBucket: "tdc-v1-development.appspot.com",
    messagingSenderId: "1056598790443"
  }
}