import React from 'react'
import FormField from './FormField'
import {getEmailValidationErrors, getReturningPasswordErrors} from './validations'
import FormSubmitButton from './FormSubmitButton'
import {authLogIn} from "./auth-requests";

class LoginForm extends React.Component {

  state = {
    hasSubmitted: false,
    isLocked: false,
    globalErrors: []
  };

  handleSubmitRequest = () => {

    if (this.state.isLocked) { return }

    this.props.setLoading(true);
    this.setState({ isLocked: true });

    authLogIn({
      email: this.props.fieldEmail,
      password: this.props.fieldPassword,

      success: (response) => {
        this.props.handleChangeViewId("loginSuccess");
        this.props.setLoading(false);
      },

      fail: (response) => {
        this.setState({
          isLocked: false,
          globalErrors: response.errors //[] whatever was in the response
        });
        this.props.setLoading(false);
      }
    })

  };


  getProblems() {
    let payload = {};
    const {fieldEmail, fieldPassword} = this.props;
    [
      {input: fieldEmail, fn: getEmailValidationErrors, key: "email"},
      {input: fieldPassword, fn: getReturningPasswordErrors, key: "password"},
    ].forEach(test => {
      const error = test.fn(test.input);
      if (error) {
        payload[test.key] = error;
      }
    });
    return payload;
  }

  render() {
    const {updateFormField, fieldEmail, fieldPassword} = this.props;
    const problems = this.getProblems();
    const isFormReady = Object.keys(problems).length === 0;

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (isFormReady) {
            this.handleSubmitRequest()
          }
        }}
      >

        <h2 style={{margin:"0 0 1em 0"}}>Log In</h2>

        <FormField
          label="Email Address"
          autoFocus={true}
          placeholder="you@email.com"
          name="login_email"
          error={problems["email"] || null}
          formName="input_login_email"
          autoComplete="email"
          type="email"
          value={fieldEmail}
          onChange={(newValue) => {
            updateFormField("fieldEmail", newValue)
          }}
          hasSubmitted={this.state.hasSubmitted}
        />
        <FormField
          label="Password"
          name="login_password"
          error={problems["password"] || null}
          formName="input_login_password"
          autoComplete="current-password"
          type="password"
          value={fieldPassword}
          onChange={(newValue) => {
            updateFormField("fieldPassword", newValue)
          }}
          renderSubtext={() => {
            return (
                <a href="#forgotPassword"
                   style={{color: "#4A90E2", fontSize: "0.8em", textDecoration: "none"}}
                   onClick={(e) => {
                     e.preventDefault();
                     this.props.handleSetForgotPassword(true)
                   }}>
                  Forgot Password?
                </a>
            )
          }}
          hasSubmitted={this.state.hasSubmitted}
        />

        <FormSubmitButton
          text="Log In"
          isDisabled={!isFormReady}
        />
        {
          this.state.globalErrors.length > 0 && (
            this.state.globalErrors.map((error, key) => {
              return <div style={{color: "red", padding: "1em 0 0", textAlign: "center"}} key={key}>{error}</div>
            })
          )
        }
      </form>
    );
  }
}

export default LoginForm;
