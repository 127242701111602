import React from 'react'
import {waitFrames} from "../../../helpers/waitFrames";
import RunAnimation from './RunAnimation'
//import {audioManager} from "../../../audio/audioManager";


class AnimationCreatePromise extends React.Component {

  state = {
    animationsFinished: 0,
    characterJumpsFinished: 0,

  };

  componentDidMount() {
    const targets = this.props.event.subjects;

    //audioManager.playSfx("sfx_useItem"); //don't have the perfect sfx for this yet

    waitFrames(10, () => {
      targets.forEach(id => {
        this.props.handleCombatantStateChange([
          {
            combatantId: id,
            absoluteChanges: {
              animation: "characterJump",
            }
          }
        ]);
        waitFrames(30, () => {
          this.props.handleCombatantStateChange([
            {
              combatantId: id,
              absoluteChanges: {
                animation: "none",
              }
            }
          ]);
          this.setState(state => ({characterJumpsFinished: state.characterJumpsFinished+1}))
        })
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const targets = this.props.event.subjects;
    const wasFinished = (
      prevState.animationsFinished === targets.length &&
      prevState.characterJumpsFinished === targets.length
    );
    const nowFinished = (
      this.state.animationsFinished === targets.length &&
      this.state.characterJumpsFinished === targets.length
    );
    if (!wasFinished && nowFinished) {
      this.props.onEventDone()
    }
  }

  render() {
    const {pixelSize, event, positions} = this.props;
    return event.subjects.map((subjectId, i) => {
      return (
        <RunAnimation
          key={subjectId}
          pixelSize={pixelSize}
          left={positions[subjectId].leftPosition}
          top={positions[subjectId].topPosition}
          widthOfSpriteSheet={128}
          frames={4}
          duration="0.3s"
          onDone={() => {
            this.setState(state => ({animationsFinished: state.animationsFinished+1}))
          }}
          renderPaths={() => (
            <React.Fragment>
              <path stroke="#feda57" d="M73 2h1M73 3h1M51 8h1M43 10h1M101 18h1" />
              <path stroke="#ffffff" d="M84 2h1M84 3h1M100 3h1M122 3h1M15 4h1M15 5h1M15 6h2M52 6h1M89 6h1M15 7h2M89 7h2M98 7h1M15 8h2M42 8h1M44 8h1M125 8h1M16 9h1M16 10h1M52 10h1M69 11h1M69 12h1M83 16h1M83 17h1M104 22h1M118 22h1" />
              <path stroke="#0fb7ec" d="M43 6h1M70 7h1M54 8h1M91 14h1M122 18h1" />
            </React.Fragment>
          )}
        />
      )
    })
  }
}

export default AnimationCreatePromise;