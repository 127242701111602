import {DefaultAction, DefaultScript} from "../Action";
import {getGenericFail} from '../../formulas/getGenericFail'
import createPrefixedId from "../../../../helpers/make-id";

export default {
  ...DefaultAction,
  ...DefaultScript,
  name: "DDoS",
  description: "Causes an opponent to lag for 3 rounds",
  icon: "disrupt",

  shouldFailOnSingleTarget(casterModel, targetModel, allUpgrades={}, actionModel) {

    if (targetModel.status !== "normal") {
      return {
        failCode: "has-status" //could be "denied-by-upgrade" in the future, etc
      }
    }

    if (targetModel.immunities && targetModel.immunities.includes("lag")) {
      return {
        failCode: "immune"
      }
    }

    const targetUpgrade = allUpgrades[targetModel.activeUpgradeId || null];
    if (targetUpgrade && targetUpgrade.preventsStatus === "lag") {
      return {
        failCode: "upgrade",
        failMessage: "Hard Wired!"
      }
    }

    return null;
  },

  failEventsPerTarget(casterModel, targetModel, failInfo={}) {
    //Maybe add "WHY" here if an upgrade caused it to fail?
    return getGenericFail({targetModel, failInfo})
  },

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {
    const resolveId = createPrefixedId('natural-resolve', 5);
    return [
      {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: targetModel.id,
            absoluteChanges: {
              status: "lag",
              statusResolveId: resolveId
            },
            message: {
              theme: "battleNegativeStatus",
              text: `LAG!`
            },
          }
        ],
      },
      {
        type: "eventSchedule",
        roundsFromNow: 3,
        combatantId: targetModel.id,
        event: {
          actionId: "natural_end-lag",
          casterId: targetModel.id,
          targetIds: [targetModel.id],
          mergeData: {
            naturalActionResolveId: resolveId
          },
        }
      }
    ]
  }
}