//We are ENTERING a map (or RE-ENTERING, or STARTING FRESH or GETTING A STORY POINT)
//We need to return the object structure that will get piped into the Overworld component
import {getRelevantInteractiveScenario} from "../story-points/getRelevantInteractiveScenario";
import {interactiveShouldHaveQuestArrow} from './interactiveShouldHaveQuestArrow'
import { getQueryVariable } from "../helpers/get-query-variable";

export function getInitialMapState(rawMapFromEditor={}, properties={}) {

  //Get the map person as the correct scenario
  let peopleWithScenarios = (rawMapFromEditor.interactives || []).map(interactive => {
    const scenario = getRelevantInteractiveScenario(interactive.scenarios, properties.acquiredStoryPoints);

    return scenario ? {
      id: interactive.id,
      skinId: interactive.skinId,
      ...scenario,
    } : null;
  }).filter(b => b);

  const mergeInteractives = properties.mergeInteractives || [];
  const acquiredMembers = properties.acquiredMembers || [];

  let startX = function() {
    if (properties.playerX || properties.playerX === 0) { return Number(properties.playerX) }
    if (rawMapFromEditor.playerX) { return Number(rawMapFromEditor.playerX) }
    return 0;
  }();

  let startY = function() {
    if (properties.playerY || properties.playerY === 0) { return Number(properties.playerY) }
    if (rawMapFromEditor.playerY) { return Number(rawMapFromEditor.playerY) }
    return 0;
  }();


  let hero = function() {

    //Find the hero from my character backlog
    let backlogHero = acquiredMembers.find(m => m.id === "hero");
    if (backlogHero) {
      return backlogHero
    }

    return {}
  }();

  let heroPerson = {
    skinId: "mystery",
    ...hero,
    id: "hero",
    customMovingSpeed: function() {
      //Hero movement speed!
      //Allow speedy flag in dev mode
      if (process.env.REACT_APP_FIREBASE_CONFIG !== "production") {
        if (getQueryVariable("speedy")) {
          return 3
        }
      }

      return 1.45454545455; //(32 divided by 22)
      //Alternative: 1.28, //(32 divided by 25)
      // was originally 1.2 before user feedback
    }(),
    isArrowControlled: true,
    x: startX,
    y: startY,
    direction: properties.playerDirection || "DOWN"
  };

  //Allow the map to START with the hero invisible, but don't look at this if this is a mere person refresh after a story point (we pass the currentHero in)
  //Otherwise, the player will go back to being invisible on any of these `playerStartsInvisible` on a SP update
  if (rawMapFromEditor.playerStartsInvisible && !properties.currentHero) {
    heroPerson.isInvisible = true;
  }
  //If we already have an INVISIBLE hero, that means we want the hero to STAY invisible through the SP refresh
  if (properties.currentHero && properties.currentHero.isInvisible) {
      heroPerson.isInvisible = true;
  }
  //NOTE TO SELF: This stuff is gross, but it really only affects ONBOARDING and the ENDING right now. Those are the only maps where you start invisible.
  //The system was not set up in a good way to let the hero have properties based on scenario, or to only do stuff on first map load

  //Add a hero
  let interactives = [
    heroPerson,
    ...peopleWithScenarios,
  ].map(person => {
    const mergePerson = mergeInteractives.find(mP => mP.id === person.id) || {};

    return {
      ...person,
      ...mergePerson,

      currentActionType: mergePerson.currentActionType === "characterJump" ? "characterStand" : mergePerson.currentActionType,


      hasQuestIndicator: interactiveShouldHaveQuestArrow(person, rawMapFromEditor.interactiveActions, properties.acquiredStoryPoints),

      isHostile: person.isHostile || false, //always use the scenario's version of isHostile (so stale hostile doesnt get merged back in)
      hasBattleLaptop: false //Always clear out battle laptops when returning to a map
    }
  });

  return {
    ...rawMapFromEditor,
    addExplodingSpaceAtHeroPosition: properties.addExplodingSpaceAtHeroPosition || false,
    interactives
  };
}