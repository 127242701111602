import React from 'react'
import {setOverworldValue} from "./redux-actions/overworld-actions";
import {getNextExternalEventTrigger} from "./Overworld/getIncrExternalEventTrigger";
import {waitFrames} from "./helpers/waitFrames";
import BoxDiv from "./interface/BoxDiv";
import BoxDivBody from "./interface/BoxDivBody";
import StaticTextLine from "./StaticTextLine";
import BoxDivTippyTop from "./interface/BoxDivTippyTop.js";
import {audioManager} from "./audio/audioManager";

class LoginReminderMessage extends React.Component {

  componentDidMount() {

    audioManager.playSfx("sfx_popup");

    //Unlock the overworld right away
    setOverworldValue({
      externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-LOGIN-REMINDER")
    });

    //Keep the window up for a bit
    waitFrames(500, () => {
      setOverworldValue({showLoginReminder: false})
    })

  }

  componentWillUnmount() {
    //unmount it early so it doesn't come back. (maybe a text message came up)
    setOverworldValue({showLoginReminder: false})
  }


  render() {
    const {pixelSize} = this.props;
    const indentStyle = {
      paddingLeft: pixelSize * 4
    };
    return (
      <div style={{
        position: "absolute",
        left: pixelSize * 4,
        bottom:pixelSize * 4,
        right: pixelSize * 4,
      }}>
        <BoxDiv pixelSize={pixelSize}>
          <BoxDivTippyTop pixelSize={pixelSize} />
          <BoxDivBody
            pixelSize={pixelSize}
            style={{
              padding: pixelSize * 4
            }}
          >
            <div style={indentStyle}>
              <StaticTextLine
                pixelSize={pixelSize}
                text="Alert! Your progress is not being saved!"
              />
            </div>
            <div style={indentStyle}>
              <StaticTextLine
                pixelSize={pixelSize}
                text="Log In from the Pause Menu (Esc) to save your game!"
              />
            </div>
          </BoxDivBody>
        </BoxDiv>
      </div>
    );
  }
}

export default LoginReminderMessage;