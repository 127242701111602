import {shuffle} from "../../../helpers/shuffle";

export default function({combatants=[], battleStoryPoints=[], regularDecision}) {

  //First enemy is the bully.
  const bully = combatants.find(c => c.belongsToTeam === "two");

  //Player
  const hero = combatants.find(c => c.belongsToTeam === "one");
  const heroHpPercent = (hero.hp / hero.maxHp) * 100;

  if (heroHpPercent >= 90) {
    return {
      submission: [
        {
          actionId: "speak",
          casterId: bully.id,
          targetIds: [hero.id],
          mergeData: {
            dialogContent: [
              "Eat my Array, noobie!"
            ]
          }
        },
        {
          actionId: "script_foreach",
          casterId: bully.id,
          targetIds: [hero.id],
        },
      ],
      updatedBattleStoryPoints: battleStoryPoints
    }
  }

  if (heroHpPercent <= 50 && bully.status === "normal") {
    return {
      submission: [
        {
          actionId: "speak",
          casterId: bully.id,
          targetIds: [bully.id],
          mergeData: {
            dialogContent: [
              "You're boring me!"
            ]
          }
        },
        {
          actionId: "script_headphones",
          casterId: bully.id,
          targetIds: [bully.id],
        },
      ],
      updatedBattleStoryPoints: battleStoryPoints
    }
  }
  if (heroHpPercent <= 50 && bully.status !== "normal") {
    return {
      submission: [
        {
          actionId: "speak",
          casterId: bully.id,
          targetIds: [bully.id],
          mergeData: {
            dialogContent: [
              shuffle([
                "Think you got what it takes?",
                "I don't got all day!",
                "Is that the best you've got?!",
                "I bet a pile of rocks could code better than you.",
                "It's like I'm one with the computer.",
                "I doubt you're half as programmatic as me!",
                "I've never coded a bug in my life!",
                "I write algorithms in my sleep!"
              ])[0]
            ]
          }
        }
      ],
      updatedBattleStoryPoints: battleStoryPoints
    }
  }


  //Maybe submit something custom, OR just use regularDecision which goes through the usual decision class
  //console.log('default to this', regularDecision)
  return {
    submission: regularDecision,
    updatedBattleStoryPoints: battleStoryPoints
  }
}
