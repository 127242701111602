export default {
  "fsbBasement": {
    src: "/images/arenas/basement-arena.png",
    background: "#9ed0c5"
  },
  "marylandAve": {
    src: "/images/arenas/maryland-ave-arena-b.png", //src: "/images/arenas/maryland-ave-arena.png",
    background: "#baffba"
  },
  "outsideFsb": {
    //src: "/images/arenas/outside-fsb-arena2.png", //"/images/arenas/outside-fsb-arena.png",
    src: "/images/arenas/outside-fsb-arena.png",
    background: "#baffba"
  },
  "office": {
    src: "/images/arenas/office-arena.png",
    background: "#9ac3cf"
  },
  "coffeeshop": {
    src: "/images/arenas/coffee-arena.png",
    background: "#f9ac89"
  },
  "serverRoom": {
    src: "/images/arenas/server-room-arena.png",
    background: "#f1f1f1",
    useScriptedDecisionFn: "serverRoomArena"
  },

  "serverRoomRichard": {
    src: "/images/arenas/server-room-richard-arena.png",
    background: "#f1f1f1",
    useScriptedDecisionFn: "serverRoomArena"
  },


  "eastClayton": {
    src: "/images/arenas/east-clayton-arena.png",
    background: "#bff3ff",
  },
  "karbon": {
    src: "/images/arenas/karbon-arena.png",
    background: "#6f7da9",
  },


  "shawpark": {
    src: "/images/arenas/shaw-top-arena.png",
    background: "#77c684"
  },
  "shawDark": {
    src: "/images/arenas/shaw-dark-arena.png",
    background: "#55865B"
  },
  "shawDarkest": {
    src: "/images/arenas/shaw-darkest-arena.png",
    background: "#245a0b",
    //useScriptedDecisionFn: "serverRoomArena"
  },


  "venue": {
    src: "/images/arenas/venue-arena.png",
    background: "#f59494",
  },
  "theater": {
    src: "/images/arenas/theater-arena.png",
    background: "#f36c6c",
  },

  "highwayGrass": {
    src: "/images/arenas/highway-grass-arena.png",
    background: "#aca99e",
  },
  "highwayPlatform": {
    src: "/images/arenas/highway-platform-arena.png",
    background: "#aca99e",
  },
  "highwayTower": {
    src: "/images/arenas/highway-tower-arena.png",
    background: "#7c7570",
  },
  "nightRoof": {
    src: "/images/arenas/roof-arena.png",
    background: "#211133",
  },

  "officeMacro": {
    src: "/images/arenas/office-arena.png",
    background: "#f36c6c"
  },


}