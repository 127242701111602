import React from "react";
import StaticTextLine from "../StaticTextLine";
import ArrowMenu from "../ArrowMenu/ArrowMenu";
import PauseHeader from "./PauseHeader";
import { connect } from "react-redux";
import Icon from "../RecoverMenu/RecoverMenu";
import ScrollingOptionsList from "../menu-components/ScrollingOptionsList";
import { setGlobalValue } from "../redux-actions/global-actions";
import createPrefixedId from "../helpers/make-id";
import { setOverworldValue } from "../redux-actions/overworld-actions";
import { audioManager } from "../audio/audioManager";

class PauseBackToTitleArrowMenu extends React.Component {

  optionHandler = (action={}) => {

    if (action.type === "GO_TO_TITLE_SCREEN") {

      audioManager.stopSong();

      setOverworldValue({
        isPauseMenuOpen: false
      });
      setGlobalValue({
        currentGameFileId: null, //clear out this value
        eventSessionId: createPrefixedId("session"), //same thing "store" does on page load. Just emulating here
        gameArea: "title"
      });
      return;
    }
    //back:
    if (action.type === "GO_BACK") {
      this.props.handleChangeMenuPage("home");
    }
  };

  render() {
    const { pixelSize } = this.props;

    const map = [
      ["toTitleScreen"],
      ["back"],
    ];

    const menu = {
      "toTitleScreen": {
        label: "Quit to Title Screen",
        handler() {
          return {
            type: "GO_TO_TITLE_SCREEN"
          }
        }
      },
      "back": {
        label: "Cancel",
        handler() {
          return { type: "GO_BACK"}
        }
      }
    };


    return (
      <ArrowMenu
        handler={this.optionHandler}
        backOptionId={"back"}
        optionsPageId={"swap"}
        optionsMap={map}
        optionModels={menu}
        render={({ handlers, selectedOptionId }) => {
          return (
            <div style={{
              width: pixelSize * 170
            }}>
              <PauseHeader
                title={"Title Screen"}
                handleBackClick={() => this.handleUnpause()}
              />
              <div style={{
                paddingBottom:pixelSize * 4,
                display: "flex",
                justifyContent: "center"
              }}>
                <StaticTextLine
                  pixelSize={pixelSize}
                  text="Any unsaved progress will be lost!"
                />
              </div>

            <ScrollingOptionsList
              handlers={handlers}
              optionsMap={map}
              optionModels={menu}
              selectedOptionId={selectedOptionId}
              pixelSize={pixelSize}
              renderOptionRight={(option) => {
                if (option.chargeCredits) {
                  return (
                    <React.Fragment>
                      <div style={{marginRight: pixelSize, marginBottom: pixelSize}}>
                        <Icon
                          category="credit"
                          pixelSize={pixelSize}
                        />
                      </div>
                      <StaticTextLine
                        pixelSize={pixelSize}
                        text={`${option.chargeCredits}`}
                      />
                    </React.Fragment>
                  )
                }
                return null
              }}
            />
            </div>
          );
        }}
      />
    );
  }
}

export default connect((state, props) => {
  return {
  }
})(PauseBackToTitleArrowMenu)
