import React from 'react'
import {waitFrames} from "../helpers/waitFrames";

class EventStep extends React.Component {

  constructor() {
    super();
    //assign me a random interval for frames
    
    let base = Math.floor(Math.random() * 10);
    base +=1 ;
    base *= 10;
    this.count = base;
  }
  
  componentDidMount() {
    waitFrames(this.count, () => {
      this.props.onEventDone();
    })
  }


  render() {
    return (
      <div>Some textmessage, blippy, animation or something</div>
    );
  }
}

EventStep.defaultProps = {
  onEventDone: function() {} //ALL EVENT STEP TYPES NEED TO FIRE ONE OF THESE WHEN DONE! :)
}


export default EventStep;