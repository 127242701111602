export function createSummary(submission={}, combatants=[], eventSteps=[]) {
  const casterModel = combatants.find(c => c.id === submission.casterId);


  //Also tack on extra stuff to the summary based on what came out of the eventSteps
  //EX 1: Knowing which instanceId of an item we stole so we can automatically use it


  let payload = {
    casterId: submission.casterId,
    actionId: submission.actionId,
    targetIds: submission.targetIds,
    casterUpgradeId: casterModel.activeUpgradeId || null,
  };

  eventSteps.forEach(step => {
    if (
      step.teamItemChangeInstructions &&
      step.teamItemChangeInstructions.type === "steal" &&
      step.teamItemChangeInstructions.instanceIds &&
      step.teamItemChangeInstructions.stolenActionIds
    ) {
      payload.stolenInstanceIds = [...step.teamItemChangeInstructions.instanceIds];
      payload.stolenActionIds = [...step.teamItemChangeInstructions.stolenActionIds];
    }
  });


  return payload
}

