import store from '../init/store'
import {setOverworldValue} from "../redux-actions/overworld-actions";
import {setGlobalValue} from "../redux-actions/global-actions";
import {shuffle} from "../helpers/shuffle";
import {chapter} from '../ChapterGetter'
import {writeSaveFileToLocalStorage} from "./localstorageSaveFiles";
// let firebase = require('firebase/app');
// require('firebase/auth');
// require('firebase/database');

function createSaveFile({belongsToUserId}) {
  let {currentMapId, heroEntranceX, heroEntranceY, heroEntranceDirection} = store.getState().overworld;

  let payload = {
    //Player data
    playerData: { //Nesting it like this makes it easier to merge when loading
      ...store.getState().playerData,
    },
    //Map stuff
    chapterId: chapter.data.chapterId,
    currentMapId,


    //User stuff
    timestamp: Date.now(),
    belongsToUserId,
  };

  if (typeof heroEntranceX !== "undefined") {
    payload.heroEntranceX = Number(heroEntranceX)
  }
  if (typeof heroEntranceY !== "undefined") {
    payload.heroEntranceY = Number(heroEntranceY)
  }
  payload.heroEntranceDirection = heroEntranceDirection;

  return payload;
}

export function createSaveFileId(userId) {
  let randomHash = "";
  for(let i = 0; i < 5; i++) {
    randomHash += String(shuffle([0,1,2,3,4,5,6,7,8,9])[0]);
  }
  return `${userId}-${Date.now()}-${randomHash}`
}

export function handleSaveGameEvent() {

  //First, get the current user logged in to Firebase (don't trust local state)
  //const user = firebase.auth().currentUser;


  //if (user && !user.isAnonymous) { //do not save for anon people


    const userId = "local"; //used to be `user.uid` from Firebase

    //Show the indicator and schedule it to go away
    setOverworldValue({ isSavingIndicatorOpen: true });
    setTimeout(() => {
      setOverworldValue({ isSavingIndicatorOpen: false })
    }, 3000);

    //create the object that will be written to File System (was Firebase)
    const saveState = createSaveFile({
      belongsToUserId: userId
    });

    //Look up the game id
    let currentGameFileId = store.getState().global.currentGameFileId;

    //If we don't have an id yet (new game) create one and store it in redux so we will continue to update this file as we play
    if (!currentGameFileId) {
      currentGameFileId = createSaveFileId(userId);
      setGlobalValue({
        currentGameFileId
      });
    }

    //Save in either Electron file system or web localStorage
    if (process.env.REACT_APP_ELECTRON_BUILD) {
      //WAY 1: [Electron] Call the saving function to write to disk
      const clientWriteSaveToLocalMachine = window.clientWriteSaveToLocalMachine || function(saveFileKey, saveFileData = {}, callback) {
        //this is the mock function for writing files. (in case we're not in Electron env, or this function is somehow not there)
        console.warn('stubbing Electron `clientWriteSaveToLocalMachine` with no-op');
        callback()
      };
      clientWriteSaveToLocalMachine(currentGameFileId, saveState, () => {
        //Not using the callback at the moment.
      });
    } else {
      //WAY 2: [Web] Call localstorage saving mechanism
      writeSaveFileToLocalStorage(currentGameFileId, saveState)
    }

    /* Firebase material in case we bring it back
    //Two calls to Firebase: (currently not checking for errors, or handling any response at all really)
    //Write the save file
    firebase.database().ref(`/saves/${currentGameFileId}`).set(saveState).then(() => {
      console.log('save written')
    });
    //Write the user's reference to this save file
    firebase.database().ref(`/users/${userId}/saves/${currentGameFileId}`).set(true).then(() => {
      console.log('user reference written')
    });
    */
  //}
}