import React from 'react'
import {createSingleKeyPressBinding} from '../../helpers/createSingleKeyPressBinding'
import {addArrow, removeArrow} from './add-direction'


class NewKeyboardArrowController extends React.Component {

  componentDidMount() {

    const upPress = () => { this.props.handleNewArrows( addArrow("UP", this.props.arrows)) };
    const upRelease = () => { this.props.handleNewArrows( removeArrow("UP", this.props.arrows))};
    const downPress = () => { this.props.handleNewArrows( addArrow("DOWN", this.props.arrows)) };
    const downRelease = () => { this.props.handleNewArrows( removeArrow("DOWN", this.props.arrows))};
    const leftPress = () => { this.props.handleNewArrows( addArrow("LEFT", this.props.arrows)) };
    const leftRelease = () => { this.props.handleNewArrows( removeArrow("LEFT", this.props.arrows))};
    const rightPress = () => { this.props.handleNewArrows( addArrow("RIGHT", this.props.arrows)) };
    const rightRelease = () => { this.props.handleNewArrows( removeArrow("RIGHT", this.props.arrows))};

    this.upKeyHandlers = createSingleKeyPressBinding([38, 87], upPress, upRelease );
    this.downKeyHandlers = createSingleKeyPressBinding([40, 83], downPress, downRelease );
    this.leftKeyHandlers = createSingleKeyPressBinding([37, 65], leftPress, leftRelease );
    this.rightKeyHandlers = createSingleKeyPressBinding([39, 68], rightPress, rightRelease );
  }

  componentWillUnmount() {
    let el = document.getElementsByTagName("body")[0];
    [
      ...this.upKeyHandlers,
      ...this.downKeyHandlers,
      ...this.leftKeyHandlers,
      ...this.rightKeyHandlers,
    ].forEach(b => {
      el.removeEventListener("keydown", b.keyDownHandler);
      el.removeEventListener("keyup", b.keyUpHandler);
    });
    //console.log('bindings removed!')
  }


  render() {
    return (
      null
    );
  }
}

export default NewKeyboardArrowController;