import React from "react";
import BoxDiv from "../interface/BoxDiv";
import BoxDivBody from "../interface/BoxDivBody";
import StaticTextLine from "../StaticTextLine";
import ArrowMenu from "../ArrowMenu/ArrowMenu";
import { getPauseLineupSwapMenu } from "./getPauseLineupSwapMenu";
import Icon from "../interface/Icon";
import PauseHeader from "./PauseHeader";
import ArrowMenuOption from "../ArrowMenu/ArrowMenuOption";
import MapPerson from "../Overworld/MapPerson";
import {removeSlotNumberFromBattleCrew} from './removeSlotNumberFromBattleCrew'
import {addSlotNumberToBattleCrew} from './addSlotNumberToBattleCrew'
import { setPlayerDataValue } from "../redux-actions/player-data-actions";
import { connect } from "react-redux";
import { audioManager } from "../audio/audioManager";

class PauseCrewLineupSwapArrowMenu extends React.Component {

  optionHandler = (action={}) => {

    if (action.type === "REMOVE_FROM_CREW") {
      audioManager.playSfx("sfx_nextPage"); //SFX for doing the action
      setPlayerDataValue({
        acquiredMembers: removeSlotNumberFromBattleCrew(this.props.acquiredMembers, action.battleCrewSlot)
      })
    }
    if (action.type === "ADD_TO_CREW") {
      audioManager.playSfx("sfx_submitAction"); //SFX for doing the action
      setPlayerDataValue({
        acquiredMembers: addSlotNumberToBattleCrew(this.props.acquiredMembers, action.memberId)
      })
    }

    //back:
    if (action.type === "GO_BACK") {
      this.props.handleChangeMenuPage("home");
    }
  };

  render() {
    const { pixelSize, acquiredMembers } = this.props;
    const { map, menu } = getPauseLineupSwapMenu({
      acquiredMembers
    });

    const flatListOptionIds = map.reduce((a,b) => [...a, ...b])

    return (
      <ArrowMenu
        handler={this.optionHandler}
        backOptionId={"back"}
        optionsPageId={"swap"}
        optionsMap={map}
        optionModels={menu}
        render={({ handlers, selectedOptionId }) => {
          const selectedOptionModel = menu[selectedOptionId];
          return (
            <div style={{ width: pixelSize * 184 }}>
              <PauseHeader
                title={"Change Lineup"}
                handleBackClick={() => this.props.handleChangeMenuPage("home")}
              />
              <nav style={{ display: "flex", flexWrap: "wrap", marginBottom: pixelSize * 7 }}>
                {flatListOptionIds.map(memberId => {
                  const isSelected = selectedOptionId === memberId;
                  const model = menu[memberId];
                  return (
                    <MemberLineupOption
                      key={memberId}
                      optionId={memberId}
                      skinId={model.skinId}
                      rpgClass={model.class}
                      pixelSize={pixelSize}
                      isSelected={isSelected}
                      isDisabled={model.checkUnavailable()}
                      isInCrew={model.battleCrewSlot || null}
                      isComplete={menu[memberId].isComplete}
                      handlers={handlers}
                    />
                  );
                })}
              </nav>
              <div>
                <BoxDiv pixelSize={pixelSize}>
                  <BoxDivBody
                    grayTextboxTheme={true}
                    pixelSize={pixelSize}
                    style={{
                      paddingLeft: pixelSize * 6,
                      paddingRight: pixelSize * 6,
                      paddingTop: pixelSize * 4,
                      paddingBottom: pixelSize * 4
                    }}
                  >
                    <StaticTextLine
                      key={selectedOptionModel.description}
                      pixelSize={pixelSize}
                      text={selectedOptionModel.description}
                    />
                  </BoxDivBody>
                </BoxDiv>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

const MemberLineupOption = ({ optionId, skinId, pixelSize, handlers, isSelected, isDisabled, rpgClass, isInCrew }) => {
  return (
    <ArrowMenuOption id={optionId} handlers={handlers}>
    <div style={{ width: pixelSize * 40, margin: pixelSize * 3, opacity: isDisabled ? 0.8 : 1, transition: "opacity 0.2s" }}>
      <BoxDiv pixelSize={pixelSize}>
        <BoxDivBody
          pixelSize={pixelSize}
          style={{
            position: "relative",
            height: pixelSize * 40,
          }}
        >
          <div
            style={{
              position: "absolute",
              left: pixelSize * 4,
              top: pixelSize * 2
            }}
          >
            <MapPerson
              cellSize={pixelSize * 32}
              pixelSize={pixelSize}
              skinId={skinId}
            />
          </div>
          <div style={{
            position: "absolute",
            right: pixelSize * 2,
            bottom: pixelSize * 1
          }}>
            <Icon category={rpgClass} pixelSize={pixelSize}/>
          </div>
          {
            isInCrew && !isDisabled && (
              <div style={{
                position: "absolute",
                left: 0,
                top: 0
              }}>
                <Icon category={"selectedCheck"} pixelSize={pixelSize}/>
              </div>
            )
          }
        </BoxDivBody>
        {
          isSelected && (
            <div style={{
              position: "absolute",
              left: pixelSize,
              top: pixelSize,
              bottom: pixelSize,
              right: pixelSize,
              boxShadow: `0 0 0 ${pixelSize}px #07bfdd, 0 0 0 ${pixelSize * 2}px #7de2f2, 0 0 0 ${pixelSize * 3}px #07bfdd`
            }}>
            </div>
          )
        }
      </BoxDiv>
    </div>
    </ArrowMenuOption>
  );
};

export default connect((state, props) => {
  return {
    acquiredMembers: state.playerData.acquiredMembers,
  }
})(PauseCrewLineupSwapArrowMenu)
