import {DefaultAction, DefaultScript} from "../Action";

export default {
  ...DefaultAction,
  ...DefaultScript,

  name: "Escape",
  description: "Turn HP to 0 to end the battle",
  icon: "support",
  targetingType: "single-friendly",

  shouldFailOnSingleTarget(casterModel, targetModel) {
    return null;
  },

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {
    return [
      {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: casterModel.id,
            absoluteChanges: {
              hasEscaped: true,
              hp: 0,
            }
          }
        ],
      }
    ]
  }
}