import React from 'react'
import {getStyle} from "./letters";
import themes from './themes'

export default {
  "*": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 1h1M2 1h1M4 1h1M1 2h3" />
          <path stroke={themes[theme].main} d="M2 3h1M1 4h3M0 5h1M2 5h1M4 5h1" />
        </svg>
      )
    }
  },
  ":": {
    width: 1,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 1 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 1h1" />
          <path stroke={themes[theme].main} d="M0 5h1" />
        </svg>
      )
    }
  },
  ",": {
    width: 2,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 2 9"
             shapeRendering="crispEdges">
          <path stroke="#9c9da8" d="M1 6h1" />
          <path stroke={themes[theme].main} d="M1 7h1M0 8h1" />
        </svg>
      )
    }
  },
  "-": {
    width: 3,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 3 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].main} d="M0 3h3" />
        </svg>
      )
    }
  },
  "!": {
    width: 1,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 1 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h1M0 1h1M0 2h1" />
          <path stroke={themes[theme].main} d="M0 3h1M0 4h1M0 6h1" />
        </svg>
      )
    }
  },
  ".": {
    width: 1,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 1 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].main} d="M0 6h1" />
        </svg>
      )
    }
  },
  "?": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M1 0h3M0 1h1M4 1h1M4 2h1" />
          <path stroke={themes[theme].main} d="M3 3h1M2 4h1M2 6h1" />
        </svg>
      )
    }
  },
  "'": {
    width: 2,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 2 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h2M1 1h1" />
          <path stroke={themes[theme].main} d="M1 2h1" />
        </svg>
      )
    }
  },
  "@": {
    width: 7,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 7 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M2 0h4M1 1h1M0 2h1M3 2h2M6 2h1" />
          <path stroke={themes[theme].main} d="M6 1h1M0 3h1M2 3h1M4 3h1M6 3h1M0 4h1M3 4h3M1 5h1M2 6h5" />
        </svg>
      )
    }
  },
  "$": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M1 0h3M0 1h1M2 1h1M4 1h1M0 2h1M2 2h1" />
          <path stroke={themes[theme].main} d="M1 3h3M2 4h1M4 4h1M0 5h1M2 5h1M4 5h1M1 6h3M2 7h1" />
        </svg>
      )
    }
  },
  "%": {
    width: 7,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 7 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M1 0h1M0 1h1M2 1h1M5 1h1M1 2h1M4 2h1" />
          <path stroke={themes[theme].main} d="M3 3h1M2 4h1M5 4h1M1 5h1M4 5h1M6 5h1M5 6h1" />
        </svg>
      )
    }
  },
  "/": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M3 0h1M3 1h1M2 2h1" />
          <path stroke={themes[theme].main} d="M2 3h1M1 4h1M1 5h1M0 6h1M0 7h1" />
        </svg>
      )
    }
  },
  "(": {
    width: 3,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 3 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M2 0h1M1 1h1M0 2h1" />
          <path stroke={themes[theme].main} d="M0 3h1M0 4h1M0 5h1M1 6h1M2 7h1" />
        </svg>
      )
    }
  },
  ")": {
    width: 3,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 3 9"
             shapeRendering="crispEdges">
          <path stroke={themes[theme].upper} d="M0 0h1M1 1h1M2 2h1" />
          <path stroke={themes[theme].main} d="M2 3h1M2 4h1M2 5h1M1 6h1M0 7h1" />
        </svg>
      )
    }
  }
}
