import {DefaultAction, DefaultScript} from "../Action";

function createStatsAction(properties) {
  return {
    ...DefaultAction,
    ...DefaultScript,
    name: properties.name,
    description: properties.description,
    icon: properties.icon,
    targetingType: properties.targetingType,


    shouldFailOnSingleTarget() {
      return false
    },

    successEventsPerAllTargets({casterModel, targetModels, allUpgrades}) {
      return [
        {
          type: "eventBlippy",
          stateChanges: targetModels.map(targetModel => {
            return {
              combatantId: targetModel.id,
              relativeChanges: properties.relativeChanges,
              message: {
                theme: properties.messageTheme,
                text: properties.messageText
              },
            }
          })
        }
      ]
    }
  }
}

export const ScreenBlur = createStatsAction({
  name: "Screen Blur",
  description: "Dramatically reduce enemy team accuracy",
  icon: "disrupt",
  targetingType: "all-enemies",
  messageTheme: "battleNegativeHp",
  messageText: "-Accuracy",
  relativeChanges: {
    accuracyModifier: -10
  }
});
export const Firewall = createStatsAction({
  name: "Firewall",
  description: "Increase team defense for the rest of the battle",
  icon: "support",
  targetingType: "all-friendlies",
  messageTheme: "battlePositiveHp",
  messageText: "+Defense",
  relativeChanges: {
    defenseModifier: 5
  }
});
export const Scrum = createStatsAction({
  name: "Scrum",
  description: "Increase team attack for the rest of the battle",
  icon: "support",
  targetingType: "all-friendlies",
  messageTheme: "battlePositiveHp",
  messageText: "+Attack",
  relativeChanges: {
    attackModifier: 3
  }
});

export const TeamBuilding = createStatsAction({
  name: "Team Building",
  description: "Increase both team attack and defense for the rest of the battle",
  icon: "support",
  targetingType: "all-friendlies",
  messageTheme: "battlePositiveHp",
  messageText: "+Attack +Defense",
  relativeChanges: {
    attackModifier: 1,
    defenseModifier: 2,
  }
});

// export const Lockdown = createStatsAction({
//   name: "Firewall",
//   description: "Increase team attack",
//   targetingType: "all-friendlies",
//   accuracy: 96,
//   messageTheme: "battlePositiveHp",
//   messageText: "+Attack",
//   relativeChanges: {
//     attackModifier: 3
//   }
// });

export const PowerStalk = createStatsAction({
  name: "Tunnel",
  description: "Drastically reduce enemy team defense",
  icon: "disrupt",
  targetingType: "all-enemies",
  messageTheme: "battleNegativeHp",
  messageText: "--Extra Defense!",
  relativeChanges: {
    defenseModifier: -4
  }
});