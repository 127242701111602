//import {shuffle} from "../../../helpers/shuffle";
export default function({combatants=[], battleStoryPoints=[], regularDecision, subject}) {

//Just the Oliver recovers friend recovery part here.
  const oliver = combatants.find(c => c.isLeadEnemy);
  if (oliver && oliver.hp <= 0) {
    return {
      submission: [
        {
          actionId: "speak",
          casterId: subject.id, //"self"
          targetIds: [subject.id],
          mergeData: {
            dialogContent: [
              "Oliver! ", "@@pause_18@@", "Oliver!"
            ]
          }
        },
        {
          actionId: "script_restart",
          casterId: subject.id,
          targetIds: [oliver.id],
        },
      ],
      updatedBattleStoryPoints: battleStoryPoints
    };
  }


  //Default to regular
  return {
    submission: regularDecision,
    updatedBattleStoryPoints: battleStoryPoints
  }

}