//WATCH OUT: This is not the source of truth. Please copy over from DS2017/AllCombatants
//This is just for dev testing

export default [
  {
    id: "enemyA",
    //
    //hp: 0,
    name: "Thug",
    class: "support",
    level: 11,
    skinId: "thug2",
    attacks: [
      {actionId: "script_bug"},
      {actionId: "script_clean"},
      {actionId: "script_pop"},
      {actionId: "script_screen-blur"},
    ],
    items: [
    ],
    personality: ["mischief:2", "healStatus:2", "lowerStats:2"],
  },
  {
    id: "enemyB",
    //
    isLeadEnemy: true, //will make the ending messaging point at her (and other misc things)
    name: "Frankie",
    class: "lead",
    level: 12,
    //hp: 0,
    skinId: "hugo",
    playerVictoryMessage: "How could you?",
    playerDefeatMessage: "You see?",
    useScriptedDecisionFn: "frankie", //comes with scripted moves
    attacks: [
      {actionId: "script_push"},
    ],
    items: [
      {actionId: "item_battery-pack"},
      {actionId: "item_battery-pack"},
      {actionId: "item_battery-pack"},
      {actionId: "item_slippery-sticker"},
      {actionId: "item_revive-pack"},
    ],
    immunities: ["lag"],
    speedModifier: 4,
    defenseModifier: 5,
  },
  {
    id: "enemyC",
    //
    //hp: 0,
    name: "Thug",
    class: "support",
    level: 11,
    skinId: "thug3",
    attacks: [
      {actionId: "script_curl"},
      {actionId: "script_block"},
      {actionId: "script_pop"},
    ],
    items: [
    ],
    personality: ["mischief:2", "positiveStatus:2", "healHp:2"],
  }
].map(e => {
  return {
    ...e,
    belongsToTeam: "two"
  }
})