import React from 'react'
import {connect} from 'react-redux'
import BoxDivBody from "../interface/BoxDivBody";
import BoxDiv from "../interface/BoxDiv";
import PauseHomeArrowMenu from "./PauseHomeArrowMenu";
import PauseHomeJournalMenu from './PauseJournalArrowMenu'
import PauseItemsArrowMenu from './PauseItemsArrowMenu'
import PauseUpgradesArrowMenu from './PauseUpgradesArrowMenu'
import PauseMarketArrowMenu from './PauseMarketArrowMenu'
import PauseCrewArrowMenu from "./PauseCrewArrowMenu";
import LoadGameMenu from "../loading/LoadGameMenu";
import BoxDivTippyTop from '../interface/BoxDivTippyTop'
import PauseLoginStub from './PauseLoginStub'
import {setOverworldValue} from "../redux-actions/overworld-actions";
import PauseFeedback from './PauseFeedback'
import PauseCrewLineupSwapArrowMenu from './PauseCrewLineupSwapArrowMenu'
import PauseBackToTitleArrowMenu from './PauseBackToTitleArrowMenu'
import { audioManager } from "../audio/audioManager";

class PauseMenuContainer extends React.Component {

  state = {
    menuId: this.props.openPauseMenuAtPage || "home", //see menuId in render for options
    rememberedOptions: {}
  };

  componentDidMount() {
    setOverworldValue({
      openPauseMenuAtPage: null, //reset this so it doesn't get stale
      showGlobalHudUnreadBadge: false
    })
  }

  rememberActionId = id => {
    //Keep track of our last visited option per page (if action opts in to that)
    this.setState(state => ({
      rememberedOptions: {
        ...state.rememberedOptions,
        [state.menuId]: id
      }
    }))
  };

  handleChangeMenuPage = (newPage) => {
    audioManager.playSfx("sfx_nextPage"); //SFX for moving to new menu page
    this.setState({
      menuId: newPage
    })
  };



  render() {
    const {pixelSize} = this.props;
    const {menuId, rememberedOptions} = this.state;

    return (
      <div style={{
        position: "absolute",
        left: 0,
        right:0,
        top: 0,
        display: "flex",
        justifyContent: "center"
      }}>
        <BoxDiv pixelSize={pixelSize}>
          <BoxDivTippyTop pixelSize={pixelSize} />
          <BoxDivBody className="Dpad--NoShow" pixelSize={pixelSize} style={{padding: pixelSize * 6}}>
            {
              menuId === "home" && (
                <PauseHomeArrowMenu
                  initiallySelectedOptionId={rememberedOptions[menuId]}
                  rememberActionId={this.rememberActionId}
                  handleChangeMenuPage={this.handleChangeMenuPage}
                />
              )
            }
            {
              menuId === "journal" && (
                <PauseHomeJournalMenu
                  pixelSize={pixelSize}
                  handleChangeMenuPage={this.handleChangeMenuPage}
                />
              )
            }
            {
              menuId === "items" && (
                <PauseItemsArrowMenu
                  pixelSize={pixelSize}
                  handleChangeMenuPage={this.handleChangeMenuPage}
                />
              )
            }
            {
              menuId === "upgrades" && (
                <PauseUpgradesArrowMenu
                  pixelSize={pixelSize}
                  handleChangeMenuPage={this.handleChangeMenuPage}
                />
              )
            }
            {
              menuId === "market" && (
                <PauseMarketArrowMenu
                  pixelSize={pixelSize}
                  handleChangeMenuPage={this.handleChangeMenuPage}
                />
              )
            }
            {
              menuId === "crew" && (
                <PauseCrewArrowMenu
                  pixelSize={pixelSize}
                  handleChangeMenuPage={this.handleChangeMenuPage}
                />
              )
            }
            {
              menuId === "swap" && (
                <PauseCrewLineupSwapArrowMenu
                  pixelSize={pixelSize}
                  handleChangeMenuPage={this.handleChangeMenuPage}
                />
              )
            }
            {
              menuId === "loadgame" && (
                <LoadGameMenu
                  //signature here is a little different because it's shared with the Title screen
                  handleGoBack={() => {
                    this.handleChangeMenuPage("home")
                  }}
                />
              )
            }
            {
              menuId === "login" && (
                <PauseLoginStub
                  //signature here is a little different because it's shared with the Title screen
                  handleGoBack={() => {
                    this.handleChangeMenuPage("home")
                  }}
                />
              )
            }
            {
              menuId === "feedback" && (
                <PauseFeedback
                  handleGoBack={() => {
                    this.handleChangeMenuPage("home")
                  }}
                />
              )
            }
            {
              menuId === "backToTitle" && (
                <PauseBackToTitleArrowMenu
                  pixelSize={pixelSize}
                  handleChangeMenuPage={this.handleChangeMenuPage}
                />
              )
            }
          </BoxDivBody>
        </BoxDiv>
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    pixelSize: state.global.pixelSize,
    openPauseMenuAtPage: state.overworld.openPauseMenuAtPage
  }
})(PauseMenuContainer)