import React from 'react'
import './canvas.css'

class MapCanvas extends React.PureComponent {

  componentDidMount() {
    // get canvas context
    let ctx = document.getElementById('mapCanvas').getContext('2d');

    // load image
    let image = new Image();
    image.onload = function () {
      // draw the image into the canvas
      ctx.drawImage(image, 0, 0);
    };
    image.src = this.props.mapImage;
  }

  render() {
    const {
      mapPxWidth,
      mapPxHeight,
      mapWidthStyle,
      mapHeightStyle
    } = this.props;

    return (
      <canvas
        id="mapCanvas"
        width={mapPxWidth}
        height={mapPxHeight}
        style={{
          width: mapWidthStyle,
          height: mapHeightStyle,
        }}
      >

      </canvas>
    )

    // return (
    //   <div
    //     className="MapCanvas"
    //     style={{
    //       position: "absolute",
    //       left:0,
    //       top:0,
    //       opacity: 0.5,
    //       width: width,
    //       height: height,
    //       background: `url(/images/png/test-bg.png)`,
    //       //background: "orange",
    //       //backgroundSize: "100%",
    //       WebkitTransform: `translateZ(0)` //promotes this "layer" within Chrome
    //     }}
    //   />
    // );
  }
}

export default MapCanvas;