import React from 'react';

export default function BoxDivBody(props) {
  const {pixelSize, children, insetTheme, grayTextboxTheme, className} = props;

  if (typeof pixelSize !== "number") {
    console.warn('no pixelSize given to BoxDivBox')
  }

  const themeStyle = (() => {
    if (insetTheme) {
      return {
        background: "#f8f8f8",
        borderTop: `${pixelSize}px solid #e3e2e9`
      }
    }

    if (grayTextboxTheme) {
      return {
        background: "#f4f4f4",
        borderTop: `${pixelSize}px solid #cccccc`
      }
    }

    //default
    return {
      background: "#f8f8f8",
      borderBottom: `${pixelSize}px solid #d8d5ed`
    }
  })();



  const style = props.style || {};
  return (
    <div
      className={className || null}
      style={{
      ...themeStyle,

      ...style
    }}>
      {children}
    </div>
  )
}