import {areStoryPointsValid} from "../../story-points/story-point-validity-checks";

export function getEventFromTriggerSpaces(pos, acquiredStoryPoints = [], triggers = [], typeFilter=null) {

  const match = triggers.find(t => {

    //Only look for a certain type (just cutscenes, just battles, etc)
    if (typeFilter && t.type !== typeFilter) {
      return false;
    }

    if (t.interaction && t.interaction.length === 0) {
      return false;
    }

    const validity = areStoryPointsValid(acquiredStoryPoints, t.bypassOnStoryPoints, t.requiresStoryPoints);
    if (!validity) {
      return false
    }

    const triggerPos = `${t.x}x${t.y}`;
    return triggerPos === pos;
  });

  if (match) {
    return match.interaction
  }

  return null;
}