export function getGenericFail({targetModel, failInfo}) {

  let text = 'FAILED!';

  if (failInfo && failInfo.failCode === "immune") {
    text = "IMMUNE!"
  }
  if (failInfo && failInfo.failCode === "upgrade") {
    text = failInfo.failMessage
  }

  return [
    {
      type: "eventBlippy",
      stateChanges: [
        {
          combatantId: targetModel.id,
          message: {
            text: text
          },
        },
      ]
    }
  ]
}