import React from "react";
import {connect} from "react-redux";
import { setGlobalValue } from "../redux-actions/global-actions";
import { setAudioPreferenceInLocalstorage, setAudioVolumeInLocalstorage } from "../audio/audio-localstorage-operations";

class AudioVolumeButtons extends React.Component {

  componentDidMount() {
    this.setVolume(this.props.audioVolume)
  }

  setVolume = (percent=100) => {
    if (window.Howler) {
      const newRoundedValue = Math.round( percent ) / 100;

      //Howler gets set to a decimal... like 0.4 for a percent value of 40
      //console.log('setting Howler volume to', newRoundedValue)
      window.Howler.volume(newRoundedValue);
    }
  };

  componentDidUpdate(oldProps) {
    if (oldProps.audioVolume !== this.props.audioVolume || oldProps.audioMode !== this.props.audioMode) {
      this.setVolume(this.props.audioVolume);

      //Save to localstorage for next time
      setAudioVolumeInLocalstorage(this.props.audioVolume);
      setAudioPreferenceInLocalstorage(this.props.audioMode);
    }
  }

  handleAudioPlus = () => {
    const nextValue = this.props.audioVolume + 10;
    if (nextValue <= 100) {
      setGlobalValue({
        audioVolume: nextValue
      });
      if (this.props.audioMode === "off") {
        setGlobalValue({
          audioMode: "full",
        });
      }

    }
    //Don't allow browser to focus on this button
    this.refs.button.blur();
  };

  handleAudioMinus = () => {
    const nextValue = this.props.audioVolume - 10;
    if (nextValue > 0) {
      setGlobalValue({
        audioVolume: nextValue
      })
      if (this.props.audioMode === "off") {
        //Again, Proceed to full mode when trying to change volume of muted mode
        setGlobalValue({
          audioMode: "full",
        });
      }
    }

    //If at last position, should mute (no such thing as volume:0)
    if (nextValue === 0) {
      setGlobalValue({
        audioMode: "off"
      })
    }

    //Don't allow browser to focus on this button
    this.refs.button.blur();
  };

  render() {
    const {pixelSize} = this.props;
    //console.log(this.props.audioVolume)

    return (
      <div style={{
        position: "relative"
      }}>
        <a
          href="#audio-up"
          ref="button"
          tabIndex="-1"
          style={{
            display: "block",
            outline: 0,
            border: 0,
            WebkitUserSelect: "none"
          }}
          onClick={e => {
            e.preventDefault();
            this.handleAudioPlus()
          }}
        >
          {AudioUp(pixelSize)}
        </a>
        <a
          href="#audio-down"
          ref="button"
          tabIndex="-1"
          style={{
            display: "block",
            position: "absolute",
            left:0,
            top: pixelSize * 8,
            outline: 0,
            border: 0,
            WebkitUserSelect: "none"
          }}
          onClick={e => {
            e.preventDefault();
            this.handleAudioMinus()
          }}
        >
          {AudioDown(pixelSize)}
        </a>
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    audioMode: state.global.audioMode,
    audioVolume: state.global.audioVolume,
    pixelSize: state.global.pixelSize,
  };
})(AudioVolumeButtons);

const AudioUp = pixelSize => {
  return (
    <svg style={{width: pixelSize * 10}} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 10 8" shapeRendering="crispEdges">
      <path className="Dpad--NoShow" stroke="#211f33" d="M0 0h9M9 1h1M9 2h1M9 3h1M9 4h1M9 5h1M9 6h1M9 7h1" />
      <path className="Dpad--NoShow" stroke="#ffffff" d="M0 1h9M0 2h9M0 3h4M5 3h4M0 4h3M6 4h3M0 5h4M5 5h4M0 6h9" />
      <path className="Dpad--NoShow" stroke="#5f5564" d="M4 3h1M3 4h3M4 5h1" />
      <path className="Dpad--NoShow" stroke="#dde6f4" d="M0 7h9" />
    </svg>
  )
};
const AudioDown = pixelSize => {
  return (
    <svg style={{width: pixelSize * 10}} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 10 8" shapeRendering="crispEdges">
      <path className="Dpad--NoShow" stroke="#ffffff" d="M0 0h9M0 1h9M0 2h9M0 3h3M6 3h3M0 4h9M0 5h9" />
      <path className="Dpad--NoShow" stroke="#211f33" d="M9 0h1M9 1h1M9 2h1M9 3h1M9 4h1M9 5h1M9 6h1M0 7h9" />
      <path className="Dpad--NoShow" stroke="#5f5564" d="M3 3h3" />
      <path className="Dpad--NoShow" stroke="#dde6f4" d="M0 6h9" />
    </svg>
  )
};
