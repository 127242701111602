/* Howler configs without the Howl instance */
export default {
  music_regularBattle: {
    src: [
      "/music/maryland-battle-trial.mp3"
    ],

    sprite: {
      trimmed: [0, 44037, true] //44.042450 sec
    },

    volume: 0.4,
    loop: true
  },
  music_hackersBattle: {
    src: [
      "/music/hackers.export2.mp3"
    ],
    sprite: {
      trimmed: [0, 64047, true] //64.052250 sec
    },
    volume: 0.6,
    loop: true
  },
  music_kingHackerBattle: {
    src: [
      "/music/hackerking.export3.mp3"
    ],
    sprite: {
      trimmed: [0, 78000, true]
    },
    volume: 0.45,
    loop: true
  },
  music_middleBoss: {
    src: [
      "/music/middleboss.demo.mp3"
    ],
    sprite: {
      trimmed: [0, 71466, true] //71.471000 sec
    },
    volume: 0.6,
    loop: true
  },
  music_default: {
    src: [
      "/music/404/basement.mp3"
    ],
    volume: 0.4,
    loop: true
  },

  music_macroSpeechBody: {
    src: [
      "/music/speech-body.export2.mp3"
    ],
    volume: 0.5,
    loop: true
  },
  music_macroSpeechChaos: {
    src: [
      "/music/speech-chaos.export2.mp3"
    ],
    volume: 0.5,
    loop: true
  },
  music_macroSpeechEnd: {
    src: [
      "/music/speech-exile.export2.mp3"
    ],
    volume: 0.5,
    loop: false
  },

  music_sneaky: {
    src: [
      "/music/chapter2/29-sneaky-beat.mp3"
    ],
    volume: 0.5,
    loop: true
  },

  music_victory: {
    src: [
      "/music/victory.mp3"
    ],
    volume: 0.4,
    loop: false
  },
  music_defeat: {
    src: [
      "/music/defeat.mp3"
    ],
    volume: 0.4,
    loop: false
  },

  //MAPS:
  music_map_onboarding: {
    src: [
      "/music/onboarding.mp3"
    ],
    sprite: {
      trimmed: [0, 40092.959, true] //| 40.097959 sec
    },
    volume: 0.4,
    loop: true
  },
  music_map_marylandAve: {
    src: [
      "/music/maryland-avenue.mp3"
    ],
    sprite: {
      trimmed: [0, 68226, true] //68.231850 sec
    },
    volume: 0.5,
    loop: true
  },
  music_map_coffeeshop: {
    src: [
      "/music/henry-coffeeshop.mp3"
    ],
    sprite: {
      trimmed: [0, 48034.125, true] //48.039125 sec - compare this to old repo?
    },
    volume: 0.4,
    loop: true
  },
  music_map_fsbBasement: {
    src: [
      "/music/henry-basement.mp3"
    ],
    sprite: {
      trimmed: [0, 60729, true] //60.734694 sec
    },
    volume: 0.5,
    loop: true
  },

  music_map_office: {
    src: [
      "/music/office.mp3"
    ],
    sprite: {
      trimmed: [0, 30532.15, true] //| 30.537150 sec
    },
    volume: 0.5,
    loop: true
  },
  music_bear_challenge: {
    src: [
      "/music/face-the-bear.mp3"
    ],
    sprite: {
      trimmed: [0, 46336.2, true] //46.341200 sec
    },
    volume: 0.9,
    loop: true
  },
  music_shawpark: {
    src: [
      "/music/shawpark.demo.mp3"
    ],
    sprite: {
      trimmed: [0, 78963.15, true] //| 78.968150 sec
    },
    volume: 0.35,
    loop: true
  },
  music_lightpark: {
    src: [
      "/music/lightpark.demo.mp3"
    ],
    sprite: {
      trimmed: [0, 64047, true] //64.052250 sec
    },
    volume: 0.35,
    loop: true
  },
  music_darkpark: {
    src: [
      "/music/dark.demo.b.mp3"
    ],
    sprite: {
      trimmed: [0, 40902.75, true] //40.907750 sec
    },
    volume: 0.5,
    loop: true
  },
  music_statueReborn: {
    src: [
      "/music/statuereborn.demo.mp3"
    ],
    sprite: {
      trimmed: [0, 31028.45, true] //| 31.033450 sec
    },
    volume: 0.2,
    loop: true
  },

  music_east: {
    src: [
      "/music/east.export2.mp3"
    ],
    sprite: {
      trimmed: [0, 58927.25, true] //58.932250 sec
    },
    volume: 0.5,
    loop: true
  },

  music_badHabits: {
    src: [
      "/music/badhabits.export2.mp3"
    ],
    volume: 0.5,
    loop: true
  },

  //watch out, this is Hackathon now too?
  music_conf: {
    src: [
      "/music/douches.demo.mp3"
    ],
    sprite: {
      trimmed: [0, 28860, true] //28.865300 sec
    },
    volume: 0.5,
    loop: true
  },

  music_macroUhoh: {
    src: [
      "/music/uhoh.demo.mp3"
    ],
    volume: 0.5,
    loop: false
  },

  music_zombieEscape: {
    src: [
      "/music/zombieescape.demo.mp3"
    ],
    sprite: {
      trimmed: [0, 47799.1, true] //| 47.804100 sec
    },
    volume: 0.6,
    loop: true
  },

  music_dangercon: {
    src: [
      "/music/dangercon.demo.mp3"
    ],
    sprite: {
      trimmed: [0, 82550.05, true] //82.573050 sec
    },
    volume: 0.5,
    loop: true
  },
  music_theater: {
    src: [
      "/music/theater.demo.mp3"
    ],
    sprite: {
      trimmed: [0, 30035.95, true] //| 30.066950 sec
    },
    volume: 0.15,
    loop: true
  },

  music_highway: {
    src: [
      "/music/highway.demo.mp3"
    ],
    sprite: {
      trimmed: [0, 73033.35, true] //| 73.038350 sec
    },
    volume: 0.5,
    loop: true
  },

  music_macroOffice: {
    src: [
      "/music/macrooffice.demo.mp3"
    ],
    sprite: {
      trimmed: [0, 72066, true] //72.071850 sec
    },
    volume: 0.5,
    loop: true
  },

  music_boss1: {
    src: [
      "/music/sweet-frankie.export2.mp3"
    ],
    sprite: {
      trimmed: [0, 67756.65, true] //| 67.761650 sec
    },
    volume: 0.5,
    loop: true
  },

  music_richard1: {
    src: [
      "/music/serverdestroy.demo.mp3"
    ],
    sprite: {
      trimmed: [0, 58248.05, true] //| 58.253050 sec
    },
    volume: 0.5,
    loop: true
  },

  music_richard2: {
    src: [
      "/music/wicked.demo.mp3"
    ],
    sprite: {
      trimmed: [0, 65510.1, true] //| 65.515100 sec
    },
    volume: 0.5,
    loop: true
  },

  music_badassAppears: {
    src: [
      "/music/badassappears.demo.mp3"
    ],
    sprite: {
      trimmed: [0, 28964.8, true] //28.969800 sec
    },
    volume: 0.45,
    loop: true
  },


  music_ending: {
    src: [
      //"/music/goodnightclayton.demo.mp3"
      "/music/goodnightclayton.export2.mp3"
    ],
    volume: 0.5,
    loop: false
  },

};