import {Person} from "./Person";
import {externalEventTypes} from './externalEventTypes'

export function getNextCutsceneFrame(state, remainingEvents) {

  const event = remainingEvents[0] || {};

  let nextStepId = state.stepId;
  let isInternalEventFinished = false;

  //console.log(event)
  if (externalEventTypes.find(type => type === event.type)) {
    return {
      nextStepId,
      isWaitingOnExternalEvent: true
    }
  } else {

    if (!["characterWalk", "characterJump", "characterStand"].includes(event.type)) {
      console.log('not known as external event type:', event.type, remainingEvents)
    }

  }

  let newInteractives = state.interactives.map(model => {

    if (model.id !== event.subjectId) { return model } //return model as is

    const cp = new Person({
      ...model,
      isDoingInternalEvent: true,
      isInternalEventFinished: false,
      doThisBehavior: event
    }); //This person instance is created without passing in walls and other people, so he will naturally not stop for walls
    cp.betterStep();


    //if (cp.hasVisualThingChanged) {
      nextStepId++;
    //}

    if (cp.params.isInternalEventFinished) {

      //Maybe shut off the person who was just walking
      const nextEvent = remainingEvents[1];
      if (nextEvent) {
        if (event.type === "characterWalk") {
          if (nextEvent.subjectId !== event.subjectId) {
            cp.params.currentActionType = "characterStand"
          }
        }
      }

      //Mark this as complete so parent will eat it.
      isInternalEventFinished = true;
    }

    return cp.params;
  });

  return {
    nextStepId,
    nextState: {
      interactives: newInteractives
    },
    isInternalEventFinished
  }
}
