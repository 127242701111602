import React from 'react'
import StaticTextLine from "./StaticTextLine";
import BoxDiv from './interface/BoxDiv'
import BoxDivBody from './interface/BoxDivBody'
import BoxDivTippyTop from "./interface/BoxDivTippyTop";
import {waitFrames} from "./helpers/waitFrames";

class CreatePersonNameForm extends React.Component {

  state = {
    name: this.props.defaultValue,
    error: null,
    mountLocked: true
  };

  componentDidMount() {
    //Throttle so the ENTER KEY doesn't automatically try to submit the form
    waitFrames(12, () => {
      this.setState({
        mountLocked: false
      })
    })
  }

  handleSubmit = () => {

    if (this.state.mountLocked) { return }

    //Check for errors
    const {name} = this.state;
    let error = null;


    if (name.length === 0) {
      error = " "
    }

    if (name.length === 1) {
      error = "Please enter at least 2 characters"
    }
    if (name.length > 9) {
      error = "This name is beautiful but too long. How about a nickname?"
    }

    if (error) {
      this.setState({
        error
      })
    } else {
      this.setState({
        error: null
      }, () => {
        //If no error, we're good!
        this.props.handleSubmission(name)
      })
    }

  };

  render() {
    const {pixelSize, isHero} = this.props;

    return (
      <div style={{width: pixelSize * 130}}>
      <BoxDiv pixelSize={pixelSize}>
        <BoxDivTippyTop
          pixelSize={pixelSize}
        />
        <BoxDivBody pixelSize={pixelSize} style={{
          padding: 4 * pixelSize,
          paddingLeft: pixelSize * 11,
          paddingRight: pixelSize * 11,
          paddingBottom: pixelSize * 8,
        }}>
          <div style={{display: "flex", justifyContent: "center"}}>
            <StaticTextLine
              pixelSize={pixelSize}
              text={isHero ? "Enter your Name" : "Enter Friend's Name"}
            />
          </div>
          <form onSubmit={e => {e.preventDefault(); this.handleSubmit()}}>
            <div style={{
              border: `${pixelSize}px solid #39393B`,
              position: "relative",
              marginBottom: pixelSize * 4
            }}>
              <input
                type="text"
                autoFocus
                style={{
                  height: pixelSize * 16,
                  outline:`${pixelSize}px solid`,
                  fontSize: 18,
                  fontFamily: "inherit",
                  textAlign: "center",
                  display: "block",
                  width: "100%",
                }}
                value={this.state.name}
                maxLength={9}
                onChange={e => this.setState({name: e.target.value})}
              />
              {/*<div style={{*/}
                {/*background: "#f4f4f4",*/}
                {/*position: "absolute",*/}
                {/*left:0,*/}
                {/*top:0,*/}
                {/*right:0,*/}
                {/*bottom: 0,*/}
                {/*borderTop: `${pixelSize}px solid #c8c8c8`,*/}
                {/*pointerEvents: "none",*/}
                {/*display: "flex",*/}
                {/*justifyContent: "center",*/}
                {/*opacity: "0.3"*/}
              {/*}}>*/}
                {/*<StaticTextLine*/}
                  {/*key={this.state.name}*/}
                  {/*pixelSize={pixelSize}*/}
                  {/*text={this.state.name}*/}
                {/*/>*/}
              {/*</div>*/}
            </div>
            {
              Boolean(this.state.error) && (
                <StaticTextLine
                  pixelSize={pixelSize}
                  text={this.state.error}
                  theme="red"
                />
              )
            }

            <div style={{position: "relative"}}>
              <input type="submit" style={{
                position: "absolute",
                display: "block",
                left:0, top:0, bottom:0, right: 0,
                width: "100%",
                cursor: "pointer",
                background:"red"
              }}/>
              <BoxDiv pixelSize={pixelSize} style={{pointerEvents:"none"}}>
                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  background: "#729efc",
                  paddingTop: pixelSize,
                  borderBottom: `${pixelSize}px solid #4372e9`,
                }}>
                  <StaticTextLine
                    pixelSize={pixelSize}
                    text={"Submit"}
                    theme="white"
                  />
                </div>
              </BoxDiv>
            </div>
          </form>
        </BoxDivBody>
      </BoxDiv>
      </div>
    );
  }
}

export default CreatePersonNameForm;