import {DefaultAction} from "../Action";
import {getAttackDamage} from "../../formulas/getAttackDamage";


export default {
  ...DefaultAction,

  getUse() {
    return []
  },

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {

    const damage = getAttackDamage(8, casterModel, targetModel, allUpgrades);

    return [
      {
        type: "eventAnimation",
        animationId: "animationZap",
        subjects: [targetModel.id],
        delayFramesAfter: 12,
      },
      //Block step
      targetModel.status === "block" ? {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: targetModel.id,
            absoluteChanges: {
              status: "normal"
            },
            message: {
              theme: "battleDamageBlock",
              text: `Damage Absorbed!`
            },
          }
        ],
      } : null,

      //Damage step
      {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: targetModel.id,
            relativeChanges: {
              hp: damage
            },
            message: {
              theme: "battleNegativeHp",
              text: `${damage} HP`
            },
          }
        ],
        delayFramesAfter: 60
      },
    ].filter(d => d)
  }
}
