import Upgrades from "./battle-data/Upgrades";
import {removeEventFromSchedule} from "./schedule";
import BattleActions from "./battle-data/battle-actions-data";
import {shuffle} from "../helpers/shuffle";

export function getNextTurn({currentRoundArray, previousTurn, combatants, allUpgrades, lastSubmissionSummary, allActions, schedule, roundNumber}) {
  

  let upgrades = allUpgrades || Upgrades;
  let actions = allActions || BattleActions;
  const nextPersonId = currentRoundArray[0];

  const previousCombatantId = previousTurn && previousTurn.combatantId;
  const previousTags = previousTurn ? (previousTurn.tags || []) : [];


  //Maybe assign furyRecoil (might need it in a few places)
  let furyRecoil = null;
  if (lastSubmissionSummary && lastSubmissionSummary.targetIds) {
    const targets = lastSubmissionSummary.targetIds.map(id => combatants.find(c => c.id === id));
    //Fury recoils
    const targetModelsWithFury = targets.filter(t => t.status === "fury");

    //Validate naturals to make sure they make sense with fury
    let lastSubmissionWasNatural = lastSubmissionSummary.actionId.startsWith("natural_");
    if (lastSubmissionSummary.actionId.match(/natural_nervous-mistake/)) {
      lastSubmissionWasNatural = false; //allow nervous mistakes to trigger fury
    }

    const wasArenaThatAttackedMe = previousCombatantId && Boolean(previousCombatantId.match(/arena/));
    if (
      !previousTags.includes("furyRecoilTurn") &&
      !wasArenaThatAttackedMe &&
      lastSubmissionSummary.actionId !== "script_troll" &&
      lastSubmissionSummary.actionId !== "noop" &&
      targetModelsWithFury.length &&
      !lastSubmissionWasNatural
    ) {
      furyRecoil = {
        type: "automaticAction",
        //combatantId: previousCombatantId,
        combatantId: targetModelsWithFury[0].id,
        actionId: "natural_recoil-by-fury",
        targetIds: [previousCombatantId],
        tags: ["furyRecoilTurn"],
        mergeData: {
          previousTargetsWithFury: targetModelsWithFury.map(c => c.id),
          amountOfRecoils: targetModelsWithFury.length
        }
      };
    }
  }


  //PHASE: Check for Bonus Turns
  //Check previous summary for things that happened during last turn
  if (lastSubmissionSummary) {

    const upgradeModelUsed = upgrades[lastSubmissionSummary.casterUpgradeId || "none"];
    if (upgradeModelUsed) {

      //CASE: used an item
      if (upgradeModelUsed.provideBonusTurnAfterUsingAnyItem && !previousTags.includes("bonusTurnAfterUsingAnyItem") && !previousTags.includes("bonusTurn")) {
        const actionUsed = actions[lastSubmissionSummary.actionId || "none"];

        const subject = combatants.find(c => c.id === previousCombatantId);
        const isCombatantAlive = subject && subject.hp > 0;

        if (actionUsed && actionUsed.isItem && isCombatantAlive) {
          return {
            updatedRoundArray: currentRoundArray,
            nextTurn: [
              furyRecoil,
              {
                type: "automaticAction",
                combatantId: previousCombatantId,
                actionId: "speak",
                targetIds: [previousCombatantId],
                tags: [],
                mergeData: {
                  subject: previousCombatantId,
                  dialogContent: ["Bonus Item Ninja turn!"]
                }
              },
              {
                type: "freeWill",
                tags: ["bonusTurnAfterUsingAnyItem", "bonusTurn"],
                combatantId: previousCombatantId,
              }
            ].filter(d => d)
          }
        }
      }

      //CASE: used a certain action
      if (upgradeModelUsed.provideBonusTurnAfterUsingActionWithCategory) {
        const actionUsed = actions[lastSubmissionSummary.actionId || "none"];
        //console.log(actionUsed, previousTurn)
        if (actionUsed) {
          const categories = actionUsed.categories || [];

          //Case: stole/bugged/affected an enemy item bonus turn (or used copypaste)
          const isMischiefValid = categories.includes("itemStealingTurn") || previousTurn.isAutoUseItemMove; //whatever item previousTurn you copy/pasted will have this flag
          if (isMischiefValid && !previousTags.includes("bonusTurnAfterUsingItemStealingAction") && !previousTags.includes("bonusTurn")) {
            if (upgradeModelUsed.provideBonusTurnAfterUsingActionWithCategory.includes("itemStealingTurn")) {
              return {
                updatedRoundArray: currentRoundArray,
                nextTurn: [
                  furyRecoil, //include the fury turn if it is relevant
                  {
                    type: "automaticAction",
                    combatantId: previousCombatantId,
                    actionId: "speak",
                    targetIds: [previousCombatantId],
                    tags: [],
                    mergeData: {
                      subject: previousCombatantId,
                      dialogContent: ["Mischief turn!"]
                    }
                  },
                  {
                    type: "freeWill",
                    tags: ["bonusTurnAfterUsingItemStealingAction", "bonusTurn"],
                    combatantId: previousCombatantId,
                  }
                ].filter(d => d)
              }
            }
          }
        }
      }
    }

    const actionUsed = actions[lastSubmissionSummary.actionId || "none"];

    //Copy/Paste -> automatically use the item you stole
    if (actionUsed && actionUsed.categories && actionUsed.categories.includes("autoUseStolenItem") && lastSubmissionSummary.stolenActionIds) {
      //console.log('auto use item', previousTags)

      const copyPasteItemActionId = lastSubmissionSummary.stolenActionIds[0];
      const itemModel = actions[copyPasteItemActionId];

      const caster = combatants.find(c => c.id === previousCombatantId);
      let targetIds = itemModel.targetingType === "all-friendlies"
        ? combatants.filter(c => c.belongsToTeam === caster.belongsToTeam && c.hp > 0).map(c => c.id) //This item is to be used on my whole team (EX: Team Danger Boost, Team Battery Pack )
        : [previousCombatantId]; //Default to use item on myself

      return {
        updatedRoundArray: currentRoundArray,
        nextTurn: [
          furyRecoil, //maybe include this if relevant
          {
            type: "automaticAction",
            isAutoUseItemMove: true,
            combatantId: previousCombatantId,
            actionId: copyPasteItemActionId, //use the item's action id
            instanceId: lastSubmissionSummary.stolenInstanceIds[0], //and the instance id
            targetIds: targetIds,
            tags: previousTags || [],
            mergeData: {
            }
          }
        ].filter(d => d)
      }
    }
    //Rage Quit -> automatically submit the followup natural_auto-kill that gets the original target
    if (actionUsed && actionUsed.categories && actionUsed.categories.includes("rageQuit")) {

      //Some people (like Richard2) can thwart rageQuit
      const targets = (lastSubmissionSummary.targetIds || []).map(tId => combatants.find(c => c.id === tId));
      if (targets.length && targets.find(t => t.isImmuneToRageQuit)) {
        return {
          updatedRoundArray: currentRoundArray,
          nextTurn: {
            type: "automaticAction",
            combatantId: lastSubmissionSummary.targetIds[0],
            actionId: "speak",
            targetIds: lastSubmissionSummary.targetIds,
            tags: [],
            mergeData: {
              dialogContent: [
                "You think your tricks will work on me?"
              ]
            }
          }
        }
      }

      return {
        updatedRoundArray: currentRoundArray,
        nextTurn: {
          type: "automaticAction",
          combatantId: previousCombatantId,
          actionId: "natural_auto-kill",
          targetIds: lastSubmissionSummary.targetIds,
          tags: [],
          mergeData: {}
        }
      }
    }


  } //ends checking for bonus turns

  //Check previous combatant for stuff:
  if (previousCombatantId) {
    const previousCombatantModel = combatants.find(c => c.id === previousCombatantId);
    //Case: Bonus turn on deadline status.
    if (!previousTags.includes("deadlineTurn") && !previousTags.includes("bonusTurn") && previousCombatantModel.status === "deadline") {

      const wasDeadline = lastSubmissionSummary && (lastSubmissionSummary.actionId === "script_deadline" || lastSubmissionSummary.actionId === "super_team-deadline");

      const justUsedDeadlineOnSelf = function () {
        return wasDeadline && previousTurn.combatantId === previousCombatantId && lastSubmissionSummary.targetIds.includes(previousTurn.combatantId);
      }();

      //Don't trigger the deadline turn if last thing you did was natural (like a recovery) - that doesn't count as your first of two turns with a Deadline status!
      const lastSubmissionWasNatural = lastSubmissionSummary && lastSubmissionSummary.actionId.startsWith("natural_");

      if (!justUsedDeadlineOnSelf && !lastSubmissionWasNatural) {
        //console.log()
        return {
          updatedRoundArray: currentRoundArray,
          nextTurn: [
            {
              type: "automaticAction",
              combatantId: previousCombatantId,
              actionId: "speak",
              targetIds: [previousCombatantId],
              tags: [],
              mergeData: {
                subject: previousCombatantId,
                dialogContent: ["Deadline turn!"]
              }
            },
            {
              type: "freeWill",
              combatantId: previousCombatantId,
              tags: ["deadlineTurn", "bonusTurn"],
            }
          ]
        }
      }
    }

    //Create flag for knowing if this is the promise resolving
    const wasPromiseResolve = previousTurn.actionId === "natural_promise-resolve" || previousTurn.actionId === "natural_promise-all-resolve";


    const lastSubmissionWasNatural = lastSubmissionSummary && lastSubmissionSummary.actionId.startsWith("natural_");
    //console.log(lastSubmissionWasNatural, lastSubmissionSummary)

    if (!previousTags.includes("memoryLeakTurn") && previousCombatantModel.status === "memory-leak" && !wasPromiseResolve && !lastSubmissionWasNatural) {
      return {
        updatedRoundArray: currentRoundArray,
        nextTurn: [
          furyRecoil, //include this if relevant
          {
            type: "automaticAction",
            combatantId: previousCombatantId,
            actionId: "natural_hurt-by-leak",
            targetIds: [previousCombatantId],
            tags: ["memoryLeakTurn"],
          }
        ].filter(d => d)
      }
    }

    if (!previousTags.includes("fireLeakTurn") && previousCombatantModel.status === "fire" && !wasPromiseResolve && !lastSubmissionWasNatural) {
      return {
        updatedRoundArray: currentRoundArray,
        nextTurn: [
          furyRecoil, //include this if relevant
          {
            type: "automaticAction",
            combatantId: previousCombatantId,
            actionId: "natural_hurt-by-fire",
            targetIds: [previousCombatantId],
            tags: ["fireLeakTurn"],
          }
        ].filter(d => d)
      }
    }

    if (!previousTags.includes("focusRecoverTurn") && previousCombatantModel.status === "focus") {

      //This may seem gross, but we're simply checking that you don't get the HP boost right after you use headphones.
      //Note: sorta hardcoding the action ID right now, but we could refactor if we make the summary analysis a little better
      //Right now the first version of the summary object is kinda useless and I us changing away from it. It really only provides the actionId, casterId, and targetIds

      const justUsedHeadphones = function () {
        return lastSubmissionSummary && lastSubmissionSummary.actionId === "script_headphones" && previousTurn.combatantId === previousCombatantId;
      }();
      const lastSubmissionWasNatural = lastSubmissionSummary && lastSubmissionSummary.actionId.startsWith("natural_");

      if (!justUsedHeadphones && !lastSubmissionWasNatural) {
        return {
          updatedRoundArray: currentRoundArray,
          nextTurn: [
            furyRecoil, //include this if relevant
            {
              type: "automaticAction",
              combatantId: previousCombatantId,
              actionId: "natural_recover-by-focus",
              targetIds: [previousCombatantId],
              tags: ["focusRecoverTurn"],
            },
          ].filter(d => d)
        }
      }
    }

    //Just the fury turn (no additional other steps)
    //We determined that we needed it earlier, so use it by itself now
    if (furyRecoil) {
      return {
        updatedRoundArray: currentRoundArray,
        nextTurn: furyRecoil
      }
    }
  }


  let updatedRoundArray = currentRoundArray.filter((d, i) => i > 0);
  const nextPersonModel = combatants.find(c => c.id === nextPersonId);

  //Auto HACK if next person is hacked
  if (nextPersonModel.status === "hacked") {

    //Hacked should attack a friend first but default to you if not
    const nextPersonOtherTeammates = combatants.filter(c => {
      return (
        c.id !== nextPersonModel.id &&
        c.belongsToTeam === nextPersonModel.belongsToTeam &&
        c.hp > 0)
    });
    const hackedTargetId = function() {
      if (nextPersonOtherTeammates.length) {
        return shuffle(nextPersonOtherTeammates.map(c => c.id))[0]
      }
      //default to the hacked person if nobody is left
      return nextPersonId;
    }();

    return {
      updatedRoundArray,
      nextTurn: {
        type: "automaticAction",
        combatantId: nextPersonId,
        actionId: "natural_hacked-attack",
        targetIds: [hackedTargetId] //[nextPersonId]
      }
    }
  }

  //PHASE: Check schedule for automatic things to happen right now
  if (schedule && schedule[String(roundNumber)]) {

    const scheduledTurnEvents = schedule[String(roundNumber)];

    const index = scheduledTurnEvents.findIndex(event => event.combatantId === nextPersonId);
    if (index > -1) {

      return {
        updatedRoundArray: currentRoundArray,
        nextTurn: {
          type: "automaticAction",
          ...scheduledTurnEvents[index]
        },
        updatedSchedule: removeEventFromSchedule(schedule, roundNumber, index)
      }
    }
  }

  //Auto noop dead person (or disabled)
  const isDead = typeof nextPersonModel.hp === "number" && nextPersonModel.hp <= 0;
  if (isDead || nextPersonModel.status === "disabled") {
    return {
      updatedRoundArray,
      nextTurn: {
        type: "automaticAction",
        combatantId: nextPersonId,
        actionId: "noop",
        targetIds: []
      }
    }
  }

  return {
    updatedRoundArray,
    nextTurn: {
      type: "freeWill",
      combatantId: nextPersonId,
      tags: []
    }
  }
}