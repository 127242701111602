import React from "react";
import { connect } from "react-redux";
import StaticTextLine from "../StaticTextLine";
import Icon from "../interface/Icon";

class PauseHeader extends React.Component {
  renderRightSide() {
    const { pixelSize, credits } = this.props;
    if (this.props.rightPreset === "credits") {
      return (
        <div style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          right: pixelSize * -2
        }}>
          <div
            style={{
              marginRight: pixelSize * 2,
              position: "relative",
              top: pixelSize * -1
            }}
          >
            <Icon category="credit" pixelSize={pixelSize} />
          </div>
          <StaticTextLine
            key={credits}
            pixelSize={pixelSize}
            text={String(credits)}
          />
        </div>
      );
    }

    return null;
  }

  render() {
    const { pixelSize, title } = this.props;
    return (
      <header
        className="Dpad--NoShow"
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "space-between", //marginBottom: pixelSize * 4,
          height: pixelSize * 20
        }}
      >
        <div>
          <a
            href={"#back"}
            onClick={e => {
              e.preventDefault();
              this.props.handleBackClick();
            }}
          >
            <Icon category="pauseBack" pixelSize={pixelSize} />
          </a>
        </div>
        <div>{this.renderRightSide()}</div>

        <div
          className="Dpad--NoShow"
          style={{
            position: "absolute",
            left: "50%",
            top: pixelSize * -1,
            height: "100%",
            //background: "red",
            transform: "translateX(-50%)"
          }}
        >
          <StaticTextLine key={title} pixelSize={pixelSize} text={title} />
        </div>
      </header>
    );
  }
}

export default connect((state, props) => {
  return {
    pixelSize: state.global.pixelSize,
    credits: state.playerData.currency.credits
  };
})(PauseHeader);
