import React from 'react';
import letters from "./letters/letters";
import './letters.css'

export default function (props) {

  const {pixelSize, word, firstLetterIndex, theme, useReveal, lettersRevealed} = props;

  const characters = word.split("");

  return (
    <span className="Word" style={{
      marginRight: pixelSize * 4,
      marginBottom: pixelSize * 2,
      marginTop: pixelSize * 4,
      display: "inline-flex",
      alignItems: "flex-start",
    }}>
        {
          characters.map((letter, i) => {
            if (letter === " ") {
              return <span key={i} style={{paddingRight: pixelSize * 4}}/>
            }

            //backtick is a special character for DELAY. Don't show anything.
            if (letter === "`") {
              return <span key={i} style={{display: "none"}}/>
            }

            const letterIndex = firstLetterIndex + i;
            let isRevealed = true;
            if (useReveal && lettersRevealed < letterIndex) {
              isRevealed = false;
            }

            return (
              <Character
                key={i}
                letter={letter}
                theme={theme}
                pixelSize={pixelSize}
                isRevealed={isRevealed}
              />
            )
          })
        }
    </span>
  )
}


class Character extends React.Component {
  //Only update if a visibility thing changes
  shouldComponentUpdate(newProps) {
    return (
      newProps.isRevealed !== this.props.isRevealed ||
      newProps.theme !== this.props.theme ||
      newProps.pixelSize !== this.props.pixelSize
    )
  }

  render() {
    const {pixelSize, letter, theme, isRevealed} = this.props;

    if (!letters[letter]) {
      return null;
    }


    return (
      <span
        className={isRevealed ? "Letter Letter--revealed" : "Letter"}
        //style={{ opacity: isRevealed ? 1 : 0 }}
      >
      {letters[letter].render({pixelSize, theme})}
    </span>
    )
  }
}