import React from 'react'
import Overworld from './Overworld'
import {connect} from 'react-redux'
import KeyboardArrowControllerContainer from "./dpad/KeyboardArrowControllerContainer";
import {getInitialMapState} from "./getInitialMapState";
import store from '../init/store'
import {setOverworldValue} from "../redux-actions/overworld-actions";
import {chapter} from "../ChapterGetter";

class OverworldContainer extends React.Component {

  state = {
    mapTransition: null,
    isOverworldMounted: false,
    globalCutsceneEvents: [

      // {
      //   "type": "changeCrewMemberProperty",
      //   "changes": [
      //     {
      //       "id": "staticSecurityJack",
      //       "isStatic": true,
      //       "isAvailable": true,
      //       "name": "Security Jack",
      //       "skinId": "securityJack",
      //       "class": "dps",
      //       "hp": 50,
      //       "equippedAttacks": {
      //         "slot1": "script_foreach-mk-ii",
      //         "slot2": "script_garbage-jammer",
      //         "slot3": "script_block",
      //         "slot4": null,
      //         "slotSuper": "super_sudo-damage"
      //       }
      //     }
      //   ]
      // },

      // {
      //   type: "showHowToPlayMenu",
      // },


      // {
      //   type: "showPauseMenu",
      //   //openPauseMenuToPage: "upgrades"
      // },
      // {
      //   type: "showPauseMenu",
      //   openPauseMenuToPage: "swap"
      // }

      // {
      //   type: "changeCrew",
      //   setCrewToIds: ["hero"]
      // },
      //
      // {
      //   type: "changeCrewMemberProperty",
      //   //Example of making a few crew members unavailable (EX: chapter 6 beginning)
      //   changes: [
      //     {
      //       id: "memberB",
      //       isAvailable: false,
      //     },
      //     {
      //       id: "memberC",
      //       isAvailable: false,
      //     }
      //   ]
      // }

      ///////////////////
      // {
      //   type: "updateHp",
      //   percent: 0.25
      // }

      // {
      //   type: "showBattleWinPopup",
      //   xpGained: 125
      // },

      // {
      //   "type": "showCreatePersonMenu",
      //   "memberProperties": {
      //     "id": "hero"
      //   }
      // },

      // {
      //   type: "playSfx",
      //   sfxId: "sfx_acceptBattle"
      // },
      // {
      //   "type": "characterJump",
      //   "subjectId": "hero",
      //   "direction": "DOWN",
      //   "jumpType": "single",
      //   "postDelayMs": 500
      // },

      // {
      //   "type": "textMessage",
      //   "textArrays": [
      //     [
      //       //"NEW_UPGRADE::upgrade_item-turn",
      //       //"NEW_SCRIPT::script_slice",
      //       //"NEW_SCRIPT::super_team-slice",
      //     ],
      //   ]
      // },

      // {
      //   "type": "stopSong"
      // },
      // {
      //   "type": "playSong",
      //   "songId": "music_macroSpeechChaos"
      // },
      // { //note: never been used in content?
      //   "type": "playSfx",
      //   "sfxId": "someSfx"
      // },

      // {
      //   type: "removeStoryPoints",
      //   removeStoryPoints: [
      //     "mememe"
      //   ],
      // }

      // {
      //   "type": "showRecoverMenu",
      // }

      // {
      //   "type": "textMessage",
      //   "textArrays": [
      //     [
      //       "See those scarier looking battle spots? You won't recover any battery life from winning those! ",
      //       "@@pause_15@@",
      //       "You're on your own!"
      //     ],
      //   ]
      // },

      // {
      //   "type": "overworldFade",
      //   "fadeTypeId": "toWhite"
      // },
      // {
      //   "type": "changeMap",
      //   "mapId": "map2",
      //   "playerX": 3,
      //   "playerY": 4
      // },
      // {
      //   "type": "overworldFade",
      //   "fadeTypeId": "fromWhite"
      // }

      // {
      //   direction: "[dynamic-face-player]",
      //   type: "characterStand",
      //   delayMsAfter: 1000,
      //   subjectId: "itemBox",
      // }

      // {
      //   type: "showCreatePersonMenu",
      //   memberProperties: {
      //     id: "hero",
      //     // class: "support",
      //     // attacks: [
      //     //   {actionId: "whatever"},
      //     //   {actionId: "whatever2"},
      //     // ]
      //   }
      // },
      // {
      //   type: "changeInteractiveProperty",
      //   personId: "hero",
      //   changes: {
      //     skinId: "[dynamic_member_skin]::hero"
      //   }
      // },
    ]
  };

  setupMap = () => {

    const mapChangePayload = store.getState().overworld.mapChangePayload || {};
    const rawMap = chapter.data.maps[this.props.currentMapId];

    const {acquiredStoryPoints, acquiredMembers} = store.getState().playerData;

    if (!rawMap) {
      console.warn('map not found in chapter.data', this.props.currentMapId)
    }

    const {returnFromBattleInteractives, returnFromLoadGameInteractives} = store.getState().overworld;

    this.mapData = getInitialMapState(rawMap, {
      ...mapChangePayload,
      acquiredStoryPoints,
      acquiredMembers,
      addExplodingSpaceAtHeroPosition: store.getState().overworld.addExplodingSpaceAtHeroPosition,

      mergeInteractives: function() {

        if (returnFromBattleInteractives) {
          return returnFromBattleInteractives
        }
        if (returnFromLoadGameInteractives) {
          return returnFromLoadGameInteractives
        }
        return []
      }()
    });

    //Set starting point of hero in state so we can save it properly
    const hero = (this.mapData.interactives || []).find(c => c.id === "hero");
    if (hero && !returnFromBattleInteractives) {
      setOverworldValue({
        heroEntranceX: hero.x,
        heroEntranceY: hero.y,
        heroEntranceDirection: hero.direction
      })
    }

    setOverworldValue({
      battle_arenaId: this.mapData.mapBattleArenaId, //Set the battle arena for when we launch into battle
      mapChangePayload: null,
      returnFromBattleInteractives: null, //clear out
      returnFromLoadGameInteractives: null, //clear out
      addExplodingSpaceAtHeroPosition: false //reset this, just in case
    });

    this.setState({
      isOverworldMounted: true
    })
  };

  componentDidMount() {
    this.setupMap();
  }

  componentDidUpdate(prevProps) {

    //Change map
    if (prevProps.mapChangeCache !== this.props.mapChangeCache) {
      this.setState({
        mapTransition: "out"
      }) //callback on child will be called. See `handleOldMapTransitionedOut`
    }

    if (prevProps.externalEventTrigger !== this.props.externalEventTrigger) {
      this.handleSingleCutsceneEventCompleted();
    }
  }

  handleOldMapTransitionedOut = () => {
    this.setState({
      isOverworldMounted: false,
      mapTransition: null
    }, () => {
      this.setupMap();
      this.handleSingleCutsceneEventCompleted();
    });
  };


  handleSingleCutsceneEventCompleted = () => {
    this.setState(state => {
      return {
        globalCutsceneEvents: state.globalCutsceneEvents.filter((d, i) => i > 0),
      }
    })
  };

  //New cutscene steps set!
  handleProvideNewCutsceneEvents = (interactionArray) => {
    this.setState(state => {
      return {
        globalCutsceneEvents: interactionArray
      }
    })
  };

  render() {
    return (
      <KeyboardArrowControllerContainer
        render={({directionArrows, actionKeyFireCache, pauseKeyFireCache}) => {
          return (
            this.state.isOverworldMounted && (
              <Overworld
                mapTransition={this.state.mapTransition}
                handleMapTransitionOut={this.handleOldMapTransitionedOut}

                // key={this.props.currentMapId}
                mapData={this.mapData}
                pixelSize={this.props.pixelSize}
                globalCutsceneEvents={this.state.globalCutsceneEvents}
                handleSingleCutsceneEventCompleted={this.handleSingleCutsceneEventCompleted}
                handleProvideNewCutsceneEvents={this.handleProvideNewCutsceneEvents}
                directionArrows={directionArrows}
                actionKeyFireCache={actionKeyFireCache}
                pauseKeyFireCache={pauseKeyFireCache}

                refreshPeopleList={this.props.refreshPeopleList}
                storyPointTrigger={this.props.storyPointTrigger}

                acquiredStoryPoints={this.props.acquiredStoryPoints}
              />
            )
          )
        }}/>

    );
  }
}

export default connect((state, props) => {

  return {
    refreshPeopleList: state.overworld.refreshPeopleList,

    currentMapId: state.overworld.currentMapId,
    mapChangeCache: state.overworld.mapChangeCache || "initial",

    externalEventTrigger: state.overworld.externalEventTrigger,
    storyPointTrigger: state.overworld.storyPointTrigger,

    acquiredStoryPoints: state.playerData.acquiredStoryPoints,
  }
})(OverworldContainer)