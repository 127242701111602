//WATCH OUT: This is not the source of truth. Please copy over from DS2017/AllCombatants
//This is just for dev testing

export default [
  {
    id: "enemyA",
    name: "Judge",
    class: "lead",
    level: 15,
    skinId: "judge",
    useMusic: "music_middleBoss",
    playerVictoryMessage: "I believe we have a winner!",
    playerDefeatMessage: "Maybe next year...",
    useScriptedDecisionFn: "judge",
    attacks: [
      {actionId: "script_bug"},
      {actionId: "script_clean"},
      {actionId: "script_pop"},
      {actionId: "script_screen-blur"},
    ],
    items: [
      {actionId: "item_battery-pack"},
      {actionId: "item_battery-pack"},
      {actionId: "item_defense-sticker"},
    ],
    immunities: ["lag", "nervous"],

    defenseModifier: 6,
    speedModifier: 6,
    activeUpgradeId: "upgrade_no-promises"
  }
].map(e => {
  return {
    ...e,
    belongsToTeam: "two"
  }
})