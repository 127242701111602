import store from '../init/store'
import {setPlayerDataValue} from "../redux-actions/player-data-actions";

export function handleSellItemInRedux(stagedInstanceId, sellValue) {
  const {currency, inventory} = store.getState().playerData;

  setPlayerDataValue({
    inventory: inventory.filter(i => i.instanceId !== stagedInstanceId),
    currency: {
      ...currency,
      credits: currency.credits + sellValue
    }
  })
}