import BattleActions from '../battle-data/battle-actions-data'
import Upgrades from '../battle-data/Upgrades'

export function getArenaHomeMenu(combatant, options={}) {

  const actions = options.allActions || BattleActions;
  const upgrades = options.allUpgrades || Upgrades;

  //Start empty
  let menu = {};
  let map = [];

  //Scripts -----------------------------------------------------
  menu.scripts = {
    label: "Scripts...",
    icon: "directory",
    description: `Select a Script from ${combatant.name}'s computer...`,

    handler() {
      return {
        goToMenuId: "scripts"
      }
    }
  };
  map.push( ["scripts"] );

  //Items -----------------------------------------------------
  menu.items = {
    label: "Items...",
    icon: "directory",
    description: `Select an Item from team inventory...`,
    checkUnavailable() {
      return combatant.items.length === 0;
    },
    handler() {
      return {
        goToMenuId: "items"
      }
    }
  };
  map.push( ["items"] );

  //Upgrades -----------------------------------------------------
  if (combatant.upgrades && combatant.upgrades.length > 0 && combatant.activeUpgradeId) {
    const upgrade = upgrades[combatant.activeUpgradeId];
    menu.upgrades = {
      label: function() {
        //Maybe include * after name to indicate disclaimer
        if (upgrade && combatant.status === "deadline") {
          if (upgrade.name === "Item Ninja" || upgrade.name === "Item Mischief") {
            return `${upgrade.name} * ...`
          }
        }
        return upgrade.name + "..."
      }(),
      icon: "upgrade",
      description: function() {
        //Maybe include disclaimer
        if (upgrade && combatant.status === "deadline") {
          if (upgrade.name === "Item Ninja" || upgrade.name === "Item Mischief") {
            return `* ${upgrade.name} doesn't stack with Deadline!`
          }
        }
        //return upgrade.description
        return `Specify an Upgrade for this turn...`
      }(),
      handler() {
        return {
          goToMenuId: "upgrades"
        }
      }
    };
    map.push(["upgrades"]);
  }

  //Super -----------------------------------------------------
  const supers = (combatant.supers || []).map(({actionId}) => actions[actionId]);
  if (supers.length) {
    const isDangerMeterReady = combatant.dangerMeter && combatant.dangerMeter >= 100;
  const superMove = supers[0]; //Not handling the case where we allow you to use multiples right now.


    const isSqlDropNotAvailable = superMove.targetTeamMustHaveItems && combatant.enemyHasZeroItems;
    const isTeamReviveNotAvailable = (superMove.targetsMustBeDead && !combatant.anyDeadAllies); //Basically checking for Team Revive - disable if nobody is dead

    menu.super = {
      label: superMove.name,
      icon: superMove.icon,
      description: isDangerMeterReady
        ? function() {
          if (isSqlDropNotAvailable) {
            return "No enemy items to delete!"
          }
          if (isTeamReviveNotAvailable) {
            return "No allies to revive!"
          }
          return superMove.description
        }()
        : `Available when Danger Meter is fully charged!`, //Default to this when no danger meter
      handler() {
        return {
          stageActionId: combatant.supers[0].actionId,
          goToMenuId: "targeting"
        }
      },
      checkUnavailable() {
        if (isSqlDropNotAvailable || isTeamReviveNotAvailable) {
          return true;
        }
        return !isDangerMeterReady
      }
    };
    map.push(["super"]);
  }

  map.push(["bail"]);

  //Bail -----------------------------------------------------
  menu.bail = {
    label: "Bail",
    icon: "bail",
    description: "Forfeit battle as a defeat",
    handler() {
      return {
        goToMenuId: "bail"
      }
    }
  };

  return {
    menu,
    map,
    //backOptionId: "bail"
  }

}