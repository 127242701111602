export function getRecoverMenu({credits, isWholeCrewFull}) {

  const creditCost = 10; //You can maybe pass this in later as the recovering should get more expensive
  const chargeCredits = credits > creditCost ? creditCost : credits;

  return {
    chargeCredits,
    specialMessage: function() {
      if (credits === 0) {
        return "Youch. You've seen better days!"
      }
      if (credits < creditCost) {
        return `We normally charge ${creditCost} but you clearly need help...`
      }
      return null
    }(),
    menu: {
      recover: {
        label: `Charge Laptop`,
        viewBag: {
          chargeCredits
        },
        checkUnavailable() {
          return isWholeCrewFull
        },
        handler() {
          return {
            type: "RECOVER",
            chargeCredits
          }
        }
      },
      back: {
        label: "Cancel",
        handler() {
          return {
            type: "GO_BACK"
          }
        }
      }
    },
    map: [
      ["recover"],
      ["back"]
    ]
  }
}