import React from 'react';
import {createSingleKeyPressBinding} from "../helpers/createSingleKeyPressBinding";
import {getNextOptionFromArrowing} from "./keyboard-navigation";
import {audioManager} from "../audio/audioManager";

class ArrowMenu extends React.Component {

  constructor(props) {
    super();
    const {initiallySelectedOptionId, optionsMap} = props;
    const defaultSelectedOption = optionsMap[0][0];

    this.state = {
      selectedOptionId: initiallySelectedOptionId || defaultSelectedOption
    }
  }


  componentDidMount() {
    const upPress = () => { this.handleArrowPress("up") };
    const downPress = () => { this.handleArrowPress("down") };
    const leftPress = () => { this.handleArrowPress("left") };
    const rightPress = () => { this.handleArrowPress("right") };
    const submitPress = () => { this.handleSubmitKey() };
    const submitBack = () => { this.handleBackKey() };

    this.upKeyHandlers = createSingleKeyPressBinding([38, 87], upPress);
    this.downKeyHandlers = createSingleKeyPressBinding([40, 83], downPress);
    this.leftKeyHandlers = createSingleKeyPressBinding([37, 65], leftPress);
    this.rightKeyHandlers = createSingleKeyPressBinding([39, 68], rightPress);

    this.submitKeyHandlers = createSingleKeyPressBinding([13, 32], submitPress);
    this.backKeyHandlers = createSingleKeyPressBinding([27], submitBack);

  }

  componentWillUnmount() {
    let el = document.getElementsByTagName("body")[0];
    [
      ...this.upKeyHandlers,
      ...this.downKeyHandlers,
      ...this.leftKeyHandlers,
      ...this.rightKeyHandlers,
      ...this.submitKeyHandlers,
      ...this.backKeyHandlers
    ].forEach(b => {
      el.removeEventListener("keydown", b.keyDownHandler);
      el.removeEventListener("keyup", b.keyUpHandler);
    });
  }

  componentWillUpdate(newProps) {
    if (newProps.optionsPageId !== this.props.optionsPageId) {
      let firstSelectedOption = newProps.optionsMap[0][0]; //default
      if (newProps.rememberedOptionId && newProps.optionModels[newProps.rememberedOptionId]) {
        firstSelectedOption = newProps.rememberedOptionId;
      }

      //Try to use a remembered option. fallback to first if we don't remember one.
      this.setState({
        selectedOptionId: firstSelectedOption
      });

      //Fire a hook to the parent to tell them the first selected option of the new page
      if (this.props.onNewPageFirstSelectedOption) {
        this.props.onNewPageFirstSelectedOption(firstSelectedOption)
      }

    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.optionsPageId !== this.props.optionsPageId) {

      this.props.onNewOptionsPage && this.props.onNewOptionsPage({
        previousMenuId: prevProps.optionsPageId,
        previousSelectedOptionId: prevState.selectedOptionId
      });

      //Maybe Auto select on first page
      if (this.props.initiallySelectedOptionId && this.props.optionModels[this.props.initiallySelectedOptionId]) {
        this.setState({
          selectedOptionId: this.props.initiallySelectedOptionId
        })
      }

      if (this.props.shouldAutoSubmit) {
        const autoSubmitId = this.props.shouldAutoSubmit(this.props);
        if (autoSubmitId) {
          this.handleSubmitKey(autoSubmitId)
        }
      }
    }
  }

  handleArrowPress = (direction) => {
    //Normal arrow press....
    const {optionsMap, optionModels, handler} = this.props;
    const {selectedOptionId} = this.state;



    //Maybe call special fn for an arrow handler that should do something other than navigate
    const handlerFnName = `${direction}Handler`;
    if (optionModels[selectedOptionId] && optionModels[selectedOptionId][handlerFnName]) {
      handler( optionModels[selectedOptionId][handlerFnName]() );
      return;
    }

    //default to navigate
    const result = getNextOptionFromArrowing(direction, optionsMap, selectedOptionId);
    if (result !== selectedOptionId) {
      audioManager.playSfx("sfx_cursorMove");

      //Maybe fire side effect hook to tell parent that we have a new option selected
      this.props.onNewOptionSelected && this.props.onNewOptionSelected(result);

      this.setState(state => {
        return { selectedOptionId: result }
      })
    }
  };

  handleSubmitKey = (key) => {

    const {selectedOptionId} = this.state;
    //Optionally pass in a direct key to submit (without being active)
    let submitOptionId = key || selectedOptionId; //default to whatever is selected

    const {optionModels, handler} = this.props;
    const activeOption = optionModels[submitOptionId];
    if (activeOption.handler) {
      if (activeOption.checkUnavailable && activeOption.checkUnavailable() ) {
        return;
      }
      handler(activeOption.handler());
    } else {
      console.warn('No handler provided for option: ', submitOptionId)
    }
  };

  handleHoverSetActive = (key) => {
    this.setState({
      selectedOptionId: key
    })
  };


  handleBackKey = () => {
    const {optionModels, handler, backOptionId} = this.props;
    if (optionModels[backOptionId]) {
      handler(optionModels[backOptionId].handler())
    }
  };


  render() {
    const {selectedOptionId} = this.state;
    return (
      this.props.render({
        selectedOptionId,
        handlers: {
          setActive: this.handleHoverSetActive,
          submit: this.handleSubmitKey
        }
      })
    )
  }
}


ArrowMenu.defaultProps = {
  initiallySelectedOptionId: null,
  backOptionId: "back"
};

export default ArrowMenu;


export function getModel(id, rawModel, selectedOptionId) {
  return {
    ...rawModel,
    isSelected: selectedOptionId === id,
    isUnavailable: rawModel.checkUnavailable ? rawModel.checkUnavailable : false
  }
}