export function getDangerMeterReset({casterModel}) {
  //Reset danger meter to 0 because you used a Super
  return {
    type: "eventBlippy",
    subjects: [casterModel.id],
    stateChanges: [
      {
        combatantId: casterModel.id,
        absoluteChanges: {
          dangerMeter: 0
        }
      }
    ],
    delayFramesAfter: 60
  }
}