
export function getFriendlyTeam(combatantId, combatants) {
  const combatantTeam = combatants.find(c => c.id === combatantId).belongsToTeam;
  return combatants.filter(c => c.belongsToTeam === combatantTeam);
}

//unused so far
// export function getTeammates(combatantId, combatants) {
//   const combatantTeam = combatants.find(c => c.id === combatantId).belongsToTeam;
//   return combatants.filter(c => c.belongsToTeam === combatantTeam && c.id !== combatantId); //don't include me
// }

export function getEnemies(combatantId, combatants) {
  const combatantTeam = combatants.find(c => c.id === combatantId).belongsToTeam;
  if (combatantTeam === "one") {
    return combatants.filter(c => c.belongsToTeam === "two");
  }
  if (combatantTeam === "two") {
    return combatants.filter(c => c.belongsToTeam === "one");
  }
  return [];
}