import React from 'react'
import TargetingCrosshair from "../TargetingCrosshair";
import Icon from '../../interface/Icon'
import BoxDiv from '../../interface/BoxDiv'
import BoxDivBody from "../../interface/BoxDivBody";
import StaticTextLine from "../../StaticTextLine";

class ArenaTargetingView extends React.Component {

  render() {
    const {optionModels, /*optionsMap,*/ positions, pixelSize, selectedOptionId, handlers} = this.props;
    const selectedOption = optionModels[selectedOptionId];
    const targets = selectedOption ? selectedOption.targets : "none";

    if (targets === "none") {
      return null
    }


    let allTargets = [];
    Object.keys(optionModels).forEach(key => {
      const model = optionModels[key];
      (model.targets || []).forEach(target => {
        allTargets.push(target);
      })
    });

    return (
      <React.Fragment>

        {/* Instructions box */}
        <div style={{
          position: "absolute",
          left: 0,
          right: 0,
          zIndex:2, //Don't collide with "Enemy Items" UI
          //Vertical position is relevant to the targeting type
          top: selectedOption.targetContext === "friendlies" ? pixelSize * 121 : pixelSize * -1,
          textAlign: "center"
        }}>
          <BoxDiv
            pixelSize={pixelSize}
            style={{display: "inline-block"}}
          >
            <BoxDivBody
              pixelSize={pixelSize}
            >
              <div style={{
                paddingLeft:pixelSize * 4,
                paddingRight:pixelSize * 4,
                paddingTop: pixelSize * 2,
                paddingBottom: pixelSize
              }}>
                <StaticTextLine
                  pixelSize={pixelSize}
                  text={selectedOption.targetingText || ""}
                  theme="dark"
                />
              </div>
              <div
                onClick={() => {
                  handlers.submit("back")
                }}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  left: pixelSize * -14,
                  top: -pixelSize
                }}>
                <Icon
                  pixelSize={pixelSize}
                  category="menuBack"
                />
              </div>
            </BoxDivBody>
          </BoxDiv>
        </div>

        {
          targets.map(t => {
            const {targetId} = t;
            return (
              <div
                key={targetId}
                style={{
                  position: "absolute",
                  pointerEvents: "none",
                  left: positions[targetId].leftPosition,
                  top: positions[targetId].topPosition - pixelSize * 2,
                  zIndex: 5,
                }}>
                <TargetingCrosshair
                  pixelSize={pixelSize}
                  text={t.name}
                  isEnemy={t.isEnemy}
                />
              </div>
            )
          })
        }
        {
          //Show Arrow Icons and Warnings
          allTargets.map((target, i) => {

            const isCurrentlyTargeted = (selectedOption.targets || []).map(t => t.targetId).includes(target.targetId);

            return (
              <React.Fragment key={i}>
                {
                  !isCurrentlyTargeted && (
                    <div style={{
                      position: "absolute",
                      left: positions[target.targetId].leftPosition + pixelSize * 12,
                      top: positions[target.targetId].topPosition + (pixelSize * -1),
                    }}>
                      <Icon
                        pixelSize={pixelSize}
                        category={selectedOption.targetContext === "friendlies" ? "friendlyIndicator" : "hostileIndicator"}
                      />
                    </div>
                  )
                }
                {/* Hoverable / Clickable elements for ArrowMenu handlers */}
                <div
                  onMouseOver={() => {
                    handlers.setActive(target.menuOptionKey)
                  }}
                  onClick={() => {
                    handlers.submit(target.menuOptionKey)
                  }}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    left: positions[target.targetId].leftPosition,
                    top: positions[target.targetId].topPosition,
                    width: pixelSize * 32, height: pixelSize * 32,
                  }}>
                </div>


                {
                  target.hasFuryWarning && (
                    <div style={{
                      position: "absolute",
                      pointerEvents: "none",
                      left: positions[target.targetId].leftPosition + pixelSize * 10,
                      top: positions[target.targetId].topPosition + (pixelSize * 11),
                    }}>
                      <Icon pixelSize={pixelSize} category="warningFuryIcon"/>
                    </div>
                  )
                }

                {
                  target.hasWarning && !target.hasFuryWarning && (
                    <div style={{
                      position: "absolute",
                      pointerEvents: "none",
                      left: positions[target.targetId].leftPosition + pixelSize * 12,
                      top: positions[target.targetId].topPosition + (pixelSize * 12),
                    }}>
                      <Icon pixelSize={pixelSize} category="warningDoNotUse"/>
                    </div>
                  )
                }
              </React.Fragment>
            )
          })
        }
      </React.Fragment>
    );
  }
}

export default ArenaTargetingView;