import {DefaultAction, DefaultScript} from "../Action";
import {getCombatantHpRaise} from "../../formulas/getCombatantHpRaise";
import {getDangerMeterReset} from "../../formulas/getDangerMeterReset";
import {negativeStatuses} from "../../formulas/battleStatuses";

export default {
  ...DefaultAction,
  ...DefaultScript,

  name: "Team Clean",
  description: "Recover HP and negative Statuses of whole friendly team",
  icon:"super-support",
  targetingType: "all-friendlies",

  shouldFailOnSingleTarget(casterModel, targetModel) {
    // if (targetModel.hp === targetModel.maxHp) {
    //   return {
    //     failCode: "full-hp"
    //   }
    // }
    return null;
  },

  successEventsPerAllTargets({casterModel, targetModels, allUpgrades}) {
    return [
      {
        type: "eventBlippy",
        stateChanges: targetModels.map(targetModel => {
          const hpChange = getCombatantHpRaise(targetModel, 100, "percent");
          return {
            combatantId: targetModel.id,
            relativeChanges: {
              hp: hpChange
            },
            absoluteChanges: {
              accuracyModifier: 0
            },
            message: {
              theme: "battlePositiveHp",
              text: `+${hpChange}HP`
            },
          }
        })
      },
      {
        type: "eventBlippy",
        stateChanges: targetModels.filter(targetModel => {
          return negativeStatuses.includes(targetModel.status)
        }).map(targetModel => {
          return {
            combatantId: targetModel.id,
            absoluteChanges: {
              status: "normal",
            },
            message: {
              theme: "battlePositiveHp",
              text: `Status Cleared!`
            },
          }
        })
      },
      getDangerMeterReset({casterModel})
    ]
  }
}