import React from 'react'
import ArenaCombatant from './ArenaCombatant'
//import ArenaActionMenu from './ArenaActionMenu'
import EventStepper from './EventStepper'
import {getArenaCombatantLayoutPosition} from "./getCombatantArenaLayoutPosition";
import MapCanvas from '../Overworld/MapCanvas'
//import PixelArtTextLine from "../pixel-letter-system/PixelArtTextLine";
//import {getLetterStreamFromArray} from "../pixel-letter-system/getLetterStreamFromArray";
import EnemyItems from './EnemyItems'
//import BlockWord from "../pixel-letter-system/BlockWord";
import FriendlyLifebarPanel from './FriendlyLifebarPanel'
import ArenaArrowMenu from './menu/ArenaArrowMenu'
//import TargetingCrosshair from "./TargetingCrosshair";
import Arenas from './Arenas'
import ResultMessage from './BattleResultMessage'


class Arena extends React.Component {

  render() {
    const {combatants, pixelSize, waitingOnCombatantToUseMenu, eventSteps, onSeenAllEvents, turnBlips} = this.props;

    //Friendly team
    const friendlyCombatants = combatants.filter(c => c.belongsToTeam === "one").map((c, i, a) => {
      return {
        ...c,
        arenaPosition: i+1,
        totalTeamPositions: a.length,
        turnBlips: turnBlips[c.id] || 0
      }
    });
    //Enemy team
    const enemyCombatants = combatants.filter(c => c.belongsToTeam === "two").map((c, i, a) => {
      return {
        ...c,
        arenaPosition: i+1,
        totalTeamPositions: a.length,
        turnBlips: turnBlips[c.id] || 0
      }
    });

    let positions = {};
    [
      ...friendlyCombatants,
      ...enemyCombatants
    ].forEach(c => {
      //console.log(c)
      positions[c.id] = getArenaCombatantLayoutPosition({
        position: c.arenaPosition,
        isEnemyTeam: c.belongsToTeam === "two",
        totalPositions: c.totalTeamPositions,
        pixelSize
      });
    });


    return (
      <div style={{
        position: "relative",
        width: (pixelSize * 32) * 7,
        height: (pixelSize * 32) * 7,
      }}>
        <MapCanvas
          mapImage={Arenas[this.props.arenaId].src}
          mapPxWidth={7 * 32}
          mapPxHeight={7 * 32}
          mapWidthStyle={7 * (pixelSize * 32)}
          mapWidthHeight={7 * (pixelSize * 32)}
        />

        {/*<ArenaPerson*/}
            {/*pixelSize={pixelSize}*/}
            {/*cellSize={pixelSize * 32}*/}
            {/*skinId="hank"*/}
        {/*/>*/}

        {
          [...friendlyCombatants, ...enemyCombatants].map(c => {
            const pos = positions[c.id];
            return (
              <ArenaCombatant
                key={c.id}
                pixelSize={pixelSize}
                leftPosition={pos.leftPosition}
                topPosition={pos.topPosition}
                isUsingMenu={waitingOnCombatantToUseMenu === c.id}
                {...c}
              />
            )
          })
        }

        <div style={{position: "absolute", right:0, top:0}}>
          {/*Enemy Items: {this.props.teamTwoItems.length}*/}
          <EnemyItems
            pixelSize={pixelSize}
            buggedItems={this.props.teamTwoItems.filter(i => i.isBugged).length}
            stableItems={this.props.teamTwoItems.filter(i => !i.isBugged).length}
          />
        </div>
        {/*<div style={{position: "absolute", right:0, bottom:0}}>*/}
          {/*Friendly Items: {this.props.teamOneItems.length}*/}
        {/*</div>*/}

        <div style={{
          position: "absolute",
          bottom:pixelSize * 21,
          left:pixelSize*2,
          right:pixelSize*2,
        }}>
          <FriendlyLifebarPanel
            pixelSize={pixelSize}
            partyCombatants={friendlyCombatants}
          />
        </div>

        {
          Boolean(waitingOnCombatantToUseMenu) && (
              <ArenaArrowMenu
                combatants={[
                  ...enemyCombatants,
                  ...friendlyCombatants,
                ]}
                combatantId={waitingOnCombatantToUseMenu}

                combatantLeftPosition={positions[waitingOnCombatantToUseMenu].leftPosition}
                combatantTopPosition={positions[waitingOnCombatantToUseMenu].topPosition}

                //Should the Left and Top just get figured out 1 layer below? We need *all* for targeting
                positions={positions}
                handleCombatantStateChange={this.props.handleCombatantStateChange}
                handleSubmitActionWithForm={this.props.handleSubmitActionWithForm}
                handleSubmitBail={this.props.handleSubmitBail}
                teamOneItems={this.props.teamOneItems}
                teamTwoItems={this.props.teamTwoItems}
                pixelSize={pixelSize}
              />
          )
        }
        {
          //I need to unmount when I'm done showing this array (so I'll mount again when a new array comes in)
          eventSteps.length > 0 && (
            <EventStepper
              events={eventSteps}
              positions={positions}
              pixelSize={pixelSize}
              handleCombatantStateChange={this.props.handleCombatantStateChange}
              handleItemsStateChange={this.props.handleItemsStateChange}
              handleSpawnCombatants={this.props.handleSpawnCombatants}
              handleScheduleEvent={this.props.handleScheduleEvent}
              onDoneWithAllEvents={() => {
                onSeenAllEvents()
              }}
            />
          )
        }
        {
        /* Render ending ResultMessage if there is a winning team established */
          Boolean(combatants[0].winningTeam) && (
            <ResultMessage
              pixelSize={pixelSize}
              winningTeam={combatants[0].winningTeam}
              onDone={() => {
                this.props.handleTransitionOutOfBattle(combatants[0].winningTeam)
              }}
            />
          )
        }

      </div>
    );
  }
}

export default Arena;

