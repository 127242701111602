import {Person} from "../Person";
import {getActiveScenario} from '../getActiveScenario'

export function getActionFromInteractives(interactives = [], actions = {}, acquiredStoryPoints = []) {

  const hero = interactives.find(p => p.id === "hero");
  if (hero.movingProgress > 0) {
    return null
  }

  const heroModel = new Person(hero);
  const actionPosition = heroModel.getNextSpace(hero.x, hero.y, hero.direction);

  //Find a valid other person to talk to:
  const target = interactives.find(m => {
    const targetPos = `${m.x}x${m.y}`;
    return actionPosition === targetPos;
  });

  if (target && !target.movingProgress > 0 && actions[target.id]) {

    const scenario = getActiveScenario(actions[target.id], acquiredStoryPoints);

    if (
      scenario.interactionRequiresPlayerDirection &&
      scenario.interactionRequiresPlayerDirection.indexOf(hero.direction) === -1
    ) {
      return scenario.wrongDirectionInteraction || null;
    }


    if (scenario && scenario.interaction && scenario.interaction.length) {
      return scenario.interaction
    }
  }

  return null
}

