import store from '../../init/store'
import {getCurrentLevelFromXp} from "../../getCurrentLevelFromXp";
import {getCombatantStats} from "../../Battle7/getCombatantStats";

//Filter acquired members for people that are in battle crew (And order by the flag value number)
export function getBattleCrew(acquiredMembers=[]) {
  return acquiredMembers.filter(m => m.battleCrewSlot).sort((m1,m2) => m1.battleCrewSlot > m2.battleCrewSlot ? 1: -1)
}

//Might need this later for a pause menu or something? Maybe not. IDK.
export function getBattleCrewFromRedux() {
  const level = getCurrentLevelFromXp(store.getState().playerData.teamXp);
  return getBattleCrew(store.getState().playerData.acquiredMembers).map(c => {
    return {
      ...c,
      maxHp: getCombatantStats({level}).maxHp
    }
  })
}