import {Slice, SliceMkII, SliceMkIII} from "./actions/offense/createSliceAction";
import {Blackberry} from './actions/offense/createGenericAttack'
import Push from "./actions/offense/Push";
import Pop from "./actions/offense/Pop";
import JPop from "./actions/offense/J-Pop";
import {ForEach, ForEachMkII} from "./actions/offense/createForEachAction";
import Deadline from "./actions/status-setting/Deadline"
import DDoS from "./actions/status-setting/DDoS"
import Intimidate from "./actions/status-setting/Intimidate"
import Hack from "./actions/status-setting/Hack"
import GarbageJammer from './actions/status-setting/GarbageJammer'
import Thrash from './actions/status-setting/Thrash'
import Headphones from './actions/status-setting/Headphones'
import Dangerous from './actions/status-setting/Dangerous'
import Block from './actions/status-setting/Block'
import Frail from './actions/status-setting/Frail'
import Inspire from './actions/recovery/Inspire'
import JudgeSpawn from './actions/recovery/JudgeSpawn'
import Escape from './actions/recovery/Escape'
import SudoDestroy from './actions/offense/SudoDestroy'
import {CopyPaste, Curl, CurlMkII} from './actions/item-mischief/Curl'
import Bug from './actions/item-mischief/Bug'
import Infest from './actions/item-mischief/Infest'
import SqlInjection from './actions/item-mischief/SqlInjection'
import {ScreenBlur, Firewall, Scrum, PowerStalk, TeamBuilding} from './actions/affect-enemy-stats/createStatsAction'
import {ItemHealMemoryLeak, ItemHealLag, ItemHealHacked, ItemHealNervous, ItemHealFire, ItemHealDisabled, ItemHealAnything} from './actions/items/createStatusCuringItem'
import {
  BatteryPack,
  FullBatteryPack,
  FullRevivePack,
  RevivePack,
  TeamBatteryPack
} from './actions/items/createBatteryPack'
import {DangerBoost, TeamDangerBoost} from './actions/items/createDangerBoost'
import {AttackSticker, DefenseSticker, SlipperySticker} from './actions/items/createStickerItem'
import Noop from './actions/natural/Noop'
import Speak from "./actions/natural/Speak";
import NaturalAutoKill from './actions/natural/NaturalAutoKill'
import NaturalEscalateFromLeakToFire from './actions/natural/NaturalEscalateFromLeakToFire'
import {NaturalHurtByLeak, NaturalHurtByFire} from './actions/natural/createHurtByLeak'
import NaturalRecoilByFury from './actions/natural/NaturalRecoilByFury'
import NaturalRecoverByFocus from './actions/natural/NaturalRecoverByFocus'
import TeamDeadline from './actions/status-setting/TeamDeadline'
import TeamClean from './actions/recovery/TeamClean'
import TeamRevive from './actions/recovery/TeamRevive'
import TeamForEach from './actions/offense/TeamForEach'
import {RageQuit} from './actions/offense/RageQuit'
import ArenaSecurityGridZap from './actions/arena/ArenaSecurityGridZap'

import {
  NaturalEndFury,
  NaturalEndDeadline,
  NaturalEndFocus,
  NaturalEndNervous,
  NaturalEndHacked,
  NaturalEndDangerous,
  NaturalEndBlock,
  NaturalEndFrail,
  NaturalEndLag,
  NaturalEndDisabled,
} from './actions/natural/createNaturalStatusEnd'
import NaturalLagOut from './actions/natural/NaturalLagOut'
import NaturalNervousMistake from './actions/natural/NaturalNervousMistake'
import NaturalHackedAttack from './actions/natural/NaturalHackedAttack'
import TeamSlice from "./actions/offense/TeamSlice";
import SqlDrop from './actions/item-mischief/SqlDrop';
import Troll from "./actions/status-setting/Troll";
import Clean from "./actions/status-setting/Clean";
import {Wildfire, IntimidateAll} from "./actions/status-setting/createTeamStatusSettingAction";
import Disable from "./actions/status-setting/ShutDown";
import {Promise, PromiseAll, NaturalPromiseAllResolve, NaturalPromiseResolve} from './actions/offense/createPromiseAction';
import ScopeBomb from "./actions/offense/ScopeBomb";
import Binary from './actions/offense/Binary'
import Restart from './actions/recovery/Restart'

const BattleActions = {

  //noop:
  "noop": Noop,
  "speak": Speak,

  //offense
  "script_slice": Slice,
  "script_slice-mk-ii": SliceMkII,
  "script_slice-mk-iii": SliceMkIII,
  "script_push": Push,
  "script_pop": Pop,
  "script_j-pop": JPop,
  "script_foreach": ForEach,
  "script_foreach-mk-ii": ForEachMkII,
  "script_promise": Promise,
  "script_scope-bomb": ScopeBomb,
  "script_binary": Binary,

  //generic one-offs
  "script_blackberry": Blackberry,
  "script_restart": Restart,
  "script_escape": Escape,

  //status setting
  "script_ddos": DDoS,
  "script_garbage-jammer": GarbageJammer,
  "script_intimidate": Intimidate,
  "script_disable": Disable,
  "script_hack": Hack,
  "script_thrash": Thrash,
  "script_headphones": Headphones,
  "script_dangerous": Dangerous,
  "script_troll": Troll,
  "script_deadline": Deadline,
  "script_block": Block,
  "script_frail": Frail,
  "script_clean": Clean,
  "script_inspire": Inspire,

  //item mischief
  "script_curl": Curl,
  "script_curl-mk-ii": CurlMkII,
  "script_bug": Bug,
  "script_infest": Infest,
  "script_sql-injection": SqlInjection,
  "script_copy-paste": CopyPaste,

  //affect enemy stats
  "script_screen-blur": ScreenBlur,
  "script_power-stalk": PowerStalk,

  "script_firewall": Firewall,
  "script_scrum": Scrum,
  "script_team-building": TeamBuilding,

  //Spawning
  "script_judge-spawn": JudgeSpawn, //reserved for story characters

  //items
  "item_heal-memory-leak": ItemHealMemoryLeak,
  "item_heal-lag": ItemHealLag,
  "item_heal-hacked": ItemHealHacked,
  "item_heal-nervous": ItemHealNervous,
  "item_heal-fire": ItemHealFire,
  "item_heal-disabled": ItemHealDisabled,
  "item_heal-anything": ItemHealAnything,

  "item_battery-pack": BatteryPack,
  "item_full-battery-pack": FullBatteryPack,
  "item_team-battery-pack": TeamBatteryPack,
  "item_revive-pack": RevivePack,
  "item_full-revive-pack": FullRevivePack,

  "item_attack-sticker": AttackSticker,
  "item_defense-sticker": DefenseSticker,
  "item_slippery-sticker": SlipperySticker,
  "item_danger-boost": DangerBoost,
  "item_team-danger-boost": TeamDangerBoost,

  //naturals
  "natural_end-lag": NaturalEndLag,
  "natural_end-focus": NaturalEndFocus,
  "natural_end-fury": NaturalEndFury,
  "natural_end-nervous": NaturalEndNervous,
  "natural_end-hacked": NaturalEndHacked,
  "natural_end-dangerous": NaturalEndDangerous,
  "natural_end-block": NaturalEndBlock,
  "natural_end-frail": NaturalEndFrail,
  "natural_end-disabled": NaturalEndDisabled,
  "natural_end-deadline": NaturalEndDeadline,

  "natural_hurt-by-leak": NaturalHurtByLeak,
  "natural_recoil-by-fury": NaturalRecoilByFury,
  "natural_hurt-by-fire": NaturalHurtByFire,
  "natural_recover-by-focus": NaturalRecoverByFocus,
  "natural_escalate-leak-to-fire": NaturalEscalateFromLeakToFire,
  "natural_lag-out": NaturalLagOut,
  "natural_nervous-mistake": NaturalNervousMistake,
  "natural_hacked-attack": NaturalHackedAttack,
  "natural_promise-resolve": NaturalPromiseResolve,
  "natural_promise-all-resolve": NaturalPromiseAllResolve,
  "natural_auto-kill": NaturalAutoKill,

  //Supers
  "super_team-slice": TeamSlice,
  "super_team-foreach": TeamForEach,
  "super_sql-drop": SqlDrop,
  "super_wildfire": Wildfire,
  "super_intimidate-all": IntimidateAll,
  "super_sudo-damage": SudoDestroy,
  "super_team-deadline": TeamDeadline,
  "super_team-clean": TeamClean,
  "super_team-revive": TeamRevive,
  "super_promise-all": PromiseAll,
  "super_rage-quit": RageQuit,

  //Arena specific stuff:
  "arena_security-grid-zap": ArenaSecurityGridZap,
};

export default BattleActions;
