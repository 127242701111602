import {DefaultAction, DefaultScript} from "../Action";

export default {
  ...DefaultAction,
  ...DefaultScript,
  name: "Restart",
  description: "Fully recover an ally",
  icon: "support",

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {
    return [
      // {
      //   type: "eventAnimation",
      //   animationId: "animationPop",
      //   subjects: [targetModel.id],
      //   delayFramesAfter: 12,
      // },
      {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: targetModel.id,
            absoluteChanges: {
              hp: targetModel.maxHp,
              status: "normal",
              defenseModifier: 0
            },
            message: {
              theme: "battlePositiveHp",
              text: `BRAND NEW MACHINE`
            },
          }
        ],
        delayFramesAfter: 60
      }
    ];
  }
}