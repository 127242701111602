import {DefaultAction, DefaultScript} from "../Action";
import BattleActions from "../../battle-actions-data";
import { shuffle } from "../../../../helpers/shuffle";

export default {
  ...DefaultAction,
  ...DefaultScript,
  name: "Sql Injection",
  description: "Delete 1 random enemy item",
  targetingType: "all-enemies",
  icon: "dps",
  accuracy: 96,

  teamItemMoveMethods: true,

  shouldFailOnTargetTeam({casterModel, friendlyItems, enemyItems}) {
    return enemyItems.length === 0;
  },

  wholeTeamFailEvent({casterModel, targetModels}) {
    return [
      {
        type: "eventBlippy",
        stateChanges: targetModels.map(targetModel => {
          return {
            combatantId: targetModel.id,
            message: {
              text: `NO ITEMS!`
            },
          }
        }),
      },
    ]
  },

  successEventOnTeam({casterModel, friendlyItems, enemyItems, allActions, targetModels}) {

    const itemToDelete = shuffle(enemyItems || [])[0];
    const actions = allActions || BattleActions;
    const itemName = (actions[itemToDelete.actionId] || {}).name || "";

    const finalText = casterModel.belongsToTeam === "one"
      ? `Deleted 1 enemy ${itemName}!`
      : `1 ${itemName} was deleted from your team!`;

    return [

      {
        type: "eventAnimation",
        animationId: "animationSqlInjection",
        subjects: [ targetModels.filter(t => t.hp > 0).map(t => t.id)[0] ],
        haltQueueUntilDone: true,
      },
      {
        type: "eventBlippy",
        subjects: [casterModel.id],
        teamItemChangeInstructions: {
          type: "delete",
          instanceIds: [itemToDelete.instanceId],
        },
        delayFramesAfter: 30,
      },
      {
        type: "eventDialogBox",
        //subject: casterModel.id,
        textArray: [finalText],
        haltQueueUntilDone: true
      },
    ]
  }
}