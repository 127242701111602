export default {

  getUse() { return [] },

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {
    return [
      {
        type: "eventAnimation",
        animationId: "animationGenericPain",
        subjects: [targetModel.id],
        delayFramesAfter: 12,
      },
      {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: targetModel.id,
            absoluteChanges: {
              hp: 0
            }
          }
        ],
      }
    ]
  }
};