import React from 'react'
import store from "../init/store";
import {getPauseUpgradesMenu} from './getPauseUpgradesMenu'
import ArrowMenu from "../ArrowMenu/ArrowMenu";
import ScrollingOptionsList from "../menu-components/ScrollingOptionsList";
import BoxDiv from "../interface/BoxDiv";
import BoxDivBody from "../interface/BoxDivBody";
import StaticTextLine from "../StaticTextLine";
import PauseHeader from "./PauseHeader";

class PauseUpgradesArrowMenu extends React.Component {

  constructor() {
    super();
    const {map, menu} = getPauseUpgradesMenu({
      upgrades: store.getState().playerData.allPlayerUpgrades
    });
    this.map = map;
    this.menu = menu;
  }

  optionHandler = (action) => {
    //Journal page doesn't really "do" anything.

    //Except for back:
    if (action && action.type === "GO_BACK") {
      this.props.handleChangeMenuPage("home")
    }
  };

  render() {
    const {pixelSize} = this.props;
    const {menu, map} = this;

    //This would theoretically not show up, I guess.
    if (map.length === 0) {
      return <div>No upgrades!</div>
    }

    const menuWidth = 160;

    return (
      <ArrowMenu
        handler={this.optionHandler}
        backOptionId={"back"}
        optionsPageId={"upgrades"}
        optionsMap={map}
        optionModels={menu}
        render={({handlers, selectedOptionId}) => {
          const selectedOptionModel = menu[selectedOptionId];
          return (
            <React.Fragment>
              <PauseHeader
                title={"Upgrades"}
                handleBackClick={() => this.props.handleChangeMenuPage("home")}
              />

              <div style={{
                display: "flex",
                justifyContent: "center",
                width: pixelSize * 160,
                marginBottom: pixelSize * 4,
                flexWrap: "wrap"
              }}>
                <StaticTextLine
                  key={selectedOptionId}
                  pixelSize={pixelSize}
                  //text={"One upgrade can be applied per turn in battle"}
                  //text={"One upgrade can be applied to your turn in battle!"}
                  //text={"Set an upgrade to be used per turn in battle!"}
                  //text={"These are used during battle. One upgrade applied per turn."}
                  //text={"These are used during battle. Apply one upgrade per turn."}
                  text={"These are used during battle. Active upgrade may be changed during each turn."}
                />
              </div>

              <ScrollingOptionsList
                pixelWidth={menuWidth}
                optionsLimit={6}
                handlers={handlers}
                optionsMap={map}
                optionModels={menu}
                selectedOptionId={selectedOptionId}
                pixelSize={pixelSize}
              />
              <div style={{marginTop: pixelSize * 4}}>
                <BoxDiv pixelSize={pixelSize}>
                  <BoxDivBody grayTextboxTheme pixelSize={pixelSize} style={{paddingLeft: pixelSize * 4, paddingRight: pixelSize * 4, width: menuWidth * pixelSize, minHeight: pixelSize * 34}}>
                    <StaticTextLine
                      key={selectedOptionId}
                      pixelSize={pixelSize}
                      text={selectedOptionModel.description}
                    />
                  </BoxDivBody>
                </BoxDiv>
              </div>
            </React.Fragment>
          )
        }
        }
      />
    );
  }
}

export default PauseUpgradesArrowMenu;