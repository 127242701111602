import store from "../init/store";
import React from "react";
import BoxDiv from "../interface/BoxDiv";
import BoxDivBody from "../interface/BoxDivBody";
import StaticTextLine from "../StaticTextLine";
import ArrowMenu from "../ArrowMenu/ArrowMenu";
import { getPauseJournalMenu } from "./getPauseJournalMenu";
import { chapter } from "../ChapterGetter";
import Icon from '../interface/Icon'
import PauseHeader from "./PauseHeader";

class PauseJournalArrowMenu extends React.Component {
  constructor() {
    super();

    const { map, menu } = getPauseJournalMenu({
      acquiredStoryPoints: store.getState().playerData.acquiredStoryPoints,
      journalStories: chapter.data.journals
    });
    this.map = map;
    this.menu = menu;
  }

  optionHandler = action => {
    //Journal page doesn't really "do" anything.

    //Except for back:
    if (action && action.type === "GO_BACK") {
      this.props.handleChangeMenuPage("home");
    }
  };

  render() {
    const { pixelSize } = this.props;
    const { menu, map } = this;

    return (
      <ArrowMenu
        handler={this.optionHandler}
        backOptionId={"back"}
        optionsPageId={"journal"}
        optionsMap={map}
        optionModels={menu}
        render={({ handlers, selectedOptionId }) => {
          const selectedOptionModel = menu[selectedOptionId];

          if (!selectedOptionModel) {
            return <div>No Journals!</div>
          }

          return (
            <div style={{ width: pixelSize * 200 }}>
              <PauseHeader
                title={"Journal"}
                handleBackClick={() => this.props.handleChangeMenuPage("home")}
              />
              <nav style={{ display: "flex", marginBottom: pixelSize*7 }}>
                {map[0].map(d => {
                  const isActive = selectedOptionId === d;
                  return (
                    <Option
                      handleClick={() => {
                        //Set this story to be active when you click on it
                        handlers.setActive(d)
                      }}
                      key={d}
                      pixelSize={pixelSize}
                      isActive={isActive}
                      isComplete={menu[d].isComplete}
                    />
                  );
                })}
              </nav>
              <div>
                <BoxDiv pixelSize={pixelSize}>
                  <BoxDivBody
                    grayTextboxTheme={true}
                    pixelSize={pixelSize}
                    style={{
                      paddingLeft: pixelSize * 6,
                      paddingRight: pixelSize * 6,
                      paddingTop: pixelSize * 4,
                      paddingBottom: pixelSize * 4,
                    }}
                  >
                    <div style={{marginBottom: pixelSize * 4}}>
                      <StaticTextLine
                        key={selectedOptionId}
                        pixelSize={pixelSize}
                        text={selectedOptionModel.label}
                      />
                    </div>
                    <StaticTextLine
                      key={selectedOptionId}
                      pixelSize={pixelSize}
                      text={selectedOptionModel.description}
                    />
                  </BoxDivBody>
                </BoxDiv>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

const Option = ({ pixelSize, isActive, isComplete, handleClick }) => {
  return (
    <div
      style={{
        width: pixelSize * 24,
        height: pixelSize * 24,
        marginRight: pixelSize * 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        cursor: "pointer"
      }}
      onClick={() => {
        handleClick();
      }}
    >
      <Icon
        pixelSize={pixelSize}
        category={isComplete ? "check" : "journal"}
      />
      {
        isActive && (
          <div style={{
            position: "absolute",
            left:pixelSize,
            top:pixelSize,
            bottom:pixelSize,
            right:pixelSize,
            boxShadow: `0 0 0 ${pixelSize}px #07bfdd, 0 0 0 ${pixelSize*2}px #7de2f2, 0 0 0 ${pixelSize*3}px #07bfdd`
          }}>
          </div>
        )
      }
    </div>
  );
};

export default PauseJournalArrowMenu;
