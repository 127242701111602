import React from 'react'
import capitals from './capitals'
import numbers from './numbers'
import specialCharacters from './special-characters'
import themes from './themes'

export function getStyle(pixelSize = 1, model) {
  return {
    width: pixelSize * model.width,
    marginRight: pixelSize,
  }
}

export default {
  ...specialCharacters,
  ...numbers,
  ...capitals,
  "a": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9" shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M1 2h2M3 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M1 4h3M0 5h1M3 5h1M1 6h3" />
        </svg>
      )
    }
  },
  "b": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 0h1M0 1h1M0 2h3M0 3h1M3 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 4h1M3 4h1M0 5h1M3 5h1M0 6h3" />
        </svg>
      )
    }
  },
  "c": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M1 2h3M0 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 4h1M0 5h1M1 6h3" />
        </svg>
      )
    }
  },
  "d": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M3 0h1M3 1h1M1 2h3M0 3h1M3 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 4h1M3 4h1M0 5h1M3 5h1M1 6h3" />
        </svg>
      )
    }
  },
  "e": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M1 2h2M0 3h1M3 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 4h4M0 5h1M1 6h3" />
        </svg>
      )
    }
  },
  "f": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M2 0h1M1 1h1M3 1h1M1 2h1M0 3h3" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M1 4h1M1 5h1M1 6h1" />
        </svg>
      )
    }
  },
  "g": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M1 2h2M0 3h1M3 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 4h1M3 4h1M0 5h1M3 5h1M1 6h3M3 7h1M0 8h3" />
        </svg>
      )
    }
  },
  "h": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 0h1M0 1h1M0 2h3M0 3h1M3 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 4h1M3 4h1M0 5h1M3 5h1M0 6h1M3 6h1" />
        </svg>
      )
    }
  },
  "i": {
    width: 1,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 1 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 0h1M0 2h1M0 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 4h1M0 5h1M0 6h1" />
        </svg>
      )
    }
  },
  "j": {
    width: 3,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 3 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M2 0h1M2 2h1M2 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M2 4h1M2 5h1M2 6h1M2 7h1M0 8h2" />
        </svg>
      )
    }
  },
  "k": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 0h1M0 1h1M3 1h1M0 2h1M2 2h1M0 3h2" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 4h1M2 4h1M0 5h1M3 5h1M0 6h1M3 6h1" />
        </svg>
      )
    }
  },
  "l": {
    width: 2,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 2 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 0h1M0 1h1M0 2h1M0 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 4h1M0 5h1M1 6h1" />
        </svg>
      )
    }
  },
  "m": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 2h4M0 3h1M2 3h1M4 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 4h1M2 4h1M4 4h1M0 5h1M2 5h1M4 5h1M0 6h1M2 6h1M4 6h1" />
        </svg>
      )
    }
  },
  "n": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 2h3M0 3h1M3 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 4h1M3 4h1M0 5h1M3 5h1M0 6h1M3 6h1" />
        </svg>
      )
    }
  },
  "o": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M1 2h2M0 3h1M3 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 4h1M3 4h1M0 5h1M3 5h1M1 6h2" />
        </svg>
      )
    }
  },
  "p": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 2h3M0 3h1M3 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 4h1M3 4h1M0 5h1M3 5h1M0 6h3M0 7h1M0 8h1" />
        </svg>
      )
    }
  },
  "q": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M1 2h2M0 3h1M3 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 4h1M3 4h1M0 5h1M3 5h1M1 6h3M3 7h1M3 8h2" />
        </svg>
      )
    }
  },
  "r": {
    width: 3,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 3 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M1 2h2M0 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 4h1M0 5h1M0 6h1" />
        </svg>
      )
    }
  },
  "s": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M1 2h3M0 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M1 4h2M3 5h1M0 6h3" />
        </svg>
      )
    }
  },
  "t": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M1 0h1M1 1h1M0 2h3M1 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M1 4h1M1 5h1M2 6h2" />
        </svg>
      )
    }
  },
  "u": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 2h1M3 2h1M0 3h1M3 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 4h1M3 4h1M0 5h1M3 5h1M1 6h2" />
        </svg>
      )
    }
  },
  "v": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 2h1M3 2h1M0 3h1M3 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 4h1M3 4h1M1 5h1M3 5h1M2 6h2" />
        </svg>
      )
    }
  },
  "w": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 2h1M2 2h1M4 2h1M0 3h1M2 3h1M4 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 4h1M2 4h1M4 4h1M0 5h1M2 5h1M4 5h1M1 6h4" />
        </svg>
      )
    }
  },
  "x": {
    width: 5,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 5 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 2h1M4 2h1M1 3h1M3 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M2 4h1M1 5h1M3 5h1M0 6h1M4 6h1" />
        </svg>
      )
    }
  },
  "y": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 2h1M3 2h1M0 3h1M3 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M0 4h1M3 4h1M0 5h1M3 5h1M1 6h3M3 7h1M0 8h3" />
        </svg>
      )
    },
  },
  "z": {
    width: 4,
    render({pixelSize, key, theme}) {
      return (
        <svg key={key} className="Dpad--NoShow" style={getStyle(pixelSize, this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 4 9"
             shapeRendering="crispEdges">
          <path className="Dpad--NoShow" stroke={themes[theme].upper} d="M0 2h4M3 3h1" />
          <path className="Dpad--NoShow" stroke={themes[theme].main} d="M1 4h2M0 5h1M0 6h4" />
        </svg>
      )
    },
  }
}