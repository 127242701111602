import {DefaultAction, DefaultScript} from "../Action";
import {getGenericFail} from "../../formulas/getGenericFail";

export default {
  ...DefaultAction,
  ...DefaultScript,
  name: "Clean",
  description: "Removes any status from an ally",
  icon: "support",
  targetingType: "single-friendly",

  shouldFailOnSingleTarget(casterModel, targetModel) {
    if (targetModel.status === "normal") {
      return {
        failCode: "no-status"
      }
    }
    return null;
  },

  failEventsPerTarget(casterModel, targetModel, failInfo={}) {
    //Maybe add "WHY" here if an upgrade caused it to fail?
    return getGenericFail({targetModel})
  },

  successEventsPerTarget(casterModel, targetModel, allUpgrades) {
    return [
      {
        type: "eventBlippy",
        stateChanges: [
          {
            combatantId: targetModel.id,
            absoluteChanges: {
              status: "normal",
              accuracyModifier: 0
            },
            message: {
              theme: "battlePositiveStatus",
              text: `Status cleared!`
            },
          }
        ]
      }
    ]
  }
}




