import React from 'react'
import Skins from '../_data/Skins'
import MapPersonCanvas from '../Overworld/MapPersonCanvas'
import ArenaLaptop from './ArenaLaptop'

class ArenaPerson extends React.PureComponent {

  constructor(props) {
    super();
    this.noShadow = [
    ].includes(props.skinId || "")
    this.noLaptop = [
      "serverRed",
      "serverBlue",
      "serverPurple",
      "defenseGrid"
    ].includes(props.skinId || "")
  }

  renderLaptop() {
    const {pixelSize, belongsToTeam, laptopFlash, hp, hasEscaped} = this.props;
    if (hasEscaped) { return null }
    return (
      <ArenaLaptop
        hp={hp}
        isInvisible={this.noLaptop}
        pixelSize={pixelSize}
        belongsToTeam={belongsToTeam}
        laptopFlash={laptopFlash || null}
      />
    )
    //return null;
  }

  render() {

    const {cellSize, skinId, transformValue, isInvisible, pixelSize} = this.props;
    const backgroundSkinSrc = Skins[skinId];

    return (
      <div className="PersonContainer" style={{
        opacity: isInvisible ? 0 : 1,
        transition: "opacity 0.3s ease",
        position: "absolute",
        top:0,
        left:0,
        width: cellSize,
        height:cellSize,
        transform: transformValue,
        background: this.noShadow ? "none" : "url(/images/skins/character-shadow.svg)",
        backgroundSize: "100%",
        zIndex:2
      }}>
        <div className="PersonCrop" style={{
          width: cellSize,
          height:cellSize,
          //background: "red",
          overflow: "hidden",
          position: "relative",
          animation: getCharacterAnimation(this.props),
        }}>
          {/*<div*/}
          {/*className="PersonSpritesheet"*/}
          {/*style={{*/}
          {/*position: "absolute",*/}
          {/*left: 0,*/}
          {/*top: 0,*/}
          {/*//background: "navy",*/}
          {/*width: cellSize * 4,*/}
          {/*height: cellSize * 4,*/}
          {/*background: `url(${backgroundSkinSrc}) no-repeat no-repeat`,*/}
          {/*backgroundSize: cellSize * 4,*/}
          {/*transform: getSpritesheetTranslateValue(this.props),*/}
          {/*animation: getAnimationValue(this.props),*/}
          {/*}}*/}
          {/*>*/}
          {/*</div>*/}
          <MapPersonCanvas
            key={backgroundSkinSrc} //Remount when skin changes
            pixelSize={pixelSize}
            skinSrc={backgroundSkinSrc}
            style={{
              transform: getSpritesheetTranslateValue(this.props),
              animation: getAnimationValue(this.props),
            }}
          />

        </div>
        {this.renderLaptop(transformValue)}
      </div>
    );
  }
}

export default ArenaPerson;


function getAnimationValue(props) {

  const {isLockedForExternalProcess, currentActionType, direction} = props;

  //Do not "walk" when frozen
  if (isLockedForExternalProcess) {
    return "none"
  }

  if (currentActionType === "characterWalk") {

    if (direction === "DOWN") {
      return "characterWalkDown 0.5s steps(4) infinite"
    }
    if (direction === "RIGHT") {
      return "characterWalkRight 0.5s steps(4) infinite"
    }
    if (direction === "LEFT") {
      return "characterWalkLeft 0.5s steps(4) infinite"
    }
    if (direction === "UP") {
      return "characterWalkUp 0.5s steps(4) infinite"
    }
  }

  return "none"

}

function getSpritesheetTranslateValue(props) {

  const {/*currentActionType, */direction} = props;

  //Do use this transform value when standing or when frozen (might actually be a walk action)
  //Also default to this if character has no currentAction (result will be just standing in his/her current `direction`)
  //if (currentActionType === "characterStand" || currentActionType === "characterJump" || !currentActionType) {


    //Default to stand!
    if (direction === "DOWN") {
      return "translate3d(0,0,0)"
    }
    if (direction === "RIGHT") {
      return "translate3d(0,-25%,0)"
    }
    if (direction === "LEFT") {
      return "translate3d(0,-75%,0)"
    }
    if (direction === "UP") {
      return "translate3d(0,-50%,0)"
    }
  //}
  //
  // //Default to top left (walking animation will take over)
  // console.log('default!!!', currentActionType)
  // //return "translate3d(0,0,0)"
  return "none"
}

//Jumping, etc
function getCharacterAnimation(props) {

  const {currentActionType} = props;

  if (currentActionType === "characterTada") {
    return "characterTada 0.5s forwards"
  }

  if (currentActionType === "characterShake") {
    return "characterShake 0.5s infinite"
  }

  if (currentActionType === "characterJump") {
    return "characterJump 0.3s"
  }

  return "none"
}