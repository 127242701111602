import { testablePercentChance } from "../../../helpers/testablePercentChance";
import { shuffle } from "../../../helpers/shuffle";

export function confBully({ combatants = [], battleStoryPoints = [], regularDecision, subject }) {
  return speakBeforeMove({
    regularDecision,
    subject,
    battleStoryPoints,
    dialogLines: [
      "Why do these dweebs like conferences so much?",
      "The speakers here are losers!"
    ],
    percentChanceToUseSpeak: 75 //add a random speak this % of the time
  })
}
export function confRecruiter({ combatants = [], battleStoryPoints = [], regularDecision, subject }) {
  return speakBeforeMove({
    regularDecision,
    subject,
    battleStoryPoints,
    dialogLines: [
      "Come on, now. Just sign the paper!",
      "Give me a YES! A simple nod will do!",
      "You shouldn't turn down an opportunity like this!",
    ],
    percentChanceToUseSpeak: 75 //add a random speak this % of the time
  })
}



function speakBeforeMove({ regularDecision, battleStoryPoints, subject, dialogLines, percentChanceToUseSpeak }) {

  if (testablePercentChance(percentChanceToUseSpeak)) {
    return {
      submission: [
        {
          actionId: "speak",
          casterId: subject.id,
          targetIds: [subject.id],
          mergeData: {
            dialogContent: [
              shuffle(dialogLines)[0]
            ]
          }
        },
        {
          //Pass through whatever they were going to do
          actionId: regularDecision.actionId,
          casterId: regularDecision.casterId,
          targetIds: regularDecision.targetIds,
        }
      ],
      updatedBattleStoryPoints: battleStoryPoints
    };
  }

  //Default to regular (with no speak)
  return {
    submission: regularDecision,
    updatedBattleStoryPoints: battleStoryPoints
  };
}