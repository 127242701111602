import {shuffle} from "../../../helpers/shuffle";
export function getStatusCuringDecision({casterModel, teamMembers, enemies, teamItems}) {

  const negativeStatuses = [
    { status: "lag", heal: "item_heal-lag" },
    { status: "nervous", heal: "item_heal-nervous" },
    { status: "memory-leak", heal: "item_heal-memory-leak" },
    { status: "fire", heal: "item_heal-fire" },
    { status: "hacked", heal: "item_heal-hacked" },
    { status: "disabled", heal: "item_heal-disabled" },
  ];

  const alliesWithNegativeStatuses = [casterModel, ...teamMembers].filter(c => negativeStatuses.map(s => s.status).includes(c.status));

  if (alliesWithNegativeStatuses.length === 0) { return null }


  //Maybe just use Clean (why waste an item?)
  if ((casterModel.attacks || []).find(a => a.actionId === "script_clean")) {
    return {
      actionId: "script_clean",
      casterId: casterModel.id,
      targetIds: [shuffle(alliesWithNegativeStatuses)[0].id]
    }
  }

  //Map and filter to only people I have a solution to.
  const solutions = shuffle(alliesWithNegativeStatuses.map(c => {
    const status = c.status;
    const actionIdNeeded = negativeStatuses.find(s => s.status === status).heal;
    return {
      ...c,
      myHealingItem: teamItems.find(i => i.actionId === actionIdNeeded)
    }
  }).filter(d => d.myHealingItem));

  if (solutions.length) {
    const solution = solutions[0].myHealingItem;
    return {
      actionId: solution.actionId,
      casterId: casterModel.id,
      targetIds: [solutions[0].id],
      instanceId: solution.instanceId
    }
  }
  /* TODO: If still no match but we have a "HEAL ANYTHING" kind of item, that could go here: */

  return null
}