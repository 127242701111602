import store from '../init/store'
import {setPlayerDataValue} from "../redux-actions/player-data-actions";

export function equipAttackToCharacter({acquiredMembers, memberId, slotId, actionId}) {
  return acquiredMembers.map(c => {
    if (c.id === memberId) {
      return {
        ...c,
        equippedAttacks: {
          ...c.equippedAttacks,
          [slotId]: actionId
        }
      }
    }
    return c;
  });
}

export function equipAttackToCharacerInRedux(memberId, slotId, actionId) {
  const updatedMembers = equipAttackToCharacter({
    acquiredMembers: store.getState().playerData.acquiredMembers,
    memberId,
    slotId,
    actionId
  });
  setPlayerDataValue({
    acquiredMembers: updatedMembers
  })
}