import { Howl } from "howler";
import Music from "./_music";
import Sfx from "./_sfx";
import store from "../init/store.js";

let sfxHowls = {};
Object.keys(Sfx).forEach(key => {
  sfxHowls[key] = new Howl(Sfx[key]);
});

export const audioManager = {
  //SFX
  currentSong: null,
  isFirstSongTrigger: false, //default to false
  currentSongKey: "",

  playSong(songKey) {

    if (!songKey || !Music[songKey]) {
      //default to this one:
      //console.warn("no song found");

      //Stop the song if we don't have one to play.
      if (this.currentSong) {
        this.currentSong.stop();
        this.currentSongKey = "";
      }
      return
    }


    //If this song is already playing, bail out so it doesnt start over!
    if (this.currentSongKey === songKey) {
      return;
    }

    //Stop any old song that is playing
    if (this.currentSong) {
      this.currentSong.stop();
      this.currentSong = null; //delete it!
    }

    //Play the new one!
    this.currentSongKey = songKey;

    const songConfig = Music[songKey];
    this.currentSong = new Howl(songConfig);

    //We might be muted, so maybe shut off the volume. (we still want the song to play in the background)
    if (store.getState().global.audioMode !== "full") {
      this.currentSong.volume(0);
    }

    //Play the song!
    if (songConfig.sprite) { //If we defined a sprite, use that config. always "trimmed"
      this.currentSong.play("trimmed");
    } else {
      this.currentSong.play();
    }


  },
  stopSong() {
    if (this.currentSong) {
      this.currentSong.stop();
      this.currentSongKey = "";
    }
  },

  changeSongVolume(status) { //"on" | "off"
    if (this.currentSong) {
      if (status === "off") {
        this.currentSong.volume(0);
      }
      if (status === "on" && Music[this.currentSongKey]) {
        //NOTE: need to remember old song's initial volume!
        this.currentSong.volume(Music[this.currentSongKey].volume || 1);
      }
    }
  },

  sfxHowls: sfxHowls,

  //SFX bank
  playSfx(sfxKey) {
    //Play if mode is set to `sfx` or `full`
    if (store.getState().global.audioMode !== "off") {
      sfxHowls[sfxKey].play();
    }
  }
};
