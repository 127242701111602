//more of a helper than an alg.
import {getPercentageOfMaxHp} from "./getPercentageOfMaxHp";
import {getCeilingHpGain} from "./getCeilingHpGain";

export function getCombatantHpRaise(combatantModel, number, metricType="points") {

  let hpChange = number;

  if (metricType === "percent") {
    hpChange = getPercentageOfMaxHp(combatantModel.maxHp, hpChange);
  }

  return getCeilingHpGain(combatantModel.maxHp, combatantModel.hp, hpChange);
}