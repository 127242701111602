import {createPrefixedId} from '../../helpers/make-id'
import {setPlayerDataValue} from "../../redux-actions/player-data-actions";
import store from '../../init/store'

export function addNewItemToInventory(itemId, currentInventory) {
  return [
    ...currentInventory,
    {actionId: itemId, instanceId: createPrefixedId("inv", 5)}
  ]
}

export function addNewItemToInventoryInRedux(itemId) {
  setPlayerDataValue({
    inventory: addNewItemToInventory(itemId, store.getState().playerData.inventory)
  })
}