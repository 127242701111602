import {DefaultAction} from "../Action";
import {getDangerMeterReset} from "../../formulas/getDangerMeterReset";

export default {
  ...DefaultAction,
  name: "Team Deadline",
  description: "Inspire your entire team with Deadlines",
  icon: "super-support",
  targetingType: "all-friendlies",

  shouldFailOnSingleTarget(casterModel, targetModel) {
    return false
  },

  successEventsPerAllTargets({casterModel, targetModels, allUpgrades}) {

    return [
      //TODO: animation?

      {
        type: "eventDialogBox",
        textArray: ["Full team Deadline!"],
      },
      //set all allies to deadline
      {
        type: "eventBlippy",
        stateChanges: targetModels.map(t => {
          return {
            combatantId: t.id,
            absoluteChanges: {
              status: "deadline"
            },
          }
        }),
        delayFramesAfter: 30
      },
      //schedule their recoveries...
      ...targetModels.map(targetModel => {
        return {
          type: "eventSchedule",
          roundsFromNow: 3,
          combatantId: targetModel.id,
          event: {
            actionId: "natural_end-deadline",
            casterId: targetModel.id,
            targetIds: [targetModel.id]
          },
          delayFramesAfter: 1
        }
      }),

      getDangerMeterReset({casterModel})
    ]
  }
}

