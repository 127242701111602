import React from 'react'
import {connect} from 'react-redux'
import JournalPopup from './JournalPopup'
import HowToPlayMessage from './HowToPlayMessage'
import LoginReminderMessage from './LoginReminderMessage'
import OverworldTextMessageBox from './Overworld/OverworldTextMessageBox'
import BattleWinPopup from './BattleWinXpPopup'

class OverworldMessages extends React.Component {

  state = {
    devMessageVisible: false
  };


  // componentWillUpdate(newProps) {
  //   if (newProps.dialogMessageContent && !this.props.dialogMessageContent) {
  //     this.setState({
  //       devMessageVisible: true
  //     })
  //   }
  // }

  //This just simulates the ANIMATE OUT -> TRIGGER
  // handleCloseMessage = () => {
  //   this.setState({
  //     devMessageVisible: false
  //   }, () => {
  //     let frameCount = 0;
  //     const step = () => {
  //       frameCount++;
  //       if (frameCount > 30) {
  //
  //         setOverworldValue({
  //           dialogMessageContent: null,
  //           externalEventTrigger: getNextExternalEventTrigger("OVERWORLD-MESSAGES__ANIMATED-OUT")
  //         })
  //
  //       } else {
  //         requestAnimationFrame(step)
  //       }
  //     };
  //     requestAnimationFrame(step);
  //
  //   })
  // };

  render() {

    const {dialogMessageContent, journalPopup, isHowToPlayMessageOpen, battleWinPopup, pixelSize, showLoginReminder} = this.props;

    if (dialogMessageContent) {
      return <OverworldTextMessageBox />
    }

    if (isHowToPlayMessageOpen) {
      return <HowToPlayMessage pixelSize={pixelSize} />
    }

    if (journalPopup) {
      return <JournalPopup />
    }

    if (battleWinPopup) {
      return <BattleWinPopup />
    }

    if (showLoginReminder) {
      return <LoginReminderMessage pixelSize={pixelSize} />
    }

    return null;
  }
}

export default connect((state, props) => {
  return {
    pixelSize: state.global.pixelSize,
    isHowToPlayMessageOpen: state.overworld.isHowToPlayMessageOpen,
    journalPopup: state.overworld.journalPopup,
    dialogMessageContent: state.overworld.dialogMessageContent,
    battleWinPopup: state.overworld.battleWinPopup,
    showLoginReminder: state.overworld.showLoginReminder
  }
})(OverworldMessages)