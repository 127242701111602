import React from 'react';
import StaticTextLine from '../StaticTextLine'
import BoxDiv from '../interface/BoxDiv'
import BoxDivBody from "../interface/BoxDivBody";
import Icon from '../interface/Icon'

const BattleUseActionMessage = React.memo(function PixelArtMenuOption(props) {
  /* only rerenders if props change */


  const {pixelSize, icon, isSelected, isDisabled} = props;

  return (
    <BoxDiv pixelSize={props.pixelSize} style={{display: "inline-block", position: "relative"}}>
      <BoxDivBody
        pixelSize={pixelSize}
        style={{
        display: "flex",
        alignItems: "center",
        paddingTop: pixelSize * 2,
        paddingBottom: 0,
        paddingLeft: pixelSize * 3,
        paddingRight: pixelSize * 3,
      }}>
        {
          Boolean(icon) && (
            <div style={{
              marginRight: pixelSize * 3,
              position: "relative",
              top: pixelSize * -1
            }}>
              <Icon
                pixelSize={pixelSize}
                category={icon}
              />
            </div>
          )
        }
        <StaticTextLine
          pixelSize={props.pixelSize}
          text={props.text}
          theme={isDisabled ? "disabled" : "dark"}
        />
        {
          isSelected && (
            <div style={{
              position: "absolute",
              left:pixelSize,
              top:pixelSize,
              bottom:pixelSize,
              right:pixelSize,
              boxShadow: `0 0 0 ${pixelSize}px #07bfdd, 0 0 0 ${pixelSize*2}px #7de2f2, 0 0 0 ${pixelSize*3}px #07bfdd`
            }}>
            </div>
          )
        }

      </BoxDivBody>
    </BoxDiv>
  )


});

export default BattleUseActionMessage;