import React from 'react';
import StaticTextLine from '../StaticTextLine'
import Icon from '../interface/Icon'

const PixelArtMenuOption = React.memo(function PixelArtMenuOption(props) {
  /* only rerenders if props change */


  const {pixelSize, icon, isSelected, isDisabled, renderOptionRight, renderCustomOptionTheme, centered} = props;

  return (
    <div style={{
      position: "relative",
      display: "flex",
      height: pixelSize * 16,
      alignItems: "center",
      paddingLeft: pixelSize * 4,
      justifyContent: centered ? "center" : ""
    }}>
        {
          Boolean(icon) && (
            <div style={{
              marginRight: pixelSize * 3,
              position: "relative",
              top: pixelSize * -1,
              opacity: isDisabled ? 0.4 : 1,
            }}>
              <Icon
                pixelSize={pixelSize}
                category={icon}
              />
            </div>
          )
        }
        <StaticTextLine
          pixelSize={props.pixelSize}
          text={props.text}
          theme={function() {

            if (renderCustomOptionTheme) {
              const customTheme = renderCustomOptionTheme(props);
              if (customTheme) { return customTheme }
            }

            //support ability to pass in own theme
            if (props.textLineTheme) { return props.textLineTheme }
            return isDisabled ? "disabled" : "dark"
          }()}
        />
      {
        renderOptionRight && (
          <div style={{position: "absolute", right:0, top:0, bottom:0, display: "flex", alignItems: "center"}}>
            {renderOptionRight(props)}
          </div>
        )
      }

        {
          isSelected && (
            <div style={{
              position: "absolute",
              left:pixelSize,
              top:pixelSize,
              bottom:pixelSize,
              right:pixelSize,
              boxShadow: `0 0 0 ${pixelSize}px #07bfdd, 0 0 0 ${pixelSize*2}px #7de2f2, 0 0 0 ${pixelSize*3}px #07bfdd`
            }}>
            </div>
          )
        }

    </div>
  )


});

export default PixelArtMenuOption;