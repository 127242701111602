import React from 'react'
import { connect } from 'react-redux'
import {setGlobalValue} from "./redux-actions/global-actions";
import store from './init/store'
import {getMergeInteractivesAfterBattleResult} from "./Overworld/getMergeInteractivesAfterBattleResult";
import {setOverworldValue} from "./redux-actions/overworld-actions";
import {getUpdatedPlayerDataAfterBattle} from './getUpdatedPlayerDataAfterBattle'
import {setPlayerDataValue} from "./redux-actions/player-data-actions";
import {chapter} from "./ChapterGetter";
import {generateBattle} from "./Battle7/generateBattle";
import ArenaContainer from "./Battle7/ArenaContainer";
import StoryBattles from './Battle7/battle-data/story-battles/StoryBattles'
//import {devInitialCombatants} from './Battle7/INITIAL-COMBATANTS'
import {getPlayerBattlePartyFromRedux} from './Battle7/getPlayerBattlePartyFromRedux'
import DevSetupBattle from './Battle7/dev-setup-battle/DevSetupBattle'
import {getQueryVariable} from "./helpers/get-query-variable";
import {audioManager} from "./audio/audioManager";
import {getAddedCompoundStoryPoints} from "./story-points/getAddedCompoundStoryPoints";
import {handleTrackEvent} from "./loading/handleTrackEvent";

//This Component is in charge of data flow in and out of battle.
class BattleContainer extends React.Component {

  constructor(props) {
    super(props);
    const battleConfigurationArray = chapter.data.battles[store.getState().overworld.battle_currentBattleId];
    this.battleEnemies = generateBattle(battleConfigurationArray, chapter.data.combatants);

    this.state = {
      isBattleEditForm: Boolean(getQueryVariable("battle")),
      combatants: [
        ...getPlayerBattlePartyFromRedux(),
        ...this.battleEnemies.map((c, i) => ({
          ...c,
          id: `enemy${i}`,
          belongsToTeam: "two",
          //activeUpgradeId: "upgrade_item-turn" //WATCH OUT - THIS AFFECTS ALL BATTLES IN STORY
        })),
      ],
      //combatants: devInitialCombatants, //dev opt in to mock data by including this line
      teamOneItemsUsed: [], //We build this up as the battle goes on so we can remove from global inventory afterwards (if we win)
    }
  }

  componentDidMount() {

    let musicSongKey = "music_regularBattle";
    //Maybe use custom battle tune
    let enemyWantsToUseSong = this.battleEnemies.find(e => e.useMusic);

    if (enemyWantsToUseSong && enemyWantsToUseSong.useMusic) {
      musicSongKey = enemyWantsToUseSong.useMusic;
    }

    //If lead + useMusic, make sure their music wins
    const leadEnemyWithMusic = this.battleEnemies.find(e => e.useMusic && e.isLeadEnemy);
    if (leadEnemyWithMusic) {
      musicSongKey = leadEnemyWithMusic.useMusic;
    }

    audioManager.playSong(musicSongKey) //Kick off the battle tune!
  }

  handleSetupFromForm = ({combatants=[], useArena, bossPreset}) => {

    if (bossPreset.length) {
      //Remove any "team two" people.
      combatants = combatants.filter(c => c.belongsToTeam !== "two");
      //Look up battle
      const storyBattleEnemies = StoryBattles[bossPreset];
      combatants = [
        ...combatants,
        ...storyBattleEnemies
      ]
    }

    setOverworldValue({
      battle_arenaId: useArena
    });

    //Give speed boost to hero //NOTE: ONLY FOR EDITOR FORM!!!
    combatants = combatants.map(c => {
      if (c.id === "memberA") {
        return {
          ...c,
          speedModifier: 1
        }
      }
      return c;
    });

    //Support custom battle song // FOR EDITOR FORM ONLY!!!!
    const enemyWantsToUseSong = combatants.find(e => e.useMusic);
    if (enemyWantsToUseSong && enemyWantsToUseSong.useMusic) {

      let musicSongKey = enemyWantsToUseSong.useMusic;

      //If lead + useMusic, make sure their music wins
      const leadEnemyWithMusic = combatants.find(e => e.useMusic && e.isLeadEnemy);
      if (leadEnemyWithMusic) {
        musicSongKey = leadEnemyWithMusic.useMusic;
      }

      audioManager.playSong(musicSongKey)
    }

    this.setState({
      isBattleEditForm: false,
      combatants
    })
  };


  handleBattleEnd = (resultType, endingCombatants, endingTeamOneItems) => {

    //console.log('tracking', resultType)


    //console.log(this.state.teamOneItemsUsed) //Tells us the instanceId, but not the item id. (only for tracking would we care)

    //SIDE EFFECT: Track what happened in Firebase
    handleTrackEvent({
      eventType: "battleResult",
      battleId: store.getState().overworld.battle_currentBattleId,
      battleResult: resultType,
      playerLevel: endingCombatants.find(c => c.belongsToTeam === "one").level
    });
    // END SIDE EFFECT

    //valid resultTypes: "win" | "lose" | "bail"

    const {
      returnFromBattleHeroProperties,
      returnFromBattleInteractives,
      battle_addStoryPointOnWin,
    } = store.getState().overworld;
    const {steppedIntoSpace} = returnFromBattleHeroProperties;

    //Figure out what the array of people should be when we return from battle.
    const newMergeInteractivesAfterBattle = getMergeInteractivesAfterBattleResult({
      resultType,
      steppedIntoSpace,
      interactives: returnFromBattleInteractives
    });

    //Make updates to Redux player state.
    const updatedPlayerData = getUpdatedPlayerDataAfterBattle({
      resultType,
      endingCombatants,
      endingTeamOneItems,
      addStoryPointOnWin: battle_addStoryPointOnWin,
      playerData: store.getState().playerData,
      teamOneItemsUsed: this.state.teamOneItemsUsed,
    });

    setPlayerDataValue(updatedPlayerData);

    //console.log(this.battleEnemies)
    const battleResults = {
      enemies: this.battleEnemies,
      //Find if we need to add any compound story points
      //Bus any compound story points to be added in the Results even phase
      addCompoundStoryPointsAfterBattle: getAddedCompoundStoryPoints(updatedPlayerData.acquiredStoryPoints) || []
    };
    //^^ This looks like:
    // const mockResults = {
    //   enemies: [
    //     {level: 1, dropsItems: ["item_whatever"], dropsCredits: 35, },
    //     {level: 1, dropsCredits: 15},
    //     {level: 1},
    //   ]
    // };




    //Apply the updated list to Redux state so `OverworldContainer` will use it to correctly mount the people
    setOverworldValue({
      returnFromBattleInteractives: newMergeInteractivesAfterBattle,
      battleResults: resultType === "win" ? battleResults : null,
      addExplodingSpaceAtHeroPosition: steppedIntoSpace && resultType === "win",
    });

    //Transition back to Overworld
    setGlobalValue({
      gameArea: "overworld"
    })
  };

  handleTrackTeamOneItemUsage = instanceId => {
    this.setState(state => {
      return {
        teamOneItemsUsed: [...state.teamOneItemsUsed, instanceId]
      }
    })
  };

  render() {


    if (this.state.isBattleEditForm) {
      return <DevSetupBattle onDone={this.handleSetupFromForm} />
    }

    return (
      //"B7"
      <ArenaContainer
        pixelSize={this.props.pixelSize}
        initialCombatants={this.state.combatants}
        arenaId={this.props.arenaId}
        handleBattleExit={this.handleBattleEnd}
        handleTrackTeamOneItemUsage={this.handleTrackTeamOneItemUsage}
      />
    )
  }
}

export default connect((state, props) => {
    return {
        arenaId: state.overworld.battle_arenaId || "office",
        pixelSize: state.global.pixelSize
    }
})(BattleContainer)