import {shuffle} from "../../../helpers/shuffle";

export function disruptServer({ combatants = [], battleStoryPoints = [], regularDecision, subject, actionId, dialog }) {


  const enemyIdsWithStatuses = combatants.filter(c => c.belongsToTeam === "one" && c.hp > 0 && c.status !== "normal").map(c => c.id);
  const enemyIdsWithNormalStatus = combatants.filter(c => c.belongsToTeam === "one" && c.hp > 0 && c.status === "normal").map(c => c.id);

    //const targetId = shuffle(enemyIdsWithNormalStatus)[0];

    //If all 3 enemies have statuses, throw up a Promise
    if (enemyIdsWithStatuses.length === 3) {
      return {
        submission: [
          {
            actionId: "script_promise",
            casterId: subject.id,
            targetIds: combatants.filter(c => c.belongsToTeam === "one").map(c => c.id)
          },
        ],
        updatedBattleStoryPoints: battleStoryPoints
      };
    }

    //If only 1 enemy doesnt have a status use DDoS
    if (enemyIdsWithNormalStatus.length === 1) {
      return {
        submission: [
          {
            actionId: "script_ddos",
            casterId: subject.id,
            targetIds: [ enemyIdsWithNormalStatus[0] ],
          },
        ],
        updatedBattleStoryPoints: battleStoryPoints
      };
    }

    //If 0 or 1 enemies have status, use DISABLE or HACK
    if (enemyIdsWithNormalStatus.length > 1) {
      return {
        submission: [
          // {
          //   actionId: "speak",
          //   casterId: subject.id,
          //   targetIds: [subject.id],
          //   mergeData: {
          //     dialogContent: [
          //       "Operation: Thwart security vulnerability."
          //     ]
          //   }
          // },
          {
            actionId: shuffle(["script_hack", "script_disable"])[0],
            casterId: subject.id,
            targetIds: [ shuffle(enemyIdsWithNormalStatus)[0] ],
          },
        ],
        updatedBattleStoryPoints: battleStoryPoints
      };
    }

  //Default to Push
  const aliveEnemyIds = combatants.filter(c => c.belongsToTeam === "one" && c.hp > 0).map(c => c.id);
  return {
    submission: [
      {
        actionId: "script_push",
        casterId: subject.id,
        targetIds: [ shuffle(aliveEnemyIds)[0] ],
      },
    ],
    updatedBattleStoryPoints: battleStoryPoints
  };
}

export function dpsServer({ combatants = [], battleStoryPoints = [], regularDecision, subject, actionId, dialog }) {

  const aliveEnemyIds = combatants.filter(c => c.belongsToTeam === "one" && c.hp > 0).map(c => c.id);
  const randomSingleTargetId = shuffle(aliveEnemyIds)[0];

  //if all 3 enemies are alive, pick one to auto kill
  if (aliveEnemyIds.length === 3) {
    return {
      submission: [
        {
          actionId: "speak",
          casterId: subject.id,
          targetIds: [subject.id],
          mergeData: {
            dialogContent: [
              "Operation: Remove security vulnerability."
            ]
          }
        },
        {
          actionId: "natural_auto-kill",
          casterId: subject.id,
          targetIds: [randomSingleTargetId],
        },
      ],
      updatedBattleStoryPoints: battleStoryPoints
    };
  }

  //if 2 or 1 are alive, either maybe BUG / TEAM FOREACH / SLICE MK II
  const defaultActionId = shuffle(["script_bug", "script_slice-mk-ii", "super_team-foreach"])[0];
  return {
    submission: [
      {
        actionId: defaultActionId,
        casterId: subject.id,
        targetIds: defaultActionId === "script_slice-mk-ii" ? [randomSingleTargetId] : aliveEnemyIds
      },
    ],
    updatedBattleStoryPoints: battleStoryPoints
  };
}

export function supportServer({ combatants = [], battleStoryPoints = [], regularDecision, subject, actionId, dialog }) {
  const friendlyIdsWithStatuses = combatants.filter(c => c.belongsToTeam === "two" && c.hp > 0 && c.status !== "normal").map(c => c.id);

  //If 2 or more allies have non-normal statuses, run Team Clean
  if (friendlyIdsWithStatuses.length >= 2) {
    return {
      submission: [
        {
          actionId: "speak",
          casterId: subject.id,
          targetIds: [subject.id],
          mergeData: {
            dialogContent: [
              "Operation: Server Restore."
            ]
          }
        },
        {
          actionId: "super_team-clean",
          casterId: subject.id,
          targetIds: combatants.filter(c => c.belongsToTeam === "two").map(c => c.id),
        },
      ],
      updatedBattleStoryPoints: battleStoryPoints
    };
  }


  //If all 3 statuses are normal, run BLOCK on all teammates
  const friendlyIdsWithNormalStatus = combatants.filter(c => c.belongsToTeam === "two" && c.hp > 0 && c.status === "normal").map(c => c.id);
  if (friendlyIdsWithNormalStatus.length === 3) {
    return {
      submission: [
        {
          actionId: "speak",
          casterId: subject.id,
          targetIds: [subject.id],
          mergeData: {
            dialogContent: [
              "Operation: Server Safety."
            ]
          }
        },
        {
          actionId: "script_block",
          casterId: subject.id,
          targetIds: friendlyIdsWithNormalStatus,
        },
      ],
      updatedBattleStoryPoints: battleStoryPoints
    };
  }

  //If alone, revive one person
  const deadFriendIds = combatants.filter(c => c.belongsToTeam === "two" && c.hp <= 0).map(c => c.id);
  if (deadFriendIds.length === 2) {
    const reviveId = shuffle(deadFriendIds)[0];
    return {
      submission: [
        {
          actionId: "script_restart",
          casterId: subject.id,
          targetIds: [ reviveId ],
        },
      ],
      updatedBattleStoryPoints: battleStoryPoints
    };
  }

  //Default to regular Pop
  const aliveEnemyIds = combatants.filter(c => c.belongsToTeam === "one" && c.hp > 0).map(c => c.id);
  return {
    submission: [
      {
        actionId: "script_pop",
        casterId: subject.id,
        targetIds: [ shuffle(aliveEnemyIds)[0] ],
      },
    ],
    updatedBattleStoryPoints: battleStoryPoints
  };
}