//"Does the person's current interaction give me a Story Point I don't already have?"
export function interactiveShouldHaveQuestArrow(person={}, interactiveActions, acquiredStoryPoints) {

  //console.log(person, interactiveActions, acquiredStoryPoints)

  if (!interactiveActions) { return false } //For other tests

  const personInteractions = interactiveActions[person.id] || [];
  const matchingScenario = personInteractions.find(i => i.id === person.scenarioId);

  if (matchingScenario && matchingScenario.interaction) {

    const addStoryPointEvent = matchingScenario.interaction.find(event => event.type === "addStoryPoint");
    if (addStoryPointEvent && !acquiredStoryPoints.includes(addStoryPointEvent.addStoryPoint)) {
      return true;
    }

  }


  return false
}