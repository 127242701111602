import {DefaultAction, DefaultItem} from "../Action";

function createStickerItem(properties={}) {
  return {
    ...DefaultAction,
    ...DefaultItem,

    name: properties.name,
    description: properties.description,
    targetingType: "single-friendly",
    sellValue: 15,

    successEventsPerTarget(casterModel, targetModel, allUpgrades) {
      return [
        {
          type: "eventAnimation",
          animationId: "animationOpenItem",
          subjects: [targetModel.id],
          haltQueueUntilDone: true,
        },
        {
          type: "eventBlippy",
          stateChanges: [
            {
              combatantId: targetModel.id,
              relativeChanges: {
                [properties.statKey]: properties.statChange
              },
              message: {
                theme: "battlePositiveHp",
                text: properties.text
              },
            }
          ]
        }
      ]
    }
  }
}

export const AttackSticker = createStickerItem({
  name: "Attack Sticker",
  description: "Increases attack for the rest of the battle",
  statKey: "attackModifier",
  statChange: 3,
  text: "+3 Attack",
});

export const DefenseSticker = createStickerItem({
  name: "Defense Sticker",
  description: "Increases defense for the rest of the battle",
  statKey: "defenseModifier",
  statChange: 3,
  text: "+3 Defense",
});

export const SlipperySticker = createStickerItem({
  name: "Slippery Sticker",
  description: "Makes oncoming attacks more likely to miss you",
  statKey: "slipperyModifier",
  statChange: 3,
  text: "+3 Harder to Hit",
});