import React from 'react'
import {waitFrames} from "../../../helpers/waitFrames";
import RunAnimation from './RunAnimation'

class AnimationSqlInjection extends React.Component {

  state = {
    animationsFinished: 0,
    characterJumpsFinished: 0,
  };

  componentDidMount() {
    const targets = this.props.event.subjects;

    waitFrames(30, () => {
      targets.forEach(id => {
        this.props.handleCombatantStateChange([
          {
            combatantId: id,
            absoluteChanges: {
              animation: "characterShake",
            }
          }
        ]);
        waitFrames(30, () => {
          this.props.handleCombatantStateChange([
            {
              combatantId: id,
              absoluteChanges: {
                animation: "none",
              }
            }
          ]);
          this.setState(state => ({characterJumpsFinished: state.characterJumpsFinished+1}))
        })
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const targets = this.props.event.subjects;
    const wasFinished = (
      prevState.animationsFinished === targets.length &&
      prevState.characterJumpsFinished === targets.length
    );
    const nowFinished = (
      this.state.animationsFinished === targets.length &&
      this.state.characterJumpsFinished === targets.length
    );
    if (!wasFinished && nowFinished) {
      this.props.onEventDone()
    }
  }

  render() {
    const {pixelSize, event, positions} = this.props;
    return event.subjects.map((subjectId, i) => {
      return (
        <RunAnimation
          key={subjectId}
          pixelSize={pixelSize}
          left={positions[subjectId].leftPosition}
          top={positions[subjectId].topPosition}
          widthOfSpriteSheet={288}
          frames={9}
          duration="0.7s"
          onDone={() => {
            this.setState(state => ({animationsFinished: state.animationsFinished+1}))
          }}
          renderPaths={() => (
            <React.Fragment>
              <path stroke="#ffffff" d="M264 3h2M264 4h2M108 6h8M108 7h8M278 7h1M283 9h2M259 10h1M283 10h2" />
              <path stroke="#5c4438" d="M75 5h10M75 6h1M84 6h1M75 7h1M84 7h1M43 10h10M43 11h1M52 11h1M43 12h1M52 12h1M11 14h10M11 15h1M20 15h1M11 16h1M20 16h1" />
              <path stroke="#ff4848" d="M107 5h10M107 6h1M116 6h1M107 7h1M116 7h1" />
              <path stroke="#d6c1bd" d="M139 5h8M171 5h2M234 5h2M139 6h1M171 6h1M234 6h1M139 7h1M171 7h1M234 7h1" />
              <path stroke="#e2bfb5" d="M173 5h2M203 5h2M173 6h2M203 6h2M145 7h2M173 7h2M203 7h2M145 8h2M145 9h2" />
              <path stroke="#e7b187" d="M76 6h8M76 7h8M44 11h8M44 12h8M12 15h8M12 16h8" />
              <path stroke="#e8b3a4" d="M140 6h7M172 6h1M235 6h1M140 7h5M172 7h1M235 7h1" />
              <path stroke="#fbc5b5" d="M147 7h2M175 7h2M205 7h2M147 8h2M173 8h4M203 8h4M147 9h2M173 9h4M203 9h4M145 10h2M173 10h2M203 10h2M145 11h2M173 11h2M203 11h2M145 12h2M173 12h2M203 12h2M145 13h2M173 13h2M203 13h2M145 14h2M173 14h2M203 14h2" />
              <path stroke="#463025" d="M75 8h1M84 8h1M75 9h1M84 9h1M75 10h1M84 10h1M75 11h1M84 11h1M75 12h1M84 12h1M43 13h1M52 13h1M75 13h1M84 13h1M43 14h1M52 14h1M43 15h1M52 15h1M43 16h1M52 16h1M11 17h1M20 17h1M43 17h1M52 17h1M11 18h1M20 18h1M43 18h1M52 18h1M11 19h1M20 19h1M11 20h1M20 20h1M11 21h1M20 21h1M11 22h1M20 22h1" />
              <path stroke="#ffd7b8" d="M76 8h8M44 13h8M12 17h8" />
              <path stroke="#ff0000" d="M107 8h1M116 8h1M107 9h1M116 9h1M107 10h1M116 10h1M107 11h1M116 11h1M107 12h1M116 12h1M107 13h1M116 13h1" />
              <path stroke="#ecf0e9" d="M108 8h8M108 9h8M108 10h8M108 11h8M108 12h8M108 13h8" />
              <path stroke="#fff9f8" d="M139 8h1M171 8h1M234 8h1M139 9h1M171 9h1M234 9h1M139 10h1M148 10h1M171 10h1M234 10h1M139 11h1M148 11h1M171 11h1M234 11h1M139 12h1M148 12h1M171 12h1M234 12h1M139 13h1M148 13h1M171 13h1M234 13h1M139 14h6M148 14h1M171 14h2M234 14h2M148 15h1M148 16h1M176 16h1M206 16h1" />
              <path stroke="#fbb8a5" d="M140 8h5M172 8h1M235 8h1" />
              <path stroke="#c17e56" d="M76 9h5M82 9h1M76 10h6M83 10h1M76 11h8M76 12h8M76 13h8M44 14h5M50 14h1M44 15h6M51 15h1M44 16h8M44 17h8M12 18h5M18 18h1M44 18h8M12 19h6M19 19h1M12 20h8M12 21h8M12 22h8" />
              <path stroke="#3f3f74" d="M81 9h1M83 9h1M82 10h1M49 14h1M51 14h1M50 15h1M17 18h1M19 18h1M18 19h1" />
              <path stroke="#f2b09d" d="M140 9h5M172 9h1M235 9h1M140 10h5M172 10h1M235 10h1M140 11h5M172 11h1M235 11h1M140 12h5M172 12h1M235 12h1M140 13h5M172 13h1M235 13h1" />
              <path stroke="#fbfbfb" d="M147 10h1M175 10h2M205 10h2M147 11h1M175 11h2M205 11h2M147 12h1M175 12h2M205 12h2M147 13h1M175 13h2M205 13h2M147 14h1M175 14h2M205 14h2M147 15h1M175 15h2M205 15h2M147 16h1M175 16h1M205 16h1" />
              <path stroke="#1f1713" d="M75 14h10M43 19h10M11 23h10" />
              <path stroke="#892828" d="M107 14h10" />
            </React.Fragment>
          )}
        />
      )
    })
  }
}

export default AnimationSqlInjection;