import React from 'react'
import BoxDiv from '../interface/BoxDiv'
import Icon from '../interface/Icon'

export default class EnemyItems extends React.PureComponent {

  render() {

    const {stableItems, buggedItems, pixelSize} = this.props;

    let itemTypes = [];
    for(let i=1; i<=buggedItems; i++) {
      itemTypes.push("buggedItem")
    }
    for(let i=1; i<=stableItems; i++) {
      itemTypes.push("enemyItem")
    }

    // if (itemTypes.length === 0) {
    //   return null
    // }

    // let labelIconCategory = "noEnemyItemsLabel";
    // if (itemTypes.length > 0) {
    //   labelIconCategory = itemTypes.length === 1 ? "oneEnemyItemLabel" : "enemyItemsLabel";
    // }
    let labelIconCategory = itemTypes.length > 0 ? "enemyItemsLabel" : "noEnemyItemsLabel";


    return (
      <BoxDiv
        pixelSize={pixelSize}
        style={{
          position: "absolute", top: pixelSize * -2, right: pixelSize * -2
        }}
      >
        <div style={{
          paddingTop: pixelSize,
          paddingLeft: pixelSize,
          paddingRight: pixelSize,
          paddingBottom: pixelSize*2,
          borderTop: `${pixelSize}px solid #e3e2e9`,
          background: "#f8f8f8",
          display: "flex",
          alignItems: "center",
          height: pixelSize * 11
        }}>
          {
            itemTypes.length > 0 && itemTypes.map((itemType, i) => {
              return (
                <div key={i} style={{marginLeft: pixelSize, marginRight: pixelSize }}>
                  <Icon
                    pixelSize={pixelSize}
                    category={itemType}
                  />
                </div>
              )
            })
          }
          <div style={{marginLeft: pixelSize*2, marginRight: pixelSize*2}}>
            <Icon pixelSize={pixelSize} category={ labelIconCategory }/>
          </div>
        </div>
      </BoxDiv>

    )
  }
}
