import {getWinningTeam} from "./getWinningTeam";
import {getNextTurn} from "./getNextTurn";
import {waitFrames} from "../helpers/waitFrames";
import {audioManager} from "../audio/audioManager";
import {getLeadEnemy} from './getLeadEnemy'

//SPIT OUT THE NEXT "EVENT" that happens, or request input from external

class BattleModel {
  constructor(combatants = [], emitListener=function(){}) {
    this.combatants = combatants;
    this.emitListener = emitListener;


    this.pendingSignals = [];

    this.schedule = {
      // "1": [
      //   {
      //     combatantId: "memberA",
      //     mergeData: {
      //     },
      //     actionId: "natural_end-deadline",
      //     targetIds: ["memberA"]
      //   },
        // {
        //   combatantId: "memberB",
        //   mergeData: {
        //   },
        //   actionId: "natural_end-focus",
        //   targetIds: ["memberB"]
        // }
      //],
      // "2": [
      //   {
      //     combatantId: "memberA",
      //     mergeData: {
      //     },
      //     actionId: "natural_end-deadline",
      //     targetIds: ["memberA"]
      //   }
      // ]
    };
    this.roundNumber = 0;
    this.waitingForSubmissionQueueTurns = 0;

    this.turnOrder = [];
    this.currentRoundArray = [];
    this.currentRoundSubmissions = [];
    this.stage = "intro"; //"intro" | "body" | "ending"
    this.previousTurn = null; //What was the previously piped out instruction for WHAT'S NEXT
    this.summary = null;
  }

  setupNewRound() {
    //console.log('NEW ROUND?')
    this.roundNumber += 1;
    this.generateTurnOrder();
    this.currentRoundArray = [...this.turnOrder];
    this.currentRoundSubmissions = [];
  }

  //A story battle has submitted an array of things that will be submitted right away, so ignore this
  setQueuedSubmissions(n) {
    this.waitingForSubmissionQueueTurns = n;
  }

  updateCombatants(newCombatants) {
    this.combatants = [...newCombatants];
  }

  updateSummary(newSummary) {
    this.summary = {...newSummary}
  }

  addScheduledEvent(roundsFromNow, combatantId, payload) {
    let newSchedule = {...this.schedule};

    let destinationRound = String(this.roundNumber + roundsFromNow);
    newSchedule[destinationRound] = newSchedule[destinationRound] || [];
    newSchedule[destinationRound].push({
      combatantId,
      ...payload
    });
    this.schedule = {...newSchedule};
  }


  next() {

    //The ArenaContainer has built up an array of events that will auto fire. We are waiting for these
    //before trying to decide what should go next.
    if (this.waitingForSubmissionQueueTurns > 0) {
      this.waitingForSubmissionQueueTurns -= 1;
      this.emit({ type: "auto-queue" })
      return;
    }


    if (this.pendingSignals.length > 0 ) {
      const topSignal = this.pendingSignals[0];
      this.pendingSignals = this.pendingSignals.filter((d,i) => i > 0);

      //We've already saved a multi-outcome of "getNextTurn", so just use that now.
      this.previousTurn = {...topSignal};
      this.emit(topSignal);
      return;
    }

    //Check if battle is over
    const winningTeam = getWinningTeam(this.combatants);
    if (winningTeam) {

      audioManager.stopSong();

      //This will queue up for next time to trigger a transition out of battle
      this.pendingSignals = [
        {
          type: "battle-over",
          winningTeam: winningTeam
        }
      ];

      //Emit the ending speak now:
      const leadEnemy = getLeadEnemy(this.combatants.filter(c => c.belongsToTeam === "two"));
      this.emit( {
        type: "automaticAction",
        combatantId: leadEnemy.id,
        actionId: "speak",
        targetIds: [leadEnemy.id],
        tags: [],
        mergeData: {
          preDelayFrames: 50,
          dialogContent: [
            winningTeam === "one" ? leadEnemy.playerVictoryMessage : leadEnemy.playerDefeatMessage
          ]
        }
      });
      return;
    }

    //Maybe start a new turn.
    if (this.currentRoundArray.length === 0) {
      //console.log('setting up a new round');
      this.setupNewRound();
    }


    //Battle is not over. Hand out turn to the next person:
    let {nextTurn, updatedRoundArray, updatedSchedule} = getNextTurn({
      currentRoundArray: this.currentRoundArray,
      previousTurn: this.previousTurn,
      combatants: this.combatants,
      lastSubmissionSummary: this.summary,
      schedule: this.schedule,
      roundNumber: this.roundNumber
    });

    if (updatedSchedule) {
      this.schedule = {...updatedSchedule}
    }

    if (Array.isArray(nextTurn)) {
      this.pendingSignals = [...nextTurn.filter((d,i) => i > 0)];
      nextTurn = nextTurn[0];
    }

    //Save this for next time
    this.previousTurn = {...nextTurn};

    //Update our current round
    this.currentRoundArray = updatedRoundArray;

    //Give the turn to the next person
    //Maybe wait a second if this is an enemy AI freeWill decision

    const nextCombatantModelToGo = this.combatants.find(c => c.id === nextTurn.combatantId);
    if (nextTurn.type === "freeWill" && nextCombatantModelToGo && nextCombatantModelToGo.belongsToTeam === "two") {
      //Provide a few frames for the person to stop and think before they submit (beacuse they will right away)
      waitFrames(15, () => {
        //It might seem weird that we do that wait here, but we delay the emit because we don't want them to step forward yet.
        this.emit(nextTurn);
      })
    } else {
      //Default to firing right away
      this.emit(nextTurn);
    }
  }

  emit(signal) {
    this.emitListener(signal)
  }

  generateTurnOrder() {
    const arenaCombatants = this.combatants.filter(c => c.belongsToTeam === "arena");

    const combatantsSortedByLevel = [...this.combatants]
      .filter(c => c.belongsToTeam !== "arena")
      .sort((c1, c2) => {
        const c1Total = c1.level + (c1.speedModifier || 0);
        const c2Total = c2.level + (c2.speedModifier || 0);
        return c1Total <= c2Total ? 1 : -1
      });

    this.turnOrder = [...arenaCombatants, ...combatantsSortedByLevel].map(c => {
      let payload = [c.id];

      //Maybe add extra turns
      if (c.extraTurns) {
        for (let i = 0; i<c.extraTurns; i++) {
          payload.push(c.id)
        }
      }
      return payload
    }).reduce((a,b) => [...a, ...b]);
  }

  addRoundSubmission(combatantId) {

    if (this.summary.actionId === "speak") {
      return
    }

    //Make turn bubble go away when:
    if (
      this.summary.actionId.startsWith("script_") ||
      this.summary.actionId.startsWith("item_") ||
      this.summary.actionId === "natural_nervous-mistake" ||
      this.summary.actionId === "natural_lag-out"
    ) {
      this.currentRoundSubmissions.push(combatantId)
    }

  }

  getTurnBlips() {
    let payload = {};

    const computedTurns = [...this.turnOrder];

    computedTurns.forEach(c => {
      if (payload[c]) {
        payload[c] += 1
      } else {
        payload[c] = 1;
      }
    });

    this.currentRoundSubmissions.forEach(id => {
      payload[id] -= 1
    });

    //console.log(payload)
    return payload;
  }
}

export default BattleModel;