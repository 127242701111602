import {shuffle} from "../../helpers/shuffle";
import {getMischiefDecision} from "./personality-functions/getMischiefDecision";
import {getViciousDecision} from "./personality-functions/getViciousDecision";
import {getCursingDecision} from "./personality-functions/getCursingDecision";
import {getStatBoostingDecision} from "./personality-functions/getStatBoostingDecision";
import {getNormalAttackDecision} from "./personality-functions/getNormalAttackDecision";
import {getStatusBoostingDecision} from "./personality-functions/getStatusBoostingDecision";
import {getStatusCuringDecision} from "./personality-functions/getStatusCuringDecision";
import {getHpCuringDecision} from "./personality-functions/getHpCuringDecision";
import {getStatLoweringDecision} from "./personality-functions/getStatLoweringDecision";
import {getDangerMeterBoostingDecision} from "./personality-functions/getDangerMeterBoostingDecision";
import {getSuperDecision} from "./personality-functions/getSuperDecision";

export default class AiPersonality {
  constructor({combatantId, allCombatants=[], teamOneItems=[], teamTwoItems=[]}) {
    this.combatant = allCombatants.find(c => c.id === combatantId) || {};
    const teamOne = allCombatants.filter(c => c.belongsToTeam === "one");
    const teamTwo = allCombatants.filter(c => c.belongsToTeam === "two");
    const {belongsToTeam} = this.combatant;

    this.teamMembers = [];
    this.enemies = [];
    this.teamItems = [];
    this.enemyItems = [];

    if (belongsToTeam === "one") {
      this.teamMembers = teamOne.filter(c => c.id !== combatantId); //filter out "me"
      this.enemies = teamTwo;
      this.teamItems = teamOneItems;
      this.enemyItems = teamTwoItems;
    }
    if (belongsToTeam === "two") {
      this.teamMembers = teamTwo.filter(c => c.id !== combatantId); //filter out "me"
      this.enemies = teamOne;
      this.teamItems = teamTwoItems;
      this.enemyItems = teamOneItems;
    }
  }

  assemblePersonalityArray() {
    const defaultPersonalityList = [
      "mischief", "vicious", "cursing", "statBoost", "positiveStatus", "healStatus", "healHp", "lowerStats", "dangerBoost"
    ];

    if (!this.combatant.personality || this.combatant.personality.length === 0) {
      return defaultPersonalityList;
    }

    //Custom array! combine coins in the bucket!
    //EX: "healHp:2", "cursing", "positiveStatus:5", "vicious", "mischief",

    let payload = [];
    const personalityArray = this.combatant.personality;
    personalityArray.forEach(p => {
      if (p.includes(":")) {
        let name = p.split(":")[0];
        let count = p.split(":")[1];
        count = Number(count);
        for (let i = 0; i < count; i++) {
          payload.push(name)
        }
      } else {
        payload.push(p)
      }
    });
    return payload;
  }

  getDecision() {

    const personalityPaths = shuffle(this.assemblePersonalityArray());

    //1: Set a threshold. How many "smart" decisions do we get to make?
      //Smarter -> higher number.. more likely to do a smart thing
      //Less difficult -> more likely to simply default to a regular or noop. (nooping should be intentional)
    const smartDecisions = 2;

    //2: Create a smart decision for every smart slot we have
    const props = {
      casterModel: this.combatant,
      teamMembers: this.teamMembers,
      enemies: this.enemies,
      teamItems: this.teamItems,
      enemyItems: this.enemyItems
    };

    //Maybe use Super move if I can
    if (this.combatant.dangerMeter >= 100 && this.combatant.supers && this.combatant.supers.length) {
      const superDecision = getSuperDecision(props);
      if (superDecision) {
        return superDecision
      }
    }

    const choices = personalityPaths.slice(0,smartDecisions).map(key => {
      if (key === "mischief") { return getMischiefDecision(props) }
      if (key === "vicious") { return getViciousDecision(props) }
      if (key === "cursing") { return getCursingDecision(props) }
      if (key === "statBoost") { return getStatBoostingDecision(props) }
      if (key === "positiveStatus") { return getStatusBoostingDecision(props) }
      if (key === "healStatus") { return getStatusCuringDecision(props) }
      if (key === "healHp") { return getHpCuringDecision(props)}
      if (key === "lowerStats") { return getStatLoweringDecision(props)}
      if (key === "dangerBoost") { return getDangerMeterBoostingDecision(props)}
      return null
    });

    let decision = choices.find(c => c);

    //If we didn't find something specific to do, try to use Regular attack
    if (!decision) {
      decision = getNormalAttackDecision(props);
    }

    //If still no regular attack was available, go ahead and noop.
    return decision || {
      actionId: "noop",
      casterId: this.combatant.id,
      targetIds: [this.combatant.id]
    }
  }
}










