import React from 'react';

class Status extends React.PureComponent {

  render() {
    const {pixelSize, status} = this.props;
    const model = blips[status];

    if (!model) {
      console.warn('icon not found:', status)
      return null;
    }

    const style = {
      width: pixelSize * model.width,
      height: pixelSize * model.height,
      display: "block"
    };

    return model.svg(style)
  }
}

export default Status;

const blips = {
  "nervous": {
    width: 31,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 31 9" shapeRendering="crispEdges">
          <path stroke="#35315c" d="M1 0h29"/>
          <path stroke="#222033" d="M0 1h1m29 0h1M0 2h1m29 0h1M0 3h1m29 0h1M0 4h1m29 0h1M0 5h1m29 0h1M0 6h1m29 0h1M0 7h1m29 0h1M1 8h29"/>
          <path stroke="#d21d47" d="M1 1h29"/>
          <path stroke="#b31358" d="M1 2h29M1 3h1m2 0h2m3 0h1m3 0h1m1 0h1m1 0h1m3 0h1m1 0h1m1 0h1m3 0h1M1 4h1m1 0h1m1 0h1m2 0h2m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h3M1 5h1m1 0h1m1 0h1m1 0h3m2 0h2m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h2m2 0h1M1 6h1m1 0h1m1 0h1m3 0h1m1 0h1m1 0h2m1 0h2m3 0h1m3 0h1m3 0h1M1 7h29"/>
          <path stroke="#f6f3f1" d="M2 3h2m2 0h3m1 0h3m1 0h1m1 0h1m1 0h3m1 0h1m1 0h1m1 0h3M2 4h1m1 0h1m1 0h2m2 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1M2 5h1m1 0h1m1 0h1m3 0h2m2 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m2 0h2M2 6h1m1 0h1m1 0h3m1 0h1m1 0h1m2 0h1m2 0h3m1 0h3m1 0h3"/>
        </svg>
      )
    }
  },
  "memory-leak": {
    width: 21,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 21 9" shapeRendering="crispEdges">
          <path stroke="#35315c" d="M1 0h19"/>
          <path stroke="#222033" d="M0 1h1m19 0h1M0 2h1m19 0h1M0 3h1m19 0h1M0 4h1m19 0h1M0 5h1m19 0h1M0 6h1m19 0h1M0 7h1m19 0h1M1 8h19"/>
          <path stroke="#f69a3c" d="M1 1h19"/>
          <path stroke="#df6919" d="M1 2h19M1 3h2m1 0h3m3 0h1m3 0h1m1 0h1m1 0h2M1 4h2m1 0h3m2 0h2m1 0h1m1 0h1m1 0h1m1 0h2M1 5h2m1 0h3m1 0h3m3 0h1m2 0h3M1 6h2m3 0h1m3 0h1m1 0h1m1 0h1m1 0h1m1 0h2M1 7h19"/>
          <path stroke="#f6f3f1" d="M3 3h1m3 0h3m1 0h3m1 0h1m1 0h1M3 4h1m3 0h2m2 0h1m1 0h1m1 0h1m1 0h1M3 5h1m3 0h1m3 0h3m1 0h2M3 6h3m1 0h3m1 0h1m1 0h1m1 0h1m1 0h1"/>
        </svg>
      )
    }
  },
  "lag": {
    width: 17,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 17 9" shapeRendering="crispEdges">
          <path stroke="#35315c" d="M1 0h15" />
          <path stroke="#222033" d="M0 1h1M16 1h1M0 2h1M16 2h1M0 3h1M16 3h1M0 4h1M16 4h1M0 5h1M16 5h1M0 6h1M16 6h1M0 7h1M16 7h1M1 8h15" />
          <path stroke="#a148a8" d="M1 1h15" />
          <path stroke="#76428a" d="M1 2h15M1 3h2M4 3h3M10 3h1M14 3h2M1 4h2M4 4h3M8 4h1M10 4h1M12 4h4M1 5h2M4 5h3M10 5h1M12 5h1M14 5h2M1 6h2M6 6h1M8 6h1M10 6h1M14 6h2M1 7h15" />
          <path stroke="#f6f3f1" d="M3 3h1M7 3h3M11 3h3M3 4h1M7 4h1M9 4h1M11 4h1M3 5h1M7 5h3M11 5h1M13 5h1M3 6h3M7 6h1M9 6h1M11 6h3" />
        </svg>
      )
    }
  },
  "hacked": {
    width: 29,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 29 9" shapeRendering="crispEdges">
          <path stroke="#35315c" d="M1 0h27" />
          <path stroke="#222033" d="M0 1h1M28 1h1M0 2h1M28 2h1M0 3h1M28 3h1M0 4h1M28 4h1M0 5h1M28 5h1M0 6h1M28 6h1M0 7h1M28 7h1M1 8h27" />
          <path stroke="#ef4cf1" d="M1 1h27" />
          <path stroke="#b721d5" d="M1 2h27M1 3h2M4 3h1M6 3h1M10 3h1M14 3h1M16 3h1M18 3h1M22 3h1M25 3h3M1 4h2M4 4h1M6 4h1M8 4h1M10 4h1M12 4h3M16 4h1M18 4h1M21 4h2M24 4h1M26 4h2M1 5h2M6 5h1M10 5h1M12 5h3M17 5h2M20 5h3M24 5h1M26 5h2M1 6h2M4 6h1M6 6h1M8 6h1M10 6h1M14 6h1M16 6h1M18 6h1M22 6h1M25 6h3M1 7h27" />
          <path stroke="#f6f3f1" d="M3 3h1M5 3h1M7 3h3M11 3h3M15 3h1M17 3h1M19 3h3M23 3h2M3 4h1M5 4h1M7 4h1M9 4h1M11 4h1M15 4h1M17 4h1M19 4h2M23 4h1M25 4h1M3 5h3M7 5h3M11 5h1M15 5h2M19 5h1M23 5h1M25 5h1M3 6h1M5 6h1M7 6h1M9 6h1M11 6h3M15 6h1M17 6h1M19 6h3M23 6h2" />
        </svg>
      )
    }
  },
  "fury": {
    width: 21,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 21 9" shapeRendering="crispEdges">
          <path stroke="#35315c" d="M1 0h19" />
          <path stroke="#222033" d="M0 1h1M20 1h1M0 2h1M20 2h1M0 3h1M20 3h1M0 4h1M20 4h1M0 5h1M20 5h1M0 6h1M20 6h1M0 7h1M20 7h1M1 8h19" />
          <path stroke="#ef4f29" d="M1 1h19" />
          <path stroke="#d00b0b" d="M1 2h19M1 3h2M6 3h1M8 3h1M10 3h1M14 3h1M16 3h1M18 3h2M1 4h2M4 4h3M8 4h1M10 4h1M12 4h1M14 4h1M16 4h1M18 4h2M1 5h2M5 5h2M8 5h1M10 5h1M13 5h3M17 5h3M1 6h2M4 6h3M10 6h1M12 6h1M14 6h2M17 6h3M1 7h19" />
          <path stroke="#f6f3f1" d="M3 3h3M7 3h1M9 3h1M11 3h3M15 3h1M17 3h1M3 4h1M7 4h1M9 4h1M11 4h1M13 4h1M15 4h1M17 4h1M3 5h2M7 5h1M9 5h1M11 5h2M16 5h1M3 6h1M7 6h3M11 6h1M13 6h1M16 6h1" />
        </svg>
      )
    }
  },
  "frail": {
    width: 23,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 23 9" shapeRendering="crispEdges">
          <path stroke="#35315c" d="M1 0h21" />
          <path stroke="#222033" d="M0 1h1M22 1h1M0 2h1M22 2h1M0 3h1M22 3h1M0 4h1M22 4h1M0 5h1M22 5h1M0 6h1M22 6h1M0 7h1M22 7h1M1 8h21" />
          <path stroke="#c3fd5c" d="M1 1h21" />
          <path stroke="#8ae731" d="M1 2h21M1 3h2M6 3h1M10 3h1M14 3h1M16 3h1M18 3h4M1 4h2M4 4h3M8 4h1M10 4h1M12 4h1M14 4h1M16 4h1M18 4h4M1 5h2M5 5h2M9 5h2M14 5h1M16 5h1M18 5h4M1 6h2M4 6h3M8 6h1M10 6h1M12 6h1M14 6h1M16 6h1M20 6h2M1 7h21" />
          <path stroke="#42412b" d="M3 3h3M7 3h3M11 3h3M15 3h1M17 3h1M3 4h1M7 4h1M9 4h1M11 4h1M13 4h1M15 4h1M17 4h1M3 5h2M7 5h2M11 5h3M15 5h1M17 5h1M3 6h1M7 6h1M9 6h1M11 6h1M13 6h1M15 6h1M17 6h3" />
        </svg>
      )
    }
  },
  "fire": {
    width: 19,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 19 9" shapeRendering="crispEdges">
          <path stroke="#35315c" d="M1 0h17" />
          <path stroke="#222033" d="M0 1h1M18 1h1M0 2h1M18 2h1M0 3h1M18 3h1M0 4h1M18 4h1M0 5h1M18 5h1M0 6h1M18 6h1M0 7h1M18 7h1M1 8h17" />
          <path stroke="#ef4f29" d="M1 1h17" />
          <path stroke="#d00b0b" d="M1 2h17M1 3h2M6 3h1M8 3h1M12 3h1M16 3h2M1 4h2M4 4h3M8 4h1M10 4h1M12 4h1M15 4h3M1 5h2M5 5h2M8 5h1M11 5h2M14 5h4M1 6h2M4 6h3M8 6h1M10 6h1M12 6h1M16 6h2M1 7h17" />
          <path stroke="#f2eea9" d="M3 3h3M7 3h1M9 3h3M13 3h3M3 4h1M7 4h1M9 4h1M11 4h1M13 4h2" />
          <path stroke="#ffffff" d="M3 5h2M7 5h1M9 5h2M13 5h1" />
          <path stroke="#ffdd5f" d="M3 6h1M7 6h1M9 6h1M11 6h1M13 6h3" />
        </svg>
      )
    }
  },
  "disabled": {
    width: 35,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 35 9" shapeRendering="crispEdges">
          <path stroke="#35315c" d="M1 0h33" />
          <path stroke="#222033" d="M0 1h1M34 1h1M0 2h1M34 2h1M0 3h1M34 3h1M0 4h1M34 4h1M0 5h1M34 5h1M0 6h1M34 6h1M0 7h1M34 7h1M1 8h33" />
          <path stroke="#706e7d" d="M1 1h33" />
          <path stroke="#54535d" d="M1 2h33M1 3h2M5 3h2M8 3h1M12 3h1M16 3h1M20 3h1M22 3h3M28 3h1M31 3h3M1 4h2M4 4h1M6 4h1M8 4h1M10 4h3M14 4h1M16 4h1M20 4h1M22 4h3M27 4h2M30 4h1M32 4h2M1 5h2M4 5h1M6 5h1M8 5h2M12 5h1M16 5h1M18 5h1M20 5h1M22 5h3M26 5h3M30 5h1M32 5h2M1 6h2M5 6h2M8 6h1M12 6h1M14 6h1M16 6h1M20 6h1M24 6h1M28 6h1M31 6h3M1 7h33" />
          <path stroke="#f6f3f1" d="M3 3h2M7 3h1M9 3h3M13 3h3M17 3h3M21 3h1M25 3h3M29 3h2M3 4h1M5 4h1M7 4h1M9 4h1M13 4h1M15 4h1M17 4h3M21 4h1M25 4h2M29 4h1M31 4h1M3 5h1M5 5h1M7 5h1M10 5h2M13 5h3M17 5h1M19 5h1M21 5h1M25 5h1M29 5h1M31 5h1M3 6h2M7 6h1M9 6h3M13 6h1M15 6h1M17 6h3M21 6h3M25 6h3M29 6h2" />
        </svg>
      )
    }
  },
  "deadline": {
    width: 35,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 35 9" shapeRendering="crispEdges">
          <path stroke="#35315c" d="M1 0h33" />
          <path stroke="#222033" d="M0 1h1M34 1h1M0 2h1M34 2h1M0 3h1M34 3h1M0 4h1M34 4h1M0 5h1M34 5h1M0 6h1M34 6h1M0 7h1M34 7h1M1 8h33" />
          <path stroke="#44b475" d="M1 1h33" />
          <path stroke="#37946e" d="M1 2h33M1 3h2M5 3h2M10 3h1M14 3h1M17 3h2M20 3h3M24 3h1M27 3h2M32 3h2M1 4h2M4 4h1M6 4h1M9 4h2M12 4h1M14 4h1M16 4h1M18 4h1M20 4h3M24 4h1M26 4h1M28 4h1M31 4h3M1 5h2M4 5h1M6 5h1M8 5h3M14 5h1M16 5h1M18 5h1M20 5h3M24 5h1M26 5h1M28 5h1M30 5h4M1 6h2M5 6h2M10 6h1M12 6h1M14 6h1M17 6h2M22 6h1M24 6h1M26 6h1M28 6h1M32 6h2M1 7h33" />
          <path stroke="#f6f3f1" d="M3 3h2M7 3h3M11 3h3M15 3h2M19 3h1M23 3h1M25 3h2M29 3h3M3 4h1M5 4h1M7 4h2M11 4h1M13 4h1M15 4h1M17 4h1M19 4h1M23 4h1M25 4h1M27 4h1M29 4h2M3 5h1M5 5h1M7 5h1M11 5h3M15 5h1M17 5h1M19 5h1M23 5h1M25 5h1M27 5h1M29 5h1M3 6h2M7 6h3M11 6h1M13 6h1M15 6h2M19 6h3M23 6h1M25 6h1M27 6h1M29 6h3" />
        </svg>
      )
    }
  },
  "block": {
    width: 25,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 25 9" shapeRendering="crispEdges">
          <path stroke="#35315c" d="M1 0h23" />
          <path stroke="#222033" d="M0 1h1M24 1h1M0 2h1M24 2h1M0 3h1M24 3h1M0 4h1M24 4h1M0 5h1M24 5h1M0 6h1M24 6h1M0 7h1M24 7h1M1 8h23" />
          <path stroke="#8bbff3" d="M1 1h23" />
          <path stroke="#7197e5" d="M1 2h23M1 3h2M5 3h2M8 3h3M14 3h1M18 3h1M20 3h1M22 3h2M1 4h2M6 4h1M8 4h3M12 4h1M14 4h1M16 4h3M20 4h1M22 4h2M1 5h2M4 5h1M6 5h1M8 5h3M12 5h1M14 5h1M16 5h3M21 5h3M1 6h2M5 6h2M10 6h1M14 6h1M18 6h1M20 6h1M22 6h2M1 7h23" />
          <path stroke="#f6f3f1" d="M3 3h2M7 3h1M11 3h3M15 3h3M19 3h1M21 3h1M3 4h3M7 4h1M11 4h1M13 4h1M15 4h1M19 4h1M21 4h1M3 5h1M5 5h1M7 5h1M11 5h1M13 5h1M15 5h1M19 5h2M3 6h2M7 6h3M11 6h3M15 6h3M19 6h1M21 6h1" />
        </svg>
      )
    }
  },
  "focus": {
    width: 25,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 25 9" shapeRendering="crispEdges">
          <path stroke="#35315c" d="M1 0h23" />
          <path stroke="#222033" d="M0 1h1M24 1h1M0 2h1M24 2h1M0 3h1M24 3h1M0 4h1M24 4h1M0 5h1M24 5h1M0 6h1M24 6h1M0 7h1M24 7h1M1 8h23" />
          <path stroke="#8bbff3" d="M1 1h23" />
          <path stroke="#7197e5" d="M1 2h23M1 3h2M6 3h1M10 3h1M14 3h1M16 3h1M18 3h1M22 3h2M1 4h2M4 4h3M8 4h1M10 4h1M12 4h3M16 4h1M18 4h1M20 4h4M1 5h2M5 5h2M8 5h1M10 5h1M12 5h3M16 5h1M18 5h2M22 5h2M1 6h2M4 6h3M10 6h1M14 6h1M18 6h1M22 6h2M1 7h23" />
          <path stroke="#f6f3f1" d="M3 3h3M7 3h3M11 3h3M15 3h1M17 3h1M19 3h3M3 4h1M7 4h1M9 4h1M11 4h1M15 4h1M17 4h1M19 4h1M3 5h2M7 5h1M9 5h1M11 5h1M15 5h1M17 5h1M20 5h2M3 6h1M7 6h3M11 6h3M15 6h3M19 6h3" />
        </svg>
      )
    }
  },
  "dangerous": {
    width: 41,
    height: 9,
    svg(style) {
      return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 41 9" shapeRendering="crispEdges">
          <path stroke="#35315c" d="M1 0h39" />
          <path stroke="#222033" d="M0 1h1M40 1h1M0 2h1M40 2h1M0 3h1M40 3h1M0 4h1M40 4h1M0 5h1M40 5h1M0 6h1M40 6h1M0 7h1M40 7h1M1 8h39" />
          <path stroke="#706e7d" d="M1 1h39" />
          <path stroke="#54535d" d="M1 2h39M1 3h2M5 3h2M10 3h1M13 3h2M18 3h1M22 3h1M26 3h1M30 3h1M32 3h1M34 3h1M38 3h2M1 4h2M4 4h1M6 4h1M8 4h1M10 4h1M12 4h1M14 4h1M16 4h3M21 4h2M24 4h1M26 4h1M28 4h1M30 4h1M32 4h1M34 4h1M36 4h4M1 5h2M4 5h1M6 5h1M10 5h1M12 5h1M14 5h1M16 5h1M18 5h1M20 5h3M25 5h2M28 5h1M30 5h1M32 5h1M34 5h2M38 5h2M1 6h2M5 6h2M8 6h1M10 6h1M12 6h1M14 6h1M18 6h1M22 6h1M24 6h1M26 6h1M30 6h1M34 6h1M38 6h2M1 7h39" />
          <path stroke="#ffb4a9" d="M3 3h2M7 3h3M11 3h2M15 3h3M19 3h3M23 3h3M27 3h3M31 3h1M33 3h1M35 3h3" />
          <path stroke="#ff7575" d="M3 4h1M5 4h1M7 4h1M9 4h1M11 4h1M13 4h1M15 4h1M19 4h2M23 4h1M25 4h1M27 4h1M29 4h1M31 4h1M33 4h1M35 4h1M3 5h1M5 5h1M7 5h3M11 5h1M13 5h1M15 5h1M17 5h1M19 5h1M23 5h2M27 5h1M29 5h1M31 5h1M33 5h1M36 5h2M3 6h2M7 6h1M9 6h1M11 6h1M13 6h1M15 6h3M19 6h3M23 6h1M25 6h1M27 6h3M31 6h3M35 6h3" />
        </svg>
      )
    }
  }
};