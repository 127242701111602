export class ChapterGetter {

  constructor() {
    this.data = {};
  }

  get() {
    return this.data;
  }

  set(data) {
    this.data = data;
  }
}

export const chapter = new ChapterGetter();