import store from '../init/store'
import {setPlayerDataValue} from "../redux-actions/player-data-actions";
import {getBattleCrewFromRedux} from "../Overworld/player-data-operations/getBattleCrew";

export function useItemOnCrewMember(initialCrewMember, item) {

  const {overworldUsableCode} = item;
  let payload = {...initialCrewMember};

  if (overworldUsableCode.includes("alive") && payload.hp <= 0) {
    return payload
  }
  if (overworldUsableCode.includes("dead") && payload.hp > 0) {
    return payload
  }


  if (overworldUsableCode.includes("50%")) {
    if (payload.hp < 0) {payload.hp = 0}
    payload.hp += (Math.round(payload.maxHp / 2));
    if (payload.hp > payload.maxHp) { payload.hp = payload.maxHp }
  }

  if (overworldUsableCode.includes("100%")) {
    payload.hp = payload.maxHp
  }

  return payload;
}

export function useItemOnCrewMembersInRedux(crewMemberIds=[], item, instanceId) {
  const {acquiredMembers, inventory} = store.getState().playerData;
  const battleCrew = getBattleCrewFromRedux();

  const updatedAcquiredMembers = acquiredMembers.map(c => {

    if (crewMemberIds.includes(c.id)) {

      //A little hacky and cheap, but grap maxHp from crew version because we don't have it in redux
      const battleCrewVersion = battleCrew.find(m => m.id === c.id);
      if (battleCrewVersion) {
        c.maxHp = battleCrewVersion.maxHp
      }

      return useItemOnCrewMember(c, item)
    }

    delete c.maxHp; //don't perist this to redux

    return c;
  });

  setPlayerDataValue({
    acquiredMembers: updatedAcquiredMembers,
    inventory: inventory.filter(i => i.instanceId !== instanceId)
  })
}