import BattleActions from './battle-data/battle-actions-data'
import {testablePercentChance} from "../helpers/testablePercentChance";
import {getCasterAccuracy} from "./battle-data/formulas/getCasterAccuracy";
import {getDangerMeterSteps} from './getDangerMeterSteps'
import {getBuggedItemSteps} from './getBuggedItemSteps'
import {getUseItemSteps} from './getUseItemSteps'
import Upgrades from "./battle-data/Upgrades";
import {shuffle} from "../helpers/shuffle";
//import createPrefixedId from "../helpers/make-id";

export function getEventStepsFromAction({
  actionModelRaw, casterModel, targetModels=[], allUpgrades={}, actionMergeData={}, teamOneItems=[], teamTwoItems=[], instanceId
}) {

  //Merge in data to create a dynamic action model
  const actionModel = {
    ...actionModelRaw,
    ...actionMergeData
  };

  let payload = [
    ...actionModel.getUse(actionModel, casterModel, targetModels)
  ];


  //Moves that target the whole team.
  if (actionModel.successEventsPerAllTargets) {

    //Only attack valid targets (even though all are technically submitted as targets)
    let validTargets = targetModels.filter(targetModel => {
      return !actionModel.shouldFailOnSingleTarget(casterModel, targetModel, Upgrades)
    });

    //Maybe reduce number of targets down to random X number (EX: Binary)
    if (actionModel.filterMaxTargets) { //number
      validTargets = shuffle([...validTargets]).filter((d,i) => i <= actionModel.filterMaxTargets - 1)
    }

    if (validTargets.length === 0) {
      //Might think through this more later, but for now, return a fail action on one target if nobody is valid
      if (actionModel.failEventsPerTarget) {
        const targetModel = targetModels[0];
        return actionModel.failEventsPerTarget(casterModel, targetModel, actionModel.shouldFailOnSingleTarget(casterModel, targetModel, Upgrades))
      }
      return [];
    }

    const multiSteps = actionModel.successEventsPerAllTargets({casterModel, targetModels: validTargets});

    const useMultiTargetItemSteps = instanceId ? getUseItemSteps({casterModel, instanceId}) : [];

    return [
      ...actionModel.getUse(actionModel, casterModel, targetModels),
      //...normal steps (all at once)
      ...multiSteps,
      //...any fail steps? (Update: I think silent fails are going to be fine for multi-target attacks. Really they just get filtered out)
        ...getDangerMeterSteps({actionModel, casterModel}),
        ...useMultiTargetItemSteps
    ];
  }

  //Pipe team item moves (Curl, etc) through their own set of methods on the Actions
  if (actionModel.teamItemMoveMethods) {
    let friendlyItems = [];
    let enemyItems = [];
    if (casterModel.belongsToTeam === "one") {
      friendlyItems = teamOneItems;
      enemyItems = teamTwoItems;
    }
    if (casterModel.belongsToTeam === "two") {
      friendlyItems = teamTwoItems;
      enemyItems = teamOneItems;
    }

    const failInfo = actionModel.shouldFailOnTargetTeam && actionModel.shouldFailOnTargetTeam({ casterModel, friendlyItems, enemyItems });


    const steps = failInfo
      ? actionModel.wholeTeamFailEvent({casterModel, targetModels, failInfo})
      : actionModel.successEventOnTeam({ casterModel, targetModels, friendlyItems, enemyItems });


    const useMultiTargetItemSteps = instanceId ? getUseItemSteps({casterModel, instanceId}) : [];

    payload = [
      ...payload,
      ...steps,
      ...useMultiTargetItemSteps
    ]

  } else {

    //Check if this is a bugged item
    let isBugged = false;
    if (instanceId) {
      const item = [...teamOneItems, ...teamTwoItems].find(i => i.instanceId === instanceId);
      if (item && item.isBugged) {
        isBugged = true;
        payload = [
          ...payload,
          ...getBuggedItemSteps({casterModel})
        ]
      }
    }

    if (!isBugged) {
      //Most moves do this flow:
      targetModels.forEach(targetModel => {

        //Maybe miss
        if (typeof actionModel.accuracy === "number") {
          const casterAccuracy = getCasterAccuracy(actionModel, casterModel, targetModel);
          if (!testablePercentChance(casterAccuracy)) {
            payload = [
              ...payload,
              ...actionModel.missEventsPerTarget(casterModel, targetModel)
            ];
            return
          }
        }

        const failInfo = actionModel.shouldFailOnSingleTarget && actionModel.shouldFailOnSingleTarget(casterModel, targetModel, Upgrades, actionModel);
        const steps = failInfo
          ? actionModel.failEventsPerTarget(casterModel, targetModel, failInfo)
          : actionModel.successEventsPerTarget(casterModel, targetModel, Upgrades);
        payload = [
          ...payload,
          ...steps
        ]
      });
    }
  }

  //Things that "usually" happen:
  //Case 1: danger meter
  const dangerMeterSteps = getDangerMeterSteps({actionModel, casterModel});
  payload = [
    ...payload,
    ...dangerMeterSteps
  ];

  //Maybe use an instanceId
  if (instanceId) {
    const useItemSteps = getUseItemSteps({casterModel, instanceId});
    payload = [
      ...payload,
      ...useItemSteps
    ]
  }

  return payload;
}



export function formatEventList(list) {
  //const randomId = createPrefixedId("event");
  return list.map((entry, index) => {

    return {
      id: `event${index}`,
      //id: `${randomId}-${index}`,
      delayFramesAfter: 60,
      ...entry, //you can merge in custom frame delay here
    };
  });
}

export function mapSubmissionIdsToRoles({actionId, casterId, targetIds=[], combatants=[], allActions=BattleActions}) {
  return {
    actionModel: allActions[actionId],
    casterModel: combatants.find(c => c.id === casterId),
    targetModels: targetIds.map(id => combatants.find(c => c.id === id)),
  }
}