/* Look at the staged battle and figure out how much XP you get from winning */
/* Returns a Number (for XP) */
export function getXpFromWinningBattle(enemies=[]) {

  //NOTE: You can tweak this formula as you go. This is going to be a shot in the dark at first.
  //Try to keep it simple. The game is rooted in simplicity, so the formulas should also be simple.

  let payload = 0;
  let highestLevel = 0;

  enemies.forEach((model, i) => {

    //Maybe use this as the highest level
    if (model.level > highestLevel) {
      highestLevel = model.level
    }

    //Start by potentially adding bonus XP from each enemy person
    if (model.bonusXp) {
      payload += Number(model.bonusXp)
    }

    if (i === 0) {
      payload += model.level * 8
    } else if (i >= 3) {
      //Nerf people when we have a lot of combatants
      payload += model.level * 5
    } else {
      payload += model.level * 6
    }
  });

  //Return how much XP you should get
  return payload;
}
