import store from '../../init/store'
import {setPlayerDataValue} from "../../redux-actions/player-data-actions";

export function addUpgradeToPlayer(upgradeId="", currentUpgrades=[]) {

  const isDuplicate = currentUpgrades.find(u => u.libraryId === upgradeId);
  if (isDuplicate) {
    return currentUpgrades
  }

  return [
    ...currentUpgrades,
    { libraryId: upgradeId }
  ]
}

export function addUpgradeToPlayerDataInRedux(upgradeId) {
  setPlayerDataValue({
    allPlayerUpgrades: addUpgradeToPlayer(upgradeId, store.getState().playerData.allPlayerUpgrades)
  })
}