import {shuffle} from "../../../helpers/shuffle";

export function getDangerMeterBoostingDecision({casterModel, teamMembers, enemies, teamItems}) {

  //Find alive combatants that have Supers and non-full DM
  const validFriends = [casterModel, ...teamMembers].filter(c => {
    if (c.hp <= 0) { return false }
    if ((c.supers || []).length === 0) { return false }
    if (c.dangerMeter >= 100) { return false }
    return true;
  });
  if (!validFriends) { return null }


  const teamDangerBoostingItem = teamItems.find(item => {
    return [
      "item_team-danger-boost",
    ].includes(item.actionId)
  });

  if (teamDangerBoostingItem && validFriends.length > 1) {
    //Determine if at least 2 people would benefit
    return {
      actionId: teamDangerBoostingItem.actionId,
      casterId: casterModel.id,
      targetIds: validFriends.map(c => c.id),
      instanceId: teamDangerBoostingItem.instanceId,
    }
  }

  //Check if I have danger items
  const dangerBoostingItem = teamItems.find(item => {
    return [
      "item_danger-boost",
    ].includes(item.actionId)
  });
  if (dangerBoostingItem) {
    if (validFriends.length) {
      return {
        actionId: dangerBoostingItem.actionId,
        casterId: casterModel.id,
        targetIds: [ shuffle(validFriends)[0].id ],
        instanceId: dangerBoostingItem.instanceId,
      }
    }
  }
  return null
}