export const externalEventTypes = [
  "wait",
  "textMessage",
  "goToBattle",
  "goToComingSoon",
  "goToCredits",

  "changeMap",
  "changeInteractiveProperty",

  "addStoryPoint",
  "removeStoryPoints",

  "showPauseMenu",
  "showRecoverMenu",
  "showCreatePersonMenu",

  "receiveItem",
  "updateCredits",
  "receiveUpgrade",
  "receiveAttack",
  "updateHp",
  "changeCrew",
  "changeCrewMemberProperty",

  "stopSong",
  "playSong",
  "playSfx",


  "overworldFade",

  "showHowToPlayMenu",
  "showBattleWinPopup",
  "showLoginReminder",

  "changeChapter",
  "saveGame"
];