// export function processStepOnPerson(params) {
//   const {nowState, heroX, heroY, walls, allPeople} = params;
//   let person = {...nowState};
//   return person
// }


export function Person(params={}, allPeople, allWalls) {
  this.params = params;
  this.hasVisualThingChanged = false;



  this.allPeople = allPeople || [];
  this.allWalls = allWalls || [];
}

Person.prototype.updateCoordsToNextPosition = function () {
  this.hasVisualThingChanged = true;
  if (this.params.direction === "UP") {
    this.params.y -= 1
  }
  if (this.params.direction === "DOWN") {
    this.params.y += 1
  }
  if (this.params.direction === "LEFT") {
    this.params.x -= 1
  }
  if (this.params.direction === "RIGHT") {
    this.params.x += 1
  }
};

Person.prototype.updateMovingProgress = function () {

  //Default to 1 (for NPCs) but allow custom speed
  let MOVING_SPEED = this.params.customMovingSpeed || 1;


  const b = this.params.currentlyExecutingBehavior;
  if (b.type === "characterWalk") {

    //Start here if we never defined it.
    if (this.params.movingProgress === undefined) {
      this.params.movingProgress = 0;
    }

    this.hasVisualThingChanged = true;
    this.params.movingProgress += MOVING_SPEED;
  }
};

Person.prototype.updateFrameWaitingProgress = function () {
  if (this.params.framesTilDone > 0) {
    this.params.framesTilDone -= 1;
  }
};

Person.prototype.betterStep = function() {

  //debugger;


  this.establishBehavior();
  const b = this.params.currentlyExecutingBehavior;
  if (b) {
    this.updateMovingProgress();
    this.updateFrameWaitingProgress();
  }

  this.concludeBehavior();
};



Person.prototype.establishBehavior = function() {

  //debugger;

  //Am I working? stop here.
  const b = this.params.currentlyExecutingBehavior;


  if (b && !b.isBusinessBehavior) { return } //Non regular business behaviors can stop here
  if (b && b.isBusinessBehavior && !this.params.doThisBehavior) { return } //business but nothing more important to do instead... can also stop here

  //What's my next behavior?
  if (this.params.doThisBehavior) { //given event is first priority
    this.params.currentlyExecutingBehavior = this.params.doThisBehavior;
  } else {
    //Next is behaviorQueue
    if (this.params.behavior) {
      const behaviorAtPosition = this.params.behavior[this.params.behaviorIndex];
      this.params.currentlyExecutingBehavior = behaviorAtPosition ? {...behaviorAtPosition, isBusinessBehavior: true} : null; //don't work on anything if no behavior exists
    }
    if (!this.params.currentlyExecutingBehavior) {
      this.params.currentlyExecutingBehavior = null;
    }
  }

  //Even if this behavior gets nulled out, we need to apply stuff for this behavior to our person.
  this.applyBehaviorDetails();

  if (this.params.currentlyExecutingBehavior) {
    this.hasVisualThingChanged = true;

    this.params.currentActionType = this.params.currentlyExecutingBehavior.type;
  }

  //If this behavior is invalid, reset it to null.
  if (!this.isBehaviorValid()) {
    this.params.currentActionType = "characterStand";
    this.params.currentlyExecutingBehavior = null;
    this.hasVisualThingChanged = true;
  }

  //Shut off walking animation if we have a stale walk
  if (this.params.currentActionType === "characterWalk" && !this.params.currentlyExecutingBehavior) {
    this.params.currentActionType = "characterStand";
    this.hasVisualThingChanged = true;
  }
};

Person.prototype.applyBehaviorDetails = function() {

  const b = this.params.currentlyExecutingBehavior;

  if (b && b.direction) {
    this.params.direction = b.direction;
  }

  //IS this maybe in the wrong spot? Too opinionated for this method?
  if (b && b.postDelayMs > 0) {
    this.params.framesTilDone = Math.round(b.postDelayMs / 18);
  }
};


Person.prototype.concludeBehavior = function() {

  const b = this.params.currentlyExecutingBehavior;
  if (!b) { return }

  let done = false;

  if (b.postDelayMs > 0 && this.params.framesTilDone === 0 ) {
    done = true;
  }

  if (b.type === "characterWalk" && this.params.movingProgress >= 32) {
    this.updateCoordsToNextPosition();
    done = true;
  }

  if (done) {
    this.params.movingProgress = 0;
    this.params.isInternalEventFinished = true;
    this.params.doThisBehavior = null;

    //Incr behaviorIndex if this was a behavior thing.
    if (b.isBusinessBehavior) {
      this.params.behaviorIndex += 1;
      if (this.params.behaviorIndex > this.params.behavior.length-1) {
        this.params.behaviorIndex = 0;
      }
    }

    this.params.currentlyExecutingBehavior = null;
  }
};



Person.prototype.isBehaviorValid = function() {
  const b = this.params.currentlyExecutingBehavior;
  if (!b) { return true } //It's totally cool to not have a behavior. You do you.

  //Validate walking valid
  if (b.type === "characterWalk") {
    const isSpaceFree = this.isNextSpaceFree();
    if (!isSpaceFree) {


      //console.log('next space is not free')
      this.pathBlockedWhenGoingDirection = b.direction;

      return false;
    }
  }
  return true;
};

Person.prototype.isNextSpaceFree = function () {

  const {x, y, direction} = this.params;
  const nextSpace = this.getNextSpace(x,y,direction);

  if (this.isNextSpaceBlockedByWall(nextSpace)) { return false }
  if (this.isNextSpaceBlockedByInteractive(nextSpace)) { return false }

  return true;
};



Person.prototype.isNextSpaceBlockedByWall = function(nextSpace) {
  return Boolean(this.allWalls.find(w => w === nextSpace));
};


Person.prototype.isNextSpaceBlockedByInteractive = function(nextSpace) {

  const personBlocker = this.allPeople.find(person => {

    //Just standing still
    const pos = `${person.x}x${person.y}`;

    if (person.movingProgress) {
      //Almost to my next space
      const movingPersonNextSpace = this.getNextSpace(person.x, person.y, person.direction);
      if (person.movingProgress > 16) {
        return movingPersonNextSpace === nextSpace;
      }
      if (person.movingProgress <= 16) {
        return (nextSpace === pos || movingPersonNextSpace === nextSpace);
      }
    }

    return pos === nextSpace;
  });

  return Boolean(personBlocker)
};

Person.prototype.getNextSpace = function (x, y, direction) {
  if (direction === "UP") {
    return `${x}x${y - 1}`
  }
  if (direction === "DOWN") {
    return `${x}x${y + 1}`
  }
  if (direction === "LEFT") {
    return `${x - 1}x${y}`
  }
  if (direction === "RIGHT") {
    return `${x + 1}x${y}`
  }
};