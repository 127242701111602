import React from 'react'
import Skins from '../_data/Skins'
import BattleLaptopOpeningAnimation from './BattleLaptopOpeningAnimation'
import FriendlyNpcLaptop from './FriendlyOverworldLaptop'
import MapPersonCanvas from './MapPersonCanvas'
//import Icon from '../interface/Icon'
import GlowingVendingMachineScreen from './GlowingVendingMachineScreen'
import {audioManager} from "../audio/audioManager";

class MapPerson extends React.PureComponent {

  constructor(props) {
    super();
    //Sometimes we don't want to show the shadow:
    this.noShadow = [
      "constructionBlocker",
      "evilLaptop",
      "friendlyLaptop",
      "employeeBadge",
      "itemBox",
      "vendingMachine",
      "equipCard",
      "attackPickup",
      "creditsPickup",
      "upgradeBox",
      "statueFull",
      "swagBag",
      "highwayBlocker",
      "switchRed",
      "switchBlue",
      "switchControl",
      "invisibleStub"
    ].includes(props.skinId || "");

    // this.noHostileFlag = [
    //   "attackPickup", "creditsPickup", "itemBox", "upgradeBox",
    //   "vendingMachine", "friendlyLaptop", "charger", "bear", "switchControl",
    //   "hrNoArrow" //Feels funny during onboarding
    // ].includes(props.skinId || "")

    this.extraYNudge = 0;
    if (["switchControl", "switchRed", "switchBlue", "highwayBlocker"].includes(props.skinId)) {
      this.extraYNudge = 8;
    }

    //Custom values for skins that don't look good with default arrow placement
    this.goldArrowTopPosition = -4;
    if (props.skinId === "evilLaptop") { this.goldArrowTopPosition = 10; }
    if (props.skinId === "socks") { this.goldArrowTopPosition = 10; }
    if (props.skinId === "pencilItemBox") { this.goldArrowTopPosition = 7; }
    if (props.skinId === "itemBoxValidGoldArrow") { this.goldArrowTopPosition = 8; }
    if (props.skinId === "swagBag") { this.goldArrowTopPosition = 8; }
    if (props.skinId === "statuePartsA") { this.goldArrowTopPosition = 8; }
    if (props.skinId === "statuePartsB") { this.goldArrowTopPosition = 8; }


  }

  componentDidUpdate(prevProps) {
   if (prevProps.currentActionType !== "characterJump" && this.props.currentActionType === "characterJump") {
     audioManager.playSfx("sfx_characterJump")
   }
  }

  renderBlinkingVendingMachineScreen() {
    const {pixelSize} = this.props;
    return (
      <div style={{
        position: "absolute",
        left:pixelSize * 7,
        top:pixelSize * 8
      }}>
        <GlowingVendingMachineScreen pixelSize={pixelSize} />
      </div>
    )
  }

  renderLaptop() {

    const {hasBattleLaptop, hasFriendlyLaptop, direction, pixelSize} = this.props;

    if (!hasBattleLaptop && !hasFriendlyLaptop) {
      return null;
    }

    return (
      <div style={{
        position: "absolute",
        textAlign: "center",
        left: 0, top: 0,
        //Be 1 below my zIndex if my direction is UP. Otherwise stack above.
        zIndex: direction === "UP" ? -1 : 1,
      }}>
        {
          //Render the battle starting laptop
          hasBattleLaptop && (
            <BattleLaptopOpeningAnimation
              pixelSize={pixelSize}
              direction={direction}
            />
          )
        }
        {
          //Render the friendly laptop so long as we're not holding the battle opening one too
          hasFriendlyLaptop && !hasBattleLaptop && (
            <FriendlyNpcLaptop
              pixelSize={pixelSize}
              direction={direction}
            />
          )
        }
      </div>
    )
  }

  // renderArrowIndicators(transformValue) {
  //
  //         return null;
  //
  //   const {isHostile, isInvisible, pixelSize, hasQuestIndicator} = this.props;
  //
  //
  //   if (hasQuestIndicator && !isInvisible) {
  //
  //     //Maybe don't show the Quest Indicator on certain skins (like Items)
  //     if (this.noHostileFlag) {
  //       return null;
  //     }
  //
  //     return (
  //       <div style={{
  //         position: "absolute",
  //         left: pixelSize * 11, top: pixelSize * this.goldArrowTopPosition,
  //         animation: "iconFloat 0.8s linear infinite alternate-reverse"
  //       }}>
  //         <Icon category="questIndicator" pixelSize={pixelSize} />
  //       </div>
  //     )
  //   }
  //
  //
  //   if (isHostile && !isInvisible) {
  //     return (
  //       <div style={{
  //         position: "absolute",
  //         left: pixelSize * 11, top: pixelSize * -5,
  //         animation: "iconFloat 0.8s linear infinite alternate-reverse"
  //       }}>
  //         <Icon category="hostileIndicator" pixelSize={pixelSize} />
  //       </div>
  //     )
  //   }
  //   return null
  // }

  render() {

    const {cellSize, skinId, transformValue, isInvisible, pixelSize, /*isHostile, useHostileShadow, currentActionType*/} = this.props;
    const backgroundSkinSrc = Skins[skinId];

    let shadowImg = "url(/images/skins/character-shadow.svg)";
    if (this.noShadow) {
      shadowImg = "none"
    }
    // if (isHostile || useHostileShadow) {
    //   shadowImg = "url(/images/skins/character-hostile-shadow.svg)"
    // }

    return (
      <div className="PersonContainer" style={{
        opacity: isInvisible ? 0 : 1,
        transition: "opacity 0.3s ease",
        position: "absolute",
        top:pixelSize * this.extraYNudge, //Some skins (see constructor) need to be pushed down a little.
        left:0,
        width: cellSize,
        height:cellSize,
        transform: transformValue,
        background: shadowImg,
        backgroundSize: "100%",
        //zIndex: currentActionType === "characterJump" ? 2 : 1, //layer jumping people higher
      }}>
        <div className="PersonCrop" style={{
          width: cellSize,
          height:cellSize,
          //background: "red",
          overflow: "hidden",
          position: "relative",
          animation: getJumpingAnimation(this.props),
        }}>
          {/*<div*/}
            {/*className="PersonSpritesheet"*/}
            {/*style={{*/}
              {/*position: "absolute",*/}
              {/*left: 0,*/}
              {/*top: 0,*/}
              {/*//background: "navy",*/}
              {/*width: cellSize * 4,*/}
              {/*height: cellSize * 4,*/}
              {/*background: `url(${backgroundSkinSrc}) no-repeat no-repeat`,*/}
              {/*backgroundSize: cellSize * 4,*/}
              {/*transform: getSpritesheetTranslateValue(this.props),*/}
              {/*animation: getAnimationValue(this.props),*/}
            {/*}}*/}
          {/*>*/}
          {/*</div>*/}
            <MapPersonCanvas
              key={backgroundSkinSrc} //Remount when skin changes
              pixelSize={pixelSize}
              skinSrc={backgroundSkinSrc}
              style={{
                transform: getSpritesheetTranslateValue(this.props),
                animation: getAnimationValue(this.props),
              }}


              // style={{
              //   // position: "absolute",
              //   // left: 0,
              //   // top: 0,
              //   // width: cellSize * 4,
              //   // height: cellSize * 4,
              //   transform: getSpritesheetTranslateValue(this.props),
              //   animation: getAnimationValue(this.props),
              // }}

            />

        </div>
        {
          this.props.isBlinkingVendingMachine && this.renderBlinkingVendingMachineScreen()
        }
        {/*{this.renderArrowIndicators(transformValue)}*/}
        {this.renderLaptop(transformValue)}
      </div>
    );
  }
}

export default MapPerson;

// function getTransformValue(props) {
//   const {cellSize, direction, movingProgress, x, y} = props;
//
//   let xNudge = 0;
//   let yNudge = cellSize * -0.5;
//
//   if (movingProgress > 0) {
//
//     const cellPercentProgress = (movingProgress / 100);
//
//     if (direction === "LEFT") {
//       xNudge = (-cellSize * cellPercentProgress)
//     }
//     if (direction === "RIGHT") {
//       xNudge = (cellSize * cellPercentProgress)
//     }
//
//     if (direction === "UP") {
//       yNudge += (-cellSize * cellPercentProgress)
//
//     }
//     if (direction === "DOWN") {
//       yNudge += (cellSize * cellPercentProgress)
//     }
//   }
//
//   //Default to x * size and y * size
//   return `translate3d(${x * cellSize + xNudge}px, ${y * cellSize + yNudge}px, 0)`
// }

function getAnimationValue(props) {

  const {isLockedForExternalProcess, currentActionType, direction, isDialogBoxUp} = props;

  //Do not "walk" when frozen or dialog
  if (isLockedForExternalProcess || isDialogBoxUp) {
    return "none"
  }

  if (currentActionType === "characterWalk") {

    if (direction === "DOWN") {
      return "characterWalkDown 0.5s steps(4) infinite"
    }
    if (direction === "RIGHT") {
      return "characterWalkRight 0.5s steps(4) infinite"
    }
    if (direction === "LEFT") {
      return "characterWalkLeft 0.5s steps(4) infinite"
    }
    if (direction === "UP") {
      return "characterWalkUp 0.5s steps(4) infinite"
    }
  }

  return "none"

}

function getSpritesheetTranslateValue(props) {

  const {currentActionType, direction, isDialogBoxUp} = props;

  //Do use this transform value when standing or when frozen (might actually be a walk action)
  //Also default to this if character has no currentAction (result will be just standing in his/her current `direction`)
  if (isDialogBoxUp || currentActionType === "characterStand" || currentActionType === "characterJump" || !currentActionType) {


    //Default to stand!
    if (direction === "DOWN") {
      return "translate3d(0,0,0)"
    }
    if (direction === "RIGHT") {
      return "translate3d(0,-25%,0)"
    }
    if (direction === "LEFT") {
      return "translate3d(0,-75%,0)"
    }
    if (direction === "UP") {
      return "translate3d(0,-50%,0)"
    }
  }

  //Default to top left (walking animation will take over)
  return "translate3d(0,0,0)"
}

//Jumping, etc
function getJumpingAnimation(props) {

  const {currentActionType, currentActionVariation} = props;

  if (currentActionType === "characterJump") {

    if (currentActionVariation === "twice") {
      return "characterJump 0.24s 2" //quick double jump
    }

    return "characterJump 0.3s"
  }
  return "none"
}

// function getOpacity(props) {
//
//   const {isCameraHero, isVisible, worldOpacity} = props;
//
//   //Always keep the hero visible (unless isVisible is false)
//   if (isCameraHero) {
//     return isVisible ? 1 : 0
//   }
//
//   //Fade out other people with the worldOpacity
//   return isVisible ? worldOpacity : 0
// }