import {shuffle} from "../../../helpers/shuffle";

export function getStatusBoostingDecision({casterModel, teamMembers, enemies, enemyItems}) {

  const myAttackIds = casterModel.attacks.map(a => a.actionId);
  let myStatusBoostingAttacks = [
    "script_headphones",
    "script_deadline",
    "script_troll",
    "script_dangerous",
    "script_block",
  ].filter(key => {
    return myAttackIds.includes(key);
  });
  if (!myStatusBoostingAttacks.length) { return null }

  const aliveFriendsWithNormalStatus = [casterModel, ...teamMembers].filter(e => {
    return e.hp > 0 && e.status === "normal"
  });

  if (aliveFriendsWithNormalStatus.length === 0) { return null }


  const actionId = shuffle(myStatusBoostingAttacks)[0];

  //A little bit of a check to not be silly - don't use a status on themselves when they are the only person left and weak
  if (casterModel.hp < casterModel.maxHp / 2 && aliveFriendsWithNormalStatus.length === 1) {
    if (actionId !== "script_block") { //Block is fine, though
      return null
    }
  }

  //Little bit of extra stuff for deadline: don't try to boost somebody that has extra turns
  if (actionId === "script_deadline") {
    const targetsWithoutExtraTurns = aliveFriendsWithNormalStatus.filter(c => !c.extraTurns);
    //If we chose deadline and have nobody without Extra Turns, we might lose out on this one (its rare that somebody has ETs though)
    if (!targetsWithoutExtraTurns.length) {
      return null
    }
    return {
      actionId: actionId,
      casterId: casterModel.id,
      targetIds: [ shuffle(targetsWithoutExtraTurns)[0].id ],
    }
  }

  return {
    actionId: actionId,
    casterId: casterModel.id,
    targetIds: [ shuffle(aliveFriendsWithNormalStatus)[0].id ],
  }
}