export function createSingleKeyPressBinding(keyCodes = [], pressHandler, releaseHandler) {

  pressHandler = pressHandler || function() {};
  releaseHandler = releaseHandler || function() {};

  return keyCodes.map(keyCode => {

    var el = document.getElementsByTagName("body")[0];

    let keysafe = true;

    var keyDownHandler = function (event) {
      if (event.keyCode === keyCode && keysafe) {

        keysafe = false;
        pressHandler();
      }
    };
    var keyUpHandler = function (event) {
      if (event.keyCode === keyCode) {
        releaseHandler();
        keysafe = true;
      }
    };

    el.addEventListener("keydown", keyDownHandler);
    el.addEventListener("keyup", keyUpHandler);

    //Return references so we can UNBIND later
    return {
      keyUpHandler,
      keyDownHandler
    }
  }) //[ {keyUp, keyDown}, {keyUp, keyDown} ]

}